//import React, { useEffect, useState } from "react"; 
import axios from "axios";
import Cookies from "js-cookie";
import localStorage from "local-storage";
 export default async function UseDevCallApi(type,url,data){  
  // var authKey=localStorage.get('Auth'); 
  var authKey=Cookies.get('microhost_api_auth');
const appInfo=localStorage.get("appInfo");
if(appInfo && authKey){   
  data.apikey="fSwtQGDLhBlkPJgMIraWRAeYxUpmbXZnovVCiqjOHFEsuyKNTdzc";
const darr= await axios({
  method: type,
  url:'https://dev.api.cloudplatformapp.com/v2/'+url,
  headers: {'Authorization': 'Bearer fSwtQGDLhBlkPJgMIraWRAeYxUpmbXZnovVCiqjOHFEsuyKNTdzc'},
  data:data
}).then(function(response){ 
  return response.data; 
}) 

return darr;
 } 
}