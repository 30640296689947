import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from "../../../../CustomHooks/useApi";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import validator from 'validator';
import {
  AddHostCount,
  cloudDeployUpdate,
} from "../../../../Redux/Services/Action/actions";
import useRazorpay from "react-razorpay";
import axios from "axios";
import UsePostData from "../../../../CustomHooks/usePostData";
import spinner from "../billing/spinner.svg";
import "./custom.css";
import PricingContainer from "../pricing/PricingContainer";
import Backup from "./deployTabs/Backup";
import ServerManagement from "./deployTabs/ServerManagement";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const CloudDeploy = (props) => {
  const [plan_period, setPlan_period] = useState("hourly");
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const forceUpdate = useForceUpdate();
  const [adcdata, setAdcdata] = useState([]);
  const [ddcdata, setDdcdata] = useState([]);
  const [pageStatus, setPageStatus] = useState(false)
  const dataFetchedRef = useRef();
  const dataFetchedRef2 = useRef();
  const navigate = useNavigate();
  const [distroData, setDistroData] = useState(null);
  const [planData, setPlanData] = useState(null);
  const [firewallData, setFirewallData] = useState(null);
  const [sshData, setSshData] = useState(null);
  const [hostCount, setHostCount] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [choosePlanType, setChoosePlanType] = useState("basic");
  const user_data = useRef(null);
  const dczoneStatus = useRef(true);
  const defaultCall =useRef(false);

  function MarkRibbonTick(type, ribbon_id, action){
    var tabSelect = document.querySelectorAll([".tickMark"+type]);
    if (tabSelect) {
      tabSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if(action=="on"){
    var currentTab = document.querySelector("#tickMark"+type+"-" + ribbon_id);
    if (currentTab) {
      currentTab.classList.remove("d-none");
    }
  }
  }

  const selectIso=(data, iso_id)=>{
    selectOsApp("iso",data);
    // var iso_field=document.querySelector("#iso_field");
    // if(iso_field){
    //   iso_field.value=data;
    // }
    MarkRibbon("iso", iso_id)
  }
  const selectSnapshot=(data, snapshot_id)=>{
    selectOsApp("snapshot",data);
    // var snapshot_field=document.querySelector("#snapshot_field");
    // if(snapshot_field){
    //   snapshot_field.value=data;
    // }
    MarkRibbon("snapshot", snapshot_id)
  }
  const selectBackup=(data, backup_id)=>{
    selectOsApp("backup",data);
    // var backup_field=document.querySelector("#backup_field");
    // if(backup_field){
    //   backup_field.value=data;
    // }
    MarkRibbon("backup", backup_id)
  }
  function MarkRibbon(type, select_id){
    var appData=["Os", "marketplace", "backup", "snapshot", "iso"]
    if(appData){
      appData.forEach((value)=>{
        MarkRibbonTick(value, 0, "off");
      })
      MarkRibbonTick(type, select_id, "on");
    }
  }
  const selectOne=(type, select_id)=>{  
    var appData=["Os", "marketplace", "backup", "snapshot", "iso"] 
      if(appData){
      appData.forEach((value)=>{
        console.log("ribbon=> "+value);
        MarkRibbonTick(value, 0, "off"); 
      })
     console.log("active ribbon => "+type);
      MarkRibbonTick(type, select_id, "on");
    }
     
  }
  const getDczone = async (dczones) => {
    // const data = await UseApi("get", "dczones", "");

    if (dczones.length === 0) {
      setAdcdata([]);
      setDdcdata([]);
    } else {
      if (dczoneStatus.current) {
        dczones.map(async (value, index) => {
          if (value.status !== "active") {
            fetch("https://api.ipify.org?format=json")
              .then((response) => response.json())
              .then((data) => {
                var server_ip = data.ip;
                if (server_ip) {
                  if (
                    server_ip !== "103.209.144.51" ||
                    server_ip !== "103.209.144.66" ||
                    server_ip !== "103.209.145.208" ||
                    server_ip !== "103.209.144.81" ||
                    server_ip !== "103.209.144.82" ||
                    server_ip !== "103.209.144.83" ||
                    server_ip !== "103.127.30.91" ||
                    server_ip !== "103.209.144.60" ||
                    server_ip !== "103.127.30.239" ||
                    server_ip !== "103.127.28.52"
                  ) {
                    ddcdata.push(value);
                  }
                  forceUpdate();
                }
              })
              .catch((error) => console.error(error));
          } else {
            adcdata.push(value);
            forceUpdate();
          }
        });
        dczoneStatus.current = false;
      }

      setTimeout(()=>{
        var clickTo=document.querySelector("#dcslugl_innoida");
        if(clickTo){
        clickTo.click();
        }
      },1000)
        }
  };
  // useEffect(() => {
  //   if (dataFetchedRef2.current) return;
  //   dataFetchedRef2.current = true;
  //   getDczone();
  // }, []);


  const getDistro = async () => {
    toast.dismiss();
    setDistroData(null);
    const data = await UseApi("get", "cloud/getdeploy", "");
    user_data.current = await UseApi("get", "account/info", "");
    setUserData(user_data.current.user);
    // //////console.log(user_data.current);
    if (Object.keys(data).length === 0) {
      setDistroData(null);
      setPlanData(null);
      setFirewallData(null);
      setSshData(null);
      setHostCount(null);
    } else {
      getDczone(data.dczones);
      getMarketplace(data.stacks);
      setPlanData(data.plans);
      setDistroData(data.distro);
      setFirewallData(data.firewalls);
      setSshData(data.keys);
      // //////console.log(data.plans);
      if (props && props.hostCountData) {
        setHostCount(props.hostCountData);
      } else {
        setHostCount(["host"]);
        //window.location.reload();
        // setTimeout(()=>{
        //   if(!props.hostCountData){
        //     setHostCount(props.hostCountData);
        //   }
        // },2000);
      }

    }
    forceUpdate();
  };
  // select dc

  const [vpcData, setVpcData] = useState(null);
  const [backupData, setBackupData] = useState(null);
  const [snapData, setSnapData] = useState(null);
  const [isoData, setIsoData] = useState(null);

  const [vpcLoader, setVpcLoader] = useState(false);
  const [backupLoader, setBackupLoader] = useState(false);
  const [snapLoader, setSnapLoader] = useState(false);
  const [isoLoader, setIsoLoader] = useState(false);

  const selectDc = async (slug) => {
    setVpcLoader(true);
    setBackupLoader(true);
    setSnapLoader(true);
    setIsoLoader(true);

    var dcSelect = document.querySelectorAll([".tickMarkDc"]);
    var currentDc = document.querySelector("#tickMarkDc-" + slug);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }

    const vpc_data = await UseApi("get", "vpc/dc/" + slug, "");
    const backup_data = await UseApi("get", "backups/" + slug, "");
    const snap_data = await UseApi("get", "snapshot/" + slug, "");
    const iso_data = await UseApi("get", "iso/" + slug, "");

    if (vpc_data.status === "error") {
      setVpcLoader(false);
      setVpcData(null);
    } else {
      setVpcLoader(false);
      delete vpc_data.html;
      setVpcData(vpc_data);
    }

    if(backup_data && backup_data.backups){
      setBackupLoader(false);
         if(backup_data.backups.length==0){
          setBackupData([]);
         }else{
          setBackupData(backup_data.backups);
         }
    }
    // if (Object.keys(backup_data).length === 0) {
    //   setBackupData(null);
    // } else {
    //   setBackupData(backup_data);
    // }
    if(snap_data && snap_data.snapshots){
      setSnapLoader(false);
      if(snap_data.snapshots.length==0){
        setSnapData([]);
      }else{
        setSnapData(snap_data.snapshots);
      }
 }
    // if (Object.keys(snap_data).length === 0) {
    //   setSnapData(null);
    // } else {
    //   setSnapData(snap_data);
    // }
    if(iso_data && iso_data.isos){
      setIsoLoader(false);
      if(iso_data.isos.length==0){
        setIsoData([]);
      }else{
        setIsoData(iso_data.isos);
      }
 }
//  var hourlyBtn=document.querySelector("#hourlyBtn");
//  if(hourlyBtn){
//  hourlyBtn.click();
//  }
    // if (Object.keys(iso_data).length === 0) {
    //   setIsoData(null);
    // } else {
    //   setIsoData(iso_data);
    // }
  };

  useEffect(() => {
    // navigate('/process/data:cloud/deploy')
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getDistro();
    setPageStatus(!pageStatus)
  }, [pageStatus]);


 function selectOsApp(type, data){
  const select_image = document.querySelector("#select_image");
  const main_stack = document.querySelector("#main_stack");
  const iso_field = document.querySelector("#iso_field");
  const backup_field = document.querySelector("#backup_field");
  const snapshot_field = document.querySelector("#snapshot_field");
  if(select_image && main_stack && iso_field && backup_field && snapshot_field){
    select_image.value="";
    main_stack.value="";
    iso_field.value="";
    backup_field.value="";
    snapshot_field.value="";
  }
  if (props.deployInfo) {
      props.deployInfo[0].select_image = "";
      props.deployInfo[0].stack = "";
      props.deployInfo[0].iso = "";
      props.deployInfo[0].backup = "";
      props.deployInfo[0].snapshot = "";
  }
  if(type=="os"){
    if(select_image){
      select_image.value=data;
    }
    if (props.deployInfo) {
      props.deployInfo[0].select_image = data;
  }
  }
  if(type=="marketplace"){
    if(main_stack){
      main_stack.value=data;
    }
    if (props.deployInfo) {
      props.deployInfo[0].stack = data;
  }
  }
  if(type=="iso"){
    if(iso_field){
      iso_field.value=data;
    }
    if (props.deployInfo) {
      props.deployInfo[0].iso = data;
  }
  }
  if(type=="backup"){
    if(backup_field){
      backup_field.value=data;
    }
    if (props.deployInfo) {
      props.deployInfo[0].backup = data;
  }
  }
  if(type=="snapshot"){
    if(snapshot_field){
      snapshot_field.value=data;
    }
    if (props.deployInfo) {
      props.deployInfo[0].snapshot = data;
  }
  }
 }

  const selectOs = (image, distribution, cost) => {
    // var os_main=document.querySelector("#os_main");
    // if(os_main){
    //   os_main.value=image;
    // }
    setCurrMarketplace(null);
    setCurrMarketplaceDistro(null);

    MarkRibbon("Os", distribution)
    // var osSelect = document.querySelectorAll([".tickMarkOs"]);
    // var currentOs = document.querySelector("#tickMarkOs-" + distribution);
    // if (osSelect) {
    //   osSelect.forEach((clist) => {
    //     clist.classList.add("d-none");
    //   });
    // }
    // if (currentOs) {
    //   currentOs.classList.remove("d-none");
    // }
    if (props) {
      const os_cost = document.querySelector("#os_cost");
      os_cost.value = cost;
      if (props.deployInfo) {
        props.deployInfo[0].os_cost = cost;
      }
      const os_version_list_ = document.querySelector(
        "#os_version_list_" + distribution
      );
      os_version_list_.classList.remove("d-block");

      const all_radio = document.getElementsByClassName("radio_image");
      for (var i = 0; i < all_radio.length; i++) {
        all_radio[i].checked = false;
      }
      // const all_label = document.getElementsByClassName("versionOs");
      // for (var i = 0; i < all_label.length; i++) {
      //   //all_label[i].classList.remove('active');
      //   all_label[i].classList.remove("shadowBox");
      // }
      var pselect = document.querySelectorAll([".deploy_osversioncontainer"]);
      pselect.forEach((clist) => {
        clist.classList.remove("d-block");
      });
      const all_versionText = document.getElementsByClassName(
        "osversionselectmultiple"
      );
      for (var i = 0; i < all_versionText.length; i++) {
        all_versionText[i].innerHTML = "Select Version";
      }

      // const select_image = document.querySelector("#select_image");
      // if (props.deployInfo) {
      //   props.deployInfo[0].select_image = image;
      // }
      // select_image.value = image;
      selectOsApp("os", image);
      const current_radio = document.querySelector("#select_" + distribution);
      current_radio.checked = true;
      // const current_label = document.querySelector(
      //   "#versionOs_" + distribution
      // );
      // current_label.classList.add("shadowBox");
      //current_label.classList.add('active');
      const current_select_version = document.querySelector(
        "#osversion_" + distribution
      );
      current_select_version.innerHTML = image;

      cloudCost();
    }
  };

  const osVersion = (distribution) => {
    var pselect = document.querySelectorAll([".deploy_osversioncontainer"]);
    var pheight = document.querySelectorAll([".versionOs"]);
    pselect.forEach((clist) => {
      clist.classList.remove("d-block");
    });
    //var cl=event.currentTarget.querySelector('.versionOs');

    pheight.forEach((clist) => {
      clist.style.height = "auto";
      clist.classList.remove("active");
    });
    //  event.currentTarget.querySelector('.deploy_osversioncontainer').classList.add('d-block');
    const os_version_list_ = document.querySelector(
      "#os_version_list_" + distribution
    );
    if(os_version_list_){
    os_version_list_.classList.add("d-block");
    }
  };

  // start- select plans
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };




  const selectTickMark = (sshid, name) => {
    var allTickmarks = document.querySelectorAll([".tickMark" + name]);
    var currentPlan = document.querySelector("#tickMark" + name + "-" + sshid);
    var mainTickMark = document.querySelectorAll([".border" + name]);
    var currentMain = document.querySelector("#border" + name + "-" + sshid);

    if (mainTickMark) {
      mainTickMark.forEach((clist) => {
        clist.classList.remove("activeBorder");
      });
    }
    if (allTickmarks) {
      allTickmarks.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentPlan) {
      currentPlan.classList.remove("d-none");
    }
    if (currentMain) {
      currentMain.classList.add("activeBorder");
    }
  };
  // end- select plans

  // start ssh keys

  const openDrawer = () => {
    var drawerElement = document.querySelector("#importkey-modal");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = "500px";
  };
  const importSshKey = async () => {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    const responseData = await UsePost("post", "key/import", "importkey");

    //////console.log(responseData);
    if (responseData.status === "error") {
      toast.update(dt, {
        render: responseData.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      var SshForm = document.querySelector("#importkey");
      SshForm.reset();
    } else if (responseData.status === "success") {
      toast.update(dt, {
        render: responseData.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getDistro();
      openDrawer();
      var SshForm = document.querySelector("#importkey");
      SshForm.reset();
    }
  };
  // end ssh keys
  const deployCloudServer = async () => {
    var submitBtn = document.querySelector("#deploy-btn");
    if (submitBtn) {
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      toast.dismiss();
      const dt = toast.loading("Please wait...");
      const responseData = await UsePost("post", "cloud/deploy", "clouddeploy");
      if (responseData) {
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML = preContent;
        if(!responseData.status){
          toast.update(dt, {
            render: "Please provide all details!",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
        if (responseData.status === "error") {
          toast.update(dt, {
            render: responseData.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.update(dt, {
            render: responseData.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const billing_info = document.querySelector("#billing_info").value;
          if (billing_info != "hourly") {
            openInvoice(responseData.invoiceid);
          } else {
            var cloudCount = document.querySelector("#total_chq");
            if (cloudCount) {
              if (cloudCount > 1) {
                navigate("/cloud");
              } else {
                navigate("/cloud/" + responseData.cloudid);
              }
            }
          }
        }
      }
    }
  };

  const getCount = () => {
    getDczone();
    cloudCost();
    forceUpdate();
  };
  const updateHostCount = (status) => {
    // forceUpdate();
    if (props) {
      const node_count = document.querySelector("#total_chq");
      const downCountBtn = document.querySelector("#hostDown");
      const upCountBtn = document.querySelector("#hostUp");
      if (status === "up") {
        downCountBtn.classList.remove("disabled");
        if (parseInt(node_count.value) >= 5) {
          upCountBtn.classList.add("disabled");
        } else {
          node_count.value = parseInt(node_count.value) + 1;
          if (props.hostCountData) {
            props.hostCountData.push("host");
            setHostCount(props.hostCountData);
          }
          //  props.updateHostCountData(props.hostCountData);
          getCount();
        }
      } else if (status === "down") {
        upCountBtn.classList.remove("disabled");
        if (parseInt(node_count.value) <= 1) {
          downCountBtn.classList.add("disabled");
        } else {
          node_count.value = parseInt(node_count.value) - 1;
          if (props.hostCountData) {
            props.hostCountData.pop();
            setHostCount(props.hostCountData);
          }
          // props.updateHostCountData(props.hostCountData);
          getCount();
        }
      }
    }
    // const plan_value = document.querySelector("#plan_value");
    // const plan_cost = document.querySelector("#plan_cost");
    // if (plan_value && plan_cost) {
    //   show_plan_price(plan_value.value, plan_cost.value);
    // }
  };



  const cloudCost=()=>{

    const total_chq = document.querySelector("#total_chq"); //html

    //Billing cycle
     const billing_cycle = document.querySelector("#billing_cycle_value"); //html
    //Oringnal cost
    const original_server_cost = document.querySelector("#original_server_cost"); //html
    const original_cost_server = document.querySelector("#original_cost_server"); //html

    //Discount Percentage
    const discount_server_price = document.querySelector("#discount_server_price"); //html
    const discount_offer = document.querySelector("#discount_offer"); //html
    const discount_price = document.querySelector("#discount_price");

    //Discount cost
    const instance_cost_sec = document.querySelector("#instance_cost_sec"); //html
    const instance_cost = document.querySelector("#instance_cost"); //html

    //Backup cost
    const weekly_backup = document.querySelector("#weekly_backup"); //html
    const weekly_backup_cost = document.querySelector("#weekly_backup_cost"); //html

    //Operating System cost
    const os_price_sec = document.querySelector("#os_price_sec"); //html
    const os_cost_price = document.querySelector("#os_cost_price"); //html

    //Server Management cost
    const server_management = document.querySelector("#server_management"); //html
    const management_server = document.querySelector("#management_server"); //html
    //Configured Instance Sec
     const configured_instance_sec=document.querySelector("#configured_instance_sec");
     const configured_instance_cost=document.querySelector("#configured_instance_cost");

    //Final Instance Cost Sec
     const final_instance_cost_sec=document.querySelector("#final_instance_cost_sec");
     const final_instance_cost=document.querySelector("#final_instance_cost");
     const final_instance_count=document.querySelector("#final_instance_count");

    //instance_calc_sec
    const instance_calc_sec=document.querySelector("#instance_calc_sec");
    //Total cost
    const order_total = document.querySelector("#order_total"); //html
    const order_total_hour = document.querySelector("#order_toatl_hr"); //html


    if(total_chq &&
       billing_cycle &&
       original_server_cost &&
       original_cost_server &&
       discount_server_price &&
       discount_offer &&
       discount_price &&
       instance_cost_sec &&
       instance_cost &&
       weekly_backup &&
       weekly_backup_cost &&
       os_price_sec &&
       os_cost_price &&
       server_management &&
       management_server &&
       configured_instance_sec &&
       configured_instance_cost &&
       final_instance_cost_sec &&
       final_instance_cost &&
       final_instance_count &&
       instance_calc_sec &&
       order_total &&
       order_total_hour){

    var serverCount=parseInt(total_chq.value);
    var InstanceCost=0;
    var BackupCost=0;
    var ManagementCost=0;
    var OsCost=0;
    if(props.deployInfo[0]){
      /*
      os_cost:"",
      select_image:"",
      plan_value:"10045",
      plan_cost:"",
      plan_final_cost:"",
      select_support_option_cost:0,
      select_support_option:"unmanaged",
      billing_info:"hourly",
      isBackup:false
      */
     console.log(props.deployInfo[0]);
     if(props.deployInfo[0].billing_info!="hourly"){
      if(props.deployInfo[0].billing_info=="monthly"){
        billing_cycle.innerHTML="Monthly";
      }else{
        billing_cycle.innerHTML="Annually";
      }
      discount_server_price.classList.remove("d-none");
      discount_offer.innerHTML=props.deployInfo[0].planDiscount;
      discount_price.innerHTML=parseInt(props.deployInfo[0].plan_cost_without_discount)-parseInt(props.deployInfo[0].plan_final_cost);

      instance_cost_sec.classList.remove("d-none");
      instance_cost.innerHTML=props.deployInfo[0].plan_final_cost;

      original_server_cost.classList.remove("d-none");
      original_cost_server.innerHTML=props.deployInfo[0].plan_cost_without_discount;

      InstanceCost=parseInt(props.deployInfo[0].plan_final_cost);
     }else{
      billing_cycle.innerHTML="Hourly";
      discount_server_price.classList.add("d-none");
      discount_offer.innerHTML=props.deployInfo[0].planDiscount;
      discount_price.innerHTML=parseInt(props.deployInfo[0].plan_cost_without_discount)-parseInt(props.deployInfo[0].plan_final_cost);

      instance_cost_sec.classList.remove("d-none");
      instance_cost.innerHTML=props.deployInfo[0].plan_final_cost;

      original_server_cost.classList.add("d-none");
      original_cost_server.innerHTML=props.deployInfo[0].plan_cost_without_discount;

      InstanceCost=parseInt(props.deployInfo[0].plan_final_cost);
     }


     if(props.deployInfo[0].isBackup==true){
      weekly_backup.classList.remove("d-none");
      if(props.deployInfo[0].plan_final_cost){
      weekly_backup_cost.innerHTML=parseInt(parseInt(props.deployInfo[0].plan_final_cost)*0.2);

      BackupCost=parseInt(parseInt(props.deployInfo[0].plan_final_cost)*0.2);
      }
      // console.log("backup enabled");
     }else{
      // console.log("backup disabled");
      weekly_backup.classList.add("d-none");
      if(props.deployInfo[0].plan_final_cost){
      weekly_backup_cost.innerHTML=0;

      BackupCost=0;
      }
     }

     if(props.deployInfo[0].select_support_option!="unmanaged"){
      server_management.classList.remove("d-none");
      management_server.innerHTML=parseInt(props.deployInfo[0].select_support_option_cost);

      ManagementCost=parseInt(props.deployInfo[0].select_support_option_cost);
     }else{
      server_management.classList.add("d-none");
      management_server.innerHTML=parseInt(props.deployInfo[0].select_support_option_cost);

      ManagementCost=parseInt(props.deployInfo[0].select_support_option_cost);
    }

     if(props.deployInfo[0].os_cost!=0){
      os_price_sec.classList.remove("d-none");
      os_cost_price.innerHTML=props.deployInfo[0].os_cost;

      OsCost=parseInt(props.deployInfo[0].os_cost);
     }else{
      os_price_sec.classList.add("d-none");
      os_cost_price.innerHTML=props.deployInfo[0].os_cost;

      OsCost=parseInt(props.deployInfo[0].os_cost);
     }

      console.log(InstanceCost+", "+BackupCost+", "+ManagementCost+", "+OsCost);
      if(parseInt(BackupCost)!=0 || parseInt(ManagementCost)!=0 || parseInt(OsCost)!=0){
         configured_instance_sec.classList.remove("d-none");
         instance_calc_sec.classList.remove("d-none");
         final_instance_cost_sec.classList.remove("d-none");
         configured_instance_cost.innerHTML=parseInt(InstanceCost)+parseInt(BackupCost)+parseInt(ManagementCost)+parseInt(OsCost);
         final_instance_cost.innerHTML=parseInt(InstanceCost)+parseInt(BackupCost)+parseInt(ManagementCost)+parseInt(OsCost);
         final_instance_count.innerHTML=serverCount;
        }else{
          if(parseInt(serverCount)>1){
            final_instance_cost_sec.classList.remove("d-none");
            final_instance_cost.innerHTML=parseInt(InstanceCost)+parseInt(BackupCost)+parseInt(ManagementCost)+parseInt(OsCost);
            final_instance_count.innerHTML=serverCount;
          }else{
            configured_instance_sec.classList.add("d-none");
            final_instance_cost_sec.classList.add("d-none");
            instance_calc_sec.classList.add("d-none");
          }

      }
      //total price
      order_total_hour.innerHTML=(((parseInt(InstanceCost)+parseInt(BackupCost)+parseInt(ManagementCost)+parseInt(OsCost))*serverCount)/720).toFixed(2);
      order_total.innerHTML=(parseInt(InstanceCost)+parseInt(BackupCost)+parseInt(ManagementCost)+parseInt(OsCost))*serverCount;
    }
  }

      }
  // const cloudCost = () => {
  //   const billing_info = document.querySelector("#billing_info");
  //   const plan_cost = document.querySelector("#plan_cost");
  //   const os_cost = document.querySelector("#os_cost");
  //   const node_count = document.querySelector("#total_chq");
  //   const supportSelect = document.querySelector("#select_support_option");
  //   const isBackup = document.querySelector("#selectBackupEnabled");
  //   const order_total = document.querySelector("#order_total"); //html
  //   const order_total_hour = document.querySelector("#order_toatl_hr"); //html
  //   const tickMarkbackup = document.querySelector("#tickMarkbackup"); //html
  //   const borderBackup = document.querySelector("#borderBackup"); //html
  //   const saved_amount = document.querySelector("#saved_cloud_cost"); //html
  //   const weekly_backup = document.querySelector("#weekly_backup"); //html
  //   const backup_weekly = document.querySelector("#backup_weekly"); //html
  //   const server_management = document.querySelector("#server_management"); //html
  //   const management_server = document.querySelector("#management_server"); //html
  //   const discount_server_cost = document.querySelector("#discount_server_cost"); //html
  //   const discount_cost_server = document.querySelector("#discount_cost_server"); //html
  //   const discount_price = document.querySelector("#discount_price");
  //   const discount_server_price = document.querySelector("#discount_server_price"); //html
  //   const current_plan_id = document.querySelector("#current_plan_id");
  //   const original_server_cost = document.querySelector("#original_server_cost"); //html
  //   const original_cost_server = document.querySelector("#original_cost_server"); //html
  //   const discount_offer = document.querySelector("#discount_offer"); //html
  //   const os_price_sec = document.querySelector("#os_price_sec"); //html
  //   const os_cost_price = document.querySelector("#os_cost_price"); //html
  //   const currency_rate = 1;
  //   var serverCount = 1;

  //   if (node_count) {
  //     if (node_count.value != 0) {
  //       serverCount = node_count.value;
  //     }
  //   }

  //   //////console.log(serverCount);
  //   var os_price = parseFloat(os_cost.value * serverCount);
  //   var backupCost = 0;

  //   var supportCost = 0;
  //   if (supportSelect.value === "full") {
  //     supportCost = 6995 * currency_rate * parseFloat(serverCount);
  //     server_management.classList.remove("d-none");
  //   } else if (supportSelect.value === "basic") {
  //     supportCost = 3995 * currency_rate * parseFloat(serverCount);
  //     server_management.classList.remove("d-none");
  //   } else if (supportSelect.value === "unmanaged") {
  //     server_management.classList.add("d-none");
  //     supportCost = 0;
  //   }
  //   var combineCost = parseFloat(plan_cost.value);
  //   var planOfferCost = 0;
  //   var hourlyFinalCost = 0;
  //   var orgAmount = 0;
  //   var org_backupCost = 0;
  //   if (current_plan_id) {
  //     if (current_plan_id.value != "") {
  //       if (billing_info) {
  //         if (
  //           discount_offer &&
  //           original_cost_server &&
  //           original_server_cost &&
  //           discount_cost_server &&
  //           discount_server_cost &&
  //           discount_cost_server
  //         ) {
  //           if (billing_info.value === "hourly") {
  //             planOfferCost = parseFloat(combineCost).toFixed(0);
  //             hourlyFinalCost = parseFloat(planOfferCost / 720).toFixed(2);
  //             discount_cost_server.innerHTML = plan_cost.value;
  //             original_server_cost.classList.add("d-none");
  //             discount_server_cost.classList.remove("d-none");
  //             discount_server_price.classList.add("d-none");
  //           } else if (billing_info.value === "monthly") {
  //             planOfferCost = parseFloat(
  //               (combineCost - (combineCost * 5) / 100) * 1
  //             ).toFixed(0);
  //             hourlyFinalCost = parseFloat(planOfferCost / 720).toFixed(2);
  //             original_server_cost.classList.remove("d-none");
  //             original_cost_server.innerHTML = (
  //               combineCost * serverCount
  //             ).toFixed(0);
  //             discount_server_price.classList.remove("d-none");
  //             discount_price.innerHTML = (
  //               parseFloat(combineCost * serverCount) -
  //               parseFloat(planOfferCost * serverCount)
  //             ).toFixed(0);
  //             discount_cost_server.innerHTML = (
  //               planOfferCost * serverCount
  //             ).toFixed(0);
  //             discount_server_cost.classList.remove("d-none");
  //             discount_offer.innerHTML = "5%";
  //           } else if (billing_info.value === "3months") {
  //             os_price *= 3;
  //             supportCost *= 3;
  //             backupCost *= 3;
  //             orgAmount = combineCost * 3;
  //             var monPrice = parseFloat(
  //               combineCost - (combineCost * 10) / 100
  //             ).toFixed(0);
  //             planOfferCost = parseFloat(planOfferCost/3).toFixed(0);
  //             hourlyFinalCost = parseFloat(monPrice / 720).toFixed(2);
  //             original_server_cost.classList.remove("d-none");
  //             original_cost_server.innerHTML = (
  //               orgAmount * serverCount
  //             ).toFixed(0);
  //             discount_server_price.classList.remove("d-none");
  //             discount_price.innerHTML = (
  //               parseFloat(orgAmount * serverCount) -
  //               parseFloat(planOfferCost * serverCount)
  //             ).toFixed(0);
  //             discount_cost_server.innerHTML = (
  //               planOfferCost * serverCount
  //             ).toFixed(0);
  //             discount_server_cost.classList.remove("d-none");
  //             discount_offer.innerHTML = "10%";
  //           } else if (billing_info.value === "12month") {
  //             os_price *= 12;
  //             supportCost *= 12;
  //             backupCost *= 12;
  //             orgAmount = combineCost * 12;
  //             planOfferCost = parseFloat(
  //               (combineCost - (combineCost * 25) / 100) * 12
  //             ).toFixed(0);
  //             var monPrice = parseFloat(planOfferCost/12).toFixed(0);
  //             hourlyFinalCost = parseFloat(monPrice / 720).toFixed(2);
  //             original_server_cost.classList.remove("d-none");
  //             original_cost_server.innerHTML = (
  //               orgAmount * serverCount
  //             ).toFixed(0);
  //             discount_server_price.classList.remove("d-none");
  //             discount_price.innerHTML = (
  //               parseFloat(orgAmount * serverCount) -
  //               parseFloat(planOfferCost * serverCount)
  //             ).toFixed(0);
  //             discount_cost_server.innerHTML = (
  //               planOfferCost * serverCount
  //             ).toFixed(0);
  //             discount_server_cost.classList.remove("d-none");
  //             discount_offer.innerHTML = "25%";
  //           } else if (billing_info.value === "36month") {
  //             os_price *= 36;
  //             supportCost *= 36;
  //             backupCost *= 36;
  //             orgAmount = combineCost * 36;
  //             planOfferCost = parseFloat(
  //               (combineCost - (combineCost * 40) / 100) * 36
  //             ).toFixed(0);
  //             var monPrice = parseFloat(planOfferCost/36).toFixed(0);
  //             hourlyFinalCost = parseFloat(monPrice / 720).toFixed(2);
  //             original_server_cost.classList.remove("d-none");
  //             original_cost_server.innerHTML = (
  //               orgAmount * serverCount
  //             ).toFixed(0);
  //             discount_server_price.classList.remove("d-none");
  //             discount_price.innerHTML = (
  //               parseFloat(orgAmount * serverCount) -
  //               parseFloat(planOfferCost * serverCount)
  //             ).toFixed(0);
  //             discount_cost_server.innerHTML = (
  //               planOfferCost * serverCount
  //             ).toFixed(0);
  //             discount_server_cost.classList.remove("d-none");
  //             discount_offer.innerHTML = "40%";
  //           }
  //         }
  //       }
  //     }
  //   }
  //   if (os_price > 0) {
  //     if (os_cost_price) {
  //       if (os_price_sec) {
  //         os_price_sec.classList.remove("d-none");
  //         os_cost_price.innerHTML = os_price;
  //       }
  //     }
  //   } else {
  //     if (os_cost_price) {
  //       if (os_price_sec) {
  //         os_price_sec.classList.add("d-none");
  //       }
  //     }
  //   }
  //   var finalCost = 0;
  //   //  //////console.log(billing_info.value);
  //   //  //////console.log(parseFloat(supportCost)+parseFloat(planOfferCost));

  //   management_server.innerHTML = supportCost;
  //   if (billing_info.value != "") {
  //     if (isBackup.checked) {
  //       backupCost = parseFloat(planOfferCost * 0.2 * serverCount);
  //       org_backupCost = parseFloat(orgAmount * 0.2 * serverCount);
  //       if (backup_weekly && weekly_backup) {
  //         if (tickMarkbackup) {
  //           if (borderBackup) {
  //             tickMarkbackup.classList.remove("d-none");
  //             borderBackup.classList.add("activeBorder");
  //           }
  //         }
  //         weekly_backup.classList.remove("d-none");
  //         backup_weekly.innerHTML = parseFloat(backupCost).toFixed(0);
  //       }
  //     } else {
  //       if (backup_weekly && weekly_backup) {
  //         if (tickMarkbackup) {
  //           if (borderBackup) {
  //             tickMarkbackup.classList.add("d-none");
  //             borderBackup.classList.remove("activeBorder");
  //           }
  //         }
  //         ////console.log("backup disabled");
  //         weekly_backup.classList.add("d-none");
  //         backupCost = 0;
  //       }
  //     }

  //     finalCost = parseFloat(
  //       parseFloat(planOfferCost * serverCount) +
  //         parseFloat(os_price) +
  //         parseFloat(backupCost) +
  //         parseFloat(supportCost)
  //     ).toFixed(0);
  //   }
  //   if (isNaN(finalCost)) {
  //     order_total.innerHTML = 0.0;
  //     order_total_hour.innerHTML = 0.0;
  //     saved_amount.innerHTML = 0.0;
  //   } else {
  //     order_total.innerHTML = finalCost;
  //     order_total_hour.innerHTML = hourlyFinalCost;
  //     saved_amount.innerHTML = parseFloat(orgAmount * serverCount) +  org_backupCost - (parseFloat(planOfferCost * serverCount) + backupCost);
  //   }
  // };

  const openInvoice = async (id) => {
    toast.dismiss();
    const invoice = await UseApi("get", "billing/" + id, "");
    setInvoiceData(invoice.invoices[0]);
    ////console.log(invoice);
    const transTabel = document.querySelector("#trans-tabel");
    const invoiceCredit = document.querySelector("#invoice-credit");
    var userAmount = 0;
    if (userData != null) {
      userAmount = userData.availablecredit;
    }
    if (invoice.invoices[0]) {
      if (transTabel) {
        if (invoice.invoices[0].transs.length <= 0) {
          transTabel.classList.add("d-none");
        } else {
          transTabel.classList.remove("d-none");
        }
      }
      // //console.log(parseFloat(invoice.invoices[0].rowtotal));
      if (invoiceCredit) {
        if (parseFloat(userAmount) > parseFloat(invoice.invoices[0].rowtotal)) {
          invoiceCredit.classList.remove("d-none");
        } else {
          invoiceCredit.classList.add("d-none");
        }
      }
    }
    var drawerElement = document.querySelector("#invoice_drawer");
    drawerElement.classList.toggle("drawer-on");
    drawerElement.style.width = "1100px";
  };
  const applyCreditAmount = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once Applied, your available credit will reduce according to this amount!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        toast.dismiss();
        const dt = toast.loading("Invoice processing...");
        const dnsp = await UsePost(
          "post",
          "billing/applycredit",
          "applycredit"
        );
        if (dnsp.status == "error") {
          toast.update(dt, {
            render: dnsp.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.update(dt, {
            render: dnsp.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        swal({
          title: "Apply Credit",
          text: "Your not applied for the invoice!",
          icon: "success",
          button: "OK",
        });
      }
    });
  };
  const Razorpay = useRazorpay();
  const invoicePayment = useCallback(async () => {
    const payInvoiceBtn = document.querySelector("#invoice-payment-button");
    if (payInvoiceBtn) {
      payInvoiceBtn.value = "Loading...";
    }
    const u_data = await UseApi("get", "account/info", "");
    const uData = u_data.user;
    const u_razorpay_info = await UseApi("get", "mhcsecinfo", "");

    ////console.log(razorpay_info);
    const amountFund = document.querySelector("#invoiceAmountFund");
    const descp_invoice_id = document.querySelector("#invoice_id");
    var invoice_id = "";
    //console.log(descp_invoice_id.value);
    if (descp_invoice_id) {
      invoice_id = descp_invoice_id.value;
    }
    var rorder_id = "";
    var rfullname = "";
    var rmobile = "";
    var remail = "";
    var ramount = 0;
    var rcurrency = "";
    if (amountFund) {
      ramount = amountFund.value;
    }

    if (uData) {
      rorder_id = uData.razorpay_orderid;
      rfullname = uData.fullname;
      rmobile = uData.mobile;
      remail = uData.email;
      rcurrency = uData.currency;
    }
    // //console.log(rorder_id);
    // //console.log(rfullname);
    // //console.log(rmobile);
    // //console.log(remail);
    // //console.log(ramount);
    // //console.log(invoice_id);
    const options = {
      //key: "rzp_live_aeMFERiIAsYFFF",
      key: u_razorpay_info.razorpay_key,
      amount: ramount * 100,
      currency: "INR",
      name: "Microhost Cloud",
      description: invoice_id,
      image: "https://www.microhost.com/assets/favicon/apple-touch-icon.png",
      prefill: {
        name: rfullname,
        email: remail,
        contact: rmobile,
      },
      options: {
        checkout: {
          method: {
            netbanking: 1,
            card: 0,
            upi: 1,
          },
        },
      },
      handler: async (res) => {
        //res.razorpay_payment_id
        //res.status_code
        //pay_L0C8DacFM1Voqi
        //console.log(res);
        if (res.status_code == 200) {
          swal({
            title: "Processing please wait...",
            icon: spinner,
            html: true,
            showSpinner: true,
          });
          const resData = await UsePostData("post", "billing/addpayment", {
            platform: "2023",
            invoiceid: invoice_id,
            transid: res.razorpay_payment_id,
            amount: ramount,
            currency: rcurrency,
            gateway: "Razorpay",
          });
          if (resData.status == "success") {
            swal({
              title: "Invoice",
              text: "Invoice paid successfuly",
              icon: "success",
              button: "OK",
            });
            setTimeout(() => {
              navigate("/cloud");
            }, 4000);
          } else {
            swal({
              title: "Invoice",
              text: "Invoice not paid!",
              icon: "warning",
              button: "OK",
            });
          }

          //console.log(resData);
          // const paymentInfo=await axios.get('https://'+u_razorpay_info.razorpay_key+':'+u_razorpay_info.razorpay_secret+'@api.razorpay.com/v1/payments/'+res.razorpay_payment_id+'');
          // //console.log(paymentInfo);
        }
      },
      notes: {
        address: "Microhost Cloud- Fund add",
      },
      theme: {
        color: "#377dff",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
    if (payInvoiceBtn) {
      payInvoiceBtn.value = "Pay";
    }
  }, [Razorpay]);

  function ChangeScreen() {
    useEffect(() => {
      function handleResize() {
        const sideCheckout = document.querySelector("#sideCheckout");
        if (sideCheckout) {
          if (window.innerWidth > 990) {
            sideCheckout.classList.add("mhc_checkout");
          } else {
            sideCheckout.classList.remove("mhc_checkout");
          }
        }
      }
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [pageStatus]);
    return null;
  }



  const [marketplaceData, setMarketplaceData]=useState(null);
  const [marketplaceLoader, setMarketplaceLoader]=useState(false);
  const getMarketplace=async(marketData)=>{
    setMarketplaceLoader(true);
    // const marketData=await UseApi("get","stacks","nothing");
    //console.log(marketData);
    if(marketData){
      setMarketplaceLoader(false);
      if(marketData.length!=0){
        if(marketData){
           setMarketplaceData(marketData);
        }
      }else{
         setMarketplaceData([]);
      }
    }
  }
  const [currMarketplace, setCurrMarketplace]=useState(null);
  const [currMarketplaceDistro, setCurrMarketplaceDistro]=useState(null);
  const [currMarketplaceLoader, setCurrMarketplaceLoader]=useState(false);
  const selectMarketPlace=async (id)=>{
    MarkRibbon("marketplace", id);
    var mainStack=document.querySelector("#main_stack");
    selectOsApp("marketplace",id);
    if (props) {
      const os_cost = document.querySelector("#os_cost");
      os_cost.value = 0;
      if (props.deployInfo) {
        props.deployInfo[0].os_cost = 0;
      }
    }
    var os_main=document.querySelector("#os_main");
    if(os_main){
      os_main.value="";
    }
    if(mainStack){
          mainStack.value=id;
    setCurrMarketplaceLoader(true);
    const marketData=await UseApi("get","stacks","nothing");
    // //console.log(marketData);
    if(marketData){
      setCurrMarketplaceLoader(false);
      if(Object.keys(marketData).length!=0){
        if(marketData.stacks){
          marketData.stacks.map((value,index)=>{
            if(value.id==id){
              if(value.distro.length==0){
                setCurrMarketplaceDistro([]);
             }else{
              setCurrMarketplaceDistro(value.distro);
             }
              if(value.fields.length==0){
                 setCurrMarketplace([]);
              }else{
                 setCurrMarketplace(value.fields);
              }
             }
          })
        }
      }
    }
  }
  cloudCost();
  }

  // useEffect(()=>{
  //     getMarketplace();
  // },[])

 //---------------------------CORE FUNCTIONS------------------------------------------

 function updatedPlanInfo(plan=[]){
  const current_plan_id = document.querySelector("#current_plan_id");
  const current_plan_price = document.querySelector("#current_plan_price");
  const billing_info = document.querySelector("#billing_info");
  const plan_value = document.querySelector("#plan_value");
  const plan_cost = document.querySelector("#plan_cost");
  const plan_final_cost = document.querySelector("#plan_final_cost");

  if (current_plan_id && current_plan_price && billing_info && plan_value && plan_cost) {
    current_plan_id.value = plan.planid;
    current_plan_price.value = plan.plan_org_value;
    billing_info.value = plan.billing;
    plan_value.value = plan.planid;
    plan_cost.value = plan.plan_org_value;
    plan_final_cost.value = plan.payable_price;

  }
  if (props.deployInfo) {
    props.deployInfo[0].plan_cost = plan.plan_org_value;
    props.deployInfo[0].plan_value = plan.planid;
    props.deployInfo[0].billing_info = plan.billing;
    props.deployInfo[0].plan_final_cost=plan.payable_price;
    props.deployInfo[0].plan_cost_without_discount=plan.plan_cost_without_discount;
    props.deployInfo[0].planDiscount=plan.plan_discount;
  }
  cloudCost();
 }

 function updateServerManagement(type, cost){
  if (props.deployInfo) {
    var set_support_field=document.querySelector("#set_support_field");
    set_support_field.value=type;
    props.deployInfo[0].select_support_option = type;
    props.deployInfo[0].select_support_option_cost = cost;
  }
  cloudCost();
   console.log("type : "+type+" <=> cost : "+cost);
 }

 function updateBackup(backupValue){
  if (props.deployInfo) {
    var set_enablebackup_field=document.querySelector("#set_enablebackup_field");
    props.deployInfo[0].isBackup = backupValue;
    set_enablebackup_field.value=backupValue;
  }
  cloudCost();
  console.log("isBackup : "+backupValue);
}

function showAuth(action){
  var sshSec=document.querySelector("#ssh-sec");
  var passwordSec=document.querySelector("#password-sec");
  if(sshSec && passwordSec){
    if(action=="ssh"){
      sshSec.classList.remove("d-none");
      passwordSec.classList.add("d-none");
    }else{
      sshSec.classList.add("d-none");
      passwordSec.classList.remove("d-none");
    }
  }
}
const validate = (value) => {
  const password_strength=document.querySelector("#password_strength");
  const password_weak=document.querySelector("#password_weak");
   if (validator .isStrongPassword(value, {
     minLength: 8, minLowercase: 1,
     minUppercase: 1, minNumbers: 1, minSymbols: 1
   })) {
     password_strength.classList.remove("d-none");
     password_weak.classList.add("d-none");
   } else {
     password_strength.classList.add("d-none");
     password_weak.classList.remove("d-none");
   }
 }
 
 const [selectedOption, setSelectedOption] = useState('option1');

 const handleOptionChange = (event) => {
   setSelectedOption(event.target.value);
 };

  return (
    <>
    {props && props.deployInfo && adcdata!=null && distroData!=null ?(<>
      <ChangeScreen />
      <div className="row">
        <div className="col-xl-9">
          <form id="clouddeploy">
            {/*begin::Tables Widget 9*/}
            <div className="mb-5 mb-xl-12">
              <div className="col-xl-12 card mb-5">
              {/*begin::Header*/}
              <div className="card-header border-3 mb-5 pt-2">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800 mb-1">
                    Select DC Location
                  </span>
                  <span className="text-gray-400 mt-1 fw-bold fs-6">
                     Data Centers
                    </span>
                </h3>

              </div>
              {/*end::Header*/}
              {/* Begin body */}
              <div className="container pb-5">
                {/*begin::Row*/}
                <div
                  id="dc-location"
                  className="row g-7"
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >
                  {adcdata.length > 0
                    ? adcdata &&
                      adcdata.map((value, index) => (
                        <>
                          <div className="col-md-4 col-lg-4 col-sm-6 col-xxl-4 dc_sibling">
                            <input
                              className="form-check-input deploy_checkbox "
                              type="radio"
                              name="dcslug"
                              defaultValue={value.slug}
                              id={"dcslug" + value.slug + ""}
                            />

                            <label
                              onClick={() => selectDc(value.slug)}
                              style={{ WebkitJustifyContent: "unset" }}
                              id={"dcslugl_" + value.slug + ""}
                              htmlFor={"dcslug" + value.slug + ""}
                              className="position-relative  dcsluglable border-1 border-gray-300 border px-1 py-1"
                            >
                              <div className=" child">
                                <div className="p-3 ">
                                  <div className="row">
                                    <div className="col-4">
                                      <img
                                        src={
                                          "/assets/media/flags/svg/" +
                                          value.cc +
                                          ".svg"
                                        }
                                        className="w-50px "
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-8">
                                      <p className="no-margin text-dark fw-bolder text-hover-primary ">
                                        {value.city}
                                        <span className="text-muted d-block fw-bold ">
                                          {value.country}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <span className="d-none child position-absolute top-0 start-100 translate-middle  badge badge-circle badge-primary">
                                5
                              </span>
                              {/*begin::Ribbon*/}
                              <div
                                className="d-none tickMarkDc ribbon ribbon-triangle ribbon-top-start border-primary"
                                id={"tickMarkDc-" + value.slug}
                              >
                                {/*begin::Ribbon icon*/}
                                <div className="ribbon-icon mt-n5">
                                  <i className="bi bi-check2 fs-2 text-white" />
                                </div>
                                {/*end::Ribbon icon*/}
                              </div>
                              {/*end::Ribbon*/}
                            </label>
                          </div>
                        </>
                      ))
                    : (<><div className="small-spinner"></div></>)}
                  {ddcdata.length > 0
                    ? !ddcdata &&
                      ddcdata.map((value, index) => (
                        <>
                          <div className="col-md-4 col-lg-4 col-sm-6 col-xxl-4 dc_sibling">
                            <input
                              className="form-check-input deploy_checkbox deploylocationsoldout"
                              type="radio"
                              name="dcslug"
                              onClick={(e) => (e.target.checked = false)}
                              defaultValue={value.slug}
                              id={"dcslug" + value.slug + ""}
                            />
                            <label
                              onClick={() => selectDc(value.slug)}
                              style={{ WebkitJustifyContent: "unset" }}
                              id={"dcslugl_" + value.slug + ""}
                              htmlFor={"dcslug" + value.slug + ""}
                              className=" dcsluglable border-1 border-gray-300 border px-1 py-1"
                            >
                              <div className="child">
                                <div className="p-3 ">
                                  <div className="row">
                                    <div className="col-4">
                                      <img
                                        src={
                                          "/assets/media/flags/svg/" +
                                          value.cc +
                                          ".svg"
                                        }
                                        className="w-50px "
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-8">
                                      <p className="no-margin text-dark fw-bolder text-hover-primary ">
                                        {value.city}
                                        <span className="text-muted d-block fw-bold ">
                                          {value.country}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="d-flex flex-stack mb-3">
    <div className="me-3 p-3">
      <img
        src={"/assets/media/flags/svg/"+value.cc+".svg"}
        className="w-35px ms-n1 me-6"
        alt=""
      />
      <a
        className="text-gray-800 text-hover-primary fw-bolder"
      >
        {value.city}
      </a>
    </div>
    <div className="me-3">
      <span className=" text-muted fw-bold justify-content-end">{value.country}</span>

    </div>
  </div>  */}
                            </label>
                          </div>
                        </>
                      ))
                    : (<></>)}
                </div>
                {/*end::Row*/}
              </div>
              {/* End body */}
              </div>
              {/*begin::Col*/}
              <div className="col-xl-12 card mb-5">
                {/*begin::List widget 10*/}
                {/*begin::Header*/}
                <div className="card-header border-3 mb-5 pt-2">
                <input
                    className="btn-check"
                    type="hidden"
                    name="imageOS"
                    id="os_main"
                  />
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Select OS/APPS
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                      Operating System, ISO, Snapshot, Backups
                    </span>
                  </h3>
                  {/*end::Title*/}
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="container pb-5">
                  {/*begin::Nav*/}
                  <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
  <li className="nav-item">
    <a
      className="nav-link text-active-primary py-5 me-6 active"
      data-bs-toggle="tab"
      href="#distros-tabs"
    >
      Operating System
    </a>
  </li>
  <li className="nav-item">
    <a
      className="nav-link text-active-primary py-5 me-6"
      data-bs-toggle="tab"
      href="#marketplace-tab"
    >
      Marketplace
    </a>
  </li>
  <li className="nav-item">
    <a
      className="nav-link text-active-primary py-5 me-6"
      data-bs-toggle="tab"
      href="#iso-tabs"
    >
      ISO
    </a>
  </li>
  <li className="nav-item">
    <a
      className="nav-link text-active-primary py-5 me-6"
      data-bs-toggle="tab"
      href="#snapshot-tabs"
          >
      Snapshots
    </a>
  </li>
  <li className="nav-item">
    <a
      className="nav-link text-active-primary py-5 me-6"
      data-bs-toggle="tab"
      href="#backups-tabs"
    >
      Backups
    </a>
  </li>

</ul>


                  {/*end::Nav*/}
                  {/*begin::Tab Content*/}
                  <div className="tab-content mt-5 pe-8">
                    {/*begin::Tap pane*/}
                    <div className="tab-pane fade show active" id="distros-tabs">
                      {/*begin::Row*/}
                      <input
                        type="hidden"
                        className="form-control"
                        id="os_cost"
                        defaultValue={
                          props.deployInfo && props.deployInfo
                            ? props.deployInfo[0].os_cost
                            : ""
                        }
                      />
                      <div
                        id="distros"
                        className="row g-3"
                        data-kt-buttons="true"
                        data-kt-buttons-target="[data-kt-button]"
                      >
                        {distroData != null &&
                          distroData?(<>
                          {distroData.map((value, index) => (
                            <>
                              {/*begin::Col*/}
                              <div
                                className="col-md-4 col-lg-4 col-xxl-4"
                                style={{ height: "73.06px !important" }}
                              >
                                <input
                                  type="radio"
                                  name="image_OS"
                                  id={"select_" + value.distro}
                                  defaultValue=""
                                  className="deploy_checkbox distro_radio Os_radio"
                                />
                                <label
                                  onClick={() => osVersion(value.distro)}
                                  //className="label versionOs btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start"
                                  className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2"
                                  id={"versionOs_" + value.distro}
                                  style={{position: "relative"}}
                                >
                                  <span className="ms-5">
                                    {/*begin::Item*/}
                                    <div className="d-flex  ">
                                      {/*begin::Text*/}
                                      <div className="flex-grow-1">
                                        <div className="row">
                                          <div className="col-4">
                                            {/*begin::Avatar*/}
                                            <div className="symbol symbol-50px me-5">
                                              <span
                                                className={
                                                  "fonticon_" + value.distro
                                                }
                                              />
                                            </div>
                                            {/*end::Avatar*/}
                                          </div>
                                          <div className="col-8">
                                            <p className="text-dark fw-bolder text-hover-primary fs-6">
                                              {value.distribution}

                                              <span
                                                className="text-muted d-block fw-bold deploy_checkbox_line2 osversionselectmultiple w-100px"
                                                id={
                                                  "osversion_" +
                                                  value.distro
                                                }
                                              >
                                                Select Version
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                        <span
                                          className="deploy_osversioncontainer bg-white p-5 border-left-right-bottom"
                                          // style={{
                                          //   zIndex: "99",
                                          //   width: "257.33px",
                                          //   marginLeft: "-37px",
                                          //   backgroundColor: "#F0FAFF",
                                          //   display: "none",
                                          // }}
                                          style={{position: "absolute",
                                            top: "100%",
                                            left: 0,
                                            width: "100%",
                                            zIndex: 99,
                                            display: "none"}}
                                          id={
                                            "os_version_list_" +
                                            value.distro
                                          }
                                        >
                                          {value.images &&
                                            value.images.map(
                                              (value2, index2) => (
                                                <>
                                                  <span
                                                    className="deploy_osversionselector alert alert-primary p-3"
                                                    data-image={value2.image}
                                                    data-oscost={value2.cost}
                                                    data-title={
                                                      value2.distro
                                                    }
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      selectOne('Os',value.distribution);
                                                      selectOs(
                                                        value2.image,
                                                        value2.distro,
                                                        value2.cost
                                                      );
                                                    }}
                                                  >
                                                    {value2.version}
                                                    {value2.cost > 0 ? (
                                                      <>
                                                        <span className="badge badge-warning">
                                                          {value2.cost}/Mo
                                                        </span>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                </>
                                              )
                                            )}
                                        </span>
                                      </div>
                                      {/*end::Text*/}
                                    </div>
                                    {/*end::Item*/}
                                  </span>

                                  {/*begin::Ribbon*/}
                                  <div
                                    className="d-none tickMarkOs ribbon ribbon-triangle ribbon-top-start border-primary"
                                    id={"tickMarkOs-" + value.distro}
                                  >
                                    {/*begin::Ribbon icon*/}
                                    <div className="ribbon-icon mt-n5">
                                      <i className="bi bi-check2 fs-2 text-white" />
                                    </div>
                                    {/*end::Ribbon icon*/}
                                  </div>
                                  {/*end::Ribbon*/}
                                </label>
                              </div>
                              {/*end::Col*/}
                            </>
                          ))}</>):(<><div className="small-spinner"></div></>)}
                      </div>
                      {/*end::Row*/}
                      <input
                        type="hidden"
                        name="image"
                        id="select_image"
                        defaultValue={
                          props.deployInfo && props.deployInfo
                            ? props.deployInfo[0].select_image
                            : ""
                        }
                        className="deploy_checkbox image_field"
                      />
                    </div>
                    {/*end::Tap pane*/}
                    {/*begin::Tap pane*/}
                    <div className="tab-pane fade" id="iso-tabs">

                      <div
                        id="getiso"
                        className="row g-9"
                        data-kt-buttons="true"
                        data-kt-buttons-target="[data-kt-button]"
                      >
                        <input type="hidden" name="iso" defaultValue={
                          props.deployInfo && props.deployInfo
                            ? props.deployInfo[0].iso
                            : ""
                        } id="iso_field"/>
                        {isoLoader==true?(<>
                        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                          <div className="small-spinner"></div>
                          </div>
                        </>):(<>
                        {isoData != null? (<>

                        {isoData.length != 0?(<>
                          {isoData.map((value, index) => (
                            <>
                              <div className="col-md-4 col-lg-4 col-xxl-4 ">
                                {/*begin::Option*/}
                                <input
                                  className="btn-check deploy_checkbox iso_radio"
                                  type="radio"
                                  name="image_OS"
                                  defaultValue={value.file}
                                  id={"iso" + value.file}
                                />
                                <label
                                onClick={(e) =>selectIso(value.file, index)}
                                  htmlFor={"iso" + value.file}
                                  className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2 d-flex"
                                >
                                  {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                  <span className="svg-icon svg-icon-primary svg-icon-4x me-4">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M19 10C18.9 10 18.9 10 18.8 10C18.9 9.7 19 9.3 19 9C19 7.3 17.7 6 16 6C15.4 6 14.8 6.2 14.3 6.5C13.4 5 11.8 4 10 4C7.2 4 5 6.2 5 9C5 9.3 5.00001 9.7 5.10001 10H5C3.3 10 2 11.3 2 13C2 14.7 3.3 16 5 16H9L13.4 20.4C14 21 15 20.6 15 19.8V16H19C20.7 16 22 14.7 22 13C22 11.3 20.7 10 19 10Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </span>
                                  {/*end::Svg Icon*/}
                                  <span className="d-block fw-bold text-start py-1">
                                    <span className="text-dark fw-bolder d-block fs-6">
                                      {value.name}
                                    </span>
                                    <span className="text-muted fw-bold fs-6">
                                      {value.added_at}
                                    </span>
                                  </span>
                                  {/*begin::Ribbon*/}
                                  <div
                                    className="d-none tickMarkiso ribbon ribbon-triangle ribbon-top-start border-primary"
                                    id={"tickMarkiso-" + index}
                                  >
                                    {/*begin::Ribbon icon*/}
                                    <div className="ribbon-icon mt-n5">
                                      <i className="bi bi-check2 fs-2 text-white" />
                                    </div>
                                    {/*end::Ribbon icon*/}
                                  </div>
                                  {/*end::Ribbon*/}
                                </label>
                                {/*end::Option*/}
                              </div>
                            </>
                          ))}
                          </>):(<>
                            <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                              {/*begin::Wrapper*/}

                              <div className="pt-lg-10 mb-10">
                                {/*begin::Message*/}
                                <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                  You don't have any ISO on this location
                                </div>
                                {/*end::Message*/}
                              </div>
                              {/*end::Wrapper*/}
                            </div>
                          </>)}
                        </>) : (
                          <>
                            {/*begin::Content*/}
                            <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                              {/*begin::Wrapper*/}
                              <div className="pt-lg-10 mb-10">
                                {/*begin::Message*/}
                                <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                  Please select Datacenter Location to view ISO
                                  list.
                                </div>
                                {/*end::Message*/}
                              </div>
                              {/*end::Wrapper*/}
                            </div>
                            {/*end::Content*/}
                          </>
                        )}
                        </>)}
                      </div>
                    </div>
                    {/*end::Tap pane*/}
                    {/*begin::Tap pane*/}
                    <div className="tab-pane fade" id="snapshot-tabs">
                      <div
                        id="getsnapshot"
                        className="row g-9"
                        data-kt-buttons="true"
                      >
                      <input type="hidden" name="snapshotid" defaultValue={
                          props.deployInfo && props.deployInfo
                            ? props.deployInfo[0].snapshot
                            : ""
                        } id="snapshot_field"/>
                        {snapLoader==true?(<>
                        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                          <div className="small-spinner"></div>
                          </div>
                        </>):(<>
                        {snapData != null ? (<>
                        {snapData.length != 0?(<>
                          {snapData.map((value, index) => (
                            <>
                              <div className="col-md-4 col-lg-4 col-xxl-4">
                                <input
                                  className="btn-check deploy_checkbox snapshot_radio"
                                  type="radio"
                                  name="image_OS"
                                  defaultValue={value.id}
                                  id={"snapshotid" + value.id}
                                />
                                <label
                                onClick={(e) =>selectSnapshot(value.id, value.id)}
                                  htmlFor={"snapshotid" + value.id}
                                  className=" label versionOs text-start border-1 border-gray-300 border px-6 py-1 d-flex"
                                >
                                  <span className="svg-icon svg-icon-primary svg-icon-4x me-4">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M22 5V19C22 19.6 21.6 20 21 20H19.5L11.9 12.4C11.5 12 10.9 12 10.5 12.4L3 20C2.5 20 2 19.5 2 19V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5ZM7.5 7C6.7 7 6 7.7 6 8.5C6 9.3 6.7 10 7.5 10C8.3 10 9 9.3 9 8.5C9 7.7 8.3 7 7.5 7Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M19.1 10C18.7 9.60001 18.1 9.60001 17.7 10L10.7 17H2V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V12.9L19.1 10Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </span>
                                  <span className="d-block fw-bold text-start py-2">
                                    <span className="text-dark fw-bolder d-block fs-6">
                                      {value.id}
                                    </span>
                                    <span className="text-muted fw-bold fs-6">
                                      {" "}
                                      {value.createdate}{" "}
                                    </span>
                                  </span>
                                  {/*begin::Ribbon*/}
                                  <div
                                    className="d-none tickMarksnapshot ribbon ribbon-triangle ribbon-top-start border-primary"
                                    id={"tickMarksnapshot-" + value.id}
                                  >
                                    {/*begin::Ribbon icon*/}
                                    <div className="ribbon-icon mt-n5">
                                      <i className="bi bi-check2 fs-2 text-white" />
                                    </div>
                                    {/*end::Ribbon icon*/}
                                  </div>
                                  {/*end::Ribbon*/}
                                </label>
                              </div>
                            </>
                          ))}
                          </>):(<>
                            <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                              {/*begin::Wrapper*/}

                              <div className="pt-lg-10 mb-10">
                                {/*begin::Message*/}
                                <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                 You don't have any Snapshots on this location
                                </div>
                                {/*end::Message*/}
                              </div>
                              {/*end::Wrapper*/}
                            </div>
                          </>)}</>
                        ) : (
                          <>
                            {/*begin::Content*/}
                            <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                              {/*begin::Wrapper*/}
                              <div className="pt-lg-10 mb-10">
                                {/*begin::Message*/}
                                <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                  Please select Datacenter Location to view
                                  snapshots list.
                                </div>
                                {/*end::Message*/}
                              </div>
                              {/*end::Wrapper*/}
                            </div>
                            {/*end::Content*/}
                          </>
                        )}
                        </>)}
                      </div>
                    </div>
                    {/*end::Tap pane*/}
                    {/*begin::Tap pane*/}
                    <div className="tab-pane fade" id="backups-tabs">
                      <div
                        id="getbackup"
                        className="row g-9"
                        data-kt-buttons="true"
                      >
                        <input type="hidden" name="backupid" defaultValue={
                          props.deployInfo && props.deployInfo
                            ? props.deployInfo[0].backup
                            : ""
                        } id="backup_field"/>
                        {backupLoader==true?(<>
                        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                          <div className="small-spinner"></div>
                          </div>
                        </>):(<>
                        {backupData != null? (<>
                          {backupData.length != 0?(<>
                          {backupData.map(
                            (value, index) => (
                              <>
                                <div className="col-md-4 col-lg-12 col-xxl-4">
                                  {/*begin::Option*/}
                                  <input
                                    className="btn-check deploy_checkbox backup_radio"
                                    type="radio"
                                    name="image_OS"
                                    defaultValue={value.id}
                                    id={"backupid" + value.id}
                                  />
                                  <label
                                  onClick={(e) =>selectBackup(value.id, value.id)}
                                    htmlFor={"backupid" + value.id}
                                    className=" label versionOs text-start border-1 border-gray-300 border px-6 py-1 d-flex"
                                    style={{ height: "73.06px !important" }}
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                    <span className="svg-icon svg-icon-primary svg-icon-4x me-4">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM6.39999 9.89999C6.99999 8.19999 8.40001 6.9 10.1 6.4C10.6 6.2 10.9 5.7 10.7 5.1C10.5 4.6 9.99999 4.3 9.39999 4.5C7.09999 5.3 5.29999 7 4.39999 9.2C4.19999 9.7 4.5 10.3 5 10.5C5.1 10.5 5.19999 10.6 5.39999 10.6C5.89999 10.5 6.19999 10.2 6.39999 9.89999ZM14.8 19.5C17 18.7 18.8 16.9 19.6 14.7C19.8 14.2 19.5 13.6 19 13.4C18.5 13.2 17.9 13.5 17.7 14C17.1 15.7 15.8 17 14.1 17.6C13.6 17.8 13.3 18.4 13.5 18.9C13.6 19.3 14 19.6 14.4 19.6C14.5 19.6 14.6 19.6 14.8 19.5Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M16 12C16 14.2 14.2 16 12 16C9.8 16 8 14.2 8 12C8 9.8 9.8 8 12 8C14.2 8 16 9.8 16 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                    <span className="d-block fw-bold text-start py-2">
                                      <span className="text-dark fw-bolder d-block fs-6">
                                        {value.id}
                                      </span>
                                      <span className="text-muted fw-bold fs-6">
                                        {" "}
                                        {value.created_at}
                                      </span>
                                    </span>
                                    {/*begin::Ribbon*/}
                                  <div
                                    className="d-none tickMarkbackup ribbon ribbon-triangle ribbon-top-start border-primary"
                                    id={"tickMarkbackup-" + value.id}
                                  >
                                    {/*begin::Ribbon icon*/}
                                    <div className="ribbon-icon mt-n5">
                                      <i className="bi bi-check2 fs-2 text-white" />
                                    </div>
                                    {/*end::Ribbon icon*/}
                                  </div>
                                  {/*end::Ribbon*/}
                                  </label>
                                  {/*end::Option*/}
                                </div>
                              </>
                            )
                          )}</>):(<>
                            <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                              {/*begin::Wrapper*/}

                              <div className="pt-lg-10 mb-10">
                                {/*begin::Message*/}
                                <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                  You don't have any Backups on this location
                                </div>
                                {/*end::Message*/}
                              </div>
                              {/*end::Wrapper*/}
                            </div>
                          </>)}</>
                        ) : (
                          <>
                            {/*begin::Content*/}
                            <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                              {/*begin::Wrapper*/}
                              <div className="pt-lg-10 mb-10">
                                {/*begin::Message*/}
                                <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                  Please select Datacenter Location to view
                                  backup list.
                                </div>
                                {/*end::Message*/}
                              </div>
                              {/*end::Wrapper*/}
                            </div>
                            {/*end::Content*/}
                          </>
                        )}
                        </>)}
                      </div>
                    </div>
                    {/*end::Tap pane*/}
                    {/*begin::Tap pane*/}
                    <div className="tab-pane fade" id="marketplace-tab">
                      {/*begin::Row*/}
                                    <input
                                    type="hidden"
                                    name="stack"
                                    id="main_stack"
                                  />
                      <div
                        id="marketplace-data"
                        className="row g-9"
                        data-kt-buttons="true"
                        data-kt-buttons-target="[data-kt-button]"
                      >
                        {/*begin::Col*/}
                        {marketplaceLoader==false?(<>
                           {marketplaceData!=null && marketplaceData.length!=0?(<>
                              {marketplaceData.map((value, index) => (<>
                                <div className="col-md-4 col-lg-12 col-xxl-4">
                                  {/*begin::Option*/}
                                  <input
                                    className="btn-check deploy_checkbox marketplace_radio"
                                    type="radio"
                                    name="image_OS"
                                    defaultValue={value.id}
                                    id={"marketplace" + value.id}
                                  />
                                  <label
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      selectOne('marketplace',value.id);
                                      selectMarketPlace(value.id)
                                    }}
                                    htmlFor={"marketplace" + value.id}
                                    className=" label versionOs text-start border-1 border-gray-300 border px-6 py-2 d-flex"
                                   >
                                    {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                    {/*begin::Avatar*/}
                                <div className="symbol symbol-50px me-5">
                                  <img
                                    src={'/'+value.logo_url}
                                    className=""
                                    alt=""
                                  />
                                </div>
                                    {/*end::Svg Icon*/}
                                    <span className="d-block  text-start p-3">
                                      <span className="text-dark d-block fs-4">
                                        {value.title}
                                      </span>
                                    </span>
                                    {/*begin::Ribbon*/}
                                  <div
                                    className="d-none tickMarkmarketplace ribbon ribbon-triangle ribbon-top-start border-primary"
                                    id={"tickMarkmarketplace-" + value.id}
                                  >
                                    {/*begin::Ribbon icon*/}
                                    <div className="ribbon-icon mt-n5">
                                      <i className="bi bi-check2 fs-2 text-white" />
                                    </div>
                                    {/*end::Ribbon icon*/}
                                  </div>
                                  {/*end::Ribbon*/}
                                  </label>
                                  {/*end::Option*/}
                                </div>

                              </>))}
                           </>):(<>
                            <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                              {/*begin::Wrapper*/}

                              <div className="pt-lg-10 mb-10">
                                {/*begin::Message*/}
                                <div className="fw-bold fs-5  mb-15 badge badge-light-primary">
                                  No Marketplace Available
                                </div>
                                {/*end::Message*/}
                              </div>
                              {/*end::Wrapper*/}
                            </div>
                           </>)}

                        </>):(<>
                          <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                          <div className="small-spinner"></div>
                          </div>
                          </>)}

                        {/*end::Col*/}

                      </div>
                        {/*begin::Col*/}
              <div
                className="col-xl-12 mb-xl-12 mt-10"
                id="market_fields"
              >
                {currMarketplaceLoader==false?(<>
                {currMarketplaceDistro!=null?(<>
                {currMarketplaceDistro.length!=0?(<>
                {currMarketplaceDistro.length==1?(<>
                  {currMarketplaceDistro.map((valueData,index)=>(<>
                    <input type="hidden" name="stackimage" className="image_field" defaultValue={valueData} />
                 </>))}
                </>):(<>
                  <div className="fv-row mb-10 fv-plugins-icon-container">
                    <label className="form-label ">Select OS</label>
                   <select className="form-select image_field" name="stackimage">

               {currMarketplaceDistro.map((value,index)=>(<>
                    <option value={value}>{value}</option>
                 </>))}
                 </select>
                  </div>
                </>)}

                 </>):('')}

                </>):('')}
              {currMarketplace!=null?(<>
              {Object.keys(currMarketplace).length!=0?(<>

                 {currMarketplace.map((value,index)=>(<>
                  <div className="fv-row mb-10 fv-plugins-icon-container">
                    <label className="form-label ">{value.label}</label>
                    <input
                      type="text"
                      name={"stack_fields[" + index + "]["+value.name+"]"}
                      defaultValue=""
                      placeholder={value.example}
                      className="form-control form-control-lg"
                    />
                  </div>
                 </>))}
              </>):(<></>)}
              </>):(<></>)}
                </>):(<>
                      <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                      <div className="small-spinner"></div>
                      </div>
                      </>)}
              </div>
              {/*begin::Col*/}
                      {/*end::Row*/}
                    </div>
                    {/*end::Tap pane*/}
                  </div>
                  {/*end::Tab Content*/}
                </div>
                {/*end: Card Body*/}
                {/*end::List widget 10*/}
              </div>
              {/*end::Col*/}

   {/*begin::Col*/}
              <input
                  type="hidden"
                  name="planid"
                  id="plan_value"
                  defaultValue={
                    props.deployInfo && props.deployInfo
                      ? props.deployInfo[0].plan_value
                      : ""
                  }
                />
                <input
                  type="hidden"
                  id="plan_cost"
                  defaultValue={
                    props.deployInfo && props.deployInfo
                      ? props.deployInfo[0].plan_cost
                      : ""
                  }
                />
                <input
                  type="hidden"
                  id="plan_final_cost"
                  defaultValue={
                    props.deployInfo && props.deployInfo
                      ? props.deployInfo[0].plan_final_cost
                      : ""
                  }
                />

                <input type="hidden" value="" id="current_plan_id" />
                <input type="hidden" value="" id="current_plan_price" />
           {userData!=null && planData!=null && props && props.deployInfo?(<>
                <PricingContainer updatePlan={updatedPlanInfo} currBilling={props.deployInfo[0].billing_info} currPlanid={props.deployInfo[0].plan_value} user={userData} plans={planData}/>
             </>):(<>
              <div className="col-xl-12 card mb-5">
                <div className="card-header border-3 mb-5 pt-2">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Select Plan Type
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                      Basic, Dedicated CPU, Dedicated Memory
                    </span>
                  </h3>
                </div>
                <div className="container pb-5">
                <div className="small-spinner"></div>
                </div>
                </div>
             </>)}
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="card border-3 mb-5 pt-2">
                <div className="p-2 border-3 pt-2">
                {/*begin::Radio group*/}
                <div  data-kt-buttons="true" className="row">
                <div className="col-6">
    {/*begin::Radio button*/}
    <label onClick={(e)=>showAuth("password")} className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5">
      {/*end::Description*/}
      <div className="d-flex align-items-center me-2">
        {/*begin::Radio*/}
        <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
          <input
            className="form-check-input"
            type="radio"
            name="auth"
            value="option1"
            checked={selectedOption === 'option1'}
            onChange={handleOptionChange} 
          />
        </div>
        {/*end::Radio*/}
        {/*begin::Info*/}
        <div className="flex-grow-1">
          <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
             Password
          </h2>
          <div className="fw-bold opacity-50">Setup password to Cloud Instance</div>
        </div>
        {/*end::Info*/}
      </div>
      {/*end::Description*/}
    </label>
    {/*end::Radio button*/}
    </div>
    <div className="col-6">
    {/*begin::Radio button*/}
    <label onClick={(e)=>showAuth("ssh")} className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6">
      {/*end::Description*/}
      <div className="d-flex align-items-center me-2">
        {/*begin::Radio*/}
        <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
          <input
            className="form-check-input"
            type="radio"
            name="auth"
            value="option2"
            checked={selectedOption === 'option2'}
            onChange={handleOptionChange}
          />
        </div>
        {/*end::Radio*/}
        {/*begin::Info*/}
        <div className="flex-grow-1">
          <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
            SSH Keys
          </h2>
          <div className="fw-bold opacity-50">Deploy your Cloud Instance using SSH Keys</div>
        </div>
        {/*end::Info*/}
      </div>
      {/*end::Description*/}
    </label>
    {/*end::Radio button*/}
  </div>
  
  </div>
  {/*end::Radio group*/}
  </div>
                {/*begin::List widget 10*/}
                <div className="col-xl-12 card mb-5 d-none" id="ssh-sec">
                  {/*begin::Header*/}
                  <div className="card-header border-3 mb-5 pt-2 d-none">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                    SSH Keys
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                    Select SSH Keys for the server
                    </span>
                  </h3>

                  </div>

                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="container pb-5">
                    {/*begin::Radio group*/}
                    <div id="ssh-data" className="row" data-kt-buttons="true">
                      {sshData != null &&
                        sshData &&
                        sshData.map((value, index) => (
                          <>
                            <div className="col-md-4 col-lg-4 col-xxl-4 sshsibling pt-5">
                              {/*begin::Radio button*/}

                              <label
                                htmlFor={"sshkeys" + value.id}
                                onClick={() => selectTickMark(value.id, "Ssh")}
                                className="borderSsh position-relative btn btn-outline btn-outline btn-outline-default d-flex flex-stack text-start"
                                id={"borderSsh-" + value.id}
                              >
                                <input
                                  className="form-check-input deploy_checkbox"
                                  id={"sshkeys" + value.id}
                                  type="radio"
                                  name="sshkeys"
                                  defaultValue={value.id}
                                />
                                {/*end::Description*/}
                                <div className="d-flex align-items-center me-2">
                                  <div className="flex-grow-1">
                                    <div className="fw-bold opacity-50">
                                      {" "}
                                      {value.name}
                                    </div>
                                    {/* <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">

          {value.name}
        </h2> */}
                                  </div>
                                  {/*end::Info*/}
                                </div>
                                {/*end::Description*/} {/*begin::Price*/}
                                <div className="ms-5">
                                  {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                  <span className="svg-icon svg-icon-primary svg-icon-3x me-4">
                                    <svg
                                      version="1.1"
                                      id="Capa_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 299.32 299.32"
                                      style={{
                                        enableBackground:
                                          "new 0 0 299.32 299.32",
                                      }}
                                      xmlSpace="preserve"
                                    >
                                      <g>
                                        <path d="M59.52,128.087c-11.895,0-21.572,9.678-21.572,21.573s9.678,21.573,21.572,21.573s21.572-9.678,21.572-21.573 S71.414,128.087,59.52,128.087z M59.52,156.233c-3.624,0-6.572-2.949-6.572-6.573s2.948-6.573,6.572-6.573s6.572,2.949,6.572,6.573 S63.144,156.233,59.52,156.233z" />
                                        <path d="M297.349,150.704l-19.257-24.072c-1.708-2.135-4.294-3.378-7.028-3.378H151.472c-10.746-30.638-40.05-51.476-73.591-51.476 C34.938,71.778,0,106.716,0,149.66s34.938,77.882,77.881,77.882c28.516,0,53.866-14.475,67.487-38.145h21.145l18.085,18.731 c1.79,1.855,4.297,2.841,6.865,2.74c2.575-0.112,4.979-1.323,6.602-3.326l11.169-13.782l14.784,14.534 c3.48,3.422,9.056,3.445,12.564,0.053l19.604-18.95h14.878c2.734,0,5.32-1.243,7.028-3.378l19.257-24.071 C299.978,158.662,299.978,153.991,297.349,150.704z M266.738,171.397h-14.191c-2.334,0-4.577,0.907-6.255,2.529l-15.912,15.381 l-15.593-15.329c-1.811-1.778-4.28-2.706-6.817-2.568c-2.533,0.144-4.888,1.349-6.484,3.32l-11.003,13.578l-13.675-14.163 c-1.696-1.756-4.033-2.749-6.475-2.749H139.94c-3.465,0-6.622,1.989-8.118,5.115c-9.751,20.374-30.42,33.03-53.941,33.03 C44.862,209.542,18,182.679,18,149.66s26.862-59.882,59.881-59.882c27.695,0,51.613,18.362,58.167,44.653 c0.999,4.009,4.601,6.823,8.732,6.823h121.957l12.058,15.072L266.738,171.397z" />
                                      </g>
                                    </svg>
                                  </span>
                                  {/*end::Svg Icon*/}
                                </div>
                                {/*end::Price*/}

                                <div
                                  className="d-none tickMarkSsh ribbon ribbon-triangle ribbon-top-start border-primary"
                                  id={"tickMarkSsh-" + value.id}
                                >
                                  <div className="ribbon-icon mt-n5">
                                    <i className="bi bi-check2 fs-2 text-white" />
                                  </div>
                                </div>
                              </label>
                              {/*end::Radio button*/}
                            </div>
                          </>
                        ))}
                      <a
                        onClick={openDrawer}
                        className="col-md-4 col-lg-4 col-xxl-4 pt-5"
                      >
                        {/*begin::Radio button*/}
                        <label className="btn btn-outline btn-outline btn-outline-default d-flex flex-stack text-start">
                          {/*end::Description*/}
                          <div className="d-flex align-items-center me-2">
                            {/*begin::Info*/}
                            <div className="flex-grow-1">
                              <div className="fw-bold opacity-50"> Add New</div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Description*/} {/*begin::Price*/}
                          <div className="ms-5">
                            {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                            <span className="svg-icon svg-icon-3x me-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.3"
                                  x={2}
                                  y={2}
                                  width={20}
                                  height={20}
                                  rx={10}
                                  fill="black"
                                />
                                <rect
                                  x="10.8891"
                                  y="17.8033"
                                  width={12}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-90 10.8891 17.8033)"
                                  fill="black"
                                />
                                <rect
                                  x="6.01041"
                                  y="10.9247"
                                  width={12}
                                  height={2}
                                  rx={1}
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </div>
                          {/*end::Price*/}
                        </label>
                        {/*end::Radio button*/}
                      </a>
                    </div>
                    {/*end::Radio group*/}
                  </div>
                  {/*end: Card Body*/}
                </div>
                <div className="col-xl-12 card mb-5" id="password-sec">
  <div className="card-header border-3 mb-5 pt-2 d-none">
    <h3 className="card-title align-items-start flex-column">
      <span className="card-label fw-bolder text-gray-800">
        Enter Root Password
      </span>
      <span className="text-gray-400 mt-1 fw-bold fs-6">
        Input the root passowrd for your cloud instance
      </span>
    </h3>
  </div>
  <div className="container pb-5">
  <div
    className="row g-9"
    data-kt-buttons="true"
    data-kt-buttons-target="[data-kt-button]"
  >
    <div className="col-md-8 col-lg-8 col-xxl-8">

                      <div className="row mb-1">
                        <div className="col-lg-12">
                          <div className="fv-row mb-0">
                            {/* <br/> <label htmlFor="newpassword" className="form-label fs-6 fw-bolder mb-3"> Enter Password </label> */}
                            <input
                              type="password"
                              className="form-control form-control-lg "
                              name="root_password"
                              id="password"
                              onChange={(e) => validate(e.target.value)}
                              placeholder="Enter Password"
                              defaultValue=""
                            />
                          </div>
                        </div>
                      </div>
                      <span
                        className="badge badge-light-danger"
                        id="PassValid"
                      />
                      <span
                            className="text-success fw-bold d-none"
                            id="password_strength">
                            Your password is strong
                          </span>
                          <span
                            className="text-danger fw-bold d-none"
                            id="password_weak">
                           Please ensure your password meets the following requirements:
<ul>
<li>- At least 1 uppercase letter (A-Z)</li>
<li>- Alphabetic diversity (both lowercase and uppercase letters)</li>
<li>- Include numbers (0-9)</li>
<li>- 1 special character from: @ # $ % ...</li>
<li>- Minimum length of 8 characters</li>
</ul>
Example of a strong password: P@$$w0rd93@mO
                          </span>
    </div>
  </div>
  </div>
</div>
                {/*end::List widget 10*/}
                </div>

<div className="col-xl-12 card mb-5">

                    {/*begin::List widget 10*/}
                    <div className=" h-lg-100 mb-5" id="vpc_section">
                      {/*begin::Header*/}
                      <div className="card-header border-3 mb-5 pt-2">
                        {/*begin::Title*/}
                        <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                    VPC Network
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                    Select VPC for the server
                    </span>
                  </h3>

                        {/*end::Title*/}
                      </div>
                      {/*end::Header*/}
                      {/*begin::Body*/}
                      {vpcLoader==true?(<>
                        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                          <div className="small-spinner"></div>
                          </div>
                        </>):(<>
                      <div className="container">
                        {/*begin::Radio group*/}
                        <div id="getvpc" className="row" data-kt-buttons="true">
                        {vpcData != null ? (
                           <>
                          {Object.keys(vpcData).length != 0
                            ? Object.entries(vpcData).map((value, index) => (
                                <>
                                  <div className="col-md-4 col-lg-4 col-xxl-4 mt-5">
                                    {/*begin::Option*/}
                                    <input
                                      className="btn-check deploy_checkbox"
                                      type="radio"
                                      name="vpc"
                                      defaultValue={value[1].id}
                                      id={"vpc" + value[1].id}
                                    />
                                    <label
                                      htmlFor={"vpc" + value[1].id}
                                      onClick={() =>
                                        selectTickMark(value[1].id, "Vpc")
                                      }
                                      className="borderVpc btn btn-outline btn-outline btn-outline-default  d-flex align-items-center"
                                      id={"borderVpc-" + value[1].id}
                                      style={{ overflow: "hidden" }}
                                    >
                                      {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                                      <span className="svg-icon svg-icon-primary svg-icon-4x me-1">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.3"
                                            d="M19 18C20.7 18 22 16.7 22 15C22 13.3 20.7 12 19 12C18.9 12 18.9 12 18.8 12C18.9 11.7 19 11.3 19 11C19 9.3 17.7 8 16 8C15.4 8 14.8 8.2 14.3 8.5C13.4 7 11.8 6 10 6C7.2 6 5 8.2 5 11C5 11.3 5.00001 11.7 5.10001 12H5C3.3 12 2 13.3 2 15C2 16.7 3.3 18 5 18H19Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>
                                      {/*end::Svg Icon*/}
                                      <span className="d-block fw-bold text-start">
                                        <span className="text-dark fw-bolder d-block fs-3">
                                          {value[1].network}
                                        </span>
                                        <span className="text-muted fw-bold fs-8 w-100">
                                          {" "}
                                          {value[1].name}
                                        </span>
                                      </span>
                                      <div
                                        className="d-none tickMarkVpc ribbon ribbon-triangle ribbon-top-start border-primary"
                                        id={"tickMarkVpc-" + value[1].id}
                                      >
                                        <div className="ribbon-icon mt-n5">
                                          <i className="bi bi-check2 fs-2 text-white" />
                                        </div>
                                      </div>
                                    </label>
                                    {/*end::Option*/}
                                  </div>
                                </>
                              ))
                            : ""}
                            </>
                          ) : (  "" )}

                          <div className="col-md-4 col-lg-4 col-xxl-4 mt-5">
                          {/*begin::Option*/}
                          {/*begin::Radio button*/}
                          <a href="/vpc/deploy" target="_blank">
                        <label className="btn btn-outline btn-outline btn-outline-default d-flex flex-stack text-start">
                          {/*end::Description*/}
                          <div className="d-flex align-items-center me-2">
                            {/*begin::Info*/}
                            <div className="flex-grow-1">
                              <div className="fw-bold opacity-50"> Add New VPC</div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Description*/} {/*begin::Price*/}
                          <div className="ms-5">
                            {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                            <span className="svg-icon svg-icon-3x me-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.3"
                                  x={2}
                                  y={2}
                                  width={20}
                                  height={20}
                                  rx={10}
                                  fill="black"
                                />
                                <rect
                                  x="10.8891"
                                  y="17.8033"
                                  width={12}
                                  height={2}
                                  rx={1}
                                  transform="rotate(-90 10.8891 17.8033)"
                                  fill="black"
                                />
                                <rect
                                  x="6.01041"
                                  y="10.9247"
                                  width={12}
                                  height={2}
                                  rx={1}
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </div>
                          {/*end::Price*/}
                        </label>
                        </a>
                        {/*end::Radio button*/}
                          {/*end::Option*/}
                          </div>
                        </div>
                        {/*end::Radio group*/}
                      </div>
                      </>)}
                      {/*end: Card Body*/}
                    </div>
                    {/*end::List widget 10*/}

                    </div>

<div className="col-xl-12 card mb-5">
                {/*begin::List widget 10*/}
                {/*begin::Header*/}
                <div className="card-header pt-7">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Cloud Firewall &nbsp;&nbsp;
                      <a target="_blank" href="/firewall">
                        <span className="badge badge-primary">Manage</span>
                      </a>
                    </span>
                  </h3>
                  {/*end::Title*/}
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className=" row card-body">
                  <div className="col-md-6 col-lg-6 col-xxl-6">
                    <select
                      className="form-select"
                      id="get-firewall-list"
                      name="firewall"
                    >
                      <option>No Firewall Group</option>
                      {firewallData != null &&
                        firewallData &&
                        firewallData.map((value, index) => (
                          <option value={value.id}>{value.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
                {/*end: Card Body*/}
                {/*end::List widget 10*/}
                </div>

<div className="col-xl-12 card mb-5">
                {/*begin::List widget 10*/}
                {/*begin::Header*/}
                <div className="card-header border-0 mb-5 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-3 mb-1">
                      Additional Features
                    </span>
                    <span className="text-muted mt-1 fw-bold fs-7">
                      Auto Backup, Server Management
                    </span>
                  </h3>
                </div>

                {/*end::Header*/}
                {userData!=null && props && props.deployInfo?(<>
               <input type="hidden" name="enablebackup" id="set_enablebackup_field" defaultValue={props.deployInfo[0].isBackup} />
               <Backup updateBackupService={updateBackup}  isEnabled={props.deployInfo[0].isBackup} />

               <input type="hidden" name="support" id="set_support_field" defaultValue={props.deployInfo[0].select_support_option} />
               <ServerManagement updateManageService={updateServerManagement} user={userData} />
               </>):(<> <div className="container pb-5">
                <div className="small-spinner"></div>
                </div></>)}

                <input
                  type="hidden"
                  name="billingcycle"
                  // defaultValue="hourly"
                  defaultValue={props.deployInfo && props.deployInfo?(props.deployInfo[0].billing_info):('')}
                  id="billing_info"
                />

                </div>
                <div className="col-xl-12 card mb-5">
                {/*begin::List widget 10*/}
                {/*begin::Header*/}
                <div className="card-header pt-7">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start text-blue flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Server Hostname &amp; Label
                    </span>
                  </h3>
                  {/*end::Title*/}
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}

                {hostCount != null &&
                  hostCount.map((value, index) => (
                    <>
                      <div className=" row card-body">
                        <div className="col-md-6 col-lg-6 col-xxl-6">
                          <input
                            type="text"
                            className="form-control"
                            name={"cloud[" + index + "][hostname]"}
                            defaultValue={"cloudserver-" + makeid(8) + ".mhc"}
                            placeholder="host!.cloud"
                          />
                        </div>
                      </div>
                    </>
                  ))}

                {/*end: Card Body*/}
                {/*end::List widget 10*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Tables Widget 9*/}
          </form>
        </div>
        <div
          className="col-xl-3"
          // style={{
          //   position: "sticky",
          //   bottom: 0,
          //   zIndex: 99,
          //   borderTop: "outset",
          //   borderColor: "#009EF7"
          // }}
        >
          {/*begin::List widget 10*/}
          <div className="card me-5" id="sideCheckout">
            {/*begin::Body*/}
            <div className="">
              <div className=" row p-5">
                <div className=" row">
                  {/*begin::Title*/}
                  <h5 className="card-title align-items-start text-blue flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Instance Quantity
                    </span>
                  </h5>
                  {/*end::Title*/}
                  <div className="col-md-3 col-lg-12 col-xxl-3">
                    {/*begin::Dialer*/}
                    <div className="input-group w-md-150px">
                      {/*begin::Decrease control*/}
                      <a
                        className="btn btn-icon btn-outline btn-outline-secondary disabled"
                        onClick={() => {
                          updateHostCount("down");
                          cloudCost();
                        }}
                        id="hostDown"
                      >
                        <i className="bi bi-dash fs-1" />
                      </a>
                      {/*end::Decrease control*/}

                      {/*begin::Input control*/}
                      <input
                        type="text"
                        className="form-control text-center"
                        readOnly={true}
                        defaultValue={1}
                        id="total_chq"
                      />
                      {/*end::Input control*/}
                      {/*begin::Increase control*/}
                      <a
                        className="btn btn-icon btn-outline btn-outline-secondary"
                        onClick={() => {
                          updateHostCount("up");
                          cloudCost();
                        }}
                        id="hostUp"
                      >
                        <i className="bi bi-plus fs-1" />
                      </a>
                      {/*end::Increase control*/}
                    </div>
                    {/*end::Dialer*/}
                  </div>
                </div>
                <div></div>
              </div>
              {/*end: Card Body*/}

              {/*end: Card Body*/}
              <div className="border-0 p-5">
                {/* <h5 className="card-title align-items-start text-blue flex-column">
          <span className="card-label fw-bolder text-gray-800">
            Server Quantity
          </span>
        </h5> */}
                <h5 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800">
                    Cost Summary
                  </span>
                  <hr />
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2">
                    <thead>
                      <tr className="border-0">
                        <th className="p-0 min-w-100px"></th>
                        <th className="p-0 min-w-100px text-end"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr className="" id="original_server_cost">
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        <a className=" text-hover-primary fw-bold text-gray-800  d-block fs-7"><span className="">Server Size:  &nbsp;&nbsp;<span id="serverSize" className="fw-bolder">6 VCPUs, 320 GB NVMe, 16384 GB Memory, 1000 GB Bandwidth</span></span></a>
                    </div>
                </div>
            </td>
            <td className="text-start">
                <a href="#" className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7">
                  <span href="#" className="text-gray-600 fw-bolder text-hover-primary d-block mb-1 fs-7"><del>Rs.<span id="original_cost_server">91140</span></del></span>
<span className="text-gray-800 fw-bolder d-block mb-1 fs-6">Rs.<span id="plan_cost_server">68355.00</span></span>
                </a>
            </td>
        </tr>
        <tr className="" id="osImageSec">
              <td>
                  <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column">
                          <a className="text-hover-primary fw-bold text-gray-800  d-block fs-7"><span className="">OS Image:  &nbsp;<span id="osImage" className="fw-bolder">ubuntu-22.04-x86_64</span></span></a>
                      </div>
                  </div>
              </td>
              <td className="text-start">
                  <a href="#" className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7"><span id="osImageCost">Free</span></a>
              </td>
          </tr>
      <tr className="" id="serverLocationSec">
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        <a className=" text-hover-primary fw-bold text-gray-800  d-block fs-7"><span className="">Server Location:  &nbsp;<span id="serverLocation" className="fw-bolder">Noida, India</span></span></a>
                    </div>
                </div>
            </td>
            <td className="text-start">
                <a href="#" className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7"><span id="serverLocationCost">Free</span></a>
            </td>
        </tr>
        <tr className="" id="discount_server_cost">
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        <a className="text-gray-800 text-hover-primary fw-bold d-block fs-7">
                            <span className="text-gray-800">You saved (<span id="discount_offer" className="fw-bolder">25%</span>)</span>
                        </a>
                    </div>
                </div>
            </td>
            <td className="text-start">
                <a href="#" className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7 ">Rs.<span id="discount_cost_server">22785.00</span></a>
            </td>
        </tr>
        <tr className="" id="plan_server_cost">
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        <a className="text-gray-800 fw-bold text-hover-primary d-block fs-7">
                            <span className="text-gray-800"><span id="payable_tot">Billing Cycle</span> </span>
                        </a>
                    </div>
                </div>
            </td>
            <td className="text-start">
                <a href="#" className="text-gray-800 fw-bolder d-block mb-1 fs-6"><span id="plan_cost">Anually</span></a>
            </td>
        </tr>
        <tr className="" id="addon_sec">
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        <a className="text-gray-800 text-hover-primary fw-boldest d-block fs-7">
                            <span className="text-gray-800">Addon</span>
                        </a>
                    </div>
                </div>
            </td>
            <td className="text-end">

              </td>

        </tr>

        <tr className="" id="weekly_backup">
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        <a className="text-gray-800 text-hover-primary fw-bold d-block fs-7"><span className="text-gray-800">Weekly backup cost</span></a>
                    </div>
                </div>
            </td>
            <td className="text-start">
                <a href="#" className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-6">Rs.<span id="backup_weekly">13671.00</span></a>
            </td>
        </tr>
        <tr className="" id="server_management">
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        <a className="text-gray-800 text-hover-primary fw-bold d-block fs-7"><span className="text-gray-800">Server management cost</span></a>
                    </div>
                </div>
            </td>
            <td className="text-start">
                <a href="#" className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-6">Rs.<span id="management_server">47940.00</span></a>
            </td>
        </tr>

        <tr>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        <a className="text-gray-800 text-hover-primary fw-bold d-block fs-7"><span className="text-gray-800">Payable Amount</span></a>
                    </div>
                </div>
            </td>
            <td className="text-start">
                <a href="#" className="text-primary fw-bolder text-hover-primary d-block mb-1 fs-6 sphide">Rs.<span id="order_total">153359.88</span></a>
            </td>
        </tr> */}

                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <a className="text-gray-800 text-hover-primary fw-bold text-muted d-block fs-7">
                                <span className="text-gray-800">
                                  Billing Cycle
                                </span>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <a className="fw-bolder badge badge-success  d-block mb-1 fs-7">
                              <span id="billing_cycle_value"></span>
                          </a>
                        </td>
                      </tr>
                      <tr className="d-none" id="original_server_cost">
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <a className="text-gray-800 text-hover-primary fw-bold text-muted d-block fs-7">
                                <span className="text-gray-800">
                                  Original cost
                                </span>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7">
                            <del>
                              {userData != null && userData.currencyprefix
                                ? userData.currencyprefix
                                : ""}
                              <span id="original_cost_server"></span>
                            </del>
                          </a>
                        </td>
                      </tr>
                      <tr className="d-none" id="discount_server_price">
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <a className="text-gray-800 text-hover-primary fw-bold text-muted d-block fs-7">
                                <span className="text-gray-800 me-3 d-none">
                                  Discount
                                </span>
                                <span className="badge badge-success">
                                <span id="discount_offer"></span>
                                 % saved
                                </span>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7">
                            {userData != null && userData.currencyprefix
                              ? userData.currencyprefix
                              : ""}
                            <span id="discount_price"></span>
                          </a>
                        </td>
                      </tr>
                      <tr className="d-none" id="instance_cost_sec">
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <a className="text-gray-800 text-hover-primary fw-bold d-block fs-7">
                                <span className="text-gray-800">Instance Cost </span>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7">
                            {userData != null && userData.currencyprefix
                              ? userData.currencyprefix
                              : ""}
                            <span id="instance_cost"></span>
                          </a>
                        </td>
                      </tr>
                      <tr className="d-none" id="weekly_backup">
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <a className="text-gray-800 text-hover-primary fw-bold d-block fs-7">
                                <span className="text-gray-800">
                                  Weekly backup cost
                                </span>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7">
                            {userData != null && userData.currencyprefix
                              ? userData.currencyprefix
                              : ""}
                            <span id="weekly_backup_cost"></span>
                          </a>
                        </td>
                      </tr>
                      <tr className="d-none" id="os_price_sec">
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <a className="text-gray-800 text-hover-primary fw-bold d-block fs-7">
                                <span className="text-gray-800">OS cost</span>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7">
                            {userData != null && userData.currencyprefix
                              ? userData.currencyprefix
                              : ""}
                            <span id="os_cost_price"></span>
                          </a>
                        </td>
                      </tr>
                      <tr className="d-none" id="server_management">
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <a className="text-gray-800 text-hover-primary fw-bold d-block fs-7">
                                <span className="text-dark">
                                  Server management
                                </span>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-7">
                            {userData != null && userData.currencyprefix
                              ? userData.currencyprefix
                              : ""}
                            <span id="management_server"></span>
                          </a>
                        </td>
                      </tr>
                      <tr className="d-none" id="configured_instance_sec">
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <a className="text-gray-800 text-hover-primary fw-bolder d-block fs-5 sphide">
                                <span className="text-dark">
                                  Subtotal
                                </span>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-5">
                            {userData != null && userData.currencyprefix
                              ? userData.currencyprefix
                              : ""}
                            <span id="configured_instance_cost"></span>
                          </a>
                        </td>
                      </tr>
                      <tr className="d-none" id="instance_calc_sec">

                        <td colSpan={12} className="text-center">
                          <a className="text-gray-800 fw-bolder text-hover-primary d-block mt-1 mb-1 fs-7">
                             ( Subtotal <span className="me-1 ms-1 text-gray-800">x</span> Instance Count )
                          </a>
                        </td>
                      </tr>
                      <tr className="d-none" id="final_instance_cost_sec">
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <a className="text-gray-800 text-hover-primary fw-bolder d-block fs-5 sphide">
                                <span className="text-dark">
                                 Final Cost
                                </span>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <a className="text-gray-800 fw-bolder text-hover-primary d-block mb-1 fs-5">
                          {userData != null && userData.currencyprefix
                              ? userData.currencyprefix
                              : ""}<span id="final_instance_cost"></span>
                            <span className="me-1 ms-1 text-gray-800">x</span>
                             <span id="final_instance_count"></span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span className="mt-1 fw-bolder fs-5">
                    Total{" "}
                    <span
                      className="card-label fw-bolder fs-5 mb-1"
                      // style={{ color: "#328cf1" }}
                    >
                      <span>
                        {user_data.current != null
                          ? user_data.current.user.currencyprefix
                          : ""}
                      </span>{" "}
                      <span className="fw-bolder fs-5 "  id="order_total">
                        0.00
                      </span>
                      <span className="badge badge-primary" />
                    </span>
                    <span>
                    &nbsp; &nbsp; (
                      <span>

                        {user_data.current != null
                          ? user_data.current.user.currencyprefix
                          : ""}
                      </span>
                      <span id="order_toatl_hr">0.000</span>/hr)
                    </span>
                  </span>
                </h5>
                <div className="card-toolbar w-100">
                  <a
                    onClick={deployCloudServer}
                    className="btn btn-sm btn-primary w-100"
                    id="deploy-btn"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                          fill="black"
                        />
                        <path
                          opacity="0.3"
                          d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}Deploy Now
                  </a>
                </div>
              </div>
            </div>
            {/*end: Card Body*/}
          </div>
          {/*end::List widget 10*/}
        </div>
      </div>
     </>):( <>
     <TopBarHeader />
        {/* <div class="loading-spinner2"></div> */}
        {/* <div style={{zIndex: "109"}} id="overlay_id" className="drawer-overlay"></div> */}
      </>)}
      <div
        id="importkey-modal"
        className="bg-body shadow drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-name="chat"
        data-kt-drawer-activate="true"
        data-kt-drawer-direction="end"
        data-kt-drawer-close="#close_ssh"
        data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
        style={{ maxWidth: "70%", important: true }}
      >
        {/*begin::Messenger*/}
        <div
          className="card rounded-0 w-100 border-0"
          id="kt_drawer_chat_messenger"
        >
          {/*begin::Card header*/}
          <div
            className="card-header pe-5"
            id="kt_drawer_chat_messenger_header"
          >
            {/*begin::Title*/}
            <div className="card-title">
              {/*begin::User*/}
              <div className="d-flex justify-content-center flex-column me-3">
                <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                  Add New SSH Key
                </a>
              </div>
              {/*end::User*/}
            </div>
            {/*end::Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Close*/}
              <div
                onClick={openDrawer}
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="close_ssh"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="" id="">
            {/*begin::Form*/}
            <form name="importkey" id="importkey" className="form">
              {/*begin::Modal body*/}
              <div className="modal-body ">
                {/*begin::Scroll*/}
                <div
                  className="scroll-y me-n7 pe-7"
                  id="kt_modal_create_api_key_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                  data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                  data-kt-scroll-offset="300px"
                >
                  {/*begin::Input group*/}
                  <div className="mb-5 fv-row">
                    {/*begin::Label*/}
                    <label className="required fs-5 fw-bold mb-2"> Name</label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <input
                      type="text"
                      className="form-control  "
                      placeholder="Name"
                      name="name"
                    />
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="d-flex flex-column mb-5 fv-row">
                    {/*begin::Label*/}
                    <label className="required fs-5 fw-bold mb-2">
                      SSH Key Content
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <textarea
                      className="form-control  "
                      type="text"
                      rows={3}
                      name="sshkey"
                      placeholder="SSH"
                      defaultValue={""}
                    />
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}
                </div>
                {/*end::Scroll*/}
              </div>
              {/*end::Modal body*/}
              {/*begin::Modal footer*/}
              <div className="modal-footer ">
                {/*begin::Button*/}
                <button
                  type="button"
                  onClick={importSshKey}
                  className="btn btn-primary"
                >
                  <span className="indicator-label">Import SSH Key</span>
                </button>
                {/*end::Button*/}
              </div>
              {/*end::Modal footer*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Messenger*/}
      </div>

      {/* Invoice start */}

      <div
        id="invoice_drawer"
        className="bg-white drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-activate="true"
        data-kt-drawer-width="1200px"
        style={{ width: "1200px !important" }}
      >
        <div className=" container-fluid">
          {/*begin::Body*/}
          <div className=" p-5">
            {/*begin::Layout*/}
            <div className="d-flex flex-column flex-xl-row">
              {/*begin::Content*/}
              <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                {/*begin::Invoice 2 content*/}
                <div className="mt-n1">
                  {/*begin::Top*/}
                  <div className="d-flex flex-stack pb-10">
                    {/*begin::Logo*/}
                    <a>
                      <img
                        alt="Logo"
                        src="https://www.microhost.com/images/logo-dark-registered-cloud-blue.png"
                        width="200px"
                      />
                    </a>
                    {/*end::Logo*/}
                    {/*begin::Action*/}
                    <div id="payinvoice" />
                    <div id="paidinvoice" className="">
                      {invoiceData != null && invoiceData.status == "Paid" ? (
                        <>
                          <button className="btn btn-success me-5">Paid</button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-success me-5"
                            id="invoice-payment-button"
                            onClick={invoicePayment}
                          >
                            Pay
                          </button>
                        </>
                      )}

                      <button
                        className="btn btn-light-danger me-5"
                        onClick={openInvoice}
                      >
                        close
                      </button>
                    </div>

                    {/*end::Action*/}
                  </div>
                  {/*end::Top*/}
                  {/*begin::Wrapper*/}
                  <div className="m-0">
                    {/*begin::Label*/}
                    <div className="fw-bolder fs-3 text-gray-800 mb-8">
                      Invoice{" "}
                      <span>
                        {invoiceData != null && invoiceData.id
                          ? invoiceData.id
                          : ""}
                      </span>
                    </div>
                    {/*end::Label*/}
                    {/*begin::Row*/}
                    <div className="row g-5 mb-11">
                      {/*end::Col*/}
                      <div className="col-sm-6">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issue Date:
                        </div>
                        {/*end::Label*/}
                        {/*end::Col*/}
                        <div
                          className="fw-bolder fs-6 text-gray-800"
                          id="invoice-date"
                        >
                          {invoiceData != null && invoiceData.date
                            ? invoiceData.date
                            : ""}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Col*/}
                      {/*end::Col*/}
                      <div className="col-sm-6"></div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row g-5 mb-12">
                      {/*end::Col*/}
                      <div className="col-sm-6">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issue For:
                        </div>
                        {/*end::Label*/}
                        {/*end::Text*/}
                        <div
                          className="fw-bolder fs-6 text-gray-800"
                          id="company_name"
                        >
                          {userData != null && userData.company
                            ? userData.company
                            : ""}
                        </div>
                        {/*end::Text*/}
                        {/*end::Description*/}
                        <div className="fw-bold fs-7 text-gray-600">
                          <span id="user-address">
                            {userData != null && userData.address
                              ? userData.address + ", "
                              : ""}{" "}
                            {userData != null && userData.city
                              ? userData.city + ", "
                              : ""}{" "}
                            {userData != null && userData.country
                              ? userData.country + ", "
                              : ""}
                          </span>
                          <br />
                          <span id="user-mobile">
                            {userData != null && userData.mobile
                              ? userData.mobile
                              : ""}
                          </span>
                          <br />
                          <span id="user-gst">
                            {userData != null && userData.gstnumber
                              ? userData.gstnumber
                              : ""}
                          </span>
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Col*/}
                      {/*end::Col*/}
                      <div className="col-sm-6 text-end">
                        {/*end::Label*/}
                        <div className="fw-bold fs-7 text-gray-600 mb-1">
                          Issued By:
                        </div>
                        {/*end::Label*/}
                        {/*end::Text*/}
                        <div className="fw-bolder fs-6 text-gray-800">
                          Micro Hosting Private Limited
                        </div>
                        {/*end::Text*/}
                        {/*end::Description*/}
                        <div className="fw-bold fs-7 text-gray-600">
                          MicroHost Cloud
                          <br />
                          A-43, Sector 63, Noida 201301 UP - India
                          <br />
                          <strong>GSTIN:</strong> 09AAICM9463A1ZB
                          <br />
                          <strong>PAN Number:</strong> AAICM9463A
                          <br />
                          <strong>SAC:</strong> 998315
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Content*/}
                    <div className="flex-grow-1">
                      {/*begin::Table*/}
                      <div className="table-responsive border mb-9">
                        <table className="table mb-3 table-row-bordered">
                          <thead className="bg-secondary">
                            <tr className="border-bottom fs-6 fw-bolder">
                              <th className="min-w-10px" />
                              <th className="min-w-175px pb-2 ">
                                Item Description
                              </th>
                              <th className="min-w-70px text-end pb-2" />
                              <th className="min-w-80px text-end pb-2" />
                              <th className="min-w-100px text-start pb-2">
                                Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody id="item-list">
                            {invoiceData != null
                              ? invoiceData.items != null
                                ? invoiceData.items.map((value, index) => (
                                    <>
                                      <tr className="fw-bold text-gray-700">
                                        <td></td>
                                        <td className="d-flex align-items-center pt-6">
                                          {value.description}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td className="pt-6 fw-bold">
                                          {userData != null &&
                                          userData.currencyprefix
                                            ? userData.currencyprefix
                                            : ""}{" "}
                                          {value.amount}
                                        </td>
                                      </tr>
                                    </>
                                  ))
                                : ""
                              : ""}
                          </tbody>
                        </table>
                      </div>
                      {/*end::Table*/}
                      {/*begin::Container*/}
                      <div className="d-flex row">
                        <div className="col-lg-8 col-md-8 col-xxl-8">
                          {invoiceData != null &&
                          invoiceData.status != "Paid" ? (
                            <>
                              <div
                                className="d-flex flex-wrap flex-stack mb-6"
                                id="invoice-credit"
                              >
                                {" "}
                                <div className="d-flex my-2">
                                  {" "}
                                  <div className="d-flex align-items-center position-relative me-4">
                                    {" "}
                                    <form id="applycredit" name="applycredit">
                                      {" "}
                                      <div
                                        className="input-group mb-5"
                                        id="wallet"
                                      >
                                        <input
                                          type="hidden"
                                          name="invoiceid"
                                          value={
                                            invoiceData != null && invoiceData
                                              ? invoiceData.id
                                              : ""
                                          }
                                        />
                                        <span className="input-group-text">
                                          {userData != null &&
                                          userData.currencyprefix
                                            ? userData.currencyprefix
                                            : ""}
                                        </span>{" "}
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="amount"
                                          id="amount"
                                          name="amount"
                                          min="10"
                                          value={
                                            invoiceData != null &&
                                            invoiceData.rowtotal
                                              ? invoiceData.rowtotal
                                              : ""
                                          }
                                        />
                                        <a
                                          className="btn btn-primary btn-sm"
                                          id="applycredit-btn"
                                          onClick={applyCreditAmount}
                                        >
                                          Apply Credit
                                        </a>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="col-lg-4 col-md-4 col-xxl-4">
                          {/*begin::Section*/}
                          <div className="mw-300px" id="invoice-final">
                            {/*begin::Item*/}{" "}
                            <div className="d-flex flex-stack mb-3">
                              {/*begin::Accountname*/}
                              <div className="fw-bold pe-10 text-gray-600 fs-7">
                                Subtotal:
                              </div>
                              {/*end::Accountname*/}
                              {/*begin::Label*/}
                              <div
                                className="text-end fw-bolder fs-6 text-gray-800"
                                id="subtotal-amount"
                              >
                                {invoiceData != null && invoiceData.subtotal
                                  ? invoiceData.subtotal
                                  : ""}
                              </div>
                              {/*end::Label*/}
                            </div>
                            {/*end::Item*/}
                            {invoiceData != null &&
                            invoiceData.taxrate != "0.00" &&
                            invoiceData.taxrate2 == "0.00" ? (
                              <>
                                {/*begin::Item*/}{" "}
                                <div className="d-flex flex-stack mb-3">
                                  {/*begin::Accountname*/}
                                  <div className="fw-bold pe-10 text-gray-600 fs-7">
                                    {invoiceData.taxrate}% IGST
                                  </div>
                                  {/*end::Accountname*/}
                                  {/*begin::Label*/}
                                  <div className="text-end fw-bolder fs-6 text-gray-800">
                                    {userData != null && userData.currencyprefix
                                      ? userData.currencyprefix
                                      : ""}{" "}
                                    {invoiceData.tax}
                                  </div>
                                  {/*end::Label*/}
                                </div>
                                {/*end::Item*/}
                              </>
                            ) : (
                              ""
                            )}
                            {invoiceData != null &&
                            invoiceData.taxrate != "0.00" &&
                            invoiceData.taxrate2 != "0.00" ? (
                              <>
                                {/*begin::Item*/}{" "}
                                <div className="d-flex flex-stack mb-3">
                                  {/*begin::Accountname*/}
                                  <div className="fw-bold pe-10 text-gray-600 fs-7">
                                    {invoiceData.taxrate}% CGST
                                  </div>
                                  {/*end::Accountname*/}
                                  {/*begin::Label*/}
                                  <div className="text-end fw-bolder fs-6 text-gray-800">
                                    {userData != null && userData.currencyprefix
                                      ? userData.currencyprefix
                                      : ""}{" "}
                                    {invoiceData.tax2}
                                  </div>
                                  {/*end::Label*/}
                                </div>
                                {/*end::Item*/}
                              </>
                            ) : (
                              ""
                            )}
                            {invoiceData != null &&
                            invoiceData.taxrate2 != "0.00" ? (
                              <>
                                {/*begin::Item*/}{" "}
                                <div className="d-flex flex-stack mb-3">
                                  {/*begin::Accountname*/}
                                  <div className="fw-bold pe-10 text-gray-600 fs-7">
                                    {invoiceData.taxrate}% SGST
                                  </div>
                                  {/*end::Accountname*/}
                                  {/*begin::Label*/}
                                  <div className="text-end fw-bolder fs-6 text-gray-800">
                                    {userData != null && userData.currencyprefix
                                      ? userData.currencyprefix
                                      : ""}{" "}
                                    {invoiceData.tax2}
                                  </div>
                                  {/*end::Label*/}
                                </div>
                                {/*end::Item*/}
                              </>
                            ) : (
                              ""
                            )}
                            {invoiceData != null &&
                            invoiceData.credit != "0" ? (
                              <>
                                {/*begin::Item*/}{" "}
                                <div className="d-flex flex-stack mb-3">
                                  {/*begin::Accountname*/}
                                  <div className="fw-bold pe-10 text-gray-600 fs-7">
                                    Credits
                                  </div>
                                  {/*end::Accountname*/}
                                  {/*begin::Label*/}
                                  <div className="text-end fw-bolder fs-6 text-gray-800">
                                    {userData != null && userData.currencyprefix
                                      ? userData.currencyprefix
                                      : ""}{" "}
                                    {invoiceData.credit}
                                  </div>
                                  {/*end::Label*/}
                                </div>
                                {/*end::Item*/}
                              </>
                            ) : (
                              ""
                            )}
                            {/*begin::Item*/}{" "}
                            <div className="d-flex flex-stack mb-3">
                              {/*begin::Accountname*/}
                              <div className="fw-bold pe-10 text-gray-600 fs-7">
                                Total
                              </div>
                              {/*end::Accountname*/}
                              {/*begin::Label*/}
                              <div
                                className="text-end fw-bolder fs-6 text-gray-800"
                                id="total-amount"
                              >
                                {userData != null && userData.currencyprefix
                                  ? userData.currencyprefix
                                  : ""}{" "}
                                {invoiceData != null && invoiceData.total}
                                <input
                                  type="hidden"
                                  id="invoiceAmountFund"
                                  value={
                                    invoiceData != null && invoiceData.total
                                  }
                                />
                                <input
                                  type="hidden"
                                  id="invoice_id"
                                  value={invoiceData != null && invoiceData.id}
                                />
                              </div>
                              {/*end::Label*/}
                            </div>
                            {/*end::Item*/}
                          </div>
                        </div>
                        {/*end::Section*/}
                      </div>
                      {/*end::Container*/}
                      <div id="trans-tabel" className="d-none">
                        <div className="fw-bolder fs-2">Transactions</div>
                        <table className="table mb-3 table-row-bordered">
                          <thead className="bg-secondary">
                            <tr className="border-bottom fs-6 fw-bolder">
                              <th className="min-w-175px pb-2 text-center">
                                Transaction Date
                              </th>
                              <th className="min-w-70px text-end pb-2">
                                Transaction Gateways
                              </th>
                              <th className="min-w-80px text-end pb-2">
                                Transaction id
                              </th>
                              <th className="min-w-100px text-center pb-2">
                                Transaction Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            className="fw-bold text-gray-600"
                            id="trans-list"
                          >
                            {invoiceData != null &&
                              invoiceData.transs.map((value, index) => (
                                <>
                                  <tr>
                                    <td className="text-center">
                                      {value.date}
                                    </td>
                                    <td className="text-end">
                                      {value.gateway}
                                    </td>
                                    <td className="text-end">
                                      {value.transid}
                                    </td>
                                    <td className="text-center">
                                      {userData != null &&
                                      userData.currencyprefix
                                        ? userData.currencyprefix
                                        : ""}{" "}
                                      {value.amountin
                                        ? value.amountin
                                        : value.amountout}
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </tbody>
                        </table>
                        {/*end::Table*/}
                        <div id="due-price" style={{ marginTop: 25 }}>
                          <div className="d-flex justify-content-end">
                            <div className="mw-300px" id="invoice-final">
                              <div className="d-flex flex-stack mb-3">
                                <div className="fw-bold pe-10 text-danger-600 fs-7">
                                  Due Amount
                                </div>
                                <div className="text-end fw-bolder fs-6 text-danger-800">
                                  {userData != null && userData.currencyprefix
                                    ? userData.currencyprefix
                                    : ""}{" "}
                                  {invoiceData != null
                                    ? invoiceData.balance
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Invoice 2 content*/}
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Layout*/}
          </div>
          {/*end::Body*/}
        </div>
      </div>

      {/* Invoice end */}
    </>
  );
};

const mapStateToProps = (state) => ({
  hostCountData: state.cloudDeployData.hostCountDataInfo,
  deployInfo: state.cloudDeployData.deployDataInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updateHostCountData: (data) => dispatch(AddHostCount(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CloudDeploy);
