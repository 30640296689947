import React, { useEffect, useRef, useState } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import swal from "sweetalert";     
import Swal from 'sweetalert2';
import copy from "copy-to-clipboard"; 
import NotFound from "../NotFound";
import UseApi from "../../../../CustomHooks/useApi"; 
import Database from "./tabs/database/Database";
import DatabaseUser from "./tabs/databaseUser/DatabaseUser";
import AccessIp from "./tabs/accessIp/AccessIp";

export default function ManageDatabaseCluster(){
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4]; 
  function datbaseName(str) {
    // Split the string into an array using the delimiter "-"
    var arr = str.split("-");
    
    // Return the element at the 0th index
    return arr[0].toLowerCase();
  }
  const togglePassword=(action)=>{
    // console.log("dfg");
    var hide_passwd=document.querySelector("#hide_passwd");
    var show_passwd=document.querySelector("#show_passwd");
    if(hide_passwd && show_passwd){
       if(action=="hide"){
        hide_passwd.classList.remove("d-none");
        show_passwd.classList.add("d-none");
       }else{
        hide_passwd.classList.add("d-none");
        show_passwd.classList.remove("d-none");
       }
    }
  }

  const copyToClipboard=(target,targetBy)=> {   
       
    var btn_id = document.querySelector("#"+targetBy);
    var target_id = document.querySelector("#"+target);
    if(btn_id){
      var copyText=btn_id.innerHTML;
      var copyEle=target_id.innerHTML;
    copy(copyEle);  
    btn_id.innerHTML="Copied"; 
    btn_id.classList.add("text-success");
    setTimeout(() => { 
      btn_id.innerHTML=copyText;
      btn_id.classList.remove("text-success");
    }, 2000);
  }
   
  }


  const navigate=useNavigate();
  const navRedirect=()=>{
    setTimeout(() => {
      navigate('/database-cluster');
     }, 2000);
      
  }
const dataFetchedRef=useRef(false);  
const [databaseInfo, setDatabaseInfo] = useState(null);
 
//  console.log(databaseInfo);
const getDatabaseClusterList=async()=>{   
  const data=await UseApi('get','databases/'+id,'');

  if(data.rcode=="success"){
    setDatabaseInfo(data);
  }else{
    setDatabaseInfo([]);
  }
  // console.log(data);
}  

  useEffect(()=>{  
    if(dataFetchedRef.current) return;
      dataFetchedRef.current=true; 
      getDatabaseClusterList();   
  },[]);  

  const deleteDatabase=async(cluster_name)=>{
    toast.dismiss(); 
      const { value: isDelete } = await Swal.fire({
        title: 'Delete Cluster',
        text: 'Enter the cluster name to delete:',
        input: 'text',
        inputPlaceholder: 'Cluster Name',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        customClass: {
            confirmButton: 'delete-button bg-danger', // Add your custom class here
          },
      }); 
   
      if(isDelete==cluster_name) {
        const dt=toast.loading("Database deleting..."); 
    const dnsp=await UseApi('DELETE','databases/'+id+'?confirm='+cluster_name,'');
    //console.log(dnsp);
    if(dnsp.status=='error'){
      toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else if(dnsp.status=='success'){
      toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });  
    navRedirect();
    }
      }else{
        swal({
              title: "Invalid Database Cluster Name",
              text: "Please confirm the valid database cluster!",
              icon: "warning",
              button: "OK",
            });
      }
} 

  if(databaseInfo!=null){
    if(databaseInfo.length!=0){
      // console.log(databaseInfo);
  return (
    <>
      <div className="card mb-6 mb-xl-9 ">
  <div className="card-body pt-9 pb-0 ">
    <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
      <div
        className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4"
        id="imageDist"
      >
       <span className="w-50">
       <img src={"/assets/media/databases/"+databaseInfo.engine+".svg"} />

</span>

      </div>
      <div className="flex-grow-1">
        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
          <div className="d-flex flex-column">
            
            <div className="d-flex align-items-center mb-1" id="showHost">
              <a
                className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3"
                id="hostname"
              >
               {databaseInfo.cluster_name}
              </a>
              <a>
               
              </a>
            </div>
            <div className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400">
              {databaseInfo.size}
               
            </div>
          </div>
          <div className="d-flex" id="console">
          <div className="d-flex flex-stack mb-5">
  {/*begin::Section*/}
  <div className="d-flex align-items-center me-5" style={{paddingRight:"28px",borderRight:"3px solid #b5b5c3"}}>
    {/*begin::Symbol*/}
    <div className="symbol symbol-50px me-8">
      <div className="">
      {databaseInfo.dclocation?(<> 
                            <img
                            src={"/img/flag_"+databaseInfo.dclocation.dccc+".png"}
                            className="h-50 rounded"
                            alt=""
                          />
                 </>):('')}
      </div>
    </div>
    {/*end::Symbol*/}
    {/*begin::Title*/}
    <div>
        <a
          className="fs-6 text-gray-800 text-hover-primary fw-bolder"
        >
          {databaseInfo.dclocation?(<> 
            {databaseInfo.dclocation.location}
    </>):('')}
        </a>
        <div className="fs-7 text-muted fw-bold mt-1">
        {databaseInfo.dclocation?(<> 
            {databaseInfo.dclocation.country}
    </>):('')}
        </div>
    </div>
    {/*end::Title*/}
  </div>
  {/*end::Section*/}
  {/*begin::Label*/}
  {databaseInfo.status=="Active"?(<><div className="btn btn-success fw-bold ms-5">Active</div></>):
                    (<><div className="btn btn-warning fw-bold ms-5">Deploying.... </div></>)}
  {/*end::Label*/}
</div> 
          </div>
        </div>
      </div>
    </div>
    <div className="separator" />
    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
      <li className="nav-item">
        <a
          className="nav-link text-active-primary py-5 me-6 active sphide"
          data-bs-toggle="tab"
          href="#connection-details"
        >
          Connection Details
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link text-active-primary py-5 me-6 d sphide"
          data-bs-toggle="tab"
          href="#databases"
        >
          Databases
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link text-active-primary py-5 me-6"
          data-bs-toggle="tab"
          href="#database_user"
        >
          Database users
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link text-active-primary py-5 me-6"
          data-bs-toggle="tab"
          href="#access_ip"
        >
          Access IP
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link text-active-primary py-5 me-6"
          data-bs-toggle="tab"
          href="#destroy"
        >
          Destroy
        </a>
      </li> 
    </ul>
  </div>
</div>

<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade active show" id="connection-details" role="tabpanel">
  <div className="card pt-4 mb-6 mb-xl-9">
  {/*begin::Card header*/}
  <div className="card-header border-0">
    {/*begin::Card title*/}
    <div className="card-title">
      <h2>Connection Details</h2>
    </div>
    {/*end::Card title*/}
  </div>
  {/*end::Card header*/}
  {/*begin::Card body*/}
  <div className="card-body pt-0 pb-5">
    {/*begin::Table wrapper*/}
    <div className="table-responsive">
      {/*begin::Table*/}
      <table
        className="table align-middle table-row-dashed gy-5"
        id="kt_table_users_login_session"
      >
        {/*begin::Table body*/}
        <tbody className="fs-6 fw-bold text-gray-600">
          <tr>
            <td>Primary Cluster IP</td>
            <td> <span id="cluster_ip">{databaseInfo.primary_ip}</span>
            <span id="cluster_ipBtn" onClick={(e)=>copyToClipboard('cluster_ip','cluster_ipBtn')} className="svg-icon svg-icon-2 ms-5">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="#eff2f5"
  >
    <path
      opacity="0.5"
      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
      fill="#eff2f5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
      fill="#eff2f5"
    />
  </svg>
</span></td>
             
          </tr>
          {databaseInfo.replica_ips.map((value, index)=>(<>
            <tr>
            <td>Cluster {index+2}</td>
            <td>
            <span id={"cluster_ip"+index}> {value}</span>
            <span id={"cluster_ipBtn"+index} onClick={(e)=>copyToClipboard('cluster_ip'+index,'cluster_ipBtn'+index)} className="svg-icon svg-icon-2 ms-5">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="#eff2f5"
  >
    <path
      opacity="0.5"
      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
      fill="#eff2f5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
      fill="#eff2f5"
    />
  </svg>
</span>
            </td>
          </tr>
          </>))}
          
          <tr>
            <td>Port</td>
            <td>{databaseInfo.port}</td>
          </tr>
          <tr className="d-none">
            <td>SSL</td>
            <td>-</td>
          </tr>
        </tbody>
        {/*end::Table body*/}
      </table>
      {/*end::Table*/}
    </div>
    {/*end::Table wrapper*/}
  </div>
  {/*end::Card body*/}
</div>

  </div>
  <div className="tab-pane fade" id="databases" role="tabpanel">
    <Database data={databaseInfo} updateDatabase={getDatabaseClusterList} />
  </div> 
  <div className="tab-pane fade" id="database_user" role="tabpanel">
    <DatabaseUser data={databaseInfo} updateDatabase={getDatabaseClusterList} />
  </div> 
  <div className="tab-pane fade" id="access_ip" role="tabpanel">
  <AccessIp data={databaseInfo} updateDatabase={getDatabaseClusterList} />
  </div> 
  <div className="tab-pane fade" id="destroy" role="tabpanel">
    <div className="card">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_deactivate"
        aria-expanded="true"
        aria-controls="kt_account_deactivate"
      >
        <div className="card-title m-0">
          <h3 className="fw-bold m-0">Destroy Cluster</h3>
        </div>
      </div>
      <div id="kt_account_settings_deactivate" className="collapse show">
        <form id="destroyform" className="form">
          <div className="card-body border-top p-9">
            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
              <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.3"
                    x={2}
                    y={2}
                    width={20}
                    height={20}
                    rx={10}
                    fill="black"
                  />
                  <rect
                    x={11}
                    y={14}
                    width={7}
                    height={2}
                    rx={1}
                    transform="rotate(-90 11 14)"
                    fill="black"
                  />
                  <rect
                    x={11}
                    y={17}
                    width={2}
                    height={2}
                    rx={1}
                    transform="rotate(-90 11 17)"
                    fill="black"
                  />
                </svg>
              </span>
              <div className="d-flex flex-stack flex-grow-1">
                <div className="fw-bold">
                  <h4 className="text-gray-900 fw-bold">
                    This will destroy your Cluster.
                  </h4>
                  <div className="fs-6 text-gray-700">
                    Note: This option will delete your Cluster from the server for
                    permanently and this not be able to undo.
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="mb-10">
              <label htmlFor="size" className="required form-label">
              Please fill text in below form to delete server "I am aware this
                action will delete database cluster permanently"
              </label>
              <input
                type="text"
                className="form-control  "
                name="confirm"
                id="confirm"
                defaultValue=""
                placeholder="I am aware this action will delete database cluster permanently"
              />
            </div> */}
          </div>
          <div
            className="card-footer d-flex justify-content-end py-6 px-9"
            id="destroy_btn"
          >
            <a
              id="destroy-btn" 
              onClick={(e)=>deleteDatabase(databaseInfo.cluster_name)}
              className="btn btn-danger fw-bold"
            > 
            
              Destroy Cluster
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>




    </>
  );
  }else{
    return (<>
      <NotFound />
      </>)
  }
  }else{
    return (
      <>
      <div class="loading-spinner2"></div>
      </>
    )
  }
}

