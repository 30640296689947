import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import UsePost from "../../../../CustomHooks/usePost";
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";

function useForceUpdate(){
  const [value, setValue] = useState(0);  
  return () => setValue(value => value + 1); 
}

export default function Iso(){
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess]=useState(true);
  const [perPage, setPerPage]=useState(5);
  const forceUpdate = useForceUpdate();
  const [dataCenter, setDataCenter]=useState([]);
  const [isoData,setIsoData]=useState(null);
  const dataFetchedRef=useRef (false);
  const dataFetchedRef2=useRef (false);
  const navigate=useNavigate();
  const navRedirect=()=>{
    setTimeout(() => {
      navigate('/iso');
     }, 3000);
      
  }
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }
  const addISO=async()=>{
    document.querySelector("#add_iso").classList.add("d-none");
    // const isoBtn=document.querySelector("#add_iso");
    // if(isoBtn){
    //   isoBtn.classList.add("d-none");
    // }
    const dt=toast.loading("Please wait...");
    const responseData=await UsePost('post','iso/add','addisos');
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      document.querySelector("#add_iso").classList.remove("d-none");
      getIso();
      navRedirect();
        
      }
    }

  const getIso=async()=>{
    
    const dt=toast
       const data=await UseApi('get','iso','');
       if(data){
        setDataFetched(true);
       }
       if('status' in data){
        if(data.status=="error"){ 
           toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });  
          setAccess(false);
          setIsoData(null); 
          loaderFunc(); 
          return;
        }
     }
       if(Object.keys(data).length === 0){
        
        setIsoData(null);
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            }); 
            loaderFunc();
       }else{
        setIsoData(data.isos);  
        toast.dismiss();
          //    toast.update(dt,{render: "Your ISO request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          //  });  
          
       }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getIso(); 
  },[]);  
  

  const deleteIso=async(isoFile)=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this ISO file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("ISO deleting..."); 
    const dnsp=await UseApi('DELETE','iso/'+isoFile+'/delete','');
    if(dnsp.status=='error'){
      toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else{
      toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    }); 
    getIso();
    navRedirect();
    }
      } 
      });
  }

  const getDczone=async()=>{
    // setAdcdata([]);
    // setDdcdata([]);
       const data=await UseApi ('get','dczones',''); 
       //console.log(data);
       if(Object.keys(data).length === 0){ 
        setDataCenter([]);
       }else{ 
        data.dczones.map((value,index)=>{ 
          dataCenter.push(value);  
        })
        forceUpdate();
       }
  }
  
  useEffect(()=>{
    if (dataFetchedRef2.current) return;
      dataFetchedRef2.current = true;
      getDczone();   
  },[]); 
 
  function ProductInfo({ productdata }) { 
    return(
        <>
       
       
        {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((isoData, index) => (
          <tr>
            
            <td className="p-3 text-center">
              
              <div className="d-flex flex-column w-100 me-2">
                
                <div className="flex-stack mb-2">
                  
                  <span className=" me-2 fs-7 fw-bold">{isoData.name}</span>
                </div>
              </div>
            </td>
            <td>
              
              <div className="d-flex align-items-center">
                
                <div className="d-flex justify-content-start flex-column">
                  
                  <a
                    
                    className="text-dark fw-bold text-hover-primary fs-6"
                  >
                    {isoData.size}
                  </a>
                </div>
              </div>
            </td>
            <td>
              
              <div className="d-flex align-items-center">
                
                <div className="symbol symbol-30px me-5">
                  
                  <img src={"/img/flag_"+isoData.dclocation.dccc+".png"} />
                </div>
                <div className="d-flex justify-content-start flex-column">
                  
                  <a
                    
                    className="text-dark fw-bold text-hover-primary fs-6"
                  >
                    {isoData.dclocation.location}
                  </a>
                </div>
              </div>
            </td>
            <td className="">
              
              <div className="d-flex flex-column w-100 me-2">
                
                <div className="d-flex flex-stack mb-2">
                  {isoData.download=='100'?(<span className="badge badge-success">Available</span>):(<span className="badge badge-warning">{isoData.download}% Downloaded</span>)}
                  
                </div>
              </div>
            </td>
            <td className="">
              
              <div className="d-flex flex-column w-100 me-2">
                
                <div className="d-flex flex-stack mb-2">
                  
                  <span className="text-muted me-2 fs-7 fw-bold">
                    {isoData.added_at}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-center">
              
              <div className="card-toolbar">
                
                <a 
                  className="btn btn-sm btn-icon btn-color-danger btn-active-light-primary"
                  onClick={()=>deleteIso(isoData.file)}                
                  >
                  
                  <span className="svg-icon svg-icon-2">
                    
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      
                      <path
                        d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                        fill="black"
                      />
                      <path
                        opacity="0.5"
                        d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                        fill="black"
                      />
                      <path
                        opacity="0.5"
                        d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </td>
          </tr>
            ))):( <>
             <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
              </>)}
              
        
        </>
    )
}

const [searchTerm,setSearchTerm]=useState('');
function PaginatedItems({ itemsPerPage, ProductData, search }) {
  // We start with an empty list of items. 
  const [pageCount, setPageCount] = useState(0);
  const [vpcData,setVpcData]=useState(null);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    
    const endOffset = itemOffset + itemsPerPage;


  //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
     if(ProductData!=null){
    //   var str="Noida";
   // ////console.log(ProductData);
    const data= ProductData.filter(row => {
     return  Object.values(row).some(value => { 
        if(value!=null){
          if(value.toString().toLowerCase().includes(search.toLowerCase())){
            return value;
          } 
        }
      });
    });
    //////console.log(data);
      // const data=ProductData.filter(item => 
      //        item.hostname.toLowerCase().includes(search.toLowerCase())
      //              ); 

                   if(data.length<=0){
                    loaderFunc();
                   }
    setVpcData(data.slice(itemOffset, endOffset));
    //setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
    // alert(pageCount);
      }
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  
  const handlePageClick = (event) => { 
    const newOffset = event.selected * itemsPerPage % ProductData.length;
    ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
   
  };
  var searchEle=document.querySelector("#searchClient");
  if(searchEle){
    searchEle.focus();
  }
  return (
    <>
      <ProductInfo productdata={vpcData} />
      <td colspan="12">
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      </td>
    </>
  );
}
 
if(dataFetched){
  if(access==false){
    return (
      <>
       <AccessDenied />
      </>
    );
  }
}else{
  return(
    <TopBarHeader/>
  )
}
return(
  <> 
   <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Notice*/}
    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 ">
      {/*begin::Icon*/}
      {/*begin::Svg Icon | path: icons/duotune/general/gen048.svg*/}
      <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="{24}"
          height="{24}"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M5 11.3V11C5 9.9 5.9 9 7 9H17C18.1 9 19 9.9 19 11V11.3C18.7 11.1 18.4 11 18 11H6C5.6 11 5.3 11.1 5 11.3ZM4 13H3C2.4 13 2 13.4 2 14V18C2 18.6 2.4 19 3 19H4V13ZM22 18V14C22 13.4 21.6 13 21 13H20V19H21C21.6 19 22 18.6 22 18Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M18 21H6C4.9 21 4 20.1 4 19V13C4 11.9 4.9 11 6 11H18C19.1 11 20 11.9 20 13V19C20 20.1 19.1 21 18 21ZM14 3C14 2.4 13.6 2 13 2H11C10.4 2 10 2.4 10 3V9H14V3ZM6 12C5.4 12 5 12.4 5 13C5 13.6 5.4 14 6 14C6.6 14 7 13.6 7 13C7 12.4 6.6 12 6 12ZM18 12C17.4 12 17 12.4 17 13C17 13.6 17.4 14 18 14C18.6 14 19 13.6 19 13C19 12.4 18.6 12 18 12ZM6 18C5.4 18 5 18.4 5 19C5 19.6 5.4 20 6 20C6.6 20 7 19.6 7 19C7 18.4 6.6 18 6 18ZM18 18C17.4 18 17 18.4 17 19C17 19.6 17.4 20 18 20C18.6 20 19 19.6 19 19C19 18.4 18.6 18 18 18Z"
            fill="black"
          />
        </svg>
      </span>
      {/*end::Svg Icon*/}
      {/*end::Icon*/}
      {/*begin::Wrapper*/}
      <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
        {/*begin::Content*/}
        <div className="mb-3 mb-md-0 fw-bold">
          <h4 className="text-gray-900 fw-bold">
            Add ISO in your account
            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
            <span className="svg-icon svg-icon-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="{24}"
                height="{24}"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 11.3V11C5 9.9 5.9 9 7 9H17C18.1 9 19 9.9 19 11V11.3C18.7 11.1 18.4 11 18 11H6C5.6 11 5.3 11.1 5 11.3ZM4 13H3C2.4 13 2 13.4 2 14V18C2 18.6 2.4 19 3 19H4V13ZM22 18V14C22 13.4 21.6 13 21 13H20V19H21C21.6 19 22 18.6 22 18Z"
                  fill="black"
                />
                <path
                  opacity="0.3"
                  d="M18 21H6C4.9 21 4 20.1 4 19V13C4 11.9 4.9 11 6 11H18C19.1 11 20 11.9 20 13V19C20 20.1 19.1 21 18 21ZM14 3C14 2.4 13.6 2 13 2H11C10.4 2 10 2.4 10 3V9H14V3ZM6 12C5.4 12 5 12.4 5 13C5 13.6 5.4 14 6 14C6.6 14 7 13.6 7 13C7 12.4 6.6 12 6 12ZM18 12C17.4 12 17 12.4 17 13C17 13.6 17.4 14 18 14C18.6 14 19 13.6 19 13C19 12.4 18.6 12 18 12ZM6 18C5.4 18 5 18.4 5 19C5 19.6 5.4 20 6 20C6.6 20 7 19.6 7 19C7 18.4 6.6 18 6 18ZM18 18C17.4 18 17 18.4 17 19C17 19.6 17.4 20 18 20C18.6 20 19 19.6 19 19C19 18.4 18.6 18 18 18Z"
                  fill="black"
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </h4>
            <br />
          <div className="fs-6 text-gray-700 pe-7 row">
            {/*begin::Form*/}
            <form id="addisos" className="form row">
              <div className="mb-10 col-lg-3 col-md-3 col-xxl-3">
                <label htmlFor="size" className="required form-label">
                  Datacenter
                </label>
                <select
                  className="form-select"
                  data-control="select2"
                  id="dcSelect"
                  name="dcslug"
                  data-select2-id="select2-data-dcSelect"
                  tabIndex={-1}
                  aria-hidden="true"
                > 
                  <option >Choose location</option>
                {dataCenter.length>0?(dataCenter && dataCenter.map((value,index)=>(
                  <>
                  {value.status=="active"?(<>
                  <option value={value.slug}>{value.city + " - " + value.country}</option>
                  </>):('')}
                  </>
                ))):('')}

                </select>
               
              </div>
              <div className="mb-10 col-lg-4 col-md-4 col-xxl-4">
                <label htmlFor="size" className="required form-label">
                  URL
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="url"
                  name="url"
                  placeholder="https://remoteaddress.com/yourappos.iso"
                />
              </div>
              <div className="mb-10 col-lg-3 col-md-3 col-xxl-3">
                <label htmlFor="size" className="required form-label">
                  Name (eg:my-linux-os)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="my-linux-os"
                />
              </div>
              {/*end::Card body*/}
              {/*begin::Card footer*/}
              <div className="card-footer d-flex justify-content-end py-6 px-9 col-lg-2 col-md-2 col-xxl-2">
                <a 
                  id="add_iso"
                  className="btn btn-sm btn-primary mb-2 px-6 align-self-center text-nowrap"
                  onClick={addISO}
                >
                  + Add ISO
                </a>
              </div>
              {/*end::Card footer*/}
            </form>
            {/*end::Form*/}
          </div>
        </div>
        {/*end::Content*/}
        {/*begin::Action*/}
        {/*end::Action*/}
      </div>
      {/*end::Wrapper*/}
    </div>
    {/*end::Notice*/}
  </div>
</div>

<div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold fs-3 mb-1">
          Current ISO
          {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="{24}"
              height="{24}"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 11.3V11C5 9.9 5.9 9 7 9H17C18.1 9 19 9.9 19 11V11.3C18.7 11.1 18.4 11 18 11H6C5.6 11 5.3 11.1 5 11.3ZM4 13H3C2.4 13 2 13.4 2 14V18C2 18.6 2.4 19 3 19H4V13ZM22 18V14C22 13.4 21.6 13 21 13H20V19H21C21.6 19 22 18.6 22 18Z"
                fill="black"
              />
              <path
                opacity="0.3"
                d="M18 21H6C4.9 21 4 20.1 4 19V13C4 11.9 4.9 11 6 11H18C19.1 11 20 11.9 20 13V19C20 20.1 19.1 21 18 21ZM14 3C14 2.4 13.6 2 13 2H11C10.4 2 10 2.4 10 3V9H14V3ZM6 12C5.4 12 5 12.4 5 13C5 13.6 5.4 14 6 14C6.6 14 7 13.6 7 13C7 12.4 6.6 12 6 12ZM18 12C17.4 12 17 12.4 17 13C17 13.6 17.4 14 18 14C18.6 14 19 13.6 19 13C19 12.4 18.6 12 18 12ZM6 18C5.4 18 5 18.4 5 19C5 19.6 5.4 20 6 20C6.6 20 7 19.6 7 19C7 18.4 6.6 18 6 18ZM18 18C17.4 18 17 18.4 17 19C17 19.6 17.4 20 18 20C18.6 20 19 19.6 19 19C19 18.4 18.6 18 18 18Z"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </span>
        <span className="text-muted mt-1 fw-bold fs-7">
          Manage your ISO here
        </span>
      </h3>
      <div className="card-toolbar">
        <div className="position-relative my-1" style={{ marginRight: 20 }}>
          <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
        <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select  
        onChange={e => setPerPage(e.target.value)}
        //data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={5}
        id="perPage"
        
      >
        <option value={5} selected="">
          5 Records
        </option>
        <option value={10}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
  </div>
</div>
        <a  onClick={()=>getIso()} className="btn btn-sm btn-light-primary">
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
          <span className="svg-icon svg-icon-2 svg-icon-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z"
                fill="black"
              />
              <path
                opacity="0.3"
                d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}Refresh
        </a>
      </div>
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    {/*begin::Table container*/}
    <div className="table-responsive">
      {/*begin::Table*/}
      <table
        id="isoTable"
        className="table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className="fw-bold">
            <th className="min-w-150px text-center">Name</th>
            <th className="min-w-150px">Size</th>
            <th className="min-w-150px">Location</th>
            <th className="min-w-150px">Status</th>
            <th className="min-w-150px">Added at</th>
            <th className="min-w-150px text-center">Delete</th>
          </tr>
        </thead>
        {/*end::Table head*/}
        {/*begin::Table body*/}
        <tbody id="iso-list">
  {isoData!=null?(<>
<PaginatedItems itemsPerPage={perPage} ProductData={isoData} search={searchTerm}  />
  </>):(<>
    <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center">
                        <img
                          src="/assets/media/illustrations/dozzy-1/5.png"
                          className="mw-400px d-none"
                        />
                        <div className="fs-1 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
  </>)}
  </tbody>
        {/*end::Table body*/}
      </table>
      {/*end::Table*/}
    </div>
    {/*end::Table container*/}
    {/*begin::Body*/}
  </div>
  {/*end::Tables Widget 9*/}
</div>
  </>
)

}