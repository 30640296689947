import React from 'react';
import { Routes, Route } from "react-router-dom"; 
import {BrowserRouter as Router} from "react-router-dom"; 
import AppRoute from './AppRoute';
 
 
function App() {      
  return (
    <> 
      <Router>
      <Routes> 
      <Route path="/Welcome" element={<AppRoute />}   />
      <Route path="*" element={<AppRoute />}   />
      </Routes> 
      </Router>
    </>
);  
}

export default App;
