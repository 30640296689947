import { IS_PLAN_UPDATE } from "../../../../Redux/Services/constants"

 
const initialState={ 
   planDataInfo:[{ram:"",cpu:"",disk:"",bandwidth:"",billing_info:"",plan_value:"", plan_cost:"",plan_cost_without_discount:"",discount:0}]
}

export default function PricingReducer(state=initialState,action){


   switch (action.type) {
       case IS_PLAN_UPDATE: 
           return(
              {planDataInfo:action.data}
           )           
       default:
           return state 
   }
}

