import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AccessDenied from "../../AccessDenied";

export default function CurrentUsage(){
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess]=useState(true);
  const [cloudCredit,setCloudCredit]=useState('');
  const [subtotalUsage,setSubtotalUsage]=useState('');
  const [tax18,setTax18]=useState('');
  const [creditUsage,setCreditUsage]=useState('');
  const [dueAmount,setDueAmount]=useState('');
  const [currentHourlyUsageData,setCurrentHourlyUsageData]=useState(null); 
  const [currentUnbilledUsageData,setCurrentUnbilledUsageData]=useState(null); 
  const dataFetchedRef=useRef (false);
  const getCurrentUsage=async()=>{ 
    const dt=toast.loading("Current Usages loading..."); 
       const data=await UseApi('get','billing/unbilled','');
       if(data){
        setDataFetched(true);
       }
       if('status' in data){
        if(data.status=="error"){ 
           toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });  
          setAccess(false);
          setCurrentUnbilledUsageData(null);
        setCurrentHourlyUsageData(null);    
          return;
        }
     }
       if(Object.keys(data).length === 0){
        setCurrentUnbilledUsageData(null);
        setCurrentHourlyUsageData(null); 
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            }); 
       }else{
        var charr=data.hourlyusages;
        var cuarr=data.unbilled; 
        //console.log(cuarr); 
        setCurrentUnbilledUsageData(cuarr);
        setCurrentHourlyUsageData(charr); 
        if(data.isfreecredit===true){
         setCloudCredit(data.freecredit);
        }
        if(data.isfreecredit===true){
          setCreditUsage(data.credit);
        }
        setSubtotalUsage(data.subtotal);
        setTax18(data.tax);
        setDueAmount(data.total);
        toast.dismiss();
          //   toast.update(dt,{render: "Your Current Usages request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          // });   
       }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getCurrentUsage(); 
  },[]);   
 
if(dataFetched){
  if(access==false){
    return (
      <>
       <AccessDenied />
      </>
    );
  }
}
    return(
        <>
        {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
        <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bolder fs-3 mb-1">
          Current Usages
          {/*begin::Svg Icon | path: assets/media/icons/duotune/graphs/gra011.svg*/}
          <span className="svg-icon svg-icon-muted svg-icon-2hx">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.3"
                d="M9.4 8.79999L16.6 14H20V12L16 11L9 4L5 6V9.90002L9.4 8.79999Z"
                fill="black"
              />
              <path
                d="M21 18H20V16H16L9 11L6 11.8V3C6 2.4 5.6 2 5 2C4.4 2 4 2.4 4 3V18H3C2.4 18 2 18.4 2 19C2 19.6 2.4 20 3 20H4V21C4 21.6 4.4 22 5 22C5.6 22 6 21.6 6 21V20H21C21.6 20 22 19.6 22 19C22 18.4 21.6 18 21 18Z"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </span>
        <span className="text-muted mt-1 fw-bold fs-7">
          Current hourly usages that going to be convert into a tax invoice by
          1st of next month.
        </span>
      </h3>
   
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    {/*begin::Table container*/}
    <div className="table-responsive">
      {/*begin::Table*/}
      <table
        className="table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
        id="table-id"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className="fw-bold">
            <th className="min-w-10px text-center" />
            <th className="min-w-150px text-center">Item Description</th>
            <th className="min-w-100px text-left">Amount</th>
          </tr>
        </thead>
        {/*end::Table head*/}
        {/*begin::Table body*/}
        <tbody id="activity-table">
        {currentHourlyUsageData!=null && Object.keys(currentHourlyUsageData).length!=0?(currentHourlyUsageData && currentHourlyUsageData.map((value, index) => (
          <>
          <tr> 
            <td className="text-center"></td>
            <td> 
              <div className="d-flex align-items-center">
                
                <div className="d-flex justify-content-start flex-column">
                  
                  <span className="fw-bold d-block fs-7">
                    {value.desc.replace('</br>',"\n")}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-end">
              
              <div className="d-flex flex-column w-100 me-2">
                
                <div className="d-flex flex-stack mb-2">
                  
                  <span className=" me-2 fs-7 fw-bold">{value.amount}</span>
                </div>
              </div>
            </td>
          </tr>
          
          </>
          ))):('')}
          {currentUnbilledUsageData!=null && Object.keys(currentHourlyUsageData).length!=0?(currentUnbilledUsageData && currentUnbilledUsageData.map((value, index) => (
          <>
          <tr> 
            <td className="text-center"></td>
            <td> 
              <div className="d-flex align-items-center">
                
                <div className="d-flex justify-content-start flex-column">
                  
                  <span className="fw-bold d-block fs-7">
                    {value.desc}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-end">
              
              <div className="d-flex flex-column w-100 me-2">
                
                <div className="d-flex flex-stack mb-2">
                  
                  <span className=" me-2 fs-7 fw-bold">{value.amount}</span>
                </div>
              </div>
            </td>
          </tr>
          
          </>
          ))):(<>
          <tr>
            <td colSpan={12} className="text-center fw-bold">
               no records found
            </td>
          </tr>
          </>)}
        </tbody>
        {/*end::Table body*/}
      </table>
      {/*end::Table*/}
      
      {currentUnbilledUsageData!=null && Object.keys(currentHourlyUsageData).length!=0?(<>
        <hr />
      <div className="container d-flex justify-content-end">
        {/*begin::Section*/}
        <div className="mw-300px">
          {/*begin::Item*/}
          
            {cloudCredit!=''?(<>
              <div className="d-flex flex-stack mb-5" id="cloud_credit"> 
            <div className="fw-bold pe-10 text-gray-600 fs-7">Cloud Credits - Free:</div>
            <div className="text-end fw-bolder fs-6 text-gray-800" id="cloud-credit"  >  {cloudCredit} </div></div></>)
            :('')} 
          
          {/*end::Item*/}
          {/*begin::Item*/}
          {subtotalUsage!=''?(<>
          <div className="d-flex flex-stack mb-5" id="subtotal_usage"> 
            <div className="fw-bold pe-10 text-gray-600 fs-7">Subtotal:</div> 
            <div
              className="text-end fw-bolder fs-6 text-gray-800"
              id="subtotal-usage"
            >
              {subtotalUsage}
            </div> 
          </div>
          </>):('')}
          {/*end::Item*/}
          {/*begin::Item*/}
          {tax18!=''?(<>
          <div className="d-flex flex-stack mb-5"> 
            <div className="fw-bold pe-10 text-gray-600 fs-7">Tax 18%</div> 
            <div className="text-end fw-bolder fs-6 text-gray-800" id="tax-18">
              {tax18}
            </div> 
          </div>
          </>):('')}
          {/*end::Item*/}
          {/*begin::Item*/}
          {creditUsage!=''?(<>
          <div className="d-flex flex-stack mb-5 d-none" id="credit_usage"> 
            <div className="fw-bold pe-10 text-gray-600 fs-7">Credits</div> 
            <div
              className="text-end fw-bolder fs-6 text-gray-800"
              id="credit-usage">
                {creditUsage}
                </div> 
          </div>
          </>):('')}
          {/*end::Item*/}
          {/*begin::Item*/}
          {dueAmount!=''?(<>
          <div className="d-flex flex-stack mb-10" id="due_amount"> 
            <div className="fw-bold pe-10 text-gray-600 fs-7">Due Amount</div> 
            <div
              className="text-end fw-bolder fs-6 text-gray-800"
              id="due-amount"
            >
              {dueAmount}
            </div> 
          </div>
          </>):('')}
          {/*end::Item*/}
        </div>
        {/*end::Section*/}
      </div>
      </>):('')}
    </div>
    
    {/*end::Table container*/}
    {/*begin::Body*/}
  </div>
  {/*end::Tables Widget 9*/}
</div>

        </>
    )
}