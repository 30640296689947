import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import Select from 'react-select';
import CountryFlag  from 'react-country-flag';
import swal from 'sweetalert';   
import MobileVerification from './MobileVerification';  
import './custom.css';
import UseApi from '../../../../CustomHooks/useApi';
const MobileUpdate = () => { 
  const [userDatas, setUserDatas]=useState(null); 
  const [currMobile, setCurrMobile]=useState(null);
  async function getUserInfo(){
    const data=await UseApi('get','account/info','');  
    if(data){
        if(data.user){
            setUserDatas(data.user);
            var userD=data.user;
            if(userD){
                var currMob=userD.mobilecc+""+userD.mobile;
                setCurrMobile(currMob);
          }
        }
    }
  }
  useEffect(()=>{
    getUserInfo();  
    
  },[]) 
    return (
        <> 
        {userDatas!=null?(<>
<div className="card">
  <div className="card-body">
  {/*begin::Stepper*/}
  <div className="stepper stepper-pills mb-20" >
    
    {/*begin::Form*/}
    <form
      className="form w-100 mx-auto mb-20" 
    >
      {/*begin::Group*/}
      <div className="mb-5">
        {/*begin::Step 1*/}
        <div className="flex-column current">
        {currMobile == null?(''):(<>
          <MobileVerification currentMobile={currMobile}/> 
        </>)}
        </div>
        {/*begin::Step 1*/} 
      </div>
      {/*end::Group*/} 
    </form>
    {/*end::Form*/}
  </div>
  {/*end::Stepper*/} 
  </div>
  </div> 
  </>):(<>
    <div class="loading-spinner2"></div>
  </>)}
  </>
    );
    
}

export default MobileUpdate;
