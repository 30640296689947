import React, { useEffect, useState } from "react";  
import UsePost from "../CustomHooks/usePost";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MicroApp from "../MicroApp/MicroApp";
import {BrowserRouter as Router} from "react-router-dom";
import localStorage from "local-storage";
import { Navigate, useNavigate } from "react-router-dom/dist"; 
import CryptoJS from 'crypto-js';
import Cookies from "js-cookie";
import ApplyCoupon from "./ApplyCoupon";

var serialize=require('form-serialize');
export default function TwoFactor(){  
  var current_url_string = window.location.href;
  const current_url = new URL(current_url_string);
  const current_domain = current_url.hostname;
  const navigate=useNavigate();
  const logout=()=>{
    localStorage.clear(); 
     
     
    const cookieNames = Object.keys(Cookies.get());
    const domain = '.'+current_domain; // Specify the domain

    // Remove each cookie with the specified domain
    cookieNames.forEach((cookieName) => {
      Cookies.remove(cookieName, { domain, path: '/' }); // Make sure to include the path
    });
    const location_url = window.location.origin;
    window.location.href = location_url; 
  }
 // const [isVerfied,setIsVerified]=useState(null);
  const [dcode,setDcode]=useState("");
  const codeForm=async()=>{
        var form=document.querySelector('#code_form');
        var requestData=serialize(form,{hash:true});
        const codeFa=requestData.code_1+''+requestData.code_2+''+requestData.code_3+''+requestData.code_4+''+requestData.code_5+''+requestData.code_6;
          setDcode(codeFa); 
  }
  const submitCode=async()=>{
    
    var submitBtn=document.querySelector("#otp-btn");
    if(submitBtn){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';  
    const dt=toast.loading("Please wait...");
    const responseData=await UsePost('post','2fa','twofa_form');
      if(responseData){
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML=preContent;
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else{
        // const mhc_auth=localStorage.get("Auth");
        const mhc_auth=Cookies.get('microhost_api_auth');  
 
    const userHash=CryptoJS.MD5(mhc_auth).toString();
    const two_fa_Auth=mhc_auth+userHash+mhc_auth;
    ////console.log(two_fa_Auth);
    localStorage.set("twoFaAuth",two_fa_Auth); 
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      const apiUrl = 'https://api.utho.com/v2/appinfo';
      const headers = { 
        'Content-Type': 'application/json', // Adjust as needed
      };

      // Define the Fetch options
      const fetchOptions = {
        method: 'GET',
        headers: headers // Change to 'POST', 'PUT', 'DELETE', etc. if needed 
      };

 const AppData= fetch(apiUrl, fetchOptions)
  .then(response => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json(); // Parse the response body as JSON
  })
  .then(data => { 
    if (data) {  
      localStorage.set("appInfo",data);
    } 
     
  })
  .catch(error => {
    console.error('API request failed:', error); 
  });
      // const data=await CallAppinfo(); 
      if(AppData){
        // navigate("/");
        const location_url = window.location.origin;
         window.location.href = location_url; 
            
      }
    //   window.sessionStorage.setItem('2faVerified', true);
    //  localStorage.set("2faVerified",true);
      //  navigate("/AuthProcess");
     
     // navigate("/");

      //setIsVerified(true);
      }
    }
  }   
  }
  useEffect(() => {
    window.addEventListener("keydown", function(event) {
      event.stopPropagation();
      if(event.key=="Enter"){
      ////console.log(event);
      submitCode();
    }
  });
  }, []);
   const resendOtp=async()=>{
    const dt=toast.loading("Please wait...");
    const responseData=await UsePost('post','2fa/resend','resendotp');
      ////console.log(responseData);
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else{
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
   }
  }
  const backKeyPress=(event)=>{
    event.preventDefault();

    ////console.log(event); 
    //////console.log(event.target.name); 
  if((event.key==0 || event.key==1 || event.key==2 || event.key==3 || event.key==4 || event.key==5 || event.key==6 || event.key==7 || event.key==8 || event.key==9 || event.key=="Backspace" || event.key=="Delete") && event.ctrlKey==false && event.altKey==false){
    var inputName=event.target.name;
    ////console.log(inputName.split("_"));
    var nameArr=inputName.split("_");
    var currentIndex=nameArr[1];
    if(event.key=="Backspace" || event.key=="Delete"){
      currentIndex--;
      if(currentIndex<7 && currentIndex>=1){
      ////console.log(currentIndex);
      ////console.log("current index");
      document.querySelector('#pc'+currentIndex).focus();
      }
    }else{
      if(event.key=="1" || event.key=="2" || event.key=="3" || event.key=="4" || event.key=="5" || event.key=="6" || event.key=="7" || event.key=="8" || event.key=="9" || event.key=="0"){
        document.querySelector('#pc'+currentIndex).value=event.key;
      }else{
        document.querySelector('#pc'+currentIndex).value="";
      }
      currentIndex++;
      if(currentIndex<7){
      ////console.log(currentIndex);
      document.querySelector('#pc'+currentIndex).focus();
      }
    }
  } 
    codeForm(); 
     //  ////console.log(event.key); 
  }

      // Function to handle input changes
const handleInputLimit = (e) => {
  const input = e.target;
  const inputValue = input.value;

  // Ensure only one digit is allowed
  if (inputValue.length > 1) {
    input.value = inputValue.charAt(0); // Keep only the first character
  }
}; 
useEffect(() => {
  document.addEventListener('paste', handleChange);

  return () => {
    // Remove the event listener when the component unmounts
    document.removeEventListener('paste', handleChange);
  };
}, []);

 const handleChange = e => {
  e.stopPropagation();
  // e.preventDefault(); // Prevent the default paste behavior

const clipboardData = e.clipboardData || window.clipboardData;
var pastedText = clipboardData.getData('text');
//  console.log(pastedText);
 pastedText=pastedText.trim();
// Check if the pasted text is a 6-digit OTP
if (/^\d{6}$/.test(pastedText)) {
  // Split the OTP into individual characters
  const otpChars = pastedText.split('');
  // console.log(otpChars);
  // Update the OTP input fields
  const otpInputs = document.querySelectorAll('.otp-input'); // Assuming you have a class "otp-input" on each input field

  otpInputs.forEach((input, index) => {
    if (index < otpChars.length) {
      input.value = otpChars[index];
    }
  });

}else{
  // console.log("not 6 digit otp");
}
codeForm(); 
}; 
    return(
        <>
        <ApplyCoupon />
        <div className="page d-flex flex-row flex-column-fluid loginbg">

                <div
  className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative  d-none d-lg-block d-md-block d-xxl-block" 
  >
    {/*begin::Wrapper*/}
    <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
     
    </div>
  {/*end::Wrapper*/}
</div>

<div className="container d-flex flex-column flex-lg-row-fluid py-10 top-front">
  {/*begin::Content*/}
  <div className="d-flex flex-center flex-column flex-column-fluid">
    {/*begin::Wrapper*/}
    <div className="w-lg-600px p-10 p-lg-15 mx-auto">
      {/*begin::Form*/}
      <form
        className="form w-100 mb-10"
        noValidate="novalidate" 
        id="code_form"
      >
        {/*begin::Icon*/}
        <div className="text-center mb-10">
          <img
            alt="Logo"
            className="mh-125px"
            src="assets/media/svg/misc/smartphone.svg"
          />
        </div>
        {/*end::Icon*/}
        {/*begin::Heading*/}
        <div className="text-center mb-10">
          {/*begin::Title*/}
          <h1 className="text-light mb-3">Two Step Verification</h1>
          {/*end::Title*/}
          {/*begin::Sub-title*/}
          <div className="text-muted fw-bold fs-5 mb-5">
            Enter the verification code we sent to
          </div>
          {/*end::Sub-title*/}
          {/*begin::Mobile no*/}
          <div className="fw-bolder text-light fs-3">Registered Mobile or Email</div>
          {/*end::Mobile no*/}
        </div>
        {/*end::Heading*/}
        {/*begin::Section*/}
        <div className="mb-10 px-md-10">
          {/*begin::Label*/}
          <div className="fw-bolder text-start text-gray-400 fs-6 mb-5 ms-1">
            Type your 6 digit security code
          </div>
          {/*end::Label*/}
          {/*begin::Input group*/}
          <div className="d-flex flex-wrap flex-stack">
          <input
              onInput={handleInputLimit}
              onPaste={handleChange}
              onKeyUp={(e) => backKeyPress(e)}
              data-id={1}
              id="pc1"
               type="number" style={{marginRight:'2px'}}
              data-inputmask="'mask': '9', 'placeholder': ''"
              max={1}
              className="hide-arrows form-control form-control-solid h-45px w-45px fs-2qx text-center border-primary border otp-input"
              defaultValue=""
              inputMode="number"
              name="code_1"
            />
            <input
            onInput={handleInputLimit}
            onPaste={handleChange}
            onKeyUp={(e) => backKeyPress(e)}
            data-id={2}
            id="pc2"
               type="number" style={{marginRight:'2px'}}
              data-inputmask="'mask': '9', 'placeholder': ''"
              maxLength={1}
              className="hide-arrows form-control form-control-solid h-45px w-45px fs-2qx text-center border-primary border otp-input"
              defaultValue=""
              inputMode="number"
              name="code_2"
            />
            <input
            onInput={handleInputLimit}
            onPaste={handleChange}
            onKeyUp={(e) => backKeyPress(e)}
              data-id={3}
            id="pc3"
               type="number" style={{marginRight:'2px'}}
              data-inputmask="'mask': '9', 'placeholder': ''"
              max={1}
              className="hide-arrows form-control form-control-solid h-45px w-45px fs-2qx text-center border-primary border otp-input"
              defaultValue=""
              inputMode="number"
              name="code_3"
            />
            <input
            onInput={handleInputLimit}
            onPaste={handleChange}
            onKeyUp={(e) => backKeyPress(e)}
              data-id={4}
            id="pc4"
               type="number" style={{marginRight:'2px'}}
              data-inputmask="'mask': '9', 'placeholder': ''"
              max={1}
              className="hide-arrows form-control form-control-solid h-45px w-45px fs-2qx text-center border-primary border otp-input"
              defaultValue=""
              inputMode="number"
              name="code_4"
            />
            <input
            onInput={handleInputLimit}
            onPaste={handleChange}
            onKeyUp={(e) => backKeyPress(e)}
              data-id={5}
            id="pc5"
               type="number" style={{marginRight:'2px'}}
              data-inputmask="'mask': '9', 'placeholder': ''"
              max={1}
              className="hide-arrows form-control form-control-solid h-45px w-45px fs-2qx text-center border-primary border otp-input"
              defaultValue=""
              inputMode="number"
              name="code_5"
            />
            <input
              onInput={handleInputLimit}
              onPaste={handleChange}
              onKeyUp={(e) => backKeyPress(e)}
              data-id={6}
              id="pc6"
               type="number" style={{marginRight:'2px'}}
              data-inputmask="'mask': '9', 'placeholder': ''"
              max={1}
              className="hide-arrows form-control form-control-solid h-45px w-45px fs-2qx text-center border-primary border otp-input"
              defaultValue=""
              inputMode="number"
              name="code_6"
            />
          </div>
          {/*begin::Input group*/}
        </div>
        {/*end::Section*/}
        {/*begin::Submit*/}
        <div className="d-flex flex-center">
          <button
            type="button"
            onClick={submitCode}
            className="btn btn-lg btn-primary fw-bolder"
            id="otp-btn"
          >
            <span className="indicator-label">Submit</span>
          </button>
        </div>
        {/*end::Submit*/}
      </form>
      <form className="form w-100 mb-10"
        noValidate="novalidate" 
        id="twofa_form">
      <input 
              className="form-control form-control-lg form-control-solid"
              type="hidden"
              name="code"
              value={dcode} 
              autoComplete="off"
            /> 
      </form>
      {/*end::Form*/}
      {/*begin::Notice*/}
      <div className="text-center fw-bold fs-5">
        <span className="text-muted me-1">Didn’t get the code ?</span>
        <a  onClick={resendOtp} className="link-primary fw-bolder fs-5 me-1">
          Resend
        </a>
        <span className="text-muted me-1">or</span>
        <a href="tel:+911204840000" className="link-primary fw-bolder fs-5">
          Call Us
        </a>
      </div> 
      <br/>
      <div className="text-center fw-bold fs-5">
        <span className="text-muted me-1">or</span>
         <br/>
         <span className="text-muted me-1">Back to </span>
        <a onClick={logout} className="link-primary fw-bolder fs-5">
          home
        </a>
      </div>
      {/*end::Notice*/}
    </div>
    {/*end::Wrapper*/}
  </div>
  {/*end::Content*/}
   
</div>
</div>
   </>
    )

  }
//}