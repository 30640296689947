import React, { useEffect, useRef, useState } from "react";
import { Link ,useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../CustomHooks/usePost";
import swal from 'sweetalert';
import validator from 'validator';
import { stateObject } from "./state";
import { countryObject } from "./country"; 
import localStorage from "local-storage"; 
import Cookies from "js-cookie";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";

export default function Account(){
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess]=useState(true);
  function sleep(delay) {
    var start = new Date().getTime();
    while (new Date().getTime() < start + delay);
} 

  const [userData,setUserData]=useState(null);
  const dataFetchedRef=useRef (false);
  const navigate=useNavigate();
  const validate = (value) => {
   const password_strength=document.querySelector("#password_strength");
   const password_weak=document.querySelector("#password_weak");
    if (validator .isStrongPassword(value, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1, minSymbols: 1
    })) {
      password_strength.classList.remove("d-none");
      password_weak.classList.add("d-none");
    } else {
      password_strength.classList.add("d-none");
      password_weak.classList.remove("d-none");
    }
  }

  const navRedirect=()=>{
    setTimeout(() => {
      navigate("/account");
     }, 3000);
      
  }
  const [stateData,setStateData]=useState('');

  function updateStates(countryValue){ 
    const newStateData = stateObject
      .filter(state => state.country_code === countryValue)
      .map(state => state.name);
    // console.log(newStateData);
    setStateData(newStateData);
  }

  const getUser=async()=>{
    
    
       const data=await UseApi('get','account/info','');  
       if(Object.keys(data).length === 0){
        const dt=toast 
        setUserData(null);
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            }); 
       }else{
        if(data.user && data.user.country){
          updateStates(data.user.country);
            }
        setUserData(data.user);  
          
          //   toast.update(dt,{render: "Your account info loaded", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          // });  
          //console.log(data.user.twofa_settings);
          const twoFaContent=document.getElementById("2faContent");
          //console.log(twoFaContent);
          if(twoFaContent){
          if(data.user.twofa_settings==="none"){
            //console.log("auth setup");
            twoFaContent.classList.add('d-none');
          }else{
            //console.log("not setup");
            twoFaContent.classList.remove('d-none');
          }  
        }
       }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getUser(); 
  },[]);  
  const twoFaAuth=async(status)=>{
    toast.dismiss();
    var authMessage="";
     const smsAuth=document.querySelector("#sms_auth");
     const emailAuth=document.querySelector("#email_auth");
     const twoFaInput=document.querySelector("#twofa_input");
     var targetId="save2faBtn";
     
    if(status=='none'){
      targetId="update2faBtn";
      authMessage="Once removed, 2-factor security service removed from your account!";
         twoFaInput.value="none";
    }else if(status=='update'){
      targetId="save2faBtn";
       var sms_auth="";
       var email_auth="";
       if(smsAuth){
       if(smsAuth.checked){
        sms_auth="yes";
       }
      }
      if(emailAuth){
       if(emailAuth.checked){
        email_auth="yes";
       }
      }

       if(sms_auth=="yes" && email_auth==""){
        authMessage="2-factor security service will update on your account!";
        twoFaInput.value="mobile";
       }
       if(sms_auth=="" && email_auth=="yes"){
        authMessage="2-factor security service will update on your account!";
        twoFaInput.value="email";
       }
       if(sms_auth=="yes" && email_auth=="yes"){
        twoFaInput.value="mobile,email";
        authMessage="2-factor security service will update on your account!";
       }
    }else{
      authMessage="Once added, 2-factor security service will apply on your account!";
      twoFaInput.value="email";
    }
   

    swal({
      title:"Are you sure?",
      text: authMessage,
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("2 factor auth updating..."); 
        const submitBtn=document.querySelector("#"+targetId);
        if(submitBtn){
          var preContent=submitBtn.innerHTML; 
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML='Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        const dnsp=await UseApi('post','account/2fa','authSetup',''); 
        if(dnsp.status=='error'){
         toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       });
       }else{
         toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       }); 
       getUser();
       navRedirect();
       }
       submitBtn.classList.remove("disabled");
       submitBtn.innerHTML=preContent;
      } 

    }
      });
    
  }

  const logout=()=>{
    localStorage.clear(); 
    // document.cookie = "microhost_api_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //localStorage.remove("Auth");

    const cookieNames = Object.keys(Cookies.get());
    // Remove each cookie
    cookieNames.forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
    const location_url = window.location.origin;
    window.location.href = location_url; 
  } 
  
  const deleteAccount=async()=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, Your account will delete permanently!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("Account deleting..."); 
        const submitBtn=document.querySelector("#deleteAccountBtn");
   if(submitBtn){
     var preContent=submitBtn.innerHTML; 
     submitBtn.classList.add("disabled");
     submitBtn.innerHTML='Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

        const dnsp=await UseApi('DELETE','account/close','closeaccount',''); 
        if(dnsp.status=='error'){
         toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       });
       }else{
         toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       }); 
       logout();
      //  getUser();
      //  navRedirect();
       }
       submitBtn.classList.remove("disabled");
       submitBtn.innerHTML=preContent;
      }
      } 
      });
    
  }
   

  const resetPasswordContent=(status)=>{
    
          const showPass=document.querySelector("#show-pass");
          const updatePass=document.querySelector("#update_pass");
          const resetPassword=document.querySelector("#reset-password");

          if(status=="show"){
              updatePass.classList.remove("d-none");
              showPass.classList.add("d-none");
              resetPassword.classList.add("d-none");
          }else{
            updatePass.classList.add("d-none");
            showPass.classList.remove("d-none");
            resetPassword.classList.remove("d-none");
          }

  }

  const updatePassword=async()=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Do you really want to update your password!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("Password updating..."); 
        const submitBtn=document.querySelector("#updatePasswordBtn");
        if(submitBtn){
          var preContent=submitBtn.innerHTML; 
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML='Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dnsp=await UseApi('post','account/update/password','updatepassword',''); 
        if(dnsp.status=='error'){
         toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       });
       }else{
         toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       }); 
       getUser();
       navRedirect();
       }
       submitBtn.classList.remove("disabled");
       submitBtn.innerHTML=preContent;
      }
      } 
      });
    
  } 
    
  const updateAccount=async ()=>{  
    toast.dismiss();
   const dt=toast.loading("Please wait..."); 
   const submitBtn=document.querySelector("#updateAccountBtn");
   if(submitBtn){
     var preContent=submitBtn.innerHTML; 
     submitBtn.classList.add("disabled");
     submitBtn.innerHTML='Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

    const responseData=await UsePost('post','account/update','updateprofile');
    if(responseData){
      //console.log(responseData);
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      }); 
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      }else{
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
        const userInfo=await UseApi('get','account/info','');  
        if(userInfo){
        localStorage.remove("user_info");
        localStorage.set("user_info",userInfo.user);  
        window.location.reload();
        submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent; 
        }
      }
    }
    }
  }
 

//Country State dropdown end
    return(
        <>
        {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
                    {userData!=null?(<>

        <div className="content d-flex flex-column flex-column-fluid">
  {/*begin::Post*/}
  <div className="post d-flex flex-column-fluid" id="kt_post">
    {/*begin::Container*/}
    <div id="kt_content_container" className="container-xxl">
      
      <div className="tab-content" id="myTabContent">
        <div
          id="profile-section"
          className="tab-pane fade show  d-none"
          role="tabpanel"
        >
          {/*begin::details View*/}
          <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
            {/*begin::Card header*/}
            <div className="card-header cursor-pointer">
              {/*begin::Card title*/}
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Profile Details</h3>
              </div>
              {/*end::Card title*/}
            </div>
            {/*begin::Card header*/}
            {/*begin::Card body*/}
            
            <div className="card-body p-9">
              {/*begin::Row*/}
              <div className="row mb-7">
                {/*begin::Label*/}
                <label className="col-lg-4 fw-bold text-muted">Full Name</label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8">
                  <span
                    className="fw-bolder fs-6 text-gray-800"
                    id="overview-name"
                  >
                    {userData!=null?(userData.fullname):('')}
                  </span>
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
              {/*begin::Input group*/}
              <div className="row mb-7">
                {/*begin::Label*/}
                <label className="col-lg-4 fw-bold text-muted">
                  Contact Email
                  {/* <i class="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Email address must be active"></i> */}
                </label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8 d-flex align-items-center">
                  <span
                    className="fw-bolder fs-6 text-gray-800 me-2"
                    id="overview-email"
                  >
                    {userData!=null?(userData.email):('')}
                  </span>
                  {/* <span class="badge badge-success">Verified</span> */}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="row mb-7">
                {/*begin::Label*/}
                <label className="col-lg-4 fw-bold text-muted">
                  Contact Phone
                  {/* <i class="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i> */}
                </label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8 d-flex align-items-center">
                  <span
                    className="fw-bolder fs-6 text-gray-800 me-2"
                    id="overview-mobile"
                  >
                    {userData!=null?(userData.mobile):('')}
                  </span>
                  {/* <span class="badge badge-success">Verified</span> */}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="row mb-7">
                {/*begin::Label*/}
                <label className="col-lg-4 fw-bold text-muted">Company</label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8 fv-row">
                  <span
                    className="fw-bold text-gray-800 fs-6"
                    id="overview-company"
                  >
                    {userData!=null?(userData.company):('')}
                  </span>
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="row mb-7">
                {/*begin::Label*/}
                <label className="col-lg-4 fw-bold text-muted">Address </label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8">
                  <a 
                    className="fw-bold fs-6 text-gray-800 text-hover-primary"
                    id="overview-address"
                  >
                    {" "}
                    {userData!=null && userData.address!=null?(userData.address+", "):('')} {userData!=null && userData.city!=null?(userData.city+", "):('')} {userData!=null && userData.country!=null?(userData.country+ ", "):('')}
                  </a>
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="row mb-10">
                {/*begin::Label*/}
                <label className="col-lg-4 fw-bold text-muted">
                  GST Number
                </label>
                {/*begin::Label*/}
                {/*begin::Label*/}
                <div className="col-lg-8">
                  <span
                    className="fw-bold fs-6 text-gray-800"
                    id="overview-gst"
                  >
                    {userData!=null && userData.gstnumber!=null?(userData.gstnumber):('')}
                  </span>
                </div>
                {/*begin::Label*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Notice*/}
              <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
                {/*begin::Icon*/}
                {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.3"
                      x={2}
                      y={2}
                      width={20}
                      height={20}
                      rx={10}
                      fill="black"
                    />
                    <rect
                      x={11}
                      y={14}
                      width={7}
                      height={2}
                      rx={1}
                      transform="rotate(-90 11 14)"
                      fill="black"
                    />
                    <rect
                      x={11}
                      y={17}
                      width={2}
                      height={2}
                      rx={1}
                      transform="rotate(-90 11 17)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                {/*end::Icon*/}
                {/*begin::Wrapper*/}
                <div className="d-flex flex-stack flex-grow-1">
                  {/*begin::Content*/}
                  <div className="fw-bold">
                    <h4 className="text-gray-900 fw-bolder">
                      Welcome to Utho cloud
                    </h4>
                    <div className="fs-6 text-gray-700">
                      We are always here to help our customers, For any query
                      <a  className="fw-bolder" href="tel:01204840000">
                        Call now
                      </a>
                      .
                    </div>
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*end::Notice*/}
            </div>
           
            {/*end::Card body*/}
          </div>
          {/*end::details View*/}
        </div>
        <div
          id="setting-section"
          className="tab-pane fade show active"
          role="tabpanel"
        >
          {/*begin::Basic info*/}
          <div className="card mb-5 mb-xl-10">
            {/*begin::Card header*/}
            <div
              className="card-header border-0 cursor-pointer"
              aria-expanded="true"
              aria-controls="kt_account_profile_details"
            >
              {/*begin::Card title*/}
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Profile Details</h3>
              </div>
              {/*end::Card title*/}
            </div>
            {/*begin::Card header*/}
            {/*begin::Content*/}
            <div
              id="kt_account_settings_profile_details"
              className="collapse show"
            >
              {/*begin::Form*/}
              <form id="updateprofile" name="updateprofile" className="form">
                {/*begin::Card body*/}
                <div className="card-body border-top p-9">
                  {/*begin::Input group*/}
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label  fw-bold fs-6">
                      Full Name
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8">
                      {/*begin::Row*/}
                      <div className="row">
                        {/*begin::Col*/}
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            id="formf_fullname"
                            name="fullname"
                            defaultValue={userData!=null && userData.fullname!=null?(userData.fullname):('')}
                            placeholder="Full Name"
                            className="form-control form-control-lg 	"
                          />
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label  fw-bold fs-6">
                      Company Name
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        id="formf_company"
                        name="company"
                        defaultValue={userData!=null && userData.company!=null?(userData.company):('')}
                        placeholder="Company Name"
                        className="form-control form-control-lg "
                      />
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label  fw-bold fs-6">
                      Email Address
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="type"
                        id="formf_email"
                        name="email" 
                        defaultValue={userData!=null && userData.email!=null?(userData.email):('')}
                        placeholder="Email Address"
                        readOnly={true}
                        className="form-control form-control-lg"
                      />
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label  fw-bold fs-6">
                      Mobile
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                    {/* <Link to="/account/updateMobile">
                      <a className="btn btn-light-secondary text-primary" style={{cursor:"pointer"}}>Update Mobile
   <span className="svg-icon svg-icon-primary svg-icon-2hx">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.3"
        width={12}
        height={2}
        rx={1}
        transform="matrix(-1 0 0 1 15.5 11)"
        fill="black"
      />
      <path
        d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z"
        fill="black"
      />
      <path
        d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z"
        fill="#C4C4C4"
      />
    </svg>
  </span>
          </a>
         </Link> */}
                      <input
                        type="text"
                        id="formf_mobile"
                        name="mobile"
                        defaultValue={userData!=null && userData.mobile!=null?(userData.mobile):('')}
                        placeholder="Mobile Number"
                        readOnly={true}
                        className="form-control form-control-lg "
                      />
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label  fw-bold fs-6">
                      Billing Address
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <textarea
                        type="text"
                        id="formf_address"
                        name="address"
                        defaultValue={userData!=null && userData.address!=null?(userData.address):('')}
                        placeholder="Street Address"
                        className="form-control form-control-lg " 
                      />
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label  fw-bold fs-6">
                      City
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        id="formf_city"
                        name="city"
                        defaultValue={userData!=null && userData.city!=null?(userData.city):('')}
                        placeholder="City"
                        className="form-control form-control-lg "
                      />
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label  fw-bold fs-6">
                      Postcode
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        id="formf_postcode"
                        name="postcode"
                        defaultValue={userData!=null && userData.postcode!=null?(userData.postcode):('')}
                        placeholder="Postcode"
                        className="form-control form-control-lg "
                      />
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-bold fs-6">
                      <span className="">Country</span>
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <select
                        id="formf_country"
                        name="country" 
                        className="form-select  form-select-lg fw-bold"  
                        onChange={(e)=>updateStates(e.target.value)}
                      > 
                        {countryObject && Object.entries(countryObject).map((value,index)=>(
                          <>
                          {userData!=null && userData.country==value[0]?(
                          <>
                            <option value={value[0]} selected>{value[1]}</option>
                          </>):(
                          <>
                          <option value={value[0]}>{value[1]}</option>
                          </>
                          )} 
                          </>
                        ))}
                      </select>
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label  fw-bold fs-6">
                      State
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row" id="auto-state">
                      <select
                        name="state"
                        className="form-select form-select-lg fw-bold"
                        id="selectState"
                      >
                        <option>Select State</option> 
                        {stateData && stateData.map((value,index)=>(
                          <>
                          {userData!=null && userData.state==value?(
                          <><option value={value} selected>{value}</option></>):
                          (<><option value={value}>{value}</option></>)}
                          </>
                        ))}
                      </select>
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label  fw-bold fs-6">
                      GST Number
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        id="formf_gstnumber"
                        name="gstnumber"
                        placeholder="GST number"
                        className="form-control form-control-lg "
                        defaultValue={userData!=null && userData.gstnumber!=null?(userData.gstnumber):('')}
                      />
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6 d-none">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-bold fs-6">
                      <span className="">
                        {" "}
                        Single Invoice of Billing Cycle?
                      </span>
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row" id="billing_sec">
                      <select
                        name="singleinvoice" 
                        className="form-select  form-select-lg fw-bold"
                      >
                        <option>Select Currency</option>
                        {userData!=null && userData.singleinvoice==1?(
                          <><option value={1} > Yes, I want single invoice of month </option>
                            <option value={0} selected="Selected">No, I need separate invoices</option>
                          </>
                        ):(
                          <><option value={1}> Yes, I want single invoice of month </option>
                          <option value={0} selected="Selected">No, I need separate invoices</option>
                          </>
                           )}
                         
                      </select>
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  <div id="currency_section">
                    {/*begin::Input group*/}
                    <div className="row mb-6">
                      {" "}
                      {/*begin::Label*/}{" "}
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        {" "}
                        <span className=""> Currency</span>{" "}
                      </label>{" "}
                      {/*end::Label*/} {/*begin::Col*/}{" "}
                      <div className="col-lg-8 fv-row">
                        {" "}
                        <select
                          name="currency"
                          id="formf_currency"  
                          className="form-select form-select-lg fw-bold"
                        >
                           {userData!=null && userData.currency=="INR"?(
                          <>
                           <option value={1}>USD</option>
                          <option selected="Selected" value={2}>INR</option>
                          </>
                        ):(
                          <>
                           <option selected="Selected" value={1}>USD</option>
                          <option value={2}>INR</option>
                          </>
                           )}
                          
                        </select>{" "}
                      </div>{" "}
                      {/*end::Col*/}{" "}
                    </div>{" "}
                    {/*end::Input group*/}
                  </div>
                </div>
                {/*end::Card body*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                 
                  <a 
                    className="btn btn-primary"
                    onClick={updateAccount}
                    id="updateAccountBtn"
                    >
                    Save Changes
                  </a>
                </div>
                {/*end::Actions*/}
              </form>
              {/*end::Form*/}
            </div>
            
            {/*end::Content*/}
          </div>
          {/*end::Basic info*/}
          {/*begin::Sign-in Method*/}
          <div className="card mb-5 mb-xl-10">
            {/*begin::Card header*/}
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_signin_method"
            >
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Update Password</h3>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Content*/}
            <div
              id="kt_account_settings_signin_method"
              className="collapse show"
            >
              {/*begin::Card body*/}
              <div className="card-body border-top p-9">
                {/*begin::Password*/}
                <div className="d-flex flex-wrap align-items-center mb-10">
                  {/*begin::Label*/}
                  <div id="show-pass" className="">
                    <div className="fs-6 fw-bolder mb-1">Password</div>
                    <div className="fw-bold text-gray-600">************</div>
                  </div>
                  {/*end::Label*/}
                  {/*begin::Edit*/}
                  <div id="update_pass" className="flex-row-fluid d-none">
                    {/*begin::Form*/}
                    <form
                      id="updatepassword"
                      className="form"
                      noValidate="novalidate"
                    >
                      <span
                        className="badge badge-light-danger"
                        id="PassValid"
                      />
                      <span
                            className="text-success fw-bold d-none"
                            id="password_strength">
                            Your password is strong
                          </span>
                          <span
                            className="text-danger fw-bold d-none"
                            id="password_weak">
                            Your password is weak (Password must be containing 1 captial letter, albhabets, numbers and 1 special charactor like- Abcds123@)
                          </span>
                      <div className="row mb-1">
                        <div className="col-lg-4"> 
                          <div className="fv-row mb-0">
                            <br />
                            <label
                              htmlFor="newpassword"
                              className="form-label fs-6 fw-bolder mb-3"
                            >
                              New Password
                            </label>
                            <input
                              type="password"
                              className="form-control form-control-lg "
                              name="password"
                              id="password"
                              onChange={(e) => validate(e.target.value)}
                              placeholder="New password"
                              defaultValue=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex mt-10">
                        <a 
                          onClick={updatePassword}
                          className="btn btn-primary me-2 px-6"
                          id="updatePasswordBtn"
                        >
                          Update Password
                        </a>
                        <a
                          onClick={()=>resetPasswordContent("hide")}
                          className="btn btn-secondary btn-active-light-secondary px-6"
                        >
                          Cancel
                        </a>
                      </div>
                    </form>
                    {/*end::Form*/}
                  </div>
                  {/*end::Edit*/}
                  {/*begin::Action*/}
                  <div id="reset-password" className="ms-auto">
                    <button onClick={()=>resetPasswordContent("show")} className="btn btn-light btn-active-light-primary">
                      Reset Password
                    </button>
                  </div>
                  {/*end::Action*/}
                </div>
                {/*end::Password*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Sign-in Method*/}
          <div className="card mb-5 mb-xl-10 ">
            {/*begin::Card header*/}
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_connected_accounts"
              aria-expanded="true"
              aria-controls="kt_account_connected_accounts"
            >
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Two factor Authentication</h3>
              </div>
              <div className="d-flex justify-content-start p-4">
                <div
                  className="form-check form-check-solid form-switch"
                  id="enableDisable-btn"
                >
                  {userData!=null && (userData.twofa_settings!="none"?(
                        <a
                        onClick={()=>twoFaAuth('none')}
                        className="btn btn-danger btn-sm" 
                        id="update2faBtn"
                      >
                        Disable two-factor
                      </a>
                        ):(
                          <a
                          onClick={()=>twoFaAuth('email')}
                          className="btn btn-primary btn-sm" 
                          id="update2faBtn"
                        >
                          Enable two-factor
                        </a>
                        ))}
                  
                </div>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Content*/}
            <div className="collapse show"  id="2faContent">
              {/*begin::Card body*/}
              <div className="card-body border-top p-9">
                {/*begin::Notice*/}
                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                  {/*begin::Icon*/}
                  {/*begin::Svg Icon | path: icons/duotune/art/art006.svg*/}
                  <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z"
                        fill="black"
                      />
                      <path
                        d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                  {/*end::Icon*/}
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-stack flex-grow-1">
                    {/*begin::Content*/}
                    <div className="fw-bold">
                      <div className="fs-6 text-gray-700">
                        Two-factor authentication adds an extra layer of
                        security to your account. To log in, in you'll need to
                        provide a 6 digit amazing code.
                        {/* <a  class="fw-bolder">Learn More</a> */}
                      </div>
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Notice*/}
                {/*begin::Items*/}
                <div className="py-2">
                  {/*begin::Item*/}
                  <div className="d-flex flex-stack">
                    <div className="d-flex">
                      <div className="w-30px me-6">
                        {/*begin::Svg Icon | path: assets/media/icons/duotune/electronics/elc002.svg*/}
                        <span className="svg-icon svg-icon-primary svg-icon-2hx">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z"
                              fill="black"
                            />
                            <path
                              opacity="0.3"
                              d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z"
                              fill="black"
                            />
                            <path
                              d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </div>
                      <div className="d-flex flex-column">
                        <a
                          
                          className="fs-5 text-dark text-hover-primary fw-bolder"
                        >
                          SMS
                        </a>
                        <div className="fs-6 fw-bold text-gray-400">
                          Enable to apply two factor auth with sms
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="form-check form-check-solid form-switch">
                      {userData!=null && (userData.twofa_settings=="mobile,email" || userData.twofa_settings=="email,mobile" || userData.twofa_settings=="mobile"?(
                        <input
                        className="form-check-input w-45px h-30px"
                        type="checkbox"
                        id="sms_auth" 
                        defaultChecked={true} 
                      />
                        ):(
                          <input
                          className="form-check-input w-45px h-30px"
                          type="checkbox"
                          id="sms_auth"  
                        />
                        ))}
                        <label className="form-check-label" htmlFor="sms" />
                      </div>
                    </div>
                  </div>
                  {/*end::Item*/}
                  <div className="separator separator-dashed my-5" />
                  {/*begin::Item*/}
                  <div className="d-flex flex-stack">
                    <div className="d-flex">
                      <div className="w-30px me-6">
                        {/*begin::Svg Icon | path: assets/media/icons/duotune/technology/teh007.svg*/}
                        <span className="svg-icon svg-icon-primary svg-icon-2hx">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M13 2.375L22 9.57501V20.575C22 21.175 21.6 21.575 21 21.575H3C2.4 21.575 2 21.175 2 20.575V9.57501L11 2.375C11.6 1.875 12.4 1.875 13 2.375ZM18.6 11.975C18.9 11.575 18.8 10.875 18.4 10.575C14.6 7.87501 9.40001 7.87501 5.60001 10.575C5.20001 10.875 4.99999 11.475 5.39999 11.975C5.69999 12.375 6.29999 12.575 6.79999 12.175C9.89999 9.97499 14.1 9.97499 17.3 12.175C17.5 12.275 17.7 12.375 17.9 12.375C18.1 12.475 18.4 12.275 18.6 11.975ZM14 18.575C14.3 18.175 14.2 17.475 13.8 17.175C12.8 16.375 11.4 16.375 10.3 17.175C9.89999 17.475 9.70001 18.075 10.1 18.575C10.4 18.975 11 19.175 11.5 18.775C11.8 18.575 12.3 18.575 12.7 18.775C12.9 18.875 13.1 18.975 13.3 18.975C13.5 18.975 13.8 18.775 14 18.575ZM16.3 15.275C16.6 14.875 16.5 14.175 16.1 13.875C13.7 12.175 10.3 12.175 8 13.875C7.6 14.175 7.39999 14.775 7.79999 15.275C8.19999 15.775 8.70001 15.875 9.20001 15.475C10.9 14.275 13.3 14.275 15 15.475C15.2 15.575 15.4 15.675 15.6 15.675C15.8 15.675 16.1 15.575 16.3 15.275Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </div>
                      <div className="d-flex flex-column">
                        <a
                          
                          className="fs-5 text-dark text-hover-primary fw-bolder"
                        >
                          Email
                        </a>
                        <div className="fs-6 fw-bold text-gray-400">
                          Enable to apply two factor auth with email
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="form-check form-check-solid form-switch">
                      {userData!=null && (userData.twofa_settings=="mobile,email" || userData.twofa_settings=="email,mobile" || userData.twofa_settings=="email"?(
                        <input
                        className="form-check-input w-45px h-30px"
                        type="checkbox"
                        id="email_auth" 
                        defaultChecked={true} 
                      />
                        ):(
                          <input
                          className="form-check-input w-45px h-30px"
                          type="checkbox"
                          id="email_auth"  
                        />
                        ))}
                         
                        <label className="form-check-label" htmlFor="Email" />
                      </div>
                    </div>
                  </div>
                  {/*end::Item*/}
                </div>
                {/*end::Items*/}
              </div>
              {/*end::Card body*/}
              {/*begin::Card footer*/}
              <form id="authSetup">
                <input
                  type="hidden"
                  id="twofa_input"
                  name="twofa"
                  defaultValue=""
                />
              </form>
              <div
                className="card-footer d-flex justify-content-end py-6 px-9"
                id="two-footer"
              >
                
                <button className="btn btn-primary" onClick={()=>twoFaAuth('update')} id="save2faBtn">
                  Save Changes
                </button>
              </div>
              {/*end::Card footer*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*begin::Deactivate Account*/}
          <div className="card ">
            {/*begin::Card header*/}
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_deactivate"
              aria-expanded="true"
              aria-controls="kt_account_deactivate"
            >
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Deactivate Account</h3>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Content*/}
            <div id="kt_account_settings_deactivate" className="collapse show">
 {/*begin::Form*/}
 <form id="closeaccount" className="form">
                {/*begin::Card body*/}
                <div className="card-body border-top p-9">
            <div className="mb-5 notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
  {/*begin::Icon*/}
  {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
  <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.3"
        x={2}
        y={2}
        width={20}
        height={20}
        rx={10}
        fill="black"
      />
      <rect
        x={11}
        y={14}
        width={7}
        height={2}
        rx={1}
        transform="rotate(-90 11 14)"
        fill="black"
      />
      <rect
        x={11}
        y={17}
        width={2}
        height={2}
        rx={1}
        transform="rotate(-90 11 17)"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
  {/*end::Icon*/}
  {/*begin::Wrapper*/}
  <div className="d-flex flex-stack flex-grow-1">
    {/*begin::Content*/}
    <div className="fw-bold">
      <h4 className="text-gray-900 fw-bolder">We need your attention!</h4>
      <div className="fs-6 text-gray-700">
      Before deleting your account, please note that it cannot be recovered once deleted. Also, ensure that all your resources are deleted beforehand to avoid any data loss.
        .
      </div>
    </div>
    {/*end::Content*/}
  </div>
  {/*end::Wrapper*/}
</div> 
             
                  {/*begin::Form input row*/}
                  <div className="form-check form-check-solid fv-row">
                    <label
                      className="form-check-label fw-bold ps-2 fs-6"
                      htmlFor="deactivate"
                    >
                      I confirm my account deactivation
                    </label>
                    <input
                      name="email"
                      className="form-control"
                      type="text"
                      defaultValue=""
                      id="email"
                      placeholder="Enter registered email id."
                    />
                  </div>
                  {/*end::Form input row*/}
                </div>
                {/*end::Card body*/}
                {/*begin::Card footer*/}
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <a
                     onClick={deleteAccount}
                     className="btn btn-danger fw-bold"
                     id="deleteAccountBtn"
                  >
                    Deactivate Account
                  </a>
                </div>
                {/*end::Card footer*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Deactivate Account*/}
        </div>
        <div id="kyc-section" className="tab-pane fade" role="tabpanel">
          <div className="card">
            <div className="card-body d-flex flex-column flex-center">
              {/*begin::Heakeyding*/}
              <div className="mb-2">
                {/*begin::Title*/}
                <h1 className="fw-bold text-gray-800 text-center lh-lg">
                  You have not complete you kyc,
                  <br />
                  Please
                  <span className="fw-boldest">Complete you KYC</span>
                </h1>
                {/*end::Title*/}
                {/*begin::Illustration*/}
                <div
                  className="flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center card-rounded-bottom h-200px mh-200px my-5 my-lg-12"
                  style={{
                    backgroundImage:
                      'url("assets/media/svg/illustrations/easy/1.svg")'
                  }}
                />
                {/*end::Illustration*/}
              </div>
              {/*end::Heading*/}
              {/*begin::Links*/}
              <div className="text-center">
                <form
                  action="https://console.utho.com/kyc.php"
                  method="POST"
                >
                  <input
                    type="hidden"
                    name="kyc_email"
                    id="kyc_email"
                    defaultValue="nitin.codingbugs@gmail.com"
                  />
                  <input
                    type="hidden"
                    name="kyc_fullname"
                    id="kyc_fullname"
                    defaultValue="Nitin Rajpu"
                  />
                  {/*begin::Link*/}
                  <button type="submit" className="btn btn-sm btn-primary me-2">
                    Complete KYC
                    {/*end::Link*/}
                  </button>
                </form>
              </div>
              {/*end::Links*/}
            </div>
          </div>
        </div>
        <div id="api-key-section" className="tab-pane fade" role="tabpanel">
          {/*begin::API keys*/}
          <div className="card">
            {/*begin::Card header*/}
            <div className="card-header">
              {/*begin::Heading*/}
              <div className="card-title">
                <h3>API Key</h3>
              </div>
              {/*end::Heading*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar">
                <a
                  
                  className="btn btn-sm btn-primary me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#generateapi-modal"
                >
                  Genrate New API
                </a>
              </div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Body*/}
            <div className="card-body p-0">
              {/*begin::Table wrapper*/}
              <div className="table-responsive container">
                {/*begin::Table*/}
                <table className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
                  {/*begin::Thead*/}
                  <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                    <tr>
                      <th className="w-250px min-w-175px ps-9">Name</th>
                      <th className="min-w-125px min-w-125px">Created</th>
                      <th className="min-w-125px">Access Scope</th>
                      <th className="w-200px">Action</th>
                    </tr>
                  </thead>
                  {/*end::Thead*/}
                  {/*begin::Tbody*/}
                  <tbody
                    id="apiList"
                    className="fs-6 fw-bold text-gray-600"
                  ></tbody>
                  {/*end::Tbody*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table wrapper*/}
            </div>
            {/*end::Body*/}
          </div>
          {/*end::API keys*/}
          {/*begin::Modals*/}
          {/*begin::Modal - Create Api Key*/}
          <div
            className="modal fade"
            id="generateapi-modal"
            tabIndex={-1}
            aria-hidden="true"
          >
            {/*begin::Modal dialog*/}
            <div className="modal-dialog modal-dialog-centered mw-650px">
              {/*begin::Modal content*/}
              <div className="modal-content">
                {/*begin::Modal header*/}
                <div
                  className="modal-header"
                  id="kt_modal_create_api_key_header"
                >
                  {/*begin::Modal title*/}
                  <h2>Create API Key</h2>
                  {/*end::Modal title*/}
                  {/*begin::Close*/}
                  <div
                    className="btn btn-sm btn-icon btn-active-color-primary"
                    data-bs-dismiss="modal"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x={6}
                          y="17.3137"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-45 6 17.3137)"
                          fill="black"
                        />
                        <rect
                          x="7.41422"
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(45 7.41422 6)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Close*/}
                </div>
                {/*end::Modal header*/}
                {/*begin::Form*/}
                <form name="generateapi" id="generateapi" className="form">
                  {/*begin::Modal body*/}
                  <div className="modal-body py-10 px-lg-17">
                    {/*begin::Scroll*/}
                    <div
                      className="scroll-y me-n7 pe-7"
                      id="kt_modal_create_api_key_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                      data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      {/*begin::Input group*/}
                      <div className="mb-5 fv-row">
                        {/*begin::Label*/}
                        <label className=" fs-5 fw-bold mb-2">API Name</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Your API Name"
                          name="name"
                        />
                        {/*end::Input*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className="mb-5 fv-row">
                        <label className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            name="write"
                            type="checkbox"
                          />
                          <span className="form-check-label">Write access</span>
                        </label>
                      </div>
                      {/*end::Input group*/}
                    </div>
                    {/*end::Scroll*/}
                  </div>
                  {/*end::Modal body*/}
                  {/*begin::Modal footer*/}
                  <div className="modal-footer flex-center">
                    {/*begin::Button*/}
                    {/*end::Button*/}
                    {/*begin::Button*/}
                    <button
                      type="button"
                      id="generateapi-btn"
                      onclick="call_api('POST','api/generate','generateapi','getAPIKey')"
                      className="btn btn-primary"
                    >
                      <span className="indicator-label">Generate New Key</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    </button>
                    {/*end::Button*/}
                    <p
                      id="apikey"
                      className="message success"
                      style={{ display: "none" }}
                    />
                  </div>
                  {/*end::Modal footer*/}
                </form>
                {/*end::Form*/}
              </div>
              {/*end::Modal content*/}
            </div>
            {/*end::Modal dialog*/}
          </div>
          {/*end::Modal - Create Api Key*/}
          {/*end::Modals*/}
        </div>
        <div id="ssh-key-section" className="tab-pane fade" role="tabpanel">
          {/*begin::API keys*/}
          <div className="card">
            {/*begin::Card header*/}
            <div className="card-header">
              {/*begin::Heading*/}
              <div className="card-title">
                <h3>SSh Key</h3>
              </div>
              {/*end::Heading*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar">
                <a
                  
                  className="btn btn-sm btn-primary me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#importkey-modal"
                >
                  Import SSH Key
                </a>
              </div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Body*/}
            <div className="card-body p-0">
              {/*begin::Table wrapper*/}
              <div className="table-responsive container ">
                {/*begin::Table*/}
                <table
                  className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                  id="kt_api_keys_table"
                >
                  {/*begin::Thead*/}
                  <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                    <tr>
                      <th className="w-250px min-w-175px ps-9">ID</th>
                      <th className="w-275px min-w-250px px-0">Name</th>
                      <th className="w-125px min-w-125px">SSH Key</th>
                      <th className="min-w-125px min-w-125px">Created</th>
                      <th className="w-200px">Action</th>
                    </tr>
                  </thead>
                  {/*end::Thead*/}
                  {/*begin::Tbody*/}
                  <tbody
                    id="sshList"
                    className="fs-6 fw-bold text-gray-600"
                  ></tbody>
                  {/*end::Tbody*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table wrapper*/}
            </div>
            {/*end::Body*/}
          </div>
          {/*end::API keys*/}
          {/*begin::Modals*/}
          {/*begin::Modal - Create Api Key*/}
          <div
            className="modal fade"
            id="importkey-modal"
            tabIndex={-1}
            aria-hidden="true"
          >
            {/*begin::Modal dialog*/}
            <div className="modal-dialog modal-dialog-centered mw-650px">
              {/*begin::Modal content*/}
              <div className="modal-content">
                {/*begin::Modal header*/}
                <div
                  className="modal-header"
                  id="kt_modal_create_api_key_header"
                >
                  {/*begin::Modal title*/}
                  <h2>Import SSH Key</h2>
                  {/*end::Modal title*/}
                  {/*begin::Close*/}
                  <div
                    className="btn btn-sm btn-icon btn-active-color-primary"
                    data-bs-dismiss="modal"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x={6}
                          y="17.3137"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-45 6 17.3137)"
                          fill="black"
                        />
                        <rect
                          x="7.41422"
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(45 7.41422 6)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Close*/}
                </div>
                {/*end::Modal header*/}
                {/*begin::Form*/}
                <form name="importkey" id="importkey" className="form">
                  {/*begin::Modal body*/}
                  <div className="modal-body py-10 px-lg-17">
                    {/*begin::Scroll*/}
                    <div
                      className="scroll-y me-n7 pe-7"
                      id="kt_modal_create_api_key_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                      data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      {/*begin::Input group*/}
                      <div className="mb-5 fv-row">
                        {/*begin::Label*/}
                        <label className=" fs-5 fw-bold mb-2"> Name</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Name"
                          name="name"
                        />
                        {/*end::Input*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className="d-flex flex-column mb-5 fv-row">
                        {/*begin::Label*/}
                        <label className=" fs-5 fw-bold mb-2">
                          SSH Key Content
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <textarea
                          className="form-control "
                          type="text"
                          rows={3}
                          name="sshkey"
                          placeholder="SSH"
                          defaultValue={""}
                        />
                        {/*end::Input*/}
                      </div>
                      {/*end::Input group*/}
                    </div>
                    {/*end::Scroll*/}
                  </div>
                  {/*end::Modal body*/}
                  {/*begin::Modal footer*/}
                  <div className="modal-footer flex-center">
                    {/*begin::Button*/}
                    <button
                      type="button"
                      id="importkey-btn"
                      onclick="call_api('POST','key/import','importkey','getSSHKey')"
                      className="btn btn-primary"
                    >
                      <span className="indicator-label">Import SSH Key</span>
                    </button>
                    {/*end::Button*/}
                  </div>
                  {/*end::Modal footer*/}
                </form>
                {/*end::Form*/}
              </div>
              {/*end::Modal content*/}
            </div>
            {/*end::Modal dialog*/}
          </div>
          {/*end::Modal - Create Api Key*/}
          {/*end::Modals*/}
        </div>
      </div>
      {/*end::Container*/}
    </div>
    {/*end::Post*/}
  </div>
  {/*end::Content*/}
</div>
</>):(
<>
<TopBarHeader/>
{/* <div class="loading-spinner2"></div> */}
</>
)}
        </>
    )
}