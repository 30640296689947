import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import useRazorpay from "react-razorpay";
import UseApi from "../../../../../../../CustomHooks/useApi";
import UsePostData from "../../../../../../../CustomHooks/usePostData";
import spinner from "../../../spinner.svg";
import localStorage from "local-storage";
import Cookies from "js-cookie";
const AddFundRazorpay = () => {
  const [currencyprefix, setCurrencyprefix]=useState(null);
  const filesUrl=localStorage.get("filesUrl");
  const [siteUrl, setSiteUrl]=useState(null);
  const app_info=localStorage.get("appInfo");
  const [user_data,setUser_data]=useState(null);
  async function getUser(){
    const u_data = await UseApi("get", "account/info", "");
    if(u_data){
      if(u_data.user){
      setUser_data(u_data.user);
      if(u_data.user.currency=="INR"){
        setCurrencyprefix("Rs.");
      }else{
        setCurrencyprefix("$");
      }
    }
  }
   }
   useEffect(()=>{
    getUser();
   },[])
   function generateUniqueOrderID() {
    // Implement logic to generate a unique order ID, for example, a combination of timestamp and a random number
    return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
  }
  const Razorpay = useRazorpay();
  const handlePayment = useCallback(async () => {
    toast.dismiss();
    const payBtn = document.querySelector("#payment-button");
    var btnHtml = payBtn.innerHTML;
    if (payBtn) {
      payBtn.innerHTML =
        'Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span>';
      payBtn.classList.add("disabled");
    }
    const u_data = await UseApi("get", "account/info", "");
    const uData = u_data.user;
    const u_razorpay_info = await UseApi("get", "mhcsecinfo", "");
  //  console.log("razopray key");
  //  console.log(u_razorpay_info);
    const amountFund = document.querySelector("#amountFund");
    if(filesUrl){
    setSiteUrl(filesUrl);
    }
    // var rorder_id = "";
    var rfullname = "";
    var rmobile = "";
    var remail = "";
    var rcurrency = "";
    var ramount=0;

    if (uData) {
      // rorder_id = uData.razorpay_orderid;
      rfullname = uData.fullname;
      rmobile = uData.mobile;
      remail = uData.email;
      rcurrency = uData.currency;
      setCurrencyprefix(uData.currencyprefix);
    }
    if(amountFund){
      ramount=amountFund.value;
    }
    if(uData.currency!="INR"){
       if(ramount<1){
        swal({
            title: "Add Fund",
            text: "Sorry you need to add minimum $1",
            icon: "warning",
            button: "OK",
          });
          if (payBtn) {
            payBtn.innerHTML = btnHtml;
            payBtn.classList.remove("disabled");
          }
          return;
       }
    }
    if(uData.currency=="INR"){
      if(ramount<1){
       swal({
           title: "Add Fund",
           text: "Sorry you need to add minimum Rs.1",
           icon: "warning",
           button: "OK",
         });
         if (payBtn) {
          payBtn.innerHTML = btnHtml;
          payBtn.classList.remove("disabled");
        }
         return;
      }
   }
  //  const rorder_id = generateUniqueOrderID();
    const options = {
      // key: "rzp_live_aeMFERiIAsYFFF",
      key: u_razorpay_info.razorpay_key,
      // key:"rzp_live_k7fSHvV65rLqOJ",
      customer_id:uData.razorpay_customerid,
      amount: parseInt(ramount * 100),
      // order_id: rorder_id,
      currency: rcurrency,
      name: app_info?(app_info.brand):(''),
      description: "Add Fund",
      image: (filesUrl!=null?(filesUrl):(''))+"/publicfiles/"+(app_info?(u_razorpay_info.logo):('')),
      prefill: { 
        contact: rmobile,
      },
      options: {
        checkout: {
          method: {
            netbanking: 1,
            card: 1,
            upi: 1,
          },
        },
      },
      handler: async (res) => {
        //res.razorpay_payment_id
        //res.status_code
        //pay_L0C8DacFM1Voqi
        // console.log(res);
        if (res.status_code == 200) {
          swal({
            title: "Processing please wait...",
            icon: spinner,
            html: true,
            showSpinner: true,
          });
          const resData = await UsePostData("post", "billing/addpayment", {
            platform: "2023",
            invoiceid: "Addfund",
            transid: res.razorpay_payment_id,
            amount: ramount,
            currency: rcurrency,
            gateway: "Razorpay",
          });
          //console.log(resData);
          if (resData.status == "success") {
            //console.log("added");
            swal({
              title: "Add Fund",
              text: "Fund added successfuly",
              icon: "success",
              button: "OK",
            });
            const couponName=Cookies.get("coupon");
            if(couponName){
            const applyCoupon = await UsePostData("post", "account/applycoupon", {"coupon":couponName});
            if(applyCoupon){
                if(applyCoupon.status=="success"){
                  setTimeout(() => {
                    window.location.reload();
                  }, 4000);
                }
            }
            }else{
              setTimeout(() => {
                window.location.reload();
              }, 4000);
            } 
          } else {
            swal({
              title: "Add Fund",
              text: "Fund not added in your account!",
              icon: "warning",
              button: "OK",
            });
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          } 
        }
      },
      notes: {
        u_userid: uData.id,
        u_type: "Add Fund",
      },
      theme: {
        color: "#377dff",
      },
    };
     
    console.log(options, "options");
    const rzpay = new Razorpay(options);
    rzpay.open();
    if (payBtn) {
      payBtn.innerHTML = btnHtml;
      payBtn.classList.remove("disabled");
    }
  }, [Razorpay]);
  return (
    <>
          <input
            type="hidden"
            className="form-control"
            placeholder="Please enter the amount"
            id="amountFund"
            defaultValue={1}
            name="amount"
          />
        <a
          onClick={handlePayment}
          id="payment-button"
          className="btn btn-primary"
        >
          Continue
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                  <span className="svg-icon svg-icon-4 ms-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={18}
                        y={13}
                        width={13}
                        height={2}
                        rx={1}
                        transform="rotate(-180 18 13)"
                        fill="black"
                      />
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
        </a> 
    </>
  );
};

export default AddFundRazorpay;
