import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseLogin from "../CustomHooks/useLogin";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import UseApi from "../CustomHooks/useApi";
import CallAppinfo from "../CustomHooks/CallAppinfo";
import axios from "axios";
import localStorage from "local-storage";
import Cookies from "js-cookie";
import "./custom.css";
import ApplyCoupon from "./ApplyCoupon";
export default function Signup(){
  const navigate=useNavigate();
  var current_url_string = window.location.href;
  const current_url = new URL(current_url_string);
  const current_domain = current_url.hostname;
  const [app_info, setApp_info] = useState(null);
  const [logo_white, setLogo_white] = useState(null);
  async function currApp(){
    const AppData=await axios.get('https://api.utho.com/v2/appinfo');
    if(AppData && AppData.data){
      //console.log("data come");
    setApp_info(AppData.data);
    //console.log(AppData);
   var siteUrlString=AppData.data.site;

   var siteUrlArr=siteUrlString.split(".");
    var siteLength=siteUrlArr.length;

   var siteOrg="https://api."+siteUrlArr[siteLength-2]+"."+siteUrlArr[siteLength-1];
   localStorage.set("filesUrl",siteOrg);
   var whiteLogo=siteOrg+"/publicfiles/"+AppData.data.logo_light;
   setLogo_white(whiteLogo);
  }
}
  const signupAccount=async()=>{
    function sleep(delay) {
      var start = new Date().getTime();
      while (new Date().getTime() < start + delay);
  }
    var submitBtn=document.querySelector("#signup-btn");
      if(submitBtn){
        var preContent=submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
    const dt=toast.loading("Please wait...");
    const responseData=await UseLogin('post','account/signup','signup');
   if(responseData){
    submitBtn.innerHTML=preContent;
    submitBtn.classList.remove("disabled");
    if(responseData.status=='loginsuccess'){
      toast.update(dt,{render: "Signup successfuly!", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      localStorage.set("Auth",responseData.token);
      Cookies.set('microhost_api_auth', responseData.token, { expires: 30, path: '/', domain: "."+current_domain });
      const apiUrl = 'https://api.utho.com/v2/appinfo';
      const headers = { 
        'Content-Type': 'application/json', // Adjust as needed
      };

      // Define the Fetch options
      const fetchOptions = {
        method: 'GET',
        headers: headers // Change to 'POST', 'PUT', 'DELETE', etc. if needed 
      };

 const AppData= fetch(apiUrl, fetchOptions)
  .then(response => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json(); // Parse the response body as JSON
  })
  .then(data => { 
    if (data) {  
      localStorage.set("appInfo",data);
    } 
     
  })
  .catch(error => {
    console.error('API request failed:', error); 
  });
      // const now = new Date();
      // const expires = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days
      // document.cookie = "microhost_api_auth="+responseData.token+"; expires=" + expires.toUTCString() + "; path=/";
      // const data=await CallAppinfo();
      
      
      if(AppData){
        
        setTimeout(()=>{
          navigate("/");
        },2000) 
      // const location_url = window.location.origin;
      //  window.location.href = location_url;
      }
      }else{
      toast.update(dt,{render: responseData.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false,  closeOnClick: true,  pauseOnHover: true,  draggable: true, progress: undefined
    });
    }
  }
}
   }
   useEffect(() => {
    currApp();
    window.addEventListener("keydown", function(event) {
      event.stopPropagation();
      var query = window.location.href;
      var vars = query.split("/");
      var action=vars[3];
      if(event.key=="Enter" && action=="signup"){
      ////console.log(event);
      signupAccount();
    }

  });
  }, []);
    return(
        <>
        <ApplyCoupon />
          <div className="page d-flex flex-row flex-column-fluid loginbg">
        <div
  className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative d-none d-lg-block d-md-block d-xxl-block"
>
    {/*begin::Wrapper*/}
  <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">

    </div>
</div>

<div className="d-flex flex-column flex-lg-row-fluid py-10 top-front">
    {/*begin::Logo*/}
  <a href="/" className="text-center ">
  {logo_white!=null?(<>
    <img
          alt="Logo"
          src={logo_white}
          className="h-60px logo-fade-in"
        />
  </>):(<>
    <img
          alt="Logo"
          src="./assets/media/logos/loader-black.png"
          className="h-60px logo-fade-in"
        />
  </>)}
      </a>
      {/*end::Logo*/}
  {/*begin::Content*/}
  <div className="d-flex flex-center flex-column flex-column-fluid">
    {/*begin::Wrapper*/}
    <div className="w-lg-600px p-10 p-lg-15 mx-auto">
      {/*begin::Form*/}
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate="novalidate"
        id="signup"
      >
        {/*begin::Heading*/}
        <div className="mb-10 text-center">
          {/*begin::Title*/}
          <h1 className="text-light mb-3">Create an Account</h1>
          {/*end::Title*/}
          {/*begin::Link*/}

          <div className="text-gray-400 fw-bold fs-4">
            Already have an account?  
            <Link to="/login">
            <a 
              className="link-primary fw-bolder me-1"
            >
              &nbsp; Sign in here
            </a>
            </Link>
          </div>

          {/*end::Link*/}
        </div>
        {/*end::Heading*/}

        {/*begin::Input group*/}
        <div className="row fv-row mb-7 fv-plugins-icon-container">
          {/*begin::Col*/}
          <div className="col-xl-12">
            <label className="form-label fw-bolder text-gray-400 fs-6">
              Full Name
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              placeholder="Enter Fullname"
              name="fullname"
              autoComplete="off"
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
          {/*end::Col*/}

        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="fv-row mb-7 fv-plugins-icon-container">
          <label className="form-label fw-bolder text-gray-400 fs-6">Email</label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="email"
            placeholder="Enter Email"
            name="email"
            autoComplete="off"
          />
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div
          className="mb-10 fv-row fv-plugins-icon-container"
          data-kt-password-meter="true"
        >
          {/*begin::Wrapper*/}
          <div className="mb-1">
            {/*begin::Label*/}
            <label className="form-label fw-bolder text-gray-400 fs-6">
              Password
            </label>
            {/*end::Label*/}
            {/*begin::Input wrapper*/}
            <div className="position-relative mb-3">
              <input
                className="form-control form-control-lg form-control-solid"
                type="password"
                placeholder="Enter Password"
                name="password"
                autoComplete="off"
              />
              {/* <span
                className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                data-kt-password-meter-control="visibility"
              >
                <i className="bi bi-eye-slash fs-2" />
                <i className="bi bi-eye fs-2 d-none" />
              </span> */}
            </div>
            {/*end::Input wrapper*/}
            {/*begin::Meter*/}
            {/* <div
              className="d-flex align-items-center mb-3"
              data-kt-password-meter-control="highlight"
            >
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" />
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" />
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" />
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px" />
            </div> */}
            {/*end::Meter*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Hint*/}
          {/* <div className="text-muted">
            Use 8 or more characters with a mix of letters, numbers &amp;
            symbols.
          </div> */}
          {/*end::Hint*/}
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        {/*end::Input group=*/}
        {/*begin::Input group*/}
        {/* <div className="fv-row mb-5 fv-plugins-icon-container">
          <label className="form-label fw-bolder text-dark fs-6">
            Confirm Password
          </label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="password"
            placeholder=""
            name="confirm-password"
            autoComplete="off"
          />
          <div className="fv-plugins-message-container invalid-feedback" />
        </div> */}
        {/*end::Input group*/}
        {/*begin::Actions*/}
        <div className="text-center">
          <button
            type="button"
            onClick={signupAccount}
            className="btn btn-lg btn-primary w-100"
            id="signup-btn"
          >
            <span className="indicator-label" >Create account</span>
            </button>
        </div>
        {/*end::Actions*/}
        <div />
      </form>
      {/*end::Form*/}
    </div>
    {/*end::Wrapper*/}
  </div>
  {/*end::Content*/}
</div>
</div>
        </>
    )
}
