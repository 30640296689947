import React from "react";   
import AddingFund from "./AddingFund";
import ApplyCoupon from "./ApplyCoupon";
export default function AddFundTab() { 
  
 
    return (
        <>
          <div
            className="tab-pane fade show active"
            id="addfund"
            role="tabpanel"
          > 
            {/*begin::License usage*/}
            <div className="">
              <div className="col-xl-12">
                {/*begin::Mixed Widget 2*/}
                <div className=" card-xl-stretch">
                  {/*begin::Header*/}
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body p-0">
                    {/*begin::Chart*/}
                    {/*end::Chart*/}
                    {/*begin::Stats*/}
                    <div className="">
                       <AddingFund />
                       <ApplyCoupon />
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Body*/}
                </div>
                {/*end::Mixed Widget 2*/}
              </div>
            </div>
            {/*end::License usage*/}
          </div>
        </>
    );
}
