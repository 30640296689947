import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import ReactPaginate from 'react-paginate'; 
import AccessDenied from "../../AccessDenied";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
export default function Snapshot(){
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess]=useState(true);
  const [perPage, setPerPage]=useState(5);
  const [snapshotData,setSnapshotData]=useState(null);
  const dataFetchedRef=useRef (false);
  const navigate=useNavigate();
  const navRedirect=()=>{
    setTimeout(() => {
      navigate("/snapshots");
     }, 3000);
      
  }
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }
  const getSnapshot=async()=>{
    
    const dt=toast
       const data=await UseApi('get','snapshot','');
       if(data){
        setDataFetched(true);
       }
       if('status' in data){
        if(data.status=="error"){ 
           toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });  
          setAccess(false);
          setSnapshotData(null); 
          loaderFunc(); 
          return;
        }
     }
       if(Object.keys(data).length === 0){ 
        setSnapshotData(null);
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            loaderFunc(); 
       }else{
        setSnapshotData(data.snapshots);  
        toast.dismiss();
          //    toast.update(dt,{render: "Your Snapshots request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          //  });  
          
       }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getSnapshot(); 
  },[]); 
  const restoreSnapshot=async(cloudId,snapId)=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once restored the snapshot, your server move to the state of this Snapshot",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("Snapshot restoring..."); 
        const dnsp=await UseApi('POST','cloud/'+cloudId+'/snapshot/'+snapId+'/restore','');
        if(dnsp.status=='error'){
          toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        }else{
          toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        }); 
        getSnapshot();
        navRedirect();
        }
      } 
      });
    
  }

  const deleteSnapshot=async(cloudId,snapId)=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this Snapshot!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("Snapshot deleting..."); 
        const dnsp=await UseApi('DELETE','cloud/'+cloudId+'/snapshot/'+snapId+'/delete','');
        //console.log(dnsp);
        if(dnsp.status=='error'){
          toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        }else{
          toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        }); 
        getSnapshot();
        navRedirect();
        }
      } 
      });
    
  }

  function ProductInfo({ productdata }) { 
    return(
        <>
        
        
        {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((snapData, index) => (
          <>
          <tr>
            
            <td className="p-3 text-center">
              
              <div className=" flex-column justify-content-center w-100 me-2">
                
                <div className=" flex-stack mb-2">
                  
                  <span className=" me-2 fs-7 fw-bold">{snapData.id}</span>
                </div>
              </div>
            </td>
            <td>
              
              <div className="d-flex flex-column w-100 me-2">
                
                <div className="d-flex flex-stack mb-2">
                  <Link to={"/cloud/"+snapData.cloudid+""}>
                  <a 
                    
                    className=" me-2 fs-7 fw-bold"
                  >
                    {snapData.cloudid}
                  </a>
                  </Link>
                </div>
              </div>
            </td>
            <td className="text-end">
              
              <div className="d-flex flex-column w-100 me-2">
                
                <div className="d-flex flex-stack mb-2">
                  
                  <span className=" me-2 fs-7 fw-bold">{snapData.size}GB</span>
                </div>
              </div>
            </td>
            <td>
              
              <div className="d-flex align-items-center">
                
                <div className="symbol symbol-30px me-5">
                  
                  <img src={"/img/flag_"+snapData.dclocation.dccc+".png"} />
                </div>
                <div className="d-flex justify-content-start flex-column">
                  
                  <a
                    
                    className="text-dark fw-bold text-hover-primary fs-6"
                  >
                    {snapData.dclocation.location}
                  </a>
                </div>
              </div>
            </td>
            <td className="text-end">
              
              <div className="d-flex flex-column w-100 me-2">
                
                <div className="d-flex flex-stack mb-2">
                  
                  <span className="text-muted me-2 fs-7 fw-bold">
                    {snapData.createdate}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-center">
              
              <div className="card-toolbar">
                
                <button
                  type="button"
                  className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                  onClick={()=>restoreSnapshot(snapData.cloudid,snapData.id)} 
                  >
                  
                  <span className="svg-icon svg-icon-2">
                    
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      
                      <path
                        d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z"
                        fill="black"
                      />
                      <path
                        opacity="0.3"
                        d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </td>
            <td className="text-center">
              
              <div className="card-toolbar">
                
                <button
                  type="button"
                  className="btn btn-sm btn-icon btn-color-danger btn-active-light-danger"
                  onClick={()=>deleteSnapshot(snapData.cloudid,snapData.id)}
                >
                  
                  <span className="svg-icon svg-icon-2">
                    
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      
                      <path
                        d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                        fill="black"
                      />
                      <path
                        opacity="0.5"
                        d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                        fill="black"
                      />
                      <path
                        opacity="0.5"
                        d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </td>
          </tr>
          </>
           ))):( <>
            <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
            </>)}
            
         </>
    )
}

const [searchTerm,setSearchTerm]=useState('');
function PaginatedItems({ itemsPerPage, ProductData, search }) {
  // We start with an empty list of items. 
  const [pageCount, setPageCount] = useState(0);
  const [vpcData,setVpcData]=useState(null);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    
    const endOffset = itemOffset + itemsPerPage;


  //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
     if(ProductData!=null){
    //   var str="Noida";
   // ////console.log(ProductData);
    const data= ProductData.filter(row => {
     return  Object.values(row).some(value => { 
        if(value!=null){
          if(value.toString().toLowerCase().includes(search.toLowerCase())){
            return value;
          } 
        }
      });
    });
    //////console.log(data);
      // const data=ProductData.filter(item => 
      //        item.hostname.toLowerCase().includes(search.toLowerCase())
      //              ); 

                   if(data.length<=0){
                    loaderFunc();
                   }
    setVpcData(data.slice(itemOffset, endOffset));
    //setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
    // alert(pageCount);
      }
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  
  const handlePageClick = (event) => { 
    const newOffset = event.selected * itemsPerPage % ProductData.length;
    ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
   
  };
  var searchEle=document.querySelector("#searchClient");
  if(searchEle){
    searchEle.focus();
  }
  return (
    <>
      <ProductInfo productdata={vpcData} />
      <td colspan="12">
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      </td>
    </>
  );
}
 
if(dataFetched){
  if(access==false){
    return (
      <>
       <AccessDenied />
      </>
    );
  }
}else{
  return(
    <TopBarHeader/>
  )
}
return(
  <> 
  <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold fs-3 mb-1">
          Current Snapshots
          {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={7}
                y={2}
                width={14}
                height={16}
                rx={3}
                fill="black"
              />
              <rect x={3} y={6} width={14} height={16} rx={3} fill="black" />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </span>
        <span className="text-muted mt-1 fw-bold fs-7">
          View your snapshots
        </span>
      </h3>
      <div className="card-toolbar">
        <div className="position-relative my-1" style={{ marginRight: 20 }}>
          <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
        <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select  
        onChange={e => setPerPage(e.target.value)}
        //data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={5}
        id="perPage"
        
      >
        <option value={5} selected="">
          5 Records
        </option>
        <option value={10}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
  </div>
</div>
      </div>
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    {/*begin::Table container*/}
    <div className="table-responsive">
      {/*begin::Table*/}
      <table
        id="snapTable"
        className="table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className="fw-bold">
            <th className="w-125px p-3 text-center"> #ID </th>
            <th className="w-200px"> Cloud #ID </th>
            <th className="min-w-200px">Snapshot Size</th>
            <th className="min-w-150px">Snapshot Location</th>
            <th className="min-w-100px">Created at</th>
            <th className="min-w-100px text-center">Restore</th>
            <th className="min-w-100px text-center">Action</th>
          </tr>
        </thead>
        {/*end::Table head*/}
        {/*begin::Table body*/}
        <tbody id="snapshot-list"> 
  {snapshotData!=null?(<>
<PaginatedItems itemsPerPage={perPage} ProductData={snapshotData} search={searchTerm}  />
  </>):(<>
    <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
  </>)}
  </tbody>
        {/*end::Table body*/}
      </table>
      {/*end::Table*/}
    </div>
    {/*end::Table container*/}
    {/*begin::Body*/}
  </div>
  {/*end::Tables Widget 9*/}
</div>
  </>
)

}