import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import UseApi from "../../../../CustomHooks/useApi"; 
import { connect, useDispatch } from "react-redux";
import { DeployNodePoolRow } from "../../../../Redux/Services/Action/actions";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
function useForceUpdate(){
  const [value, setValue] = useState(0);  
  return () => setValue(value => value + 1); 
}

const KubernetesDeploy=(props)=>{
  const makeid=(length)=> {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
  const forceUpdate = useForceUpdate();
const [adcdata,setAdcdata]=useState([]);
  const [ddcdata,setDdcdata]=useState([]);
  const dataFetchedRef=useRef(); 
  const dataFetchedRef2=useRef();  
  const [planData,setPlanData]=useState(null);  
  const dispatch=useDispatch(); 
  const user_data=useRef(null);
  const [poolData, setPoolData]=useState(null);
  const rowCount=useRef(2);
  let navigate=useNavigate();
    const getDczone=async()=>{
      // setAdcdata([]);
      // setDdcdata([]);
         const data=await UseApi('get','dczones',''); 
         if(Object.keys(data).length === 0){ 
          setAdcdata([]);
          setDdcdata([]);
         }else{
          
          data.dczones.map(async(value,index)=>{ 
             if(value.status!='active'){
              fetch("https://api.ipify.org?format=json")
              .then(response => response.json())
              .then(data =>{
                var server_ip=data.ip;
                if(server_ip){
              if(server_ip !== '103.209.144.51' || server_ip !== '103.209.144.66' || server_ip !==
                '103.209.145.208' || server_ip !== '103.209.144.81' || server_ip !== '103.209.144.82' ||
                server_ip !== '103.209.144.83' || server_ip !== '103.127.30.91' || server_ip !==
                '103.209.144.60' || server_ip !== '103.127.30.239' || server_ip !== '103.127.28.52'){
                  ddcdata.push(value); 
                } 
              }
              })
              .catch(error => console.error(error));
              // var client=await fetch(`https://geolocation-db.com/json/`)
              // .then(res => res.json()); 
              // var server_ip=client.IPv4;
              // if(server_ip !== '103.209.144.51' || server_ip !== '103.209.144.66' || server_ip !==
              //   '103.209.145.208' || server_ip !== '103.209.144.81' || server_ip !== '103.209.144.82' ||
              //   server_ip !== '103.209.144.83' || server_ip !== '103.127.30.91' || server_ip !==
              //   '103.209.144.60' || server_ip !== '103.127.30.239' || server_ip !== '103.127.28.52'){
              //     ddcdata.push(value); 
              //   } 
                forceUpdate();
             }else{
                 adcdata.push(value);
                 forceUpdate();
             }
          })
            
         }
    }
    useEffect(()=>{
      if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        toast.dismiss();
        getDczone(); 
    },[]);  


    const getPlans=async()=>{  
      const data=await UseApi('get','cloud/getdeploy','');  
      user_data.current=await UseApi('get','account/info','');
     // ////console.log(user_data.current);
      if(Object.keys(data).length === 0){
       setPlanData(null); 
      }else{
       setPlanData(data.plans);  
      // ////console.log(data.plans);
      } 
      setPoolData(props.nodePoolData);
      //console.log(props.nodePoolData);
      setTimeout(()=>{
        calcTotalCost();
      },100)
 }
 
 useEffect(()=>{ 
   if(dataFetchedRef2.current) return;
      dataFetchedRef2.current=true;
   getPlans();   
},[]);


 const openDrawer=(datas)=>{
  var drawerElement = document.querySelector("#kubernetes-modal");
  if(drawerElement){
  drawerElement.style.width='1100px';
   if(datas=='close'){ 
       drawerElement.classList.remove("drawer-on"); 
 }else{
  ////console.log("open drawer"); 
        drawerElement.classList.add("drawer-on"); 
        const node_plan_pool_plan_id=document.querySelector("#node_plan_pool_plan_id");
       node_plan_pool_plan_id.value=datas;
   }
  }
}

const updatePool=(pid)=>{
  const update_pool_yes=document.querySelector('#update_pool_yes_'+pid);
  const update_pool_no=document.querySelector('#update_pool_no_'+pid);
  const update_pool=document.querySelector('#update_pool_'+pid);
  update_pool.classList.toggle('d-none');
  update_pool_yes.classList.toggle('d-none');
  update_pool_no.classList.toggle('d-none');

}

const selectNodeSize=(pid,ram,cpu,disk,bandwidth,price)=>{ 
  const node_plan_pool_plan_id=document.querySelector("#node_plan_pool_plan_id");
  const poolId=node_plan_pool_plan_id.value;
  const show_select_plans=document.querySelector("#show_select_plans_"+poolId);
  const select_kubernetes_plans=document.querySelector("#select_kubernetes_plans_"+poolId);
  const select_ram=document.querySelector("#ram_"+poolId);
  const select_cpu=document.querySelector("#cpu_"+poolId);
  const select_bandwidth=document.querySelector("#bandwidth_"+poolId); 
  const node_select_plan=document.querySelector("#node_select_plan_"+poolId);
  const order_total=document.querySelector("#order_total_"+poolId);
  const currency_=document.querySelector("#currency_final");
  const billing_=document.querySelector("#billing_final");
  const node_count=document.querySelector("#count_"+poolId);
  const plan_price=document.querySelector("#plan_price_"+poolId); 
  console.log(order_total,total_plan_price);
  if(node_count){
  var total_plan_price=(parseFloat(price)*parseInt(node_count.value)*720).toFixed(2);
  plan_price.value=(parseFloat(price)*720).toFixed(2);
  if(user_data.current!=null){
    if(currency_ && order_total && billing_){
  currency_.innerHTML=user_data.current.user.currencyprefix;
  order_total.innerHTML=`${total_plan_price}/mon`;
  billing_.innerHTML="/mon";
    }
  }
  node_select_plan.value=pid;
  show_select_plans.classList.remove('d-none');
  select_kubernetes_plans.classList.add('d-none');
  select_ram.innerHTML=ram;
  select_cpu.innerHTML=cpu;
  select_bandwidth.innerHTML=bandwidth; 
  }
  var drawerElement = document.querySelector("#kubernetes-modal");
  if(drawerElement){
      drawerElement.classList.remove("drawer-on");
  }
  if(props){
  for(var i=0;i<props.nodePoolData.length;i++){
    
    if(props.nodePoolData[i]){
      //console.log(props.nodePoolData[i].pool_id);
      // //console.log("pid => "+pid);
      if(node_plan_pool_plan_id){
    if(props.nodePoolData[i].pool_id==node_plan_pool_plan_id.value){  
      // //console.log(props.nodePoolData[i].nodePoolValue);
       props.nodePoolData[i].totalCost=(parseFloat(price)*720).toFixed(2);
       props.nodePoolData[i].nodeSize=pid;
    }
    
        }
        }
  }
}
  calcTotalCost();
}

function calcTotalCost(){
   var nodePoolCost=document.querySelectorAll(".nodePoolCost");
   var finalCost=0;
   nodePoolCost.forEach((ncost) => {
    if (ncost) {
      // //console.log(ncost);
      var currCost=ncost.innerHTML;
      finalCost+=parseFloat(currCost);
    }
  });
  var total_cost=document.querySelector("#total_cost");
  const currency_=document.querySelector("#currency_final");
  const billing_=document.querySelector("#billing_final");
  if(user_data.current!=null){
    if(total_cost && currency_ && billing_){
      currency_.innerHTML=user_data.current.user.currencyprefix;
      total_cost.innerHTML=finalCost.toFixed(0);
      billing_.innerHTML="/mon";
        }
    }
 
  //console.log(props.nodePoolData);
}

const nodePoolUpdate=(npid)=>{
  const pool_name=document.querySelector("#pool_name_"+npid);
  const node_select_plan=document.querySelector("#node_select_plan_"+npid);
  const node_count=document.querySelector("#count_"+npid);
  const plan_price=document.querySelector("#plan_price_"+npid);
  const node_plan_pool_plan_id=document.querySelector("#node_plan_pool_plan_id");

  for(var i=0;i<props.nodePoolData.length;i++){
    if(props.nodePoolData[i]){
      if(node_plan_pool_plan_id){
    if(props.nodePoolData[i].pool_id==node_plan_pool_plan_id.value){
       props.nodePoolData[i].nodePoolValue=pool_name.value;
       props.nodePoolData[i].nodeSize=node_select_plan.value;
       props.nodePoolData[i].nodeCount=node_count.value;
       props.nodePoolData[i].totalCost=plan_price.value;
    }
  }
  }
  }
  calcTotalCost(); 
 
}
  
  const addFormField = ()=> {  
    rowCount.current+=1;
  var newRow={pool_id:rowCount.current, nodePoolValue:'pool-'+makeid(8), nodeSize:{}, nodeCount:1, totalCost:0.00};  
  props.nodePoolData.push(newRow);
  
  ////console.log(props);
  setPoolData(props.nodePoolData); 
  getPlans();
  calcTotalCost();
  navigate("/kubernetes/deploy");
}
const deleteFormField=(frid)=> {  
  for(var i=0;i<props.nodePoolData.length;i++){
    if(props.nodePoolData[i]){
    if(props.nodePoolData[i].pool_id==frid){
       delete props.nodePoolData[i];
      ////console.log(props.nodePoolData[i]);
    }
  }
  }
  ////console.log(props); 
  calcTotalCost();
  getPlans();
  
  // navigate("/kubernetes/deploy");
  
}

const nodePoolCount=(npid,status)=>{
  const node_count=document.querySelector("#count_"+npid);
  const downCountBtn=document.querySelector("#downcount_"+npid);
  const upCountBtn=document.querySelector("#upcount_"+npid);
  const order_total=document.querySelector("#order_total_"+npid); 
  const currency_=document.querySelector("#currency_final");
  const billing_=document.querySelector("#billing_final");
  const plan_price=document.querySelector("#plan_price_"+npid);
   
  if(status=='up'){
    downCountBtn.classList.remove('disabled');
    if(parseInt(node_count.value)>=5){
      upCountBtn.classList.add('disabled'); 
    }else{
      node_count.value=parseInt(node_count.value)+1; 
      for(var i=0;i<props.nodePoolData.length;i++){
        if(props.nodePoolData[i]){
        if(props.nodePoolData[i].pool_id==npid){
           props.nodePoolData[i].nodeCount=parseInt(node_count.value);
          ////console.log(props.nodePoolData[i]);
        }
      }
      }
    } 
    var total_plan_price=(parseFloat(plan_price.value)*parseInt(node_count.value)).toFixed(2); 
      if(user_data.current!=null){
        if(currency_ && order_total && billing_){
          currency_.innerHTML=user_data.current.user.currencyprefix;
          order_total.innerHTML=total_plan_price;
          billing_.innerHTML="/mon";
            }
        }
   
  }else if(status=='down'){
    upCountBtn.classList.remove('disabled');
    if(parseInt(node_count.value)<=1){
      downCountBtn.classList.add('disabled'); 
    }else{
      node_count.value=parseInt(node_count.value)-1;
      for(var i=0;i<props.nodePoolData.length;i++){
        if(props.nodePoolData[i]){
        if(props.nodePoolData[i].pool_id==npid){
           props.nodePoolData[i].nodeCount=parseInt(node_count.value);
          ////console.log(props.nodePoolData[i]);
        }
      }
      }
    } 
      var total_plan_price=(parseFloat(plan_price.value)*parseInt(node_count.value)).toFixed(2);
      if(user_data.current!=null){
        if(currency_ && order_total && billing_){
          currency_.innerHTML=user_data.current.user.currencyprefix;
          order_total.innerHTML=total_plan_price;
          billing_.innerHTML="/mon";
            }
        }
  } 

  calcTotalCost();
 }
 
 const deployKubernetes=async()=>{
  var submitBtn=document.querySelector("#deploykubernetes-btn");
  if(submitBtn){
    var preContent=submitBtn.innerHTML;
    submitBtn.classList.add("disabled");
    submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';   
  toast.dismiss();
    const dt=toast.loading("Kubernetes deploying, Please wait...");
    const responseData=await UsePost('post','kubernetes/deploy','deploykubernetes');
    if(responseData){
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      
      navigate("/kubernetes/"+responseData.id);
        
      }
    }
  }
    }
  
    return(
        <> 
        {adcdata!=null && poolData!=null?(<>
           <form id="deploykubernetes" className="form">
      <div className="col-xl-12 card mb-5">
        {/*begin::Tables Widget 9*/} 
           {/*begin::Header*/}
           <div className="card-header border-3 mb-5 pt-2">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                    Select DC Location
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                    Data Centers
                    </span>
                  </h3>
                    
                  </div>
   
                  {/*end::Header*/} 
        
            {/* Begin body */}
            {/*begin::Row*/}
            <div className="container pb-5">
            <div

              id="dc-location"
              className="row g-9 mb-5"
              data-kt-buttons="true"
              data-kt-buttons-target="[data-kt-button]"
            >
               
               {adcdata.length>0?(adcdata && adcdata.map((value,index)=>(
                <>
                <div className="col-md-3 col-lg-3 col-xxl-3 dc_sibling">
                  <input
                    className="form-check-input deploy_checkbox "
                    type="radio"
                    name="dcslug"
                    defaultValue={value.slug}
                    id={"dcslug"+value.slug+""} 
                  />
                  <label
                  
                  style={{ WebkitJustifyContent: 'unset' }}
                  id={"dcslugl_"+value.slug+""}
                  htmlFor={"dcslug"+value.slug+""}
                  className=" dcsluglable border-1 border-gray-300 border px-1 py-1">
  {/*begin::Info*/}
  <div className="d-flex flex-stack mb-3">
    {/*begin::Wrapper*/}
    <div className="me-3 p-3">
      {/*begin::Icon*/}
      <img
        src={"/assets/media/flags/svg/"+value.cc+".svg"}
        className="w-35px ms-n1 me-6"
        alt=""
      />
      {/*end::Icon*/}
      {/*begin::Title*/}
      <a 
        className="text-gray-800 text-hover-primary fw-bolder"
      >
        {value.city}
      </a>
      {/*end::Title*/}
    </div>
    {/*end::Wrapper*/}
    {/*begin::Action*/}
    <div className="me-3">
      {/*begin::Menu*/}
      <span className=" text-muted fw-bold justify-content-end">{value.country}</span>
      {/*end::Menu*/}
    </div>
    {/*end::Action*/}
  </div>
  {/*end::Info*/}
  {/*begin::Customer*/}
  {/*end::Customer*/}
</label>
                </div>  
                </>
              ))):(<><div className="small-spinner"></div></>)}
{ddcdata.length>0?(ddcdata && ddcdata.map((value,index)=>(
                <>
                <div className="col-md-3 col-lg-3 col-xxl-3 dc_sibling d-none">
                  <input
                    className="form-check-input deploy_checkbox deploylocationsoldout"
                    type="radio"
                    name="dcslug"
                    onClick={(e)=>e.target.checked=false}
                    defaultValue={value.slug}
                    id={"dcslug"+value.slug+""} 
                  />
                   <label
                  style={{ WebkitJustifyContent: 'unset' }}
                  id={"dcslugl_"+value.slug+""}
                  htmlFor={"dcslug"+value.slug+""}
                  className=" dcsluglable border-1 border-gray-300 border px-1 py-1">
                    {/*begin::Info*/}
                    <div className="d-flex flex-stack mb-3">
                      {/*begin::Wrapper*/}
                      <div className="me-3 p-3">
                        {/*begin::Icon*/}
                        <img
                          src={"/assets/media/flags/svg/"+value.cc+".svg"}
                          className="w-35px ms-n1 me-6"
                          alt=""
                        />
                        {/*end::Icon*/}
                        {/*begin::Title*/}
                        <a 
                          className="text-gray-800 text-hover-primary fw-bolder"
                        >
                          {value.city}
                        </a>
                        {/*end::Title*/}
                      </div>
                      {/*end::Wrapper*/}
                      {/*begin::Action*/}
                      <div className="me-3">
                        {/*begin::Menu*/}
                        <span className=" text-muted fw-bold justify-content-end">{value.country}</span>
                        {/*end::Menu*/}
                      </div>
                      {/*end::Action*/}
                    </div>
                    {/*end::Info*/}
                    {/*begin::Customer*/}
                    {/*end::Customer*/}
                  </label>
                </div>  
                </>
              ))):('')}

            </div>
            </div>
            </div>
            {/*end::Row*/}

            <div className="col-xl-12 card mb-5">
        {/*begin::Tables Widget 9*/} 
           {/*begin::Header*/}
           <div className="card-header border-3 mb-5 pt-2">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                    Select Version
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                    Cluster Label, Version
                    </span>
                  </h3>
                    
                  </div>
   
                  {/*end::Header*/} 
            
            {/*begin::Row*/}
            <div className="container pb-5">
            <div
              id="define-vpc"
              className="row g-9"
              data-kt-buttons="true"
              data-kt-buttons-target="[data-kt-button]"
            >
              <div className="col-md-4 col-lg-4 col-xxl-4">
                <div className="mb-10">
                  <label htmlFor="ClusterLabel" className="required form-label">
                    Cluster Label
                  </label>
                  <input
                    className="form-control  "
                    type="text"
                    name="cluster_label"
                    id="cluster_label"
                    defaultValue={"MyK8S-"+makeid(8)+"-"+(Math.random() + 1).toString(36).substring(8)}
                    placeholder="pool-nkeunuewd"
                  />
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xxl-4">
                <div className="mb-10" id="cluster_v">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="required form-label"
                  >
                    Cluster Version
                  </label>
                  <select
                    className="form-select"
                    name="cluster_version"
                    id="cluster_version" 
                  >
                    <option value="1.26" data-select2-id="select2-data-51-3nrk">
                      1.26
                    </option>
                  </select>
                   
                </div>
              </div>
            </div>
            </div>
            </div>
            {/*end::Row*/}

             {/*begin::List widget 10*/}
             <div className="col-xl-12 card mb-5">
                  {/*begin::Header*/}
                  <div className="card-header border-3 mb-5 pt-2">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                    Select Node Pool Sizes
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                    Node Pool Name, Size, plans
                    </span>
                  </h3>
                    
                  </div>
   
                  {/*end::Header*/}  
            {/*begin::Row*/}
            <div className="container pb-5">
            <div
              id="define-vpc"
              className="row g-9"
              data-kt-buttons="true"
              data-kt-buttons-target="[data-kt-button]"
            >
              <div className="col-md-12 col-lg-12 col-xxl-12">
                <div className=" h-lg-100">
                  {/*begin::Tables Widget 11*/}
                  <div className=" mb-5 mb-xl-8">
                    {/*begin::Body*/}
                    <div className=" py-3">
                      {/*begin::Table container*/}
                      <div className="table-responsive">
                        {/*begin::Table*/}
                        <input
                          type="hidden"
                          id="node_plan_pool_plan_id"
                          defaultValue=""
                          /> 
                          
                        <table
                          className="table table-striped align-middle gs-4 gy-4"
                          id="nodepool_list"
                        >
                          {/*begin::Table head*/}
                          <thead>
                            <tr className="fw-bold  bg-light">
                              <th className="ps-4 min-w-225px">Pool Name</th>
                              <th className="text-center min-w-325px">
                                Node Size
                              </th>
                              <th className="text-center min-w-100px">
                                Node Count
                              </th>
                              <th className="text-center min-w-100px">
                                Total Cost
                              </th>
                              <th className="min-w-100px " />
                            </tr>
                          </thead>
                          {/*end::Table head*/}
                           
                          {/*begin::Table body*/}
                          <tbody>
                          {poolData!=null && (poolData && poolData.map((value,index)=>(<>
                            <tr id="pool-row-0">
                              <td>
                                <div className="d-flex align-items-center p-2">
                                  <input
                                  type="hidden"
                                  id={"plan_price_"+value.pool_id}
                                  defaultValue={value.totalCost}
                                  />
                                  <input
                                    type="text"
                                    className="form-control  "
                                    name={"nodepools["+index+"][label]"}
                                    // name={"nodepools"+index+"label"}
                                    id={"pool_name_"+value.pool_id}
                                    onChange={()=>nodePoolUpdate(value.pool_id)}
                                    defaultValue={value.nodePoolValue}
                                    placeholder="webserver"
                                  />
                                </div>
                              </td>
                              <td className="text-center">
                                
                                <input
                                  type="hidden"
                                  id={"node_select_plan_"+value.pool_id}
                                  name={"nodepools["+index+"][size]"}
                                  // name={"nodepools"+index+"size"}
                                  onChange={()=>nodePoolUpdate(value.pool_id)}
                                />
                                <div
                                  id="select_node_section_0"
                                  onclick="show_kubernetes_plan('0')"
                                  className="align-items-center"
                                >
                                  <div
                                  onClick={()=>openDrawer(value.pool_id)}
                                    className="row d-none"
                                    id={"show_select_plans_"+value.pool_id}
                                  >
                                    <div className="col-lg-3">
                                      <div className="position-relative ps-4 pe-3 py-2">
                                        <a
                                           
                                          className="mb-1 text-dark text-hover-primary fw-bolder"
                                        >
                                          RAM
                                        </a>
                                        <div
                                          className="fs-7 text-muted fw-bolder"
                                          id={"ram_"+value.pool_id}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div
                                        className="position-relative ps-6 pe-3 py-2"
                                        style={{
                                          borderLeft: "3px solid #009ef7"
                                        }}
                                      >
                                        <a
                                           
                                          className="mb-1 text-dark text-hover-primary fw-bolder"
                                        >
                                          CPU
                                        </a>
                                        <div
                                          className="fs-7 text-muted fw-bolder"
                                          id={"cpu_"+value.pool_id}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-5">
                                      <div
                                        className="position-relative ps-6 pe-3 py-2"
                                        style={{
                                          borderLeft: "3px solid #009ef7"
                                        }}
                                      >
                                        <a
                                           
                                          className="mb-1 text-dark text-hover-primary fw-bolder"
                                        >
                                          Bandwidth
                                        </a>
                                        <div
                                          className="fs-7 text-muted fw-bolder"
                                          id={"bandwidth_"+value.pool_id}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                  onClick={()=>openDrawer(value.pool_id)}
                                    id={"select_kubernetes_plans_"+value.pool_id}
                                    className="btn btn-sm btn-light align-items-center px-4" 
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {/*begin::Display range*/}
                                    <div className="text-gray-600 fw-bolder">
                                      Select Node Size +{/*end::Display range*/}
                                      {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                                      <span className="svg-icon svg-icon-1 ms-2 me-0">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.3"
                                            d="M18.041 22.041C18.5932 22.041 19.041 21.5932 19.041 21.041C19.041 20.4887 18.5932 20.041 18.041 20.041C17.4887 20.041 17.041 20.4887 17.041 21.041C17.041 21.5932 17.4887 22.041 18.041 22.041Z"
                                            fill="black"
                                          />
                                          <path
                                            opacity="0.3"
                                            d="M6.04095 22.041C6.59324 22.041 7.04095 21.5932 7.04095 21.041C7.04095 20.4887 6.59324 20.041 6.04095 20.041C5.48867 20.041 5.04095 20.4887 5.04095 21.041C5.04095 21.5932 5.48867 22.041 6.04095 22.041Z"
                                            fill="black"
                                          />
                                          <path
                                            opacity="0.3"
                                            d="M7.04095 16.041L19.1409 15.1409C19.7409 15.1409 20.141 14.7409 20.341 14.1409L21.7409 8.34094C21.9409 7.64094 21.4409 7.04095 20.7409 7.04095H5.44095L7.04095 16.041Z"
                                            fill="black"
                                          />
                                          <path
                                            d="M19.041 20.041H5.04096C4.74096 20.041 4.34095 19.841 4.14095 19.541C3.94095 19.241 3.94095 18.841 4.14095 18.541L6.04096 14.841L4.14095 4.64095L2.54096 3.84096C2.04096 3.64096 1.84095 3.04097 2.14095 2.54097C2.34095 2.04097 2.94096 1.84095 3.44096 2.14095L5.44096 3.14095C5.74096 3.24095 5.94096 3.54096 5.94096 3.84096L7.94096 14.841C7.94096 15.041 7.94095 15.241 7.84095 15.441L6.54096 18.041H19.041C19.641 18.041 20.041 18.441 20.041 19.041C20.041 19.641 19.641 20.041 19.041 20.041Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>
                                      {/*end::Svg Icon*/}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="d-flex align-items-center">
                                  {/*begin::Dialer*/}
                                  <div
                                    className="input-group w-md-150px"
                                    data-kt-dialer="true"
                                  >
                                    {/*begin::Decrease control*/}
                                    <a  
                                      className="btn btn-icon btn-outline btn-outline-secondary disabled"
                                      id={"downcount_"+value.pool_id}
                                      onClick={()=>nodePoolCount(value.pool_id,'down')}
                                    >
                                      <i className="bi bi-dash fs-1" />
                                    </a>
                                    {/*end::Decrease control*/}
                                    {/*begin::Input control*/}
                                    <input
                                      type="text"
                                      className="form-control text-center"
                                      readOnly={true}
                                      id={"count_"+value.pool_id}
                                      name={"nodepools["+index+"][count]"}
                                      // name={"nodepools"+index+"count"}
                                      defaultValue={1}
                                      onChange={()=>nodePoolUpdate(value.pool_id)}
                                    />
                                    {/*end::Input control*/}
                                    {/*begin::Increase control*/}
                                    <a
                                      className="btn btn-icon btn-outline btn-outline-secondary"
                                      id={"upcount_"+value.pool_id}
                                      onClick={()=>nodePoolCount(value.pool_id,'up')}
                                    >
                                      <i className="bi bi-plus fs-1" />
                                    </a>
                                    {/*end::Increase control*/}
                                  </div>
                                  {/*end::Dialer*/}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div
                                    className="fw-bold fs-3 text-primary" 
                                  > 
                                    <span className="nodePoolCost" id={"order_total_"+value.pool_id}>0.00</span> 
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <a 
                                  onClick={()=>deleteFormField(value.pool_id)}
                                  className="btn btn-icon  btn-sm"
                                >
                                  {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                                  <span className="svg-icon svg-icon-2 svg-icon-danger">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </span>
                                  {/*end::Svg Icon*/}
                                </a>
                              </td>
                            </tr>
                            </>)))}
                          </tbody>
                          {/*end::Table body*/}
                        </table>
                        {/*end::Table*/}
                      </div>
                      {/*end::Table container*/}
                    </div>
                    {/*begin::Body*/}
                    {/*begin::Footer*/}
                    <div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column"></h3>
                      <div className="card-toolbar">
                        <a 
                          id="addnodepool" onClick={addFormField}
                          className="btn btn-sm btn-light-primary"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                          <span className="svg-icon svg-icon-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.5"
                                x="11.364"
                                y="20.364"
                                width={16}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11.364 20.364)"
                                fill="black"
                              />
                              <rect
                                x="4.36396"
                                y="11.364"
                                width={16}
                                height={2}
                                rx={1}
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}Add Node Pool
                        </a>
                      </div>
                    </div>
                    {/*end::Footer*/}
                  </div>
                  {/*end::Tables Widget 11*/}
                </div>
              </div> 
            </div>
            </div>
            </div>
            {/*end::Row*/}
           
      <div className="col-xl-12 card mb-5">
      <div className="card-header  mb-5 pt-2" style={{border:"none"}}>
  <h3 className="card-title align-items-start flex-column">
    <span className="card-label fw-bold fs-3 mb-1">
      Total Cost
    </span>
    <span className="fs-xl-2x fs-2 fw-boldest">
    <span id="currency_final"></span>
    <span id="total_cost"></span>
    <span id="billing_final"></span> </span>
    <span className="text-muted mt-1 fw-bold fs-7" />
  </h3>
  <div className="card-toolbar">  
    <a  id="deploykubernetes-btn"
                style={{ width: "100%" }}
                 onClick={deployKubernetes} className="btn btn-sm btn-primary btn-active-light-primary">
      Deploy Cluster &nbsp; &nbsp;
      <span className="svg-icon svg-icon-3">
      <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
      fill="black"
    />
    <path
      opacity="0.3"
      d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
      fill="black"
    />
  </svg>
      </span>
    </a>
  </div>
</div>
</div>
      </form> 

<div
  id="kubernetes-modal"
  className="bg-body shadow drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-name="chat"
  data-kt-drawer-activate="true"  
  data-kt-drawer-direction="end"   
>
  {/*begin::Messenger*/}
  <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
    {/*begin::Card header*/}
    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div className="d-flex justify-content-center flex-column me-3">
          <a
             
            className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
          >
            Choose plan for kubernetes
          </a>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Close*/}
        <div
          className="btn btn-sm btn-icon btn-active-light-primary"
          onClick={()=>openDrawer("close")}
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {/*end::Card header*/}
    {/*begin::Card body*/}
    <div className="" id="">
      {/*begin::Modal body*/}
      <div className="modal-body ">
        {/*begin::Col*/}
        <div className="col-xl-12">
          {/*begin::List widget 10*/}
          <div className="">
            {/*begin::Body*/}
            {/*begin::Nav*/}
            <ul className="nav nav-pills nav-pills-custom row position-relative mx-0 mb-9">
              {/*begin::Item*/}
              <li className="nav-item col-2 mx-0 p-0">
                {/*begin::Link*/}
                <a
                  className="nav-link active d-flex justify-content-center w-100 border-0 h-100"
                  data-bs-toggle="pill"
                  href="#basic-tab"
                >
                  {/*begin::Subtitle*/}
                  <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                    Basic
                  </span>
                  {/*end::Subtitle*/}
                  {/*begin::Bullet*/}
                  <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                  {/*end::Bullet*/}
                </a>
                {/*end::Link*/}
              </li>
              {/*end::Item*/}
              {/*begin::Item*/}
              <li className="nav-item col-2 mx-0 px-0 d-none">
                {/*begin::Link*/}
                <a
                  className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                  data-bs-toggle="pill"
                  href="#dedicated-standard-tab"
                >
                  {/*begin::Subtitle*/}
                  <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                    Dedicated Standard
                  </span>
                  {/*end::Subtitle*/}
                  {/*begin::Bullet*/}
                  <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                  {/*end::Bullet*/}
                </a>
                {/*end::Link*/}
              </li>
              {/*end::Item*/}
              {/*begin::Item*/}
              <li className="nav-item col-2 mx-0 px-0">
                {/*begin::Link*/}
                <a
                  className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                  data-bs-toggle="pill"
                  href="#dedicated-cpu-tab"
                >
                  {/*begin::Subtitle*/}
                  <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                    Dedicated CPU
                  </span>
                  {/*end::Subtitle*/}
                  {/*begin::Bullet*/}
                  <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                  {/*end::Bullet*/}
                </a>
                {/*end::Link*/}
              </li>
              {/*end::Item*/}
              {/*begin::Item*/}
              <li className="nav-item col-2 mx-0 px-0">
                {/*begin::Link*/}
                <a
                  className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                  data-bs-toggle="pill"
                  href="#dedicated-memory-tab"
                >
                  {/*begin::Subtitle*/}
                  <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                    Dedicated Memory
                  </span>
                  {/*end::Subtitle*/}
                  {/*begin::Bullet*/}
                  <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                  {/*end::Bullet*/}
                </a>
                {/*end::Link*/}
              </li>
              {/*end::Item*/}
              {/*begin::Bullet*/}
              <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded" />
              {/*end::Bullet*/}
            </ul>
            {/*end::Nav*/}
            {/*begin::Tab Content*/}
            <div className="tab-content">
              {/*begin::Tap pane*/}
              <div className="tab-pane fade show active" id="basic-tab">
                {/*begin::Radio group*/}
                <div className="row" data-kt-buttons="true">
                  <div className="table-responsive" id="ramcpuplans">
                    {/*begin::Table*/}
                    <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                      {/*begin::Thead*/}
                      <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                        <tr> 
                          <th className="min-w-125px px-0 text-center">RAM</th>
                          <th className="min-w-125px">vCPU</th>
                          <th className="min-w-125px">SSD Disk</th>
                          <th className="min-w-125px">Bandwidth</th> 
                          <th className="min-w-125px ps-0 text-start">
                            Price
                          </th>
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody
                        id="basic-plan"
                        className="fs-6 fw-bold text-gray-600"
                      >
                        {planData!=null && (planData && planData.map((value,index)=>(
                          <>
                          {value.slug=='basic'?(<>
                            <tr class="planseffect" onClick={()=>selectNodeSize(value.id,value.ram,value.cpu,value.disk,value.bandwidth,parseFloat(value.price/720).toFixed(2))}>
                              <td class="ps-0 text-center">{value.ram} MB <input type="hidden" id={"plan_field_row_"+value.id}/></td> 
                              <td>{value.cpu} vCPU</td> 
                              <td>{value.disk} GB</td> 
                              <td>{value.bandwidth} GB</td> 
                              <td>
                                <span class="badge badge-light-success fs-7 fw-bolder  me-3">{user_data.current!=null?(user_data.current.user.currencyprefix):('')} {parseFloat(value.price/720).toFixed(2)}/hourly</span>
                                {value.free_availabel==1?(
                                  <span class="badge badge-warning fs-base">Free {value.free_days} days</span>
                                ):('')}
                                
                              </td>
                            </tr>
                            </>):('')}
                          </>
                        )))}
                         
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                {/*end::Radio group*/}
              </div>
              {/*end::Tap pane*/}
              {/*begin::Tap pane*/}
              <div className="tab-pane fade" id="dedicated-standard-tab">
                {/*begin::Radio group*/}
                <div className="row" data-kt-buttons="true">
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                      {/*begin::Thead*/}
                      <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr> 
                          <th className="min-w-125px px-0 text-center">RAM</th>
                          <th className="min-w-125px">vCPU</th>
                          <th className="min-w-125px">SSD Disk</th>
                          <th className="min-w-125px">Bandwidth</th> 
                          <th className="min-w-125px ps-0 text-start">
                            Price
                          </th>
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody
                        id="standard-plan"
                        className="fs-6 fw-bold text-gray-600"
                      >
                        {planData!=null && (planData && planData.map((value,index)=>(
                          <>
                          {value.slug=='dedicated-standard'?(<>
                            <tr class="planseffect" onClick={()=>selectNodeSize(value.id,value.ram,value.cpu,value.disk,value.bandwidth,parseFloat(value.price/720).toFixed(2))}>
                              <td class="ps-0 text-center">{value.ram} MB</td> 
                              <td>{value.cpu} vCPU</td> 
                              <td>{value.disk} GB</td> 
                              <td>{value.bandwidth} GB</td> 
                              <td>
                                <span class="badge badge-light-success fs-7 fw-bolder  me-3">{user_data.current!=null?(user_data.current.user.currencyprefix):('')} {parseFloat(value.price/720).toFixed(2)}/hourly</span>
                                {value.free_availabel==1?(
                                  <span class="badge badge-warning fs-base">Free {value.free_days} days</span>
                                ):('')}
                                
                              </td>
                            </tr>
                            </>):('')}
                          </>
                        )))}
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                {/*end::Radio group*/}
              </div>
              {/*end::Tap pane*/}
              {/*begin::Tap pane*/}
              <div className="tab-pane fade" id="dedicated-memory-tab">
                {/*begin::Radio group*/}
                <div className="row" data-kt-buttons="true">
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                      {/*begin::Thead*/}
                      <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr> 
                          <th className="min-w-125px px-0 text-center">RAM</th>
                          <th className="min-w-125px">vCPU</th>
                          <th className="min-w-125px">SSD Disk</th>
                          <th className="min-w-125px">Bandwidth</th> 
                          <th className="min-w-125px ps-0 text-start">
                            Price
                          </th>
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody
                        id="memory-plan"
                        className="fs-6 fw-bold text-gray-600"
                      >
                        {planData!=null && (planData && planData.map((value,index)=>(
                          <>
                          {value.slug=='dedicated-cpu'?(<>
                            <tr class="planseffect" onClick={()=>selectNodeSize(value.id,value.ram,value.cpu,value.disk,value.bandwidth,parseFloat(value.price/720).toFixed(2))}>
                              <td class="ps-0 text-center">{value.ram} MB</td> 
                              <td>{value.cpu} vCPU</td> 
                              <td>{value.disk} GB</td> 
                              <td>{value.bandwidth} GB</td> 
                              <td>
                                <span class="badge badge-light-success fs-7 fw-bolder  me-3">{user_data.current!=null?(user_data.current.user.currencyprefix):('')} {parseFloat(value.price/720).toFixed(2)}/hourly</span>
                                {value.free_availabel==1?(
                                  <span class="badge badge-warning fs-base">Free {value.free_days} days</span>
                                ):('')}
                                
                              </td>
                            </tr>
                            </>):('')}
                          </>
                        )))}
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                {/*end::Radio group*/}
              </div>
              {/*end::Tap pane*/}
              {/*begin::Tap pane*/}
              <div className="tab-pane fade" id="dedicated-cpu-tab">
                {/*begin::Radio group*/}
                <div className="row" data-kt-buttons="true">
                  <div className="table-responsive" id="ramcpuplans">
                    {/*begin::Table*/}
                    <table className="table table-row-bordered table-flush align-middle gy-6 tableramcpu">
                      {/*begin::Thead*/}
                      <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr> 
                          <th className="min-w-125px px-0 text-center">RAM</th>
                          <th className="min-w-125px">vCPU</th>
                          <th className="min-w-125px">SSD Disk</th>
                          <th className="min-w-125px">Bandwidth</th> 
                          <th className="min-w-125px ps-0 text-start">
                            Price
                          </th>
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody
                        id="cpu-plan"
                        className="fs-6 fw-bold text-gray-600"
                      >
                        {planData!=null && (planData && planData.map((value,index)=>(
                          <>
                          {value.slug=='dedicated-memory'?(<>
                            <tr class="planseffect" onClick={()=>selectNodeSize(value.id,value.ram,value.cpu,value.disk,value.bandwidth,parseFloat(value.price/720).toFixed(2))}>
                              <td class="ps-0 text-center">{value.ram} MB</td> 
                              <td>{value.cpu} vCPU</td> 
                              <td>{value.disk} GB</td> 
                              <td>{value.bandwidth} GB</td> 
                              <td>
                                <span class="badge badge-light-success fs-7 fw-bolder  me-3">{user_data.current!=null?(user_data.current.user.currencyprefix):('')} {parseFloat(value.price/720).toFixed(2)}/hourly</span>
                                {value.free_availabel==1?(
                                  <span class="badge badge-warning fs-base">Free {value.free_days} days</span>
                                ):('')}
                                
                              </td>
                            </tr>
                            </>):('')}
                          </>
                        )))}
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                {/*end::Radio group*/}
              </div>
              {/*end::Tap pane*/}
            </div>
            {/*end::Tab Content*/}
          </div>
          {/*end: Card Body*/}
          {/*end::List widget 10*/}
        </div>
        {/*end::Col*/}
      </div>
      {/*end::Modal body*/}
      {/*begin::Modal footer*/}
      {/* <div class="modal-footer ">
              <button type="button" id="importkey-btn"
                  onclick="call_api('POST','key/import','importkey','getDeployAPI')" class="btn btn-primary">
                  <span class="indicator-label">Import SSH Key</span>
              </button>
          </div> */}
      {/*end::Modal footer*/}
    </div>
    {/*end::Card body*/}
  </div>
  {/*end::Messenger*/}
</div>
</>):(<><TopBarHeader /></>)}
        </>
        
    )
}

const mapStateToProps=state=>({
  nodePoolData:state.kubernetes.deployPoolRow
})

const mapDispatchToProps=dispatch=>({
  updateRow:data=>dispatch(DeployNodePoolRow(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(KubernetesDeploy) 