import UseCallApi from "./useCallApi";

 export default async function UseTicketPost(type, url, formid) {
  var form = document.querySelector('#' + formid); 
  // var requestData = serialize(form, { hash: true });
  var requestData = new FormData(form);
  const temp = await UseCallApi(type, url, requestData);
  // console.log(requestData);
  return temp;
}
