import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

export default function Process(){
       const navigate=useNavigate();
  

    var {id} = useParams();
     function redirectToPrev(id){
      if(id=="firewall" || id=="dns"){
                navigate('/'+id+'?action=deploy');
        }else{
        navigate('/'+id+'/deploy');
        } 
      }
      useEffect(()=>{
        redirectToPrev(id);
      },[id])
  return (
    <></>
    // <div>process</div>
  )
}
