import React, { useEffect, useState } from 'react';
import AddingFund from '../../billing/sections/AddFund/AddingFund';
import AddingCard from '../../billing/sections/AddCard/AddingCard';
import localStorage from 'local-storage';
import AddingRazorpayCard from '../../billing/sections/AddCard/AddCards/AddingRazorpayCard';
import AddingStripeCard from '../../billing/sections/AddCard/AddCards/AddingStripeCard';

const CardVerification = () => { 
    const [userData, setUserData] = useState(null);
    useEffect(() => {
      setUserData(localStorage.get("user_info"));
    }, []);
    return (
        <>
        <div className='row container'>
        <div className='col-12'>

        </div>
         <div className='col-lg-5 col-lg-5 col-sm-12' >
         <div className="row g-0">
  <div className="col  px-6 py-8 rounded-2 me-7 mb-7 btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start">
    <i className="bi bi-wallet2 fs-2x" />
    <div className="card-body">
      <a className="card-title fw-bold text-muted text-hover-primary fs-4">
        Add New Payment Method
      </a>
      <div className="fw-bold text-primary my-6">
        <label htmlFor="size" className="form-label">
          {" "}
          Add Debit/Credit Card
        </label>
      </div>
      <div className="d-flex align-items-center flex-center" >
         
          <div className="symbol symbol-45px me-2 d-none">
          <span className="mw-100 mh-300px">
      <svg
  xmlns="http://www.w3.org/2000/svg"
  width="140px"
  height="140px"
  viewBox="0 0 24 24"
  fill="#21325B"
>
  <path d="M22 7H2V11H22V7Z" fill="#21325B" />
  <path
    opacity="0.3"
    d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z"
    fill="#21325B"
  />
</svg>


      </span> 
          </div> 
          <div className="d-flex align-items-center flex-center mt-12 mb-20">
            {" "}
            {userData != null && userData.currency == "INR" ? (
              <>
                <AddingRazorpayCard />
              </>
            ) : (
              <>
                <AddingStripeCard />
              </>
            )}
          </div> 
      </div>
    </div>
  </div>
</div>

         <div className="card d-none">
  {/*begin::Body*/}
  <div className="mt-5 d-flex flex-column flex-center">
    {/*begin::Heading*/}
    <div className="mb-2">
      {/*begin::Title*/}
      <h1 className="fw-bold text-gray-800 text-center lh-lg">
      Expand Your Payment Horizons
        <br />
        <span className="fw-boldest">Add New Credit/Debit Card</span>
      </h1>
      {/*end::Title*/}
      {/*begin::Illustration*/}
       {/*begin::Svg Icon | path: assets/media/icons/duotune/finance/fin002.svg*/}
      <div className="text-center">
      <span className="mw-100 mh-300px">
      <svg
  xmlns="http://www.w3.org/2000/svg"
  width="140px"
  height="140px"
  viewBox="0 0 24 24"
  fill="#21325B"
>
  <path d="M22 7H2V11H22V7Z" fill="#21325B" />
  <path
    opacity="0.3"
    d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z"
    fill="#21325B"
  />
</svg>


      </span> 

    </div>
  {/*end::Svg Icon*/} 
      {/*end::Illustration*/}
    </div>
    {/*end::Heading*/}
    {/*begin::Links*/}
    <div className="text-center mb-16">
      {/*begin::Link*/}
      {userData != null && userData.currency == "INR" ? (
              <>
                <AddingRazorpayCard />
              </>
            ) : (
              <>
                <AddingStripeCard />
              </>
            )}
      {/*end::Link*/}
    </div>
    {/*end::Links*/}
  </div>
  {/*end::Body*/}
</div>

          {/* <AddingCard /> */}
         </div>
         <div className='col-lg-1 col-lg-1 col-sm-12'>
         <div className=" separator separator-dotted separator-content border-dark my-15">
          <span class="h1">OR</span></div>
         </div>
         <div className='col-lg-6 col-lg-6 col-sm-12'>
           
          <AddingFund />
         </div>
        </div>
        </>
    );
}

export default CardVerification;
