import { CURRENT_OBJECT_PARENTID, OBJECT_BREADCRUMB } from "../../../../Redux/Services/constants"

 
const initialState={
   currentObjectParentid:"/",
   objectBreadcrumb:[]
 }

export default function ObjectReducer(state=initialState,action){


   switch (action.type) {
       case OBJECT_BREADCRUMB:
           ////console.log("Reducer",action.data);
           return {...state, objectBreadcrumb:action.data}
       case CURRENT_OBJECT_PARENTID:
           ////console.log("Reducer",action.data);
           return {...state, currentObjectParentid:action.data}           
       default:
           return state 
   }
}

