import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert"; 
import UsePost from "../../../../CustomHooks/usePost";

function useForceUpdate(){
  const [value, setValue] = useState(0);  
  return () => setValue(value => value + 1); 
}

export default function ManageDns(){
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4];
  const forceUpdate = useForceUpdate(); 
  const [dnsData,setDnsData]=useState(null);
  const dataFetchedRef=useRef (false); 
  const navigate=useNavigate();
  const navRedirect=()=>{
    setTimeout(() => {
      navigate("/dns/"+id);
     }, 3000);
      
  }
  const addRecord=async()=>{
    toast.dismiss();
    const dt=toast.loading("Please wait...");
    const responseData=await UsePost('post','dns/'+id+'/record/add','adddnsrecord');
    //console.log(responseData);
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      getDns();  
      }
      const dnsForm=document.querySelector('#adddnsrecord');
      dnsForm.reset();
      navRedirect();
    }

  const getDns=async()=>{
    
     
       const data=await UseApi('get','dns/'+id,'');
       if(data.domains.length<=0){
        swal({
            title: "DNS",
            text: "No DNS record found with id: "+id+"!",
            icon: "warning",
            button: "OK",
          }).then(()=>{
            navigate("/dns");
          })
         
            return;
       }else{
        setDnsData(data.domains[0].records);   
       }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getDns(); 
  },[]);  
  

  const deleteDns=async()=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this DNS!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("DNS deleting..."); 
    const dnsp=await UseApi('DELETE','dns/'+id+'/delete','');
    if(dnsp.status=='error'){
      toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else{
      toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });  
    navigate('/dns'); 
    }
      }else {
        swal({
            title: "DNS",
            text: "Your DNS is safe!",
            icon: "success",
            button: "OK",
          });
      }
      });
  }
  const deleteDnsRecord=async(dnsId)=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this DNS record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("DNS deleting..."); 
    const dnsp=await UseApi('DELETE','dns/'+id+'/record/'+dnsId+'/delete','');
    if(dnsp.status=='error'){
      toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else{
      toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    }); 
    getDns();
    navRedirect();
    }
      }else {
        swal({
            title: "DNS",
            text: "Your DNS record is safe!",
            icon: "success",
            button: "OK",
          });
      }
      });
  }

   const dnsType=()=>{
    const dnstypes=document.querySelector('#dnstype');
    const hostnameinput=document.querySelector('#hostnameinput');
    const valueinput=document.querySelector('#valueinput');
    const value_priority=document.querySelector('#value_priority'); 
    const ttlinput=document.querySelector('#ttlinput');
    const extravalues=document.querySelector('#extravalues');
   // //console.log(dnstypes.value);
    if(dnstypes.value==='A'){
         hostnameinput.innerHTML='<input type="text" class="form-control form-control-sm" placeholder="enter @ or hostname" name="hostname" id="hostname">';
         value_priority.innerHTML='Value';
         extravalues.classList.add('d-none');
         valueinput.innerHTML='<div class="position-relative w-md-200px me-md-2"><input type="text" class="form-control form-control-sm" id="value" name="value" placeholder="IPv4 Address"></div>';
    }else if(dnstypes.value==='AAA'){
         hostnameinput.innerHTML='<input type="text" class="form-control form-control-sm  " placeholder="enter @ or hostname" name="hostname" id="hostname">';
         value_priority.innerHTML='Value';
         extravalues.classList.add('d-none');
         valueinput.innerHTML='<div class="position-relative w-md-200px me-md-2"><input type="text" class="form-control form-control-sm  " id="value" name="value" placeholder="IPv6 Address"></div>';
    }else if(dnstypes.value==='CNAME'){
      hostnameinput.innerHTML='<input type="text" class="form-control form-control-sm  " placeholder="Name" name="hostname" id="hostname">';
      value_priority.innerHTML='Value';
      extravalues.classList.add('d-none');
      valueinput.innerHTML='<div class="position-relative w-md-200px me-md-2"><input type="text" class="form-control form-control-sm  " id="value" name="value" placeholder="Domain Name"></div>';
    }else if(dnstypes.value==='TXT'){
      hostnameinput.innerHTML='<input type="text" class="form-control form-control-sm  " placeholder="Name" name="hostname" id="hostname">';
      value_priority.innerHTML='Value';
      extravalues.classList.add('d-none');
      valueinput.innerHTML='<div class="position-relative w-md-200px me-md-2"><input type="text" class="form-control  form-control-sm " id="value" name="value" placeholder="Paste your TXT Content"></div>';
    }else if(dnstypes.value==='CAA'){
      hostnameinput.innerHTML='<input type="text" class="form-control  form-control-sm " placeholder="Name" name="hostname" id="hostname">';
      value_priority.innerHTML='Value';
      extravalues.classList.add('d-none');
      valueinput.innerHTML='<div class="position-relative w-md-200px me-md-2"><input type="text" class="form-control  form-control-sm " id="value" name="value" placeholder="Paste your CAA Content"></div>';
    }else if(dnstypes.value==='NS'){
      hostnameinput.innerHTML='<input type="text" class="form-control form-control-sm  " placeholder="Name" name="hostname" id="hostname">';
      value_priority.innerHTML='Value';
      extravalues.classList.add('d-none');
      valueinput.innerHTML='<div class="position-relative w-md-200px me-md-2"><input type="text" class="form-control  form-control-sm " id="value" name="value" placeholder="Name Server"></div>';
    }else if(dnstypes.value==='MX'){
      hostnameinput.innerHTML='<input type="text" class="form-control form-control-sm  " placeholder="enter @ or name" name="hostname" id="hostname" value="@">';
      valueinput.innerHTML='<div class="row"><div class="position-relative w-md-200px me-md-2" style="border-right:2px solid #aeaaa9"><input type="text" class="form-control  form-control-sm " id="value" name="value" placeholder="Mail Server MX" value="aspmx.l.google.com"></div><input type="text" id="priority" name="priority" minvalue="0" maxvalue="65444" placeholder="10" value="10" class="form-control form-control-sm" style="width: 50px;vertical-align: middle;height: 29px;"></div>';
      value_priority.innerHTML='Value | Priority';
      extravalues.classList.add('d-none');
      ttlinput.innerHTML='<input type="text" class="form-control  form-control-sm " id="ttl" name="ttl" minvalue="1" maxvalue="65444" placeholder="65444" value="65444">';
    }else if(dnstypes.value==='SRV'){
      hostnameinput.innerHTML='<input type="text" class="form-control form-control-sm  " placeholder="_service" name="hostname" id="hostname">';
      valueinput.innerHTML='<div class="position-relative w-md-200px me-md-2"><input type="text" class="form-control form-control-sm  " id="value" name="value" placeholder="target eg:myservice.abc.com" ></div>';
      value_priority.innerHTML='Value';
      ttlinput.innerHTML='<input type="text" class="form-control form-control-sm  " id="ttl" name="ttl" minvalue="1" maxvalue="65444" placeholder="65444" value="65444">';
      //extravalues.innerHTML='<td></td><td style="weight:20%"><select class="form-select  form-select-sm" name="porttype" id="porttype"><option value="TCP">TCP</option><option value="UDP">UDP</option><option value="TLS">TLS</option></select></td><td><input type="text" class="form-control  " id="port" name="port" minvalue="1" maxvalue="65444" placeholder="Port eg: 5060" value="5060"></td><td><input type="text" class="form-control  " id="priority" name="priority" minvalue="1" maxvalue="20" placeholder="Priority" value="10"></td><td><input type="text" class="form-control  " id="wight" name="wight" minvalue="1" maxvalue="100" placeholder="Wight" value="100"></td>';
       extravalues.classList.remove('d-none');
    }else if(dnstypes.value==='PTR'){
      hostnameinput.innerHTML='<input type="text" class="form-control form-control-sm  " placeholder="_service" name="hostname" id="hostname">';
      valueinput.innerHTML='<div class="position-relative w-md-200px me-md-2"><input type="text" class="form-control form-control-sm  " id="value" name="value" placeholder="target eg:myservice.abc.com" ></div>';
      value_priority.innerHTML='Value';
      ttlinput.innerHTML='<input type="text" class="form-control form-control-sm  " id="ttl" name="ttl" minvalue="1" maxvalue="65444" placeholder="65444" value="65444">';
      //extravalues.innerHTML='<td></td><td style="weight:20%"><select class="form-select  form-select-sm" name="porttype" id="porttype"><option value="TCP">TCP</option><option value="UDP">UDP</option><option value="TLS">TLS</option></select></td><td><input type="text" class="form-control  " id="port" name="port" minvalue="1" maxvalue="65444" placeholder="Port eg: 5060" value="5060"></td><td><input type="text" class="form-control  " id="priority" name="priority" minvalue="1" maxvalue="20" placeholder="Priority" value="10"></td><td><input type="text" class="form-control  " id="wight" name="wight" minvalue="1" maxvalue="100" placeholder="Wight" value="100"></td>';
       extravalues.classList.add('d-none');
    }

   } 
    return(
        <>
         
        {dnsData!=null?(<>
        <div className="card mb-6 mb-xl-9">
  <div className="card-body pt-9 pb-0">
    {/*begin::Details*/}
    <div className="d-flex flex-wrap flex-sm-nowrap">
      {/*begin: Pic*/}
      <div className="me-7 mb-4">
        <div
          id="flag-icon"
          className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
        >
          {/*begin::Image input*/}
          <div
            className="image-input image-input-empty"
            data-kt-image-input="true"
          >
            <span className="svg-icon svg-icon-2x svg-icon-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z"
                  fill="black"
                />
                <path
                  d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>
          {/*end::Image input*/}
        </div>
      </div>
      {/*end::Pic*/}
      {/*begin::Info*/}
      <div className="flex-grow-1">
        {/*begin::Title*/}
        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
          {/*begin::User*/}
          <div className="d-flex flex-column">
            {/*begin::Name*/}
            <div className="d-flex align-items-center mb-2">
              <a 
                className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1"
                id="domain-name"
              >
                {id}
              </a>
            </div>
            {/*end::Name*/}
            {/*begin::Info*/}
            <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2"></div>
            {/*end::Info*/}
          </div>
          {/*end::User*/}
        </div>
        {/*end::Title*/}
      </div>
      {/*end::Info*/}
    </div>
    {/*end::Details*/}
    <div className="row">
      <div className="col-lg-5">
        {/*begin::Nav*/}
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
          {/*begin::Nav item*/}
          <li className="nav-item">
            <a
              className="nav-link text-active-primary py-5 me-6 active"
              data-bs-toggle="tab"
              href="#resources"
            >
              Resources
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" href="#destroy_dns">
              Destroy DNS
            </a>
          </li>
          {/*end::Nav item*/}
        </ul>
        {/*end::Nav*/}
      </div>
      <div className="col-lg-7">
        <div className="row">
          <div className="col-lg-4" />
          <div className="col-lg-8">
            {/*begin::Stats*/}
            <div className="d-flex flex-wrap flex-stack">
              {/*begin::Wrapper*/}
              <div className="d-flex flex-column flex-grow-1 pe-8">
                {/*begin::Stats*/}
                <div className="d-flex flex-wrap sphide ">
                  {/*begin::Stat*/}
                  {/*begin::Number*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                    <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                      {/*begin::Svg Icon | path: assets/media/icons/duotune/abstract/abs027.svg*/}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                          fill="black"
                        />
                        <path
                          d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                          fill="black"
                        />
                      </svg>
                      {/*end::Svg Icon*/}
                    </span>
                    {/*end::Svg Icon*/}
                    <div className="fw-bold fs-6 " style={{marginRight:"10px"}}>NS1.MICROHOST.COM</div>
                   
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                    <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                      {/*begin::Svg Icon | path: assets/media/icons/duotune/abstract/abs027.svg*/}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                          fill="black"
                        />
                        <path
                          d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                          fill="black"
                        />
                      </svg>
                      {/*end::Svg Icon*/}
                    </span>
                    {/*end::Svg Icon*/}
                    <div className="fw-bold fs-6">NS2.MICROHOST.COM</div>
                  </div>
                  {/*end::Number*/}
                  {/*end::Stat*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::Wrapper*/}
              {/*begin::Actions*/}
              {/* <div class="d-flex mb-4 ">
            <div class="text-gray-800 fs-1 me-3">Status: </div>
            <a  class="animation-blink btn btn-sm btn-success">Running</a>
            </div> */}
              {/*end::Actions*/}
              {/*begin::Progress*/}
              {/*end::Progress*/}
            </div>
            {/*end::Stats*/}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="resources" role="tabpanel">
    {/*begin::License usage*/}
    <div className="card">
      {/*begin::incoming rules*/}
      <div className="">
        <div className="tab-content">
          {/*begin::Tap pane*/}
          <div className="tab-pane fade show active" id="rule_tab_1">
            <div className="p-5">
            <span className="svg-icon svg-icon-2x svg-icon-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z"
                  fill="black"
                />
                <path
                  d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z"
                  fill="black"
                />
              </svg>
            </span>
            <span className="card-label fw-bolder fs-3 mb-1">
              DNS Records for {id}
            </span>
            </div>
            {/*begin::Table container*/}
            <div className="table-responsive">
              {/*begin::Table*/}
              <table className="table table-striped table-row-dashed table-row-gray-200 align-middle gs-4 gy-4">
                {/*begin::Table head*/}
                <thead className="bg-secondary">
                  <tr className="fw-bolder">
                    <th className="w-100px">Type</th>
                    <th className="w-200px">Hostname</th>
                    <th className="w-200px text-start" id="value_priority">Value</th>
                    <th className="w-100px text-end">TTL</th>
                    <th className="w-100px " />
                  </tr>
                </thead>
                {/*end::Table head*/}
                <tbody id="dns_list" />
                 {dnsData!=null?(dnsData && dnsData.map((value,index)=>(
                  <>
                  <tr> 
                    <td className="fw-bold"> 
                      <div className="symbol symbol-45px me-2"> {value.type} </div>
                      <div className="separator separator-dashed" ></div> 
                    </td> 
                    <td class="fw-bold">
                       <div className="symbol symbol-45px me-2"> {value.hostname} </div> 
                       <div className="separator separator-dashed" ></div> 
                    </td>
                    <td class="fw-bold">
                       <div className="position-relative me-md-2"> {value.value}&nbsp;&nbsp;{value.priority!='0'?(<span class="badge badge-primary">{value.priority}</span>):('')}  </div> 
                       <div className="separator separator-dashed" ></div> 
                    </td> 
                    <td class="fw-bold">
                       <div className="position-relative me-md-2"> {value.ttl} </div>
                       <div className="separator separator-dashed" ></div> 
                    </td>
                    <td>
                       <a className="btn btn-danger btn-sm" onClick={()=>deleteDnsRecord(value.id)}>Delete</a>
                       <div className="separator separator-dashed" ></div> 
                    </td>
                    
                  </tr>
                  
                  </>
                 ))):('')}
                </table>
              {/*begin::Table body*/}
              <form className="form" id="adddnsrecord" name="adddnsrecord">
                <table className="table table-striped table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                  <tbody>
                    <tr>
                      <td className="text-end">
                        <div className="symbol symbol-45px me-2">
                          <select
                            className="form-select form-select-sm"
                            name="type"
                            onChange={dnsType}
                            id="dnstype"
                          >
                            <option value="A">A</option>
                            <option value="AAA">AAA</option>
                            <option value="CNAME">CNAME</option>
                            <option value="CAA">CAA</option>
                            <option value="MX">MX</option>
                            <option value="TXT">TXT</option>
                            <option value="NS">NS</option>
                            <option value="SRV">SRV</option>
                            <option value="PTR">PTR</option>
                          </select>
                        </div>
                      </td>
                      <td className="text-end">
                        <div className="row"> 
                        <div
                          className="position-relative w-md-200px me-md-2 col-lg-8 col-md-8 col-xxl-8"
                          id="hostnameinput"
                        >
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="name"
                            name="hostname"
                            id="hostname"
                          />
                        </div>  
                        <span className="badge badge-light-primary col-lg-4 col-md-4 col-xxl-4" id="suggestion">{id}</span>
                        </div> 
                      </td>
                      <td className="text-end" id="valueinput">
                      <div className="position-relative w-md-200px me-md-2">
                        <input type="text" className="form-control form-control-sm" id="value" name="value" placeholder="IPv4 Address" />
                      </div>
                      </td>
                      <td className="text-end">
                        <div
                          className="position-relative w-md-200px me-md-2"
                          id="ttlinput"
                        >
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="ttl"
                            name="ttl"
                            minvalue={1}
                            maxvalue={65444}
                            placeholder={65444}
                            defaultValue={65444}
                          />
                        </div>
                      </td>
                      <td className="text-start">
                        <a 
                          
                          className="btn btn-primary btn-sm button"
                          onClick={()=>addRecord()}
                        >
                          Add New
                        </a>
                      </td>
                    </tr>
                    <tr id="extravalues" className="d-none">
                     
                     <td className="text-center" style={{weight:"20%"}}>
                     <div className="symbol symbol-45px me-2">
                       <select className="form-select  form-select-sm" name="porttype" id="porttype">
                         <option value="TCP">TCP</option>
                         <option value="UDP">UDP</option>
                         <option value="TLS">TLS</option>
                       </select>
                       </div>
                     </td>
                     <td>
                     <div
                          className="position-relative w-md-200px me-md-2 col-lg-8 col-md-8 col-xxl-8"
                          id="hostnameinput"
                        >
                     <input
                         type="text"
                         className="form-control form-control-sm"
                         id="port"
                         name="port"
                         minvalue={1}
                         maxvalue={65444}
                         placeholder="Port eg: 5060"
                         defaultValue={5060}
                       />
                       </div>
                     </td>
                     <td>
                     <div className="position-relative w-md-200px me-md-2">
                     <input
                         type="text"
                         className="form-control  form-control-sm"
                         id="priority"
                         name="priority"
                         minvalue={1}
                         maxvalue={20}
                         placeholder="Priority"
                         defaultValue={10}
                       />
                    </div>
                     </td>
                     <td>
                     <div
                          className="position-relative w-md-200px me-md-2"
                          id="ttlinput"
                        >
                     <input
                         type="text"
                         className="form-control form-control-sm"
                         id="wight"
                         name="wight"
                         minvalue={1}
                         maxvalue={100}
                         placeholder="Wight"
                         defaultValue={100}
                       />
                      </div>
                     </td>
                     <td>

                     </td>
                     </tr>
                  </tbody>
                  {/*end::Table body*/}
                </table>
              </form>
            </div>
          </div>
          {/*end::Tap pane*/}
          {/*end::Tap pane*/}
        </div>
      </div>
      {/*end::incoming rules*/}
    </div>
    {/*end::License usage*/}
  </div>
  <div className="tab-pane fade" id="destroy_dns" role="tabpanel">
    {/*begin::Deactivate Account*/}
    <div className="card">
      {/*begin::Card header*/}
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_deactivate"
        aria-expanded="true"
        aria-controls="kt_account_deactivate"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Destroy DNS for {id}</h3>
        </div>
      </div>
      {/*end::Card header*/}
      {/*begin::Content*/}
      <div id="kt_account_settings_deactivate" className="collapse show">
        {/*begin::Form*/}
        {/*begin::Card body*/}
        <div className="card-body border-top p-9">
          {/*begin::Form input row*/}
          <div className="mb-10">
            <label htmlFor="size" className="required form-label">
              This will delete all records of dns for domain {id}
            </label>
          </div>
          {/*end::Form input row*/}
        </div>
        {/*end::Card body*/}
        {/*begin::Card footer*/}
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button
            onClick={deleteDns}
            type="button"
            className="btn btn-danger fw-bold"
          >
            Destroy
          </button>
        </div>
        {/*end::Card footer*/}
        {/*end::Form*/}
      </div>
      {/*end::Content*/}
    </div>
    {/*end::Deactivate Account*/}
  </div>
</div>
</>):(<><div class="loading-spinner2"></div></>)}
        </>
    )
}