import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import UseDevApi from "../../../../CustomHooks/useDevApi"; 
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";

export default function DatabaseCluster() { 
  const [dataFetched, setDataFetched] = useState(false); 
  const [perPage, setPerPage]=useState(5);
  const [clusterData, setClusterData] = useState(null); 
  const dataFetchedRef=useRef (false);
  function datbaseName(str) {
    // Split the string into an array using the delimiter "-"
    var arr = str.split("-");
    
    // Return the element at the 0th index
    return arr[0].toLowerCase();
  }
  function formatMessageTime(dateString) {
    const date = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - date.getTime());
    const minutes = Math.floor(timeDiff / (1000 * 60));
    const hours = Math.floor(minutes / 60);
  
    if (minutes <= 1) {
      return 'Just now';
    } else if (minutes < 30) {
      return `${minutes} mins ago`;
    } else if (minutes < 90) {
      return 'An hour ago';
    } else if (hours < 24) {
      const formattedTime = formatTime(date);
      return `Today at ${formattedTime}`;
    } else if (hours < 48) {
      const formattedTime = formatTime(date);
      return `Yesterday at ${formattedTime}`;
    } else {
      const formattedDate = formatDate(date);
      const formattedTime = formatTime(date);
      return `${formattedDate} at ${formattedTime}`;
    }
  }
  function formatTime(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}${period}`;
  }

  function formatDate(date) {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }
  const getDatabases=async()=>{ 
    const dt=toast
       const data=await UseApi('get','databases','');
       if(data){
        setDataFetched(true);
       }
       if(data.databases){
             if(data.databases.length==0){
              setClusterData([]);
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            }); 
             }else{
              setClusterData(data.databases);  
              toast.dismiss(); 
             }
       } 
  }
  const getDatabasesClusters=async()=>{  
    const dt=toast.loading("Databases loading..."); 
    const data=await UseDevApi('get','dbcluster','');
    if(data){
     setDataFetched(true);
    }
    if(data.status=="error"){
     setClusterData([]);
          toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
         });  
    }else{ 
     setClusterData(data.db);  
     toast.dismiss();  
       
    }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss(); 
      getDatabases();  
  },[]); 

  function ProductInfo({ productdata }) { 
    return(
        <> 
 
        {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((value, index) => (
       <tr>
       <td className="text-start" style={{paddingLeft:"20px"}}>
         <div className=" align-items-center">
           {" "}
           <div className="d-flex justify-content-start flex-column">
             {" "}
             <a className="text-dark fw-bold text-hover-primary fs-6 sphide">
               {value.cluster_name}
             </a>{" "}
           </div>{" "}
         </div>
       </td>
       <td className="text-center">
       <div className="d-flex align-items-center sphide ms-10">
             {" "}
             <div className="symbol symbol-30px me-5">
               {" "}
               {value.dclocation?(<>
                <img src={"/img/flag_"+value.dclocation.dccc+".png"} />
                  </>):('')}
               
             </div>{" "}
             <div className="d-flex justify-content-start flex-column">
               {" "}
               <a className="text-gray-700 fw-bold text-hover-primary fs-6 text-start">
                 {value.dclocation?(<>
                 {value.dclocation.location}
                 </>):('')}
               </a>{" "}
               <span className="text-muted fw-bold text-muted d-block fs-7 text-start">
               {value.dclocation?(<>
                 {value.dclocation.country}
                 </>):('')}
               </span>{" "}
             </div>{" "}
           </div>
       </td>
       <td className="text-center">
         <div className="d-flex align-items-center">
           <div className="symbol symbol-45px me-2">
             <span className="w-50">
               <img src={"/assets/media/databases/"+value.engine+".svg"} />
             </span>
           </div>
           <div className="d-flex justify-content-start flex-column">
             <a className=" fw-bold text-hover-primary d-block fs-6">
               {value.engine.charAt(0).toUpperCase() + value.engine.slice(1)}({value.version})
             </a>
           </div>
         </div>
       </td>
       <td className="text-center">
         <div className="text-center">
          {value.replica_node_count>1?(<>
            <span className="me-10">Primary +{parseInt(value.replica_node_count)}</span>
          </>):(<>
            <span className="me-10">Primary</span>
          </>)} 
           <span
             className="badge badge-success"
             style={{ cursor: "pointer" }}
           >
             <a id="checknsundefined-btn">HA </a>
           </span>
         </div>
       </td>
       <td className="text-center">
         <div className=" mb-4">
         {value.status=="Active"?(<>
                    <span className="fw-bold fs-6 text-gray-700"><span className="status-circle"></span>
                    Active
                    </span>  </>):(<>
                        <span className="fw-bold fs-6 text-gray-700"><span className="status-circle-warning"></span>
                    Pending
                    </span>  
                    </>)}
         </div>
       </td>
       <td className="text-center">
         <span className="fs-7 fw-bold sphide">
           {value.created_at}
         </span>
       </td>
       <td className="text-center">
         <div className="d-flex justify-content-end flex-shrink-0">
           {" "}
           <Link to={"/database-cluster/"+value.id}>
             <a className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 ">
               {" "}
               Manage{" "}
               <span className="svg-icon ms-2 svg-icon-3">
                 {" "}
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   width={24}
                   height={24}
                   viewBox="0 0 24 24"
                   fill="none"
                 >
                   {" "}
                   <rect
                     opacity="0.5"
                     x={18}
                     y={13}
                     width={13}
                     height={2}
                     rx={1}
                     transform="rotate(-180 18 13)"
                     fill="black"
                   />{" "}
                   <path
                     d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                     fill="black"
                   />{" "}
                 </svg>{" "}
               </span>{" "}
             </a>{" "}
           </Link>
         </div>{" "}
       </td>
     </tr>  
           ))):( <>
            <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
            </>)}
            
        </>
    )
}


const [searchTerm,setSearchTerm]=useState('');
function PaginatedItems({ itemsPerPage, ProductData, search }) {
  // We start with an empty list of items. 
  const [pageCount, setPageCount] = useState(0);
  const [vpcData,setVpcData]=useState(null);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    
    const endOffset = itemOffset + itemsPerPage;


  //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
     if(ProductData!=null){
    //   var str="Noida";
   // ////console.log(ProductData);
    const data= ProductData.filter(row => {
     return  Object.values(row).some(value => { 
        if(value!=null){
          if(value.toString().toLowerCase().includes(search.toLowerCase())){
            return value;
          } 
        }
      });
    });
    //////console.log(data);
      // const data=ProductData.filter(item => 
      //        item.hostname.toLowerCase().includes(search.toLowerCase())
      //              ); 

                   if(data.length<=0){
                    loaderFunc();
                   }
    setVpcData(data.slice(itemOffset, endOffset));
    //setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
    // alert(pageCount);
      }
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  
  const handlePageClick = (event) => { 
    const newOffset = event.selected * itemsPerPage % ProductData.length;
    ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
   
  };
  var searchEle=document.querySelector("#searchClient");
  if(searchEle){
    searchEle.focus();
  }
  return (
    <>
      <ProductInfo productdata={vpcData} />
      <td colspan="12">
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      </td>
    </>
  );
} 

  if (dataFetched) {
    if (clusterData != null) {
      console.log(clusterData);
      if (clusterData.length > 0) {
       
        return (
          <>
            <div className="col-xl-12">
              <div className="card card-xl-stretch mb-5 mb-xl-12">
                <div className="card-header border-0 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">
                      Database Clusters
                      <span className="svg-icon svg-icon-2 ms-3">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 84 84"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            id="DBaaS-Icon-1C-BLK"
                            transform="translate(0.189181, -0.000000)"
                            fill="#a1a5b7"
                            fillRule="nonzero"
                          >
                            <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                            <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                          </g>
                        </svg>
                      </span>
                    </span>
                    <span className="text-muted mt-1 fw-bold fs-7">
                      Manage your database clusters
                    </span>
                  </h3>
                  <div className="card-toolbar">
                  <div
                      className="position-relative my-1"
                      style={{ marginRight: 20 }}
                    >
                       <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>

                  <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select  
        onChange={e => setPerPage(e.target.value)}
        //data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={5}
        id="perPage"
        
      >
        <option value={5} selected="">
          5 Records
        </option>
        <option value={10}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
                      </div>
                    </div>
                    <Link to="/database-cluster/deploy">
                      <a className="btn btn-sm btn-primary btn-active-light-primary">
                        <span className="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="11.364"
                              y="20.364"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11.364 20.364)"
                              fill="black"
                            />
                            <rect
                              x="4.36396"
                              y="11.364"
                              width={16}
                              height={2}
                              rx={1}
                              fill="black"
                            />
                          </svg>
                        </span>
                        Create Cluster
                      </a>
                    </Link>
                  </div>
                </div>
                <div className="table-responsive">
                  <table
                    id="firewallTable"
                    className="table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                  >
                    <thead className="bg-secondary">
                      <tr className="fw-bold ">
                        <th className=" min-w-150px text-center">Cluster</th>
                        <th className="w-225px p-3 text-center">Location</th>
                        <th className=" min-w-150px text-center">Engine</th>
                        <th className="text-center min-w-200px">
                          configuration
                        </th>
                        <th className="text-center min-w-100px">Status</th>
                        <th className="text-center min-w-100px">Created at</th>
                        <th className="min-w-150px text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>  
                      <PaginatedItems itemsPerPage={perPage} ProductData={clusterData} search={searchTerm}  />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="col-xl-12">
              <div className="card">
                {/*begin::Card body*/}
                <div className="card-body p-0 mt-10">
                  {/*begin::Illustration*/}
                  <div className="text-center px-4 mt-10">
                    <span className="mw-100 mh-300px">
                      <svg
                        width="234px"
                        height="234px"
                        viewBox="0 0 84 84"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          id="DBaaS-Icon-1C-BLK"
                          transform="translate(0.189181, -0.000000)"
                          fill="#21325B"
                          fillRule="nonzero"
                        >
                          <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                          <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                        </g>
                      </svg>
                    </span>
                  </div>
                  {/*end::Illustration*/}
                  {/*begin::Wrapper*/}
                  <div className="card-px text-center py-5 mb-20">
                    {/*begin::Title*/}
                    <h1 className="fs-2x fw-bolder mb-5">Database Cluster</h1>
                    <h2 className="fs-1x fw-bolder mb-5 text-muted">
                      Power Up Your Data Management
                    </h2>
                    {/*end::Title*/}
                    {/*begin::Description*/}
                    <p className="text-gray-400 fs-4 fw-bold mb-10">
                      Effortlessly deploy a high-performance and fault-tolerant
                      database <br />
                      cluster, to efficiently manage and scale your data
                      infrastructure.
                    </p>
                    {/*end::Description*/}
                    {/*begin::Action*/}
                    <Link to="/database-cluster/deploy">
                      <a href="#" className="btn btn-primary mb-10">
                        Create Database Cluster
                      </a>
                    </Link>
                    {/*end::Action*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Card body*/}
              </div>
            </div>
          </>
        );
      }
    }
  } else {
    return (
      <>
      <TopBarHeader/>
        {/* <div class="loading-spinner2"></div> */}
      </>
    );
  }
}
