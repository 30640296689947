import React from "react";
import { Link } from "react-router-dom";

export default function NewPassword(){
    return(
        <>
        <div className="page d-flex flex-row flex-column-fluid loginbg">
        <div
  className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative  d-none d-lg-block d-md-block d-xxl-block" 
  >
    {/*begin::Wrapper*/}
    <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
   
    </div>
  {/*end::Wrapper*/}
</div>

<div className="d-flex flex-column flex-lg-row-fluid py-10 top-front">
  {/*begin::Content*/}
  <div className="d-flex flex-center flex-column flex-column-fluid">
    {/*begin::Logo*/}
  <a href="/" className="text-center ">
        <img
          alt="Logo"
          src="./assets/media/logos/logo_white_utho.png"
          className="h-60px"
        />
      </a>
      {/*end::Logo*/} 
    {/*begin::Wrapper*/}
    <div className="w-lg-550px p-10 p-lg-15 mx-auto">
      {/*begin::Form*/}
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate="novalidate"
        id="kt_new_password_form"
      >
        {/*begin::Heading*/}
        <div className="text-center mb-10">
          {/*begin::Title*/}
          <h1 className="text-light mb-3">Setup New Password</h1>
          {/*end::Title*/}
          {/*begin::Link*/}
          <div className="text-gray-400 fw-bold fs-4">
            Already have reset your password ?
            <Link to="/login">
            <a
              
              className="link-primary fw-bolder"
            >
              Sign in here
            </a>
            </Link>
          </div>
          {/*end::Link*/}
        </div>
        {/*begin::Heading*/}
        {/*begin::Input group*/}
        <div
          className="mb-10 fv-row fv-plugins-icon-container"
          data-kt-password-meter="true"
        >
          {/*begin::Wrapper*/}
          <div className="mb-1">
            {/*begin::Label*/}
            <label className="form-label fw-bolder text-gray-400 fs-6">
              Password
            </label>
            {/*end::Label*/}
            {/*begin::Input wrapper*/}
            <div className="position-relative mb-3">
              <input
                className="form-control form-control-lg form-control-solid"
                type="password"
                placeholder=""
                name="password"
                autoComplete="off"
              />
              <span
                className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                data-kt-password-meter-control="visibility"
              >
                <i className="bi bi-eye-slash fs-2" />
                <i className="bi bi-eye fs-2 d-none" />
              </span>
            </div>
            {/*end::Input wrapper*/}
            {/*begin::Meter*/}
            <div
              className="d-flex align-items-center mb-3"
              data-kt-password-meter-control="highlight"
            >
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" />
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" />
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" />
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px" />
            </div>
            {/*end::Meter*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Hint*/}
          <div className="text-gray-400">
            Use 8 or more characters with a mix of letters, numbers &amp;
            symbols.
          </div>
          {/*end::Hint*/}
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        {/*end::Input group=*/}
        {/*begin::Input group=*/}
        <div className="fv-row mb-10 fv-plugins-icon-container">
          <label className="form-label fw-bolder text-gray-400 fs-6">
            Confirm Password
          </label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="password"
            placeholder=""
            name="confirm-password"
            autoComplete="off"
          />
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        {/*end::Input group=*/}
        {/*begin::Input group=*/}
        <div className="fv-row mb-10 fv-plugins-icon-container">
          <div className="form-check form-check-custom form-check-solid form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              name="toc"
              defaultValue={1}
            />
            <label className="form-check-label fw-bold text-gray-400 fs-6">
              I Agree &amp;
              <a  className="ms-1 link-primary">
                Terms and conditions
              </a>
              .
            </label>
          </div>
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        {/*end::Input group=*/}
        {/*begin::Action*/}
        <div className="text-center">
          <button
            type="button"
            id="kt_new_password_submit"
            className="btn btn-lg btn-primary fw-bolder w-100"
          >
            <span className="indicator-label">Submit</span>
          </button>
        </div>
        {/*end::Action*/}
        <div />
      </form>
      {/*end::Form*/}
    </div>
    {/*end::Wrapper*/}
  </div>
  {/*end::Content*/}
 
</div>
</div>
        </>
    )
}