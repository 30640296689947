import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import UsePostData from "../../../../CustomHooks/usePostData";
import swal from 'sweetalert';    
import { useNavigate } from "react-router-dom/dist";
function useForceUpdate(){
  const [value, setValue] = useState(0);  
  return () => setValue(value => value + 1); 
} 
export default function KycSent(){
  const forceUpdate=useForceUpdate();
  const [kycData,setKycData]=useState([]);
  const [userData, setUserData]=useState(null);
  const dataFetchedRef=useRef (false);
  const navigate=useNavigate();
  const getKYC=async()=>{
    
    const dt=toast.loading("KYC users loading..."); 
       const data=await UseApi('get','account/info',''); 
       if(Object.keys(data).length === 0){
        setKycData([]);
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            }); 
       }else{
        //setKycData(data.user.kyc_data); 
        setUserData(data.user); 
            toast.update(dt,{render: "Your KYC users listed below", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });  
          data.user.kyc_data.map((value,index)=>{
            //console.log(Object.keys(value.actions[0].details).length);
            if(Object.keys(value.actions[0].details).length>0){
              kycData.push(value); 
             // console.log("value");
            }
            
          })
          forceUpdate();   
       }
       swal({
          title: "Complete your Kyc",
          text: "Kyc email sent your registered email address, Please check your email!",
          icon: "success",
          button: "OK",
        });
         
        setTimeout(() => {
          navigate('/account/kyc');
        }, 1000);
  }

  
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      getKYC(); 
  },[]);  

  const getInfo=(id)=>{
    kycData.map((value,index)=>{
      if(value.id==id){ 
        var image = new Image();
        image.src = 'data:image/png;base64,'+value.actions[0].details.aadhaar.image;
        const profile=document.querySelector('#aadhaar_image');
        const aadhaar=document.querySelector('#aadhaar_id');
        const fullname=document.querySelector('#aadhaar_fullname');
        const dob=document.querySelector('#aadhaar_dob');
        const gender=document.querySelector('#aadhaar_gender');
        const address=document.querySelector('#aadhaar_address');
        profile.innerHTML='<img src="'+image.src+'" style="width:100%"/>';
        aadhaar.innerHTML=value.actions[0].details.aadhaar.id_number;
        fullname.innerHTML=value.actions[0].details.aadhaar.name;
        dob.innerHTML=value.actions[0].details.aadhaar.dob;
        gender.innerHTML=value.actions[0].details.aadhaar.gender;
        address.innerHTML=value.actions[0].details.aadhaar.permanent_address;

       // console.log(image.src+" <==> "+value.actions[0].details.aadhaar.id_number+" <==> "+value.actions[0].details.aadhaar.name+" <==> "+value.actions[0].details.aadhaar.dob+" <==> "+value.actions[0].details.aadhaar.gender+" <==> "+value.actions[0].details.aadhaar.permanent_address);
      }
    }) 
  }
 // console.log(kycData);

 var options={
  environment: "sandbox",
  callback : function(response){
     if(response.hasOwnProperty("error code")){
                    swal({
                  title: "Kyc Request",
                  text: "Your KYC request has been cancelled!",
                  icon: "warning",
                  button: "OK",
                });
               }else{
               
             var digio_val=JSON.stringify(response);
             var requestid=response.digio_doc_id; 
             UsePostData('POST','account/kyc', {"digio_data":digio_val,"requestid":requestid});
             getKYC();
             // setTimeout(() => {
             //       //  window.location.href="<?=$siteurl?>/#account";
             //       }, 4000);
                     }
                 },
            logo : "https://www.utho.com/assets/img/logo-dark-registered-cloud-blue.png",
            theme:{
              primaryColor: "#AB3498",
              secondaryColor: "#000000"
            }
 }
 
 const kyc_process=()=>{
  swal({
      title: "Complete your Kyc",
      text: "Kyc email sent your registered email address, Please check your email!",
      icon: "success",
      button: "OK",
    });
      //  if(userData!=null){
      //    const kycData=UseDigioPost("POST","react_kyc.php",{"email":userData.email,"fullname":userData.fullname});
      //    console.log(kycData); 
      // }

 }
    return(
        <>
        {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
        <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold fs-3 mb-1">
          KYC
          {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.3"
                d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z"
                fill="black"
              />
              <path
                d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z"
                fill="black"
              />
              <path
                d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </span>
        <span className="text-muted mt-1 fw-bold fs-7" />
      </h3>
      <div
        className="card-toolbar" 
      >
        <div className="position-relative my-1" style={{ marginRight: 20 }}>
          <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            name="searchClient"
            placeholder="Search"
          />
        </div>
        <form action="http://nitin.cloudplatformapp.com/react_kyc.php">
          <input type="hidden" value={userData!=null?(userData.email):('')} name="email"/>
          <input type="hidden" value={userData!=null?(userData.fullname):('')} name="fullname"/>
        
        <button  
          className="btn btn-sm btn-primary btn-active-light-primary"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
          <span className="svg-icon svg-icon-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-90 11.364 20.364)"
                fill="black"
              />
              <rect
                x="4.36396"
                y="11.364"
                width={16}
                height={2}
                rx={1}
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}Check your email
        </button>
        </form>
      </div>
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    {/*begin::Table container*/}
    <div className="table-responsive">
      {/*begin::Table*/}
      <table
        id="kycdata"
        className="table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className="fw-bold ">
            <th className="w-25px text-center">#</th>
            <th className="w-150px">Name</th>
            <th className="w-150px">Updated at</th>
            <th className="w-150px">Created at</th>
            <th className="w-150px text-center">Status</th>
            <th className="w-150px text-center" />
          </tr>
        </thead>
        {/*end::Table head*/}
        {/*begin::Table body*/}
        <tbody id="kyc-body">
        {kycData.length>0?(kycData && kycData.map((value, index) => (
        <tr>
           <td class="p-3">
            <div class=" flex-column w-100 me-2">
              <div class="text-center flex-stack mb-2">
                <span class=" me-2 fs-7 fw-bold">{++index}</span>
              </div>
            </div>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <div class="d-flex justify-content-start flex-column">
                  <a href="#" class="text-dark fw-bold text-hover-primary fs-6">{value.actions[0].details.length !=0 ? value.actions[0].details.aadhaar.name : ''}</a>
                  <span class="text-muted fw-bold text-muted d-block fs-7">{value.customer_identifier}</span>
                </div>
              </div>
            </td>
            <td class="text-end">
              <div class="d-flex flex-column w-100 me-2">
                <div class="d-flex flex-stack mb-2">
                  <span class=" me-2 fs-7 fw-bold">{value.updated_at}</span>
                </div>
              </div>
            </td>
            <td class="text-end">
              <div class="d-flex flex-column w-100 me-2">
                <div class="d-flex flex-stack mb-2">
                  <span class=" me-2 fs-7 fw-bold">{value.created_at}</span>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-center flex-shrink-0">
                {value.status=='approved'?(<span class="btn btn-sm btn-light-success">Approved</span>):(<span class="btn btn-sm btn-light-warning">Pending</span>)}
                
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-end flex-shrink-0">
                <a onClick={()=>getInfo(value.id)}
                        data-bs-toggle="modal"
                        data-bs-target="#kyc-modal" 
                        class="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 "
                        >
                    View 
                  <span class="svg-icon ms-2 svg-icon-3"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect> 
                      <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                    </svg>
                  </span> 
                </a> 
              </div>
            </td>
         </tr>
          ))):(<td colspan="12" className="dataTables_empty" valign="top"><div className="d-flex flex-column flex-center">
          <img src="/assets/media/illustrations/dozzy-1/5.png" className="mw-400px" />
          <div className="fs-1 fw-bolder text-dark mb-4">Your KYC not complete</div><div className="fs-1 fw-bolder text-dark mb-4"><Link to="/cloud/deploy"><a href="#" className="btn btn-primary">Complete your KYC</a></Link></div>
          </div></td>)}
        </tbody>
        {/*end::Table body*/}
      </table>
      {/*end::Table*/}
    </div>
    {/*end::Table container*/}
    {/*begin::Body*/}
  </div>
  {/*end::Tables Widget 9*/}
</div>


<div
  className="modal fade"
  id="kyc-modal"
  tabIndex={-1}
  style={{ display: "none" }}
  aria-hidden="true"
>
  {/*begin::Modal dialog*/}
  <div className="modal-dialog modal-dialog-centered mw-650px">
    {/*begin::Modal content*/}
    <div className="modal-content">
      {/*begin::Modal header*/}
      <div className="p-4 bg-primary">
        {/*begin::Modal title*/}
        <h2 className="text-light">KYC Details</h2>
        {/*end::Modal title*/}
        {/*begin::Close*/}
        {/* <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
          <span class="svg-icon svg-icon-1">
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
             </svg>
          </span>
       </div> */}
        {/*end::Close*/}
      </div>
      {/*end::Modal header*/}
      {/*begin::Form*/}
      {/*begin::Modal body*/}
      <div className="modal-body py-10 px-lg-17">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-4">
                <div
                  className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
                  id="aadhaar_image"
                >
                  <img
                    src=""
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="col-8">
                <div style={{ marginLeft: 20 }}>
                  <div className="row">
                    <div className="col-6">
                      {/*begin::Details item*/}
                      <div className="fw-bolder mt-5">Aadhaar ID</div>
                      <div className="text-gray-600" id="aadhaar_id">
                         
                      </div>
                      {/*begin::Details item*/}
                    </div>
                    <div className="col-6">
                      {/*begin::Details item*/}
                      <div className="fw-bolder mt-5">Full Name</div>
                      <div className="text-gray-600">
                        <a
                          href="#"
                          className="text-gray-600 text-hover-primary"
                          id="aadhaar_fullname"
                        >
                           
                        </a>
                      </div>
                      {/*begin::Details item*/}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      {/*begin::Details item*/}
                      <div className="fw-bolder mt-5">Date of birth</div>
                      <div className="text-gray-600" id="aadhaar_dob">
                         
                      </div>
                      {/*begin::Details item*/}
                    </div>
                    <div className="col-6">
                      {/*begin::Details item*/}
                      <div className="fw-bolder mt-5">Gender</div>
                      <div className="text-gray-600" id="aadhaar_gender">
                         
                      </div>
                      {/*begin::Details item*/}
                    </div>
                  </div>
                  {/*begin::Details item*/}
                  <div className="fw-bolder mt-5">Address</div>
                  <div className="text-gray-600" id="aadhaar_address">
                     
                  </div>
                  {/*begin::Details item*/}
                  {/*begin::Details item*/}
                  {/* <div class="fw-bolder mt-5">Address</div>
														<div class="text-gray-600">101 Collin Street,
														<br>Melbourne 3000 VIC
														<br>Australia</div> */}
                  {/*begin::Details item*/}
                  {/*begin::Details item*/}
                  {/* <div class="fw-bolder mt-5">Last Login</div>
														<div class="text-gray-600">20 Jun 2022, 6:43 am</div> */}
                  {/*begin::Details item*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*end::Modal body*/}
      {/*end::Form*/}
    </div>
    {/*end::Modal content*/}
  </div>
  {/*end::Modal dialog*/}
</div>

        </>
    )
}