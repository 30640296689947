import React, { useEffect } from "react";
import Body from "./Body/Body";
// import Footer from "./Footer/Footer";
import AsideBar from "./Header/Aside/AsideBar";
import Toolbar from "./Header/Toolbar/Toolbar";
import BottomNavbar from "./Header/BottomNavbar/BottomNavbar"; 
import Feedback from "./Body/Feedback";
import { useNavigate } from "react-router-dom";

export default function MicroApp() {
  const navigate=useNavigate(); 
  return (
    <>
      <BottomNavbar />
      <AsideBar />
       <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <Toolbar />
        <Body />
       </div> 
        <Feedback />
    </>
  );
}
