import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../CustomHooks/usePost";
import swal from "sweetalert"; 

export default function ManageVpn(){
  var fileDownload = require('js-file-download');
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4];
  const navigate=useNavigate();
  const [vpnData,setVpnData]=useState(null);
  const dataFetchedRef=useRef (false);
  const navRedirect=()=>{
    setTimeout(() => {
      navigate("/vpn/"+id);
     }, 3000);
      
  }
  const getVPN=async()=>{
     
       const data=await UseApi('get','vpn/'+id,'');
       //console.log(Object.keys(data).length);
       if(Object.keys(data).length <   2){
        swal({
            title: "VPN",
            text: "No VPN record found with id: "+id+"!",
            icon: "warning",
            button: "OK",
          }).then(()=>{
            navigate("/vpn");
          })
          return; 
       }else{ 
        setVpnData(data.vpn[0]);   
        //   toast.update(dt,{render: "Your VPN info listed below", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          // });  
          ////console.log(vpnData);
       }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getVPN(); 
  },[]); 
 // //console.log(vpnData);


  const addVpnUser=async()=>{
    toast.dismiss();
    const dt=toast.loading("Please wait...");
    const responseData=await UsePost('post','vpn/'+id+'/user/add','addnewuser');
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else{
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      getVPN();
      navRedirect();
        
      }
     
  }

  const destroyVpn=async()=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this VPN!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("VPN deleting..."); 
    const dnsp=await UseApi('DELETE','vpn/'+id+'/destroy','');
    //console.log(dnsp);
    if(dnsp.status=='error'){
      toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else if(dnsp.status=='success'){
      toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });  
    navigate('/vpn');
    }
      }else {
        swal({
            title: "VPN",
            text: "Your VPN is safe!",
            icon: "success",
            button: "OK",
          });
      }
      });
  }

  const deleteVpn=async(name)=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this VPN!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("VPN user deleting..."); 
    const dnsp=await UseApi('DELETE','vpn/'+id+'/user/'+name+'/remove','');
    //console.log(dnsp);
    if(dnsp.status=='error'){
      toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else if(dnsp.status=='success'){
      toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    }); 
    getVPN();
    navRedirect();
    }
      }else {
        swal({
            title: "VPN",
            text: "Your VPN user is safe!",
            icon: "success",
            button: "OK",
          });
      }
      });
  }

  const downloadVpn=async(name)=>{ 
    toast.dismiss();
    const dt=toast.loading("VPN downloading..."); 
    const dnsp=await UseApi('get','vpn/'+id+'/user/'+name+'/download',''); 
    fileDownload(dnsp, name+'.ovpn'); 
    toast.update(dt,{render: 'VPN downloaded successfully!', type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
     }); 
      navRedirect(); 
  }
    return(
        <>
        {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
        <div className="col-xl-12">
          {vpnData!=null?(<>
  <div className="post d-flex flex-column-fluid">
    <div className="container-xxl" id="appresult">
      <div className="card mb-6 mb-xl-9">
        <div className="card-body pt-9 pb-0">
          {/*begin::Details*/}
          <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
            {/*begin::Image*/}
            <div
              className="d-flex flex-center flex-shrink-0 rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4"
              id="flag-icon"
            >
              {/*begin::Image input*/}
              <div
                className="image-input image-input-empty"
                data-kt-image-input="true"
              >
                {" "}
                <img
                  src={"/img/flag_"+vpnData.dclocation.dccc+".png"}
                  className="image-input-wrapper w-40px h-40px"
                />{" "}
              </div>
              {/*end::Image input*/}
            </div>
            {/*end::Image*/}
            {/*begin::Wrapper*/}
            <div className="flex-grow-1">
              {/*begin::Head*/}
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                {/*begin::Details*/}
                <div className="d-flex flex-column">
                  {/*begin::Status*/}
                  <div className="d-flex align-items-center mb-1">
                    <a
                      
                      className="text-gray-800 text-hover-primary fs-2 fw-bold me-3"
                      id="vpn-name"
                    >
                      {vpnData.name}
                    </a>
                  </div>
                  {/*end::Status*/}
                  {/*begin::Description*/}
                  <div className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400">
                    Location: <span id="vpn-location">{vpnData.dclocation.location}</span>
                  </div>
                  {/*end::Description*/}
                  {/*begin::Wrapper*/}
                  {/*end::Wrapper*/}
                </div>
                {/*end::Details*/}
                {/*begin::Actions*/}
                <div className="d-flex mb-4 ">
                 {vpnData.status=='Active'?(
                  <a
                    
                    className="btn btn-sm btn-success"
                    id="vpn-status"
                  >
                    {vpnData.status}
                  </a>
                  ):(
                    <a
                    
                    className="btn btn-sm btn-warning"
                    id="vpn-status"
                  >
                    {vpnData.status}
                  </a>
                  )}
                </div>
                {/*end::Actions*/}
              </div>
              {/*end::Head*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          <div className="d-flex flex-wrap">
            {/*begin::Stat*/}
            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
              {/*begin::Number*/}
              <div className="d-flex align-items-center">
                <div className="fs-2 fw-bold" id="vpn-ip">
                  {vpnData.ip}
                </div>
              </div>
              {/*end::Number*/}
              {/*begin::Label*/}
              <div className="fw-bold fs-6 text-gray-400">VPN Gateway</div>
              {/*end::Label*/}
            </div>
            {/*end::Stat*/}
            {/*begin::Stat*/}
            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
              {/*begin::Number*/}
              <div className="d-flex align-items-center">
                <div className="fs-2 fw-bold" id="vpn-users">
                  {vpnData.accountlimit}
                </div>
              </div>
              {/*end::Number*/}
              {/*begin::Label*/}
              <div className="fw-bold fs-6 text-gray-400">Total Users</div>
              {/*end::Label*/}
            </div>
            {/*end::Stat*/}
            {/*begin::Stat*/}
            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
              {/*begin::Number*/}
              <div className="d-flex align-items-center">
                <div className="fs-2 fw-bold" id="vpn-created">
                  {vpnData.accountcreated}
                </div>
              </div>
              {/*end::Number*/}
              {/*begin::Label*/}
              <div className="fw-bold fs-6 text-gray-400">VPN Created</div>
              {/*end::Label*/}
            </div>
            {/*end::Stat*/}
          </div>
          <div className="separator" />
          {/*begin::Nav*/}
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            {/*begin::Nav item*/}
            <li className="nav-item">
              <a
                className="nav-link text-active-primary py-5 me-6 active"
                data-bs-toggle="tab"
                href="#manage_users"
              >
                Manage Users
              </a>
            </li>
            {/*end::Nav item*/}
            {/*begin::Nav item*/}
            <li className="nav-item">
              <a
                className="nav-link text-active-primary py-5 me-6"
                data-bs-toggle="tab"
                href="#firewall"
              >
                Firewall
              </a>
            </li>
            {/*end::Nav item*/}
            {/*begin::Nav item*/}
            <li className="nav-item">
              <a
                className="nav-link text-active-primary py-5 me-6"
                data-bs-toggle="tab"
                href="#destroy"
              >
                Destroy
              </a>
            </li>
            {/*end::Nav item*/}
          </ul>
          {/*end::Nav*/}
        </div>
      </div>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="manage_users"
          role="tabpanel"
        >
          {/*begin::License usage*/}
          <div className="card">
            {/*begin::Header*/}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">VPN Users</span>
                <span className="text-muted mt-1 fw-bold fs-7">
                  View your API keys
                </span>
              </h3>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Click to add a user"
              >
                <a 
                  className="btn btn-sm btn-light-primary btn-active-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#addnewuser-modal"
                >
                  Add New user
                </a>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Card body*/}
            <div className="card-body">
              {/*begin::Table wrapper*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
                  id="kt_security_license_usage_table"
                >
                  {/*begin::Table head*/}
                  <thead>
                    <tr className="fw-bold ">
                      <th className="min-w-250px p-3">Name</th>
                      <th className="min-w-200px">Local IP</th>
                      <th className="min-w-200px">Created at</th>
                      <th className="min-w-50px" />
                      <th className="min-w-50px" />
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  <tbody id="vpn-user-list">
                    {Object.keys(vpnData.users).length > 0 && (vpnData.users && vpnData.users.map((value,index)=>(
                    <tr>
                      {" "}
                      <td className="fw-bold p-3">
                        {" "}
                        <div className=" mt-1 fw-bold symbol symbol-45px me-2">
                          {" "}
                          {value.name}{" "}
                        </div>{" "}
                      </td>{" "}
                      <td className="fw-bold">
                        {" "}
                        <div className="text-muted mt-1 symbol symbol-45px me-2">
                          {" "}
                          {value.ip}{" "}
                        </div>{" "}
                      </td>{" "}
                      <td className="fw-bold">
                        {" "}
                        <div className="text-muted mt-1 symbol symbol-45px me-2">
                          {" "}
                          {value.created_at}{" "}
                        </div>{" "}
                      </td>{" "}
                      <td className="text-end">
                        {" "}
                        <a
                          onClick={()=>downloadVpn(value.name)}
                          
                          className="btn btn-success btn-sm"
                        >
                          Download
                        </a>{" "}
                      </td>{" "}
                      <td className="text-end">
                        {" "}
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={()=>deleteVpn(value.name)}
                           >
                          Remove{" "}
                        </button>
                      </td>{" "}
                    </tr>
                    )))}
                  </tbody>
                </table>
              </div>
              {/*end::Table wrapper*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::License usage*/}
        </div>
        <div className="tab-pane fade" id="firewall" role="tabpanel">
          {/*begin::Access*/}
          <div className="card mb-5 mb-xxl-10">
            {/*begin::Header*/}
            <div className="card-header">
              {/*begin::Title*/}
              <div className="card-title">
                <h3>Firewalls</h3>
              </div>
              {/*end::Title*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-10">
              {/*begin::Row*/}
              <div className="row mb-10">
                {/*begin::Notice*/}
                <div className="notice d-flex bg-light-primary rounded  p-6">
                  {/*begin::Icon*/}
                  {/*begin::Svg Icon | path: icons/duotune/finance/fin001.svg*/}
                  <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z"
                        fill="black"
                      />
                      <path
                        opacity="0.3"
                        d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                  {/*end::Icon*/}
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                    {/*begin::Content*/}
                    <div className="mb-3 mb-md-0 fw-bold">
                      <div className="fs-6 text-gray-700 pe-7">
                        You can create new firewall or assign any existing
                        firewall group to this VPN.
                      </div>
                    </div>
                    {/*end::Content*/}
                    {/*begin::Action*/}
                    <Link to="/firewall/deploy">
                    <a
                      
                      className="btn btn-primary px-6 align-self-center text-nowrap"
                    >
                      Create or assign Firewall
                    </a>
                    </Link>
                    {/*end::Action*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Notice*/}
              </div>
              {/*end::Row*/}
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Access*/}
          {/*begin::License usage*/}
          <div className="card">
            {/*begin::Card header*/}
            <div className="card-header">
              {/*begin::Heading*/}
              <div className="card-title">
                <h3>Current Firewalls </h3>
              </div>
              {/*end::Heading*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body p-0">
              {/*begin::Table wrapper*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
                  id="kt_security_license_usage_table"
                >
                  {/*begin::Thead*/}
                  <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
                    <tr>
                      <th className="w-150px ps-9">Firewall Name</th>
                      <th className="w-150px">Created At</th>
                      <th className="w-150px ps-9" />
                    </tr>
                  </thead>
                  {/*end::Thead*/}
                  {/*begin::Tbody*/}
                  <tbody
                    id="firewall-list"
                    className="fw-6 fw-bold text-gray-600"
                  >
                    {Object.keys(vpnData.firewalls).length > 0 && (vpnData.firewalls && vpnData.firewalls.map((value,index)=>(
                    <tr>
                      <td className="ps-9">{value.name}</td>
                      <td>{value.created_at}</td>
                      <td className="ps-9">
                        {" "}
                        <Link to={"/firewall/"+value.id}>
                        <a
                          
                          className="btn btn-light-primary"
                        >
                          Manage Firewall
                        </a>
                        </Link>
                        {" "}
                      </td>
                    </tr>
                    )))}
                  </tbody>
                  {/*end::Tbody*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table wrapper*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::License usage*/}
        </div>
        <div className="tab-pane fade" id="destroy" role="tabpanel">
          {/*begin::Deactivate Account*/}
          <div className="card">
            {/*begin::Card header*/}
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_deactivate"
              aria-expanded="true"
              aria-controls="kt_account_deactivate"
            >
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Destroy VPN</h3>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Content*/}
            <div id="kt_account_settings_deactivate" className="collapse show">
              {/*begin::Form*/}
              {/*begin::Card body*/}
              <div className="card-body border-top p-9">
                {/*begin::Notice*/}
                <div className="notice d-flex bg-light-primary rounded  mb-9 p-6">
                  {/*begin::Icon*/}
                  {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                  <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.3"
                        x={2}
                        y={2}
                        width={20}
                        height={20}
                        rx={10}
                        fill="black"
                      />
                      <rect
                        x={11}
                        y={14}
                        width={7}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11 14)"
                        fill="black"
                      />
                      <rect
                        x={11}
                        y={17}
                        width={2}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11 17)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                  {/*end::Icon*/}
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-stack flex-grow-1">
                    {/*begin::Content*/}
                    <div className="fw-bold">
                      <h4 className="text-gray-900 fw-bold">
                        This will destroy your VPN.
                      </h4>
                      <div className="fs-6 text-gray-700">
                        Your VPN will be permanently destroyed. You will lose
                        the provisioned IP address.
                      </div>
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Notice*/}
              </div>
              {/*end::Card body*/}
              {/*begin::Card footer*/}
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  id="destroy-btn"
                  onClick={destroyVpn}
                  type="button"
                  className="btn btn-danger fw-bold"
                >
                  Destroy VPN
                </button>
              </div>
              {/*end::Card footer*/}
              {/*end::Form*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Deactivate Account*/}
        </div>
      </div>
    </div>
  </div>
  </>):(<><div class="loading-spinner2"></div></>)}
</div>


<div
  className="modal fade"
  id="addnewuser-modal"
  tabIndex={-1} 
>
  {/*begin::Modal dialog*/}
  <div className="modal-dialog modal-dialog-centered mw-650px">
    {/*begin::Modal content*/}
    <div className="modal-content">
      {/*begin::Modal header*/}
      <div className="modal-header" id="kt_modal_create_api_key_header">
        {/*begin::Modal title*/}
        <h2>Add New User</h2>
        {/*end::Modal title*/}
        {/*begin::Close*/}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          data-bs-dismiss="modal"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Modal header*/}
      {/*begin::Form*/}
      <form id="addnewuser" name="addnewuser" className="form">
        {/*begin::Modal body*/}
        <div className="modal-body py-10 px-lg-17">
          {/*begin::Scroll*/}
          <div
            className="scroll-y me-n7 pe-7"
            id="kt_modal_create_api_key_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
            data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
            data-kt-scroll-offset="300px"
          >
            {/*begin::Input group*/}
            <div className="mb-5 fv-row">
              {/*begin::Label*/}
              <label className="required fs-5 fw-bold mb-2">
                Please enter name of user (no special characters are allowed,
                Only 1 word name).
              </label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <input
                type="text"
                className="form-control  "
                id="client"
                name="client"
                defaultValue=""
                placeholder="Enter user name"
              />
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Modal body*/}
        {/*begin::Modal footer*/}
        <div className="modal-footer flex-center" id="add-btn">
          <button
            onClick={addVpnUser}
            type="button"
            className="btn btn-primary fw-bold"
          >
            + Add Users
          </button>
        </div>
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
    </div>
    {/*end::Modal content*/}
  </div>
  {/*end::Modal dialog*/}
</div>

        </>
    )
}