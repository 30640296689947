import React, { useEffect, useRef, useState } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import swal from "sweetalert";    
import Access from "./storage/tabs/Access";
import FileFolder from "./storage/tabs/FileFolder";
import Ssl from "./storage/tabs/Ssl"; 
import UseApi from "../../../../CustomHooks/useApi";
import Permissions from "./storage/tabs/Permissions";
import UsePost from "../../../../CustomHooks/usePost";
import CreateAccesskey from "./accessKey/CreateAccesskey";

export default function ManageObjectStorage(){

    
    var query = window.location.href; 
    var vars = query.split("/"); 
    var id=vars[5]; 
  
    const navigate=useNavigate();
    const navRedirect=()=>{
      setTimeout(() => {
        navigate('/objectStorage');
       }, 2000);
        
    }
  const dataFetchedRef=useRef(false); 
  const [objectData, setObjectData] = useState(null);
  
  const getObjectStorage=async()=>{   
    const data=await UseApi('get','objectstorage/innoida/bucket/'+id,'');
    //console.log(data);
    
    if(data.status == "error"){
      setObjectData(null);
      swal({
          title: "No Record found ",
          text: "We are unable to find record with this Id: "+id+"!",
          icon: "warning",
          button: "OK",
        });
        navRedirect();
    }else{ 
      if(data){
      setObjectData(data);
       
    } 
   }
  }  
  
  const deleteObject=async()=>{
    var submitBtn=document.querySelector("#destroy-btn"); 
     var confirmString=document.querySelector("#confirm");
     if(confirmString){
      if(confirmString.value!="I am aware this action will delete data and bucket permanently"){
        const dt=toast.loading(""); 
          toast.update(dt,{render: "Please provide the confirmation", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
      }
     }
    if(submitBtn){
     
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this object storage!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        var preContent=submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
    
        const dt=toast.loading("Object Storage deleting..."); 
        const dnsp=await UseApi('DELETE','objectstorage/innoida/bucket/'+id+'/delete','');
        //console.log(dnsp);
        if(dnsp.status=='error'){
          toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        }else{
          toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });  
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML=preContent;
        navRedirect();
        }
      } 
      });
    }
    
  }
  
  useEffect(()=>{  
    if(dataFetchedRef.current) return;
      dataFetchedRef.current=true; 
      getObjectStorage();  
  },[]);  

  const [isActivePermission, setIsActivePermission] = useState(false); 
  
  function togglePermission(data){ 
         setIsActivePermission(data);
  }
 

  if(objectData!=null){
  return (
    <>
    <div className="card mb-6 mb-xl-9 ">
    <CreateAccesskey />
    </div>
      <div className="card mb-6 mb-xl-9 ">
     
        <div className="card-body pt-9 pb-0 ">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
            <div
              className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4"
              id="imageDist"
            >
              <span className="w-50">
                <svg
                  width={44}
                  height={44}
                  viewBox="0 0 28 22"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <title>dropbox [#158]</title>
                  <desc>Created with Sketch.</desc>
                  <defs />
                  <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Dribbble-Light-Preview"
                      transform="translate(-300.000000, -7479.000000)"
                      fill="#494b74"
                    >
                      <g
                        id="icons"
                        transform="translate(56.000000, 160.000000)"
                      >
                        <path
                          d="M254.012,7330.74707 L249.825,7334.24637 L248,7333.0687 L248,7334.38937 L254,7338 L260,7334.38937 L260,7333.0687 L258.187,7334.24637 L254.012,7330.74707 Z M264,7322.92318 L258.117,7319 L254,7322.50952 L259.932,7326.25089 L264,7322.92318 Z M254,7329.99226 L258.117,7333.50177 L264,7329.57859 L259.932,7326.25089 L254,7329.99226 Z M244,7329.57859 L249.883,7333.50177 L254,7329.99226 L248.068,7326.25089 L244,7329.57859 Z M254,7322.50952 L248.068,7326.25089 L244,7322.92318 L249.883,7319 L254,7322.50952 Z"
                          id="dropbox-[#158]"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-1" id="showHost">
                    <a
                      className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3"
                      id="hostname"
                    >
                      {objectData.name}
                    </a>
                    <a></a>
                  </div>
                  <div className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400">
                    Size - &nbsp;<span className="fw-bolder"> {objectData.size?((objectData.size/1024).toFixed()):(0)} GB</span>
                  </div>
                </div>
                <div className="d-flex" id="console">
                <div className="d-flex flex-stack mb-5">
                    {/*begin::Section*/}
                    <div
                      className="d-flex align-items-center me-5"
                      style={{
                        paddingRight: "28px",
                        borderRight: "3px solid #b5b5c3",
                      }}
                    >
                      {/*begin::Symbol*/}
                      <div className="symbol symbol-50px me-8">
                        <div className="">
                        {objectData.dclocation && objectData.dclocation?(<> 
                            <img
                            src={"/img/flag_"+objectData.dclocation.dccc+".png"}
                            className="h-50 rounded"
                            alt=""
                          />
                 </>):('')}
                          
                        </div>
                      </div>
                      {/*end::Symbol*/}
                      {/*begin::Title*/}
                      <div>
                        <a
                          className="fs-6 text-gray-800 text-hover-primary fw-bolder"
                        >
                         {objectData.dclocation && objectData.dclocation?(<> 
                            {objectData.dclocation.location}
                 </>):('')}
                        </a>
                        <div className="fs-7 text-muted fw-bold mt-1">
                        {objectData.dclocation && objectData.dclocation?(<> 
                            {objectData.dclocation.country}
                 </>):('')}
                        </div>
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Section*/}
                    {/*begin::Label*/}
                    {objectData.status=="active" || objectData.status=="Active"?(<><div className="btn btn-success fw-bold ms-5">Active</div></>):
                    (<><div className="btn btn-success fw-bold ms-5">Active</div></>)}
                     
                    {/*end::Label*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="separator" />
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
          <li className="nav-item">
              <a onClick={(e)=>togglePermission(false)}
                className="nav-link text-active-primary py-5 active me-6"
                data-bs-toggle="tab"
                href="#object-tab"
              >
                Object
              </a>
            </li>
            <li className="nav-item">
              <a onClick={(e)=>togglePermission(false)}
                className="nav-link text-active-primary py-5 me-6 sphide"
                data-bs-toggle="tab"
                href="#connection-details"
              >
                Access Control
              </a>
            </li>
            <li className="nav-item" >
              <a onClick={(e)=>togglePermission(true)}
                className="nav-link text-active-primary py-5 me-6 sphide"
                data-bs-toggle="tab"
                href="#permissions-tab"
              >
                Permissions
              </a>
            </li>
          
            <li className="nav-item d-none">
              <a onClick={(e)=>togglePermission(false)}
                className="nav-link text-active-primary py-5 me-6"
                data-bs-toggle="tab"
                href="#sslCertificate-tab"
              >
                SSL/TLS
              </a>
            </li>
            <li className="nav-item">
              <a onClick={(e)=>togglePermission(false)}
                className="nav-link text-active-primary py-5 me-6"
                data-bs-toggle="tab"
                href="#destroy"
              >
                Destroy
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="tab-content" id="myTabContent">
        <Access  data={objectData}  updateObject={getObjectStorage}/>
        <FileFolder data={objectData} updateObject={getObjectStorage}/>
        <Ssl data={objectData} updateObject={getObjectStorage} />
        <div className="tab-pane fade" id="permissions-tab" role="tabpanel">
        {isActivePermission?(<>  
          <Permissions data={objectData} updateObject={getObjectStorage}/>
        </>):(<> 
          {/* <Permissions data={objectData} updateObject={getObjectStorage}/> */}
        </>)}
        </div>
        <div className="tab-pane fade" id="destroy" role="tabpanel">
          <div className="card">
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_deactivate"
              aria-expanded="true"
              aria-controls="kt_account_deactivate"
            >
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Destroy Cluster</h3>
              </div>
            </div>
            <div id="kt_account_settings_deactivate" className="collapse show">
              <form id="destroyform" className="form">
                <div className="card-body border-top p-9">
                  <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                    <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={10}
                          fill="black"
                        />
                        <rect
                          x={11}
                          y={14}
                          width={7}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11 14)"
                          fill="black"
                        />
                        <rect
                          x={11}
                          y={17}
                          width={2}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11 17)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <div className="d-flex flex-stack flex-grow-1">
                      <div className="fw-bold">
                        <h4 className="text-gray-900 fw-bold">
                          This will destroy your object Storage.
                        </h4>
                        <div className="fs-6 text-gray-700">
                          Note: This option will delete your object storage from
                          the server for permanently and this not be able to
                          undo.
                        </div>
                      </div>
                    </div>
                  </div> 
                    <form id="destroyform" className="form">
            <div className="mb-10 mt-20">
              <label htmlFor="size" className="required form-label">
                Please fill text in below form to delete Object Storage "I am aware this
                action will delete data and bucket permanently"
              </label>
              <input
                type="text"
                className="form-control  "
                name="confirm"
                id="confirm"
                defaultValue=""
                placeholder="I am aware this action will delete data and bucket permanently"
              />
            </div> 
          </form> 
                </div>
                <div
                  className="card-footer d-flex justify-content-end py-6 px-9"
                  id="destroy_btn"
                >
                  <button
                  onClick={deleteObject}
                    id="destroy-btn"
                    type="button"
                    className="btn btn-danger fw-bold"
                  >
                    Destroy
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
 
    </>
  );
}else{
  return(<>
  <div class="loading-spinner2"></div>
  </>);
}
}
 
