import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../CustomHooks/usePost";
import swal from 'sweetalert';
import copy from "copy-to-clipboard";
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import { event } from "jquery";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
export default function Ssh(){
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess]=useState(true);
  const [perPage, setPerPage]=useState(5);
  const [sshData,setSshData]=useState(null);
  const dataFetchedRef=useRef (false);
  const navigate=useNavigate();
  const navRedirect=()=>{
    setTimeout(() => {
      navigate("/ssh");
     }, 3000);
      
  }
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }
  const getSSH=async()=>{
    
    const dt=toast 
       const data=await UseApi('get','key',''); 
       if(data){
        setDataFetched(true);
       }
       if('status' in data){
        if(data.status=="error"){ 
           toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });  
          setAccess(false);
          setSshData(null); 
          loaderFunc(); 
          return;
        }
     }
       delete(data.key.html);  
       if(Object.keys(data).length === 0){
        
        setSshData(null);
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            }); 
            loaderFunc();
       }else{
        setSshData(Object.values(data.key));  
        toast.dismiss();
          //    toast.update(dt,{render: "Your SSH Keys request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          //  });   
          
       }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getSSH(); 
  },[]);  

  const deleteSSH=async(id)=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this SSH Key!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const deletesshkey=document.querySelector("#deletessh-"+id);
        if(deletesshkey){
          var preContent=deletesshkey.innerHTML; 
          deletesshkey.classList.add("disabled");
          deletesshkey.innerHTML='Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
    
        const dt=toast.loading("SSH Keys deleting..."); 
        const dnsp=await UseApi('delete','key/'+id+'/delete',''); 
        if(dnsp.status=='error'){
         toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       });
       }else{
         toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       }); 
       getSSH();
       navigate("/ssh")
       }
       deletesshkey.classList.remove("disabled");
       deletesshkey.innerHTML=preContent;
      }
    }
      });
    
  }

  const openDrawer=()=>{
    // event.preventDefault();
    var drawerElement = document.querySelector("#importkey-modal");
        drawerElement.classList.toggle("drawer-on");
        drawerElement.style.width='500px';
  }
  const importSshKey=async ()=>{  
    toast.dismiss();
   const dt=toast.loading("Please wait...");
   const submitBtn=document.querySelector("#importKeyBtn");
        if(submitBtn){
          var preContent=submitBtn.innerHTML; 
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML='Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
    const responseData=await UsePost('post','key/import','importkey');
    
      //console.log(responseData);
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      var SshForm = document.querySelector("#importkey");
      SshForm.reset();
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      }); 
      getSSH();
      openDrawer();
      var SshForm = document.querySelector("#importkey");
      SshForm.reset();
      navRedirect();
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
    }
  }
  const copyToClipboard=(data,sid)=> {   
       
    var copyText = document.querySelector("#sshKeyData");
    copy(data); 
    document.querySelector("#sshKeyData"+sid).classList.remove("btn-secondary");
    document.querySelector("#sshKeyData"+sid).classList.add("btn-success"); 
    document.querySelector("#sshKeyData"+sid).innerHTML="Copied";
    setTimeout(() => {
      document.querySelector("#sshKeyData"+sid).classList.add("btn-secondary");
      document.querySelector("#sshKeyData"+sid).classList.remove("btn-success"); 
      document.querySelector("#sshKeyData"+sid).innerHTML="Copy Key";
    }, 2000);
   
  }
  function ProductInfo({ productdata }) { 
    return(
        <> 
      
        {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((value, index) => (
        <tr>
          <td class="ps-9 text-dark">{value.id}</td>
          <td data-bs-target="license" class="ps-0 text-dark">{value.name}</td>
          <td data-bs-target="license" class="ps-0">
            <button type="button" 
                    class="btn btn-secondary btn-sm" 
                    data-bs-toggle="tooltip" 
                    data-bs-html="true" 
                    title={value.value}
                    id={"sshKeyData"+value.id}
                    onClick={()=>copyToClipboard(value.value, value.id)}
                    >
                      Copy Key
            </button>
            
          </td>
          <td class="text-center text-dark">{value.created_at}</td> 
          <td class="pe-9"> 
            <button type="button" 
                    onClick={()=>deleteSSH(value.id)}
                    class="btn btn-danger btn-sm btn-active-light-danger"
                    id={"deletessh-"+value.id}
                    >
                      Delete
            </button> 
          </td> 
        </tr>
        ))):( <>
         <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
          </>)}
          
        </>
    )
}

const [searchTerm,setSearchTerm]=useState('');
function PaginatedItems({ itemsPerPage, ProductData, search }) {
  // We start with an empty list of items. 
  const [pageCount, setPageCount] = useState(0);
  const [vpcData,setVpcData]=useState(null);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    
    const endOffset = itemOffset + itemsPerPage;


  //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
     if(ProductData!=null){
    //   var str="Noida";
   // ////console.log(ProductData);
    const data= ProductData.filter(row => {
     return  Object.values(row).some(value => { 
        if(value!=null){
          if(value.toString().toLowerCase().includes(search.toLowerCase())){
            return value;
          } 
        }
      });
    });
    //////console.log(data);
      // const data=ProductData.filter(item => 
      //        item.hostname.toLowerCase().includes(search.toLowerCase())
      //              ); 

                   if(data.length<=0){
                    loaderFunc();
                   }
    setVpcData(data.slice(itemOffset, endOffset));
    //setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
    // alert(pageCount);
      }
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  
  const handlePageClick = (event) => { 
    const newOffset = event.selected * itemsPerPage % ProductData.length;
    ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
   
  };
  var searchEle=document.querySelector("#searchClient");
  if(searchEle){
    searchEle.focus();
  }
  return (
    <>
      <ProductInfo productdata={vpcData} />
      <td colspan="12">
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      </td>
    </>
  );
}
 
if(dataFetched){
if(access==false){
  return (
    <>
     <AccessDenied />
    </>
  );
}
if(sshData!=null){
  if(Object.keys(sshData).length==0){
return(
  <> 
    <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold fs-3 mb-1">
          SSH Key {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
          <span className="svg-icon svg-icon-2">
            <i className="las la-key" style={{ fontSize: 20 }} />
          </span>
          {/*end::Svg Icon*/}
        </span>
        <span className="text-muted mt-1 fw-bold fs-7">View your SSH Keys</span>
      </h3>
      <div
        className="card-toolbar" 
      >
        <div className="position-relative my-1" style={{ marginRight: 20 }}>
          <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
        <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select  
        onChange={e => setPerPage(e.target.value)}
        //data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={5}
        id="perPage"
        
      >
        <option value={5} selected="">
          5 Records
        </option>
        <option value={10}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
  </div>
</div>
        <a 
        onClick={openDrawer}
          className="btn btn-sm btn-primary btn-active-light-primary"
          id="importkey-open"
        >
          Import SSH Key
        </a>
      </div>
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    {/*begin::Table container*/}
    <div className="table-responsive">
      {/*begin::Table*/}
      <table
        id="sshTable"
        className="table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className="fw-bold">
            <th className="w-175px ps-9">#ID</th>
            <th className="w-200px px-0">Name</th>
            <th className="w-125px">SSH Key</th>
            <th className="min-w-125px text-center">Created</th>
            <th className="w-200px">Action</th>
          </tr>
        </thead>
        {/*end::Table head*/}
        {/*begin::Table body*/}
        <tbody id="sshList" className="fs-6 fw-bold text-gray-600"> 
  {sshData!=null?(<>
<PaginatedItems itemsPerPage={perPage} ProductData={sshData} search={searchTerm}  />
  </>):(<>
    <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
  </>)}
  </tbody>
        {/*end::Table body*/}
      </table>
      {/*end::Table*/}
    </div>
    {/*end::Table container*/}
    {/*begin::Body*/}
  </div>
  {/*end::Tables Widget 9*/}
</div>
<div
  id="importkey-modal"
  className="bg-body shadow drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-name="chat"
  data-kt-drawer-activate="true" 
  data-kt-drawer-direction="end" 
  data-kt-drawer-close="#close_ssh"
  data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}" 
  style={{maxWidth: '70%',important:true}}
  
>
  {/*begin::Messenger*/}
  <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
    {/*begin::Card header*/}
    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div className="d-flex justify-content-center flex-column me-3">
          <a
            
            className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
          >
            Add New SSH Key
          </a>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Close*/}
        <div
        onClick={openDrawer}
          className="btn btn-sm btn-icon btn-active-light-primary"
          id="close_ssh"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {/*end::Card header*/}
    {/*begin::Card body*/}
    <div className="" id="">
      {/*begin::Form*/}
      <form name="importkey" id="importkey" className="form">
        {/*begin::Modal body*/}
        <div className="modal-body ">
          {/*begin::Scroll*/}
          <div
            className="scroll-y me-n7 pe-7"
            id="kt_modal_create_api_key_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
            data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
            data-kt-scroll-offset="300px"
          >
            {/*begin::Input group*/}
            <div className="mb-5 fv-row">
              {/*begin::Label*/}
              <label className="required fs-5 fw-bold mb-2"> Name</label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <input
                type="text"
                className="form-control  "
                placeholder="Name"
                name="name"
              />
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <div className="d-flex flex-column mb-5 fv-row">
              {/*begin::Label*/}
              <label className="required fs-5 fw-bold mb-2">
                SSH Key Content
              </label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <textarea
                className="form-control  "
                type="text"
                rows={3}
                name="sshkey"
                placeholder="SSH"
                defaultValue={""}
              />
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Modal body*/}
        {/*begin::Modal footer*/}
        <div className="modal-footer ">
          {/*begin::Button*/}
          <button
            type="button"
            onClick={importSshKey}
            className="btn btn-primary w-100"
            id="importKeyBtn"
          >
            <span className="indicator-label">Import SSH Key</span>
          </button>
          {/*end::Button*/}
        </div>
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
    </div>
    {/*end::Card body*/}
  </div>
  {/*end::Messenger*/}
</div> 
  </>
)
} else{
  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          {/*begin::Card body*/}
          <div className="card-body p-0 mt-10">
            {/*begin::Illustration*/}
            <div className="text-center px-4 mt-10">
              <span className="mw-100 mh-300px">
              <svg  
              width="204px"
              height="234px" 
              fill="#21325B"
              viewBox="0 0 36 36"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#21325B"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
              <g id="SVGRepo_iconCarrier">
                {" "}
                <title>alt-key</title>{" "}
                <path d="M0 10.016q0 2.016 0.8 3.872t2.144 3.2 3.168 2.112 3.904 0.8q2.048 0 4-0.832v2.848h4v1.984h4v4h4v4h5.984v-8l-9.984-8v-1.984h-2.848q0.832-1.952 0.832-4t-0.8-3.872-2.112-3.2-3.2-2.144-3.872-0.8q-2.72 0-5.024 1.344t-3.648 3.648-1.344 5.024zM4 8q0-1.632 1.184-2.816t2.816-1.184 2.816 1.184 1.184 2.816-1.184 2.848-2.816 1.152-2.816-1.152-1.184-2.848z" />{" "}
              </g>
            </svg>
            

              </span>
            </div>
            {/*end::Illustration*/}
            {/*begin::Wrapper*/}
            <div className="card-px text-center py-5 mb-20">
              {/*begin::Title*/}
              <h1 className="fs-2x fw-bolder mb-5">SSH Keys</h1>
              <h2 className="fs-1x fw-bolder mb-5 text-muted">
              Secure your server connections
              </h2>
              {/*end::Title*/}
              {/*begin::Description*/}
              <p className="text-gray-400 fs-4 fw-bold mb-10">
              SSH Keys for secure, password-less logins to your servers. Secure your cloud.              </p>
              {/*end::Description*/}
              {/*begin::Action*/} 
              <a
              onClick={openDrawer}
                href="#"
                className="btn btn-primary mb-10"
              >
                Create New SSH Key
              </a> 
              {/*end::Action*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Card body*/}
        </div>
      </div>
      <div
  id="importkey-modal"
  className="bg-body shadow drawer drawer-end"
  // data-kt-drawer="true"
  // data-kt-drawer-name="chat"
  // data-kt-drawer-activate="true" 
  // data-kt-drawer-direction="end" 
  data-kt-drawer-close="#close_ssh"
  data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}" 
  style={{maxWidth: '70%',important:true}}
  
>
  {/*begin::Messenger*/}
  <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
    {/*begin::Card header*/}
    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div className="d-flex justify-content-center flex-column me-3">
          <a
            
            className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
          >
            Add New SSH Key
          </a>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Close*/}
        <div
        onClick={openDrawer}
          className="btn btn-sm btn-icon btn-active-light-primary"
          id="close_ssh"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {/*end::Card header*/}
    {/*begin::Card body*/}
    <div className="" id="">
      {/*begin::Form*/}
      <form name="importkey" id="importkey" className="form">
        {/*begin::Modal body*/}
        <div className="modal-body ">
          {/*begin::Scroll*/}
          <div
            className="scroll-y me-n7 pe-7"
            id="kt_modal_create_api_key_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
            data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
            data-kt-scroll-offset="300px"
          >
            {/*begin::Input group*/}
            <div className="mb-5 fv-row">
              {/*begin::Label*/}
              <label className="required fs-5 fw-bold mb-2"> Name</label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <input
                type="text"
                className="form-control  "
                placeholder="Name"
                name="name"
              />
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <div className="d-flex flex-column mb-5 fv-row">
              {/*begin::Label*/}
              <label className="required fs-5 fw-bold mb-2">
                SSH Key Content
              </label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <textarea
                className="form-control  "
                type="text"
                rows={3}
                name="sshkey"
                placeholder="SSH"
                defaultValue={""}
              />
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Modal body*/}
        {/*begin::Modal footer*/}
        <div className="modal-footer ">
          {/*begin::Button*/}
          <button
            type="button"
            onClick={importSshKey}
            className="btn btn-primary w-100"
            id="importKeyBtn"
          >
            <span className="indicator-label">Import SSH Key</span>
          </button>
          {/*end::Button*/}
        </div>
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
    </div>
    {/*end::Card body*/}
  </div>
  {/*end::Messenger*/}
</div> 
    </>
  );
}
}
}else{
return (
<>
{/* <div class="loading-spinner2"></div> */}
<TopBarHeader/>
</>
);
}
}