import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom"; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";  
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate"; 
import copy from "copy-to-clipboard";
import UsePost from "../../../../../../CustomHooks/usePost";
import UseApi from "../../../../../../CustomHooks/useApi";
const DatabaseUser = (props) => { 
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4]; 
    const openDrawer=()=>{
        var drawerElement = document.querySelector("#databaseUser-modal");
            drawerElement.classList.toggle("drawer-on");
            drawerElement.style.width='500px';
      }
      const loaderFunc=()=>{
        var loader = document.querySelector("#loader");
        var no_record = document.querySelector("#no_record");
        if(loader && no_record){
        no_record.classList.add("d-none");
        loader.classList.remove("d-none");
    
        setTimeout(() => {
          loader.classList.add("d-none");
          no_record.classList.remove("d-none");
        }, 100);
      }
      }
      

      const togglePassword=(action, pid)=>{
        // console.log(action,pid);
        var hide_passwd=document.querySelector("#hide_passwd_"+pid);
        var show_passwd=document.querySelector("#show_passwd_"+pid);
        // console.log(hide_passwd,show_passwd);
        if(hide_passwd && show_passwd){
          // console.log("sjc");
           if(action=="hide"){
            hide_passwd.classList.remove("d-none");
            show_passwd.classList.add("d-none");
           }else{
            hide_passwd.classList.add("d-none");
            show_passwd.classList.remove("d-none");
           }
        }
      }
    
      const copyToClipboard=(target,targetBy)=> {   
           
        var btn_id = document.querySelector("#"+targetBy);
        var target_id = document.querySelector("#"+target);
        if(btn_id){
          var copyText=btn_id.innerHTML;
          var copyEle=target_id.innerHTML;
        copy(copyEle);  
        btn_id.innerHTML="Copied"; 
        btn_id.classList.add("text-success");
        setTimeout(() => { 
          btn_id.innerHTML=copyText;
          btn_id.classList.remove("text-success");
        }, 2000);
      }
       
      }
const [perPage, setPerPage]=useState(5); 
const [databaseUserData, setDatabaseUserData]=useState(null);
      const getDatabaseUsers=async()=>{
        if(props){
            if(props.data){
                 if(props.data.users.length==0){
                    setDatabaseUserData([]);
                 }else{
                    setDatabaseUserData(props.data.users);
                 }
            }   
        }
      }

      const createDatabaseUser=async()=>{ 
        var submitBtn=document.querySelector("#create-databaseUser-btn"); 
       
        if(submitBtn){
          var preContent=submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
        const dt=toast.loading("Please wait...");
        
        const responseData=await UsePost('post','databases/'+id+'/user','databaseUser-form');
        if(responseData){
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML=preContent;
          if(responseData.status==='error'){
            toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          }else if(responseData.status==='success'){
            toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });  
          if(props){
            openDrawer();
            props.updateDatabase();
          }
           
        }
      }
          }
        }
        // const deleteDatabase=async(dbid)=>{
        //     var submitBtn=document.querySelector("#delete-database"+dbid+"-btn"); 
       
        //     if(submitBtn){
               
        //     toast.dismiss();
        //     swal({
        //       title:"Are you sure?",
        //       text: "Once deleted, you will not be able to recover this database!",
        //       icon: "warning",
        //       buttons: true,
        //       dangerMode: true,
        //       })
        //       .then(async(willDelete) => {
        //       if(willDelete) {
        //         var preContent=submitBtn.innerHTML;
        //         submitBtn.classList.add("disabled");
        //         submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';   
        //         const dt=toast.loading("Database deleting..."); 
        //     const dnsp=await UseApi('DELETE','databases/'+id+'/user/'+dbid,'');
        //     submitBtn.classList.remove("disabled");
        //     submitBtn.innerHTML=preContent;
        //     //console.log(dnsp);
        //     if(dnsp.status=='error'){
        //       toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        //     });
        //     }else if(dnsp.status=='success'){
        //       toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        //     }); 
        //     if(props){
        //         openDrawer();
        //         props.updateDatabase();
        //       }
        //     }
        //       }
        //       });
        //     }
        //   }
      useEffect(()=>{
        getDatabaseUsers();
      },[props])

      const deleteDatabaseUser=async(dbid)=>{
        var submitBtn=document.querySelector("#delete-databaseuser"+dbid+"-btn"); 
   
        if(submitBtn){
           
        toast.dismiss();
        const { value: isDelete } = await Swal.fire({
            title: 'Delete User',
            text: 'Enter the Username to delete:',
            input: 'text',
            inputPlaceholder: 'Database User',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'delete-button bg-danger', // Add your custom class here
              },
          }); 
         if(isDelete==dbid){  
            var preContent=submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';   
            const dt=toast.loading("Database user deleting..."); 
        const dnsp=await UseApi('DELETE','databases/'+id+'/user/'+dbid+'?confirm='+dbid,'');
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML=preContent;
        //console.log(dnsp);
        if(dnsp.status=='error'){
          toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        }else if(dnsp.status=='success'){
          toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        }); 
        if(props){ 
            props.updateDatabase();
          }
        } 
          }else{
            swal({
                  title: "Invalid Database User",
                  text: "Please confirm the valid database user!",
                  icon: "warning",
                  button: "OK",
                });
          }
        }
      }
      function ProductInfo({ productdata }) { 
        console.log(productdata,"kui");
        return(
            <> 
     
            {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((value, index) => (
          <tr>
            <td></td>
          <td className="ps-9">{value.db_user}</td>
          <td>
              
              <span id={"hide_passwd_"+value.db_user} className="">
                ******
              <span style={{cursor:"pointer"}} className="text-primary ms-5" onClick={(e)=>togglePassword("show", value.db_user)}>show</span>
              </span>
              <span id={"show_passwd_"+value.db_user} className="d-none">
                <span id={"passwd_id_"+value.db_user}>{value.db_password}</span>
                <span style={{cursor:"pointer"}} className="text-primary ms-5" onClick={(e)=>togglePassword("hide", value.db_user)}>hide</span>
                <span id={"passwd_copy_"+value.db_user} onClick={(e)=>copyToClipboard('passwd_id_'+value.db_user,'passwd_copy_'+value.db_user)} className="svg-icon svg-icon-2 ms-5">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="#eff2f5"
  >
    <path
      opacity="0.5"
      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
      fill="#eff2f5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
      fill="#eff2f5"
    />
  </svg>
                </span>
              </span>
            </td>
            <td className="text-center">
         <span className="fs-7 fw-bold">
           {value.created_at}
          </span>
          </td> 
          <td className="text-center" >
          <a
             onClick={(e)=>deleteDatabaseUser(value.db_user)}
              id={"delete-databaseuser"+value.db_user+"-btn"}
              className="btn btn-danger sphide"
            >
              Delete
            </a>
            </td>
        </tr>
               ))):( <>
                <tr>
                         <td colspan="12" className="dataTables_empty" id="loader">
                          <div className="loading-spinner"></div>
                        </td>
                        <td colspan="12" className="d-none" id="no_record" valign="top">
                          <div className="d-flex flex-column flex-center"> 
                            <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                          </div>
                        </td>
                        </tr>
                </>)}
                
            </>
        )
    }
    
    
    const [searchTerm,setSearchTerm]=useState('');
    function PaginatedItems({ itemsPerPage, ProductData, search }) {
      // We start with an empty list of items. 
      const [pageCount, setPageCount] = useState(0);
      const [vpcData,setVpcData]=useState(null);
      // Here we use item offsets; we could also use page offsets
      // following the API or data you're working with.
      const [itemOffset, setItemOffset] = useState(0);
    
      useEffect(() => {
        // Fetch items from another resources.
        
        const endOffset = itemOffset + itemsPerPage;
    
    
      //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
         if(ProductData!=null){
        //   var str="Noida";
       // ////console.log(ProductData);
        const data= ProductData.filter(row => {
         return  Object.values(row).some(value => { 
            if(value!=null){
              if(value.toString().toLowerCase().includes(search.toLowerCase())){
                return value;
              } 
            }
          });
        });
        //////console.log(data);
          // const data=ProductData.filter(item => 
          //        item.hostname.toLowerCase().includes(search.toLowerCase())
          //              ); 
    
                       if(data.length<=0){
                        loaderFunc();
                       }
        setVpcData(data.slice(itemOffset, endOffset));
        //setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
        // alert(pageCount);
          }
      }, [itemOffset, itemsPerPage]);
    
      // Invoke when user click to request another page.
      
      const handlePageClick = (event) => { 
        const newOffset = event.selected * itemsPerPage % ProductData.length;
        ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
       
      };
      var searchEle=document.querySelector("#searchClient");
      if(searchEle){
        searchEle.focus();
      }
      return (
        <>
          <ProductInfo productdata={vpcData} />
          <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
          </td>
        </>
      );
    } 
    return (
        <>
            <div className="card">
  <div className="card-header">
    <div className="card-title">
      <h3>Databases </h3>
    </div>
    <div className="card-toolbar">
    <div
                      className="position-relative my-1"
                      style={{ marginRight: 20 }}
                    >
                       <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>

                  <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select  
        onChange={e => setPerPage(e.target.value)}
        //data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={5}
        id="perPage"
        
      >
        <option value={5} selected="">
          5 Records
        </option>
        <option value={10}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
                      </div>
                    </div>
      <a  onClick={openDrawer} className="btn btn-sm btn-primary my-1 sphide">
        + Add Database
      </a>
    </div>
  </div>
  <div className="card-body p-0">
    <div className="table-responsive">
      <table
        className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
        id="kt_security_license_usage_table"
      >
        <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
          <tr>  
            <th className="w-50px ps-9"></th>
            <th className="w-150px ps-9">User</th> 
            <th className="w-150px ps-9">Password</th> 
            <th className="text-center pe-5 w-150px">CreatedAt</th> 
            <th className="w-150px ps-9" />
          </tr>
        </thead>
        <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
            {databaseUserData!=null?(<>
            {databaseUserData.length==0?(<>
              <tr> 
                        <td colspan="12" valign="top">
                          <div className="d-flex flex-column flex-center"> 
                            <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                          </div>
                        </td>
                        </tr>
            </>):(<>
        <PaginatedItems itemsPerPage={perPage} ProductData={databaseUserData} search={searchTerm}  />
        </>)}
        </>):('')}
        </tbody>
      </table>
    </div>
  </div>
  <div
  id="databaseUser-modal"
  className="bg-body shadow drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-name="chat"
  data-kt-drawer-activate="true" 
  data-kt-drawer-direction="end" 
  data-kt-drawer-close="#close_ssh"
  data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}" 
  style={{maxWidth: '70%',important:true}}
  
>
  {/*begin::Messenger*/}
  <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
    {/*begin::Card header*/}
    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div className="d-flex justify-content-center flex-column me-3">
          <a
            
            className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
          >
            Add New Database User
          </a>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Close*/}
        <div
        onClick={openDrawer}
          className="btn btn-sm btn-icon btn-active-light-primary"
          id="close_ssh"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {/*end::Card header*/}
    {/*begin::Card body*/}
    <div className="" id="">
      {/*begin::Form*/}
      <form id="databaseUser-form" className="form">
        {/*begin::Modal body*/}
        <div className="modal-body ">
          {/*begin::Scroll*/}
          <div
            className="scroll-y me-n7 pe-7"
            id="kt_modal_create_api_key_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
            data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
            data-kt-scroll-offset="300px"
          >
            {/*begin::Input group*/}
            <div className="mb-5 fv-row">
              {/*begin::Label*/}
              <label className="required fs-5 fw-bold mb-2"> Database User</label>
              {/*end::Label*/}
              {/*begin::Input*/} 
                <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Enter Database Username" 
              /> 
              {/*end::Input*/}
            </div>
             {/*end::Input group*/}
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Modal body*/}
        {/*begin::Modal footer*/}
        <div className="modal-footer ">
          {/*begin::Button*/}
          <a
             onClick={createDatabaseUser}
            className="btn btn-primary w-100"
            id="create-databaseUser-btn"
          >
            <span className="indicator-label"> + Add Database User</span>
          </a>
          {/*end::Button*/}
        </div>
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
    </div>
    {/*end::Card body*/}
  </div>
  {/*end::Messenger*/}
</div>
</div>
        </>
    );
}


export default DatabaseUser 
