import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Error404 from "../../Auth/Error404";
import Activity from "./Products/activity/Activity";
import CurrentUsage from "./Products/activity/CurrentUsage";
import Api from "./Products/api/Api";
import Backups from "./Products/backups/Backups";
import Billing from "./Products/billing/Billing";
import Cloud from "./Products/cloud/Cloud";
import CloudDeploy from "./Products/cloud/CloudDeploy";
import ManageCloud from "./Products/cloud/ManageCloud";
import Dashboard from "./Products/Dashboard";
import Dns from "./Products/dns/Dns";
import DnsDeploy from "./Products/dns/DnsDeploy";
import ManageDns from "./Products/dns/ManageDns";
import Firewall from "./Products/firewall/Firewall";
import FirewallDeploy from "./Products/firewall/FirewallDeploy";
import ManageFirewall from "./Products/firewall/ManageFirewall";
import Iso from "./Products/iso/Iso";
import Kubernetes from "./Products/kubernetes/Kubernetes";
import KubernetesDeploy from "./Products/kubernetes/KubernetesDeploy";
import ManageKubernetes from "./Products/kubernetes/ManageKubernetes";
import Kyc from "./Products/kyc/Kyc";
import KycSent from "./Products/kyc/KycSent";
import LoadBalancer from "./Products/loadbalancer/LoadBalanacer";
import LoadbalancerDeploy from "./Products/loadbalancer/LoadbalancerDeploy";
import ManageLoadBalancer from "./Products/loadbalancer/ManageLoadBalancer";
import Account from "./Products/setting/Account";
import Snapshot from "./Products/snapshots/Snapshot";
import Ssh from "./Products/ssh/Ssh";
import AccountManage from "./Products/subuser/AccountManage";
import ActivateUser from "./Products/subuser/ActivateUser";
import ManageAccount from "./Products/subuser/ManageAccount";
import Tally from "./Products/tally/Tally";
import ManageTicket from "./Products/ticket/ManageTicket";
import Ticket from "./Products/ticket/Ticket";
import ManageVpc from "./Products/vpc/ManageVpc";
import Vpc from "./Products/vpc/Vpc";
import VpcDeploy from "./Products/vpc/VpcDeploy";
import ManageVpn from "./Products/vpn/ManageVpn";
import Vpn from "./Products/vpn/Vpn";
import VpnDeploy from "./Products/vpn/VpnDeploy";  
import Monitoring from "./Products/monitoring/Monitoring";
import localStorage from "local-storage";  
import ObjectStorage from "./Products/objectStorage/ObjectStorage";
import ManageObjectStorage from "./Products/objectStorage/ManageObjectStorage";
import DeployDatabaseCluster from "./Products/databases/DeployDatabaseCluster";
import DeployObjectStorage from "./Products/objectStorage/DeployObjectStorage";
import BlockStorage from "./Products/blockStorage/BlockStorage";
import ManageBlockStorage from "./Products/blockStorage/ManageBlockStorage";
import DeployBlockStorage from "./Products/blockStorage/DeployBlockStorage";
import DeployArchiveStorage from "./Products/archiveStorage/DeployArchiveStorage";
import ManageArchiveStorage from "./Products/archiveStorage/ManageArchiveStorage";
import ArchiveStorage from "./Products/archiveStorage/ArchiveStorage";
import DatabaseCluster from "./Products/databases/DatabaseCluster";
import ManageDatabaseCluster from "./Products/databases/ManageDatabaseCluster";
import Addcard from "./Products/card/Addcard";
import StackScripts from "./Products/stackScript/StackScripts";
import ManageStackScripts from "./Products/stackScript/ManageStackScripts";
import DeployStackScript from "./Products/stackScript/DeployStackScript";
import JoinUs from "./Products/JoinUs";
import Verification from "./Products/verification/Verification";
import Ssl from "./Products/ssl/Ssl";
import MobileUpdate from "./Products/setting/MobileUpdate";
import Welcome from "./Products/verification/Welcome";
import NotFound from "./Products/NotFound";
import ManageMonitoring from "./Products/monitoring/ManageMonitoring";
import PricingContainer from "./Products/pricing/PricingContainer";
import GetCloud from "./Products/helpers/GetCloud";
import SelectComp from "./Products/helper/SelectComp";
import ActionList from "./Products/cloud/helper/ActionList"; 
import Process from "../../../process/Process";
 
export default function Body(){
  
  const location = useLocation(); 
  useEffect(() => {  
    var query = window.location.href; 
    var vars = query.split("/");  
    const app_info=localStorage.get("appInfo"); 
    const user_info = localStorage.get("user_info");
      if(app_info){
     //Setting title
      if(vars.length==4){
        var id=vars.at(3); 
        document.title = app_info.brand+" | "+id;
      }else if(vars.length==5){
        var id=vars.at(3); 
        document.title = app_info.brand+" | Manage "+id;
      }
      //setting description

      const descriptionTag = document.querySelector('meta[name="description"]');
      if (descriptionTag) {
        descriptionTag.setAttribute('content', app_info.disc);
      }
    } 
    //setting keywords
    const keywordsTag = document.querySelector('meta[name="keywords"]');
    if (keywordsTag) {
      keywordsTag.setAttribute('content', app_info.keywords);
    }

    //console.log('URL changed:', location.pathname);
  }, [location.pathname]);
 
const user_info = localStorage.get("user_info");
if(user_info){
  // if(user_info.sms_verified==0 || (user_info.c_added=='no' && user_info.rvn==null)){
    if(user_info.sms_verified==0){ 
    return(
      <> 
{/*begin::Content*/}
<div className="d-flex flex-column flex-column-fluid" id="kt_content">
  {/* Body content Start */}
 
{/* <div className="container-xxl"> */}
<div className="p-3">
 <Routes>
 <Route path="/activateuser/:id" element={<ActivateUser />}   />
 <Route path="/kyc/complete" element={<Welcome />}   /> 
 <Route path="/account" element={<Account />}   />
 <Route path="/ticket/" element={<Ticket />}   />
 <Route path="/ticket/:id" element={<ManageTicket />}   /> 
 <Route path="*" element={<Verification />}   /> 
 </Routes> 
 </div>
 </div>
 </>
 )
  }else{
    return(
        <>
  {/*begin::Content*/}
  <div className="d-flex flex-column flex-column-fluid" id="kt_content">
    {/* Body content Start */}
    {/*<div id="overlay" style="display:none;position: absolute;" >
        <div class="dot-floating">
          <span class="sr-only overlay_text">Loading...</span>
        </div>
       </div>
    */}  
{/* <div className="container-xxl"> */}
<div className="p-3">
   <Routes> 
   <Route path="/" element={<Dashboard />}   />
   <Route path="/dashboard" element={<Dashboard />}   />
   <Route path="/cloud" element={<Cloud />}   />
   <Route path="/user/activate/:id" element={<ActivateUser />}   />
   <Route path="/cloud/:id" element={<ManageCloud />}   />
   <Route path="/cloud/:id/:id" element={<ManageCloud />}   />
   <Route path="/kubernetes" element={<Kubernetes />}   />
   <Route path="/kubernetes/:id" element={<ManageKubernetes />}   />
   <Route path="/loadbalancer" element={<LoadBalancer />}   />
   <Route path="/loadbalancer/:id" element={<ManageLoadBalancer />}   />
   <Route path="/processing/:id" element={<Process />}   />
   <Route path="/vpn" element={<Vpn />}   />
   <Route path="/vpn/:id" element={<ManageVpn />}   />
   <Route path="/vpc" element={<Vpc />}   />
   <Route path="/vpc/:id" element={<ManageVpc />}   />
   <Route path="/firewall" element={<Firewall />}   />
   <Route path="/firewall/:id" element={<ManageFirewall />}   />
   <Route path="/dns" element={<Dns />}   />
   <Route path="/dns/:id" element={<ManageDns />}   />
   <Route path="/backups" element={<Backups />}   />
   <Route path="/snapshots" element={<Snapshot />}   />
   <Route path="/iso" element={<Iso />}   />
   <Route path="/tally" element={<Tally />}   />
   <Route path="/accountManagement" element={<AccountManage />}   />
   <Route path="/accountManagement/:id" element={<ManageAccount />}   />
   <Route path="/account/updateMobile" element={<MobileUpdate />}   />
   <Route path="/account/kyc" element={<Kyc />}   />
   <Route path="/account/kyc/sent" element={<KycSent />}   />
   <Route path="/account" element={<Account />}   />
   <Route path="/billing" element={<Billing />}   />
   <Route path="/ssh" element={<Ssh />}   />
   <Route path="/api" element={<Api />}   />
   <Route path="/activity" element={<Activity />}   />
   <Route path="/ticket/" element={<Ticket />}   />
   <Route path="/ticket/:id" element={<ManageTicket />}   /> 
   <Route path="/currentUsage" element={<CurrentUsage />}   />  
   
   {/* <Route path="/testing" element={<GetCloud />}   />   */}

   {/* Object Storage Routing start */}
   <Route path="/objectStorage" element={<ObjectStorage />}   /> 
   <Route path="/objectStorage/:zone/:id" element={<ManageObjectStorage />}   /> 
   <Route path="/objectStorage/deploy" element={<DeployObjectStorage />}   /> 
   {/* Object Storage Routing end */}

    {/* Block Storage Routing start */}
    {/* <Route path="/blockStorage" element={<BlockStorage />}   />  */}
   {/* <Route path="/blockStorage/:id" element={<ManageBlockStorage />}   />  */}
   {/* <Route path="/blockStorage/deploy" element={<DeployBlockStorage />}   />  */}
   {/* Block Storage Routing end */}

    {/* Archive Storage Routing start */}
    {/* <Route path="/archiveStorage" element={<ArchiveStorage />}   /> 
   <Route path="/archiveStorage/:id" element={<ManageArchiveStorage />}   /> 
   <Route path="/archiveStorage/deploy" element={<DeployArchiveStorage />}   />  */}
   {/* Archive Storage Routing end */}

   {/* Archive Storage Routing start */}
   <Route path="/database-cluster" element={<DatabaseCluster />}   /> 
   <Route path="/database-cluster/:id" element={<ManageDatabaseCluster />}   /> 
   <Route path="/database-cluster/deploy" element={<DeployDatabaseCluster />}   /> 
   {/* Archive Storage Routing end */}
 
   {/* Stackscripts Routing start */}
   {/* <Route path="/stacks" element={<StackScripts />}   /> 
   <Route path="/stacks/deploy" element={<DeployStackScript />}   />  
   <Route path="/stacks/:id" element={<ManageStackScripts />}   />  */}
   {/* Stackscripts Routing end */}

   {/* Monitoring Routing start */}
    {/* <Route path="/ssl" element={<Ssl />}   /> */}
   {/* Monitoring Routing end */}

   {/* Monitoring Routing start */}
     {/* <Route path="/monitoring" element={<Monitoring />}   />
     <Route path="/monitoring/:id" element={<ManageMonitoring />}   /> */}
   {/* Monitoring Routing end */}

   {/* Deploy Pages start*/}
   <Route path="/select" element={<ActionList />}   />  

   <Route path="/cloud/deploy" element={<CloudDeploy />}   />  
   <Route path="/kubernetes/deploy" element={<KubernetesDeploy />}   />
   <Route path="/loadbalancer/deploy" element={<LoadbalancerDeploy />}   />
   <Route path="/vpn/deploy" element={<VpnDeploy />}   />
   <Route path="/vpc/deploy" element={<VpcDeploy />}   />
   <Route path="/firewall/deploy" element={<FirewallDeploy />}   />
   <Route path="/dns/deploy" element={<DnsDeploy />}   />
   

   {/* Deploy Pages end*/}
   <Route path="/activateuser/:id" element={<ActivateUser />}   />
   <Route path="/stacks" element={<JoinUs />}   />
   <Route path="/ssl" element={<JoinUs />}   />
   <Route path="/gpu" element={<JoinUs />}   />
   {/* <Route path="/monitoring" element={<JoinUs />}   /> */}
   {/* <Route path="/objectStorage" element={<JoinUs />}   /> 
   <Route path="/blockStorage" element={<JoinUs />}   /> 
   <Route path="/archiveStorage" element={<JoinUs />}   />   
   <Route path="/database-cluster" element={<JoinUs />}   /> */}
   <Route path="*" element={<NotFound />}   />


  
   </Routes>


        {/*<div className="d-flex justify-content-center">
          <div class="spinner-border" role="status">
<span class="sr-only">Loading...</span>
    </div>
        </div>*/}  
        </div>
    {/* Body Content end */}
  </div>
  {/*end::Content*/}
 
        </>

    )
  }
      }
}
