import React, { useEffect, useState } from 'react';
import UseLogin from '../CustomHooks/useLogin';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';

const ApplyCoupon = () => {
    
    // let {coupon} = useParams() 
   const [couponMessage, setCouponMessage]=useState(null);
    const getCoupon=async(coupon_name)=>{ 
        const res=await axios.get("https://api.utho.com/v2/coupon/"+coupon_name);
        // console.log(res);
        if(res && res.data.status=="success"){ 
            setCouponMessage(res.data.message);
         }
    } 
    useEffect(()=>{ 
        let couponNmae  = Cookies.get('coupon')
        if(couponNmae){
        getCoupon(couponNmae);
        } 
        
    },[])
    return (
        <>
        {couponMessage!=null?(<>
        <div className="alert alert-success text-center fs-2" style={{marginBottom:"-3px"}}>{couponMessage}</div>
        </>):("")}
        </>
    );
}

export default ApplyCoupon;
