import localStorage from "local-storage";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";   
import UseApi from "../../../CustomHooks/useApi";
import SwitchAccount from "./SwitchAccount"; 
import Cookies from "js-cookie";
import "./custom.css";
export default function Toolbar(){
  const navigate=useNavigate();

  function renderComponent(type){

    navigate("/processing/"+type)
    // console.log(type); 
  }
  var current_url_string = window.location.href;
      const current_url = new URL(current_url_string);
      const current_domain = current_url.hostname;
  const dataFetchedRef=useRef (false); 
  const logout=()=>{
    localStorage.clear(); 
    // document.cookie = "microhost_api_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //localStorage.remove("Auth");
    
    const cookieNames = Object.keys(Cookies.get());
    const domain = '.'+current_domain; // Specify the domain

// Remove each cookie with the specified domain
cookieNames.forEach((cookieName) => {
  Cookies.remove(cookieName, { domain, path: '/' }); // Make sure to include the path
});

cookieNames.forEach((cookieName) => {
  Cookies.remove(cookieName, { current_domain, path: '/' }); // Make sure to include the path
});
    const location_url = window.location.origin;
    window.location.href = location_url; 
  } 
 
  const [userData,setUserData]=useState(null); 
  const getUserInfo=async()=>{
    const themeInfo=document.querySelector("#themeSelect");
    //console.log(localStorage.get("darkmode"));
    if(localStorage.get("darkmode")=="yes"){
    if(themeInfo){
      themeInfo.checked=true; 
    }
  }
      //  const data=await UseApi('get','account/info',''); 
      const data=localStorage.get("user_info");
        if(data){
        setUserData(data);  
        }
        ////console.log(data.user);
  }
  const [logo_dark, setLogo_dark]=useState(null);
  const getAppInfo=async ()=>{
    let toolbarData = localStorage.get('app_info')
    if(toolbarData == null){
    const app_info=await UseApi('get','/appinfo','');
    if(app_info && app_info){
      var siteUrlString=app_info.site;
      
      var siteUrlArr=siteUrlString.split(".");
      var siteLength=siteUrlArr.length;
      
      var siteOrg="https://api."+siteUrlArr[siteLength-2]+"."+siteUrlArr[siteLength-1];  
      var DarkLogo=siteOrg+"/publicfiles/"+app_info.logo;
      setLogo_dark(DarkLogo);
      window.localStorage.setItem('app_info', JSON.stringify(app_info))
      }
    }else{
      var siteUrlString=toolbarData.site;
      
      var siteUrlArr=siteUrlString.split(".");
      var siteLength=siteUrlArr.length;
      
      var siteOrg="https://api."+siteUrlArr[siteLength-2]+"."+siteUrlArr[siteLength-1];  
      var DarkLogo=siteOrg+"/publicfiles/"+toolbarData.logo;
      setLogo_dark(DarkLogo);
    }
  }

  useEffect(()=>{ 
    // if (dataFetchedRef.current) return;
    // dataFetchedRef.current = true;
      getUserInfo();
      // setTimeout(() =>{

        getAppInfo();
      // },100) 
  },[]);  

  const setTheme=(event)=>{
    
    if (event.target.checked) {
      localStorage.set("darkmode",'yes');
      window.location.reload(); 
    } else {
      localStorage.set("darkmode",'no');
      window.location.reload();
        }
  }

  const handleMenu=(type)=>{
    renderComponent(type);
    const parentMenuDiv=document.querySelector("#parentMenuDiv");
    const childMenuDiv=document.querySelector("#childMenuDiv");
    if(parentMenuDiv && childMenuDiv){
      parentMenuDiv.classList.remove('show');
      childMenuDiv.classList.remove('show');
    }
  }
    return(
      <> 
        {/* {userData!=null?(<>  */}
      {/*begin::Header*/}
      <div id="kt_header" style={{}} className="header align-items-stretch">
    
        {/*begin::Container*/}
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          {/*begin::Aside mobile toggle*/}



          {/* <div
            className="d-none d-flex align-items-center d-lg-none ms-n2 me-2"
            title="Show aside menu"
          >
            <div
              className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_aside_mobile_toggle"
            > 
              <span className="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                    fill="black"
                  />
                  <path
                    opacity="0.3"
                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                    fill="black"
                  />
                </svg>
              </span> 
            </div>
          </div> */}
          {/*end::Aside mobile toggle*/}
          {/*begin::Mobile logo*/}
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <a  className="d-lg-none">
            {logo_dark!=null?(<>
              <img
              alt="Logo"
              src={logo_dark}
              className="h-30px logo"
            />
            </>):('')}
               
            </a>
          </div>
          {/*end::Mobile logo*/}
          {/*begin::Wrapper*/}
          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
            {/*begin::Navbar*/}
            <div className="d-flex align-items-stretch" id="kt_header_nav">
              {/*begin::Menu wrapper*/}
              <div
                className="header-menu align-items-stretch"
                data-kt-drawer="true"
                data-kt-drawer-name="header-menu"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                data-kt-drawer-direction="end"
                data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                data-kt-swapper="true"
                data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
              >
                {/*begin::Menu*/}
                
                <div
                  className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch slide-animation"
                  id="#kt_header_menu"
                  data-kt-menu="true"
                >
                  <div
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-start"
                    className="menu-item here show  menu-lg-down-accordion me-lg-1"
                    id="parentMenuDiv"
                  >
                    <span className="menu-link py-3 btn btn-sm ">
                      <span className="text-primary sphide">Deploy New</span>
                      <span className="menu-arrow " />
                    </span>
                    <div id="childMenuDiv" className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                      <div className="menu-item">
                        {/* <Link to="cloud/deploy"> */}
                        <a onClick={(e)=>handleMenu("cloud")}
                          className="menu-link active py-3"
                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <i
                                className="las la-cloud"
                                style={{ fontSize: 20 }}
                              />
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">Cloud Instances</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item">
                      {/* <Link to="kubernetes/deploy"> */}
                        <a onClick={(e)=>handleMenu("kubernetes")}
                          className="menu-link py-3"
                           
                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <i
                                className="las la-binoculars"
                                style={{ fontSize: 20 }}
                              />
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">Kubernetes</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item">
                      {/* <Link to="vpc/deploy"> */}
                        <a onClick={(e)=>handleMenu("vpc")}
                          className="menu-link py-3"
                          
                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <i
                                className="fonticon-share"
                                style={{ fontSize: 20 }}
                              />
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">VPC</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item">
                      {/* <Link to="vpn/deploy"> */}
                        <a onClick={(e)=>handleMenu("vpn")}
                          className="menu-link py-3"
                           
                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <i className="las la-lock" style={{ fontSize: 20 }} />
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">VPN</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item">
                      {/* <Link to="firewall?action=deploy"> */}
                        <a  onClick={(e)=>handleMenu("firewall")}
                          className="menu-link py-3"
                           
                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <i
                                className="lab la-hotjar"
                                style={{ fontSize: 20 }}
                              />
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">Cloud Firewall</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item">
                      {/* <Link to="loadbalancer/deploy"> */}
                        <a onClick={(e)=>handleMenu("loadbalancer")}
                          className="menu-link py-3"
                           
                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <i
                                className="bi bi-diagram-3 "
                                style={{ fontSize: 20 }}
                              />
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">Load Balancer</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item">
                      {/* <Link to="dns?action=deploy"> */}
                        <a onClick={(e)=>handleMenu("dns")}
                          className="menu-link py-3"
                           
                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="{24}"
                                height="{24}"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z"
                                  fill="black"
                                />
                                <path
                                  d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">DNS/Domain</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item">
                      {/* <Link to="dns?action=deploy"> */}
                        <a onClick={(e)=>handleMenu("database-cluster")}
                          className="menu-link py-3"
                           
                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                            <svg
                        width={24}
                        height={24}
                        viewBox="0 0 84 84"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          id="DBaaS-Icon-1C-BLK"
                          transform="translate(0.189181, -0.000000)"
                          fill="#494b74"
                          fillRule="nonzero"
                        >
                          <path d="m66.7 48.9c-7.7 2.1-17.7 3.3-28.4 3.3s-20.8-1.2-28.4-3.3c-2.2-.6-4.2-1.3-5.9-2v22c0 5.2 15.3 9.4 34.3 9.4s34.3-4.2 34.3-9.4v-22c-1.8.8-3.7 1.4-5.9 2zm-6.3 19.8c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z" />
                          <path d="m38.3 5.1c-19 0-34.3 4.2-34.3 9.4v25.4c3.6 2.4 14.8 6.1 34.3 6.1 19.4 0 30.7-3.7 34.3-6.1v-25.4c-.1-5.2-15.4-9.4-34.3-9.4zm22.1 31.7c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6c.1 2.5-2 4.6-4.6 4.6zm-22.1-14.3c-20.9 0-32.3-4.9-32.3-7.4s11.4-7.4 32.3-7.4 32.3 4.9 32.3 7.4-11.5 7.4-32.3 7.4z" />
                        </g>
                      </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">DataBase</span>
                        </a>
                        {/* </Link> */}
                      </div>
                      <div className="menu-item">
                      {/* <Link to="dns?action=deploy"> */}
                        <a onClick={(e)=>handleMenu("objectStorage")}
                          className="menu-link py-3"
                           
                        >
                          <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                            <span className="svg-icon svg-icon-2">
                            <svg
                          fill="#494b74"
                          viewBox="0 0 34 24"
                          id="icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width={34}
                          height={24}
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <g id="SVGRepo_iconCarrier">
                            <defs>
                              <style
                                dangerouslySetInnerHTML={{
                                  __html:
                                    "\n                                .cls-1 {\n                                    fill: none;\n                                }\n                            ",
                                }}
                              />
                            </defs>
                            <path d="M28,20H26v2h2v6H4V22H14V20H4a2.0023,2.0023,0,0,0-2,2v6a2.0023,2.0023,0,0,0,2,2H28a2.0023,2.0023,0,0,0,2-2V22A2.0023,2.0023,0,0,0,28,20Z" />
                            <circle cx={7} cy={25} r={1} />
                            <path d="M30,8H22v6H16v8h8V16h6ZM22,20H18V16h4Zm6-6H24V10h4Z" />
                            <path d="M18,10H10V2h8ZM12,8h4V4H12Z" />
                          </g>
                        </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </span>
                          <span className="menu-title">Object Storage</span>
                        </a>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>{" "}
                  {userData!=null?(<> 
                  <div
                    data-kt-menu-trigger="click"
                    className=" menu-item here  menu-lg-down-accordion me-lg-1"
                  >
                    <Link to="/currentUsage">
                    <a 
                      
                      className=" py-3 btn btn-sm btn-light-primary"
                    >
                      <span className="menu-title sphide" id="current_usage">
                        Current Usage: {userData!=null?(userData.currencyprefix):(<></>)}  {userData!=null?(userData.currentusages):(<></>)}
                      </span>
                    </a>
                    </Link>
                  </div>
                  <div
                    data-kt-menu-trigger="click"
                    className="  menu-item here menu-lg-down-accordion me-lg-1"
                  >
                    <Link to="/billing">
                    <a
                      
                      
                      className="btn btn-sm btn-light-primary py-3"
                    >
                      <span className="menu-title sphide" id="available_credit">
                        Available Credits: {userData!=null?(userData.currencyprefix):(<>hur</>)} {userData!=null?((userData?.credit+userData?.freecredit).toFixed(2)):(<></>)}
                      </span>
                    </a>
                    </Link>
                  </div>
                  <div
                    data-kt-menu-trigger="click"
                    className=" menu-item here menu-lg-down-accordion me-lg-1"
                  >
                    <a  className="btn btn-sm btn-light-primary py-3 sphide" style={{cursor:'arow'}}>
                      User ID <b># {userData!=null?(userData.id):(<></>)}</b>.
                    </a>
                  </div>
                  <div
                    data-kt-menu-trigger="click"
                    className="d-none menu-item here menu-lg-down-accordion me-lg-1"
                  >
                    <a
                      target="_blank"
                      href="https://cloud.microhost.com/old"
                      className="btn btn-sm btn-light-primary py-3"
                    >
                      <span className="menu-title sphide">
                        Old Platform{" "}
                        {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr076.svg*/}
                        <span className="svg-icon svg-icon-muted svg-icon-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.3"
                              width={12}
                              height={2}
                              rx={1}
                              transform="matrix(-1 0 0 1 15.5 11)"
                              fill="black"
                            />
                            <path
                              d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z"
                              fill="black"
                            />
                            <path
                              d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z"
                              fill="#C4C4C4"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </span>
                    </a>
                  </div>  
                 <SwitchAccount />
                 </>):(<> </>)}
                </div>
                
                {/*end::Menu*/}
              </div>
              {/*end::Menu wrapper*/}
            </div>
            {/*end::Navbar*/}
            {/*begin::Toolbar wrapper*/}
            <div className="d-flex align-items-stretch flex-shrink-0">
              {/*begin::User menu*/}
              <div
                className="d-flex align-items-center ms-1 ms-lg-3"
                id="kt_header_user_menu_toggle"
              >
                {/*begin::Menu wrapper*/}
                <div
                  className="cursor-pointer symbol symbol-30px symbol-md-40px"
                  data-kt-menu-trigger="click"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                >
                  <div
                    className="vis-item-content"
                    style={{ transform: "translateX(0px)" }}
                  >
                    <div className="rounded-pill bg-light-danger d-flex align-items-center position-relative h-40px w-100 p-2 overflow-hidden">
                      <div
                        className="position-absolute rounded-pill d-block bg-light-primary start-0 top-0 h-100 z-index-1"
                        style={{ width: "100%" }}
                      />
                      <div className="d-flex align-items-center position-relative z-index-2">
                        <div className="symbol-group symbol-hover flex-nowrap me-3">
                          <div className="symbol symbol-circle symbol-25px">
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/communication/com006.svg*/}
                            <span className="svg-icon svg-icon-primary svg-icon-2hx">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                  fill="black"
                                />
                                <path
                                  d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </div>
                        </div>
                        <a
                          
                          className="fw-bold  text-hover-dark"
                          id="header_profile_name"
                        >
                         {userData!=null?(userData.fullname):(<><div className="small-spinner2"></div></>)}
                        </a>
                        <div className="d-flex flex-center bg-body rounded-pill fs-7 fw-bolder ms-auto h-100 px-3 position-relative z-index-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*begin::Header menu toggle*/}
                {/* <div
                  className="d-flex align-items-center d-lg-none ms-2 me-n3"
                  title="Show header menu"
                >
                  <div
                    className="btn btn-icon btn-active-light-primary w-50px h-30px w-md-40px h-md-40px"
                    id="kt_header_menu_mobile_toggle"
                  >
                    <img src="https://www.microhost.com/assets/favicon/favicon-32x32.png?v=3" />
                  </div>
                </div> */}
                {/*end::Header menu toggle*/}
                {/*begin::User account menu*/}
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                  data-kt-menu="true"
                >
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-50px me-5 d-none">
                        <img
                          alt="Logo"
                          src="https://www.microhost.com/assets/favicon/favicon-32x32.png?v=3"
                        />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Username*/}
                      <div className="d-flex flex-column">
                        <div className="fw-bolder d-flex align-items-center fs-5">
                          <span id="profile_name">{userData!=null?(userData.fullname):(<></>)}</span>
                        </div>
                        <a
                          
                          className="fw-bold text-muted text-hover-primary fs-7"
                          id="profile_email"
                        >
                          {userData!=null?(userData.email):(<></>)}
                        </a>
                      </div>
                      {/*end::Username*/}
                    </div>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-5">
                    <Link to="account" >
                    <a  className="menu-link px-5">
                      My Profile
                    </a>
                    </Link>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-5">
                    <Link to="cloud" >
                    <a  className="menu-link px-5">
                      <span className="menu-text">My Servers</span>
                      <span className="menu-badge"></span>
                    </a>
                    </Link>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-5 my-1">
                    <Link to="accountManagement">
                    <a  className="menu-link px-5">
                      Account Settings
                    </a>
                    </Link>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-5">
                    <a
                    onClick={logout}
                      
                      className="menu-link px-5"
                    >
                      Sign Out
                    </a>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-5">
                    <div className="menu-content px-5">
                      <label
                        className="form-check form-switch form-check-custom form-check-solid pulse pulse-success"
                        htmlFor="kt_user_menu_dark_mode_toggle"
                      >
                        <div id="darkmodeChange">
                          <input
                            className="form-check-input w-30px h-20px"
                            type="checkbox" 
                            name="mode"
                            id="themeSelect" 
                             onChange={setTheme}
                          />
                        </div>
                        <span className="pulse-ring ms-n1" />
                        <span className="form-check-label text-gray-600 fs-7">
                          Dark Mode{" "}
                        </span>
                      </label>
                    </div>
                  </div>
                  {/*begin::Menu separator*/}
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                  <div className="menu-item px-5 d-none">
                    <a className="menu-link px-5">Language</a>
                    <span className="text-muted" id="google_translate_element">
                      <div
                        className="skiptranslate goog-te-gadget"
                        dir="ltr"
                        style={{}}
                      >
                        <div id=":0.targetLanguage">
                          <select
                            className="goog-te-combo"
                            aria-label="Language Translate Widget"
                          >
                            <option value="">Select Language</option>
                            <option value="af">Afrikaans</option>
                            <option value="sq">Albanian</option>
                            <option value="am">Amharic</option>
                            <option value="ar">Arabic</option>
                            <option value="hy">Armenian</option>
                            <option value="as">Assamese</option>
                            <option value="ay">Aymara</option>
                            <option value="az">Azerbaijani</option>
                            <option value="bm">Bambara</option>
                            <option value="eu">Basque</option>
                            <option value="be">Belarusian</option>
                            <option value="bn">Bengali</option>
                            <option value="bho">Bhojpuri</option>
                            <option value="bs">Bosnian</option>
                            <option value="bg">Bulgarian</option>
                            <option value="my">Burmese</option>
                            <option value="ca">Catalan</option>
                            <option value="ceb">Cebuano</option>
                            <option value="ny">Chichewa</option>
                            <option value="zh-CN">Chinese (Simplified)</option>
                            <option value="zh-TW">Chinese (Traditional)</option>
                            <option value="co">Corsican</option>
                            <option value="hr">Croatian</option>
                            <option value="cs">Czech</option>
                            <option value="da">Danish</option>
                            <option value="dv">Dhivehi</option>
                            <option value="doi">Dogri</option>
                            <option value="nl">Dutch</option>
                            <option value="eo">Esperanto</option>
                            <option value="et">Estonian</option>
                            <option value="ee">Ewe</option>
                            <option value="tl">Filipino</option>
                            <option value="fi">Finnish</option>
                            <option value="fr">French</option>
                            <option value="fy">Frisian</option>
                            <option value="gl">Galician</option>
                            <option value="ka">Georgian</option>
                            <option value="de">German</option>
                            <option value="el">Greek</option>
                            <option value="gn">Guarani</option>
                            <option value="gu">Gujarati</option>
                            <option value="ht">Haitian Creole</option>
                            <option value="ha">Hausa</option>
                            <option value="haw">Hawaiian</option>
                            <option value="iw">Hebrew</option>
                            <option value="hi">Hindi</option>
                            <option value="hmn">Hmong</option>
                            <option value="hu">Hungarian</option>
                            <option value="is">Icelandic</option>
                            <option value="ig">Igbo</option>
                            <option value="ilo">Ilocano</option>
                            <option value="id">Indonesian</option>
                            <option value="ga">Irish Gaelic</option>
                            <option value="it">Italian</option>
                            <option value="ja">Japanese</option>
                            <option value="jw">Javanese</option>
                            <option value="kn">Kannada</option>
                            <option value="kk">Kazakh</option>
                            <option value="km">Khmer</option>
                            <option value="rw">Kinyarwanda</option>
                            <option value="gom">Konkani</option>
                            <option value="ko">Korean</option>
                            <option value="kri">Krio</option>
                            <option value="ku">Kurdish (Kurmanji)</option>
                            <option value="ckb">Kurdish (Sorani)</option>
                            <option value="ky">Kyrgyz</option>
                            <option value="lo">Lao</option>
                            <option value="la">Latin</option>
                            <option value="lv">Latvian</option>
                            <option value="ln">Lingala</option>
                            <option value="lt">Lithuanian</option>
                            <option value="lg">Luganda</option>
                            <option value="lb">Luxembourgish</option>
                            <option value="mk">Macedonian</option>
                            <option value="mai">Maithili</option>
                            <option value="mg">Malagasy</option>
                            <option value="ms">Malay</option>
                            <option value="ml">Malayalam</option>
                            <option value="mt">Maltese</option>
                            <option value="mi">Maori</option>
                            <option value="mr">Marathi</option>
                            <option value="mni-Mtei">Meiteilon (Manipuri)</option>
                            <option value="lus">Mizo</option>
                            <option value="mn">Mongolian</option>
                            <option value="ne">Nepali</option>
                            <option value="no">Norwegian</option>
                            <option value="or">Odia (Oriya)</option>
                            <option value="om">Oromo</option>
                            <option value="ps">Pashto</option>
                            <option value="fa">Persian</option>
                            <option value="pl">Polish</option>
                            <option value="pt">Portuguese</option>
                            <option value="pa">Punjabi</option>
                            <option value="qu">Quechua</option>
                            <option value="ro">Romanian</option>
                            <option value="ru">Russian</option>
                            <option value="sm">Samoan</option>
                            <option value="sa">Sanskrit</option>
                            <option value="gd">Scots Gaelic</option>
                            <option value="nso">Sepedi</option>
                            <option value="sr">Serbian</option>
                            <option value="st">Sesotho</option>
                            <option value="sn">Shona</option>
                            <option value="sd">Sindhi</option>
                            <option value="si">Sinhala</option>
                            <option value="sk">Slovak</option>
                            <option value="sl">Slovenian</option>
                            <option value="so">Somali</option>
                            <option value="es">Spanish</option>
                            <option value="su">Sundanese</option>
                            <option value="sw">Swahili</option>
                            <option value="sv">Swedish</option>
                            <option value="tg">Tajik</option>
                            <option value="ta">Tamil</option>
                            <option value="tt">Tatar</option>
                            <option value="te">Telugu</option>
                            <option value="th">Thai</option>
                            <option value="ti">Tigrinya</option>
                            <option value="ts">Tsonga</option>
                            <option value="tr">Turkish</option>
                            <option value="tk">Turkmen</option>
                            <option value="ak">Twi</option>
                            <option value="uk">Ukrainian</option>
                            <option value="ur">Urdu</option>
                            <option value="ug">Uyghur</option>
                            <option value="uz">Uzbek</option>
                            <option value="vi">Vietnamese</option>
                            <option value="cy">Welsh</option>
                            <option value="xh">Xhosa</option>
                            <option value="yi">Yiddish</option>
                            <option value="yo">Yoruba</option>
                            <option value="zu">Zulu</option>
                          </select>
                        </div>
                        Powered by{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          <a
                            className="goog-logo-link"
                            href="https://translate.google.com"
                            target="_blank"
                          >
                            <img
                              src="https://www.gstatic.com/images/branding/googlelogo/1x/googlelogo_color_42x16dp.png"
                              style={{ paddingRight: 3 }}
                              alt="Google Translate"
                              width="37px"
                              height="14px"
                            />
                            Translate
                          </a>
                        </span>
                      </div>
                    </span>
                  </div>
                  {/*end::Menu item*/}
                </div>
                {/*end::User account menu*/}
                {/*end::Menu wrapper*/}
              </div>
              {/*end::User menu*/}
            </div>
            {/*end::Toolbar wrapper*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Container*/}
        
      </div>
      {/*end::Header*/}
      {/* </>):(<></>)} */}
     </>
    
    )
}