import React from 'react'; 
import { Link } from "react-router-dom"; 
const BottomNavbar = () => {
    return (
        <>
    <nav className="mobile-nav">
    <a id="kt_aside_mobile_toggle" className="bloc-icon">
      {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen008.svg*/}
  <span className="svg-icon svg-icon-primary svg-icon-2hx">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
    </a>
    
    <a  className="bloc-icon">
    <Link to="/billing">
      {/*begin::Svg Icon | path: assets/media/icons/duotune/finance/fin008.svg*/}
  <span className="svg-icon svg-icon-primary svg-icon-2hx">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        opacity="0.3"
        d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z"
        fill="black"
      />
      <path
        d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
  </Link>
    </a>
    
    
    <a  className="bloc-icon"> 
    <Link to="/">
  {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen001.svg*/}
  <span className="svg-icon svg-icon-primary svg-icon-2hx">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11 2.375L2 9.575V20.575C2 21.175 2.4 21.575 3 21.575H9C9.6 21.575 10 21.175 10 20.575V14.575C10 13.975 10.4 13.575 11 13.575H13C13.6 13.575 14 13.975 14 14.575V20.575C14 21.175 14.4 21.575 15 21.575H21C21.6 21.575 22 21.175 22 20.575V9.575L13 2.375C12.4 1.875 11.6 1.875 11 2.375Z"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/} 
  </Link> 
    </a>
    
    <a id="kt_header_menu_mobile_toggle" className="bloc-icon">
   
  {/*begin::Svg Icon | path: assets/media/icons/duotune/layouts/lay009.svg*/}
  <span className="svg-icon svg-icon-primary svg-icon-2hx">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        opacity="0.3"
        d="M20 14H11C10.4 14 10 13.6 10 13V10C10 9.4 10.4 9 11 9H20C20.6 9 21 9.4 21 10V13C21 13.6 20.6 14 20 14ZM21 20V17C21 16.4 20.6 16 20 16H11C10.4 16 10 16.4 10 17V20C10 20.6 10.4 21 11 21H20C20.6 21 21 20.6 21 20Z"
        fill="black"
      />
      <path
        d="M20 7H3C2.4 7 2 6.6 2 6V3C2 2.4 2.4 2 3 2H20C20.6 2 21 2.4 21 3V6C21 6.6 20.6 7 20 7ZM7 9H3C2.4 9 2 9.4 2 10V20C2 20.6 2.4 21 3 21H7C7.6 21 8 20.6 8 20V10C8 9.4 7.6 9 7 9Z"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
    </a>
    <a   className="bloc-icon"> 
    <Link to="/account">
  {/*begin::Svg Icon | path: assets/media/icons/duotune/communication/com006.svg*/}
  <span className="svg-icon svg-icon-primary svg-icon-2hx">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        opacity="0.3"
        d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
        fill="black"
      />
      <path
        d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/} 
  </Link>
    </a>
  </nav>
        </>
    );
};
 


export default BottomNavbar;
