import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import DeployDns from "./DeployDns";
import { ActionTypes } from "@redux-devtools/core";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
export default function Dns(){
  var currentUrl = window.location.href; 
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess]=useState(true);
  const [perPage, setPerPage]=useState(5);
  const [dnsData,setDnsData]=useState(null);
  const dataFetchedRef=useRef(false);
  const [actionType, setActionType]=useState("page");
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }
  const getDns=async()=>{ 
    const dt=toast
       const data=await UseApi('get','dns',''); 
       if(data){
        setDataFetched(true);
       }
       if('status' in data){
        if(data.status=="error"){ 
           toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });  
          setAccess(false);
          setDnsData(null); 
          loaderFunc(); 
          return;
        }
     }
       if(Object.keys(data).length === 0){
        setDnsData(null);
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            }); 
            loaderFunc();
       }else{
        setDnsData(data.domains);  
        toast.dismiss();
          //   toast.update(dt,{render: "Your DNS request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          // });  
          
       }
  }
  

  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss(); 
      const urlSearchParams = new URLSearchParams(new URL(currentUrl).search);
      const actionValue = urlSearchParams.get('action');
      setActionType(actionValue);
      getDns(); 
  },[]); 
  const dnsPoint=async(domain)=>{ 
    toast.dismiss();
    const dt=toast.loading("Finding Name servers..."); 
    const dnsp=await UseApi('GET','dns/'+domain+'/checkns/','');
    if(dnsp.status=='error'){
      toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else{
      toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    }); 
    }
    //window.location.href=domain;
  }
  function ProductInfo({ productdata }) { 
    return(
        <>
        
       
        {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((value, index) => (
          <tr>
            <td className="p-3 text-center">
              <div className="d-flex text-center justify-content-center">
                <div className="d-flex  fw-bold text-center">{++index}</div>
              </div>
            </td>
            <td className=" ">
              <a  className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                {value.domain}
              </a>
            </td>
            <td className="text-center">
              <span className="badge badge-secondary badge-lg">{value.dnsrecord_count}</span>
            </td>
            <td className="text-center">
              {value.nspoint==='YES'?(<span className="badge badge-success" style={{ marginRight: 10 }}> {value.nspoint} </span>):(<span className="badge badge-danger" style={{ marginRight: 10 }}> No </span> )}
              <span className="badge badge-primary" style={{cursor: "pointer"}}>
                <a  
                  id="checknsundefined-btn"
                  onClick={()=>dnsPoint(value.domain)}
                >
                  Check Now{" "}
                </a>
              </span>
            </td>
            <td className="text-center">
              <span className="fs-7 fw-bold">{value.created_at}</span>
            </td>
            <td className="">
              {" "}
              <div className="d-flex justify-content-center flex-shrink-0">
                {" "}
                <Link to={"/dns/"+value.domain+""}>
                <a 
                  className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 "
                >
                  {" "}
                  Manage{" "}
                  <span className="svg-icon ms-2 svg-icon-3">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      {" "}
                      <rect
                        opacity="0.5"
                        x={18}
                        y={13}
                        width={13}
                        height={2}
                        rx={1}
                        transform="rotate(-180 18 13)"
                        fill="black"
                      />{" "}
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="black"
                      />{" "}
                    </svg>{" "}
                  </span>{" "}
                </a>{" "}
                </Link>
              </div>
            </td>
          </tr>
          ))):( <>
           <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
            </>)}
             
       
        </>
    )
}


const [searchTerm,setSearchTerm]=useState('');
function PaginatedItems({ itemsPerPage, ProductData, search }) {
  // We start with an empty list of items. 
  const [pageCount, setPageCount] = useState(0);
  const [vpcData,setVpcData]=useState(null);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    
    const endOffset = itemOffset + itemsPerPage;


  //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
     if(ProductData!=null){
    //   var str="Noida";
   // ////console.log(ProductData);
    const data= ProductData.filter(row => {
     return  Object.values(row).some(value => { 
        if(value!=null){
          if(value.toString().toLowerCase().includes(search.toLowerCase())){
            return value;
          } 
        }
      });
    });
    //////console.log(data);
      // const data=ProductData.filter(item => 
      //        item.hostname.toLowerCase().includes(search.toLowerCase())
      //              ); 

                   if(data.length<=0){
                    loaderFunc();
                   }
    setVpcData(data.slice(itemOffset, endOffset));
    //setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
    // alert(pageCount);
      }
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  
  const handlePageClick = (event) => { 
    const newOffset = event.selected * itemsPerPage % ProductData.length;
    ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
   
  };
  var searchEle=document.querySelector("#searchClient");
  if(searchEle){
    searchEle.focus();
  }
  return (
    <>
      <ProductInfo productdata={vpcData} />
      <td colspan="12">
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      </td>
    </>
  );
}
 
if(dataFetched){
  if(access==false){
    return (
      <>
       <AccessDenied />
      </>
    );
  }

  if(dnsData!=null){
    if(Object.keys(dnsData).length!=0){
return(
  <> 
   <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold fs-3 mb-1">
          DNS Management{" "}
          {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="{24}"
              height="{24}"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.3"
                d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z"
                fill="black"
              />
              <path
                d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </span>
        <span className="text-muted mt-1 fw-bold fs-7">Manage your DNS</span>
      </h3>
      <div
        className="card-toolbar" 
      >
        <div className="position-relative my-1" style={{ marginRight: 20 }}>
          <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
        <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select  
        onChange={e => setPerPage(e.target.value)}
        //data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={5}
        id="perPage"
        
      >
        <option value={5} selected="">
          5 Records
        </option>
        <option value={10}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
  </div>
</div>
      <DeployDns type={actionType} updateDnsList={getDns} />
      </div>
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    {/*begin::Table container*/}
    <div className="table-responsive">
      {/*begin::Table*/}
      <table
        id="dnsTable"
        className="table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className="fw-bold ">
            <th className="min-w-50px p-3 text-center">#</th>
            <th className="min-w-150px">Domain Name</th>
            <th className="text-center min-w-100px">DNS Record</th>
            <th className="text-center min-w-100px">DNS Point</th>
            <th className="text-center min-w-150px">Created at</th>
            <th className="min-w-100px text-center">Action</th>
          </tr>
        </thead>
        {/*end::Table head*/}
        {/*begin::Table body*/}
        <tbody id="dns-list"> 
  {dnsData!=null?(<>
<PaginatedItems itemsPerPage={perPage} ProductData={dnsData} search={searchTerm}  />
  </>):(<>
    <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center">
                        <img
                          src="/assets/media/illustrations/dozzy-1/5.png"
                          className="mw-400px"
                        />
                        <div className="fs-1 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
  </>)}
  </tbody>
        {/*end::Table body*/}
      </table>
      {/*end::Table*/}
    </div>
    {/*end::Table container*/}
    {/*begin::Body*/}
  </div>
  {/*end::Tables Widget 9*/}
</div>

  </>
)
} else{
  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          {/*begin::Card body*/}
          <div className="card-body p-0 mt-10">
            {/*begin::Illustration*/}
            <div className="text-center px-4 mt-10">
              <span className="mw-100 mh-300px">
              <svg  
              width="234px"
              height="234px" 
              fill="#21325B"
              viewBox="0 0 32 32"
              id="icon"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#21325B"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
              <g id="SVGRepo_iconCarrier">
                {" "}
                <defs>
                  {" "}
                  <style
                    dangerouslySetInnerHTML={{ __html: " .cls-1 { fill: none; } " }}
                  />{" "}
                </defs>{" "}
                <path
                  d="M16,2a8,8,0,1,0,8,8A8.0092,8.0092,0,0,0,16,2Zm5.91,7H19.4724a15.2457,15.2457,0,0,0-.7917-4.36A6.0088,6.0088,0,0,1,21.91,9ZM16.022,15.999h-.0076c-.3813-.1206-1.3091-1.8213-1.479-4.999h2.9292C17.2952,14.1763,16.3711,15.877,16.022,15.999ZM14.5354,9c.1694-3.1763,1.0935-4.877,1.4426-4.999h.0076c.3813.1206,1.3091,1.8213,1.479,4.999ZM13.3193,4.64A15.2457,15.2457,0,0,0,12.5276,9H10.09A6.0088,6.0088,0,0,1,13.3193,4.64ZM10.09,11h2.4373a15.2457,15.2457,0,0,0,.7917,4.36A6.0088,6.0088,0,0,1,10.09,11Zm8.59,4.36A15.2457,15.2457,0,0,0,19.4724,11H21.91A6.0088,6.0088,0,0,1,18.6807,15.36Z"
                  transform="translate(0 0)"
                />{" "}
                <path
                  d="M28,30H4a2.0021,2.0021,0,0,1-2-2V22a2.0021,2.0021,0,0,1,2-2H28a2.0021,2.0021,0,0,1,2,2v6A2.0021,2.0021,0,0,1,28,30ZM4,22v6H28V22Z"
                  transform="translate(0 0)"
                />{" "}
                <circle cx={7} cy={25} r={1} />{" "} 
              </g>
            </svg>
            

              </span>
            </div>
            {/*end::Illustration*/}
            {/*begin::Wrapper*/}
            <div className="card-px text-center py-5 mb-20">
              {/*begin::Title*/}
              <h1 className="fs-2x fw-bolder mb-5">DNS</h1>
              <h2 className="fs-1x fw-bolder mb-5 text-muted">
              Reliable, scalable, and secure DNS service
              </h2>
              {/*end::Title*/}
              {/*begin::Description*/}
              <p className="text-gray-400 fs-4 fw-bold mb-10">
              Easy and cost-effective to connect user requests to your applications. <br/>Navigate the web smoothly.</p>
              {/*end::Description*/}
              {/*begin::Action*/}
              <Link to="/dns/deploy">
              <a
                href="#"
                className="btn btn-primary mb-10"
              >
                Create DNS
              </a>
              </Link>
              {/*end::Action*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Card body*/}
        </div>
      </div>
    </>
  );
}
}
}else{
return (
<>
<TopBarHeader/>
{/* <div class="loading-spinner2"></div> */}
</>
);
}
}