import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";   
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import swal from 'sweetalert'; 
import copy from "copy-to-clipboard";
import UseApi from "../../../../CustomHooks/useApi";
import UsePost from "../../../../CustomHooks/usePost";
import {UpdateNodePoolRow } from "../../../../Redux/Services/Action/actions"; 
const ManageKubernetes=(props)=>{
  var fileDownload = require('js-file-download');
  const makeid=(length)=> {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
  const navigate=useNavigate();
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4];   
  const [planData,setPlanData]=useState(null); 
  const dataFetchedRef=useRef (false);
  const dispatch=useDispatch(); 
  const user_data=useRef(null);
  const [poolData, setPoolData]=useState(null);
  const rowCount=useRef(2);
  const navRedirect=()=>{
    setTimeout(() => {
      navigate("/kubernetes/"+id);
     }, 3000);
      
  }

  const copyToClipboard=(id)=> {   
       
    var copyText = document.querySelector("#"+id).innerHTML;
    copy(copyText);  
    document.querySelector("#"+id).innerHTML="Copied";
    setTimeout(() => {
      document.querySelector("#"+id).innerHTML=copyText;
    }, 2000);
   
  }
  const getPlans=async()=>{  
       const data=await UseApi('get','cloud/getdeploy','');  
       user_data.current=await UseApi('get','account/info','');
      // //console.log(user_data.current);
       if(Object.keys(data).length === 0){
        setPlanData(null); 
       }else{
        setPlanData(data.plans);  
       // //console.log(data.plans);
       } 
       setPoolData(props.nodePoolData);
  }
  
  useEffect(()=>{ 
    if(dataFetchedRef.current) return;
       dataFetchedRef.current=true;
       toast.dismiss();
    getPlans();   
},[props.nodePoolData]); 
  const openDrawer=(datas)=>{
    var drawerElement = document.querySelector("#kubernetes-modal");
    if(drawerElement){
    drawerElement.style.width='1100px';
    if(datas=='close'){ 
        drawerElement.classList.remove("drawer-on"); 
  }else{ 
        drawerElement.classList.add("drawer-on"); 
      const node_plan_pool_plan_id=document.querySelector("#node_plan_pool_plan_id");
      node_plan_pool_plan_id.value=datas;
  }
}
}

  const showNodePool=()=>{
    const nodePool=document.querySelector("#addpool");
    const add_node=document.querySelector("#add_node");
    const cancel_node=document.querySelector("#cancel_node");
         nodePool.classList.toggle('d-none');
         add_node.classList.toggle('d-none');
         cancel_node.classList.toggle('d-none');
  }

  const updatePool=(pid)=>{
    const update_pool_yes=document.querySelector('#update_pool_yes_'+pid);
    const update_pool_no=document.querySelector('#update_pool_no_'+pid);
    const update_pool=document.querySelector('#update_pool_'+pid);
    update_pool.classList.toggle('d-none');
    update_pool_yes.classList.toggle('d-none');
    update_pool_no.classList.toggle('d-none');

  }

  const selectNodeSize=(pid,ram,cpu,disk,bandwidth,price)=>{ 
    const node_plan_pool_plan_id=document.querySelector("#node_plan_pool_plan_id");
    const poolId=node_plan_pool_plan_id.value;
    const show_select_plans=document.querySelector("#show_select_plans_"+poolId);
    const select_kubernetes_plans=document.querySelector("#select_kubernetes_plans_"+poolId);
    const select_ram=document.querySelector("#ram_"+poolId);
    const select_cpu=document.querySelector("#cpu_"+poolId);
    const select_bandwidth=document.querySelector("#bandwidth_"+poolId); 
    const node_select_plan=document.querySelector("#node_select_plan_"+poolId);
    const order_total=document.querySelector("#order_total_"+poolId);
    const node_count=document.querySelector("#count_"+poolId);
    const plan_price=document.querySelector("#plan_price_"+poolId);
    if(node_count){
    var total_plan_price=(parseFloat(price)*parseInt(node_count.value)*720).toFixed(2);
    plan_price.value=(parseFloat(price)*720).toFixed(2);
    if(user_data.current!=null){
    order_total.innerHTML=user_data.current.user.currencyprefix+total_plan_price+"/mon";
    }
    node_select_plan.value=pid;
    show_select_plans.classList.remove('d-none');
    select_kubernetes_plans.classList.add('d-none');
    select_ram.innerHTML=ram;
    select_cpu.innerHTML=cpu;
    select_bandwidth.innerHTML=bandwidth;
  }
    var drawerElement = document.querySelector("#kubernetes-modal");
    if(drawerElement){
      drawerElement.classList.remove("drawer-on");
         }
  }
  const nodePoolUpdate=(npid)=>{
    const pool_name=document.querySelector("#pool_name_"+npid);
    const node_select_plan=document.querySelector("#node_select_plan_"+npid);
    const node_count=document.querySelector("#count_"+npid);

    for(var i=0;i<props.nodePoolData.length;i++){
      if(props.nodePoolData[i]){
      if(props.nodePoolData[i].pool_id==npid){
         props.nodePoolData[i].nodePoolValue=pool_name.value;
         props.nodePoolData[i].nodeSize=node_select_plan.value;
         props.nodePoolData[i].nodeCount=node_count.value;
      }
    }
    } 
    //console.log(props.nodePoolData);
  }
    
    const addFormField = ()=> {  
      rowCount.current+=1;
    var newRow={pool_id:rowCount.current, nodePoolValue:'pool-'+makeid(8), nodeSize:{}, nodeCount:1, totalCost:0.00};  
    props.nodePoolData.push(newRow);
    //console.log(props);
    setPoolData(props.nodePoolData); 
    getPlans();
    navRedirect();
  }
  const deleteFormField=(frid)=> {  
    for(var i=0;i<props.nodePoolData.length;i++){
      if(props.nodePoolData[i]){
      if(props.nodePoolData[i].pool_id==frid){
         delete props.nodePoolData[i];
        //console.log(props.nodePoolData[i]);
      }
    }
    }
    //console.log(props); 
    getPlans();
    navRedirect();
    
  }
  const submitNodePool=async ()=>{ 
    toast.dismiss(); 
    const dt=toast.loading("New pool adding, Please wait...");
     const responseData=await UsePost('post','kubernetes/'+id+'/nodepool/add','addnodepoolkubernetes'); 
       ////console.log(responseData);
       if(responseData.status==='error'){
         toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       });  
       }else if(responseData.status==='success'){
         toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       }); 
      //  const data=await UseApi('get','kubernetes/'+id,'');
      //   dispatch(props.updateCloudStatus(data.cloud[0]));
      getKubernetes();
       navigate('/kubernetes/'+id);
       }
   }
   

   const nodePoolCount=(npid,status)=>{
    const node_count=document.querySelector("#count_"+npid);
    const downCountBtn=document.querySelector("#downcount_"+npid);
    const upCountBtn=document.querySelector("#upcount_"+npid);
    const order_total=document.querySelector("#order_total_"+npid); 
    const plan_price=document.querySelector("#plan_price_"+npid);
     
    if(status=='up'){
      downCountBtn.classList.remove('disabled');
      if(parseInt(node_count.value)>=5){
        upCountBtn.classList.add('disabled'); 
      }else{
        node_count.value=parseInt(node_count.value)+1; 
      } 
      var total_plan_price=(parseFloat(plan_price.value)*parseInt(node_count.value)).toFixed(2); 
        if(user_data.current!=null){
        order_total.innerHTML=user_data.current.user.currencyprefix+total_plan_price+"/mon";
         }
     
    }else if(status=='down'){
      upCountBtn.classList.remove('disabled');
      if(parseInt(node_count.value)<=1){
        downCountBtn.classList.add('disabled'); 
      }else{
        node_count.value=parseInt(node_count.value)-1;
      } 
        var total_plan_price=(parseFloat(plan_price.value)*parseInt(node_count.value)).toFixed(2);
        if(user_data.current!=null){
        order_total.innerHTML=user_data.current.user.currencyprefix+total_plan_price+"/mon";
         }
    }
    
    
   }

//start- kubernetes pool listing
const [kubernetesData,setKubernetesData]=useState(null);

const getKubernetes =async()=>{  
  const data=await UseApi('get','kubernetes/'+id,'');   
 // //console.log(user_data.current);
  if(data.rcode!="success"){
    swal({
        title: "Kubernetes",
        text: "No Kubernetes found with id: "+id+"!",
        icon: "warning",
        button: "OK",
      }).then(()=>{
        navigate("/kubernetes");
      })
        return;
  //  setKubernetesData(null); 
  }else{ 
    setKubernetesData(data);   
    setTimeout(()=>{
      if(data && data.info && data.info.cluster){  
        if (data.info.cluster.status == "Pending") {
          var installing_kubernetes = document.querySelector("#installing_kubernetes");
          if (installing_kubernetes) {
            installing_kubernetes.classList.remove("d-none");
          }
        } 
    }
    },100)
  }  
}

useEffect(()=>{  
  getKubernetes();   
},[]); 

useEffect(() => { 
  const intervalId = setInterval(() => { 
    if(kubernetesData!=null && kubernetesData.info && kubernetesData.info.cluster){  
        if (kubernetesData.info.cluster.status != "Pending") {
          var installing_kubernetes = document.querySelector("#installing_kubernetes");
          if (installing_kubernetes) {
            installing_kubernetes.classList.add("d-none");
          }
        }else{
          var installing_kubernetes = document.querySelector("#installing_kubernetes");
          if (installing_kubernetes) {
            installing_kubernetes.classList.remove("d-none");
          }
           getKubernetes(); 
        }
    }
  }, 9000);

  // Clear the interval when the component unmounts
  return () => clearInterval(intervalId);
}, [kubernetesData]);

const downloadFile = async (url, name) => {
  try {
    const response = await fetch(url);
    const data = await response.blob(); // Get the file content as a Blob

    fileDownload(data, name); // Initiate the download using js-file-download
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};
const downloadKubernetes=async()=>{ 
  toast.dismiss();
  const dt=toast.loading("Kubernetes downloading..."); 
  const dnsp=await UseApi('get','kubernetes/'+id+'/download',''); 
  downloadYamlFile(dnsp); 
  toast.update(dt,{render: 'Kubernetes downloaded successfully!', type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
   }); 
    navigate('/kubernetes/'+id); 
}

function downloadYamlFile(content) {
  const blob = new Blob([content], { type: 'application/yaml' });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = 'kubeconfig_mks_'+id+'.yaml'; // Specify the desired filename here
  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
}
const destroyKubernetes=async()=>{
  toast.dismiss();
  swal({
    title:"Are you sure?",
    text: "Once deleted, you will not be able to recover this Kubernetes!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
    })
    .then(async(willDelete) => {
    if(willDelete) {
      const dt=toast.loading("Kubernetes deleting..."); 
  const dnsp=await UseApi('DELETE','kubernetes/'+id+'/destroy', 'destroyform');
  if(dnsp.status=='error'){
    toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  });
  }else if(dnsp.status=='success'){
    toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  });  
  navigate('/kubernetes');
  }
    }else {
      swal({
          title: "Kubernetes",
          text: "Your Kubernetes   is safe!",
          icon: "success",
          button: "OK",
        });
    }
    });
}

if(kubernetesData!=null){
  ////console.log(Object.keys(kubernetesData.nodepools).length);
  Object.entries(kubernetesData.nodepools).map((value,index)=>{
   //console.log(value[1]);
  });

}

const updateNodeCount=(pid,status,cost)=>{
    const node_count_up=document.querySelector("#node_count_up_"+pid);
    const node_count_down=document.querySelector("#node_count_down_"+pid);
    const order_total_pool=document.querySelector("#order_total_pool-"+pid);
    const count_pool=document.querySelector("#count_pool_node_"+pid);
   
    if(parseInt(count_pool.value)>=5){
      node_count_up.classList.add('disabled');
    }else if(parseInt(count_pool.value)<=1){
      node_count_down.classList.add('disabled'); 
    }
    if(status=='up'){
      node_count_down.classList.remove('disabled');
      if(parseInt(count_pool.value)>=5){
        node_count_up.classList.add('disabled'); 
      }else{
        count_pool.value=parseInt(count_pool.value)+1; 
      } 
      var total_plan_price=(parseFloat(cost)*parseInt(count_pool.value)).toFixed(2); 
        if(user_data.current!=null){
          order_total_pool.innerHTML=user_data.current.user.currencyprefix+total_plan_price+"/mon";
         }
     
    }else if(status=='down'){
      node_count_up.classList.remove('disabled');
      if(parseInt(count_pool.value)<=1){
        node_count_down.classList.add('disabled'); 
      }else{
        count_pool.value=parseInt(count_pool.value)-1;
      } 
        var total_plan_price=(parseFloat(cost)*parseInt(count_pool.value)).toFixed(2);
        if(user_data.current!=null){
          order_total_pool.innerHTML=user_data.current.user.currencyprefix+total_plan_price+"/mon";
         }
    }
  
}

const updatePoolList=async (pid)=>{  
  toast.dismiss();
  const dt=toast.loading("New pool adding, Please wait...");
   const responseData=await UsePost('post','kubernetes/'+id+'/nodepool/'+pid+'/update','update_pool_pool-'+pid); 
     ////console.log(responseData);
     if(responseData.status==='error'){
       toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
     });  
     }else if(responseData.status==='success'){
       toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
     }); 
    //  const data=await UseApi('get','kubernetes/'+id,'');
    //   dispatch(props.updateCloudStatus(data.cloud[0]));
    getKubernetes();
     navigate('/kubernetes/'+id);
     }
 }
  
//end- kubernetes pool listing
    return(
        <>
        {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
         {kubernetesData!=null?(<>
        <div className="col-xl-12">
  <div className="post d-flex flex-column-fluid">
    <div className="container-xxl">
      <div className="card mb-6 mb-xl-9">
        <div className="card-body pt-9 pb-0">
          {/*begin::Details*/}
          {kubernetesData!=null?(
          <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
            {/*begin::Image*/}
            <div
              className="d-flex flex-center flex-shrink-0 rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4"
              id="flag-icon"
            >
              
              {/*begin::Image input*/}
              <div
                className="image-input image-input-empty"
                data-kt-image-input="true"
                style={{ backgroundImage: "url(/assets/media/flags/svg/"+kubernetesData.info.cluster.dclocation.dccc+".svg)" }}
              >
                
                <div className="image-input-wrapper w-60px h-40px" />
              </div>
              {/*end::Image input*/}
            </div>
            {/*end::Image*/}
            {/*begin::Wrapper*/}
            <div className="flex-grow-1">
              {/*begin::Head*/}
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                {/*begin::Details*/}
                <div className="d-flex flex-column">
                  {/*begin::Status*/}
                  <div className="d-flex align-items-center mb-1">
                    <a 
                      className="text-gray-800 text-hover-primary fs-2 fw-bold me-3"
                      id="clustername"
                    >
                      {kubernetesData.info.cluster.label}
                    </a>
                  </div>
                  {/*end::Status*/}
                  {/*begin::Description*/}
                  <div className="flex-grow-1">
                    <p className="text-muted">
                      <span
                        className="text-muted text-hover-primary fw-bold fs-6"
                        id="country"
                      >
                        {kubernetesData.info.cluster.dclocation.country}
                      </span>
                      ,
                      <span className="text-muted fw-bold" id="location">
                        {kubernetesData.info.cluster.dclocation.location}
                      </span>
                    </p>
                  </div>
                  {/*end::Description*/}
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column flex-grow-1 pe-8 d-none">
                    {/*begin::Stats*/}
                    <div className="d-flex flex-wrap">
                      {/*begin::Stat*/}
                      <div className="min-w-125px py-3 px-4 me-6 mb-3">
                        {/*begin::Number*/}
                        <div className="d-flex align-items-center">
                          {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                          <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/abstract/abs027.svg*/}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                                fill="black"
                              />
                              <path
                                d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                                fill="black"
                              />
                            </svg>
                            {/*end::Svg Icon*/}
                          </span>
                          {/*end::Svg Icon*/}
                          {/*begin::Label*/}
                        <div className="fw-bold fs-6 text-gray-400 me-3">Cluster IP : </div>
                        {/*end::Label*/}
                          <div className="fs-2 fw-bold" id="masterip">
                            {kubernetesData.info.master.ip}
                          </div>
                        </div>
                        {/*end::Number*/}
                        
                      </div>
                      {/*end::Stat*/} 
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Details*/}
                {/*begin::Actions*/}
                <div className="d-flex mb-4 ">
           
                  {/*begin::Number*/}
                  <div className=" zoom me-5 btn btn-sm btn-light-primary" onClick={()=>copyToClipboard('ip_copy')}>
              IP Address:{" "}
              <span id="ip_copy"  className="fw-bold">
              {kubernetesData.info.master.ip}
              </span>
            </div>
                  
                        {/*end::Number*/}
                   {/*begin::Number*/}
                   <div className="d-flex align-items-center me-3">
                          <a
                            // target="_blank"
                            onClick={downloadKubernetes}
                            className="btn btn-primary btn-sm sphide"
                            // href="https://cloud.microhost.com/kubernetes/283/download"
                          >
                            Download &nbsp; 
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr091.svg*/}
  <span className="svg-icon svg-icon-muted svg-icon-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.3"
        width={12}
        height={2}
        rx={1}
        transform="matrix(0 -1 -1 0 12.75 19.75)"
        fill="black"
      />
      <path
        d="M12.0573 17.8813L13.5203 16.1256C13.9121 15.6554 14.6232 15.6232 15.056 16.056C15.4457 16.4457 15.4641 17.0716 15.0979 17.4836L12.4974 20.4092C12.0996 20.8567 11.4004 20.8567 11.0026 20.4092L8.40206 17.4836C8.0359 17.0716 8.0543 16.4457 8.44401 16.056C8.87683 15.6232 9.58785 15.6554 9.9797 16.1256L11.4427 17.8813C11.6026 18.0732 11.8974 18.0732 12.0573 17.8813Z"
        fill="black"
      />
      <path
        d="M18.75 15.75H17.75C17.1977 15.75 16.75 15.3023 16.75 14.75C16.75 14.1977 17.1977 13.75 17.75 13.75C18.3023 13.75 18.75 13.3023 18.75 12.75V5.75C18.75 5.19771 18.3023 4.75 17.75 4.75L5.75 4.75C5.19772 4.75 4.75 5.19771 4.75 5.75V12.75C4.75 13.3023 5.19771 13.75 5.75 13.75C6.30229 13.75 6.75 14.1977 6.75 14.75C6.75 15.3023 6.30229 15.75 5.75 15.75H4.75C3.64543 15.75 2.75 14.8546 2.75 13.75V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75L18.75 2.75C19.8546 2.75 20.75 3.64543 20.75 4.75V13.75C20.75 14.8546 19.8546 15.75 18.75 15.75Z"
        fill="#C4C4C4"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
                          </a>
                        </div>
                        {/*end::Number*/}
                  <div id="kstatus">
                    <div className="text-gray-800 fs-1 me-3"> 
                      {kubernetesData.info.cluster.status=="Active"?(<a className=" btn btn-sm btn-success">Active</a>):(<a className=" btn btn-sm btn-warning">Pending</a>)}
                    </div>
                  </div>
                </div>
                {/*end::Actions*/}
              </div>
              {/*end::Head*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          ):('')}
          <div className="separator" />
          {/*begin::Nav*/}
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            {/*begin::Nav item*/}
            <li className="nav-item">
              <a
                className="nav-link text-active-primary py-5 me-6 active"
                data-bs-toggle="tab"
                href="#K8S-Nodes"
              >
                K8S Nodes
              </a>
            </li>
            {/*end::Nav item*/}
            {/*begin::Nav item*/}
            <li className="nav-item">
              <a
                className="nav-link text-active-primary py-5 me-6"
                data-bs-toggle="tab"
                href="#destroy"
              >
                Destroy
              </a>
            </li>
            {/*end::Nav item*/}
          </ul>
          {/*end::Nav*/}
        </div>
      </div>
      <div className="text-center d-none" id="installing_kubernetes"> 
 <span className="fw-bold text-center text-gray-600">Installing Kubernetes, Please Wait...</span>
 <div className="loadingStack"> 
 </div>
 </div>
       
        
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="K8S-Nodes"
          role="tabpanel"
        >
          <div className="card">
            <div className="">
              <div className="p-3">
                <div className="notice d-flex bg-light-primary rounded border-primary border p-6">
                  {/*begin::Icon*/}
                  {/*begin::Svg Icon | path: icons/duotune/finance/fin001.svg*/}
                  <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M11.425 7.325C12.925 5.825 15.225 5.825 16.725 7.325C18.225 8.825 18.225 11.125 16.725 12.625C15.225 14.125 12.925 14.125 11.425 12.625C9.92501 11.225 9.92501 8.825 11.425 7.325ZM8.42501 4.325C5.32501 7.425 5.32501 12.525 8.42501 15.625C11.525 18.725 16.625 18.725 19.725 15.625C22.825 12.525 22.825 7.425 19.725 4.325C16.525 1.225 11.525 1.225 8.42501 4.325Z"
                        fill="black"
                      />
                      <path
                        d="M11.325 17.525C10.025 18.025 8.425 17.725 7.325 16.725C5.825 15.225 5.825 12.925 7.325 11.425C8.825 9.92498 11.125 9.92498 12.625 11.425C13.225 12.025 13.625 12.925 13.725 13.725C14.825 13.825 15.925 13.525 16.725 12.625C17.125 12.225 17.425 11.825 17.525 11.325C17.125 10.225 16.525 9.22498 15.625 8.42498C12.525 5.32498 7.425 5.32498 4.325 8.42498C1.225 11.525 1.225 16.625 4.325 19.725C7.425 22.825 12.525 22.825 15.625 19.725C16.325 19.025 16.925 18.225 17.225 17.325C15.425 18.125 13.225 18.225 11.325 17.525Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                  {/*end::Icon*/}
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                    {/*begin::Content*/}
                    <div className="mb-3 mb-md-0 fw-bold">
                      <h4 className="text-gray-900 fw-bolder">Nodes</h4>
                      <div className="fs-6 text-gray-700 pe-7">
                        Here you can manage node pools and nodes
                      </div>
                    </div>
                    {/*end::Content*/} {/*begin::Action*/}
                    <a 
                      onClick={showNodePool}
                      id="add_node"
                      className="btn btn-sm btn-primary btn-active-primary"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                      <span className="svg-icon svg-icon-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x="11.364"
                            y="20.364"
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11.364 20.364)"
                            fill="black"
                          />
                          <rect
                            x="4.36396"
                            y="11.364"
                            width={16}
                            height={2}
                            rx={1}
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}Add Node Pools
                    </a>
                    <a 
                      onClick={showNodePool}
                      id="cancel_node"
                      className="d-none btn btn-sm btn-danger btn-active-primary"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                      <span className="svg-icon svg-icon-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x="7.05025"
                            y="15.5356"
                            width={12}
                            height={2}
                            rx={1}
                            transform="rotate(-45 7.05025 15.5356)"
                            fill="black"
                          />
                          <rect
                            x="8.46447"
                            y="7.05029"
                            width={12}
                            height={2}
                            rx={1}
                            transform="rotate(45 8.46447 7.05029)"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}Close
                    </a>
                    {/*end::Action*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
              </div>
              <div className=" h-lg-100 d-none" id="addpool">
                {/*begin::Tables Widget 11*/}
                <div className="">
                  <form id="addnodepoolkubernetes" className="form">
                    <div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                          Add Node Pool(s)
                        </span>
                        <span className="text-muted  mt-1 fw-bold fs-7">
                          Increasing the number of nodes in a pool lets you run
                          more instances of the scheduled services. Adding more
                          node pools allows you to schedule pods to different
                          node pools so each pod has the RAM, CPU, and storage
                          it requires. You can add and remove nodes and node
                          pools at any time.
                        </span>
                      </h3>
                    </div>
                    {/*begin::Body*/}
                    <div className="">
                      {/*begin::Table container*/}
                      <div className="table-responsive">
                        {/*begin::Table*/}
                        <input
                          type="hidden"
                          id="node_plan_pool_plan_id"
                          defaultValue=""
                          /> 
                        <table
                          className="table table-striped align-middle gs-0 gy-4"
                          id="nodepool_list"
                        >
                          {/*begin::Table head*/}
                          <thead className="bg-secondary">
                            <tr className="fw-bold">
                              <th className="ps-4 min-w-225px">Pool Name</th>
                              <th className="text-center min-w-325px text-center">
                                Node Size
                              </th>
                              <th className="text-center min-w-100px">
                                Node Count
                              </th>
                              <th className="min-w-100px">Total Cost</th>
                              <th className="min-w-100px " />
                            </tr>
                          </thead>
                          {/*end::Table head*/}

                          {/*begin::Table body*/}
                          <tbody>
                          {poolData!=null && (poolData && poolData.map((value,index)=>(<>
                            <tr id="pool-row-0">
                              <td>
                                <div className="d-flex align-items-center p-2">
                                  <input
                                  type="hidden"
                                  id={"plan_price_"+value.pool_id}
                                  defaultValue="0.00"
                                  />
                                  <input
                                    type="text"
                                    className="form-control  "
                                    name={"nodepools["+index+"][label]"}
                                    // name={"nodepools"+index+"label"}
                                    id={"pool_name_"+value.pool_id}
                                    onChange={()=>nodePoolUpdate(value.pool_id)}
                                    defaultValue={value.nodePoolValue}
                                    placeholder="webserver"
                                  />
                                </div>
                              </td>
                              <td className="text-center">
                                
                                <input
                                  type="hidden"
                                  id={"node_select_plan_"+value.pool_id}
                                  name={"nodepools["+index+"][size]"}
                                  // name={"nodepools"+index+"size"}
                                  onChange={()=>nodePoolUpdate(value.pool_id)}
                                />
                                <div
                                  id="select_node_section_0"
                                  onclick="show_kubernetes_plan('0')"
                                  className="align-items-center"
                                >
                                  <div
                                  onClick={()=>openDrawer(value.pool_id)}
                                    className="row d-none"
                                    id={"show_select_plans_"+value.pool_id}
                                  >
                                    <div className="col-lg-3">
                                      <div className="position-relative ps-4 pe-3 py-2">
                                        <a
                                          
                                          className="mb-1 text-dark text-hover-primary fw-bolder"
                                        >
                                          RAM
                                        </a>
                                        <div
                                          className="fs-7 text-muted fw-bolder"
                                          id={"ram_"+value.pool_id}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div
                                        className="position-relative ps-6 pe-3 py-2"
                                        style={{
                                          borderLeft: "3px solid #009ef7"
                                        }}
                                      >
                                        <a
                                          
                                          className="mb-1 text-dark text-hover-primary fw-bolder"
                                        >
                                          CPU
                                        </a>
                                        <div
                                          className="fs-7 text-muted fw-bolder"
                                          id={"cpu_"+value.pool_id}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-5">
                                      <div
                                        className="position-relative ps-6 pe-3 py-2"
                                        style={{
                                          borderLeft: "3px solid #009ef7"
                                        }}
                                      >
                                        <a
                                          
                                          className="mb-1 text-dark text-hover-primary fw-bolder"
                                        >
                                          Bandwidth
                                        </a>
                                        <div
                                          className="fs-7 text-muted fw-bolder"
                                          id={"bandwidth_"+value.pool_id}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                  onClick={()=>openDrawer(value.pool_id)}
                                    id={"select_kubernetes_plans_"+value.pool_id}
                                    className="btn btn-sm btn-light align-items-center px-4"
                                    onclick="show_kubernetes_plan('0')"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {/*begin::Display range*/}
                                    <div className="text-gray-600 fw-bolder">
                                      Select Node Size +{/*end::Display range*/}
                                      {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                                      <span className="svg-icon svg-icon-1 ms-2 me-0">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.3"
                                            d="M18.041 22.041C18.5932 22.041 19.041 21.5932 19.041 21.041C19.041 20.4887 18.5932 20.041 18.041 20.041C17.4887 20.041 17.041 20.4887 17.041 21.041C17.041 21.5932 17.4887 22.041 18.041 22.041Z"
                                            fill="black"
                                          />
                                          <path
                                            opacity="0.3"
                                            d="M6.04095 22.041C6.59324 22.041 7.04095 21.5932 7.04095 21.041C7.04095 20.4887 6.59324 20.041 6.04095 20.041C5.48867 20.041 5.04095 20.4887 5.04095 21.041C5.04095 21.5932 5.48867 22.041 6.04095 22.041Z"
                                            fill="black"
                                          />
                                          <path
                                            opacity="0.3"
                                            d="M7.04095 16.041L19.1409 15.1409C19.7409 15.1409 20.141 14.7409 20.341 14.1409L21.7409 8.34094C21.9409 7.64094 21.4409 7.04095 20.7409 7.04095H5.44095L7.04095 16.041Z"
                                            fill="black"
                                          />
                                          <path
                                            d="M19.041 20.041H5.04096C4.74096 20.041 4.34095 19.841 4.14095 19.541C3.94095 19.241 3.94095 18.841 4.14095 18.541L6.04096 14.841L4.14095 4.64095L2.54096 3.84096C2.04096 3.64096 1.84095 3.04097 2.14095 2.54097C2.34095 2.04097 2.94096 1.84095 3.44096 2.14095L5.44096 3.14095C5.74096 3.24095 5.94096 3.54096 5.94096 3.84096L7.94096 14.841C7.94096 15.041 7.94095 15.241 7.84095 15.441L6.54096 18.041H19.041C19.641 18.041 20.041 18.441 20.041 19.041C20.041 19.641 19.641 20.041 19.041 20.041Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>
                                      {/*end::Svg Icon*/}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="d-flex align-items-center">
                                  {/*begin::Dialer*/}
                                  <div
                                    className="input-group w-md-150px"
                                    data-kt-dialer="true"
                                  >
                                    {/*begin::Decrease control*/}
                                    <a  
                                      className="btn btn-icon btn-outline btn-outline-secondary disabled"
                                      id={"downcount_"+value.pool_id}
                                      onClick={()=>nodePoolCount(value.pool_id,'down')}
                                    >
                                      <i className="bi bi-dash fs-1" />
                                    </a>
                                    {/*end::Decrease control*/}
                                    {/*begin::Input control*/}
                                    <input
                                      type="text"
                                      className="form-control text-center"
                                      readOnly={true}
                                      id={"count_"+value.pool_id}
                                      name={"nodepools["+index+"][count]"}
                                      // name={"nodepools"+index+"count"}
                                      defaultValue={1}
                                      onChange={()=>nodePoolUpdate(value.pool_id)}
                                    />
                                    {/*end::Input control*/}
                                    {/*begin::Increase control*/}
                                    <a
                                      className="btn btn-icon btn-outline btn-outline-secondary"
                                      id={"upcount_"+value.pool_id}
                                      onClick={()=>nodePoolCount(value.pool_id,'up')}
                                    >
                                      <i className="bi bi-plus fs-1" />
                                    </a>
                                    {/*end::Increase control*/}
                                  </div>
                                  {/*end::Dialer*/}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div
                                    className="fw-bold fs-3 text-primary"
                                    id={"order_total_"+value.pool_id}
                                  >
                                    0.00
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <a 
                                  onClick={()=>deleteFormField(value.pool_id)}
                                  className="btn btn-icon  btn-sm"
                                >
                                  {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                                  <span className="svg-icon svg-icon-2 svg-icon-danger">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </span>
                                  {/*end::Svg Icon*/}
                                </a>
                              </td>
                            </tr>
                            </>)))}
                             
                          </tbody>
                          {/*end::Table body*/}
                        </table>
                        {/*end::Table*/}
                      </div>
                      {/*end::Table container*/}
                    </div>
                    {/*begin::Body*/}
                    {/*begin::Footer*/}
                    <div className="p-5 border-0 pt-5">
                      <a  id="addnodepool" onClick={addFormField} className="btn btn-primary btn-active-light-primary">
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                        <span className="svg-icon svg-icon-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="11.364"
                              y="20.364"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11.364 20.364)"
                              fill="black"
                            />
                            <rect
                              x="4.36396"
                              y="11.364"
                              width={16}
                              height={2}
                              rx={1}
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}Add
                      </a>
                    </div>
                    {/*end::Footer*/}
                  </form>
                </div>
                {/*begin::Footer*/}
                <div className="pt-2">
                  <a 
                    id="addnodepoolkubernetes-btn"
                    style={{ width: "100%" }}
                    onClick={submitNodePool}
                    className="btn btn-primary"
                  >
                    Add Node Pool +
                  </a>
                </div>
                {/*end::Footer*/}
                {/*end::Tables Widget 11*/}
              </div>
            </div>
          </div>
          <div className="separator my-10" />
          {/*begin::License usage*/}
          
               
              {kubernetesData!=null && (Object.keys(kubernetesData.nodepools).length!=0 && Object.entries(kubernetesData.nodepools).map((value,index)=>(
              <>
              <div className="card mb-5"> 
              <div className="card-header border-3 mb-5 pt-2">
              <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                    {value[0]} - {value[1].size}
                    </span>
                    
                  </h3> 
                <div
                  className="card-toolbar" 
                >
                  
                  <a
                    id={"update_pool_yes_"+value[0]}
                    onClick={()=>updatePool(value[0])}
                    className="btn btn-sm btn-primary btn-active-primary"
                  >
                    
                    <span className="svg-icon svg-icon-3">
                      
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="11.364"
                          y="20.364"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11.364 20.364)"
                          fill="black"
                        />
                        <rect
                          x="4.36396"
                          y="11.364"
                          width={16}
                          height={2}
                          rx={1}
                          fill="black"
                        />
                      </svg>
                    </span>
                    Scale Pool
                  </a>
                  <a  
                    id={"update_pool_no_"+value[0]}
                    onClick={()=>updatePool(value[0])}
                    className=" d-none btn btn-sm btn-danger btn-active-primary"
                  >
                    
                    <span className="svg-icon svg-icon-3">
                      
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="7.05025"
                          y="15.5356"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-45 7.05025 15.5356)"
                          fill="black"
                        />
                        <rect
                          x="8.46447"
                          y="7.05029"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(45 8.46447 7.05029)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    Close
                  </a>
                </div>
              </div>

              <div
                className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 d-none"
                id={"update_pool_"+value[0]}
              >
                
                <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                  
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M11.425 7.325C12.925 5.825 15.225 5.825 16.725 7.325C18.225 8.825 18.225 11.125 16.725 12.625C15.225 14.125 12.925 14.125 11.425 12.625C9.92501 11.225 9.92501 8.825 11.425 7.325ZM8.42501 4.325C5.32501 7.425 5.32501 12.525 8.42501 15.625C11.525 18.725 16.625 18.725 19.725 15.625C22.825 12.525 22.825 7.425 19.725 4.325C16.525 1.225 11.525 1.225 8.42501 4.325Z"
                      fill="black"
                    />
                    <path
                      d="M11.325 17.525C10.025 18.025 8.425 17.725 7.325 16.725C5.825 15.225 5.825 12.925 7.325 11.425C8.825 9.92498 11.125 9.92498 12.625 11.425C13.225 12.025 13.625 12.925 13.725 13.725C14.825 13.825 15.925 13.525 16.725 12.625C17.125 12.225 17.425 11.825 17.525 11.325C17.125 10.225 16.525 9.22498 15.625 8.42498C12.525 5.32498 7.425 5.32498 4.325 8.42498C1.225 11.525 1.225 16.625 4.325 19.725C7.425 22.825 12.525 22.825 15.625 19.725C16.325 19.025 16.925 18.225 17.225 17.325C15.425 18.125 13.225 18.225 11.325 17.525Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                  
                  <div className="mb-3 mb-md-0 fw-bold">
                    
                    <h4 className="text-gray-900 fw-bold">Update Nodes</h4>
                    <br />
                    <div className="fs-6 text-gray-700 pe-7 row">
                      
                      <form id={"update_pool_pool-"+value[0]} className="form ">
                        
                        <div className="row"> 
                          <div className="mb-10 col-lg-5 col-md-5 col-xxl-5 text-center">
                            
                            <label htmlFor="size" className=" form-label ">
                              Node Count
                            </label>
                            <div
                              className="input-group w-md-150px"
                              data-kt-dialer="true"
                            >
                              
                              <a
                                className={"btn btn-icon btn-outline btn-outline-secondary"}
                                 id={"node_count_down_"+value[0]}
                                 onClick={()=>updateNodeCount(value[0],"down",value[1].cost)}
                              >
                                <i className="bi bi-dash fs-1" />
                              </a>
                              <input
                                type="text"
                                className="form-control text-center"
                                readOnly={true}
                                id={"count_pool_node_"+value[0]}
                                name="count"
                                defaultValue={value[1].workers.length}
                              />
                              <a
                                className="btn btn-icon btn-outline btn-outline-secondary up_pool-tj2gsp74"
                                id={"node_count_up_"+value[0]}
                                onClick={()=>updateNodeCount(value[0],"up",value[1].cost)}
                              >
                                
                                <i className="bi bi-plus fs-1" />
                              </a>
                            </div>
                          </div>
                          <div className="mb-10 col-lg-5 col-md-5 col-xxl-5 text-center">
                            
                            <label htmlFor="size" className=" form-label">
                              Total cost
                            </label>
                            <div
                              className="fw-bold fs-3 text-primary mt-3"
                              id={"order_total_pool-"+value[0]}
                            >
                              0.00
                            </div>
                          </div>
                          <div className="mb-10 col-lg-2 col-md-2 col-xxl-2">
                            
                            <label
                              htmlFor="button"
                              className=" form-label"
                            />
                            <a 
                             className="btn btn-primary btn-sm mt-3 px-6 align-self-center text-nowrap"
                             onClick={()=>updatePoolList(value[0])}
                              >
                              Update
                            </a>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                
                <div className="table-responsive">
                  
                  <table
                    className="table  table-flush align-middle table-row-bordered table-row-solid gy-4"
                    id="kt_security_license_usage_table"
                  >
                    
                    <thead className="bg-secondary"> 
                      <tr className="fw-bold ">
                        
                        <th className="min-w-250px text-center">Name</th>
                        <th className="min-w-200px">Local IP</th>
                        <th className="min-w-200px" />
                        <th className="min-w-150px">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                       {value[1].workers.length>0 && (value[1].workers && value[1].workers.map((value2,index2)=>(
                      <tr>
                        <td className="fw-bold text-center">
                          <div className="  mt-1 fw-bold symbol symbol-45px me-2">
                            
                            {value2.hostname}
                          </div>
                        </td>
                        <td className="fw-bold">
                          <div className="  mt-1 symbol symbol-45px me-2">
                             {value2.ip}
                          </div>
                        </td>
                        <td>
                          <div
                            className="progress d-none"
                            id="action_process_cloud_84164"
                          />
                        </td>
                        <td className="fw-bold">
                          <div className=" mt-1 symbol symbol-45px me-2">
                            {value2.status=='Installed'?(<a className="btn btn-sm btn-success">  Active </a>):(<><a className="btn btn-sm btn-warning">  Pending </a></>)}
                          </div>
                        </td>
                      </tr>
                      )))}
                      
                    </tbody>
                  </table>
                </div>
              </div>
               </div>
              
              </>)))} 
          {/*end::License usage*/}
        </div>
        <div className="tab-pane fade" id="destroy" role="tabpanel">
          {/*begin::Deactivate Account*/}
          <div className="card">
            {/*begin::Card header*/}
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_deactivate"
              aria-expanded="true"
              aria-controls="kt_account_deactivate"
            >
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Destroy Kubernetes Cluster</h3>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Content*/}
            <div id="kt_account_settings_deactivate" className="collapse show">
              {/*begin::Form*/}
              {/*begin::Card body*/}
              <div className="card-body border-top p-9">
                {/*begin::Notice*/}
                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                  {/*begin::Icon*/}
                  {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                  <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.3"
                        x={2}
                        y={2}
                        width={20}
                        height={20}
                        rx={10}
                        fill="black"
                      />
                      <rect
                        x={11}
                        y={14}
                        width={7}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11 14)"
                        fill="black"
                      />
                      <rect
                        x={11}
                        y={17}
                        width={2}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11 17)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                  {/*end::Icon*/}
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-stack flex-grow-1">
                    {/*begin::Content*/}
                    <div className="fw-bold">
                      <h4 className="text-gray-900 fw-bold">
                        This will destroy Kubernetes Cluster.
                      </h4>
                      <div className="fs-6 text-gray-700">
                        Cluster will be permanently destroyed including all
                        worker nodes. You will lose the provisioned IP address.
                      </div>
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Notice*/}
                {/*begin::Form input row*/}
                <form id="destroyform" className="form">
                  <div className="mb-10">
                    <label htmlFor="size" className="required form-label">
                      Please fill text in below form to delete server "I am
                      aware this action will delete data and cluster
                      permanently"
                    </label>
                    <input
                      type="text"
                      className="form-control  "
                      name="confirm"
                      id="confirm"
                      defaultValue=""
                      placeholder="I am aware this action will delete data and cluster permanently"
                    />
                  </div>
                </form>
                {/*end::Form input row*/}
                {/*begin::Card footer*/}
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <button
                   onClick={destroyKubernetes}
                   className="btn btn-danger fw-bold"
                  >
                    Destroy Cluster
                  </button>
                </div>
                {/*end::Card footer*/}
              </div>
              {/*end::Card body*/}
              {/*end::Form*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Deactivate Account*/}
        </div>
      </div>
    </div>
  </div>
</div>


<div
  id="kubernetes-modal"
  className="bg-body shadow drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-name="chat"
  data-kt-drawer-activate="true"  
  data-kt-drawer-direction="end"   
>
  {/*begin::Messenger*/}
  <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
    {/*begin::Card header*/}
    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div className="d-flex justify-content-center flex-column me-3">
          <a
            
            className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
          >
            Choose plan for kubernetes
          </a>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Close*/}
        <div
          className="btn btn-sm btn-icon btn-active-light-primary"
          onClick={()=>openDrawer("close")}
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {/*end::Card header*/}
    {/*begin::Card body*/}
    <div className="" id="">
      {/*begin::Modal body*/}
      <div className="modal-body ">
        {/*begin::Col*/}
        <div className="col-xl-12">
          {/*begin::List widget 10*/}
          <div className="">
            {/*begin::Body*/}
            {/*begin::Nav*/}
            <ul className="nav nav-pills nav-pills-custom row position-relative mx-0 mb-9">
              {/*begin::Item*/}
              <li className="nav-item col-2 mx-0 p-0">
                {/*begin::Link*/}
                <a
                  className="nav-link active d-flex justify-content-center w-100 border-0 h-100"
                  data-bs-toggle="pill"
                  href="#basic-tab"
                >
                  {/*begin::Subtitle*/}
                  <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                    Basic
                  </span>
                  {/*end::Subtitle*/}
                  {/*begin::Bullet*/}
                  <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                  {/*end::Bullet*/}
                </a>
                {/*end::Link*/}
              </li>
              {/*end::Item*/}
              {/*begin::Item*/}
              <li className="nav-item col-2 mx-0 px-0">
                {/*begin::Link*/}
                <a
                  className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                  data-bs-toggle="pill"
                  href="#dedicated-standard-tab"
                >
                  {/*begin::Subtitle*/}
                  <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                    Dedicated Standard
                  </span>
                  {/*end::Subtitle*/}
                  {/*begin::Bullet*/}
                  <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                  {/*end::Bullet*/}
                </a>
                {/*end::Link*/}
              </li>
              {/*end::Item*/}
              {/*begin::Item*/}
              <li className="nav-item col-2 mx-0 px-0">
                {/*begin::Link*/}
                <a
                  className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                  data-bs-toggle="pill"
                  href="#dedicated-cpu-tab"
                >
                  {/*begin::Subtitle*/}
                  <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                    Dedicated CPU
                  </span>
                  {/*end::Subtitle*/}
                  {/*begin::Bullet*/}
                  <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                  {/*end::Bullet*/}
                </a>
                {/*end::Link*/}
              </li>
              {/*end::Item*/}
              {/*begin::Item*/}
              <li className="nav-item col-2 mx-0 px-0">
                {/*begin::Link*/}
                <a
                  className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                  data-bs-toggle="pill"
                  href="#dedicated-memory-tab"
                >
                  {/*begin::Subtitle*/}
                  <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                    Dedicated Memory
                  </span>
                  {/*end::Subtitle*/}
                  {/*begin::Bullet*/}
                  <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                  {/*end::Bullet*/}
                </a>
                {/*end::Link*/}
              </li>
              {/*end::Item*/}
              {/*begin::Bullet*/}
              <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded" />
              {/*end::Bullet*/}
            </ul>
            {/*end::Nav*/}
            {/*begin::Tab Content*/}
            <div className="tab-content">
              {/*begin::Tap pane*/}
              <div className="tab-pane fade show active" id="basic-tab">
                {/*begin::Radio group*/}
                <div className="row" data-kt-buttons="true">
                  <div className="table-responsive" id="ramcpuplans">
                    {/*begin::Table*/}
                    <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                      {/*begin::Thead*/}
                      <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                        <tr> 
                          <th className="min-w-125px px-0 text-center">RAM</th>
                          <th className="min-w-125px">vCPU</th>
                          <th className="min-w-125px">SSD Disk</th>
                          <th className="min-w-125px">Bandwidth</th> 
                          <th className="min-w-125px ps-0 text-start">
                            Price
                          </th>
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody
                        id="basic-plan"
                        className="fs-6 fw-bold text-gray-600"
                      >
                        {planData!=null && (planData && planData.map((value,index)=>(
                          <>
                          {value.slug=='basic'?(<>
                            <tr class="planseffect" onClick={()=>selectNodeSize(value.id,value.ram,value.cpu,value.disk,value.bandwidth,parseFloat(value.price/720).toFixed(2))}>
                              <td class="ps-0 text-center">{value.ram} MB <input type="hidden" id={"plan_field_row_"+value.id}/></td> 
                              <td>{value.cpu} vCPU</td> 
                              <td>{value.disk} GB</td> 
                              <td>{value.bandwidth} GB</td> 
                              <td>
                                <span class="badge badge-light-success fs-7 fw-bolder  me-3">{user_data.current!=null?(user_data.current.user.currencyprefix):('')} {parseFloat(value.price/720).toFixed(2)}/hourly</span>
                                {value.free_availabel==1?(
                                  <span class="badge badge-warning fs-base">Free {value.free_days} days</span>
                                ):('')}
                                
                              </td>
                            </tr>
                            </>):('')}
                          </>
                        )))}
                         
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                {/*end::Radio group*/}
              </div>
              {/*end::Tap pane*/}
              {/*begin::Tap pane*/}
              <div className="tab-pane fade" id="dedicated-standard-tab">
                {/*begin::Radio group*/}
                <div className="row" data-kt-buttons="true">
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                      {/*begin::Thead*/}
                      <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr> 
                          <th className="min-w-125px px-0 text-center">RAM</th>
                          <th className="min-w-125px">vCPU</th>
                          <th className="min-w-125px">SSD Disk</th>
                          <th className="min-w-125px">Bandwidth</th> 
                          <th className="min-w-125px ps-0 text-start">
                            Price
                          </th>
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody
                        id="standard-plan"
                        className="fs-6 fw-bold text-gray-600"
                      >
                        {planData!=null && (planData && planData.map((value,index)=>(
                          <>
                          {value.slug=='dedicated-standard'?(<>
                            <tr class="planseffect" onClick={()=>selectNodeSize(value.id,value.ram,value.cpu,value.disk,value.bandwidth,parseFloat(value.price/720).toFixed(2))}>
                              <td class="ps-0 text-center">{value.ram} MB</td> 
                              <td>{value.cpu} vCPU</td> 
                              <td>{value.disk} GB</td> 
                              <td>{value.bandwidth} GB</td> 
                              <td>
                                <span class="badge badge-light-success fs-7 fw-bolder  me-3">{user_data.current!=null?(user_data.current.user.currencyprefix):('')} {parseFloat(value.price/720).toFixed(2)}/hourly</span>
                                {value.free_availabel==1?(
                                  <span class="badge badge-warning fs-base">Free {value.free_days} days</span>
                                ):('')}
                                
                              </td>
                            </tr>
                            </>):('')}
                          </>
                        )))}
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                {/*end::Radio group*/}
              </div>
              {/*end::Tap pane*/}
              {/*begin::Tap pane*/}
              <div className="tab-pane fade" id="dedicated-memory-tab">
                {/*begin::Radio group*/}
                <div className="row" data-kt-buttons="true">
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                      {/*begin::Thead*/}
                      <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr> 
                          <th className="min-w-125px px-0 text-center">RAM</th>
                          <th className="min-w-125px">vCPU</th>
                          <th className="min-w-125px">SSD Disk</th>
                          <th className="min-w-125px">Bandwidth</th> 
                          <th className="min-w-125px ps-0 text-start">
                            Price
                          </th>
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody
                        id="memory-plan"
                        className="fs-6 fw-bold text-gray-600"
                      >
                        {planData!=null && (planData && planData.map((value,index)=>(
                          <>
                          {value.slug=='dedicated-cpu'?(<>
                            <tr class="planseffect" onClick={()=>selectNodeSize(value.id,value.ram,value.cpu,value.disk,value.bandwidth,parseFloat(value.price/720).toFixed(2))}>
                              <td class="ps-0 text-center">{value.ram} MB</td> 
                              <td>{value.cpu} vCPU</td> 
                              <td>{value.disk} GB</td> 
                              <td>{value.bandwidth} GB</td> 
                              <td>
                                <span class="badge badge-light-success fs-7 fw-bolder  me-3">{user_data.current!=null?(user_data.current.user.currencyprefix):('')} {parseFloat(value.price/720).toFixed(2)}/hourly</span>
                                {value.free_availabel==1?(
                                  <span class="badge badge-warning fs-base">Free {value.free_days} days</span>
                                ):('')}
                                
                              </td>
                            </tr>
                            </>):('')}
                          </>
                        )))}
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                {/*end::Radio group*/}
              </div>
              {/*end::Tap pane*/}
              {/*begin::Tap pane*/}
              <div className="tab-pane fade" id="dedicated-cpu-tab">
                {/*begin::Radio group*/}
                <div className="row" data-kt-buttons="true">
                  <div className="table-responsive" id="ramcpuplans">
                    {/*begin::Table*/}
                    <table className="table table-row-bordered table-flush align-middle gy-6 tableramcpu">
                      {/*begin::Thead*/}
                      <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr> 
                          <th className="min-w-125px px-0 text-center">RAM</th>
                          <th className="min-w-125px">vCPU</th>
                          <th className="min-w-125px">SSD Disk</th>
                          <th className="min-w-125px">Bandwidth</th> 
                          <th className="min-w-125px ps-0 text-start">
                            Price
                          </th>
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody
                        id="cpu-plan"
                        className="fs-6 fw-bold text-gray-600"
                      >
                        {planData!=null && (planData && planData.map((value,index)=>(
                          <>
                          {value.slug=='dedicated-memory'?(<>
                            <tr class="planseffect" onClick={()=>selectNodeSize(value.id,value.ram,value.cpu,value.disk,value.bandwidth,parseFloat(value.price/720).toFixed(2))}>
                              <td class="ps-0 text-center">{value.ram} MB</td> 
                              <td>{value.cpu} vCPU</td> 
                              <td>{value.disk} GB</td> 
                              <td>{value.bandwidth} GB</td> 
                              <td>
                                <span class="badge badge-light-success fs-7 fw-bolder  me-3">{user_data.current!=null?(user_data.current.user.currencyprefix):('')} {parseFloat(value.price/720).toFixed(2)}/hourly</span>
                                {value.free_availabel==1?(
                                  <span class="badge badge-warning fs-base">Free {value.free_days} days</span>
                                ):('')}
                                
                              </td>
                            </tr>
                            </>):('')}
                          </>
                        )))}
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                {/*end::Radio group*/}
              </div>
              {/*end::Tap pane*/}
            </div>
            {/*end::Tab Content*/}
          </div>
          {/*end: Card Body*/}
          {/*end::List widget 10*/}
        </div>
        {/*end::Col*/}
      </div>
      {/*end::Modal body*/}
      {/*begin::Modal footer*/}
      {/* <div class="modal-footer ">
              <button type="button" id="importkey-btn"
                  onclick="call_api('POST','key/import','importkey','getDeployAPI')" class="btn btn-primary">
                  <span class="indicator-label">Import SSH Key</span>
              </button>
          </div> */}
      {/*end::Modal footer*/}
    </div>
    {/*end::Card body*/}
  </div>
  {/*end::Messenger*/}
</div>
</>):(<><div class="loading-spinner2"></div></>)}
        </>
    )
}

const mapStateToProps=state=>({
  nodePoolData:state.kubernetes.nodePoolRow
})

const mapDispatchToProps=dispatch=>({
  updateRow:data=>dispatch(UpdateNodePoolRow(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(ManageKubernetes) 