import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from "../../../../CustomHooks/useApi";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert"; 
import useRazorpay from "react-razorpay"; 
import UsePostData from "../../../../CustomHooks/usePostData";
import spinner from "../billing/spinner.svg"; 
import UseDevPost from "../../../../CustomHooks/useDevPost";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const DeployObjectStorage = (props) => { 
  const forceUpdate = useForceUpdate();
  const [adcdata, setAdcdata] = useState([]);
  const [ddcdata, setDdcdata] = useState([]); 
  const dataFetchedRef2 = useRef();
  const navigate = useNavigate(); 
  const [planData, setPlanData] = useState(null); 
  const [userData, setUserData] = useState(null); 
  const user_data = useRef(null);
  const dczoneStatus = useRef(true);

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result.toLowerCase();
  };
  const getDczone = async () => {
    const data = await UseApi("get", "dczones", "");
    user_data.current = await UseApi("get", "account/info", "");
    setUserData(user_data.current.user);
    if (Object.keys(data).length === 0) {
      setAdcdata([]);
      setDdcdata([]);
    } else {
      if (dczoneStatus.current) {
        data.dczones.map(async (value, index) => {
              if(value.slug=="innoida"){
          if (value.status !== "active") {
            fetch("https://api.ipify.org?format=json")
              .then((response) => response.json())
              .then((data) => {
                var server_ip = data.ip;
                if (server_ip) {
                  if (
                    server_ip !== "103.209.144.51" ||
                    server_ip !== "103.209.144.66" ||
                    server_ip !== "103.209.145.208" ||
                    server_ip !== "103.209.144.81" ||
                    server_ip !== "103.209.144.82" ||
                    server_ip !== "103.209.144.83" ||
                    server_ip !== "103.127.30.91" ||
                    server_ip !== "103.209.144.60" ||
                    server_ip !== "103.127.30.239" ||
                    server_ip !== "103.127.28.52"
                  ) {
                    ddcdata.push(value);
                  }
                  forceUpdate();
                }
              })
              .catch((error) => console.error(error));
          } else {
            adcdata.push(value);
            forceUpdate();
          }
        }
        });
        dczoneStatus.current = false;
      }
    }
  };

  function removeDuplicates(data, key) {
    const uniqueEntries = [];
    const seenKeys = new Set();
  
    data.forEach(entry => {
      const keyValue = entry[key];
  
      if (!seenKeys.has(keyValue)) {
        seenKeys.add(keyValue);
        uniqueEntries.push(entry);
      }
    });
  
    return uniqueEntries;
  }
  const getPlans = async () => {
    const data = await UseApi("get", "pricing/objectstorage", "");  
    // console.log(data);
    if(data.rcode=="success"){
      var arr=data.pricing;
      var modifyPlanData=removeDuplicates(arr, "name");
       setPlanData(modifyPlanData);
    }else{
      setPlanData(null);
    }
  };
  useEffect(() => {
    if (dataFetchedRef2.current) return;
    dataFetchedRef2.current = true;
    getDczone();
    getPlans();
  }, []);
 
 

  const selectDc = async (slug) => {
    var dcSelect = document.querySelectorAll([".tickMark"]);
    var currentDc = document.querySelector("#tickMark-" + slug);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }
   
  }; 

  const isDcSelected = () => {
    const selectedRadio = document.querySelector('input[name="dcslug"]:checked');
    return selectedRadio !== null;
};
  const addObjectStorage=async()=>{ 
    if(!isDcSelected()){
      const dt=toast.loading("Please wait..."); 
      toast.update(dt,{render: "Please select data center location", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    }); 
    return;
    }
    
    var submitBtn=document.querySelector("#object-btn");  
    if(submitBtn){ 
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
    const dt=toast.loading("Please wait..."); 
    const responseData=await UsePost('post','objectstorage/bucket/create','objectdeploy');
    if(responseData){   
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      
      navigate("/objectStorage");
    }
  }
      }
    }
  
    function checkInput(val){
      var db_node_count=document.querySelector("#obj_size");
      if(db_node_count){
      if(val<250){
        const dt=toast.loading("Please wait..."); 
        toast.update(dt,{render: "Node count must be minimum 250 GB!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      db_node_count.value=250;
      }
      }
    }

    const selectPlan=(planid, price, billing)=>{
      //unselect all plans 
      var planLabels=document.querySelectorAll(".tick-parent"); 
      var PlansTicks=document.querySelectorAll(".tickMarkPlan"); 
      planLabels.forEach((lfield)=>{
          lfield.classList.remove("plan_list_active");
      })
      PlansTicks.forEach((tfield)=>{
          tfield.classList.add("d-none");
       })
  
      //select the specific plan
  
      var activeLabels=document.querySelector("#tick_parent_"+planid); 
      var activePlansTicks=document.querySelector("#tickMarkPlan-"+planid);
      if(activeLabels && activePlansTicks){
          activeLabels.classList.add("plan_list_active");
          activePlansTicks.classList.remove("d-none");
      }  
      
      var plan_cost=document.querySelector("#plan_cost");
      var plan_value=document.querySelector("#plan_value");
      var billing_info=document.querySelector("#billing_info"); 
      var total_cost=document.querySelector("#total_cost");
      if(plan_cost && plan_value && billing_info){
        plan_cost.value=parseInt(price);
        total_cost.innerHTML=parseInt(price);
        plan_value.value=planid;
        billing_info.value=billing;
      }
  }

  return (
    <>   
    {adcdata.length>0?(<>
          <form id="objectdeploy">
            {/*begin::Tables Widget 9*/}
            <div className="col-xl-12 card mb-5">
              {/*begin::Header*/}
              <div className="card-header border-3 mb-5 pt-2">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800 mb-1">
                    Select DC Location
                  </span>
                  <span className="text-muted mt-1 fw-bold fs-7">
                    Data Centeres
                  </span>
                </h3>
              </div>
              {/*end::Header*/}
              {/* Begin body */}
              <div className="container pb-5">
                {/*begin::Row*/}
                <div
                  id="dc-location"
                  className="row g-7"
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >
                  {adcdata.length > 0
                    ? adcdata &&
                      adcdata.map((value, index) => (
                        <>
                          <div className="col-md-4 col-lg-4 col-sm-6 col-xxl-4 dc_sibling">
                            <input
                              className="form-check-input deploy_checkbox "
                              type="radio"
                              name="dcslug"
                              defaultValue={value.slug}
                              id={"dcslug" + value.slug + ""}
                            />

                            <label
                              onClick={() => selectDc(value.slug)}
                              style={{ WebkitJustifyContent: "unset" }}
                              id={"dcslugl_" + value.slug + ""}
                              htmlFor={"dcslug" + value.slug + ""}
                              className="position-relative uptrans dcsluglable border-1 border-gray-300 border px-1 py-1"
                            >
                              <div className=" child">
                                <div className="p-3 ">
                                  <div className="row">
                                    <div className="col-4">
                                      <img
                                        src={
                                          "/assets/media/flags/svg/" +
                                          value.cc +
                                          ".svg"
                                        }
                                        className="w-50px "
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-8">
                                      <p className="no-margin text-dark fw-bolder text-hover-primary ">
                                        {value.city}
                                        <span className="text-muted d-block fw-bold ">
                                          {value.country}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <span className="d-none child position-absolute top-0 start-100 translate-middle  badge badge-circle badge-primary">
                                5
                              </span>
                              {/*begin::Ribbon*/}
                              <div
                                className="d-none tickMark ribbon ribbon-triangle ribbon-top-start border-primary"
                                id={"tickMark-" + value.slug}
                              >
                                {/*begin::Ribbon icon*/}
                                <div className="ribbon-icon mt-n5">
                                  <i className="bi bi-check2 fs-2 text-white" />
                                </div>
                                {/*end::Ribbon icon*/}
                              </div>
                              {/*end::Ribbon*/}
                            </label>
                          </div>
                        </>
                      ))
                    : (<><div className="small-spinner"></div></>)}
                  {ddcdata.length > 0
                    ? !ddcdata &&
                      ddcdata.map((value, index) => (
                        <>
                          <div className="col-md-4 col-lg-4 col-sm-6 col-xxl-4 dc_sibling">
                            <input
                              className="form-check-input deploy_checkbox deploylocationsoldout"
                              type="radio"
                              name="dcslug"
                              onClick={(e) => (e.target.checked = false)}
                              defaultValue={value.slug}
                              id={"dcslug" + value.slug + ""}
                            />
                            <label
                              onClick={() => selectDc(value.slug)}
                              style={{ WebkitJustifyContent: "unset" }}
                              id={"dcslugl_" + value.slug + ""}
                              htmlFor={"dcslug" + value.slug + ""}
                              className="uptrans dcsluglable border-1 border-gray-300 border px-1 py-1"
                            >
                              <div className="child">
                                <div className="p-3 ">
                                  <div className="row">
                                    <div className="col-4">
                                      <img
                                        src={
                                          "/assets/media/flags/svg/" +
                                          value.cc +
                                          ".svg"
                                        }
                                        className="w-50px "
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-8">
                                      <p className="no-margin text-dark fw-bolder text-hover-primary ">
                                        {value.city}
                                        <span className="text-muted d-block fw-bold ">
                                          {value.country}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
 
                            </label>
                          </div>
                        </>
                      ))
                    : (<></>)}
                </div>
                {/*end::Row*/}
              </div>
              {/* End body */}
               </div>

              {/*begin::Col*/}
              <div className="col-xl-12 card mb-5 d-none">
                {/*begin::List widget 10*/}
                
                <div className="card-header border-3 mb-5 pt-2">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Select storage size
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                      Storage size as per your server need
                    </span>
                  </h3>
                </div>
               <div className="container pb-5">
                <div 
  className="row g-9"
  data-kt-buttons="true"
  data-kt-buttons-target="[data-kt-button]"
>
  <div className="col-md-4 col-lg-4 col-xxl-4">
    <div className=""> 
      {/*begin::Input group*/}
  <div className="input-group mb-5">
    <input
      type="number" 
      min={1}
      id="obj_size"
      className="form-control text-center"
      onChange={(e)=>checkInput(e.target.value)}
      defaultValue={250}
    />
    <span className="input-group-text" id="basic-addon2">
     GB
    </span>
  </div>
  {/*end::Input group*/}
    </div>
  </div> 
</div>
</div>
</div>
                  
              {/*end::Col*/} 

              <div className="col-xl-12 card mb-5">
                <div className="card-header border-3 mb-5 pt-2">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Select Plan Type
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                      Object Storage plans
                    </span>
                  </h3>
                </div> 
                 
                {/*end::Nav*/}
                <input
                  type="hidden"  
                  name="billing"
                  id="billing_info"
                  defaultValue=""
                />
                <input
                  type="hidden" 
                  name="size"
                  id="plan_value"
                  defaultValue=""
                />
                <input
                  type="hidden"
                  name="price"
                  id="plan_cost"
                  defaultValue="0"
                />

                {/* Horizontal PLAN START*/} 
                <div className="row mt-5" data-kt-buttons="true">
                  <div className="table-responsive" id="ramcpuplans">
                    {/*begin::Table*/}
                    <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                      {/*begin::Thead*/}
                      <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                        <tr>
                          <th className="w-25 px-0 text-center">Size</th>  
                          <th className="w-25 ps-0 text-center"> Type </th>
                          <th className="w-20 ps-0 text-center">  Price  </th>
                          <th className="w-20 ps-0 text-center">  </th>
                          <th className="w-20 ps-0 text-center" />
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody className="fs-6 fw-bold text-gray-600">
                        {planData != null &&
                          planData?(<>{
                          planData.map((value, index) => (
                            <>
                              {value.type == "objectstorage" ? (
                                <>
                                  <tr
                                    onClick={(e)=>selectPlan(value.id, value.price, "monthly")}
                                    className="planseffect tick-parent"
                                    id={"tick_parent_" + value.id}
                                  >
                                    <td className="ps-0 text-center">
                                       {value.name}
                                    </td>
                                    <td className="text-center"> 
                                          <span
                                            className="badge badge-light-success fs-7 fw-bolder"
                                            style={{ marginRight: 5 }} > 
                                            Monthly
                                          </span>  
                                    </td>
                                    <td className="text-center"> 
                                          <span
                                            className="badge badge-light-primary fs-7 fw-bolder"
                                            style={{ marginRight: 5 }}
                                            id={"plan_price_" + value.id} >
                                            {userData != null ? (<>{userData.currencyprefix}</>) : ("")}
                                            {value.price}
                                          </span>  
                                    </td> 
                                    <td className="text-center">
                                      <a className="btn btn-sm btn-primary sphide"> Choose Plan 
                                      <span className="svg-icon svg-icon-2">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
      fill="black"
    />
    <path
      opacity="0.3"
      d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
      fill="black"
    />
  </svg>
</span>

                                      </a>
                                       </td>
                                    <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                      <div
                                        className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                        id={"tickMarkPlan-" + value.id}
                                      >
                                        <div className="ribbon-icon mt-n5">
                                          <i className="bi bi-check2 fs-2 text-white" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                         
                         ))}</>):(<><td colspan="12"><div className="small-spinner"></div></td></>)}
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                </div>  
                {/* Horizontal PLAN START*/} 

      {/*begin::Col*/}
      <div className="col-xl-12 card mb-5">
                {/*begin::List widget 10*/}
                
                <div className="card-header border-3 mb-5 pt-2">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Bucket Name
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                      Input the bucket name
                    </span>
                  </h3>
                </div>
  <div className="container pb-5">             
                <div 
  className="row g-9"
  data-kt-buttons="true"
  data-kt-buttons-target="[data-kt-button]"
>
  <div className="col-md-4 col-lg-4 col-xxl-4">
    <div className=""> 
      {/*begin::Input group*/}
  <div className="input-group mb-5">
    <input
      type="text"
      name="name"
      className="form-control"  
      placeholder="storage_1"
      defaultValue={"mybucket"+makeid(8)+makeid(8)}
    /> 
  </div>
  {/*end::Input group*/}
    </div>
  </div> 
</div>

                 
              </div>
              </div>
              {/*end::Col*/} 

              <div className="col-xl-12 card mb-5">
      <div className="card-header  mb-5 pt-2" style={{border:"none"}}>
  <h3 className="card-title align-items-start flex-column">
    <span className="card-label fw-bold fs-3 mb-1">
      Total Cost
    </span>
    <span className="fs-xl-2x fs-2 fw-boldest">
    <span>{userData != null ? (<>{userData.currencyprefix}</>) : ("")}</span>
    <span id="total_cost">0</span>&nbsp; /mon </span>
    <span className="text-muted mt-1 fw-bold fs-7" />
  </h3>
  <div className="card-toolbar">  
    <a   style={{ width: "100%" }}
                 className="btn  btn-primary btn-active-light-primary"  id="object-btn" onClick={addObjectStorage}>
                  Create Storage &nbsp; &nbsp;
      <span className="svg-icon svg-icon-3">
      <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
      fill="black"
    />
    <path
      opacity="0.3"
      d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
      fill="black"
    />
  </svg>
      </span>
    </a>
  </div>
</div>
</div>  
            {/*end::Tables Widget 9*/}
          </form> 
          </>):(<><TopBarHeader /></>)}
    </>
  );
};
 
export default DeployObjectStorage;
