import UseDevCallApi from "./useDevCallApi";
var serialize = require('form-serialize');

export default async function UseDevPost(type, url, formid) {
  var form = document.querySelector('#' + formid);
 
  // var requestData = serialize(form, { hash: true });
  var requestData = new FormData(form);
  const temp = await UseDevCallApi(type, url, requestData);
  console.log(requestData);
  return temp;
}
