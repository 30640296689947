import { ACTIVITY_PAGINATION} from "../../../../Redux/Services/constants"

 
const initialState={  
   activityPaginationInfo:[{currentPage:1,perpage:10}]
 }

export default function ActivityReducer(state=initialState,action){


   switch (action.type) {
       case ACTIVITY_PAGINATION: 
           return {...state, activityPaginationInfo:action.data}         
       default:
           return state 
   }
}



