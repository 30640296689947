import localStorage from "local-storage";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../../CustomHooks/usePost";
import UseLogin from "../../../../../CustomHooks/useLogin";
import axios from "axios";
import "./custom.css";
import MobileVerification from "../steps/MobileVerification";
var serialize=require('form-serialize');
export default function AccountVerifyMobile(){
  const navigate=useNavigate();
  const [app_info, setApp_info] = useState(null);
  const [logo_white, setLogo_white] = useState(null);
  async function currApp(){ 
    const AppData=await axios.get('https://api.utho.com/v2/appinfo');
    if(AppData && AppData.data){
      //console.log("data come");
    setApp_info(AppData.data);
    //console.log(AppData);
   var siteUrlString=AppData.data.site;
   
   var siteUrlArr=siteUrlString.split(".");
    var siteLength=siteUrlArr.length;

   var siteOrg="https://api."+siteUrlArr[siteLength-2]+"."+siteUrlArr[siteLength-1]; 
   localStorage.set("filesUrl",siteOrg);
   var whiteLogo=siteOrg+"/publicfiles/"+AppData.data.logo_light;
   setLogo_white(whiteLogo); 
  }
}
  const getOTP=async()=>{
    var submitBtn=document.querySelector("#otp-btn");
      if(submitBtn){
        var preContent=submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';  
    const dt=toast.loading("Please wait...");
    const responseData=await UseLogin('post','account/loginotp','loginotp');
      if(responseData){
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML=preContent;
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else{
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      }); 
      var form=document.querySelector('#loginotp');
      var requestData=serialize(form,{hash:true});
      localStorage.set("loginEmail",requestData.email);
      navigate("/verify-otp");
      // const location_url = window.location.origin;
      //   window.location.href = location_url+'/verify-otp'; 

      //setIsVerified(true);
      }
    }
  }
  } 
  useEffect(() => {
    currApp();
    window.addEventListener("keydown", function(event) { 
      event.stopPropagation();
      var query = window.location.href; 
      var vars = query.split("/"); 
      var action=vars[3]; 
      if(event.key=="Enter" && action=="login-with-otp"){
      getOTP();
    }
  });
  },[]);

    return(
        <> 
        <div className=" d-flex flex-row flex-column-fluid loginbg">
        <div
  className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative  d-none d-lg-block d-md-block d-xxl-block"
  >
      {/*begin::Wrapper*/}
    <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
      
    </div>
</div>

<div className="d-flex flex-column flex-lg-row-fluid py-10 top-front">
 
  {/*begin::Content*/}
  <div className="text-center">
    {/*begin::Wrapper*/} 
    <MobileVerification /> 
    
    {/*end::Wrapper*/}
  </div>
  {/*end::Content*/}
  
</div>
</div>

        </>
    )
}