import localStorage from 'local-storage';
import React from 'react'; 
import { useEffect } from 'react';
import { useState } from 'react';
import UseApi from '../../../CustomHooks/useApi';
import UsePostData from '../../../CustomHooks/usePostData';
import CallAppinfo from '../../../CustomHooks/CallAppinfo';
import Cookies from 'js-cookie';

const SwitchAccount = () => {
  var current_url_string = window.location.href;
  const current_url = new URL(current_url_string);
  const current_domain = current_url.hostname;
    const [subusers,setSubusers]=useState(null);
    
    const getSubusers=async()=>{
        const responseData=await UseApi('get','amaccess','');
        if(responseData && responseData.status=="success"){
          var selfAccount=true;
          var myAccount=null;
          responseData.accountaccess.map((value,index)=>{
            if(value.type=="self"){
             selfAccount=false;
             myAccount=value;
            }
          });
             //console.log(myAccount);
             //console.log(responseData.accountaccess);
            if(selfAccount){
               setSubusers(responseData.accountaccess);
            }else{
              setSubusers([myAccount]);
            }
           
        }else{
            setSubusers(null);
        }
        //console.log(responseData);
    }
    useEffect(()=>{
       getSubusers();
    },[])

     const switchTo=async(accountId, type)=>{
        // alert(accountId+" - "+type);
       if(type=="self"){
        const responseData=await UsePostData('POST','switchaccessself/'+accountId,{});
        if(responseData && responseData.status=="loginsuccess"){
             localStorage.clear();
            //  document.cookie = "microhost_api_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            const cookieNames = Object.keys(Cookies.get());
            const domain = '.'+current_domain; // Specify the domain
        
          // Remove each cookie with the specified domain
          cookieNames.forEach((cookieName) => {
          Cookies.remove(cookieName, { domain, path: '/' }); // Make sure to include the path
        });
            //  localStorage.set("Auth",responseData.token);
            setTimeout(async()=>{
             Cookies.set('microhost_api_auth', responseData.token, { expires:30, path:"/", domain: "."+current_domain });
            //  const now = new Date();
            //  const expires = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days
            //  document.cookie = "microhost_api_auth="+responseData.token+"; expires=" + expires.toUTCString() + "; path=/"; 
             const data=await CallAppinfo();
                const location_url = window.location.origin;
                window.location.href = location_url; 
              })
        }
       }else{
        const responseData=await UsePostData('POST','switchaccess/'+accountId,{});
        //console.log(responseData);
        if(responseData && responseData.status=="loginsuccess"){
          localStorage.clear();
          //  document.cookie = "microhost_api_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          const cookieNames = Object.keys(Cookies.get());
          const domain = '.'+current_domain; // Specify the domain
      
        // Remove each cookie with the specified domain
        cookieNames.forEach((cookieName) => {
        Cookies.remove(cookieName, { domain, path: '/' }); // Make sure to include the path
      });
      cookieNames.forEach((cookieName) => {
        Cookies.remove(cookieName, { current_domain, path: '/' }); // Make sure to include the path
      });
          //  localStorage.set("Auth",responseData.token);
          setTimeout(async()=>{
           Cookies.set('microhost_api_auth', responseData.token, { expires:30, path:"/", domain: "."+current_domain });
          //  const now = new Date();
          //  const expires = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days
          //  document.cookie = "microhost_api_auth="+responseData.token+"; expires=" + expires.toUTCString() + "; path=/"; 
           const data=await CallAppinfo();
              const location_url = window.location.origin;
              window.location.href = location_url; 
            })
       }
       }
       

     }
    return (
        <>  
        {subusers!=null?(<>
                        <div
  data-kt-menu-trigger="click"
  data-kt-menu-placement="bottom-start"
  className="menu-item here show  menu-lg-down-accordion me-lg-1"
>
  <span className="menu-link py-3 btn btn-sm ">
    <span className="text-primary">Switch Account</span>
    <span className="svg-icon svg-icon-primary svg-icon-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect
          opacity="0.3"
          width={12}
          height={2}
          rx={1}
          transform="matrix(-1 0 0 1 15.5 11)"
          fill="black"
        />
        <path
          d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z"
          fill="black"
        />
        <path
          d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z"
          fill="#C4C4C4"
        />
      </svg>
    </span>
  </span>
  <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
    


    {subusers.map((value,index)=>(
        <> 
          {value.status=='Active'?(<>
         {value.type=='self'?(<>
<div className="menu-item">
<a className="menu-link  py-3" onClick={()=>switchTo(value.id,value.type)} >
  <span className="menu-icon"> 
    <span className="svg-icon svg-icon-primary svg-icon-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          opacity="0.3"
          d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
          fill="black"
        />
        <path
          d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
          fill="black"
        />
      </svg>
    </span> 
  </span>
  <span className="menu-title">{value.fullname}</span>
  </a>
</div></>):(<>
<div className="menu-item">
<a className="menu-link  py-3" onClick={()=>switchTo(value.id,value.type)} >
  <span className="menu-icon"> 
    <span className="svg-icon svg-icon-primary svg-icon-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          opacity="0.3"
          d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
          fill="black"
        />
        <path
          d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
          fill="black"
        />
      </svg>
    </span> 
  </span>
  <span className="menu-title">{value.fullname}</span>
  </a>
</div>
         
         </>)}</>):('')} 
         </>
    ))} 
  </div>
</div>
</>):('')}
        </>
        
    );
};
 
export default SwitchAccount;
