import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IsCloudUpdate } from "../../../../../Redux/Services/Action/actions";   
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import swal from 'sweetalert';
import UseApi from "../../../../../CustomHooks/useApi";
import UsePost from "../../../../../CustomHooks/usePost";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
const Iso=(props)=>{
  const navigate=useNavigate();
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4];    
  const [isoList,setIsoList]=useState(null);  
  const [pageStatus,setPageStatus] = useState(false)
  const dcLocation=useRef();
  const dispatch=useDispatch(); 
  
  const navRedirect=()=>{
    setTimeout(() => {
      navigate("/cloud/"+id);
     }, 3000);  
  }
  const getIso=async()=>{ 
    
       const data=await UseApi('get','iso','');
       if(Object.keys(data).length === 0){
        setIsoList(null);  
       }else{ 
        delete(data.html); 
        //////console.log(data.isos);
        setIsoList(data.isos);
        if(props.cloudData.dclocation){
          dcLocation.current=props.cloudData.dclocation.dc;
        }
       }
  }
  useEffect(() => {  
  getIso();
  }, [props.cloudData]);  

  const unmountIso=async()=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this ISO!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        setPageStatus(true)
        const dt=toast.loading("ISO file "+props.cloudData.iso+" deleting..."); 
    const responseData=await UsePost('POST','cloud/'+id+'/umountiso','unmountiso'); 
    if(responseData.status==='error'){
      setPageStatus(false)
      toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });  
    }else if(responseData.status==='success'){
      setPageStatus(false)
      toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    }); 
    const data=await UseApi('get','cloud/'+id,'');
    dispatch(props.updateCloudStatus(data.cloud[0]));
    navRedirect();
    }
      }else {
        swal({
            title: "ISO",
            text: "Your ISO is safe!",
            icon: "success",
            button: "OK",
          });
      }
      });
  }

  const addIso=async ()=>{ 
    toast.dismiss();
    const iso_selects=document.querySelector("#iso_selects");
    if(iso_selects){
      if(iso_selects.value==""){
        const dt=toast.loading("ISO adding, Please wait...");
        toast.update(dt,{render: "Please select an iso to mount", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      }); 
    }else{
    swal({
    title:"Are you sure?",
    text: "Do you really want to add iso in your cloud!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
    })
    .then(async(willDelete) => {
    if(willDelete) {
      setPageStatus(true)
      const dt=toast.loading("ISO adding, Please wait...");
      const responseData=await UsePost('post','cloud/'+id+'/mountiso','cloudisoboot'); 
       // ////console.log(responseData);
        if(responseData.status==='error'){
          setPageStatus(false)
          toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });  
        }else if(responseData.status==='success'){
          setPageStatus(false)
          toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        }); 
        const data=await UseApi('get','cloud/'+id,'');
        dispatch(props.updateCloudStatus(data.cloud[0]));
        navRedirect();
        }
    }else {
      swal({
          title: "ISO",
          text: "ISO not add in your cloud!",
          icon: "success",
          button: "OK",
        });
    }
    });
  }
}
   }
    return(
        <>

            <div className="card">
      {/*begin::Card header*/}
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_deactivate"
        aria-expanded="true"
        aria-controls="kt_account_deactivate"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Boot from ISO</h3>
        </div>
      </div>
      {/*end::Card header*/}
      {/*begin::Content*/}
      <div id="kt_account_settings_deactivate" className="collapse show">
        {/*begin::Form*/}
        <form id="cloudisoboot" className="form">
          {/*begin::Card body*/}
          <div className="card-body border-top p-9">
            {/*begin::Notice*/}
            <div className="notice d-flex bg-light-primary rounded border-primary border mb-9 p-6">
              {/*begin::Icon*/}
              {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
              <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.3"
                    x={2}
                    y={2}
                    width={20}
                    height={20}
                    rx={10}
                    fill="black"
                  />
                  <rect
                    x={11}
                    y={14}
                    width={7}
                    height={2}
                    rx={1}
                    transform="rotate(-90 11 14)"
                    fill="black"
                  />
                  <rect
                    x={11}
                    y={17}
                    width={2}
                    height={2}
                    rx={1}
                    transform="rotate(-90 11 17)"
                    fill="black"
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}
              {/*end::Icon*/}
              {/*begin::Wrapper*/}
              <div className="d-flex flex-stack flex-grow-1">
                {/*begin::Content*/}
                <div className="fw-bold">
                  <h4 className="text-gray-900 fw-bolder">
                    You can boot your server from your ISO and install OS
                    manually
                  </h4>
                  <div className="fs-6 text-gray-700">
                    You can upload ISO{" "}
                    <Link to="/iso">
                    <a>
                      here
                    </a>
                    </Link>
                    .
                  </div>
                </div>
                {/*end::Content*/}
              </div>
              {/*end::Wrapper*/}
            </div>
            {/*end::Notice*/}
            {/*begin::Form input row*/}
            <div className="mb-10">
              <label htmlFor="size" className="required form-label">
                Your ISO List (Same Datacenter Location ISO)
              </label>
              <select
                className="form-select" 
                id="iso_selects"
                name="iso" 
              >
                <option value=""> Select ISO  </option>
                {isoList!=null && (isoList && isoList.map((value,index)=>(<>
                  {value.dc==dcLocation.current?(<>
                    <option value={value.file}>{value.name}</option>
                  </>):(<></>)}
                  </>)))}
                {/* <div id="isoSec"></div> */}
              </select> 
            </div>
            {/*end::Form input row*/}
          </div>
          {/*end::Card body*/}
          {/*begin::Card footer*/}
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <a
              onClick={addIso}
              className="btn btn-primary fw-bold"
            >
              Mount and Boot from ISO
            </a>
          </div>
          {/*end::Card footer*/}
        </form>
        {/*end::Form*/}
      </div>
      {/*end::Content*/}
    </div>


    <div className="separator my-10" />
    {/*begin::License usage*/}
    <div className="card">
      {/*begin::Card header*/}
      <div className="card-header">
        {/*begin::Heading*/}
        <div className="card-title">
          <h3>Current Mounted ISO </h3>
        </div>
        {/*end::Heading*/}
      </div>
      {/*end::Card header*/}
      {/*begin::Card body*/}
      <div className="card-body p-0">
        {/*begin::Table wrapper*/}
        <div className="table-responsive">
          {/*begin::Table*/}
          <table
            className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
            id="kt_security_license_usage_table"
          >
            {/*begin::Thead*/}
            <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
              <tr>
                <th className="w-150px ps-9">Current Mounted ISO</th>
                <th className="w-150px ps-9" />
              </tr>
            </thead>
            {/*end::Thead*/}
            {/*begin::Tbody*/}
            <tbody id="iso-list" className="fw-6 fw-bold text-gray-600">
              {props.cloudData.iso?(<>  
              <tr>
              <td className="ps-9">{props.cloudData.iso}</td>
              <td className="text-center ps-9">
                <a className="btn btn-sm btn-light-danger" onClick={unmountIso}>Unmount ISO</a>
              </td>
              </tr>
              </>):(
              <>
              <tr>
                <td className="ps-9">No ISO Mounted Currently</td>
                <td className="text-center ps-9"></td>
              </tr>
              </>)}
              
            </tbody>
            {/*end::Tbody*/}
          </table>
          {/*end::Table*/}
        </div>
        {/*end::Table wrapper*/}
      </div>
      {/*end::Card body*/}
    </div>
    {pageStatus &&
        <TopBarHeaderLine />
      }
    {/*end::License usage*/}
        </>
    )
}

const mapStateToProps=state=>({
  cloudData:state.cloud.cloudDataInfo
})

const mapDispatchToProps=dispatch=>({
  updateCloudStatus:data=>dispatch(IsCloudUpdate(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(Iso) 