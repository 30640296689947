import axios from "axios"; 
 export default async function UseLoginApi(type,url,data){ 
   // const [data,setData]=useState([]);
    
  //  const location = window.location.origin;
   const darr= await axios({
        method: type,
        url:'https://api.utho.com/v2/'+url,
       // headers: {'Authorization': 'Bearer SzgLcEVwUNFZTOaGIdfmXhoYjepWqRKsMvAkiCxDluPrBntJybQH'},
        data:data
    }).then(function(response){
        // //console.log("jai shree ram");
        // //console.log(response.data);
        return response.data;
    })
    ////console.log(darr);
    return darr;
}