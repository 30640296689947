import React from 'react';
import { Link } from 'react-router-dom';
import UsePost from '../../../../../CustomHooks/usePost';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import copy from "copy-to-clipboard";
const CreateAccesskey = () => {
    const addAccesskey=async()=>{ 
        var submitBtn=document.querySelector("#accesskey-btn");  
       
        if(submitBtn){
          var preContent=submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
        const dt=toast.loading("Please wait...");   
        const responseData=await UsePost('post','objectstorage/innoida/accesskey/create','accesskey-add');
        if(responseData){   
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML=preContent;
          if(responseData.status==='error'){
            toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          }else if(responseData.status==='success'){
            toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });

          var copy_keys=document.querySelector("#copy_keys");
          var accesskey_copy = document.querySelector("#accesskey_copy");
          var secretkey_copy = document.querySelector("#secretkey_copy");
           if(accesskey_copy && secretkey_copy && copy_keys){
            accesskey_copy.value=responseData.accesskey;
            secretkey_copy.value=responseData.secretkey;
            copy_keys.classList.remove("d-none");
           }
        }
      } 
          }
        }
    
         const copyToClipboard=(key)=> {   
       
    var eleText = document.querySelector("#"+key+"_copy");
    var ele = document.querySelector("#"+key+"_copy_btn");
    if(ele && eleText){
      var copyText=ele.innerHTML;
      var copyEle=eleText.value;
    copy(copyEle);   
    ele.innerHTML="Copied";
    eleText.classList.add("bg-primary");
    eleText.classList.add("text-light");
     setTimeout(() => {
      eleText.classList.remove("bg-primary");
      eleText.classList.remove("text-light");
      ele.innerHTML=copyText;
    }, 2000);
  }
   
  }
            const openDrawerCreate = () => {
                var copy_keys=document.querySelector("#copy_keys");
                if(copy_keys){
                    copy_keys.classList.add("d-none");
                }
              var drawerElement = document.querySelector("#create-keys-modal");
              drawerElement.classList.toggle("drawer-on");
              drawerElement.style.width = "650px";
            }; 
    return (
        <>
  <div className="card-header border-0 mb-5 pt-5">
  <h3 className="card-title align-items-start flex-column">
    <span className="card-label fw-bolder text-gray-800">Object Storage</span>
    <span className="text-gray-400 mt-1 fw-bold fs-6">
      Object Storage, Access Keys
    </span>
  </h3>
  <div className="card-toolbar">
    <Link to="/objectStorage/deploy">
  <div style={{ marginRight: 20 }}>
      <a className="btn btn-sm btn-primary" >+ Create Bucket</a>
  </div>
  </Link>
  <div style={{ marginRight: 20 }}>
      <a className="btn btn-sm btn-primary" onClick={openDrawerCreate}>+ Create Access Keys</a>
  </div>

  </div>

</div>
        <div
        id="create-keys-modal"
        className="bg-body shadow drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-name="chat"
        data-kt-drawer-activate="true"
        data-kt-drawer-direction="end"
        data-kt-drawer-close="#close_ssh"
        data-kt-drawer-width="{default:'650px', 'md': '650px', 'sm': '300px'}"
        style={{ maxWidth: "70%", important: true }}
      >
       <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
  <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
    <div className="card-title">
      <div className="d-flex justify-content-center flex-column me-3">
        <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
          Create Access Key
        </a>
      </div>
    </div>
    <div className="card-toolbar">
      <div className="btn btn-sm btn-icon btn-active-light-primary" onClick={openDrawerCreate}>
        <span className="svg-icon svg-icon-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <rect
              opacity="0.5"
              x={6}
              y="17.3137"
              width={16}
              height={2}
              rx={1}
              transform="rotate(-45 6 17.3137)"
              fill="black"
            />
            <rect
              x="7.41422"
              y={6}
              width={16}
              height={2}
              rx={1}
              transform="rotate(45 7.41422 6)"
              fill="black"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
  <div className="" id="">
    <form id="accesskey-add">
      <div className="modal-body ">
        <div className="scroll-y me-n7 pe-7">
          <div className="mb-5 fv-row">
            <label htmlFor="type" className="required fs-5 fw-bold mb-2">
              {" "}
              Key Name
            </label>
            <input
              type="text"
              id="accesskeyid"
              name="accesskey"
              className="form-control" 
            />
          </div> 
       
        </div>
      
      <div className=" ">
        <a style={{cursor:"pointer"}} id="accesskey-btn" onClick={addAccesskey} className="btn btn-primary w-100">
          <span className="indicator-label">+ Create Key</span>
        </a>
      </div>
      <div className="mt-10 d-none" id="copy_keys">
                     {/*begin::Input group*/}
                     <div>
  <label className="form-label mb-5">Access key</label>
  <div className="d-flex">
                  <input
                    id="accesskey_copy"
                    type="text"
                    className="form-control form-control-solid me-3 flex-grow-1" 
                    defaultValue=""
                  />
                  <a style={{cursor:"pointer"}} 
                    id="accesskey_copy_btn"
                    onClick={(e)=>copyToClipboard('accesskey')}
                    className="btn btn-light fw-bolder flex-shrink-0" 
                  >
                    Copy key
                  </a>
                </div> 
                      </div> 
                      {/*end::Input group*/}
                       {/*begin::Input group*/}
                       <div>
  <label className="form-label mb-5">Secret key</label>
  <div className="d-flex">
                  <input
                    id="secretkey_copy"
                    type="text"
                    className="form-control form-control-solid me-3 flex-grow-1" 
                    defaultValue=""
                  />
                  <a style={{cursor:"pointer"}} 
                    id="secretkey_copy_btn"
                    onClick={(e)=>copyToClipboard('secretkey')}
                    className="btn btn-light fw-bolder flex-shrink-0" 
                  >
                    Copy Link
                  </a>
                </div>
  <div className="text-danger fs-6 mb-7 mt-5">
    Please collect the credentials, these credentials shows only once!
   </div>
                      </div> 
                      {/*end::Input group*/}
                      </div>
                      </div>
    </form>
  </div>
</div>

      </div> 
        </>
    );
}

export default CreateAccesskey;
