import { CLOUD_DEPLOY_DATA, HOST_COUNT, IS_CLOUD_UPDATE } from "../../../../Redux/Services/constants"

const initialState={
   cloudDataInfo:[],
   hostCountDataInfo:['host'],
   deployDataInfo:[{os_cost:0,select_image:"",stack:"",iso:"",backup:"",snapshot:"",plan_value:"10045",plan_cost:0,plan_final_cost:0,plan_cost_without_discount:0,select_support_option_cost:0,select_support_option:"unmanaged",billing_info:"hourly", isBackup:false, planDiscount:0}]
}

export default function CloudReducer(state=initialState,action){


   switch (action.type) {
       case IS_CLOUD_UPDATE:
           ////console.log("Reducer",action.data);
           return(
              {cloudDataInfo:action.data}
           )
       case HOST_COUNT:
           ////console.log("Reducer",action.data);
           return(
            {hostCountDataInfo:action.data}
           ) 
       case CLOUD_DEPLOY_DATA:
           ////console.log("Reducer",action.data);
           return(
            {deployDataInfo:action.data}
           )             
       default:
           return state 
   }
}

