import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom"; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";  
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate"; 
import UsePost from "../../../../../../CustomHooks/usePost";
import UseApi from "../../../../../../CustomHooks/useApi";
import UsePostData from "../../../../../../CustomHooks/usePostData";
const Database = (props) => { 
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4]; 

  const [permissionArr, setPermissionArr]=useState(["read","full","none"]);
  const [currPermission, setCurrPermission]=useState('');
    const openDrawer=()=>{
        var drawerElement = document.querySelector("#database-modal");
            drawerElement.classList.toggle("drawer-on");
            drawerElement.style.width='500px';
      }
      const openPermissionDrawer=()=>{
        var drawerElement = document.querySelector("#databasePemission-modal");
            drawerElement.classList.toggle("drawer-on");
            drawerElement.style.width='500px';
      }
      
      const loaderFunc=()=>{
        var loader = document.querySelector("#loader");
        var no_record = document.querySelector("#no_record");
        if(loader && no_record){
        no_record.classList.add("d-none");
        loader.classList.remove("d-none");
    
        setTimeout(() => {
          loader.classList.add("d-none");
          no_record.classList.remove("d-none");
        }, 100);
      }
      }

      const togglePermSec=()=>{
        var addPermSec=document.querySelector("#addPerm-sec");
        var addPermBtn=document.querySelector("#addPerm-btn");
        if(addPermBtn && addPermSec){
            addPermBtn.classList.toggle("d-none");
            addPermSec.classList.toggle("d-none");
        }
      } 
const [perPage, setPerPage]=useState(5); 
const [databaseData, setDatabaseData]=useState(null);
      const getDatabases=async()=>{
        if(props){
            if(props.data){
                 if(props.data.databases.length==0){
                    setDatabaseData([]);
                 }else{
                    setDatabaseData(props.data.databases);
                 }
            }   
        }
      }
const [databasePermissions, setDatabasePermissions]=useState(null);
const [userArr, setUserArr]=useState(null);

function deleteUserFromArray(array, userToDelete) {
    var filteredArray = array.filter(function(item) {
      return item.db_user !== userToDelete;
    });
  
    return filteredArray;
  }
     const updatePermission=(db_name)=>{
        var pdb_name=document.querySelector("#pdb_name");
        if(pdb_name){
         pdb_name.value=db_name;
        }
        if(props){
            if(props.data){
                 if(props.data.permissions.length==0){
                    setDatabasePermissions([]);
                    var users=props.data.users;
                    setUserArr(users);
                 }else{
                    var dbPermissions=[]
                    var users=props.data.users;
                    props.data.permissions.map((value,index)=>{
                        if(db_name==value.db_name){
                            dbPermissions.push({"db_name":value.db_name,"db_user":value.db_user,"permission":value.permission});
                        }
                        deleteUserFromArray(users, value.db_user);
                    }) 
                    setUserArr(users);
                    setDatabasePermissions(dbPermissions);
                 }
            }   
        }
        openPermissionDrawer();
     }
      const createDatabase=async()=>{ 
        var submitBtn=document.querySelector("#create-database-btn"); 
       
        if(submitBtn){
          var preContent=submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
        const dt=toast.loading("Please wait...");
        
        const responseData=await UsePost('post','databases/'+id+'/database/newusername','database-form');
        if(responseData){
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML=preContent;
          if(responseData.status==='error'){
            toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          }else if(responseData.status==='success'){
            toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });  
          if(props){
            openDrawer();
            props.updateDatabase();
          }
           
        }
      }
          }
        }

const createNewPermission=async()=>{ 
            var submitBtn=document.querySelector("#create-permission-btn"); 
           
            if(submitBtn){
              var preContent=submitBtn.innerHTML;
              submitBtn.classList.add("disabled");
              submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
            const dt=toast.loading("Please wait...");
            var pdb_name=document.querySelector("#pdb_name");
            if(pdb_name){ 
            const responseData=await UsePost('post','databases/'+id+'/database/'+pdb_name.value+'/user','databasePermission-form');
            if(responseData){
              submitBtn.classList.remove("disabled");
              submitBtn.innerHTML=preContent;
              if(responseData.status==='error'){
                toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });
              }else if(responseData.status==='success'){
                toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              });  
              if(props){
                togglePermSec();
                openPermissionDrawer();
                props.updateDatabase();
              }
               
            }
        }
          }
              }
            }
 const updateDbPermission=async(db_name, db_user)=>{
    var submitBtn=document.querySelector("#update-permission"+db_user+"-btn"); 
           
    if(submitBtn){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
    const dt=toast.loading("Please wait...");
     var newpermission=document.querySelector("#newpermission_field"+db_user);
     if(newpermission){
    const responseData=await UsePostData('post','databases/'+id+'/database/'+db_name+'/user',{"db_name":db_name, "db_user":db_user, "permission":newpermission.value});
    if(responseData){
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      if(props){ 
        openPermissionDrawer();
        props.updateDatabase();
      }
    }
    } 
  }
      }

 }
        const deleteDatabase=async(dbid)=>{
            var submitBtn=document.querySelector("#delete-database"+dbid+"-btn"); 
       
            if(submitBtn){
               
            toast.dismiss();
            const { value: isDelete } = await Swal.fire({
                title: 'Delete Database',
                text: 'Enter the Database name to delete:',
                input: 'text',
                inputPlaceholder: 'Database name',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                customClass: {
                    confirmButton: 'delete-button bg-danger', // Add your custom class here
                  },
              }); 
             if(isDelete==dbid){  
                var preContent=submitBtn.innerHTML;
                submitBtn.classList.add("disabled");
                submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';   
                const dt=toast.loading("Database deleting..."); 
            const dnsp=await UseApi('DELETE','databases/'+id+'/database/'+dbid+'?confirm='+dbid,'');
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML=preContent;
            //console.log(dnsp);
            if(dnsp.status=='error'){
              toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            }else if(dnsp.status=='success'){
              toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            }); 
            if(props){ 
                props.updateDatabase();
              }
            } 
              }else{
                swal({
                      title: "Invalid Database Name",
                      text: "Please confirm the valid database name!",
                      icon: "warning",
                      button: "OK",
                    });
              }
            }
          }
      useEffect(()=>{
        getDatabases();
      },[props])

 
      function ProductInfo({ productdata }) { 
        return(
            <> 
     
            {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((value, index) => (
          <tr>
            <td></td>
          <td className="ps-9">{value.db_name}</td>
          
          
            <td className="text-center">
         <span className="fs-7 fw-bold">
           {value.created_at}
          </span>
          </td> 
          <td className="text-center" >
          <a
             onClick={(e)=>updatePermission(value.db_name)} 
              className="btn btn-sm btn-primary sphide me-5"
            >
              Manage Permissions
            </a>
          <a
             onClick={(e)=>deleteDatabase(value.db_name)}
              id={"delete-database"+value.db_name+"-btn"}
              className="btn btn-sm btn-danger sphide"
            >
              Delete
            </a>
            </td>
        </tr> 
               ))):( <>
                <tr>
                         <td colspan="12" className="dataTables_empty" id="loader">
                          <div className="loading-spinner"></div>
                        </td>
                        <td colspan="12" className="d-none" id="no_record" valign="top">
                          <div className="d-flex flex-column flex-center"> 
                            <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                          </div>
                        </td>
                        </tr>
                </>)}
                
            </>
        )
    }
    
    
    const [searchTerm,setSearchTerm]=useState('');
    function PaginatedItems({ itemsPerPage, ProductData, search }) {
      // We start with an empty list of items. 
      const [pageCount, setPageCount] = useState(0);
      const [vpcData,setVpcData]=useState(null);
      // Here we use item offsets; we could also use page offsets
      // following the API or data you're working with.
      const [itemOffset, setItemOffset] = useState(0);
    
      useEffect(() => {
        // Fetch items from another resources.
        
        const endOffset = itemOffset + itemsPerPage;
    
    
      //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
         if(ProductData!=null){
        //   var str="Noida";
       // ////console.log(ProductData);
        const data= ProductData.filter(row => {
         return  Object.values(row).some(value => { 
            if(value!=null){
              if(value.toString().toLowerCase().includes(search.toLowerCase())){
                return value;
              } 
            }
          });
        });
        //////console.log(data);
          // const data=ProductData.filter(item => 
          //        item.hostname.toLowerCase().includes(search.toLowerCase())
          //              ); 
    
                       if(data.length<=0){
                        loaderFunc();
                       }
        setVpcData(data.slice(itemOffset, endOffset));
        //setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
        // alert(pageCount);
          }
      }, [itemOffset, itemsPerPage]);
    
      // Invoke when user click to request another page.
      
      const handlePageClick = (event) => { 
        const newOffset = event.selected * itemsPerPage % ProductData.length;
        ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
       
      };
      var searchEle=document.querySelector("#searchClient");
      if(searchEle){
        searchEle.focus();
      }
      return (
        <>
          <ProductInfo productdata={vpcData} />
          <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
          </td>
        </>
      );
    } 
    return (
        <>
            <div className="card">
  <div className="card-header">
    <div className="card-title">
      <h3>Databases </h3>
    </div>
    <div className="card-toolbar">
    <div
                      className="position-relative my-1"
                      style={{ marginRight: 20 }}
                    >
                       <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>

                  <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select  
        onChange={e => setPerPage(e.target.value)}
        //data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={5}
        id="perPage"
        
      >
        <option value={5} selected="">
          5 Records
        </option>
        <option value={10}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
                      </div>
                    </div>
      <a  onClick={openDrawer} className="btn btn-sm btn-primary my-1 sphide">
        + Add Database
      </a>
    </div>
  </div>
  <div className="card-body p-0">
    <div className="table-responsive">
      <table
        className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
        id="kt_security_license_usage_table"
      >
        <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
          <tr>  
            <th className="w-50px ps-9"></th>
            <th className="w-150px ps-9">Database</th> 
            <th className="text-center pe-5 w-150px">CreatedAt</th> 
            <th className="w-150px ps-9" />
          </tr>
        </thead>
        <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
            {databaseData!=null?(<>
        <PaginatedItems itemsPerPage={perPage} ProductData={databaseData} search={searchTerm}  />
        </>):('')}
        </tbody>
      </table>
    </div>
  </div>
  <div
  id="database-modal"
  className="bg-body shadow drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-name="chat"
  data-kt-drawer-activate="true" 
  data-kt-drawer-direction="end" 
  data-kt-drawer-close="#close_ssh"
  data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}" 
  style={{maxWidth: '70%',important:true}}
  
>
  {/*begin::Messenger*/}
  <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
    {/*begin::Card header*/}
    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div className="d-flex justify-content-center flex-column me-3">
          <a
            
            className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
          >
            Add New Database
          </a>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Close*/}
        <div
        onClick={openDrawer}
          className="btn btn-sm btn-icon btn-active-light-primary"
          id="close_ssh"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {/*end::Card header*/}
    {/*begin::Card body*/}
    <div className="" id="">
      {/*begin::Form*/}
      <form id="database-form" className="form">
        {/*begin::Modal body*/}
        <div className="modal-body ">
          {/*begin::Scroll*/}
          <div
            className="scroll-y me-n7 pe-7"
            id="kt_modal_create_api_key_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
            data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
            data-kt-scroll-offset="300px"
          >
            {/*begin::Input group*/}
            <div className="mb-5 fv-row">
              {/*begin::Label*/}
              <label className="required fs-5 fw-bold mb-2"> Database Name</label>
              {/*end::Label*/}
              {/*begin::Input*/} 
                <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Enter Database Name" 
              /> 
              {/*end::Input*/}
            </div>
             {/*end::Input group*/}
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Modal body*/}
        {/*begin::Modal footer*/}
        <div className="modal-footer ">
          {/*begin::Button*/}
          <a
             onClick={createDatabase}
            className="btn btn-primary w-100"
            id="create-database-btn"
          >
            <span className="indicator-label"> + Add Database</span>
          </a>
          {/*end::Button*/}
        </div>
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
    </div>
    {/*end::Card body*/}
  </div>
  {/*end::Messenger*/}
</div>
<div
  id="databasePemission-modal"
  className="bg-body shadow drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-name="chat"
  data-kt-drawer-activate="true" 
  data-kt-drawer-direction="end" 
  data-kt-drawer-close="#close_ssh"
  data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}" 
  style={{maxWidth: '70%',important:true}}
  
>
  {/*begin::Messenger*/}
  <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
    {/*begin::Card header*/}
    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div className="d-flex justify-content-center flex-column me-3">
          <a
            
            className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
          >
            Update Permission
          </a>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Close*/}
        <div
        onClick={openPermissionDrawer}
          className="btn btn-sm btn-icon btn-active-light-primary"
          id="close_ssh"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {/*end::Card header*/}
    {/*begin::Card body*/}
    <div className="" id=""> 
       
        {/*begin::Modal body*/}
        <div className=" ">
          {/*begin::Scroll*/}
          <div
            className="scroll-y me-n7 pe-7"
            id="kt_modal_create_api_key_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
            data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
            data-kt-scroll-offset="300px"
          >
            {/*begin::Input group*/}
            <div className="d-none p-5" id="addPerm-sec">
            <form id="databasePermission-form" className="form">
                <div className="row">
                    <div className="col-6">
                    <div className="mb-5 fv-row">
              {/*begin::Label*/}
              <label className="required fs-5 fw-bold mb-2"> Database Permission</label>
              {/*end::Label*/}
              {/*begin::Input*/} 
              <input
                type="hidden"
                className="form-control"
                name="db_name"
                id="pdb_name"
              /> 
              <select className='form-select' name="db_user" tabIndex={-1} aria-hidden="true">
                {userArr && userArr.map((value2,index)=>(<> 
                    <option value={value2.db_user}>{value2.db_user}</option>
                    </>
                ))}
                </select>
              {/*end::Input*/}
            </div>
                    </div>
                    <div className="col-6">
                    <div className="mb-5 fv-row">
              {/*begin::Label*/}
              <label className="required fs-5 fw-bold mb-2"> Select Permission</label>
              {/*end::Label*/}
              {/*begin::Input*/} 
              
              <select className='form-select' name="permission" tabIndex={-1} aria-hidden="true">
                {permissionArr && permissionArr.map((value2,index)=>(<> 
                    <option value={value2}>{value2}</option>
                    </>
                ))}
                </select>
              {/*end::Input*/}
            </div>
                    </div>
                </div>
           
           
            <div className="mb-5 fv-row">
                <a className="btn btn-primary me-3" id="create-permission-btn" onClick={createNewPermission}>+Add Permission</a>
                <a className="btn btn-danger" onClick={togglePermSec}>Cancel</a>
            </div>
            </form>
            </div>
             {/*end::Input group*/}
     <div className="mb-5 mt-5 text-right" style={{float:"right"}}>
     <a className="btn btn-primary" id="addPerm-btn" onClick={togglePermSec}>+Add New</a>    
    </div>        
     <div className="table-responsive w-100">
      <table
        className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
        id="kt_security_license_usage_table"
      >
        <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
          <tr>  
            <th className="w-50px ps-9"></th>
            <th className="w-150px ps-9">User</th> 
            <th className="w-150px ps-9">Permission</th>  
            <th className="w-150px ps-9" />
          </tr>
        </thead>
        <tbody className="fw-6 fw-bold text-gray-600">
             {databasePermissions!=null?(<>
             {databasePermissions.map((value,index)=>(<>
             
             <tr>
                <td></td>
                <td className="text-start" style={{paddingLeft:"20px"}}>
         <div className=" align-items-center">
           {" "}
           <div className="d-flex justify-content-start flex-column">
             {" "}
             <a className="text-dark fw-bold text-hover-primary fs-6 sphide">
               {value.db_user}
             </a>{" "}
           </div>{" "}
         </div>
       </td>
         <td className="text-center"> 
         <select className='form-select' id={"newpermission_field"+value.db_user}>
                        {permissionArr && permissionArr.map((value2,index)=>(<>
                          {value.permission==value2?(<>
                            <option value={value2} selected={true}>{value2}</option>
                          </>):(<>
                            <option value={value2}>{value2}</option>
                          </>)}
                          
                          </>
                        ))}
                      </select>
         </td>
         <td className="text-center">
         <a
             onClick={(e)=>updateDbPermission(value.db_name, value.db_user)}
              id={"update-permission"+value.db_user+"-btn"}
              
              className="btn btn-sm btn-primary sphide"
            >
              update
            </a>
         </td>
             </tr>
             </>))}
             </>):('')}
        </tbody>
      </table>
    </div>
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Modal body*/}   
    </div>
    {/*end::Card body*/}
  </div>
  {/*end::Messenger*/}
</div>
</div>
        </>
    );
}


export default Database 
