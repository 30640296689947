import React, { useRef, useEffect, useState } from 'react';  
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import './custom.css'; 
import OtpVerify from './OtpVerify';
import UsePost from '../../../../CustomHooks/usePost';
const MobileVerification = (props) => { 
  function extractCountryCodeAndMobileNumber(phoneNumber) {
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
    if (!parsedPhoneNumber) {
      return {
        countryCode: '',
        mobileNumber: '',
      };
    }
  
    const countryCode = `+${parsedPhoneNumber.countryCallingCode}`;
    const mobileNumber = parsedPhoneNumber.nationalNumber;
  
    return {
      countryCode,
      mobileNumber,
    };
  }
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleInputChange = (value) => {
    setPhoneNumber(value);
  };

  const verifyMobile=async()=>{
    const { countryCode, mobileNumber }=extractCountryCodeAndMobileNumber(phoneNumber);
    if(!countryCode && !mobileNumber){
      const dt=toast.loading("Please wait...");
      toast.update(dt,{render: "Please provide valid mobile number!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
       return;
    }
    var mobile_code=document.querySelector("#mobile_code");
    var mobile_no=document.querySelector("#mobile_no");
    if(mobile_code && mobile_no){
      mobile_code.value=countryCode;
      mobile_no.value=mobileNumber;
    }
      var submitBtn=document.querySelector("#mobile-btn");
        if(submitBtn){
          var preContent=submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';  
      const dt=toast.loading("Please wait...");
      const responseData=await UsePost('post','account/update/mobile/'+countryCode,'verify-mobile');
        if(responseData){
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML=preContent;
        if(responseData.status==='error'){
          toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
        }else{
          toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        }
        // else{
        //   const responseData2=await UsePost('post','account/otp/sendotp','nothing');
        //   if(responseData2 && responseData2.status=="success"){
        //   toast.update(dt,{render: "OTP has been sent to "+phoneNumber, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        // }); 
        //  var mobile_verify_sec=document.querySelector("#mobile-verify-sec");
        //  var otp_verify_sec=document.querySelector("#otp-verify-sec");
        //  if(mobile_verify_sec && otp_verify_sec){
        //   mobile_verify_sec.classList.add("d-none");
        //   otp_verify_sec.classList.remove("d-none");
        //  }
        // } 
        // }
      }
    }


  } 

  useEffect(()=>{
    if(props){
      if(props.currentMobile){ 
        setPhoneNumber(props.currentMobile);
      }
    }
  },[])
  
    return (
        <>
        <OtpVerify />
        
<div className='w-lg-600px p-10 p-lg-15 mx-auto' id="mobile-verify-sec">
  <form id="verify-mobile">
  <input type='hidden' name="mobilecc" id="mobile_code"/>
  <input type='hidden' name="mobile" id="mobile_no"/>
  </form>
  <form className="form w-100 mb-10" noValidate="" id="code_form">
 
  <div className="text-center mb-10">
    <h1 className="text-dark mb-3">Update Mobile Number </h1> 
  </div>
  <div className="mb-10 px-md-10">
    <div className="fw-bolder text-start text-gray-400 fs-6 mb-5 ms-1">
      Enter the mobile number to update
    </div>
    <div className="d-flex flex-wrap flex-stack">
    <PhoneInput
      international
      defaultCountry="IN"
      value={phoneNumber}
      onChange={handleInputChange}
      className="form-control customCss"
    />
    </div>
  </div>
  <div className="d-flex flex-center">
    <button
      type="button"
      className="btn btn-lg btn-primary fw-bolder"
      id="mobile-btn"
    >
      <span className="indicator-label" onClick={verifyMobile}>Submit</span>
    </button>
  </div>
</form>
</div>
              
        </>
    );
}

export default MobileVerification;
