import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IsCloudUpdate } from "../../../../../Redux/Services/Action/actions";   
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import swal from 'sweetalert';
import UseApi from "../../../../../CustomHooks/useApi";
import UsePost from "../../../../../CustomHooks/usePost";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
const Network=(props)=>{
  const navigate=useNavigate();
  var query = window.location.href; 
  const [pageStatus,setPageStatus] = useState(false)
  var vars = query.split("/"); 
  var id=vars[4];  
  const [privateData,setPrivateData]=useState(null);
  const [publicData,setPublicData]=useState(null);
  const [vpcData,setVpcData]=useState(null);
  const dataFetchedRef=useRef (false);
  const dispatch=useDispatch();
  // if(props.cloudData.networks){
  // ////console.log(props.cloudData.networks.private.v4);
  // }
  const navRedirect=()=>{
    setTimeout(() => {
      navigate("/cloud/"+id);
     }, 3000);  
  }
  useEffect(() => { 
    if(props.cloudData.networks){
    setPublicData(props.cloudData.networks.public.v4);
    setPrivateData(props.cloudData.networks.private.v4);  
  }
  }, [props.cloudData]); 

  const getVpc=async()=>{ 
    
       const data=await UseApi('get','vpc',''); 
       delete(data.html); 
       if(Object.keys(data).length === 0){
        setVpcData(null); 
       }else{
        setVpcData(Object.values(data));   
       }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      getVpc(); 
  },[]);  

  const deletePrivateIp=async(privateId)=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this Private IP!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        var submitBtn=document.querySelector("#delete-private-ip-"+privateId.replace(/\./g, "-"));
    if(submitBtn){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
    setPageStatus(true)
      submitBtn.innerHTML='<span className="indicator-label">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';   
        const dt=toast.loading("Private IP "+privateId+" deleting, Please wait..."); 
        const dnsp=await UseApi('delete','cloud/'+id+'/ip/'+privateId+'/delete','');  
        if(dnsp.status=='error'){
setPageStatus(false)
         toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       });
       }else{
        setPageStatus(false)
         toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       }); 
       const data=await UseApi('get','cloud/'+id,'');
       dispatch(props.updateCloudStatus(data.cloud[0]));
       navRedirect();
       }
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML=preContent;
      }
      }else {
        setPageStatus(false)
        swal({
            title: "Private IP",
            text: "Your Private IP is safe!",
            icon: "success",
            button: "OK",
          });
      }
      });
    
  }

  const deletePublicIp=async(publicId)=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this Public IP!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) { 
        var submitBtn=document.querySelector("#delete-ip-"+publicId.replace(/\./g, "-"));
    if(submitBtn){
    setPageStatus(true)
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';   
        const dt=toast.loading("Public IP "+publicId+" deleting, Please wait...."); 
        const dnsp=await UseApi('delete','cloud/'+id+'/ip/'+publicId+'/delete','');  
        if(dnsp.status=='error'){
          setPageStatus(false)
         toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       });
       }else{
        setPageStatus(false)
         toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       }); 
       const data=await UseApi('get','cloud/'+id,'');
       dispatch(props.updateCloudStatus(data.cloud[0]));
       navRedirect();
       }
       submitBtn.classList.remove("disabled");
       submitBtn.innerHTML=preContent;
      }
      }else {
        setPageStatus(false)
        swal({
            title: "Public IP",
            text: "Your Public IP is safe!",
            icon: "success",
            button: "OK",
          });
      }
      });
    
  }
 
  const addAdditionalIp=async ()=>{  
    toast.dismiss();
   const dt=toast.loading("Additional IP assigning, Please wait...");
   var submitBtn=document.querySelector("#add-additional-ip");
    if(submitBtn){
    setPageStatus(true)
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';   
    const responseData=await UsePost('post','cloud/'+id+'/assignpublicip','assignpublicip'); 
      if(responseData.status==='error'){
        setPageStatus(false)
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      }else if(responseData.status==='success'){
        setPageStatus(false)
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      }); 
      const data=await UseApi('get','cloud/'+id,'');
       dispatch(props.updateCloudStatus(data.cloud[0]));
       navRedirect();
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
    }
  }
  
  const addVpc=async (vpcid)=>{ 
    swal({
    title:"Are you sure?",
    text: "Do you really want to assign VPC in your cloud!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
    })
    .then(async(willDelete) => {
    if(willDelete) {
      var submitBtn=document.querySelector("#vpc-"+vpcid);
    if(submitBtn){
    setPageStatus(true)
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span class="svg-icon svg-icon-primary svg-icon-4x me-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M19 18C20.7 18 22 16.7 22 15C22 13.3 20.7 12 19 12C18.9 12 18.9 12 18.8 12C18.9 11.7 19 11.3 19 11C19 9.3 17.7 8 16 8C15.4 8 14.8 8.2 14.3 8.5C13.4 7 11.8 6 10 6C7.2 6 5 8.2 5 11C5 11.3 5.00001 11.7 5.10001 12H5C3.3 12 2 13.3 2 15C2 16.7 3.3 18 5 18H19Z" fill="black"></path></svg></span> <span class="d-block fw-bold text-start"> <span classname="indicator-label sphide">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span> </span>';   
      const dt=toast.loading("Additional IP assigning, Please wait...");
      const responseData=await UsePost('post','cloud/'+id+'/assignvpcip/'+vpcid,'addvpc'); 
       // ////console.log(responseData);
        if(responseData.status==='error'){
          setPageStatus(false)
          toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });  
        }else if(responseData.status==='success'){
          setPageStatus(false)
          toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        }); 
        
        setTimeout(async()=>{
          const data=await UseApi('get','cloud/'+id,'');
          dispatch(props.updateCloudStatus(data.cloud[0]));
          // navRedirect();
        },3000)
        
        }
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML=preContent;
      }
    } else{
      setPageStatus(false)
    }
    });
    
   }
  const updateRdns=async(publicId)=>{ 
    toast.dismiss();
    const dt=toast.loading("Public IP publicId , Please wait...");
    var submitBtn=document.querySelector("#update-rdns-"+publicId.replace(/\./g, "-"));
    if(submitBtn){
    setPageStatus(true)
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label sphide">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';   
    const ip_addres=publicId.replaceAll('.','_');
     const responseData=await UsePost('post','cloud/'+id+'/updaterdns/'+publicId,'updaterdns_'+ip_addres);
     
      // ////console.log(responseData);
       if(responseData.status==='error'){
        setPageStatus(false)
         toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       });  
       }else if(responseData.status==='success'){
        setPageStatus(false)
         toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       }); 
       const data=await UseApi('get','cloud/'+id,'');
       dispatch(props.updateCloudStatus(data.cloud[0]));
       navRedirect();
       }
     submitBtn.classList.remove("disabled");
     submitBtn.innerHTML=preContent;
      }else{
        setPageStatus(false)
      }
   }
    return(
        <>

           <div className="card">
      {/*begin::Card header*/}
      <div className="card-header">
        {/*begin::Heading*/}
        <div className="card-title">
          <h3>IPv4 Public IP Address </h3>
        </div>
        {/*end::Heading*/}
        {/*begin::Toolbar*/}
        <div className="card-toolbar">
          <a  
          id="add-additional-ip"
            onClick={addAdditionalIp}
            className="btn btn-sm btn-primary my-1 sphide"
          >
            + Assign Additional Public IP
          </a>
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Card header*/}
      {/*begin::Card body*/}
      <div className="card-body p-0">
        {/*begin::Table wrapper*/}
        <div className="table-responsive">
          {/*begin::Table*/}
          <table
            className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
            id="kt_security_license_usage_table"
          >
            {/*begin::Thead*/}
            <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
              <tr>
                <th className="w-150px ps-9">IP Address</th>
                <th className="min-w-150px">Gateway</th>
                <th className="min-w-150px">Netmask</th>
                <th className="text-center pe-5 min-w-150px">RDNS</th>
                <th className="w-150px ps-9" />
                <th className="w-250px ps-9" />
              </tr>
            </thead>
            {/*end::Thead*/}
            {/*begin::Tbody*/}
            <tbody id="public-IP-list" className="fw-6 fw-bold text-gray-600">
            {publicData!=null && publicData.map((value,index)=>(
              <>
              {Object.keys(value).length === 0?(<></>):(<>
              <tr>
                <td className="ps-9">{value.ip_address}</td>
                <td>{value.gateway}</td>
                <td>{value.netmask}</td>{" "}
                <td className="ps-5 text-center">
                  {" "}
                  <form
                    className="form"
                    id={"updaterdns_"+value.ip_address.replaceAll('.','_')}
                    name={"updaterdns_"+value.ip_address.replaceAll('.','_')}
                  >
                    <input
                      type="text"
                      className="form-control "
                      id="rdns"
                      name="rdns"
                      placeholder="server1.domain.com"
                      defaultValue={""+value.rdns+""}
                      // style={{ width: "65%" }}
                    />{" "}
                  </form>
                </td>
                <td className="text-center">
                  <a  
                   id={"update-rdns-"+value.ip_address.replace(/\./g, "-")}
                    onClick={(e)=>{e.preventDefault();updateRdns(value.ip_address)}}
                    className="btn btn-primary sphide"
                  >
                    Update
                  </a>
                </td>{" "}
                {value.primary=='0'?(
                  <td className="text-center">
                  <a id={"delete-ip-"+value.ip_address.replace(/\./g, "-")} onClick={(e)=>{e.preventDefault();deletePublicIp(value.ip_address)}} className="btn btn-danger sphide">Delete</a>
                </td>
                ):(
                  <td className="text-center" />
                )}
                 
              </tr>
              </>)}
              </>
              ))} 
            </tbody>
            {/*end::Tbody*/}
          </table>
          {/*end::Table*/}
        </div>
        {/*end::Table wrapper*/}
      </div>
      {/*end::Card body*/}
    </div>
    {/*end::License usage*/}
    <div className="separator my-10" />
    {/*begin::License usage*/}
    <div className="card">
      {/*begin::Card header*/}
      <div className="card-header">
        {/*begin::Heading*/}
        <div className="card-title">
          <h3>IPv4 Private IP Address </h3>
        </div>
        {/*end::Heading*/}
      </div>
      {/*end::Card header*/}
      {/*begin::Card body*/}
      <div className="card-body p-0">
        {/*begin::Table wrapper*/}
        <div className="table-responsive">
          {/*begin::Table*/}
          <table
            className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
            id="kt_security_license_usage_table"
          >
            {/*begin::Thead*/}
            <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
              <tr>
                <th className="w-150px ps-9">IP Address</th>
                <th className="w-150px">Gateway</th>
                <th className="w-150px">Netmask</th>
                <th className="w-150px ps-9" />
              </tr>
            </thead>
            {/*end::Thead*/}
            {/*begin::Tbody*/}
            <tbody
              id="private-IP-list"
              className="fw-6 fw-bold text-gray-600"
            >
              {privateData!=null && Object.keys(privateData).length != 1?(<> {privateData.map((value,index)=>(
              <>
              {Object.keys(value).length === 0?(<> 
              
              </>):(<>
                {value.noip=='0'?(<></>):(
                <tr> 
                  <td className="ps-9">{value.ip_address}</td> 
                  {value.vpc_id?(<>
                    <td> 
                      <span className="badge badge-light-success fs-7 fw-bolder">VPC </span>
                      <Link to={"/vpc/"+value.vpc_id} ><a >{value.network}</a></Link>
                    </td>
                  </>):(<td>{value.gateway}</td> )}
                  
                  <td>{value.netmask}</td> 
                  <td className="text-center">
                    <a id={"delete-private-ip-"+value.ip_address.replace(/\./g, "-")} onClick={(e)=>{e.preventDefault();deletePrivateIp(value.ip_address)}} className="btn btn-danger sphide">Delete</a>
                  </td> 
                </tr>
                )}
              </>)}
              </>
              ))}</>):(<>
                  <tr>  
                    <td colspan="12" >
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr></>)}
              </tbody>
            {/*end::Tbody*/}
          </table>
          {/*end::Table*/}
        </div>
        {/*end::Table wrapper*/}
      </div>
      {/*end::Card body*/}
    </div>
    {/*end::License usage*/}
    <div className="separator my-10" />
    {/*begin::Tables Widget 9*/}
    <div className="card card-xl-stretch mb-5 mb-xl-12">
      {/*begin::Header*/}
      <div className="card-header border-0 pt-5">
        {/*begin::Heading*/}
        {/*end::Heading*/}
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            {" "}
            Select VPC to assign to your cloud
            <span className="svg-icon svg-icon-2">
              <i className="fonticon-share" style={{ fontSize: 20 }} />
            </span>
          </span>
        </h3>
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className="row mt-5 container" id="vpc-list">
        {vpcData!=null && (vpcData && vpcData.map((value,index)=>(
        <div className="col-md-4 col-lg-12 col-xxl-4 mb-5">
          <label
            htmlFor=""
            id={"vpc-"+value.id}
            onClick={(e)=>{e.preventDefault();addVpc(value.id)}}
            className="btn btn-outline btn-outline-dashed btn-outline-default  d-flex align-items-center"
          >
            <span className="svg-icon svg-icon-primary svg-icon-4x me-4"> 
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M19 18C20.7 18 22 16.7 22 15C22 13.3 20.7 12 19 12C18.9 12 18.9 12 18.8 12C18.9 11.7 19 11.3 19 11C19 9.3 17.7 8 16 8C15.4 8 14.8 8.2 14.3 8.5C13.4 7 11.8 6 10 6C7.2 6 5 8.2 5 11C5 11.3 5.00001 11.7 5.10001 12H5C3.3 12 2 13.3 2 15C2 16.7 3.3 18 5 18H19Z"
                  fill="black"
                />
              </svg>
            </span>
            <span className="d-block fw-bold text-start">
              <span className="text-dark fw-bolder d-block fs-3">
                {value.network}
              </span>
              <span className="text-muted fw-bold fs-6">
                {" "}
                {value.name}
              </span>
            </span>
          </label>
        </div>
       )))}
      </div>
      {/*begin::Body*/}
    </div>
    {pageStatus &&
        <TopBarHeaderLine />
      }
        </>
    )
}

const mapStateToProps=state=>({
  cloudData:state.cloud.cloudDataInfo
})

const mapDispatchToProps=dispatch=>({
  updateCloudStatus:data=>dispatch(IsCloudUpdate(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(Network) 