import axios from "axios";
// import { Tooltip } from "chart.js";
import React, { useEffect, useRef, useState } from "react"; 
// import { Chart } from "react-google-charts";
import UseApi from "../../../../../CustomHooks/useApi";
import UseCharts from "../../../../../CustomHooks/useCharts";
import { Area } from '@ant-design/plots';
export default function Overview(){ 
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4]; 
   const [dataDuration, setDataDuration]=useState(4);
   let appData= localStorage.getItem("appInfo");
   let siteUrl = JSON.parse(appData)
   //CORE FUNCTIONS
   function readableBytes(bytes) {
    var i = Math.floor(bytes / 1024); 
    var sizes = ['Bits', 'Kbits', 'Mbits', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
     return sizes[i];
  }
  
  function readableBytesValue(bytes) {
    let i = Math.floor(Math.log(bytes) / Math.log(1024));
    let sizes = ['B/s', 'KB/s', 'MB/s', 'GB/s', 'TB/s', 'PB/s', 'EB/s', 'ZB/s', 'YB/s'];
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2));
  }
  


  // CPU CHART DESIGN START
  const [data, setData] = useState([]);  
  const asyncFetch = () => {

    // let siteUrl=appData.site;
    // console.log(siteUrl.site);
    
         const url=`https://${siteUrl.site}/zabbix.php?requesttype=POST&id=`+id+'&type=vCPU_usage&period='+dataDuration+'hour';    
    fetch(url)
      .then((response) => response.json())
      .then((json) => { 
        const transformedData = json.result.map((value,index) => ({
          Date: new Date(value.clock),
          'CPU_Utilization': parseFloat(value.value),
        }));
       ////console.log(transformedData);
        setData(transformedData);
      })
      .catch((error) => {
       ////console.log('fetch data failed', error);
      });
  };

  const config = {
    key:'area1',
    data,
    xField: 'Date',
    yField: 'CPU_Utilization',
    xAxis: {
      type: 'time',
      range: [0, 1],
      tickCount: 10,
      tickInterval: 1000 * 60 * 60, // one day interval
      //mask: 'YYYY-MM-DD', // date format
      mask: 'HH:mm:ss A', // time format
    },
    tooltip: {
      customContent: (date,items) => {
        // //console.log(title);
         //console.log(items);
         if(items.length>0){
           const now = new Date(items[0].data.Date);
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const dayOfWeek = daysOfWeek[now.getDay()];
        const month = months[now.getMonth()];
        const dayOfMonth = now.getDate().toString().padStart(2, '0');
        const year = now.getFullYear();
        const hours = now.getHours() % 12 || 12;
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        const ampm = now.getHours() >= 12 ? 'pm' : 'am';
        const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth} ${year}`;
        const formattedTime= `${hours}:${minutes}:${seconds} ${ampm}`;
        return `<div class="p-5"> 
          <div class="d-flex fs-6 fw-bold align-items-center mb-2">  
            <div class=" me-3">
            <span class="svg-icon svg-icon-muted svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path opacity="0.3" d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z" fill="black"/>
            <path d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z" fill="black"/>
            <path d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z" fill="black"/>
            </svg></span></div> 
            <div class="text-gray-500 flex-grow-1 me-4">Date :</div> 
            <div class="fw-boldest text-gray-700 text-xxl-end">${formattedDate}</div> 
          </div>
          <div class="d-flex fs-6 fw-bold align-items-center mb-2"> 
            <div class="me-3">
            <span class="svg-icon svg-icon-muted svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"/>
            <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"/>
            </svg></span>
            </div> 
            <div class="text-gray-500 flex-grow-1 me-4">Time :</div> 
            <div class="fw-boldest text-gray-700 text-xxl-end">${formattedTime}</div> 
          </div>
          <div class="d-flex fs-6 fw-bold align-items-center"> 
            <div class="me-3">
            <span class="svg-icon svg-icon-primary svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path opacity="0.3" d="M21 13H15V11H21C21.6 11 22 10.6 22 10C22 9.4 21.6 9 21 9H15V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V9H11V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V9H3C2.4 9 2 9.4 2 10C2 10.6 2.4 11 3 11H9V13H3C2.4 13 2 13.4 2 14C2 14.6 2.4 15 3 15H9V21C9 21.6 9.4 22 10 22C10.6 22 11 21.6 11 21V15H13V21C13 21.6 13.4 22 14 22C14.6 22 15 21.6 15 21V15H21C21.6 15 22 14.6 22 14C22 13.4 21.6 13 21 13Z" fill="black"/>
            <path d="M16 17H8C7.4 17 7 16.6 7 16V8C7 7.4 7.4 7 8 7H16C16.6 7 17 7.4 17 8V16C17 16.6 16.6 17 16 17ZM14 10H10V14H14V10Z" fill="black"/>
            </svg></span>
            </div> 
            <div class="text-gray-500 flex-grow-1 me-4">CPU Usage :</div> 
            <div class="fw-boldest text-gray-700 text-xxl-end">${items[0].data.CPU_Utilization}%</div> 
          </div>
          </div>
        `;
         }
      },  
    },
    areaStyle: () => {
      return {
        fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
      };
    },
  };
 
  
  // CPU CHART DESIGN END

  // Memory CHART DESIGN START
  const [memoryInfo, setMemoryInfo] = useState([]);
  const [memoryFormat, setMemoryFormat] = useState('');
   const asyncFetchMemory = () => {
         const url=`https://${siteUrl.site}/zabbix.php?requesttype=POST&id=`+id+'&type=Memory_Used&period='+dataDuration+'hour';    
    fetch(url)
      .then((response) => response.json())
      .then((json) => { 
        setMemoryFormat(json.bytes);
        const transformedData = json.result.map((value,index) => ({
          Date: new Date(value.clock),
          'Memory_Usage': parseFloat(value.value),
        }));
       ////console.log(transformedData);
        setMemoryInfo(transformedData);
      })
      .catch((error) => {
       ////console.log('fetch data failed', error);
      });
  };
 
  const config2 = {
    key:'area2',
    data:memoryInfo,
    xField: 'Date',
    yField: 'Memory_Usage',
    xAxis: {
      type: 'time',
       tickCount: 10,
      tickInterval: 1000 * 60 , // one day interval
      //mask: 'YYYY-MM-DD', // date format
      mask: 'HH:mm:ss A', // time format
    },
    tooltip: {
      customContent: (date,items) => {
        // //console.log(title);
         //console.log(items);
         if(items.length>0){
           const now = new Date(items[0].data.Date);
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const dayOfWeek = daysOfWeek[now.getDay()];
        const month = months[now.getMonth()];
        const dayOfMonth = now.getDate().toString().padStart(2, '0');
        const year = now.getFullYear();
        const hours = now.getHours() % 12 || 12;
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        const ampm = now.getHours() >= 12 ? 'pm' : 'am';
        const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth} ${year}`;
        const formattedTime= `${hours}:${minutes}:${seconds} ${ampm}`;
        return `<div class="p-5"> 
        <div class="d-flex fs-6 fw-bold align-items-center mb-2">  
        <div class=" me-3">
        <span class="svg-icon svg-icon-muted svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z" fill="black"/>
        <path d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z" fill="black"/>
        <path d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z" fill="black"/>
        </svg></span></div> 
        <div class="text-gray-500 flex-grow-1 me-4">Date :</div> 
        <div class="fw-boldest text-gray-700 text-xxl-end">${formattedDate}</div> 
      </div>
      <div class="d-flex fs-6 fw-bold align-items-center mb-2"> 
        <div class="me-3">
        <span class="svg-icon svg-icon-muted svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"/>
        <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"/>
        </svg></span>
        </div> 
        <div class="text-gray-500 flex-grow-1 me-4">Time :</div> 
        <div class="fw-boldest text-gray-700 text-xxl-end">${formattedTime}</div> 
      </div>
          <div class="d-flex fs-6 fw-bold align-items-center"> 
            <div class="me-3">
            <span class="svg-icon svg-icon-primary svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM6.39999 9.89999C6.99999 8.19999 8.40001 6.9 10.1 6.4C10.6 6.2 10.9 5.7 10.7 5.1C10.5 4.6 9.99999 4.3 9.39999 4.5C7.09999 5.3 5.29999 7 4.39999 9.2C4.19999 9.7 4.5 10.3 5 10.5C5.1 10.5 5.19999 10.6 5.39999 10.6C5.89999 10.5 6.19999 10.2 6.39999 9.89999ZM14.8 19.5C17 18.7 18.8 16.9 19.6 14.7C19.8 14.2 19.5 13.6 19 13.4C18.5 13.2 17.9 13.5 17.7 14C17.1 15.7 15.8 17 14.1 17.6C13.6 17.8 13.3 18.4 13.5 18.9C13.6 19.3 14 19.6 14.4 19.6C14.5 19.6 14.6 19.6 14.8 19.5Z" fill="black"/>
              <path d="M16 12C16 14.2 14.2 16 12 16C9.8 16 8 14.2 8 12C8 9.8 9.8 8 12 8C14.2 8 16 9.8 16 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" fill="black"/>
              </svg></span>
            </div> 
            <div class="text-gray-500 flex-grow-1 me-4">Memory Usage :</div> 
            <div class="fw-boldest text-gray-700 text-xxl-end">${items[0].data.Memory_Usage} ${memoryFormat}</div> 
          </div>
          </div>
        `;
         }
      },
    },
    areaStyle: () => {
      return {
        fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
      };
    },
  };

  // Memory CHART DESIGN START


  // Block Read CHART DESIGN START
  const [blockReadInfo, setBlockReadInfo] = useState([]);
  const [blockReadFormat, setBlockReadFormat] = useState('');
 

  const BlockReadFetch = () => {
         const url=`https://${siteUrl.site}/zabbix.php?requesttype=POST&id=`+id+'&type=block_stats_read_bytes_sec&period='+dataDuration+'hour';    
    fetch(url)
      .then((response) => response.json())
      .then((json) => { 
        setBlockReadFormat(json.bytes);
        const transformedData = json.result.map((value,index) => ({
          Date: new Date(value.clock),
          'Read': parseFloat(value.value),
        }));
       ////console.log(transformedData);
        setBlockReadInfo(transformedData);
      })
      .catch((error) => {
       ////console.log('fetch data failed', error);
      });
  };
 
  const config3 = {
    key:'area3',
    data:blockReadInfo,
    xField: 'Date',
    yField: 'Read',
    xAxis: {
      type: 'time',
       tickCount: 10,
      tickInterval: 1000 * 60 , // one day interval
      //mask: 'YYYY-MM-DD', // date format
      mask: 'HH:mm:ss A', // time format
    },
    tooltip: {
      customContent: (date,items) => {
        // //console.log(title);
         //console.log(items);
         if(items.length>0){
           const now = new Date(items[0].data.Date);
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const dayOfWeek = daysOfWeek[now.getDay()];
        const month = months[now.getMonth()];
        const dayOfMonth = now.getDate().toString().padStart(2, '0');
        const year = now.getFullYear();
        const hours = now.getHours() % 12 || 12;
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        const ampm = now.getHours() >= 12 ? 'pm' : 'am';
        const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth} ${year}`;
        const formattedTime= `${hours}:${minutes}:${seconds} ${ampm}`; 
        return `<div class="p-5"> 
        <div class="d-flex fs-6 fw-bold align-items-center mb-2">  
        <div class=" me-3">
        <span class="svg-icon svg-icon-muted svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z" fill="black"/>
        <path d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z" fill="black"/>
        <path d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z" fill="black"/>
        </svg></span></div> 
        <div class="text-gray-500 flex-grow-1 me-4">Date :</div> 
        <div class="fw-boldest text-gray-700 text-xxl-end">${formattedDate}</div> 
      </div>
      <div class="d-flex fs-6 fw-bold align-items-center mb-2"> 
        <div class="me-3">
        <span class="svg-icon svg-icon-muted svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"/>
        <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"/>
        </svg></span>
        </div> 
        <div class="text-gray-500 flex-grow-1 me-4">Time :</div> 
        <div class="fw-boldest text-gray-700 text-xxl-end">${formattedTime}</div> 
      </div>
          <div class="d-flex fs-6 fw-bold align-items-center"> 
            <div class="me-3">
            <span class="svg-icon svg-icon-primary svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="black"/>
              <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="black"/>
              </svg></span>
            </div> 
            <div class="text-gray-500 flex-grow-1 me-4">Block Read :</div> 
            <div class="fw-boldest text-gray-700 text-xxl-end">${items[0].data.Read} ${blockReadFormat}</div> 
          </div>
          </div>
        `;
         }
      },
    },
    areaStyle: () => {
      return {
        fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
      };
    },
  };

  // Block Read CHART DESIGN START

   // Block Write CHART DESIGN START
   const [blockWriteInfo, setBlockWriteInfo] = useState([]);
   const [blockWriteFormat, setBlockWriteFormat] = useState('');
 

   const BlockWriteFetch = () => {
          const url='https://console.utho.com/zabbix.php?requesttype=POST&id='+id+'&type=block_stats_write_bytes_sec&period='+dataDuration+'hour';    
     fetch(url)
       .then((response) => response.json())
       .then((json) => { 
        setBlockWriteFormat(json.bytes);
         const transformedData = json.result.map((value,index) => ({
           Date: new Date(value.clock),
           'Write': parseFloat(value.value),
         }));
        ////console.log(transformedData);
         setBlockWriteInfo(transformedData);
       })
       .catch((error) => {
        ////console.log('fetch data failed', error);
       });
   };
  
   const config4 = {
     key:'area4',
     data:blockWriteInfo,
     xField: 'Date',
     yField: 'Write',
     xAxis: {
       type: 'time',
        tickCount: 10,
       tickInterval: 1000 * 60*60 , // one day interval
       //mask: 'YYYY-MM-DD', // date format
       mask: 'HH:mm:ss A', // time format
     },
     tooltip: {
      customContent: (date,items) => {
        // //console.log(title);
         //console.log(items);
         if(items.length>0){
           const now = new Date(items[0].data.Date);
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const dayOfWeek = daysOfWeek[now.getDay()];
        const month = months[now.getMonth()];
        const dayOfMonth = now.getDate().toString().padStart(2, '0');
        const year = now.getFullYear();
        const hours = now.getHours() % 12 || 12;
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        const ampm = now.getHours() >= 12 ? 'pm' : 'am';
        const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth} ${year}`;
        const formattedTime= `${hours}:${minutes}:${seconds} ${ampm}`;
        return `<div class="p-5"> 
        <div class="d-flex fs-6 fw-bold align-items-center mb-2">  
        <div class=" me-3">
        <span class="svg-icon svg-icon-muted svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z" fill="black"/>
        <path d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z" fill="black"/>
        <path d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z" fill="black"/>
        </svg></span></div> 
        <div class="text-gray-500 flex-grow-1 me-4">Date :</div> 
        <div class="fw-boldest text-gray-700 text-xxl-end">${formattedDate}</div> 
      </div>
      <div class="d-flex fs-6 fw-bold align-items-center mb-2"> 
        <div class="me-3">
        <span class="svg-icon svg-icon-muted svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"/>
        <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"/>
        </svg></span>
        </div> 
        <div class="text-gray-500 flex-grow-1 me-4">Time :</div> 
        <div class="fw-boldest text-gray-700 text-xxl-end">${formattedTime}</div> 
      </div>
          <div class="d-flex fs-6 fw-bold align-items-center"> 
            <div class="me-3">
            <span class="svg-icon svg-icon-primary svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="black"/>
              <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="black"/>
              <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="black"/>
              </svg></span>
            </div> 
            <div class="text-gray-500 flex-grow-1 me-4">Block Write :</div> 
            <div class="fw-boldest text-gray-700 text-xxl-end">${items[0].data.Write} ${blockWriteFormat}</div> 
          </div>
          </div>
        `;
         }
      },
    },
     areaStyle: () => {
       return {
         fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
       };
     },
   };
 
   // Block Write CHART DESIGN START


    // Network CHART DESIGN START
    const [networkInfo, setNetworkInfo] = useState([]); 
    const [networkInboundFormat, setNetworkInboundFormat] = useState('');
    const [networkOutboundFormat, setNetworkOutboundFormat] = useState('');  
  
    const NetworkFetch = async() => {
    const inboundData=await UseCharts("POST",'requesttype=POST&id='+id+'&type=interface_stats_receive_bits_sec&period='+dataDuration+'hour');
    const outboundData=await UseCharts("POST",'requesttype=POST&id='+id+'&type=Interface_Transmit&period='+dataDuration+'hour');
    
    if(inboundData && outboundData){
      if(inboundData.result && outboundData.result){
        setNetworkOutboundFormat(inboundData.bytes);
        setNetworkInboundFormat(outboundData.bytes);
    const inData = inboundData.result.map((value,index) => ({
      Date: new Date(value.clock),
      'category':'Inbound Data',
      'value': parseFloat(value.value),
        })); 
      
    const outData = outboundData.result.map((value,index) => ({
      Date: new Date(value.clock),
      'category':'Outbound Data',
      'value': parseFloat(value.value),
        })); 
       ////console.log(inData);
       ////console.log(outData);
    const netData=[...inData, ...outData];
       ////console.log(netData);
    setNetworkInfo(netData); 
      }
    }
    };
   
    const config5 = {
      key:'area5',
      data:networkInfo,
      xField: 'Date',
      yField: 'value',
      seriesField: 'category',
      color: ['#6897a7', '#60d7a7'],
      xAxis: {
        type: 'time',
         tickCount: 10,
        tickInterval: 1000 * 60 * 60 , // one day interval 
        mask: 'HH:mm:ss A', // time format
      },
      yAxis: {
        label: { 
          formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
        },
      },
      tooltip: {
        customContent: (date,items) => {
          // //console.log(title);
           //console.log(items);
           if(items.length>0){
             const now = new Date(items[0].data.Date);
          const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          const dayOfWeek = daysOfWeek[now.getDay()];
          const month = months[now.getMonth()];
          const dayOfMonth = now.getDate().toString().padStart(2, '0');
          const year = now.getFullYear();
          const hours = now.getHours() % 12 || 12;
          const minutes = now.getMinutes().toString().padStart(2, '0');
          const seconds = now.getSeconds().toString().padStart(2, '0');
          const ampm = now.getHours() >= 12 ? 'pm' : 'am';
          const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth} ${year}`;
          const formattedTime= `${hours}:${minutes}:${seconds} ${ampm}`;
          return `<div class="p-5"> 
          <div class="d-flex fs-6 fw-bold align-items-center mb-2">  
          <div class=" me-3">
          <span class="svg-icon svg-icon-muted svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path opacity="0.3" d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z" fill="black"/>
          <path d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z" fill="black"/>
          <path d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z" fill="black"/>
          </svg></span></div> 
          <div class="text-gray-500 flex-grow-1 me-4">Date :</div> 
          <div class="fw-boldest text-gray-700 text-xxl-end">${formattedDate}</div> 
        </div>
        <div class="d-flex fs-6 fw-bold align-items-center mb-2"> 
          <div class="me-3">
          <span class="svg-icon svg-icon-muted svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"/>
          <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"/>
          </svg></span>
          </div> 
          <div class="text-gray-500 flex-grow-1 me-4">Time :</div> 
          <div class="fw-boldest text-gray-700 text-xxl-end">${formattedTime}</div> 
        </div>
        <div class="d-flex fs-6 fw-bold align-items-center mb-2"> 
        <div class="me-3">
        <span class="svg-icon svg-icon-primary svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M13 14.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V14.4H13Z" fill="black"/>
        <path opacity="0.3" d="M4 14.4H20L12.7 21.7C12.3 22.1 11.7 22.1 11.3 21.7L4 14.4Z" fill="black"/>
        </svg></span>
        </div> 
        <div class="text-gray-500 flex-grow-1 me-4">Inbound Data :</div> 
        <div class="fw-boldest text-gray-700 text-xxl-end">${items[0].data.value} ${networkInboundFormat}</div> 
      </div>
      <div class="d-flex fs-6 fw-bold align-items-center"> 
        <div class="me-3">
          <span class="svg-icon svg-icon-success svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black"/>
            <path opacity="0.3" d="M4 9.60002H20L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L4 9.60002Z" fill="black"/>
            </svg>
          </span>
        </div> 
        <div class="text-gray-500 flex-grow-1 me-4">Outbound Data :</div> 
        <div class="fw-boldest text-gray-700 text-xxl-end">${items[1].data.value} ${networkOutboundFormat}</div> 
      </div>
            </div>
          `;
           }
        },
      },
      areaStyle: () => {
        return {
          fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
        };
      },
      legend: {
        position: 'top',
      },
    };
  
    // Network CHART DESIGN START
const modifyDate=(inputDate)=>{ 

// Extract year, month, and day components
const year = parseInt(inputDate.substr(0, 4));
const month = parseInt(inputDate.substr(4, 2)) - 1; // Months are 0-based (0 = January, 1 = February, ...)
const day = parseInt(inputDate.substr(6, 2));

// Create a Date object
const formattedDate = new Date(year, month, day);
return formattedDate;
}

// Bandwidth CHART DESIGN START
const [bandwidthInfo, setBandwidthInfo] = useState([]); 

const [totalBandwidth, setTotalBandwidth] = useState(0); 
const [usedBandwidth, setUsedBandwidth] = useState(0); 

const BandwidthFetch = async() => {
const response=await UseApi("GET",'cloud/'+id+'/statistics/bandwidth?period=month','bandwidths');

if(response){
  if(response.limit) setTotalBandwidth((response.limit/1024).toFixed(0));
  if(response.used) setUsedBandwidth((response.used/1024).toFixed(0));
  var dataInbound=Object.entries(response.in);
  var dataOutbound=Object.entries(response.out);
   
   var i=0
const inData = dataInbound.map((value,index) => ({ 
  'number': i++,
  'category':'Inbound Data',
  'value': parseFloat(value[1]),
  'Date' : modifyDate(value[0])
    })); 
  var j=0;
const outData = dataOutbound.map((value,index) => ({ 
  'number':j++,
  'category':'Outbound Data',
  'value': parseFloat(value[1]),
  'Date' : modifyDate(value[0])
    })); 
   ////console.log(inData);
   ////console.log(outData);
const netData=[...inData, ...outData];
   ////console.log(netData);
    setBandwidthInfo(netData); 
  }
};

const config6 = {
  key:'area6',
  data:bandwidthInfo,
  xField: 'number',
  yField: 'value',
  seriesField: 'category',
  color: ['#6897a7', '#60d7a7'],
  xAxis: {
    type: 'linear',
     tickCount: 5,
    //tickInterval: 1000 * 60 * 60 , // one day interval
    //mask: 'YYYY-MM-DD', // date format
    mask: 'HH:mm:ss A', // time format
  },
  yAxis: {
    label: { 
      formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
    },
  },
  tooltip: {
    customContent: (date,items) => {
      // //console.log(title);
       //console.log(items); 
       if(items.length>0){ 
        // console.log(items);
        const now = new Date(items[0].data.Date);
          const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          const dayOfWeek = daysOfWeek[now.getDay()];
          const month = months[now.getMonth()];
          const dayOfMonth = now.getDate().toString().padStart(2, '0');
          const year = now.getFullYear();
          const hours = now.getHours() % 12 || 12;
          const minutes = now.getMinutes().toString().padStart(2, '0');
          const seconds = now.getSeconds().toString().padStart(2, '0');
          const ampm = now.getHours() >= 12 ? 'pm' : 'am';
          const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth} ${year}`;
          const formattedTime= `${hours}:${minutes}:${seconds} ${ampm}`;
 
      return `<div class="p-5">   
          <div class="d-flex fs-6 fw-bold align-items-center mb-2">  
          <div class=" me-3">
          <span class="svg-icon svg-icon-muted svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path opacity="0.3" d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z" fill="black"/>
          <path d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z" fill="black"/>
          <path d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z" fill="black"/>
          </svg></span></div> 
          <div class="text-gray-500 flex.-grow-1 me-4">Date :</div> 
          <div class="fw-boldest text-gray-700 text-xxl-end">${formattedDate}</div> 
        </div>
        <div class="d-flex fs-6 fw-bold align-items-center mb-2"> 
          <div class="me-3">
          <span class="svg-icon svg-icon-primary svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M13 14.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V14.4H13Z" fill="black"/>
          <path opacity="0.3" d="M4 14.4H20L12.7 21.7C12.3 22.1 11.7 22.1 11.3 21.7L4 14.4Z" fill="black"/>
          </svg></span>
          </div> 
          <div class="text-gray-500 flex-grow-1 me-4">Inbound Data :</div> 
          <div class="fw-boldest text-gray-700 text-xxl-end">${(items[0].data.value/1024).toFixed(2)} Gb</div> 
        </div>
        <div class="d-flex fs-6 fw-bold align-items-center"> 
          <div class="me-3">
            <span class="svg-icon svg-icon-success svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z" fill="black"/>
              <path opacity="0.3" d="M4 9.60002H20L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L4 9.60002Z" fill="black"/>
              </svg>
            </span>
          </div> 
          <div class="text-gray-500 flex-grow-1 me-4">Outbound Data :</div> 
          <div class="fw-boldest text-gray-700 text-xxl-end">${(items[1].data.value/1024).toFixed(2)} Gb</div> 
        </div>
        </div>
      `;
       }
    },
  },
  areaStyle: () => {
    return {
      fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
    };
  },
  legend: {
    position: 'top',
  },
};

// Bandwidth CHART DESIGN START


  useEffect(() => { 
    asyncFetchMemory();
    asyncFetch();
    BlockReadFetch();
    BlockWriteFetch();
    NetworkFetch();
    BandwidthFetch();
  }, [dataDuration]);  

  var query = window.location.href; 
  var vars = query.split("/");
  var id=vars[4]; 
  const cpuFetch=useRef(false); 
  
//   const [CpuData, setCpuData]=useState([]); 
//   const [MemoryData, setMemoryData]=useState([]);
//   const [BlockReadData, setBlockReadData]=useState([]);
//   const [BlockWriteData, setBlockWriteData]=useState([]); 
//   const [NetworkData, setNetworkData]=useState([]);
//   const [BandwidthData, setBandwidthData]=useState([]);  
  
  
//   const BandwidthUsage=async()=>{
//     const response=await UseApi("GET",'cloud/'+id+'/statistics/bandwidth?period=month','bandwidths');
//     if(response){ 
//      var bwidth=[]; 
//       bwidth.push(["Number", "Inbound", "Outbound"]);
//       bwidth.push([null, 0, 0]); 
//       ////console.log(response);
//       if(response.in && response.out){
//       var inData=Object.entries(response.in);
//       var outData=Object.entries(response.out);
//       ////console.log(inData);
//       ////console.log(outData);
//       for(var i=0;i<inData.length;i++){ 
//        // //////console.log(value);  

//        bwidth.push([inData[i][0]%10,parseFloat(inData[i][1]),parseFloat(outData[i][1])]); 
//       }
//     }
//       ////console.log(bwidth);
//       setBandwidthData(bwidth);
//     } 
//   }
//   const BandwidthOptions = {
     
//     hAxis: { title: "Bandwidth Usages", titleTextStyle: { color: "#333" } },
//     vAxis: {maxValue:0 }, 
//     chartArea: { width: "90%", height: "80%" },
//     legend: { position: "top"},
//     responsive: true,
//     }; 
//   const CpuUsage=async()=>{
//     const response=await UseCharts("POST",'id='+id+'&type=vCPU_usage&period=4hour');
//     var cpu=[];
//     if(response){ 
//       var cpu=[];
//       cpu.push(["Date", "CPU Utilization ", { role: "tooltip", type: "string", showColorCode: true, p: { html: true } }]);
//       cpu.push([null, 0, null]);
//       if(response.result){
//       var cpuDatas=response.result;
//       cpuDatas.map((value,index)=>{ 
//        // //////console.log(value); 
//        var dt=new Date(value.clock);
//        var date=dt.toLocaleDateString("en-US", {
//         month: "short",
//         day: "numeric",
//         year: "numeric", 
//       }).replace(/,/g, "");
//       var dateTime=dt.toLocaleTimeString("en-US", { 
//         hour: "numeric",
//         minute: "2-digit",
//         second: "2-digit",
//         hour12: true
//       });
//        cpu.push([new Date(value.clock),parseFloat(value.value),`<div class="p-3">Date : <strong>${date}</strong><br>Time : <strong>${dateTime}</strong><hr><span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color:#ffa600" class="fw-bold">CPU Utilization : <strong class="text-dark">${value.value}%</strong></span></div>`]); 
//       })
//     }
//       setCpuData(cpu);  
//     } 
//   }
//   useEffect(()=>{
//     if(cpuFetch.current)return;
//     cpuFetch.current=true;
//    CpuUsage();
//    MemoryUsage();
//    BlockReadDataFunc();
//    BlockWriteDataFunc();
//    NetworkDatas();
//    BandwidthUsage();
//   },[])

  
// const CpuOptions = {
//   colors: ["#ffa600"],
//   hAxis: { title: "CPU Usage", titleTextStyle: { color: "#333" }, },
//   vAxis: {minValue:0 }, 
//   chartArea: { width: "80%", height: "50%" },
//   legend: { position: "top"},
//   responsive: true,
  
//   tooltip: {
//     isHtml: true,
//     // textStyle: { 
//     //   fontName: 'Arial',
//     //   fontSize: 11,
//     // },
//     //  showColorCode: true, 
//     //ignoreBounds: true,
//     //isStacked: true,
//     // custom: function ({ column, rowIndex }) {
//     //   if (column === 1) {
//     //     const value = CpuData[rowIndex + 1][column];
//     //     return `<div>value</div>`;
//     //   }
//     //   return null;
//     // },
//   },
//   // tooltip: {
//   //   callbacks: {
//   //     label: (context) => {
//   //       const label = context.dataset.label || '';
//   //       ////console.log(context); 
//   //       return "CPU Usage"+context.raw+" gb";
//   //     },
//   //   },
//   // },
//   };
  
  
//   const MemoryUsage=async()=>{
//     const response=await UseCharts("POST",'id='+id+'&type=Memory_Used&period=4hour');
//     var memory=[];
//     if(response){
//       memory.push(["Date", "Memory Usage (GB) "]);
//       memory.push([null, 0]);
//       if(response.result){
//       var MemoryDatas=response.result;
//       MemoryDatas.map((value,index)=>{
//        // //////console.log(value); 
//        memory.push([new Date(value.clock),parseFloat(value.value)]); 
//       })  
//     }
//       setMemoryData(memory);
//     } 
//   } 
//    const MemoryOptions = {
     
//   hAxis: { title: "Memory Usage", titleTextStyle: { color: "#333" } },
//   vAxis: {title:"Memory ( GB ) ",maxValue:1 }, 
//   chartArea: { width: "80%", height: "80%" },
//   legend: { position: "top"},
//   responsive: true,
//   };



//   const BlockReadDataFunc=async()=>{
//     const response=await UseCharts("POST",'id='+id+'&type=block_stats_read_bytes_sec&period=4hour');
//     var read=[];
//     if(response){
//       read.push(["Date", "Read (Bits) "]);
//       read.push([null, 0]);
//       if(response.result){
//       var ReadDatas=response.result;
//       ReadDatas.map((value,index)=>{
//        // //////console.log(value); 
//        read.push([new Date(value.clock),parseFloat(value.value)]); 
//       }) 
//     }
//       setBlockReadData(read);
//     }
// //     const darr= await axios({
// //       method: 'POST',
// //       url:'https://cloud.microhost.com/zabbix.php?id='+id+'&type=block_stats_read_bytes_sec&period=4hour',
// //   }).then(function(response){ 

// // //      //////console.log(response.data.result);
// //       var cpuDatas=response.data.result;
// //       BlockReadData.push(["Period", "Read"]);
// //       cpuDatas.map((value,index)=>{
// //        // //////console.log(value); 
// //        BlockReadData.push([value.clock,parseFloat(value.value)]); 
// //       }) 
// //       //////console.log(CpuData); 
// //   })
//   } 
//    const BlockReadOptions = {
     
//   hAxis: { title: "Read", titleTextStyle: { color: "#333" } },
//   vAxis: {title:"Read ( Bits ) ",maxValue:1 }, 
//   chartArea: { width: "80%", height: "80%" },
//   legend: { position: "top"},
//   responsive: true,
//   }; 


//   const BlockWriteDataFunc=async()=>{
//     const response=await UseCharts("POST",'id='+id+'&type=block_stats_write_bytes_sec&period=4hour');
//     var write=[];
//     if(response){
//      // ////console.log(response);
//     write.push(["Date", "Write  (Bits) "]);
//     write.push([null, 0]);
//     if(response.result){
//     var WriteDatas=response.result;
//       WriteDatas.map((value,index)=>{
//        // //////console.log(value); 
//        write.push([new Date(value.clock),parseFloat(value.value)]); 
//       }) 
//     }
//       setBlockWriteData(write);
//     }
// //     const darr= await axios({
// //       method: 'POST',
// //       url:'https://cloud.microhost.com/zabbix.php?id='+id+'&type=block_stats_write_bytes_sec&period=4hour',
// //   }).then(function(response){ 

// // //      //////console.log(response.data.result);
// //       var cpuDatas=response.data.result;
// //       BlockWriteData.push(["Period", "Write"]);
// //       cpuDatas.map((value,index)=>{
// //        // //////console.log(value); 
// //        BlockWriteData.push([value.clock,parseFloat(value.value)]); 
// //       }) 
// //       //////console.log(CpuData); 
// //   })
//   } 
//    const BlockWriteOptions = {
     
//   hAxis: { title: "Write", titleTextStyle: { color: "#333" } },
//   vAxis: {title:"Write ( Bits ) ",maxValue:1 }, 
//   chartArea: { width: "80%", height: "80%" },
//   legend: { position: "top"},
//   responsive: true,
//   }; 


//   const NetworkDatas=async()=>{
//     const inboundData=await UseCharts("POST",'id='+id+'&type=interface_stats_receive_bits_sec&period=4hour');
//     const outboundData=await UseCharts("POST",'id='+id+'&type=Interface_Transmit&period=4hour');
//     //////console.log(inboundData);
//     //////console.log(outboundData);
//     var traffic=[];
//     if(inboundData && outboundData){ 
//       traffic.push(["Date", "Inbound (KBits) ", "Outbound (KBits) "]); 
//       traffic.push([null, 0, 0]);
//       if(inboundData.result && outboundData.result){
//       for(var i=0;i<inboundData.result.length;i++){ 
//        // //////console.log(value); 
//        if(inboundData.result[i] && outboundData.result[i]){
//         if(inboundData.result[i].value && outboundData.result[i].value && inboundData.result[i].clock){ 
//        traffic.push([new Date(inboundData.result[i].clock),parseFloat(inboundData.result[i].value),parseFloat(outboundData.result[i].value)]);       
//          }
//         }
//       }
//     }
//       setNetworkData(traffic);  
//     } 
//   } 
//    const NetworkOptions = {
     
//   hAxis: { title: "Network Traffic", titleTextStyle: { color: "#333" } },
//   vAxis: {title:"Network ( Kbits ) ",maxValue:1 }, 
//   chartArea: { width: "80%", height: "80%" },
//   legend: { position: "top"},
//   responsive: true,
//   }; 
    
    return(
        <>
        <div className="post flex-column-fluid" id="kt_post">
      {/*begin::Container*/}
      <div id="" className="">
        {/*begin::Row*/}
        <div className="row g-5 g-xl-8">
          <div className="col-xl-6">
            {/*begin::Charts Widget 2*/}
            <div className="card card-xl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder fs-3 mb-1">
                    CPU Utilization
                  </span>
                  <span className="text-muted fw-bold fs-7">
                    Monitor your CPU utilization here
                  </span>
                </h3> 
                <div className="card-toolbar">
                <div className="w-100 mw-150px" data-select2-id="select2-data-122-q0bu">
  {/*begin::Select2*/}
  <select
    className="form-select form-select-sm form-select-solid "
    //data-control="select2"
    // data-hide-search="true"  
    // data-select2-id="select2-data-10-prjn"
    // tabIndex={-1}
    // aria-hidden="true"
    defaultValue={4}
    onChange={(e)=>setDataDuration(e.target.value)}
  > 
    <option value="4" >
      4 hours
    </option>
    <option value="1">
      1 hour
    </option>
    
  </select>
  {/*end::Select2*/}
</div>

     
                </div>

              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body" id="chart-cpu-id">
                 {data.length>0?(<>
                  <Area {...config} />
                {/* <Chart
                  chartType="AreaChart"
                  width="100% "
                  height="100%"
                  data={CpuData}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
                  options={CpuOptions} 
                /> */}
                </>):(<><div className="flex flex-sib">
                <div>
                  <h4 className='w-100'>Graph data not available yet</h4>
    {/* <div class="ml-loader d-none">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div> */}
  </div>
                  </div></>)} 
              </div>
              {/*end::Body*/}
            </div>
            {/*end::Charts Widget 2*/}
          </div>
          {/*end::Row*/}
          {/*begin::Row*/}
          <div className="col-xl-6">
            {/*begin::Charts Widget 3*/}
            <div className="card card-xl-stretch mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder fs-3 mb-1">
                    Memory Utilization
                  </span>
                  <span className="text-muted fw-bold fs-7">
                    Monitor your Memory utilization here
                  </span>
                </h3>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body" id="chart-memory-id"> 
              {memoryInfo.length>0?(<>
                <Area {...config2} />
                {/* <Chart
                  chartType="AreaChart"
                  width="100% "
                  height="400px"
                  data={MemoryData}
                  options={MemoryOptions}
                /> */}
                </>):(<><div class="flex flex-sib">
                <h4 className='w-100'>Graph data not available yet</h4>
                  </div></>)}
               
              </div>
              {/*end::Charts Widget 3*/}
            </div>
          </div>
        </div>
        <div className="row g-5 g-xl-8">
          <div className="col-xl-6">
            {/*begin::Charts Widget 4*/}
            <div className="card card-xl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder fs-3 mb-1">
                    Block IO/s Read
                  </span>
                  <span className="text-muted fw-bold fs-7">
                    Monitor your Block IO/s Read here
                  </span>
                </h3>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body" id="chart-readbytes-id">
                
              
              {blockReadInfo.length>0?(<>
                <Area {...config3} />
                {/* <Chart
                  chartType="AreaChart"
                  width="100%"
                  height="400px"
                  data={BlockReadData}
                  options={BlockReadOptions}
                /> */}
                </>):(<><div class="flex flex-sib">
                <h4 className='w-100'>Graph data not available yet</h4>
                </div></>)}
              
               
              </div>
              {/*end::Body*/}
            </div>
            {/*end::Charts Widget 4*/}
          </div>
          {/*end::Row*/}
          {/*begin::Row*/}
          <div className="col-xl-6">
            {/*begin::Charts Widget 5*/}
            <div className="card card-xl-stretch mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder fs-3 mb-1">
                    Block IO/s Write
                  </span>
                  <span className="text-muted fw-bold fs-7">
                    Monitor your Block IO/s Write here
                  </span>
                </h3>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body" id="chart-writebytes-id">
                
              {blockWriteInfo.length>0?(<>
                <Area {...config4} />
                {/* <Chart
                  chartType="AreaChart"
                  width="100%"
                  height="400px"
                  data={BlockWriteData}
                  options={BlockWriteOptions}
                /> */}
                </>):(<><div class="flex flex-sib">
                <h4 className='w-100'>Graph data not available yet</h4>
                 </div></>)}
              
                 
              </div>
              {/*end::Body*/}
            </div>
            {/*end::Charts Widget 5*/}
          </div>
        </div>
        <div className="row g-5 g-xl-8">
          <div className="col-xl-6">
            {/*begin::Charts Widget 5*/}
            <div className="card card-xl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder fs-3 mb-1">
                    Network Traffic
                  </span>
                  <span className="text-muted fw-bold fs-7">
                    Monitor your Network traffic here
                  </span>
                </h3>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body" id="chart-network-id">
               {networkInfo.length>0?(<>
                <Area {...config5} />
                {/* <Chart
                  chartType="AreaChart"
                  width="100%"
                  height="400px"
                  data={NetworkData}
                  options={NetworkOptions}
                /> */}
                </>):(<><div class="flex flex-sib">
                <h4 className='w-100'>Graph data not available yet</h4>
                </div></>)}
             
              
              </div>
              {/*end::Body*/}
            </div>
            {/*end::Charts Widget 5*/}
          </div>
          {/*end::Row*/}
          {/*begin::Row*/}
          <div className="col-xl-6">
            {/*begin::Charts Widget 7*/}
            <div className="card card-xl-stretch mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder fs-3 mb-1">
                    Daily Public Bandwidth Usages
                  </span>
                  <p><span className="badge badge-primary me-3">Current Month Total : {totalBandwidth}GB</span>
                  <span className="badge badge-warning">Used: {usedBandwidth}GB</span></p>
                  {/* <span className="text-muted fw-bold fs-7">
                    Daily Public Bandwidth Usages - Current Month Total
                    :1000GB Used:2GB
                  </span> */}
                </h3>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body" id="chart-perday-id">
              
              {bandwidthInfo.length>0?(<>
                <Area {...config6} />
                {/* <Chart
                  chartType="AreaChart"
                  width="100%"
                  height="400px"
                  data={BandwidthData}
                  options={BandwidthOptions}
                /> */}
                </>):(<><div class="flex flex-sib">
                <h4 className='w-100'>Graph data not available yet</h4>
                   </div></>)}
             
                 
              </div>
              {/*end::Body*/}
            </div>
            {/*end::Charts Widget 7*/}
          </div>
        </div>
      </div>
      {/*end::Row*/}
    </div>
        </>
    )
}


 