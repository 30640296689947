import React from "react"; 
import { useEffect } from "react";
import { useNavigate } from "react-router-dom/dist";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../CustomHooks/usePost";

export default function DnsDeploy(){
  toast.dismiss();
  let navigate=useNavigate();
  const deployDns=async()=>{ 
    var submitBtn=document.querySelector("#dns-btn");
    var domainName=document.querySelector("#domain");
   
    if(submitBtn){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
    const dt=toast.loading("Please wait...");
    if(domainName){
      if(domainName.value==""){
        submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
        toast.update(dt,{render: "Domain name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
        return;
      }
    }  
    const responseData=await UsePost('post','dns/adddomain','adddomain');
    if(responseData){
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      //console.log(responseData);
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      
      navigate("/dns");
    }
  }
      }
    }
     
    return(
        <> 
        <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold fs-3 mb-1">DNS </span>
        <span className="text-muted mt-1 fw-bold fs-7">Deploy your DNS</span>
      </h3>
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    <div className="card-body py-3">
      {/*begin::Modal header*/}
      <div className="modal-header d-none" id="kt_modal_create_api_key_header">
        {/*begin::Modal title*/}
        <h2>Create New Domain</h2>
        {/*end::Modal title*/}
      </div>
      {/*end::Modal header*/}
      {/*begin::Form*/}
      <form id="adddomain" onSubmit={(event)=>event.preventDefault()} className="form">
        {/*begin::Modal body*/}
        <div className="modal-body py-10 px-lg-17">
          {/*begin::Scroll*/}
          <div
            className="scroll-y me-n7 pe-7"
            id="kt_modal_create_api_key_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
            data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
            data-kt-scroll-offset="300px"
          >
            {/*begin::Input group*/}
            <div className="mb-5 fv-row">
              {/*begin::Label*/}
              <label className="required fs-5 fw-bold mb-2"> Domain Name</label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <input
                type="text"
                className="form-control"
                name="domain"
                id="domain"
                placeholder="mydomain.xyz"
              />
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Modal body*/}
        {/*begin::Modal footer*/}
        <div className=" flex-center">
          {/*begin::Button*/}
          <button 
            style={{ width: "100%" }}
            onClick={deployDns}
            className="btn btn-primary"
            id="dns-btn"
          >
            <span className="indicator-label">Deploy DNS</span>
          </button>
          {/*end::Button*/}
        </div>
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
    </div>
    {/*begin::Body*/}
  </div>
  {/*end::Tables Widget 9*/}
</div>

        </>
        
    )
}