import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert"; 
export default function ManageVpc(){
  const navigate=useNavigate();
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4];
  const [vpcData,setVpcData]=useState(null);
  const dataFetchedRef=useRef (false);
  const getVPC=async()=>{
    
     
       const data=await UseApi('get','vpc/'+id,''); 
       //console.log(Object.keys(data).length);
       if(Object.keys(data).length <= 2){
        swal({
            title: "VPC",
            text: "No VPC record found with id: "+id+"!",
            icon: "warning",
            button: "OK",
          }).then(()=>{
            navigate("/vpc");
          })
            return;
       }else{
        setVpcData(data);  
          //   toast.update(dt,{render: "Your VPC info listed below", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          // });  
          
       }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getVPC(); 
  },[]); 
  //console.log(vpcData);

  const destroyvpc=async()=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this VPC!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("Loadbalancer deleting..."); 
    const dnsp=await UseApi('DELETE','vpc/'+id+'/destroy','');
   // //console.log(dnsp);
    if(dnsp.status=='error'){
      toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else{
      toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });  
    navigate('/vpc');
    }
  }
      });
  }
    return(
        <>
       
        <div className="col-xl-12">
          {vpcData!=null?(<>
  <div className="post d-flex flex-column-fluid">
    <div className="container-xxl" id="appresult">
      <div className="card mb-6 mb-xl-9">
        <div className="card-body pt-9 pb-0">
          {/*begin::Details*/}
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            {/*begin: Pic*/}
            <div className="me-7 mb-4">
              <div
                id="flag-icon"
                className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
              >
                {/*begin::Image input*/}
                <div
                  className="image-input image-input-empty"
                  data-kt-image-input="true"
                >
                  {" "}
                  <img
                    src={"/img/flag_"+vpcData.dclocation.dccc+".png"}
                    className="image-input-wrapper w-40px h-40px"
                  />{" "}
                </div>
                {/*end::Image input*/}
              </div>
            </div>
            {/*end::Pic*/}
            {/*begin::Info*/}
            <div className="flex-grow-1">
              {/*begin::Title*/}
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                {/*begin::User*/}
                <div className="d-flex flex-column">
                  {/*begin::Name*/}
                  <div className="d-flex align-items-center mb-2">
                    <a
                      
                      className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                      id="head-name"
                    >
                      {vpcData.name}
                    </a>
                  </div>
                  {/*end::Name*/}
                  {/*begin::Info*/}
                  <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                    <div
                      className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400"
                      id="location"
                    >
                      {vpcData.dclocation.location}
                    </div>
                  </div>
                  {/*end::Info*/}
                </div>
                {/*end::User*/}
              </div>
              {/*end::Title*/}
              {/*begin::Stats*/}
              <div className="d-flex flex-wrap flex-stack">
                {/*begin::Wrapper*/}
                <div className="d-flex flex-column flex-grow-1 pe-8">
                  {/*begin::Stats*/}
                  <div className="d-flex flex-wrap">
                    {/*begin::Stat*/}
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      {/*begin::Number*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                        <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                          {/*begin::Svg Icon | path: assets/media/icons/duotune/abstract/abs027.svg*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                              fill="black"
                            />
                            <path
                              d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                              fill="black"
                            />
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                        {/*end::Svg Icon*/}
                        <div className="fs-2 fw-bold" id="vpc-id">
                          {vpcData.network}
                        </div>
                      </div>
                      {/*end::Number*/}
                      {/*begin::Label*/}
                      <div className="fw-bold fs-6 text-gray-400">VPC</div>
                      {/*end::Label*/}
                    </div>
                    {/*end::Stat*/}
                    {/*begin::Stat*/}
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      {/*begin::Number*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                        <span className="svg-icon svg-icon-3 svg-icon-primary me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z"
                              fill="black"
                            />
                            <path
                              opacity="0.3"
                              d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z"
                              fill="black"
                            />
                            <path
                              d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                        <div className="fs-2 fw-bold" id="vpc-address">
                          {vpcData.available}
                        </div>
                      </div>
                      {/*end::Number*/}
                      {/*begin::Label*/}
                      <div className="fw-bold fs-6 text-gray-400">
                        Available Addresses
                      </div>
                      {/*end::Label*/}
                    </div>
                    {/*end::Stat*/}
                  </div>
                  {/*end::Stats*/}
                </div>
                {/*end::Wrapper*/}
                {/*begin::Actions*/}
                <div className="d-flex mb-4 ">
                  <div className="text-gray-800 fs-1 me-3">Status: </div>
                  <a  className=" btn btn-sm btn-success">
                    Running
                  </a>
                </div>
                {/*end::Actions*/}
                {/*begin::Progress*/}
                {/*end::Progress*/}
              </div>
              {/*end::Stats*/}
            </div>
            {/*end::Info*/}
          </div>
          {/*end::Details*/}
          <div className="separator" />
          {/*begin::Nav*/}
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            {/*begin::Nav item*/}
            <li className="nav-item">
              <a
                className="nav-link text-active-primary py-5 me-6 active"
                data-bs-toggle="tab"
                href="#resources"
              >
                Resources
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#destroy_vpc">
                Destroy VPC
              </a>
            </li>
            {/*end::Nav item*/}
          </ul>
          {/*end::Nav*/}
        </div>
      </div>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="resources"
          role="tabpanel"
        >
          {/*begin::License usage*/}
          <div className="card">
            {/*begin::Card header*/}
            <div className="card-header">
              {/*begin::Heading*/}
              <div className="card-title">
                <h3>VPC Resources</h3>
              </div>
              {/*end::Heading*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body">
              {/*begin::Table wrapper*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
                  id="kt_security_license_usage_table"
                >
                  {/*begin::Table head*/}
                  <thead>
                    <tr className="fw-bold ">
                      <th className="min-w-250px">Name</th>
                      <th className="min-w-200px">IP</th>
                      <th className="min-w-50px" />
                      <th className="min-w-50px" />
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  <tbody id="vpc-list">
                    {vpcData!=null?(vpcData.resources && vpcData.resources.map((value,index)=>(
                    <tr>
                      {" "}
                      <td className="fw-bold">
                        {" "}
                        <div className="mt-1 fw-bold symbol symbol-45px me-2">
                          <Link to={"/cloud/"+value.id}>
                          <a  >
                            {value.name}{" "}
                          </a>
                          </Link>
                          {" "}
                          ({value.type}){" "}
                        </div>{" "}
                      </td>{" "}
                      <td className="fw-bold">
                        {" "}
                        <div className="mt-1 symbol symbol-45px me-2">
                          {" "}
                          {value.ip}
                          {" "}
                        </div>{" "}
                      </td>{" "}
                      <td className="text-end">
                        {" "}
                        <Link to={"/cloud/"+value.id}>
                        <a
                          
                          className="btn btn-success btn-sm" 
                        >
                          View Cloud{" "}
                        </a>
                        </Link>
                      </td>{" "}
                      <td className="text-end"> </td>{" "}
                    </tr>
                    ))):('')}
                    
                  </tbody>
                </table>
              </div>
              {/*end::Table wrapper*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::License usage*/}
        </div>
        <div className="tab-pane fade" id="destroy_vpc" role="tabpanel">
          {/*begin::Deactivate Account*/}
          <div className="card">
            {/*begin::Card header*/}
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_deactivate"
              aria-expanded="true"
              aria-controls="kt_account_deactivate"
            >
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Destroy VPC</h3>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Content*/}
            <div id="kt_account_settings_deactivate" className="collapse show">
              {/*begin::Form*/}
              <form id="destroyform" className="form">
                {/*begin::Card body*/}
                <div className="card-body border-top p-9">
                  {/*begin::Notice*/}
                  <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                    {/*begin::Icon*/}
                    {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                    <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={10}
                          fill="black"
                        />
                        <rect
                          x={11}
                          y={14}
                          width={7}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11 14)"
                          fill="black"
                        />
                        <rect
                          x={11}
                          y={17}
                          width={2}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11 17)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                    {/*end::Icon*/}
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-stack flex-grow-1">
                      {/*begin::Content*/}
                      <div className="fw-bold">
                        <h4 className="text-gray-900 fw-bold">
                          This will destroy your VPC.
                        </h4>
                        <div className="fs-6 text-gray-700">
                          Note: This option will delete your VPC from the server
                          for permanently and this not be able to undo.
                        </div>
                      </div>
                      {/*end::Content*/}
                    </div>
                    {/*end::Wrapper*/}
                  </div>
                  {/*end::Notice*/}
                  {/*begin::Form input row*/}
                  <div className="mb-10">
                    <label htmlFor="size" className="required form-label">
                      Your VPC will be permanently destroyed. You will lose the
                      provisioned Network addresses.
                    </label>
                  </div>
                  {/*end::Form input row*/}
                </div>
                {/*end::Card body*/}
                {/*begin::Card footer*/}
                <div
                  className="card-footer d-flex justify-content-end py-6 px-9"
                  id="destroy_btn"
                >
                  <button
                    id="destroy-btn"
                    onClick={destroyvpc}
                   // onclick="call_api('DELETE','vpc/undefined/destroy','destroy','refdestroy','#vpc')"
                    type="button"
                    className="btn btn-danger fw-bold"
                  >
                    Destroy VPC
                  </button>
                </div>
                {/*end::Card footer*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Deactivate Account*/}
        </div>
      </div>
    </div>
  </div>
  </>):(<><div class="loading-spinner2"></div></>)}
</div>

        </>
    )
}