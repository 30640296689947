import React, { useRef, useEffect, useState } from 'react'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import './custom.css';
import UsePost from '../../../../../CustomHooks/usePost';
import OtpVerify from './OtpVerify';
import UseApi from '../../../../../CustomHooks/useApi';
const MobileVerification = () => { 
  function extractCountryCodeAndMobileNumber(phoneNumber) {
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
    if (!parsedPhoneNumber) {
      return {
        countryCode: '',
        mobileNumber: '',
      };
    }
  
    const countryCode = `+${parsedPhoneNumber.countryCallingCode}`;
    const mobileNumber = parsedPhoneNumber.nationalNumber;
  
    return {
      countryCode,
      mobileNumber,
    };
  }
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleInputChange = (value) => {
    setPhoneNumber(value);
  };

  const verifyMobile=async()=>{  
    const { countryCode, mobileNumber }=extractCountryCodeAndMobileNumber(phoneNumber); 
    
    if(!countryCode && !mobileNumber){
      const dt=toast.loading("Please wait...");
      toast.update(dt,{render: "Please provide valid mobile number!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        // console.log(countryCode+" "+mobileNumber);
       return;
    }
   
    var mobile_code=document.querySelector("#mobile_code");
    var mobile_no=document.querySelector("#mobile_no");
    if(mobile_code && mobile_no){
      mobile_code.value=countryCode;
      mobile_no.value=mobileNumber;
    }
      var submitBtn=document.querySelector("#mobile-btn");
        if(submitBtn){
          var preContent=submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';  
      const dt=toast.loading("Please wait...");
      const responseData=await UsePost('post','account/update/mobile/'+countryCode,'verify-mobile');
        if(responseData){
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML=preContent;
        if(responseData.status=="error"){
          toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        }else{
          const responseData2=await UsePost('post','account/otp/sendotp','nothing');
          if(responseData2){  
          if(responseData2.status=="success"){
          toast.update(dt,{render: "OTP has been sent to "+phoneNumber, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        }); 
         var mobile_verify_sec=document.querySelector("#mobile-verify-sec");
         var otp_verify_sec=document.querySelector("#otp-verify-sec");
         if(mobile_verify_sec && otp_verify_sec){
          mobile_verify_sec.classList.add("d-none");
          otp_verify_sec.classList.remove("d-none");
         }
        }else{
          toast.update(dt,{render: responseData2.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        }); 
        } 
      }
        }
      }
    } 
  } 

const toggle_section=()=>{
  var mobile_verify_sec=document.querySelector("#mobile-verify-sec");
  var otp_verify_sec=document.querySelector("#otp-verify-sec"); 
  var back_otp=document.querySelector("#back_otp");

  if(mobile_verify_sec && otp_verify_sec && back_otp){
  mobile_verify_sec.classList.toggle("d-none");
  otp_verify_sec.classList.toggle("d-none"); 
  back_otp.classList.toggle("d-none");
  }
}
  const checkIsMobile=async ()=>{ 
    var mobile_verify_sec=document.querySelector("#mobile-verify-sec");
    var otp_verify_sec=document.querySelector("#otp-verify-sec");
    var loader_spinner=document.querySelector("#loader_spinner");

    if(mobile_verify_sec && otp_verify_sec && loader_spinner){
    mobile_verify_sec.classList.add("d-none");
    otp_verify_sec.classList.add("d-none");
    loader_spinner.classList.remove("d-none");
    }
    const user_data= await UseApi("get","account/info", "");
          if(user_data){
            if(user_data.user){
             if(user_data.user.mobile && user_data.user.mobilecc){
              const responseData2=await UsePost('post','account/otp/sendotp','nothing');
              if(responseData2){  
                setPhoneNumber(user_data.user.mobilecc+user_data.user.mobile);
                const dt=toast.loading("Please wait...");
              if(responseData2.status=="success"){

                var phoneNo=user_data.user.mobilecc+" "+user_data.user.mobile;
              toast.update(dt,{render: "OTP has been sent to "+phoneNo, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });  
             if(mobile_verify_sec && otp_verify_sec && loader_spinner){
              loader_spinner.classList.add("d-none");
              mobile_verify_sec.classList.add("d-none");
              otp_verify_sec.classList.remove("d-none");
             }
            }else{
              toast.update(dt,{render: responseData2.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            }); 
            } 
          }
             }else{
              if(mobile_verify_sec && otp_verify_sec && loader_spinner){
                loader_spinner.classList.add("d-none");
                mobile_verify_sec.classList.remove("d-none");
                otp_verify_sec.classList.add("d-none");
               }
             }
            }
          }
  }

  useEffect(()=>{
    checkIsMobile();
  },[]);
    return (
        <>
        <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />  
        <OtpVerify updateMobile={toggle_section} /> 

<div className='w-lg-600px p-10 p-lg-15 mx-auto' id="mobile-verify-sec">
  <form id="verify-mobile">
  <input type='hidden' name="mobilecc" id="mobile_code"/>
  <input type='hidden' name="mobile" id="mobile_no"/>
  </form>
        <form className="form w-100 mb-10" noValidate="" id="code_form">
  <div className="text-center mb-10">
    <img
      alt="Logo"
      className="mh-125px"
      src="assets/media/svg/misc/smartphone.svg"
    />
  </div> 
   {/*begin::Heading*/}
   <div className="text-center mb-10">
          {/*begin::Title*/}
          <h1 className="text-light mb-3">Verify Mobile Number</h1>
          {/*end::Title*/}
          {/*begin::Link*/}
          <div className="text-gray-400 fw-bold fs-4">
            Enter your mobile number to verify your identity.
          </div>
          {/*end::Link*/}
        </div>
        {/*begin::Heading*/} 
  <div className="mb-10 px-md-10">
    <div className="fw-bolder text-start text-gray-400 fs-6 mb-5 ms-1 d-none">
    Enter your mobile number to verify your identity.    </div>
    <div className="d-flex flex-wrap flex-stack">
    <PhoneInput
      international
      defaultCountry="IN"
      value={phoneNumber}
      onChange={handleInputChange}
      className="form-control customCss"
    />
    </div>
  <div className="d-flex flex-center mt-10">
    <a onClick={verifyMobile}
      className="btn btn-lg btn-primary fw-bolder w-100"
      id="mobile-btn"
    >
      <span className="indicator-label"  >Submit</span>
    </a>
  </div>

  </div>
</form>
</div>
<div className="text-center fw-bold fs-5 mt-5 d-none" id="back_otp">
        <span className="text-muted me-1">Back to verify OTP, </span>
        <a onClick={toggle_section} className="link-primary fw-bolder fs-5 me-1" style={{cursor:"pointer"}}>
          click here
        </a> 
      </div>   
<div className="d-none"  id="loader_spinner">
<div class="loading-spinner2"></div>
</div>
        </>
    );
}

export default MobileVerification;
