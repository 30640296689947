import React, { useEffect, useState } from 'react';

const Backup = (props) => {
const [backupEnabled, setBackupEnabled]=useState(props.isEnabled);
const handleBackupChange=()=>{
  var tickMarkbackup=document.querySelector("#tickMarkbackup");
  var borderBackup=document.querySelector("#borderBackup");
  if(props && tickMarkbackup && borderBackup){ 
  if(backupEnabled==true){
    tickMarkbackup.classList.remove("d-none");
    borderBackup.classList.add("activeBorder");
    setBackupEnabled(false);
    props.updateBackupService(true);
  
  }else{
    tickMarkbackup.classList.add("d-none");
    borderBackup.classList.remove("activeBorder");
    setBackupEnabled(true);
    props.updateBackupService(false);
    
  }
  }
}
useEffect(()=>{
  handleBackupChange();
},[])
    return (
        <>
              <div className="p-7">
                  <div
                    className=" card pt-7 border border-2 border-gray-300" 
                    id="borderBackup"
                  >
                    <div className="">
                      <div className="row">
                        <div className="col-8" style={{ paddingLeft: "51px" }}>
                          <h3 className="card-title align-items-start flex-column">
                            <p>
                              <span className="card-label fw-bolder text-gray-800">
                                Auto Backup
                              </span>
                            </p>
                            <span className="text-gray-400 mt-1 fw-bold fs-6">
                              Automatic backup are highly recommend for
                              mission-critical systems. Once backed up, you can
                              easily recover from disaster by spinning up a new
                              instance from a saved image.
                            </span>
                          </h3>
                          <div className="pt-8">
                            {/* <input
                              type="hidden"
                              className="form-control"
                              defaultValue={
                                props.deployInfo && props.deployInfo
                                  ? props.deployInfo[0].select_support_option
                                  : ""
                              }
                              id="select_support_option"
                            /> */}
                            <div className="form-check form-switch form-check-custom form-check-solid me-10 mb-10">
                              <input
                                className="form-check-input w-70px"
                                type="checkbox"
                                onClick={handleBackupChange}  
                                defaultChecked={backupEnabled}
                              />
                            </div>
                            {/* <button className="btn btn-primary">Add Backup</button> */}
                          </div>
                        </div>
                        <div className="col-4">
                          <img
                            className="w-100"
                            src="https://demo.rsstudio.net/lagom-order-form/modules/addons/LagomOrderForm/storage/gallery/automatic-backups.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-none tickMarkbackup ribbon ribbon-triangle ribbon-top-start border-primary"
                      id={"tickMarkbackup"}
                    >
                      <div className="ribbon-icon mt-n5">
                        <i className="bi bi-check2 fs-2 text-white" />
                      </div>
                    </div>
                  </div> 
                </div>
        </>
    );
}

export default Backup;
