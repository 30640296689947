import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from '../../../../CustomHooks/usePost';
import UsePostData from '../../../../CustomHooks/usePostData';
import UseApi from '../../../../CustomHooks/useApi';
import swal from "sweetalert";
const Contact = () => {
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  } 
    const openDrawer = (id) => {
        var drawerElement = document.querySelector("#"+id);
        drawerElement.classList.toggle("drawer-on"); 
    };

    const ToogleAddContact=()=>{
        var addContactSec=document.querySelector("#add_contact_sec");
        var addContactBtn=document.querySelector("#addcontact-btn");
        if(addContactSec && addContactBtn){
            addContactSec.classList.toggle("d-none");
            addContactBtn.classList.toggle("disabled");
        }
    }

    const dataFetchedRef =useRef(false);
const [contacts, setContacts]=useState(null); 
 
const getContact = async () => {
  toast.dismiss(); 
  const data = await UseApi("get","monitoring/contact/list","");
  // console.log(data);
  if('status' in data){
    if(data.status=="error"){   
      setContacts(null); 
      loaderFunc(); 
      return;
    }
 }
  if (data.contacts.length === 0) { 
    setContacts(null);  
    loaderFunc();
  } else {
    setContacts(data.contacts);
       
  }
};


useEffect(() => {
  if (dataFetchedRef.current) return;
  dataFetchedRef.current = true;
  toast.dismiss();
  getContact();
}, []);
    const addNewContact=async()=>{ 
      var submitBtn=document.querySelector("#addnewcontact-btn");
      var add_name=document.querySelector("#add_name");
      var add_email=document.querySelector("#add_email"); 
      var add_mobilenumber=document.querySelector("#add_mobilenumber"); 
      var add_status=document.querySelector("#add_status"); 
      var cname="";
      var cemail="";
      var cmobilenumber="";
      var cstatus=""
      if(add_name && add_email && add_mobilenumber && add_status){
       
        if(add_name.value==""){
          const dt=toast.loading("Please wait..."); 
          toast.update(dt,{render: "Name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
        }else{
          cname=add_name.value;
        }
        if(add_email.value==""){
          const dt=toast.loading("Please wait..."); 
          toast.update(dt,{render: "Email must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
        }else{
          cemail=add_email.value;
        }
        if(add_mobilenumber.value==""){
          const dt=toast.loading("Please wait..."); 
          toast.update(dt,{render: "Mobile must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
        }else{
          cmobilenumber=add_mobilenumber.value;
        }
        if(add_status.value==""){
          const dt=toast.loading("Please wait..."); 
          toast.update(dt,{render: "Please provide status", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        return;
        }else{
          cstatus=add_status.value;
        }
      
      if(submitBtn){
        var preContent=submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
      const dt=toast.loading("Please wait..."); 
      const responseData=await UsePostData('post','monitoring/contact/add',{"name":cname, "email":cemail, "mobilenumber":cmobilenumber, "status":cstatus});
      if(responseData){
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML=preContent;
        if(responseData.status==='error'){
          toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        }else if(responseData.status==='success'){
          toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });  
          add_name.value="";
          add_mobilenumber="";
          add_status="";
          add_email="";
        ToogleAddContact();
        getContact();
      }
    }
        }
      }
      }

      const updateContact=async(id)=>{ 
        var submitBtn=document.querySelector("#updatecontact"+id+"-btn");
        var add_name=document.querySelector("#name_"+id);
        var add_email=document.querySelector("#email_"+id); 
        var add_mobilenumber=document.querySelector("#mobilenumber_"+id); 
        // var add_status=document.querySelector("#add_status"); 
        var cname="";
        var cemail="";
        var cmobilenumber="";
        var cstatus=""
        if(add_name && add_email && add_mobilenumber){
         
          if(add_name.value==""){
            const dt=toast.loading("Please wait..."); 
            toast.update(dt,{render: "Name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          return;
          }else{
            cname=add_name.value;
          }
          if(add_email.value==""){
            const dt=toast.loading("Please wait..."); 
            toast.update(dt,{render: "Email must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          return;
          }else{
            cemail=add_email.value;
          }
          if(add_mobilenumber.value==""){
            const dt=toast.loading("Please wait..."); 
            toast.update(dt,{render: "Mobile must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          return;
          }else{
            cmobilenumber=add_mobilenumber.value;
          } 
        }
        if(submitBtn){
          var preContent=submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
        const dt=toast.loading("Please wait..."); 
        const responseData=await UsePostData('post','monitoring/contact/'+id+'/update',{"name":cname, "email":cemail, "mobilenumber":cmobilenumber, "status":cstatus});
        if(responseData){
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML=preContent;
          if(responseData.status==='error'){
            toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          }else if(responseData.status==='success'){
            toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });  
          getContact();
        }
      }
          }
        }
   
        const deleteContact=async(id)=>{
          toast.dismiss();
          var submitBtn=document.querySelector("#deletecontact"+id+"-btn");
          if(submitBtn){
            var preContent=submitBtn.innerHTML;
            submitBtn.classList.add("disabled");
            submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
       
          swal({
            title:"Are you sure?",
            text: "Once deleted, you will not be able to recover this contact!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            })
            .then(async(willDelete) => {
            if(willDelete) {
              const dt=toast.loading("Contact deleting..."); 
          const dnsp=await UseApi('DELETE','monitoring/contact/'+id+'/delete','');
          //console.log(dnsp);
          if(dnsp){
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML=preContent;
          if(dnsp.status=='error'){
            toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          }else if(dnsp.status=='success'){
            toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          }); 
          getContact();
          }
        } 
            } 
            });
          }
        }
    return (
        <>
<a
onClick={()=>openDrawer('ticketmodal2')}
className="btn btn-sm btn-warning btn-active-light-warning"
style={{ marginRight: 20 }}
>
{/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
<span className="svg-icon svg-icon-3">
<svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
    fill="black"
  />
  <rect opacity="0.3" x={8} y={3} width={8} height={8} rx={4} fill="black" />
</svg>

</span>
{/*end::Svg Icon*/}Monitoring Contacts
</a>
         <div
  id="ticketmodal2"
  className="bg-white drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-activate="true" 
  data-kt-drawer-close="#contact_dismiss_close"
  data-kt-drawer-overlay="true"
  data-kt-drawer-width="{default:'1025pxpx', 'md': '1025px', 'sm': '300px'}"
  style={{ maxWidth: "70%", important: true }}
>
  <div className="card rounded-0 w-100">
    {/*begin::Card header*/}
    <div className="card-header pe-5 ">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div
          className="d-flex justify-content-center flex-column me-3"
          style={{ marginRight: 20 }}
        >
          <h3 className="  me-1 lh-2" id="top-head">
            Monitoring Contacts
          </h3>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
  {/*begin::Close*/}
  <div className="btn btn-sm btn-icon btn-active-light-primary" onClick={()=>openDrawer('ticketmodal2')} id="contact_dismiss_close">
    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
    <span className="svg-icon svg-icon-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect
          opacity="0.5"
          x={6}
          y="17.3137"
          width={16}
          height={2}
          rx={1}
          transform="rotate(-45 6 17.3137)"
          fill="black"
        />
        <rect
          x="7.41422"
          y={6}
          width={16}
          height={2}
          rx={1}
          transform="rotate(45 7.41422 6)"
          fill="black"
        />
      </svg>
    </span>
    {/*end::Svg Icon*/}
  </div>
  {/*end::Close*/}
</div>

      {/*end::Card toolbar*/}
    </div>
    <div className=" hover-scroll-overlay-y">
      <div
        className="tab-pane fade show active"
        id="customer-details-section"
        role="tabpanel"
      >
        {/*begin::Deactivate Account*/}
        <div className="">
          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
            <div className=" mt-10 d-none" id="od_sec">
              {" "}
              {/*begin::Table*/}
              <div
                id="kt_table_users_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div className="table-responsive">
                  <table
                    className="table table-row-dashed table-row-bordered gy-5 gs-7"
                    id="page_list"
                  >
                    {/*begin::Table head*/}
                    <thead className="bg-secondary">
                      {/*begin::Table row*/}
                      <tr className="text-start fw-bolder fs-7 text-uppercase gs-0">
                        <th className="min-w-100px sorting text-center">
                          Path{" "}
                        </th>
                        <th className="min-w-125px sorting">Size</th>
                        <th className="min-w-100px sorting">Storage ID</th>
                        <th className="min-w-100px sorting">Action</th>
                      </tr>
                      {/*end::Table row*/}
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    <tbody className="text-gray-600" id="od-data">
                      {" "}
                    </tbody>
                    {/*end::Table body*/}
                  </table>
                </div>
              </div>
              {/*end::Table*/}
            </div>
            <div className=" mt-10 d-none" id="cloud_sec">
              {/*begin::Table*/}
              <div
                id="kt_table_users_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div className="table-responsive">
                  <table
                    className="table table-row-dashed table-row-bordered gy-5 gs-7"
                    id="page_list"
                  >
                    {/*begin::Table head*/}
                    <thead className="bg-secondary">
                      {/*begin::Table row*/}
                      <tr className="text-start fw-bolder fs-7 text-uppercase gs-0">
                        <th className="min-w-100px sorting text-center">
                          Cloud ID{" "}
                        </th>
                        <th className="min-w-125px sorting">User</th>
                        <th className="min-w-100px sorting">Hostname</th>
                        <th className="min-w-100px sorting">Disk</th>
                        <th className="min-w-100px sorting">RAM</th>
                        <th className="min-w-100px sorting">CPU</th>
                        <th className="min-w-100px sorting">Billing</th>
                      </tr>
                      {/*end::Table row*/}
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    <tbody className="text-gray-600" id="cloud-data">
                      {" "}
                    </tbody>
                    {/*end::Table body*/}
                  </table>
                </div>
              </div>
              {/*end::Table*/}
            </div>
            {/*begin::Card body*/}
            <div className=" mt-10" id="server_sec">
              {/*begin::Table*/} 
              <div
                id="kt_table_users_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div className="table-responsive">
                  <table
                    className="table table-row-dashed border table-row-bordered gy-5 gs-7"
                    id="page_list"
                  >
                    {/*begin::Table head*/}
                    <thead className="bg-secondary">
                      {/*begin::Table row*/}
                      <tr className="text-start fw-bolder fs-7 text-uppercase gs-0">
                        <th className="min-w-150px">Name </th>
                        <th className="min-w-250px">Email </th>
                        <th className="min-w-100px sorting">Mobile</th>
                        <th className="min-w-150px text-center">Status</th>
                        <th className="min-w-300px sorting" />
                      </tr>
                      {/*end::Table row*/}
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    <tbody className="text-gray-600" id="con-data">
                      {contacts!=null?(<>
                       {contacts.map((value,index)=>(<>
                        <tr className="openCustomer" data-id="">
                        {" "}
                        <td className="text-center"> 
                            <input
                              type="text"
                              name="name"
                              id={"name_"+value.id}
                              defaultValue={value.name}
                              className="form-control"
                              placeholder="Enter Name"
                            /> 
                        </td> 
                        <td className="text-center">
                          <input
                            type="text"
                            name="email"
                            id={"email_"+value.id}
                            defaultValue={value.email}
                            className="form-control"
                            placeholder="Enter Email"
                          />
                        </td> 
                        <td className="text-center">
                          <input
                            type="text" 
                            id={"mobilenumber_"+value.id}
                            name="mobilenumber"
                            defaultValue={value.mobilenumber}
                            className="form-control"
                            placeholder="Enter Mobile"
                          />
                        </td> 
                        <td className="text-center">
                          <select
                            className="form-select"
                            id="status"
                            name="status"
                          >
                            <option value={1}>Active</option>
                            <option value={0} selected="">
                              Inactive
                            </option>
                          </select>
                        </td> 
                        <td className="text-center">
                          <a
                            href="#"
                            id={"updatecontact"+value.id+"-btn"}
                            onClick={(e)=>updateContact(value.id)}
                             className="btn btn-sm btn-success"
                            style={{ marginRight: 20 }}
                          >
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil017.svg*/}{" "}
                            <span className="svg-icon svg-icon-muted svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                {" "}
                                <path
                                  opacity="0.3"
                                  d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                  fill="black"
                                />{" "}
                                <path
                                  opacity="0.3"
                                  d="M13 14.4V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V14.4H13Z"
                                  fill="black"
                                />{" "}
                                <path
                                  d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM13 14.4V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V14.4H8L11.3 17.7C11.7 18.1 12.3 18.1 12.7 17.7L16 14.4H13Z"
                                  fill="black"
                                />{" "}
                              </svg>
                            </span>{" "}
                            {/*end::Svg Icon*/} Update
                          </a>{" "}
                          <a
                            href="#"
                            id={"deletecontact"+value.id+"-btn"}
                            onClick={(e)=>deleteContact(value.id)}
                             className="btn btn-icon btn-light-danger"
                          >
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen027.svg*/}{" "}
                            <span className="svg-icon svg-icon-muted svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                {" "}
                                <path
                                  d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                  fill="black"
                                />{" "}
                                <path
                                  opacity="0.5"
                                  d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                  fill="black"
                                />{" "}
                                <path
                                  opacity="0.5"
                                  d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                  fill="black"
                                />{" "}
                              </svg>
                            </span>{" "}
                            {/*end::Svg Icon*/}
                          </a>
                        </td>{" "}
                      </tr>
                        </>))}
                      </>):('')}
                      
                      <tr>
                        <td colSpan={12}>
                        <a
                            href="#"
                            id="addcontact-btn"
                            onClick={ToogleAddContact}
                            // onclick="call_api('POST','monitoring/contact/add','addcontact','getContact')"
                            className="btn btn-primary"
                            style={{ marginRight: 20 }}
                          >
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil017.svg*/}
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil013.svg*/}
                            <span className="svg-icon svg-icon-muted svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                  fill="black"
                                />
                                <path
                                  d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.3"
                                  d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                            {/*end::Svg Icon*/}
                            Add New
                          </a>
                        </td>
                      </tr>
                     
                      <tr className="openCustomer d-none" id="add_contact_sec" >
                        <td className="text-center">
                           <input
                              type="text"
                              id="add_name"
                              name="name"
                              defaultValue=""
                              className="form-control"
                              placeholder="Enter Name"
                            />
                          
                        </td>
                        <td className="text-center">
                          <input
                            type="text"
                            id="add_email"
                            name="email"
                            defaultValue=""
                            className="form-control"
                            placeholder="Enter Email"
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="text"
                            id="add_mobilenumber"
                            name="mobilenumber"
                            defaultValue=""
                            className="form-control"
                            placeholder="Enter Mobile"
                          />
                        </td>
                        <td className="text-center">
                          <select
                            className="form-select"
                            id="add_status"
                            name="status" 
                          >
                            <option
                              value={1} 
                            >
                              Active
                            </option>
                            <option value={0}>Inactive</option>
                          </select> 
                        </td>
                        <td className="text-center">
                          <a
                            href="#"
                            id="addnewcontact-btn"
                            onClick={addNewContact}
                             className="btn btn-primary"
                            style={{ marginRight: 20 }}
                          > 
                            Add Contact
                          </a>
                          <a
                            href="#"
                            id="addcontact-btn"
                            onClick={ToogleAddContact}
                             className="btn btn-danger"
                            style={{ marginRight: 20 }}
                          > 
                            Cancel
                          </a>
                        </td> 
                      </tr>
                      
                    </tbody>
                    {/*end::Table body*/}
                  </table>
                  
                </div>
              </div>
              {/*end::Table*/}
            </div> 
            {/*end::Card body*/}
            {/*end::Form*/}
          </div>
        </div>
      </div>
    </div>
    {/*end::Card body*/}
  </div>
</div>   
        </>
    );
}

export default Contact;
