import { DEPLOY_NODE_POOL_ROW, UPDATE_NODE_POOL_ROW } from "../../../../Redux/Services/constants"
const makeid=(length)=> {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
const initialState={
   nodePoolRow:[
                {pool_id:0, nodePoolValue:'pool-'+makeid(8), nodeSize:0, nodeCount:1, totalCost:0.00},
                {pool_id:1, nodePoolValue:'pool-'+makeid(8), nodeSize:0, nodeCount:1, totalCost:0.00}
               ],
   deployPoolRow:[
                {pool_id:0, nodePoolValue:'pool-'+makeid(8), nodeSize:0, nodeCount:1, totalCost:0.00},
                {pool_id:1, nodePoolValue:'pool-'+makeid(8), nodeSize:0, nodeCount:1, totalCost:0.00}
                ],
}

export default function KubernetesReducer(state=initialState,action){


   switch (action.type) {
       case DEPLOY_NODE_POOL_ROW: 
           return[ 
              {deployPoolRow:action.data}
              ]
       case UPDATE_NODE_POOL_ROW: 
           return[  
              {nodePoolRow:action.data}
              ]             
       default:
           return state 
   }
}

