import React, { useState } from 'react';

const ServerManagement = (props) => {
  const [userInfo, setUserInfo]=useState(props.user);
    const supportOp = (type) => {
        var tickMarkManagement = document.querySelector("#tickMarkManagement");
        var borderManagement = document.querySelector("#borderManagement");
        if (tickMarkManagement) {
          if (borderManagement && props && userInfo) {
            if (type == "basic") {
              if(userInfo.currency=="INR"){
                props.updateManageService("basic", 3995);
              }else{
                props.updateManageService("basic", 48);
              }
              tickMarkManagement.classList.remove("d-none");
              borderManagement.classList.add("activeBorder");
            }else if (type == "full") {
              if(userInfo.currency=="INR"){
                props.updateManageService("full", 6995);
              }else{
                props.updateManageService("full", 84);
              }
              tickMarkManagement.classList.remove("d-none");
              borderManagement.classList.add("activeBorder");
            }else {
                props.updateManageService("unmanaged", 0);
              tickMarkManagement.classList.add("d-none");
              borderManagement.classList.remove("activeBorder");
            }

          }
        }
      };


    return (
        <>
             <div className="p-7">
                  <div
                    className=" card pt-7 border border-2 border-gray-300"
                    id="borderManagement"
                  >
                    <div className="">
                      <div className="row">
                        <div className="col-8" style={{ paddingLeft: 51 }}>
                          <h3 className="card-title align-items-start flex-column">
                            <p>
                              <span className="card-label fw-bolder text-gray-800">
                                Server Management
                              </span>
                            </p>
                            <span className="text-gray-400 mt-1 fw-bold fs-6 d-none">
                              Proper configuration, monitoring, and maintenance
                              of servers are essential for ensuring optimal
                              performance, security, and reliability of critical
                              IT infrastructure.
                            </span>
                          </h3>
                          <div className="pt-8">
                            {/*begin:Option*/}
                            <label
                              className="d-flex flex-stack mb-5 cursor-pointer"
                              onClick={() => supportOp("basic")}
                            >
                              {/*begin:Label*/}
                              <span className="d-flex align-items-center me-2">
                                {/*begin:Input*/}
                                <span className="form-check form-check-custom form-check-solid me-10">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    defaultValue="basic"
                                     name="management"
                                    id="management_basic"
                                  />
                                </span>
                                {/*end:Input*/}
                                <span className="d-flex flex-column">
                                  <span className="fw-bolder fs-6">
                                    Basic Managed &nbsp;&nbsp;
                                    <span className="badge badge-primary">
                                      {userInfo?(<>

                                      {userInfo.currency=="INR"?(<>
                                        Rs.3995/Mon
                                      </>):(<>
                                        $48/Mon
                                      </>)}
                                      </>):(userInfo)}

                                    </span>
                                  </span>
                                  <span className="fs-7 text-muted">
                                    In this support package we will manage basic
                                    OS areas like Users,Auth,Updates
                                  </span>
                                </span>
                              </span>
                              {/*end:Label*/}
                              {/*begin:Icon*/}
                              {/*end:Icon*/}
                              {/*begin:Info*/}
                              {/*end:Info*/}
                            </label>
                            {/*end::Option*/}
                            {/*begin:Option*/}
                            <label
                              className="d-flex flex-stack mb-5 cursor-pointer"
                              onClick={() => supportOp("full")}
                            >
                              {/*begin:Label*/}
                              <span className="d-flex align-items-center me-2">
                                {/*begin:Input*/}
                                <span className="form-check form-check-custom form-check-solid me-10">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    defaultValue="full"
                                    name="management"
                                    id="management_full"
                                  />
                                </span>
                                {/*end:Input*/}
                                <span className="d-flex flex-column">
                                  <span className="fw-bolder fs-6">
                                    Fully Management &nbsp;&nbsp;
                                    <span className="badge badge-primary">
                                    {userInfo?(<>

                                      {userInfo.currency=="INR"?(<>
                                        Rs.6995/Mon
                                      </>):(<>
                                        $84/Mon
                                      </>)}
                                      </>):('')}
                                    </span>
                                  </span>
                                  <span className="fs-7 text-muted">
                                    Complete management of server including OS,
                                    Control Panel, Databases Install or upgrades
                                    (MySQL,MSSQL)
                                  </span>
                                </span>
                              </span>
                              {/*end:Label*/}
                              {/*begin:Icon*/}
                              {/*end:Icon*/}
                              {/*begin:Info*/}
                              {/*end:Info*/}
                            </label>
                            {/*end::Option*/}
                            {/*begin:Option*/}
                            <label
                              className="d-flex flex-stack mb-5 cursor-pointer"
                              onClick={() => supportOp("unmanaged")}
                            >
                              {/*begin:Label*/}
                              <span className="d-flex align-items-center me-2">
                                {/*begin:Input*/}
                                <span className="form-check form-check-custom form-check-solid me-10">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    defaultChecked="checked"
                                    defaultValue="unmanaged"
                                    name="management"
                                    id="management_self"
                                  />
                                </span>
                                {/*end:Input*/}
                                <span className="d-flex flex-column">
                                  <span className="fw-bolder fs-6">
                                    Un-Managed
                                  </span>
                                </span>
                              </span>
                              {/*end:Label*/}
                              {/*begin:Icon*/}
                              {/*end:Icon*/}
                              {/*begin:Info*/}
                              {/*end:Info*/}
                            </label>
                            {/*end::Option*/}
                          </div>
                        </div>
                        <div className="col-4">
                          <img
                            className="w-100"
                            src="https://demo.rsstudio.net/lagom-order-form/modules/addons/LagomOrderForm/storage/gallery/ddos-protection.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="ribbon ribbon-triangle ribbon-top-start border-primary d-none"
                      id="tickMarkManagement"
                    >
                      <div className="ribbon-icon mt-n5">
                        <i className="bi bi-check2 fs-2 text-white" />
                      </div>
                    </div>
                  </div>
                </div>
        </>
    );
}

export default ServerManagement;
