import React, { useState, useEffect} from "react";  
import { connect, useDispatch } from "react-redux"; 
  
const PricingContainer=(props)=>{


  const [plan_period, setPlan_period] = useState(null); 
  const [planData, setPlanData] = useState(null); 
  const [userData, setUserData] = useState(null); 
  
  useEffect(()=>{
    if(props){
        if(props.plans){
            setPlanData(props.plans);
        }
        if(props.user){
            setUserData(props.user);
        }
        if(props.currPlanData){

        } 
    }
  },[])

 

const selectPlan=(planid)=>{
    //unselect all plans 
    var planLabels=document.querySelectorAll(".tick-parent"); 
    var PlansTicks=document.querySelectorAll(".tickMarkPlan"); 
    planLabels.forEach((lfield)=>{
        lfield.classList.remove("plan_list_active");
    })
    PlansTicks.forEach((tfield)=>{
        tfield.classList.add("d-none");
     })

    //select the specific plan

    var activeLabels=document.querySelector("#tick_parent_"+planid); 
    var activePlansTicks=document.querySelector("#tickMarkPlan-"+planid);
    if(activeLabels && activePlansTicks){
        activeLabels.classList.add("plan_list_active");
        activePlansTicks.classList.remove("d-none");
    }  

    if(planData!=null){
      planData.map((value,index)=>{
        if(value.id==planid){
          if(props.currPlanData[0]){
            var payablePrice=0;
            var orgCost=0;
            var planDiscount=0;
            if(props.currPlanData[0].billing_info=="12month"){
              orgCost=calculateActualPrice(value.price, 0, props.currPlanData[0].billing_info);
              payablePrice=calculateActualPrice(value.price, 25, props.currPlanData[0].billing_info);
              planDiscount=25;  
            }else if(props.currPlanData[0].billing_info=="monthly"){
                orgCost=calculateActualPrice(value.price, 0, props.currPlanData[0].billing_info);
                payablePrice=calculateActualPrice(value.price, 5, props.currPlanData[0].billing_info);
                planDiscount=5;
              }else{
                orgCost=value.price;
                payablePrice=value.price;
                planDiscount=0;
              }
            var result={"ram":value.ram, "cpu":value.cpu, "disk":value.disk, "bandwidth":value.bandwidth, "billing_info":props.currPlanData[0].billing_info, "plan_value":value.id, "plan_cost":payablePrice,"plan_org_value":value.price, "plan_cost_without_discount":orgCost, "plan_discount":planDiscount};
            props.currPlanData[0]=result; 
        }
        }
      })
    }
    if(props.currPlanData[0]){ 
      if(props.currPlanData[0].ram!=""){
      var planInfo={"planid":planid,"ram":props.currPlanData[0].ram, "vcpu":props.currPlanData[0].cpu, "disk":props.currPlanData[0].disk, "bandwidth":props.currPlanData[0].bandwidth, "payable_price":props.currPlanData[0].plan_cost, "plan_cost_without_discount": props.currPlanData[0].plan_cost_without_discount,"billing":props.currPlanData[0].billing_info, "plan_org_value":props.currPlanData[0].plan_org_value, "plan_discount":props.currPlanData[0].plan_discount};
      props.updatePlan(planInfo);  
  }
}
}
 

const changeBillingCycle=(planType)=>{ 
     
    
        if(props.currPlanData[0]){ 
          props.currPlanData[0].billing_info=planType;
          selectPlan(props.currPlanData[0].plan_value);
         }
     var allBillingLabels=document.querySelectorAll(".activeBilling");
     var activeLabel=document.querySelector("#label-"+planType);
      allBillingLabels.forEach((bfield)=>{
        bfield.classList.remove("active");
     })
     if(activeLabel){
        activeLabel.classList.add("active");
     }
     setPlan_period(planType); 
      
}
 
 
    useEffect(()=>{
    setTimeout(()=>{
    if(props){   
      if(props.currPlanid!=""){ 
        if(props.currPlanData[0]){ 
          props.currPlanData[0].plan_value=props.currPlanid;
          
        }  
      }
     if(props.currBilling!=""){  
      changeBillingCycle(props.currBilling); 
     }
    
    }

},100)
 },[planData])

 function calculateActualPrice(monthlyPrice, discount, duration) {
    let actualPrice = monthlyPrice;
  
    if (duration === 'hourly') { 
      actualPrice = monthlyPrice / 720;
      actualPrice -= actualPrice * (discount / 100);
      actualPrice = actualPrice.toFixed(2);  
    } else if (duration === '12month') {
      actualPrice = monthlyPrice * 12;  
      actualPrice -= actualPrice * (discount / 100);
      actualPrice = actualPrice.toFixed(0);  
    } else if (duration === 'monthly') {
      actualPrice -= actualPrice * (discount / 100);
      actualPrice = actualPrice.toFixed(0);  
    }
   
    return actualPrice;
  }
  return (
    <>
     
            
              {/*begin::Col*/}
              <div className="col-xl-12 card mb-5">
                {/*begin::List widget 10*/}
                {/*begin::Header*/}
                <div className="card-header border-3 mb-5 pt-2">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Select Billing Cycle
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                       Anuualy, Monthly, Hourly
                    </span>
                  </h3>
                </div>

                {/*begin::Radio group*/}
                <div
                  className="btn-group w-100 container pb-5"
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >
                  {/*begin::Radio*/}
                  <label
                    onClick={(e) => changeBillingCycle("36month")}
                    className="d-none activeBilling billingCycle btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary "
                    data-kt-button="true"
                    id="label-36month"
                  >
                     3 years
                  </label>
                  {/*end::Radio*/}
                   {/*begin::Radio*/}
                   <label 
                    onClick={(e) => changeBillingCycle("hourly")}
                    className="activeBilling billingCycle btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary active"
                    data-kt-button="true"
                    id="label-hourly"
                  > 
                    Hourly
                  </label>
                  {/*end::Radio*/}
                
                  {/*begin::Radio*/}
                  <label
                    onClick={(e) => changeBillingCycle("monthly")}
                    className="activeBilling billingCycle btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary"
                    data-kt-button="true"
                    id="label-monthly"
                  > 
                    Monthly
                  </label>
                  {/*end::Radio*/}
                   {/*begin::Radio*/}
                   <label
                    onClick={(e) => changeBillingCycle("12month")}
                    className="activeBilling billingCycle btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary"
                    data-kt-button="true"
                    id="label-12month"
                  >
                    Annually
                  </label>
                  {/*end::Radio*/}
                </div>
                {/*end::Radio group*/}
 
</div>
<div className="col-xl-12 card mb-5">
                <div className="card-header border-3 mb-5 pt-2">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Select Plan Type
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                      Basic, Dedicated CPU, Dedicated Memory
                    </span>
                  </h3>
                </div>
                {/*begin::Nav*/}
                <ul className="container nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
  <li className="nav-item">
    <a
    // onClick={(e) => planChange("basic",plan_period, e)}
      className="nav-link text-active-primary py-5 me-6 active"
      data-bs-toggle="tab"
      href="#basic-tab"
    >
      Basic Plan
    </a> 
  </li> 
  <li className="nav-item">
    <a
    // onClick={(e) => planChange("dedicated-cpu",plan_period, e)}
      className="nav-link py-5 me-6 "
      data-bs-toggle="tab"
      href="#dedicated-cpu-tab"
    >
       CPU Optimized
    </a> 
  </li>
  <li className="nav-item">
    <a
    // onClick={(e) => planChange("dedicated-memory",plan_period, e)} 
      className="nav-link py-5 me-6"
      data-bs-toggle="tab"
      href="#dedicated-memory-tab"
    >
      Memory Optimized
    </a> 
  </li>
  </ul>
                 
                {/*end::Nav*/}
                {/* <input
                  type="hidden"  
                  id="billing_info"
                  defaultValue=""
                />
                <input
                  type="hidden" 
                  id="plan_value"
                  defaultValue=""
                />
                <input
                  type="hidden"
                  id="plan_cost"
                  defaultValue="0"
                /> */}

                {/* Horizontal PLAN START*/}
                <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="basic-tab" role="tabpanel">
                <div className="row mt-5" data-kt-buttons="true">
                  <div className="table-responsive" id="ramcpuplans">
                    {/*begin::Table*/}
                    <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                      {/*begin::Thead*/}
                      <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                        <tr>
                          <th className="w-10 px-0 text-center">RAM</th>
                          <th className="w-20 text-center">vCPU</th>
                          <th className="w-20 text-center">SSD Disk</th>
                          <th className="w-20 text-center">Bandwidth</th>
                          <th className="w-10 ps-0 text-center">Plan</th>
                          <th className="w-15 ps-0 text-center">
                            Price
                          </th>
                          <th className="w-5 ps-0 text-center" />
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody className="fs-6 fw-bold text-gray-600">
                        {planData != null &&
                          planData?(<>{
                          planData.map((value, index) => (
                            <>
                              {value.slug == "basic" ? (
                                <>
                                  <tr
                                    onClick={(e)=>selectPlan(value.id)}
                                    className="planseffect tick-parent"
                                    id={"tick_parent_" + value.id}
                                  >
                                    <td className="ps-0 text-center">
                                        <input type="hidden" id={"plan_cost_"+value.id} defaultValue={value.price} />
                                        <input type="hidden" id={"ram_"+value.id} defaultValue={value.ram} />
                                      {(value.ram/1024).toFixed(0)} GB
                                      {/* {value.id} */}
                                    </td>
                                    <td className="text-center">
                                    <input type="hidden" id={"cpu_"+value.id} defaultValue={value.cpu} />
                                      {value.slug=="basic"?(<>
                                        {value.cpu} vCPU
                                      </>):(<>
                                        {value.cpu} vCPU <br/><span className="badge badge-success">Dedicated</span>
                                      </>)}
                                      </td>
                                    <td className="text-center">
                                        <input type="hidden" id={"disk_"+value.id} defaultValue={value.disk} />
                                        {value.disk} GB</td>
                                    <td className="text-center">
                                        <input type="hidden" id={"bandwidth_"+value.id} defaultValue={value.bandwidth} />
                                        {value.bandwidth} GB</td>
                                    <td className="text-center">
                                       <span
                                        className="badge badge-success"
                                        id={"plan_type_" + value.id}
                                      >
                                        {plan_period=="12month"?(<>
                                        Annually
                                        </>):(<>
                                        {plan_period=="monthly"?(<>Monthly</>):(<>Hourly</>)}
                                        </>)}
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <div className="row">
                                        <div className="col-lg-2 col-xxl-2"></div>
                                        <div className="col-lg-10 col-xxl-10">
                                          <span className="text-muted fs-7 fw-bolder">
                                            <del
                                              id={"actual_price_" + value.id}
                                            > 
                                             {userData != null ? (
                                              <> 
                                             {plan_period=="12month"?(<>
                                                {userData.currencyprefix}{(value.price*12).toFixed(0)}
                                        </>):(<>
                                        {plan_period=="monthly"?(<>{userData.currencyprefix}{(value.price).toFixed(0)}</>):('')}
                                        </>)}</>):('')}
                                            </del>
                                          </span>
                                          <br />
                                          <span
                                            className="badge badge-light-primary fs-7 fw-bolder"
                                            style={{ marginRight: 5 }}
                                            id={
                                              "updated_plan_price_" + value.id
                                            }
                                          >
                                            {userData != null ? (
                                              <>
                                                {
                                                  userData.currencyprefix
                                                }
                                                {plan_period=="12month"?(<>
                                       {(calculateActualPrice(value.price, 25, plan_period))}
                                        </>):(<>
                                        {plan_period=="monthly"?(<>{calculateActualPrice(value.price, 5, plan_period)}</>):
                                        (<>{calculateActualPrice(value.price, 0, plan_period)}</>)}
                                        </>)}
                                                 
                                               
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                          <br />
                                          
                                        </div>
                                        <div />
                                      </div>
                                    </td>
                                    <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                      <div
                                        className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                        id={"tickMarkPlan-" + value.id}
                                      >
                                        <div className="ribbon-icon mt-n5">
                                          <i className="bi bi-check2 fs-2 text-white" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                         
                         ))}</>):(<><td colspan="12"><div className="small-spinner"></div></td></>)}
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                </div>
                <div className="tab-pane " id="dedicated-cpu-tab" role="tabpanel">
                <div className="row mt-5" data-kt-buttons="true">
                  <div className="table-responsive" id="ramcpuplans">
                    {/*begin::Table*/}
                    <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                      {/*begin::Thead*/}
                      <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                        <tr>
                          <th className="w-10 px-0 text-center">RAM</th>
                          <th className="w-20 text-center">vCPU</th>
                          <th className="w-20 text-center">SSD Disk</th>
                          <th className="w-20 text-center">Bandwidth</th>
                          <th className="w-10 ps-0 text-center">Plan</th>
                          <th className="w-15 ps-0 text-center">
                            Price
                          </th>
                          <th className="w-5 ps-0 text-center" />
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody className="fs-6 fw-bold text-gray-600">
                        {planData != null &&
                          planData?(<>{
                          planData.map((value, index) => (
                            <>
                              {value.slug == "dedicated-cpu" ? (
                                <>
                                <tr
                                    onClick={(e)=>selectPlan(value.id)}
                                    className="planseffect tick-parent"
                                    id={"tick_parent_" + value.id}
                                  >
                                    <td className="ps-0 text-center">
                                        <input type="hidden" id={"plan_cost_"+value.id} defaultValue={value.price} />
                                        <input type="hidden" id={"ram_"+value.id} defaultValue={value.ram} />
                                    {(value.ram/1024).toFixed(0)} GB
                                    {/* {value.id} */}
                                    </td>
                                    <td className="text-center">
                                    <input type="hidden" id={"cpu_"+value.id} defaultValue={value.cpu} />
                                      {value.slug=="basic"?(<>
                                        {value.cpu} vCPU
                                      </>):(<>
                                        {value.cpu} vCPU <br/><span className="badge badge-success">Dedicated</span>
                                      </>)}
                                      </td>
                                    <td className="text-center">
                                        <input type="hidden" id={"disk_"+value.id} defaultValue={value.disk} />
                                        {value.disk} GB</td>
                                    <td className="text-center">
                                        <input type="hidden" id={"bandwidth_"+value.id} defaultValue={value.bandwidth} />
                                        {value.bandwidth} GB</td>
                                    <td className="text-center">
                                       <span
                                        className="badge badge-success"
                                        id={"plan_type_" + value.id}
                                      >
                                        {plan_period=="12month"?(<>
                                        Annually
                                        </>):(<>
                                        {plan_period=="monthly"?(<>Monthly</>):(<>Hourly</>)}
                                        </>)}
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <div className="row">
                                        <div className="col-lg-2 col-xxl-2"></div>
                                        <div className="col-lg-10 col-xxl-10">
                                          <span className="text-muted fs-7 fw-bolder">
                                            <del
                                              id={"actual_price_" + value.id}
                                            > 
                                             {userData != null ? (
                                              <> 
                                             {plan_period=="12month"?(<>
                                                {userData.currencyprefix}{(value.price*12).toFixed(0)}
                                        </>):(<>
                                        {plan_period=="monthly"?(<>{userData.currencyprefix}{(value.price).toFixed(0)}</>):('')}
                                        </>)}</>):('')}
                                            </del>
                                          </span>
                                          <br />
                                          <span
                                            className="badge badge-light-primary fs-7 fw-bolder"
                                            style={{ marginRight: 5 }}
                                            id={
                                              "updated_plan_price_" + value.id
                                            }
                                          >
                                            {userData != null ? (
                                              <>
                                                {
                                                  userData.currencyprefix
                                                }
                                                {plan_period=="12month"?(<>
                                       {(calculateActualPrice(value.price, 25, plan_period))}
                                        </>):(<>
                                        {plan_period=="monthly"?(<>{calculateActualPrice(value.price, 5, plan_period)}</>):
                                        (<>{calculateActualPrice(value.price, 0, plan_period)}</>)}
                                        </>)}
                                                 
                                               
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                          <br />
                                          
                                        </div>
                                        <div />
                                      </div>
                                    </td>
                                    <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                      <div
                                        className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                        id={"tickMarkPlan-" + value.id}
                                      >
                                        <div className="ribbon-icon mt-n5">
                                          <i className="bi bi-check2 fs-2 text-white" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                         
                         ))}</>):(<><td colspan="12"><div className="small-spinner"></div></td></>)}
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div> 
                </div> 
                <div className="tab-pane " id="dedicated-memory-tab" role="tabpanel">
                <div className="row mt-5"  data-kt-buttons="true">
                  <div className="table-responsive" id="ramcpuplans">
                    {/*begin::Table*/}
                    <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                      {/*begin::Thead*/}
                      <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                        <tr>
                          <th className="w-10 px-0 text-center">RAM</th>
                          <th className="w-20 text-center">vCPU</th>
                          <th className="w-20 text-center">SSD Disk</th>
                          <th className="w-20 text-center">Bandwidth</th>
                          <th className="w-10 ps-0 text-center">Plan</th>
                          <th className="w-15 ps-0 text-center">
                            Price
                          </th>
                          <th className="w-5 ps-0 text-center" />
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody className="fs-6 fw-bold text-gray-600">
                        {planData != null &&
                          planData?(<>{
                          planData.map((value, index) => (
                            <>
                              {value.slug == "dedicated-memory" ? (
                                <>
                                 <tr
                                    onClick={(e)=>selectPlan(value.id)}
                                    className="planseffect tick-parent"
                                    id={"tick_parent_" + value.id}
                                  >
                                    <td className="ps-0 text-center">
                                        <input type="hidden" id={"plan_cost_"+value.id} defaultValue={value.price} />
                                        <input type="hidden" id={"ram_"+value.id} defaultValue={value.ram} />
                                     {(value.ram/1024).toFixed(0)} GB
                                     {/* {value.id} */}
                                    </td>
                                    <td className="text-center">
                                    <input type="hidden" id={"cpu_"+value.id} defaultValue={value.cpu} />
                                      {value.slug=="basic"?(<>
                                        {value.cpu} vCPU
                                      </>):(<>
                                        {value.cpu} vCPU <br/><span className="badge badge-success">Dedicated</span>
                                      </>)}
                                      </td>
                                    <td className="text-center">
                                        <input type="hidden" id={"disk_"+value.id} defaultValue={value.disk} />
                                        {value.disk} GB</td>
                                    <td className="text-center">
                                        <input type="hidden" id={"bandwidth_"+value.id} defaultValue={value.bandwidth} />
                                        {value.bandwidth} GB</td>
                                    <td className="text-center">
                                       <span
                                        className="badge badge-success"
                                        id={"plan_type_" + value.id}
                                      >
                                        {plan_period=="12month"?(<>
                                        Annually
                                        </>):(<>
                                        {plan_period=="monthly"?(<>Monthly</>):(<>Hourly</>)}
                                        </>)}
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <div className="row">
                                        <div className="col-lg-2 col-xxl-2"></div>
                                        <div className="col-lg-10 col-xxl-10">
                                          <span className="text-muted fs-7 fw-bolder">
                                            <del
                                              id={"actual_price_" + value.id}
                                            > 
                                             {userData != null ? (
                                              <> 
                                             {plan_period=="12month"?(<>
                                                {userData.currencyprefix}{(value.price*12).toFixed(0)}
                                        </>):(<>
                                        {plan_period=="monthly"?(<>{userData.currencyprefix}{(value.price).toFixed(0)}</>):('')}
                                        </>)}</>):('')}
                                            </del>
                                          </span>
                                          <br />
                                          <span
                                            className="badge badge-light-primary fs-7 fw-bolder"
                                            style={{ marginRight: 5 }}
                                            id={
                                              "updated_plan_price_" + value.id
                                            }
                                          >
                                            {userData != null ? (
                                              <>
                                                {
                                                  userData.currencyprefix
                                                }
                                                {plan_period=="12month"?(<>
                                       {(calculateActualPrice(value.price, 25, plan_period))}
                                        </>):(<>
                                        {plan_period=="monthly"?(<>{calculateActualPrice(value.price, 5, plan_period)}</>):
                                        (<>{calculateActualPrice(value.price, 0, plan_period)}</>)}
                                        </>)}
                                                 
                                               
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                          <br />
                                          
                                        </div>
                                        <div />
                                      </div>
                                    </td>
                                    <td className="ps-0 text-center ribbon ribbon-end ribbon-clip">
                                      <div
                                        className="d-none tickMarkPlan ribbon ribbon-triangle ribbon-top-start border-primary"
                                        id={"tickMarkPlan-" + value.id}
                                      >
                                        <div className="ribbon-icon mt-n5">
                                          <i className="bi bi-check2 fs-2 text-white" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                         
                         ))}</>):(<><td colspan="12"><div className="small-spinner"></div></td></>)}
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                </div> 
                </div> 
                {/* Horizontal PLAN START*/}

                {/*end::Header*/}
                {/*end: Card Body*/}
                {/*end::List widget 10*/}
              </div>
              {/*end::Col*/} 
    </>
  );
};
 
const mapStateToProps=state=>({
  currPlanData:state.planData.planDataInfo
})

const mapDispatchToProps=dispatch=>({
  // updateRow:data=>dispatch(DeployNodePoolRow(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(PricingContainer) 