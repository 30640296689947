import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePost from "../../../../CustomHooks/usePost";
import swal from "sweetalert";

export default function ManageLoadBalancer() {    
  const [aclConditionList, setAclConditionList]=useState(["http_user_agent","http_referer","url_path","","http_method","ssl_cipher_suite","ssl_certificate","network_protocol","ip_geolocation","authenticated_users","custom_header"]);
  const openDrawerAcl=()=>{
    var drawerElement = document.querySelector("#acl-modal");
        drawerElement.classList.toggle("drawer-on");
        drawerElement.style.width='500px';
  }
  const openDrawerRouting=()=>{
    var drawerElement = document.querySelector("#routing-modal");
        drawerElement.classList.toggle("drawer-on");
        drawerElement.style.width='500px';
  }
  var query = window.location.href;
  var vars = query.split("/");
  var id = vars[4];
  const navigate = useNavigate();
  const [lbData, setLbData] = useState(null);
  const [cloudData, setCloudData] = useState(null);
  const [lbBackendData, setLbBackendData] = useState(null);
  const dataFetchedRef = useRef(false);
  const navRedirect = () => {
    setTimeout(() => {
      navigate("/loadbalancer/" + id);
    }, 3000);
  };
  const getLoadbalancer = async () => {
    const data = await UseApi("get", "loadbalancer/" + id, "");
    if (data.loadbalancers.length <= 0) {
      swal({
        title: "Loadbalancer",
        text: "No Loadbalancer record found with id: " + id + "!",
        icon: "warning",
        button: "OK",
      }).then(() => {
        navigate("/loadbalancer");
      });
      return;
    } else {
      setLbData(data.loadbalancers[0]);
      if (data.loadbalancers[0]) {
        if (data.loadbalancers[0].backends) {
          setLbBackendData(data.loadbalancers[0].backends);
        }
      }

      //   toast.update(dt,{render: "Your Loadbalancer info listed below", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      // });
      // //console.log(data.loadbalancers);
    }
  };

  const getCloud = async () => {
    const data = await UseApi("get", "cloud", "");
    if (Object.keys(data).length === 0) {
      setCloudData(null);
    } else {
      setCloudData(data.cloud);
    }
  };

  const chnageRule = () => {
    const src_proto = document.querySelector("#src_proto");
    const src_port = document.querySelector("#src_port");
    const dst_proto = document.querySelector("#dst_proto");
    const dst_port = document.querySelector("#dst_port");

    if (src_proto.value == "tcp") {
      dst_proto.innerHTML = "<option value='tcp'>TCP</option>";
      src_port.value = 80;
      dst_port.value = 80;
    }
    if (src_proto.value == "http") {
      dst_proto.innerHTML = "<option value='http'>HTTP</option>";
      src_port.value = 80;
      dst_port.value = 80;
    }
    if (src_proto.value == "https") {
      dst_proto.innerHTML = "<option value='https'>HTTPS</option>";
      src_port.value = 443;
      dst_port.value = 443;
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getLoadbalancer();
    getCloud();
  }, []);

  const addBackendServer = async () => {
    toast.dismiss();
    const dt = toast.loading("Backend server in process, Please wait...");
    const responseData = await UsePost(
      "post",
      "loadbalancer/" + id + "/backend/add",
      "addbackend"
    );
    if (responseData.status === "error") {
      toast.update(dt, {
        render: responseData.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getLoadbalancer();
      navRedirect();
    } else {
      toast.update(dt, {
        render: responseData.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getLoadbalancer();
      navRedirect();
    }
  };
  const addCustomBackendServer = async () => {
    toast.dismiss();
    const dt = toast.loading("Backend server in process, Please wait...");
    const responseData = await UsePost(
      "post",
      "loadbalancer/" + id + "/backend/add",
      "addcustombackend"
    );
    if (responseData.status === "error") {
      toast.update(dt, {
        render: responseData.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getLoadbalancer();
      navRedirect();
    } else {
      toast.update(dt, {
        render: responseData.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getLoadbalancer();
      navRedirect();
    }
  };

  const updateLb = async () => {
    toast.dismiss();
    const dt = toast.loading(
      "Updating the current Loadbalancer, Please wait..."
    );
    const responseData = await UsePost(
      "post",
      "loadbalancer/" + id + "/update",
      "lbupdate"
    );
    if (responseData.status === "error") {
      toast.update(dt, {
        render: responseData.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getLoadbalancer();
      navRedirect();
    } else {
      toast.update(dt, {
        render: responseData.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getLoadbalancer();
      navRedirect();
    }
  };

  const destroyLb = async () => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Load Balancer!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const dt = toast.loading("Loadbalancer deleting...");
        const dnsp = await UseApi(
          "DELETE",
          "loadbalancer/" + id + "/destroy",
          ""
        );
        // //console.log(dnsp);
        if (dnsp.status == "error") {
          toast.update(dt, {
            render: dnsp.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.update(dt, {
            render: dnsp.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/loadbalancer");
        }
      } else {
        swal({
          title: "Load Balancer",
          text: "Your LoadBalancer is safe!",
          icon: "success",
          button: "OK",
        });
      }
    });
  };

  const deleteBackend = async (lbid) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Backend server!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const dt = toast.loading("LoadBalancer backend server deleting...");
        const dnsp = await UseApi(
          "DELETE",
          "loadbalancer/" + id + "/backend/" + lbid + "/delete",
          ""
        );
        //console.log(dnsp);
        if (dnsp.status == "error") {
          toast.update(dt, {
            render: dnsp.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (dnsp.status == "success") {
          toast.update(dt, {
            render: dnsp.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getLoadbalancer();
          navRedirect();
        }
      } else {
        swal({
          title: "Load Balancer",
          text: "Your Backend server is safe!",
          icon: "success",
          button: "OK",
        });
      }
    });
  };

  const deleteRule = async (ruleid) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this loadbalancer rule!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const dt = toast.loading("Rule deleting...");
        const dnsp = await UseApi(
          "DELETE",
          "loadbalancer/" + id + "/rule/" + ruleid + "/delete",
          ""
        );
        //console.log(dnsp);
        if (dnsp.status == "error") {
          toast.update(dt, {
            render: dnsp.message,
            type: "warning",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (dnsp.status == "success") {
          toast.update(dt, {
            render: dnsp.message,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getLoadbalancer();
          navRedirect();
        }
      } else {
        swal({
          title: "Load Balancer",
          text: "Your loadbalancer rule is safe!",
          icon: "success",
          button: "OK",
        });
      }
    });
  };

  const custom_show_sec = (id) => {
    const customBody = document.querySelector("#customBody");
    const cloudBody = document.querySelector("#cloudBody");
    const customShow = document.querySelector("#custom_show");
    const cloudShow = document.querySelector("#cloud_show");
    const customFooter = document.querySelector("#customFooter");
    const cloudFooter = document.querySelector("#cloudFooter");

    if (id === "custom") {
      customShow.classList.add("d-none");
      cloudShow.classList.remove("d-none");
      customBody.classList.remove("d-none");
      customFooter.classList.remove("d-none");
      cloudBody.classList.add("d-none");
      cloudFooter.classList.add("d-none");
    }
    if (id === "cloud") {
      customShow.classList.remove("d-none");
      cloudShow.classList.add("d-none");
      customBody.classList.add("d-none");
      customFooter.classList.add("d-none");
      cloudBody.classList.remove("d-none");
      cloudFooter.classList.remove("d-none");
    }
  };
  //  const addCustomServer=async()=>{
  //   const dt=toast.loading("Please wait...");
  //   const responseData=await UsePost('post','loadbalancer/'+id+'/backend/add','addcustombackend');
  //   //console.log(responseData);
  //     if(responseData.status==='error'){
  //       toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  //     });
  //     navigate("/loadbalancer/"+id);
  //     }else if(responseData.status==='success'){
  //       toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  //     });
  //     getLoadbalancer();
  //   }
  //     // navigate("/loadbalancer/"+id);
  //   }
  const addNewRule = async () => {
    toast.dismiss();
    const dt = toast.loading("Please wait...");
    const responseData = await UsePost(
      "post",
      "loadbalancer/" + id + "/rule/add",
      "addrule"
    );
   
    if (responseData.status === "error") {
      toast.update(dt, {
        render: responseData.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getLoadbalancer();
      navRedirect();
    } else {
      toast.update(dt, {
        render: responseData.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getLoadbalancer();
      navRedirect();
    }
  };


  const addAdvanceRouting = async () => {
    var submitBtn=document.querySelector("#addRouteBtn");  
       
    if(submitBtn){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
   
    toast.dismiss();
    const dt = toast.loading("Adding Advance Routing, Please wait...");
    const responseData = await UsePost(
      "post",
      "loadbalancer/" + id + "/route/add",
      "addRoutingForm"
    );
    submitBtn.classList.remove("disabled");
    submitBtn.innerHTML=preContent;
    if (responseData.status === "error") {
      toast.update(dt, {
        render: responseData.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getLoadbalancer();
      navRedirect();
    } else {
      toast.update(dt, {
        render: responseData.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      openDrawerRouting();
      getLoadbalancer();
      navRedirect();
    }
  }
  };

  const addAclRule = async () => {
    var submitBtn=document.querySelector("#AddAclBtn");  
       
    if(submitBtn){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
   
    toast.dismiss();
    const dt = toast.loading("ACL Rule adding, Please wait...");
    const responseData = await UsePost(
      "post",
      "loadbalancer/" + id + "/acl/add",
      "addAclForm"
    );
    submitBtn.classList.remove("disabled");
    submitBtn.innerHTML=preContent;
    if (responseData.status === "error") {
      toast.update(dt, {
        render: responseData.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getLoadbalancer();
      navRedirect();
    } else {
      toast.update(dt, {
        render: responseData.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      openDrawerAcl();
      getLoadbalancer();
      navRedirect();
    }
  }
  };
  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
      {lbData != null ? (
        <>
          <div className="card mb-6 mb-xl-9">
            <div className="card-body pt-9 pb-0">
              {/*begin::Details*/}
              <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                {/*begin: Pic*/}
                <div className="me-7 mb-4">
                  <div
                    id="flag-icon"
                    className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
                  >
                    {/*begin::Image input*/}
                    <div
                      className="image-input image-input-empty"
                      data-kt-image-input="true"
                    >
                      {lbData != null ? (
                        <>
                          <img
                            className="image-input-wrapper w-40px h-40px"
                            src={"/img/flag_" + lbData.cc + ".png"}
                          />
                        </>
                      ) : (
                        ""
                      )}{" "}
                    </div>{" "}
                    {/*end::Image input*/}
                  </div>
                </div>
                {/*end::Pic*/}
                {/*begin::Info*/}
                <div className="flex-grow-1">
                  {/*begin::Title*/}
                  <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    {/*begin::User*/}
                    <div className="d-flex flex-column">
                      {/*begin::Name*/}
                      <div className="d-flex align-items-center mb-2">
                        <a
                          className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1"
                          id="head-name"
                        >
                          {lbData != null ? <> {lbData.name} </> : ""}
                        </a>
                      </div>
                      {/*end::Name*/}
                      {/*begin::Info*/}
                      <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                        <div
                          className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400"
                          id="location"
                        >
                          Location: {lbData != null ? <> {lbData.city} </> : ""}
                        </div>
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Stats*/}
                    <div className="d-flex flex-wrap flex-stack">
                      {/*begin::Actions*/}
                      <div className="d-flex mb-4 ">
                        {/*begin::Input*/}
                        <div
                          className=" zoom me-5 btn btn-sm btn-light-primary"
                          style={{ marginRight: 50 }}
                        >
                          IP Address:{" "}
                          <span id="load-ip" className="fw-bold">
                            {lbData != null ? <> {lbData.ip} </> : ""}
                          </span>
                        </div>
                        {/*end::Input*/}
                        <div className="text-gray-800 fs-1 me-3">Status: </div>
                        {lbData != null ? (
                          <>
                            {lbData.status == "Active" ? (
                              <>
                                <a
                                  className="btn btn-sm btn-success"
                                  id="status-type"
                                >
                                  {lbData.status}
                                </a>
                              </>
                            ) : (
                              <>
                                <a
                                  className="btn btn-sm btn-warning"
                                  id="status-type"
                                >
                                  {lbData.status}
                                </a>
                              </>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/*end::Actions*/}
                      {/*begin::Progress*/}
                      {/*end::Progress*/}
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Title*/}
                </div>
                {/*end::Info*/}
              </div>
              {/*end::Details*/}
              <div className="separator" />
              {/*begin::Nav*/}
              <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                {/*begin::Nav item*/}
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6 active"
                    data-bs-toggle="tab"
                    href="#backends_load"
                  >
                    Backends
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#rules_load"
                  >
                    Rules
                  </a>
                </li>
                <li className="nav-item d-none">
                  <a
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#advanceRouting_load"
                  >
                    Advance Routing
                  </a>
                </li>
                <li className="nav-item d-none">
                  <a
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#ssl_load"
                  >
                    SSL
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={getLoadbalancer}
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#settings_load"
                  >
                    Settings
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-active-primary py-5 me-6"
                    data-bs-toggle="tab"
                    href="#destroy_load"
                  >
                    Destroy
                  </a>
                </li>
                {/*end::Nav item*/}
              </ul>
              {/*end::Nav*/}
            </div>
          </div>

          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade " id="rules_load" role="tabpanel">
              {/*begin::License usage*/}
              <div className="card">
                {/*begin::incoming rules*/}
                <div className="">
                  <div className="tab-content">
                    {/*begin::Tap pane*/}
                    <div className="tab-pane fade show active" id="rule_tab_1">
                      {/*begin::API keys*/}
                      <div className="">
                        {/*begin::Card header*/}
                        <div className="card-header">
                          {/*begin::Heading*/}
                          <div className="card-title">
                            <h3>Load Balancer Rules</h3>
                          </div>
                          {/*end::Heading*/}
                          {/*begin::Toolbar*/}
                          <div className="card-toolbar">
                            <a
                              className="btn btn-sm btn-primary me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#addrule-modal"
                            >
                              Add Rules
                            </a>
                          </div>
                          {/*end::Toolbar*/}
                        </div>
                        {/*end::Card header*/}
                      </div>
                      {/*begin::Table container*/}
                      <div className="table-responsive">
                        {/*begin::Table*/}
                        <table className="table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                          {/*begin::Table head*/}
                          <thead>
                            <tr className="fw-bolder">
                              <th className="text-center min-w-250px">
                                Load Balancer
                              </th>
                              <th className="text-center min-w-250px">
                                Servers
                              </th>
                              <th className="text-center min-w-200px"></th>
                            </tr>
                          </thead>
                          {/*end::Table head*/}
                          <tbody id="dns_list">
                            <tr>
                              <td>
                                <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
                                  {/*begin::Table head*/}
                                  <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                                    <tr className="fw-bolder">
                                      <th className="w-250px min-w-175px ps-9">
                                        Protocol
                                      </th>
                                      <th className="text-center w-275px min-w-250px px-0">
                                        Port
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    id="balancer_"
                                    className="fs-6 fw-bold text-gray-600"
                                  >
                                    {lbData != null &&
                                      lbData.rules &&
                                      lbData.rules.map((value, index) => (
                                        <tr>
                                          <td className="fw-bold">
                                            {value.src_proto}
                                          </td>{" "}
                                          <td className="text-center fw-bold">
                                            <a className="badge badge-light-success  me-1 me-5">
                                              {value.src_port}
                                            </a>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </td>
                              <td>
                                <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
                                  {/*begin::Table head*/}
                                  <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                                    <tr className="fw-bolder">
                                      <th className="w-250px min-w-175px ps-9">
                                        Protocol
                                      </th>
                                      <th className="text-center w-275px min-w-250px px-0">
                                        Port
                                      </th>
                                      <th className="text-center w-275px min-w-250px px-0"></th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    id="server_"
                                    className="fs-6 fw-bold text-gray-600"
                                  >
                                    {lbData != null &&
                                      lbData &&
                                      lbData.rules.map((value, index) => (
                                        <tr>
                                          <td className="fw-bold">
                                            {value.dst_proto}
                                          </td>{" "}
                                          <td className="text-center fw-bold">
                                            <a className="badge badge-light-success me-1 me-5">
                                              {value.dst_port}
                                            </a>
                                          </td>
                                          <td className="text-center fw-bold">
                                            <a
                                              onClick={() =>
                                                deleteRule(value.id)
                                              }
                                            >
                                              <span className="svg-icon svg-icon-danger svg-icon-2 mt-5">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                >
                                                  <path
                                                    d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                                    fill="black"
                                                  />
                                                  <path
                                                    opacity="0.5"
                                                    d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                                    fill="black"
                                                  />
                                                  <path
                                                    opacity="0.5"
                                                    d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                                    fill="black"
                                                  />
                                                </svg>
                                              </span>
                                            </a>
                                            {/*end::Svg Icon*/}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {/*begin::Table body*/}
                      </div>
                    </div>
                    {/*end::Tap pane*/}
                    {/*end::Tap pane*/}
                  </div>
                </div>
                {/*end::incoming rules*/}
              </div>
              {/*end::License usage*/}
            </div>
            <div className="tab-pane fade d-none" id="ssl_load" role="tabpanel">
              <div className="card">
                <div className="card-header mt-5">
                  <h2>New Certificate</h2>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="" style={{ marginLeft: 50 }}>
                        <div className="w-100 d-none" id="paid-ssl">
                          {/*begin::Heading*/}
                          <div className="pb-10 pb-lg-12">
                            {/*begin::Title*/}
                            <h2 className="fw-bold text-dark">Let's Encrypt</h2>
                            {/*end::Title*/}
                            {/*begin::Notice*/}
                            <div className="text-muted fw-semibold fs-6">
                              Automatically encrypt traffic up to the Load
                              Balancer with a free Let's Encrypt certificate.
                              Choose domains using the search box below. We'll
                              generate and auto renew the certificate.
                              <a
                                href="https://letsencrypt.org/"
                                className="link-primary fw-bold"
                              >
                                Learn more
                              </a>
                              .
                            </div>
                            {/*end::Notice*/}
                          </div>
                          {/*end::Heading*/}
                          {/*begin::Input group*/}
                          <div className="fv-row mb-10 fv-plugins-icon-container">
                            {/*begin::Label*/}
                            <label className="form-label required">
                              Search for a domain on Utho Cloud
                            </label>
                            {/*end::Label*/}
                            {/*begin::Input*/}
                            <input
                              className="form-control form-control-lg "
                              defaultValue=""
                            />
                            {/*end::Input*/}
                            <div className="fv-plugins-message-container invalid-feedback" />
                          </div>
                          {/*end::Input group*/}
                          <div className="mb-0 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                            {/*begin::Options*/}
                            <div className="mb-0">
                              {/*begin:Option*/}
                              <label className="d-flex flex-stack mb-5 cursor-pointer">
                                {/*begin:Label*/}
                                <span className="d-flex align-items-center me-2">
                                  {/*begin::Icon*/}
                                  <span className="symbol symbol-50px me-6">
                                    <span className="symbol-label">
                                      {/*begin::Svg Icon | path: icons/duotune/finance/fin001.svg*/}
                                      <span className="svg-icon svg-icon-1 svg-icon-gray-600">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.3"
                                            d="M21.3 11.9C21.3 17.1 17.1 21.3 12 21.3C6.8 21.3 2.70001 17.1 2.70001 11.9C2.70001 6.70002 6.9 2.5 12 2.5C17.2 2.6 21.3 6.80002 21.3 11.9Z"
                                            fill="black"
                                          />
                                          <path
                                            d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM20 11.5C18.2 11.3 16.4 11.3 14.8 11.6C14.6 11 14.3 10.4 14.1 9.90002C15.6 9.10002 17.1 8.10002 18.3 6.90002C19.2 8.20002 19.9 9.8 20 11.5ZM16.7 5.59998C15.6 6.59998 14.4 7.49995 13.1 8.19995C12.4 6.79995 11.6 5.49995 10.7 4.19995C11.1 4.09995 11.5 4.09998 12 4.09998C13.8 3.99998 15.4 4.59998 16.7 5.59998ZM8.70001 4.69995C9.70001 6.09995 10.5 7.50002 11.3 8.90002C9.09999 9.70002 6.79999 10 4.29999 9.80005C4.89999 7.60005 6.60001 5.69995 8.70001 4.69995ZM4 12C4 11.9 4 11.9 4 11.8C4.5 11.8 5.10001 11.9 5.60001 11.9C7.90001 11.9 10.1 11.5 12.1 10.7C12.3 11.2 12.5 11.7 12.7 12.1C10.1 13 7.70001 14.6 5.70001 17C4.70001 15.6 4 13.9 4 12ZM7.20001 18.4C9.00001 16.3 11.1 14.8 13.4 14C14 15.8 14.4 17.6 14.7 19.5C13.8 19.8 12.9 20 11.9 20C10.2 20 8.60001 19.4 7.20001 18.4ZM16.6 18.5C16.3 16.8 15.9 15.2 15.4 13.5C16.8 13.3 18.3 13.3 19.8 13.5C19.4 15.6 18.3 17.3 16.6 18.5Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>
                                      {/*end::Svg Icon*/}
                                    </span>
                                  </span>
                                  {/*end::Icon*/}
                                  {/*begin::Description*/}
                                  <span className="d-flex flex-column">
                                    <span className="fw-bold text-gray-800 text-hover-primary fs-5">
                                      Include all subdomains (wildcard
                                      certificate)
                                    </span>
                                  </span>
                                  {/*end:Description*/}
                                </span>
                                {/*end:Label*/}
                                {/*begin:Input*/}
                                <span className="form-check form-check-custom form-check-solid">
                                  <input
                                    className="form-check-input"
                                    onclick="subdomain_field();"
                                    name="subdomain_radio"
                                    id="all_domain"
                                    type="radio"
                                  />
                                </span>
                                {/*end:Input*/}
                              </label>
                              {/*end::Option*/}
                              {/*begin:Option*/}
                              <label className="d-flex flex-stack mb-5 cursor-pointer">
                                {/*begin:Label*/}
                                <span className="d-flex align-items-center me-2">
                                  {/*begin::Icon*/}
                                  <span className="symbol symbol-50px me-6">
                                    <span className="symbol-label">
                                      {/*begin::Svg Icon | path: icons/duotune/graphs/gra006.svg*/}
                                      <span className="svg-icon svg-icon-1 svg-icon-gray-600">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.3"
                                            d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z"
                                            fill="black"
                                          />
                                          <path
                                            d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span>
                                      {/*end::Svg Icon*/}
                                    </span>
                                  </span>
                                  {/*end::Icon*/}
                                  {/*begin::Description*/}
                                  <span className="d-flex flex-column">
                                    <span className="fw-bold text-gray-800 text-hover-primary fs-5">
                                      Select specific subdomains
                                    </span>
                                  </span>
                                  {/*end:Description*/}
                                </span>
                                {/*end:Label*/}
                                {/*begin:Input*/}
                                <span className="form-check form-check-custom form-check-solid">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    onclick="subdomain_field();"
                                    id="custom_domain"
                                    name="subdomain_radio"
                                  />
                                </span>
                                {/*end:Input*/}
                              </label>
                              {/*end::Option*/}
                            </div>
                            {/*end::Options*/}
                          </div>
                          {/*begin::Input group*/}
                          <div
                            className="fv-row mb-10 fv-plugins-icon-container d-none"
                            id="select_subdomain_filed"
                          >
                            {/*begin::Input*/}
                            <input
                              className="form-control form-control-lg "
                              placeholder="Name this certificate"
                              defaultValue=""
                            />
                            {/*end::Input*/}{" "}
                          </div>
                          {/*end::Input group*/}
                          {/*begin::Input group*/}
                          <div className="fv-row mb-10 fv-plugins-icon-container">
                            <button className="btn btn-primary w-100">
                              Generate Certificate
                            </button>
                          </div>
                          {/*end::Input group*/}
                        </div>
                        <div className="w-100" id="own-certificate">
                          {/*begin::Heading*/}
                          <div className="pb-10 pb-lg-12">
                            {/*begin::Title*/}
                            <h2 className="fw-bold text-dark">
                              Create an SSl certificate
                            </h2>
                            {/*end::Title*/}
                            {/*begin::Notice*/}
                            {/* <div class="text-muted fw-semibold fs-6">If you need more info, please check out
															<a  class="link-primary fw-bold">Help Page</a>.</div> */}
                            {/*end::Notice*/}
                          </div>
                          {/*end::Heading*/}
                          {/*begin::Input group*/}
                          <div className="fv-row mb-10 fv-plugins-icon-container">
                            {/*begin::Label*/}
                            <label className="form-label required">Name</label>
                            {/*end::Label*/}
                            {/*begin::Input*/}
                            <input
                              name="name"
                              className="form-control form-control-lg  w-50"
                              defaultValue=""
                            />
                            {/*end::Input*/}
                            <div className="fv-plugins-message-container invalid-feedback" />
                          </div>
                          {/*end::Input group*/}
                          <div className="row">
                            {/*begin::Input group*/}
                            <div className="col-lg-6 col-md-6">
                              <div className="fv-row mb-10">
                                {/*end::Label*/}
                                <label className="form-label required">
                                  Certificate
                                </label>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <textarea
                                  name="certificate"
                                  className="form-control form-control-lg "
                                  rows={18}
                                  defaultValue={""}
                                />
                                {/*end::Input*/}
                              </div>
                              {/*end::Input group*/}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              {/*begin::Input group*/}
                              <div className="fv-row mb-10">
                                {/*end::Label*/}
                                <label className="form-label required">
                                  Private Key
                                </label>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <textarea
                                  name="private_key"
                                  className="form-control form-control-lg "
                                  rows={18}
                                  defaultValue={""}
                                />
                                {/*end::Input*/}
                              </div>
                              {/*end::Input group*/}
                            </div>
                          </div>
                          {/*begin::Input group*/}
                          <div className="fv-row mb-10">
                            {/*end::Label*/}
                            <label className="form-label">
                              Certificate chain
                            </label>
                            {/*end::Label*/}
                            {/*begin::Input*/}
                            <textarea
                              name="certificate_chain"
                              className="form-control form-control-lg "
                              rows={15}
                              defaultValue={""}
                            />
                            {/*end::Input*/}
                          </div>
                          {/*end::Input group*/}
                          <div className="fv-row mb-10">
                            <button className="btn btn-primary w-100">
                              Save SSL Certificate
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="advanceRouting_load"
              role="tabpanel"
            >
              {/*begin::API keys*/}
              <div className="card mb-10">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Heading*/}
                  <div className="card-title">
                    <h3>ACL Rules</h3>
                  </div>
                  {/*end::Heading*/}
                  {/*begin::Toolbar*/}
                  <div className="card-toolbar">
                    <a
                      className="btn btn-sm btn-primary me-2"
                      onClick={openDrawerAcl}
                    >
                      Add New Rule +
                    </a>
                  </div>
                  {/*end::Toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Body*/}
                <div className="card-body p-0">
                  {/*begin::Table wrapper*/}
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table
                      className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                      id="kt_api_keys_table"
                    >
                      {/*begin::Thead*/}
                      <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                        <tr>
                          <th className="w-125px " style={{paddingLeft:"60px"}}>Name</th>
                          <th className="w-15px" />
                          <th className="w-125px">Condition</th>
                          <th className="w-15px" />
                          <th className="w-125px">Value</th>
                          <th className="w-25px" />
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody
                        className="fs-6 fw-bold text-gray-600"
                        id="acl-list"
                      >
                        {lbData!=null?(<>
                          {lbData.acls?(<>
                          {lbData.acls.length==0?(<>
                          <tr>
                            <td colSpan={12} className="text-center"> No records found</td>
                          </tr>
                          </>):(<> 
                              {lbData.acls.map((AclValue,index)=>(<>
                        <tr>
                          <td className="fw-bold text-center ps-10">
                          <div className="d-flex flex-column w-100 ps-10 me-2">
                            {" "}
                            <div className="d-flex flex-stack mb-2">
                              {" "}
                              <span className=" me-2 fs-7 fw-bold">{AclValue.name}</span>{" "}
                            </div>{" "}
                          </div>
                          </td>
                          <td />
                          <td className="fw-bold text-center">
                          <input
                                type="text"
                                className="form-control read"
                                placeholder="Condition" 
                                readOnly={true}
                                defaultValue={AclValue.acl_condition}
                              />
                            {/* <select className="form-select">
                              
                              {aclConditionList.length!=0?(<>
                                {aclConditionList.map((value2, index2)=>(<>
                                 {value2==AclValue.acl_condition?(<>
                                  <option value={value2} selected={true}>{value2}</option>
                                 </>):(<>
                                  <option value={value2}>{value2}</option>
                                 </>)}
                                </>))}
                              </>):('')}
                               
                            </select> */}
                          </td>
                          <td />
                          <td className="fw-bold text-center">
                            <div className="mt-1">
                              <input
                                type="text"
                                className="form-control read"
                                placeholder="Value"
                                name="value"
                                readOnly={true}
                                defaultValue={AclValue.value}
                              />
                            </div>
                          </td>
                          <td className="fw-bold text-center">
                            <span className="svg-icon svg-icon-danger svg-icon-2hx">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.5"
                                  d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.5"
                                  d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </td>
                        </tr>
                              </>))} 
                          </>)}
                          </>):('')}
                          </>):('')}
                        
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>

                  {/*end::Table wrapper*/}
                </div>
                {/*end::Body*/}
              </div>
              {/*end::API keys*/}
              {/*begin::API keys*/}
              <div className="card">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Heading*/}
                  <div className="card-title">
                    <h3>Advance Routing</h3>
                  </div>
                  {/*end::Heading*/}
                  {/*begin::Toolbar*/}
                  <div className="card-toolbar">
                    <a
                      className="btn btn-sm btn-primary me-2"
                      onClick={openDrawerRouting}
                    >
                      Add New Rule +
                    </a>
                  </div>
                  {/*end::Toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Body*/}
                <div className="card-body p-0">
                  {/*begin::Table wrapper*/}
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table
                      className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                      id="kt_api_keys_table"
                    >
                      {/*begin::Thead*/}
                      <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                        <tr>
                          <th className="w-125px">Advance Rule</th>
                          <th className="w-15px" />
                          <th className="w-125px">Condition</th>
                          <th className="w-15px" />
                          <th className="w-125px">Backend Resource</th>
                          <th className="w-25px" />
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody
                        className="fs-6 fw-bold text-gray-600"
                        id="advance-routing-list"
                      >
                        {lbData!=null?(<>
                          {lbData.routes?(<>
                          {lbData.routes.length==0?(<>
                          <tr>
                            <td colSpan={12} className="text-center"> No records found</td>
                          </tr>
                          </>):(<> 
                              {lbData.routes.map((value2,index)=>(<>
                                <tr>
                          <td className="fw-bold text-center ps-10">
                          <div className="d-flex flex-column w-100 ps-10 me-2">
                            {" "}
                            <div className="d-flex flex-stack mb-2">
                              {" "}
                              <span className=" me-2 fs-7 fw-bold">{value2.acl_name}</span>{" "}
                            </div>{" "}
                          </div>
                          </td>
                          <td />
                          <td className="fw-bold text-center">
                          <input
                                type="text"
                                className="form-control read"
                                placeholder="Condition" 
                                readOnly={true}
                                defaultValue={value2.routing_condition}
                              />
                            {/* <select className="form-select">
                              
                              {aclConditionList.length!=0?(<>
                                {aclConditionList.map((value2, index2)=>(<>
                                 {value2==AclValue.acl_condition?(<>
                                  <option value={value2} selected={true}>{value2}</option>
                                 </>):(<>
                                  <option value={value2}>{value2}</option>
                                 </>)}
                                </>))}
                              </>):('')}
                               
                            </select> */}
                          </td>
                          <td />
                          <td className="fw-bold text-center">
                            <div className="mt-1">
                              <input
                                type="text"
                                className="form-control read"
                                placeholder="Value"
                                name="value"
                                readOnly={true}
                                defaultValue={value2.backend_id}
                              />
                            </div>
                          </td>
                          <td className="fw-bold text-center">
                            <span className="svg-icon svg-icon-danger svg-icon-2hx">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.5"
                                  d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.5"
                                  d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </td>
                        </tr>
                              </>))} 
                          </>)}
                          </>):('')}
                          </>):('')}
                       
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>

                  {/*end::Table wrapper*/}
                </div>
                {/*end::Body*/}
              </div>
              {/*end::API keys*/}
            </div>
            <div
              className="tab-pane fade show active"
              id="backends_load"
              role="tabpanel"
            >
              {/*begin::API keys*/}
              <div className="card">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Heading*/}
                  <div className="card-title">
                    <h3>Load Balancer Backends</h3>
                  </div>
                  {/*end::Heading*/}
                  {/*begin::Toolbar*/}
                  <div className="card-toolbar">
                    <a
                      className="btn btn-sm btn-primary me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#addbackend-modal"
                    >
                      Add Backend Server
                    </a>
                  </div>
                  {/*end::Toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Body*/}
                <div className="card-body p-0">
                  {/*begin::Table wrapper*/}
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table
                      className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                      id="kt_api_keys_table"
                    >
                      {/*begin::Thead*/}
                      <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                        <tr>
                          <th className="w-250px min-w-175px ps-9">
                            #Backends
                          </th>
                          <th className="w-275px min-w-250px px-0">Name</th>
                          <th className="w-125px min-w-225px">IP</th>
                          <th className="w-125px min-w-225px">Added at</th>
                          <th className="w-125px min-w-225px" />
                        </tr>
                      </thead>
                      {/*end::Thead*/}
                      {/*begin::Tbody*/}
                      <tbody
                        id="backends-list"
                        className="fs-6 fw-bold text-gray-600"
                      >
                        {lbBackendData != null &&
                          lbBackendData &&
                          lbBackendData.map((value, index) => (
                            <tr>
                              <td className="text-center">
                                <div className="d-flex flex-column w-100 me-2">
                                  <div className="d-flex flex-stack mb-2">
                                    <span className=" me-2 fs-7 fw-bold">
                                      {value.id}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="d-flex flex-column w-100 me-2">
                                  <div className="d-flex flex-stack mb-2">
                                    <span className=" me-2 fs-7 fw-bold">
                                      {value.name}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="d-flex flex-column w-100 me-2">
                                  <div className="d-flex flex-stack mb-2">
                                    <span className=" me-2 fs-7 fw-bold">
                                      {value.ip}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="d-flex flex-column w-100 me-2">
                                  <div className="d-flex flex-stack mb-2">
                                    <span className=" me-2 fs-7 fw-bold d-none">
                                      {value.created_at}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="d-flex justify-content-end flex-shrink-0">
                                  <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => deleteBackend(value.id)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      {/*end::Tbody*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                  {/*end::Table wrapper*/}
                </div>
                {/*end::Body*/}
              </div>
              {/*end::API keys*/}
              {/*begin::Modals*/}
              {/*begin::Modal - Create Api Key*/}
              <div
                className="modal fade"
                id="addbackend-modal"
                tabIndex={-1}
                aria-hidden="true"
              >
                {/*begin::Modal dialog*/}
                <div className="modal-dialog modal-dialog-centered mw-650px">
                  {/*begin::Modal content*/}
                  <div className="modal-content">
                    {/*begin::Modal header*/}
                    <div
                      className="modal-header"
                      id="kt_modal_create_api_key_header"
                    >
                      {/*begin::Modal title*/}
                      <h2>Add Backend Servers</h2>
                      {/*end::Modal title*/}
                      {/*begin::Close*/}
                      <div
                        className="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                      >
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                        <span className="svg-icon svg-icon-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x={6}
                              y="17.3137"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-45 6 17.3137)"
                              fill="black"
                            />
                            <rect
                              x="7.41422"
                              y={6}
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(45 7.41422 6)"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </div>
                      {/*end::Close*/}
                    </div>
                    {/*end::Modal header*/}
                    {/*begin::Form*/}

                    {/*begin::Modal body*/}
                    <div className="modal-body py-10 px-lg-17">
                      {/*begin::Scroll*/}
                      <div
                        className="scroll-y me-n7 pe-7"
                        id="kt_modal_create_api_key_scroll"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                        data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                        data-kt-scroll-offset="300px"
                      >
                        {/*begin::Notice*/}
                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-10 p-6">
                          {/*begin::Icon*/}
                          {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                          <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={10}
                                fill="black"
                              />
                              <rect
                                x={11}
                                y={14}
                                width={7}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11 14)"
                                fill="black"
                              />
                              <rect
                                x={11}
                                y={17}
                                width={2}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11 17)"
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                          {/*end::Icon*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex flex-stack flex-grow-1">
                            {/*begin::Content*/}
                            <div className="fw-bold">
                              <div className="fs-6 text-gray-700">
                                You can add multiple servers in backend of your
                                load balancer.
                              </div>
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Notice*/}
                        <div id="cloudBody">
                          <form id="addbackend" className="form">
                            {/*begin::Input group*/}
                            <div className="d-flex flex-column mb-10 fv-row">
                              {/*begin::Label*/}
                              <label className="required fs-5 fw-bold mb-2">
                                Cloud Servers
                              </label>
                              {/*end::Label*/}
                              {/*begin::Select*/}
                              <select
                                className="form-select form-select-solid"
                                name="cloudid"
                                id="cloudSet"
                              >
                                <option value="">Choose backend server</option>
                                {cloudData != null &&
                                  cloudData &&
                                  cloudData.map((value, index) => (
                                    <option value={value.cloudid}>
                                      {value.hostname + " " + value.ip}
                                    </option>
                                  ))}
                              </select>
                              <input
                                type="hidden"
                                className="form-control "
                                defaultValue=""
                                name="ip"
                                placeholder="Enter your custom IP"
                              />
                              {/*end::Select*/}
                            </div>
                          </form>
                        </div>
                        <div id="customBody" className="d-none">
                          <form id="addcustombackend" className="form">
                            <div className="d-flex flex-column mb-10 fv-row">
                              {/*begin::Label*/}
                              <input
                                type="hidden"
                                className="form-control"
                                name="cloudid"
                                id="cloudSet"
                                defaultValue={0}
                              />
                              <label className="required fs-5 fw-bold mb-2">
                                Custom IP
                              </label>
                              {/*end::Label*/}
                              <input
                                className="form-control "
                                defaultValue=""
                                name="ip"
                                placeholder="Enter your custom IP"
                              />
                            </div>
                          </form>
                        </div>
                        {/*end::Input group*/}
                        <div className="separator separator-content my-15">
                          or
                        </div>
                        {/*begin::Input group*/}
                        <div className="text-center">
                          <a
                            className=" btn btn-sm btn-secondary"
                            onClick={() => custom_show_sec("custom")}
                            id="custom_show"
                          >
                            Custom IP
                          </a>
                          <a
                            className=" btn btn-sm btn-secondary d-none"
                            onClick={() => custom_show_sec("cloud")}
                            id="cloud_show"
                          >
                            Cloud servers
                          </a>
                        </div>
                        {/*end::Input group*/}
                      </div>
                      {/*end::Scroll*/}
                    </div>
                    {/*end::Modal body*/}
                    {/*begin::Modal footer*/}
                    <div className="modal-footer flex-center" id="cloudFooter">
                      {/*begin::Button*/}
                      <button
                        id="addserver"
                        type="button"
                        onClick={addBackendServer}
                        className="btn btn-primary w-100"
                      >
                        Add Server
                      </button>
                      {/*end::Button*/}
                    </div>
                    <div
                      className="modal-footer flex-center d-none"
                      id="customFooter"
                    >
                      {/*begin::Button*/}
                      <button
                        id="addcustomserver"
                        type="button"
                        onClick={addCustomBackendServer}
                        className="btn btn-primary w-100"
                      >
                        Add Custom Server
                      </button>
                      {/*end::Button*/}
                    </div>
                    {/*end::Modal footer*/}

                    {/*end::Form*/}
                  </div>
                  {/*end::Modal content*/}
                </div>
                {/*end::Modal dialog*/}
              </div>
              {/*end::Modal - Create Api Key*/}
              {/*end::Modals*/}
            </div>
            <div className="tab-pane fade" id="settings_load" role="tabpanel">
              {/*begin::Deactivate Account*/}
              <div className="card">
                {/*begin::Card header*/}
                <div
                  className="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_account_deactivate"
                  aria-expanded="true"
                  aria-controls="kt_account_deactivate"
                >
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Load Balancer Settings</h3>
                  </div>
                </div>
                {/*end::Card header*/}
                {/*begin::Content*/}
                <div
                  id="kt_account_settings_deactivate"
                  className="collapse show"
                >
                  <form id="lbupdate">
                    <div className="container">
                      {/*begin::Row*/}
                      <div
                        className="row g-9"
                        data-kt-buttons="true"
                        data-kt-buttons-target="[data-kt-button]"
                        id="load_bal"
                      >
                        <div className="col-md-4 col-lg-12 col-xxl-4">
                          {" "}
                          <label className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5">
                            {" "}
                            <div className="d-flex align-items-center me-2">
                              {" "}
                              <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                {" "}
                                {lbData != null &&
                                  (lbData &&
                                  lbData.algorithm == "roundrobin" ? (
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="algorithm"
                                      defaultValue="roundrobin"
                                      id="roundrobin"
                                      defaultChecked="checked"
                                    />
                                  ) : (
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="algorithm"
                                      defaultValue="roundrobin"
                                      id="roundrobin"
                                    />
                                  ))}{" "}
                              </div>{" "}
                              <div className="flex-grow-1">
                                {" "}
                                <div className="fw-bold ">
                                  {" "}
                                  Round Robin{" "}
                                </div>{" "}
                              </div>
                            </div>
                          </label>
                        </div>{" "}
                        <div className="col-md-4 col-lg-12 col-xxl-4">
                          {" "}
                          <label className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5">
                            {" "}
                            <div className="d-flex align-items-center me-2">
                              {" "}
                              <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                {" "}
                                {lbData != null &&
                                  (lbData.algorithm &&
                                  lbData.algorithm == "leastconn" ? (
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="algorithm"
                                      defaultValue="leastconn"
                                      id="leastconn"
                                      defaultChecked="checked"
                                    />
                                  ) : (
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="algorithm"
                                      defaultValue="leastconn"
                                      id="leastconn"
                                    />
                                  ))}{" "}
                              </div>{" "}
                              <div className="flex-grow-1">
                                {" "}
                                <div className="fw-bold ">
                                  {" "}
                                  Least Connections{" "}
                                </div>{" "}
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <div
                        className="row g-9"
                        data-kt-buttons="true"
                        data-kt-buttons-target="[data-kt-button]"
                      >
                        <div className="col-md-4 col-lg-12 col-xxl-4">
                          {/*begin::Radio button*/}
                          <label className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5">
                            {/*end::Description*/}
                            <div
                              className="form-check form-switch form-check-custom form-check-solid"
                              id="redirect-http"
                            >
                              {lbData != null &&
                                (lbData.redirecthttps &&
                                lbData.redirecthttps == "1" ? (
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="redirecthttps"
                                    checked
                                  />
                                ) : (
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="redirecthttps"
                                  />
                                ))}{" "}
                              <label className="form-check-label">
                                {" "}
                                Redirect all traffic to HTTPS{" "}
                              </label>
                            </div>
                            {/*end::Description*/}
                          </label>
                          {/*end::Radio button*/}
                        </div>
                        <div className="col-md-4 col-lg-12 col-xxl-4">
                          {/*begin::Radio button*/}
                          <label className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5">
                            {/*end::Description*/}
                            <div
                              className="form-check form-switch form-check-custom form-check-solid"
                              id="sticky-session"
                            >
                              {lbData != null &&
                                (lbData.cookie && lbData.cookie == "1" ? (
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="cookie"
                                    id="cookie"
                                    checked
                                  />
                                ) : (
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="cookie"
                                    id="cookie"
                                  />
                                ))}

                              <label className="form-check-label">
                                {" "}
                                Sticky Sessions
                              </label>
                            </div>
                            {/*end::Description*/}
                            {/*begin::Price*/}
                            <div className="ms-5" id="sticky-input">
                              <input
                                type="text"
                                className="form-control"
                                id="cookiename"
                                style={{ display: "none" }}
                                name="cookiename"
                                minvalue={1}
                                placeholder="MH-LB"
                                defaultValue="MH-LB"
                              />
                            </div>
                            {/*end::Price*/}
                          </label>
                          {/*end::Radio button*/}
                        </div>
                      </div>
                      {/*end::Row*/}
                      {/*begin::Card footer*/}
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          onClick={updateLb}
                          type="button"
                          className="btn btn-primary fw-bold"
                        >
                          Update Setting
                        </button>
                      </div>
                      {/*end::Card footer*/}
                    </div>
                  </form>
                </div>
                {/*end::Content*/}
              </div>
              {/*end::Deactivate Account*/}
            </div>
            <div className="tab-pane fade" id="destroy_load" role="tabpanel">
              {/*begin::Deactivate Account*/}
              <div className="card">
                {/*begin::Card header*/}
                <div
                  className="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_account_deactivate"
                  aria-expanded="true"
                  aria-controls="kt_account_deactivate"
                >
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Destroy Load Balancer</h3>
                  </div>
                </div>
                {/*end::Card header*/}
                {/*begin::Content*/}
                <div
                  id="kt_account_settings_deactivate"
                  className="collapse show"
                >
                  {/*begin::Form*/}
                  {/*begin::Card body*/}
                  <div className="card-body border-top p-9">
                    {/*begin::Form input row*/}
                    <div className="mb-10">
                      <label htmlFor="size" className=" form-label">
                        Your Load Balancer will be permanently destroyed. Any
                        associated servers will be disconnected and will stop
                        receiving distributed traffic. Servers will not be
                        destroyed. You will lose the provisioned IP address,
                        which might impact any DNS records pointing to it. This
                        will not affect any associated Servers.{" "}
                      </label>
                    </div>
                    {/*end::Form input row*/}
                  </div>
                  {/*end::Card body*/}
                  {/*begin::Card footer*/}
                  <div
                    className="card-footer d-flex justify-content-end py-6 px-9"
                    id="destroy-load"
                  >
                    <button
                      onClick={destroyLb}
                      type="button"
                      className="btn btn-danger fw-bold"
                    >
                      Destroy
                    </button>
                  </div>
                  {/*end::Card footer*/}
                  {/*end::Form*/}
                </div>
                {/*end::Content*/}
              </div>
              {/*end::Deactivate Account*/}
            </div>
          </div>

          <div
            className="modal fade"
            id="addrule-modal"
            tabIndex={-1}
            style={{ display: "none" }}
          >
            {/*begin::Modal dialog*/}
            <div className="modal-dialog modal-dialog-centered mw-650px">
              {/*begin::Modal content*/}
              <div className="modal-content">
                {/*begin::Modal header*/}
                <div
                  className="modal-header"
                  id="kt_modal_create_api_key_header"
                >
                  {/*begin::Modal title*/}
                  <h2>Add Load Balancer Rules</h2>
                  {/*end::Modal title*/}
                  {/*begin::Close*/}
                  <div
                    className="btn btn-sm btn-icon btn-active-color-primary"
                    data-bs-dismiss="modal"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x={6}
                          y="17.3137"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-45 6 17.3137)"
                          fill="black"
                        />
                        <rect
                          x="7.41422"
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(45 7.41422 6)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Close*/}
                </div>
                {/*end::Modal header*/}
                {/*begin::Form*/}
                <form id="addrule" name="addrule" className="form">
                  {/*begin::Modal body*/}
                  <div className="modal-body py-10 px-lg-17">
                    {/*begin::Scroll*/}
                    <div
                      className="scroll-y me-n7 pe-7"
                      id="kt_modal_create_api_key_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                      data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      <div className="row">
                        <h2 className="text-center">Load Balancer</h2>
                        {/*begin::Input group*/}
                        <div className="col-lg-6 col-md-6 col-xxl-6">
                          <div className="d-flex flex-column mb-10 fv-row">
                            {/*begin::Label*/}
                            <label className="required fs-5 fw-bold mb-2 text-muted">
                              Protocol
                            </label>
                            {/*end::Label*/}
                            {/*begin::Select*/}
                            <select
                              className="form-select form-select-solid"
                              name="src_proto"
                              id="src_proto"
                              data-hide-search="true"
                              onClick={chnageRule}
                            >
                              <option value="tcp">TCP</option>
                              <option value="http">HTTP</option>
                              <option value="https">HTTPS</option>
                            </select>
                            {/*end::Select*/}
                          </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="col-lg-6 col-md-6 col-xxl-6">
                          <div className="d-flex flex-column mb-10 fv-row">
                            {/*begin::Label*/}
                            <label className="required fs-5 fw-bold mb-2  text-muted">
                              Port
                            </label>
                            {/*end::Label*/}
                            {/*begin::Select*/}
                            <input
                              className="form-control"
                              type="text"
                              id="src_port"
                              name="src_port"
                              minvalue={1}
                              maxvalue={65444}
                              placeholder={80}
                              defaultValue={80}
                            />
                            {/*end::Select*/}
                          </div>
                        </div>
                        {/*end::Input group*/}
                      </div>
                      <div className="row">
                        <h2 className="text-center">Servers</h2>
                        {/*begin::Input group*/}
                        <div className="col-lg-6 col-md-6 col-xxl-6">
                          <div className="d-flex flex-column mb-10 fv-row">
                            {/*begin::Label*/}
                            <label className="required fs-5 fw-bold mb-2  text-muted">
                              Protocol
                            </label>
                            {/*end::Label*/}
                            {/*begin::Select*/}
                            <select
                              className="form-select form-select-solid "
                              name="dst_proto"
                              id="dst_proto"
                              data-hide-search="true"
                              onClick={chnageRule}
                            >
                              <option value="tcp">TCP</option>
                              <option value="http">HTTP</option>
                              <option value="https">HTTPS</option>
                            </select>
                            {/*end::Select*/}
                          </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="col-lg-6 col-md-6 col-xxl-6">
                          <div className="d-flex flex-column mb-10 fv-row">
                            {/*begin::Label*/}
                            <label className="required fs-5 fw-bold mb-2  text-muted">
                              Port
                            </label>
                            {/*end::Label*/}
                            {/*begin::Select*/}
                            <input
                              className="form-control"
                              type="text"
                              id="dst_port"
                              name="dst_port"
                              minvalue={1}
                              maxvalue={65444}
                              placeholder={80}
                              defaultValue={80}
                            />
                            {/*end::Select*/}
                          </div>
                        </div>
                        {/*end::Input group*/}
                      </div>
                    </div>
                    {/*end::Scroll*/}
                  </div>
                  {/*end::Modal body*/}
                  {/*begin::Modal footer*/}
                  <div className="modal-footer flex-center">
                    {/*begin::Button*/}
                    <button
                      type="button"
                      onClick={addNewRule}
                      className="btn btn-primary w-100"
                    >
                      <span className="indicator-label">Add New Rule</span>
                    </button>
                    {/*end::Button*/}
                  </div>
                  {/*end::Modal footer*/}
                </form>
                {/*end::Form*/}
              </div>
              {/*end::Modal content*/}
            </div>
            {/*end::Modal dialog*/}
          </div>

          <div
            id="routing-modal"
            className="bg-body shadow drawer drawer-end"
            data-kt-drawer="true"
            data-kt-drawer-name="chat"
            data-kt-drawer-activate="true"
            data-kt-drawer-direction="end"
            data-kt-drawer-close="#close_ssh"
            data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
            style={{ maxWidth: "70%", important: true }}
          >
            {/*begin::Messenger*/}
            <div
              className="card rounded-0 w-100 border-0"
              id="kt_drawer_chat_messenger"
            >
              {/*begin::Card header*/}
              <div
                className="card-header pe-5"
                id="kt_drawer_chat_messenger_header"
              >
                {/*begin::Title*/}
                <div className="card-title">
                  {/*begin::User*/}
                  <div className="d-flex justify-content-center flex-column me-3">
                    <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                      Add Advance Routing
                    </a>
                  </div>
                  {/*end::User*/}
                </div>
                {/*end::Title*/}
                {/*begin::Card toolbar*/}
                <div className="card-toolbar">
                  {/*begin::Close*/}
                  <div
                    onClick={openDrawerRouting}
                    className="btn btn-sm btn-icon btn-active-light-primary"
                    id="close_ssh"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x={6}
                          y="17.3137"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-45 6 17.3137)"
                          fill="black"
                        />
                        <rect
                          x="7.41422"
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(45 7.41422 6)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Close*/}
                </div>
                {/*end::Card toolbar*/}
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="" id="">
                {/*begin::Form*/}
                <form  id="addRoutingForm" className="form">
                  {/*begin::Modal body*/}
                  <div className="modal-body ">
                    {/*begin::Scroll*/}
                    <div
                      className="scroll-y me-n7 pe-7"
                      id="kt_modal_create_api_key_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                      data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      {/*begin::Input group*/}
                      <div className="mb-5 fv-row">
                        {/*begin::Label*/}
                        <label className="required fs-5 fw-bold mb-2">
                          {" "}
                          Advance Rule
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                       
                          {lbData!=null?(<>
                          {lbData.acls?(<>
                            <select className="form-select" name="acl_id">
                          {lbData.acls.length==0?(<>
                            <option>No ACL rule found</option>
                          </>):(<>
                             
                              {lbData.acls.map((value2,index)=>(<>
                                <option value={value2.id}>{value2.name}</option>
                              </>))}
                        
                          </>)}
                          </select> 
                          </>):('')}
                          </>):('')}
                          
                        {/*end::Input*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className="mb-5 fv-row">
                        {/*begin::Label*/}
                        <label className="required fs-5 fw-bold mb-2">
                          {" "}
                          Condition
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select className="form-select" name="route_condition">
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </select> 
                        {/*end::Input*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className="mb-5 fv-row">
                        {/*begin::Label*/}
                        <label className="required fs-5 fw-bold mb-2">
                          {" "}
                          Backend Resource
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        {lbData!=null?(<>
                          {lbData.backends?(<>
                            <select className="form-select" name="backend">
                          {lbData.backends.length==0?(<>
                          <option>No backend resource found</option>
                          </>):(<>
                            
                              {lbData.backends.map((value2,index)=>(<>
                                <option value={value2.id}>{value2.name}</option>
                              </>))}
                       
                          </>)}
                          </select> 
                          </>):('')}
                          </>):('')}
                          
                        {/*end::Input*/}
                      </div>
                      {/*end::Input group*/}
                    </div>
                    {/*end::Scroll*/}
                  </div>
                  {/*end::Modal body*/}
                  {/*begin::Modal footer*/}
                  <div className="modal-footer ">
                    {/*begin::Button*/}
                    <a
                      onClick={addAdvanceRouting}
                      className="btn btn-primary w-100"
                      id="addRouteBtn"
                    >
                      <span className="indicator-label"> Add Routing</span>
                    </a>
                    {/*end::Button*/}
                  </div>
                  {/*end::Modal footer*/}
                </form>
                {/*end::Form*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Messenger*/}
          </div>

          <div
            id="acl-modal"
            className="bg-body shadow drawer drawer-end"
            data-kt-drawer="true"
            data-kt-drawer-name="chat"
            data-kt-drawer-activate="true"
            data-kt-drawer-direction="end"
            data-kt-drawer-close="#close_ssh"
            data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
            style={{ maxWidth: "70%", important: true }}
          >
            {/*begin::Messenger*/}
            <div
              className="card rounded-0 w-100 border-0"
              id="kt_drawer_chat_messenger"
            >
              {/*begin::Card header*/}
              <div
                className="card-header pe-5"
                id="kt_drawer_chat_messenger_header"
              >
                {/*begin::Title*/}
                <div className="card-title">
                  {/*begin::User*/}
                  <div className="d-flex justify-content-center flex-column me-3">
                    <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                      Add ACL Rule
                    </a>
                  </div>
                  {/*end::User*/}
                </div>
                {/*end::Title*/}
                {/*begin::Card toolbar*/}
                <div className="card-toolbar">
                  {/*begin::Close*/}
                  <div
                    onClick={openDrawerAcl}
                    className="btn btn-sm btn-icon btn-active-light-primary"
                    id="close_ssh"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x={6}
                          y="17.3137"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-45 6 17.3137)"
                          fill="black"
                        />
                        <rect
                          x="7.41422"
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(45 7.41422 6)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Close*/}
                </div>
                {/*end::Card toolbar*/}
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="" id="">
                {/*begin::Form*/}
                <form  id="addAclForm" className="form">
                  {/*begin::Modal body*/}
                  <div className="modal-body ">
                    {/*begin::Scroll*/}
                    <div
                      className="scroll-y me-n7 pe-7"
                      id="kt_modal_create_api_key_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                      data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      {/*begin::Input group*/}
                      <div className="mb-5 fv-row">
                        {/*begin::Label*/}
                        <label className="required fs-5 fw-bold mb-2">
                          {" "}
                          Name
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          type="text"
                          className="form-control  "
                          placeholder="ACL Name"
                          name="name"
                        />
                        {/*end::Input*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className="mb-5 fv-row">
                        {/*begin::Label*/}
                        <label className="required fs-5 fw-bold mb-2">
                          {" "}
                          Condition
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <select className="form-select" name="conditionType">
                          <option value="http_user_agent">Http User Agent</option>
                          <option value="http_referer">Http Referer</option>
                          <option value="url_path">Url Path</option>
                          <option value="http_method">Http Method</option>
                          <option value="ssl_cipher_suite">SSL Cipher Suite</option>
                          <option value="ssl_certificate">SSL Certificate</option>
                          <option value="network_protocol">Network Protocol</option>
                          <option value="ip_geolocation">IP Geolocation</option>
                          <option value="authenticated_users">Authenticated Users</option>
                          <option value="custom_header">Custom Header</option>  
                        </select> 
                        {/*end::Input*/}
                      </div>
                      {/*end::Input group*/}
                       {/*begin::Input group*/}
                       <div className="mb-5 fv-row">
                        {/*begin::Label*/}
                        <label className="required fs-5 fw-bold mb-2">
                          {" "}
                          Value
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          type="text"
                          className="form-control  "
                          placeholder="Value"
                          name="conditionValue"
                        />
                        {/*end::Input*/}
                      </div>
                      {/*end::Input group*/}
                    </div>
                    {/*end::Scroll*/}
                  </div>
                  {/*end::Modal body*/}
                  {/*begin::Modal footer*/}
                  <div className="modal-footer ">
                    {/*begin::Button*/}
                    <a 
                      onClick={addAclRule}
                      className="btn btn-primary w-100"
                      id="AddAclBtn"
                    >
                      <span className="indicator-label">Add ACL Rule</span>
                    </a>
                    {/*end::Button*/}
                  </div>
                  {/*end::Modal footer*/}
                </form>
                {/*end::Form*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Messenger*/}
          </div>
        </>
      ) : (
        <><div class="loading-spinner2"></div></>
      )}
    </>
  );
}
