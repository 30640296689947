import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import UsePost from "../../../../../../CustomHooks/usePost";
export default function ApplyCoupon() {
  const applyCoupon = async () => {
    toast.dismiss();
    var couponBtn = document.querySelector("#addcoupon-btn");
    var btnHtml = couponBtn.innerHTML;
    if (couponBtn) {
      couponBtn.innerHTML =
        'Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span>';
      couponBtn.classList.add("disabled");
    }
    swal({
      title: "Are you sure?",
      text: "Do you really want to apply coupon in your account!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const dt = toast.loading("Coupon applying...");
        const dnsp = await UsePost("post", "account/applycoupon", "addcoupon");
        if (dnsp) {
          if (couponBtn) {
            couponBtn.innerHTML = btnHtml;
            couponBtn.classList.remove("disabled");
          }
          if (dnsp.status == "error") {
            toast.update(dt, {
              render: dnsp.message,
              type: "warning",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.update(dt, {
              render: dnsp.message,
              type: "success",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(()=>{
            const location_url = window.location.origin;
            window.location.href = location_url;
          },2000)
          }
        }
      } else {
        if (couponBtn) {
          couponBtn.innerHTML = btnHtml;
          couponBtn.classList.remove("disabled");
        }
      }
    });
  };

  return (
    <>
      {/*begin::Row*/}
      <div className="row g-0 mb-10">
        {/*begin::Col*/}
        <div className="col  px-6 py-8 rounded-2 me-7 btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start">
          {/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
          <i className="bi bi-ticket-perforated fs-2x" />
          {/*end::Svg Icon*/}
          <div className="card-body">
            <form name="addcoupon" id="addcoupon">
              <a className="card-title fw-bold text-muted text-hover-primary fs-4">
                Add Credits with credit coupon
              </a>
              <div className="fw-bold text-primary my-6">
                <label htmlFor="size" className="form-label">
                  You can fund with credit coupon you received from us!
                </label>
              </div>
              <div className=" align-items-center">
                {/*begin::Input group*/}
                <div className="symbol symbol-45px me-2 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="coupon"
                    id="coupon"
                    name="coupon"
                  />
                </div>
                {/*end::Input group*/}
                {/*begin:Action*/}
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-primary me-5 sphide"
                    id="addcoupon-btn"
                    onClick={applyCoupon}
                  >
                    Apply Coupon
                  </button>
                </div>
                {/*end:Action*/}
              </div>
            </form>
          </div>
        </div>
        {/*end::Col*/}
      </div>
      {/*end::Row*/}
    </>
  );
}
