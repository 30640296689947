import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { IsCloudUpdate } from "../../../../Redux/Services/Action/actions";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import Swal from 'sweetalert2';
import copy from "copy-to-clipboard";
import UsePost from "../../../../CustomHooks/usePost";
import Backup from "./cloudFeatures/Backup";
import Billing from "./cloudFeatures/Billing";
import Firewall from "./cloudFeatures/Firewall";
import Iso from "./cloudFeatures/Iso";
import Network from "./cloudFeatures/Network";
import Overview from "./cloudFeatures/Overview";
import Power from "./cloudFeatures/PowerTab/Power";
import Rebuild from "./cloudFeatures/Rebuild";
import Resize from "./cloudFeatures/Resize";
import Snapshot from "./cloudFeatures/Snapshot";
import Storage from "./cloudFeatures/Storage";
import PowerOn from "./cloudFeatures/PowerTab/PowerOn";
import "./custom.css";
import AlertTab from "./cloudFeatures/AlertTab2";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
const ManageCloud=(props)=>{
  const navigate=useNavigate();
  const dispatch=useDispatch();
  const [processCount, setProcessCount]=useState(0);
  var query = window.location.href;
  var vars = query.split("/");
  var id=vars[4];
  useEffect(()=>{
    if(vars.length==6){
      var tabSelect = document.querySelectorAll([".listTab"]);
    if (tabSelect) {
      tabSelect.forEach((clist) => {
        clist.classList.remove("active");
      });
    }
    var activeTab=document.querySelector("."+vars[5]+"Tab");
    if(activeTab){
      activeTab.click();
    }

    setTimeout(()=>{
      navigate("/cloud/"+id);
    },1000)
    }
  })
  const [cloudData,setCloudData]=useState(null);
  const [iscloudData,setIsCloudData]=useState(null);
  const [currTab, setCurrTab]=useState("overview");
  const dataFetchedRef=useRef (false);
  const dataFetchedRef2=useRef (false);
  const cData=useRef({});
  const navRedirect=()=>{
    setTimeout(() => {
      navigate("/cloud/"+id);
     }, 3000);
  }




  const getCloud=async()=>{
       const data=await UseApi('get','cloud/'+id,'');
       //console.log(data);
       if(data.cloud){
       if(Object.keys(data.cloud).length <= 0){
        swal({
            title: "Cloud Server",
            text: "No Cloud server found with id: "+id+"!",
            icon: "warning",
            button: "OK",
          }).then(()=>{
            navigate("/cloud");
          })
          return;
        // const dt=toast.loading("Cloud servers loading...");
        // //const dt=toast.loading("Cloud servers loading...");
        // setCloudData(null);
        //      toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        //     });
       }else{
        //const dt=toast.loading("Cloud servers loading...");
       // ////console.log(dt);
          //   toast.update(dt,{render: "Your cloud server loaded", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          // });
          dispatch(props.updateCloudStatus(data.cloud[0]));

       }
      }
  }

  useEffect(()=>{
     if(dataFetchedRef.current) return;
       dataFetchedRef.current=true;
    getCloud();
  },[]);




  useEffect(() => {
    const intervalId = setInterval(() => {
      if (props.cloudInfo.cloudid === id) {
        if (props.cloudInfo.stack) {
          if (props.cloudInfo.stack.status !== "Pending") {
            var installing_stack = document.querySelector("#installing_stack");
            if (installing_stack) {
              installing_stack.classList.add("d-none");
            }
          }else{
            getCloud();
          }
        }
      }
    }, 20000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [props]);

  useEffect(() => {

  }, [props]);

  // setInterval(getCloud, 60000);
  useEffect(()=>{
      if(props.cloudInfo.length == 0){
      }else{
        if(props.cloudInfo.cloudid==id){
          setCloudData(props.cloudInfo);
           if(props.cloudInfo.stack){
          if(props.cloudInfo.stack.status!="Pending"){
            var installing_stack=document.querySelector("#installing_stack");
          if(installing_stack){
            installing_stack.classList.add("d-none");
          }
          }
        }
        }
      }

  },[props]);

  const processBarRef = useRef(null);
  const process_bar_parentRef = useRef(null);
  const serverAlertRef = useRef(null);

  const destroyCloud=async(cloudName)=>{
   var confirmString=document.querySelector("#confirm");
   if(confirmString){
    if(confirmString.value==""){
      const dt=toast.loading("Cloud server deleting...");
      toast.update(dt,{render: "Please provide the confirm string", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    return;
    }
   }
    const { value: isDelete } = await Swal.fire({
      title: 'Delete Cloud Instance',
      text: 'Enter the Cloud Instance name to delete:',
      input: 'text',
      inputPlaceholder: 'Instance name',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      customClass: {
          confirmButton: 'delete-button bg-danger', // Add your custom class here
        },
    });
   if(isDelete==cloudName){
    var confirm_name=document.querySelector("#confirm_name");
    if(confirm_name){
      confirm_name.value=isDelete;
    }
        toast.dismiss();
        const dt=toast.loading("Cloud server deleting...");
    const dnsp=await UseApi('DELETE','cloud/'+id+'/destroy', 'destroyform');
    ////console.log(dnsp);
    if(dnsp.status=='error'){
      toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else if(dnsp.status=='success'){
      toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    navigate('/cloud');
    }
      }else {
        swal({
            title: "Invalid Instance Name",
            text: "Please confirm the valid Instance name or check the extra white space in the given instance name!",
            icon: "warning",
            button: "OK",
          });
      }
  }

  const getActions=async()=>{
      const actionData=await UseApi("get","actions","action-form");
      if(actionData && actionData.actions){
        //console.log(actionData);
      var dataInfo=null;
      var barStatus=false;
      actionData.actions.map((value,index)=>{
        // //console.log(value);
          if(value.resource_id==id){
            dataInfo=value;
            barStatus=true;
          }
        });

        if(barStatus==false){
          if(processBarRef.current && process_bar_parentRef.current && serverAlertRef.current){
            process_bar_parentRef.current.classList.add("d-none");
            processBarRef.current.classList.add("d-none");
            serverAlertRef.current.classList.add("d-none");
            if(props.cloudInfo.stack){
              // console.log("bar status false");
              var installing_stack=document.querySelector("#installing_stack");
              if(installing_stack){
              if(props.cloudInfo.stack.status!="Pending"){
                installing_stack.classList.add("d-none");
              }else{
                  installing_stack.classList.remove("d-none");
                }
              }
            }

            }
            return;
        }
        if(barStatus==false && dataInfo!=null){
            if(processBarRef.current && process_bar_parentRef.current && serverAlertRef.current){
              setProcessCount(100);
              process_bar_parentRef.current.classList.add("d-none");
              processBarRef.current.style.width=dataInfo.process+"%";
              processBarRef.current.classList.add("d-none");
              serverAlertRef.current.classList.add("d-none");
              if(props.cloudInfo.stack){
                // console.log("bar status");
                var installing_stack=document.querySelector("#installing_stack");
                if(installing_stack){
                if(props.cloudInfo.stack.status!="Pending"){
                  installing_stack.classList.add("d-none");
                }else{
                    installing_stack.classList.remove("d-none");
                  }
                }
              }
              }
              return;
          }
        if(dataInfo!=null){
    if(dataInfo.id!=null){

      if(dataInfo.status=="Pending"){
        toast.dismiss();
        if(processBarRef.current && process_bar_parentRef.current){
          setProcessCount(dataInfo.process);
          process_bar_parentRef.current.classList.remove("d-none");
          processBarRef.current.style.width=dataInfo.process+"%";
          processBarRef.current.classList.remove("d-none");
          }
          setTimeout(()=>{
           getActions();
          },2000);
      }else if(dataInfo.status=="Support"){
      // const processBar=document.querySelector("#process_bar");
      // const process_bar_parent=document.querySelector("#process_bar_parent");
      // const server_status=document.querySelector("#server-status");
      //console.log("outer");
      if(processBarRef.current && process_bar_parentRef.current && serverAlertRef.current){
        //console.log("inner");
        setProcessCount(dataInfo.process);
        process_bar_parentRef.current.classList.remove("d-none");
        processBarRef.current.style.width=dataInfo.process+"%";
        processBarRef.current.classList.remove("d-none");
        serverAlertRef.current.classList.remove("d-none");
        }
        setTimeout(()=>{
         getActions();
        },2000);
      }else if(dataInfo.status=="Complete"){
        if(processBarRef.current && process_bar_parentRef.current && serverAlertRef.current){
          setProcessCount(dataInfo.process);
          process_bar_parentRef.current.classList.add("d-none");
          processBarRef.current.style.width=dataInfo.process+"%";
          processBarRef.current.classList.add("d-none");
          serverAlertRef.current.classList.add("d-none");
          if(props.cloudInfo.stack){
            console.log("complete");
            var installing_stack=document.querySelector("#installing_stack");
            if(installing_stack){
            if(props.cloudInfo.stack.status!="Pending"){
              installing_stack.classList.add("d-none");
            }else{
                installing_stack.classList.remove("d-none");
              }
            }
          }
          }
    }

    }else{
      ////console.log(actionData);
    }
    return;
  }

}
  }

  useEffect(()=>{
    toast.dismiss();
    getActions();
  },[props]);


  const copyToClipboard=(id)=> {

    var copyText = document.querySelector("#"+id).innerHTML;
    copy(copyText);
    document.querySelector("#"+id).innerHTML="Copied";
    setTimeout(() => {
      document.querySelector("#"+id).innerHTML=copyText;
    }, 2000);

  }

const ActionHostname=async(action)=>{
  var showHost=document.querySelector("#showHost");
  var updateHost=document.querySelector("#updateHost");
  if(showHost){
    if(updateHost){
if(action=="showHost"){
         showHost.classList.add("d-none");
         updateHost.classList.remove("d-none");
}
if(action=="cancelHost"){
  showHost.classList.remove("d-none");
  updateHost.classList.add("d-none");
}
if(action=="updateHost"){

    toast.dismiss();
   const dt=toast.loading("updating Host name, Please wait...");
    const responseData=await UsePost('post','cloud/'+id+'/name','updateHostname');
      //////console.log(responseData);
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      showHost.classList.remove("d-none");
      updateHost.classList.add("d-none");
      const data=await UseApi('get','cloud/'+id,'');
       dispatch(props.updateCloudStatus(data.cloud[0]));
       navRedirect();
      }
    }}
}
}

  return(
        <>
        {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
        {cloudData!=null?(<>
        <div className="p-2">
        <div className="card mb-6 mb-xl-9 ">
  <div className="card-body pt-9 pb-0 ">
    {/*begin::Details*/}
    <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
      {/*begin::Image*/}
      <div
        className="d-flex flex-center flex-shrink-0 bg-light rounded w-55px h-55px w-lg-55px h-lg-55px me-7 mb-4"
        id="imageDist"
      >
        {cloudData.stack?(<>
          <div className="symbol symbol-45px me-2">
          <img src={'/'+cloudData.stack.logo_url} className="" alt=""  />
          </div>
        </>):(<>
          <span className={"fonticon_"+(cloudData.image.distribution).replace(/\s/g, '')+""} />
        </>)
        }

      </div>
      {/*end::Image*/}
      {/*begin::Wrapper*/}
      <div className="flex-grow-1">
        {/*begin::Head*/}
        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
          {/*begin::Details*/}
          <div className="d-flex flex-column">
            {/*begin::Status*/}
            <div className="d-flex align-items-center mb-1 d-none" id="updateHost">
            <form id="updateHostname" name="updateHostname">
               {/*begin::Title*/}
  <div className="d-flex">

    <input
      type="text"
      className="form-control form-control-sm  me-3 flex-grow-1"
      name="name"
      defaultValue= {cloudData.hostname}
    />
    <input
    type={"button"}
      onClick={()=>ActionHostname('updateHost')}
      className="btn btn-primary btn-sm fw-bolder flex-shrink-0 me-2"
      value={"save"}
      />
    <input
    type={"button"}
      onClick={()=>ActionHostname('cancelHost')}
      className="btn btn-danger btn-sm fw-bolder flex-shrink-0"
      value={"cancel"}
      />

  </div>
  </form>
  {/*end::Title*/}
            </div>
            <div className="d-flex align-items-center mb-1" id="showHost">
              <a onClick={()=>ActionHostname('showHost')}
              style={{cursor: "pointer"}}
                className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3"
                id="hostname"

              >
                {cloudData.hostname}
              </a>
              {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen055.svg*/}

  {/*end::Svg Icon*/}
            </div>
            {/*end::Status*/}
            {/*begin::Description*/}
            <div
              className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400"

            >
             {cloudData.dedicated_cpu && cloudData.dedicated_cpu=="1"?(<>Dedicated - </>):('')} {cloudData.cpu} vCPU / {(cloudData.ram/1024).toFixed(0)} GB Memory / {cloudData.disksize} GB SSD Disk / {cloudData.image?(cloudData.image.name):('')}
            </div>
            {/*end::Description*/}
          </div>
          {/*end::Details*/}
          {/* <span id="action_process_cloud_84469"></span> */}
          {/*begin::Actions*/}
          <div className="" id="console">
            {/*begin::Input*/}

            <div className=" zoom me-5 btn btn-sm btn-light-primary" onClick={()=>copyToClipboard('ip_copy')}>
              IP Address:{" "}
              <span id="ip_copy"  className="fw-bold">
              {cloudData.ip}
              </span>
            </div>
            {/*end::Input*/}

            <a
            href={"/cloud/console/"+id}
             target="_blank"
             className="btn btn-sm btn-primary me-3"
            >
              Console
            </a>
          </div>
          {/*end::Actions*/}
        </div>
        {/*end::Head*/}
      </div>
      {/*end::Wrapper*/}
    </div>
    <div className="separator" />
    {/*begin::Nav*/}
    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-trakt_charts_widget_1_chartnsparent fs-5 fw-bolder">
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          onClick={(e)=>setCurrTab('overview')}
          className="nav-link text-active-primary listTab overviewTab  py-5 me-6 active"
          data-bs-toggle="tab"
          href="#Analysis"
        >
          Overview
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          onClick={(e)=>setCurrTab('access')}
          className="nav-link text-active-primary listTab accessTab py-5 me-6 d-none"
          data-bs-toggle="tab"
          href="#access"

        >
          Access
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          onClick={(e)=>setCurrTab('powerTab')}
          className="nav-link text-active-primary listTab powerTab py-5 me-6"
          data-bs-toggle="tab"
          href="#powerTab"
        >
          Power
        </a>
      </li>
      {/*end::Nav item*/}
       {/*begin::Nav item*/}
       <li className="nav-item d-none">
        <a
          onClick={(e)=>setCurrTab('alertTab')}
          className="nav-link text-active-primary listTab alertTab py-5 me-6 sphide"
          data-bs-toggle="tab"
          href="#alertTab"
        >
          Alert
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
         onClick={(e)=>setCurrTab('network')}
          className="nav-link text-active-primary listTab networkTab py-5 me-6"
          data-bs-toggle="tab"
          href="#network"
        >
          Network
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          onClick={(e)=>setCurrTab('storage')}
          className="nav-link text-active-primary listTab storageTab py-5 me-6"
          data-bs-toggle="tab"
          href="#storage"
        >
          Storage
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          onClick={(e)=>setCurrTab('resize')}
          className="nav-link text-active-primary listTab resizeTab py-5 me-6"
          onclick="resizeDiskRam(1)"
          data-bs-toggle="tab"
          href="#resize"
        >
          Resize
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
         onClick={(e)=>setCurrTab('rebuild')}
          className="nav-link text-active-primary listTab rebuildTab py-5 me-6"
          data-bs-toggle="tab"
          href="#rebuild"
        >
          Rebuild
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          onClick={(e)=>setCurrTab('firewall')}
          className="nav-link text-active-primary listTab firewallTab py-5 me-6"
          data-bs-toggle="tab"
          href="#firewall"
        >
          Firewall
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          onClick={(e)=>setCurrTab('iso')}
          className="nav-link text-active-primary listTab isoTab py-5 me-6"
          data-bs-toggle="tab"
          href="#iso"
        >
          ISO
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
         onClick={(e)=>setCurrTab('billing')}
          className="nav-link text-active-primary listTab billingTab py-5 me-6"
          data-bs-toggle="tab"
          href="#billing"
        >
          Billing
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          onClick={(e)=>setCurrTab('backup')}
          className="nav-link text-active-primary listTab backupTab py-5 me-6"
          data-bs-toggle="tab"
          href="#backup"
        >
          Backup
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          onClick={(e)=>setCurrTab('snapshot')}
          className="nav-link text-active-primary listTab snapshotTab py-5 me-6"
          data-bs-toggle="tab"
          href="#snapshot"
        >
          Snapshot
        </a>
      </li>
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      <li className="nav-item">
        <a
          className="nav-link text-active-primary listTab destroyTab py-5 me-6"
          data-bs-toggle="tab"
          href="#destroy"
        >
          Destroy
        </a>
      </li>
      {/*end::Nav item*/}
    </ul>
    {/*end::Nav*/}
  </div>
</div>
<div className="text-center d-none" id="installing_stack">
 <span className="fw-bold text-center text-gray-600">Installing Stack, Please Wait...</span>
 <div className="loadingStack">
 </div>
 </div>
<div className="progress d-none mb-5" id="process_bar_parent" ref={process_bar_parentRef}>
<div
  className="progress-bar progress-bar-striped progress-bar-animated bg-primary d-none"
  ref={processBarRef}
  role="progressbar"
  id="process_bar"
  style={{ width: "0%" }}
  aria-valuenow={0}
  aria-valuemin={0}
  aria-valuemax={100}
>
{processCount}%
  </div>
        </div>
        <div className="alert alert-danger d-none" id="server_alert" ref={serverAlertRef}>
    <div className="d-flex flex-column">
        <span>Uh Oh! That was unexpected! An error has occured and we're working to fix the problem!</span>
    </div>
</div>
<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="Analysis" role="tabpanel">
    {/*begin::overview*/}
     {currTab=="overview"?(<> <Overview /></>):('')}

    {/*end::overview*/}
  </div>
  {/*end::Post*/}
  <div className="tab-pane fade" id="powerTab" role="tabpanel">
    {/*begin::Power*/}

     {/* {cloudData!=null && cloudData.powerstatus=='Running'?(
      <></>
    ):(
      <></>
    )}  */}
     {currTab=="powerTab"?(<> <Power /></>):('')}
    {/*end::Power*/}
  </div>
  <div className="tab-pane fade" id="alertTab" role="tabpanel">
    {/*begin::network*/}
    {/* {cloudData!=null?(<Network />):('')}  */}
    {currTab=="alertTab"?(<> <AlertTab /></>):('')}

    {/*end:: network*/}
  </div>
  <div className="tab-pane fade" id="network" role="tabpanel">
    {/*begin::network*/}
    {/* {cloudData!=null?(<Network />):('')}  */}
    {currTab=="network"?(<> <Network /></>):('')}

    {/*end:: network*/}
  </div>
  <div className="tab-pane fade" id="storage" role="tabpanel">
    {/*begin:: storage*/}
    {currTab=="storage"?(<> <Storage /></>):('')}

    {/*end:: storage*/}
  </div>
  <div className="tab-pane fade" id="rebuild" role="tabpanel">
    {/*begin:: rebuild*/}
    {currTab=="rebuild"?(<> <Rebuild /></>):('')}

    {/*end:: rebuild*/}
  </div>
  <div className="tab-pane fade" id="resize" role="tabpanel">
    {/*begin:: resize*/}
    {currTab=="resize"?(<> <Resize /></>):('')}

    {/*end:: resize*/}
  </div>
  <div className="tab-pane fade" id="firewall" role="tabpanel">
    {/*begin:: firewall*/}
    {currTab=="firewall"?(<> <Firewall /></>):('')}

    {/*end:: firewall*/}
  </div>
  <div className="tab-pane fade" id="iso" role="tabpanel">
    {/*begin:: iso*/}
    {currTab=="iso"?(<> <Iso /></>):('')}

    {/*end:: iso*/}
  </div>
  <div className="tab-pane fade" id="billing" role="tabpanel">
    {/*begin:: billing*/}
    {currTab=="billing"?(<> <Billing /></>):('')}

    {/*end:: billing*/}
  </div>
  <div className="tab-pane fade" id="backup" role="tabpanel">
    {/*begin:: backup*/}
    {currTab=="backup"?(<> <Backup /></>):('')}

    {/*end:: backup*/}
  </div>
  <div className="tab-pane fade" id="snapshot" role="tabpanel">
    {/*begin:: snapshot*/}
    {currTab=="snapshot"?(<> <Snapshot /></>):('')}

    {/*end:: snapshot*/}
  </div>
  <div className="tab-pane fade" id="destroy" role="tabpanel">
    {/*begin::Deactivate Account*/}
    <div className="card">
      {/*begin::Card header*/}
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_deactivate"
        aria-expanded="true"
        aria-controls="kt_account_deactivate"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Destroy Cloud Server</h3>
        </div>
      </div>
      {/*end::Card header*/}
      {/*begin::Content*/}
      <div id="kt_account_settings_deactivate" className="collapse show">
        {/*begin::Form*/}
        {/* <form id="destroyform" className="form"> */}
        {/*begin::Card body*/}
        <div className="card-body border-top p-9">
          {/*begin::Notice*/}
          <div className="notice d-flex bg-light-primary rounded border-primary border mb-9 p-6">
            {/*begin::Icon*/}
            {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.3"
                  x={2}
                  y={2}
                  width={20}
                  height={20}
                  rx={10}
                  fill="black"
                />
                <rect
                  x={11}
                  y={14}
                  width={7}
                  height={2}
                  rx={1}
                  transform="rotate(-90 11 14)"
                  fill="black"
                />
                <rect
                  x={11}
                  y={17}
                  width={2}
                  height={2}
                  rx={1}
                  transform="rotate(-90 11 17)"
                  fill="black"
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            {/*end::Icon*/}
            {/*begin::Wrapper*/}
            <div className="d-flex flex-stack flex-grow-1">
              {/*begin::Content*/}
              <div className="fw-bold">
                <h4 className="text-gray-900 fw-bolder">
                  This will destroy your cloud server.
                </h4>
                <div className="fs-6 text-gray-700">
                  Note: This option will delete your complete data from the
                  server for permanently and this not be able to undo.
                </div>
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Notice*/}
          {/*begin::Form input row*/}
          <form id="destroyform" className="form">
            <div className="mb-10 mt-20">
              <label htmlFor="size" className="required form-label">
                Please fill text in below form to delete server "I am aware this
                action will delete data and server permanently"
              </label>
              <input
                type="text"
                className="form-control  "
                name="confirm"
                id="confirm"
                defaultValue=""
                placeholder="I am aware this action will delete data and server permanently"
              />
              <input
                type="hidden"
                className="form-control  "
                name="confirm_name"
                id="confirm_name"
                defaultValue=""
              />
            </div>
            {/*end::Form input row*/}
          </form>
        </div>
        {/*end::Card body*/}
        {/*begin::Card footer*/}
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button
            onClick={(e)=>destroyCloud(cloudData.hostname)}
            className="btn btn-danger fw-bold"
          >
            Destroy Server
          </button>
        </div>
        {/*end::Card footer*/}
        {/*end::Form*/}
      </div>
      {/*end::Content*/}
    </div>
    {/*end::Deactivate Account*/}
  </div>
</div>
</div>
</>):(<><TopBarHeaderLine/></>)}
        </>
    )
}

const mapStateToProps=state=>({
  cloudInfo:state.cloud.cloudDataInfo
})

const mapDispatchToProps=dispatch=>({
  updateCloudStatus:data=>dispatch(IsCloudUpdate(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(ManageCloud)
