import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom/dist"; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";    
import UseDevPost from "../../../../../../CustomHooks/useDevPost";
import UseDevApi from "../../../../../../CustomHooks/useDevApi";
import UseApi from "../../../../../../CustomHooks/useApi";
import UsePost from "../../../../../../CustomHooks/usePost";
const Permissions = (props) => {

  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[5]; 

  const loaderFunc=()=>{
    var loader = document.querySelector("#key_loader");
    var no_record = document.querySelector("#key_no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }
 
  
  const openDrawer=()=>{
    var drawerElement = document.querySelector("#key-modal");
        drawerElement.classList.toggle("drawer-on");
        drawerElement.style.width='500px';
  }
  
  
  const closeDrawer=()=>{ 
    var drawerElement = document.querySelector("#key-modal");
        drawerElement.classList.remove("drawer-on");
        drawerElement.style.width='500px';
  }

 

  const updateUserPermissions=async()=>{ 
    var submitBtn=document.querySelector("#key-btn"); 
    var selectedKey=document.querySelector("#selected_key"); 
    var selectedPermission=document.querySelector("#selected_permission"); 
   
    if(submitBtn && selectedKey && selectedPermission){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
    const dt=toast.loading("Please wait...");  
    
    var skey=selectedKey.value;
    var spermission=selectedPermission.value
    const responseData=await UsePost('post','objectstorage/innoida/bucket/'+id+'/permission/'+spermission+'/accesskey/'+skey,'permission_add');
    if(responseData){   
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      openDrawer();
      if(props){
      props.updateObject();
      } 
    }
  } 
      }
    }
    
     const [ObjectKeysInfo, setObjectKeysInfo]= useState(null);
    const [keyData, setKeyData]= useState(null);
    const getAllkeys=async()=>{
     
         const datas=await UseApi('get','objectstorage/innoida/accesskeys','');
        //  console.log(datas);
         if(datas.status=="error"){   
          setKeyData([]);
         }else{ 
          setKeyData(datas.accesskeys);  
          toast.dismiss(); 
         } 
    }
    
    const getObjectKeys=async()=>{    
      if(props){
        if(props.data && props.data.permissions){
          
          if(props.data.permissions.length<=0){ 
            setObjectKeysInfo([]); 
            loaderFunc();
          }else{
             setObjectKeysInfo(props.data.permissions);
             loaderFunc();
              toast.dismiss();  
          }
         
        }
      }
         
    }

const updateKey=(keyname, permission)=>{
  const selected_key = document.querySelector("#selected_key");
  const selected_permission = document.querySelector("#selected_permission");
  
  if(selected_key && selected_permission){
    selected_key.value=keyname;
    selected_permission.value=permission;
    openDrawer();
  }
}

    useEffect(()=>{ 
      closeDrawer();
      getObjectKeys();
      getAllkeys(); 
      
    },[props])

    useEffect(()=>{
       setTimeout(()=>{
         var AccessKeyName=document.querySelectorAll(".AccessKeyName"); 
    if (AccessKeyName) {
      AccessKeyName.forEach((clist) => {
        clist.classList.remove("d-none");
      });
    }
       },100)
    },[])
if(props && props.data){
    return (
        <>
          
          <div className="card">
            <div className="card-header mt-5">
              <h2>Manage Permissions</h2>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <div className="w-100" id="own-certificate">
                      {/*begin::Heading*/}
                      <div className="pb-10 pb-lg-12">
                      <div className="notice d-flex bg-light-primary rounded border-primary border p-6">
            {" "}
            {/*begin::Icon*/}{" "}
            {/*begin::Svg Icon | path: icons/duotune/finance/fin001.svg*/}{" "}
            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                {" "}
                <path
                  d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z"
                  fill="black"
                />{" "}
                <path
                  opacity="0.3"
                  d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z"
                  fill="black"
                />{" "}
              </svg>{" "}
            </span>{" "}
            {/*end::Svg Icon*/} {/*end::Icon*/}
            
             
             {/*begin::Wrapper*/}{" "}
            <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
              {" "}
              {/*begin::Content*/}{" "}
              <div className="mb-3 mb-md-0 fw-bold">
                {" "}
                <h4 className="text-gray-900 fw-bolder">Permissions</h4>{" "}
                <div className="fs-6 text-gray-700 pe-7">
                Effortlessly control and secure access to your data with our powerful platform. Simplify permissions management, ensure authorized user access, and optimize collaboration for your object storage.                </div>{" "}
              </div>{" "}
              {/*end::Content*/} {/*begin::Action*/}{" "}
              <a   
              onClick={openDrawer} 
                 className="btn btn-primary px-6 align-self-center text-nowrap"
              >
                Update Permissions
              </a>{" "}
              {/*end::Action*/}{" "}
            </div>{" "}
            {/*end::Wrapper*/}{" "}

            
          </div>
                      </div>
                      {/*end::Heading*/} 

                          {/*begin::Table container*/}
    <div className="table-responsive">
      {/*begin::Table*/}
      <table 
        className="table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className="fw-bold ">  
            <th className="w-50px"></th>
            <th className="w-150px">Name</th>
            <th className="w-150px">Access key</th> 
            <th className="w-150px">Permission</th>
            <th className="w-150px">CreatedAt</th> 
            <th className="w-150px"></th>   
          </tr>
        </thead>
        {/*end::Table head*/}
        {/*begin::Table body*/}
        <tbody> 
        {ObjectKeysInfo!=null && Object.keys(ObjectKeysInfo).length!=0?(ObjectKeysInfo && ObjectKeysInfo.map((value, index) => (
          <tr>  
            <td className="AccessKeyName"  ></td>
            <td className="">
              {/*begin::User details*/}
              <div className="d-flex flex-column"> 
                <a   className="text-gray-800 fw-bolder text-hover-primary mb-1"   >
                  {value.name?(value.name):('')}
                </a> 
              </div>
              {/*begin::User details*/}
            </td> 
            <td className="text-left">
              {/*begin::User details*/}
              <div className="d-flex flex-column">
                <span className="text-muted fw-bold">{value.accesskey}</span> 
              </div>
              {/*begin::User details*/}
            </td> 
            <td> 
              {value.permission=="full"?(<>
                <div className="badge badge-success me-3">Read</div>
                <div className="badge badge-success">Write</div>
              </>):(<>
                <div className="badge badge-success">{value.permission}</div>
              </>)} 
            </td>   
            <td className="text-end">
              
              <div className="d-flex flex-column w-100 me-2">
                
                <div className="d-flex flex-stack mb-2">
                  
                  <span className="text-muted me-2 fs-7 fw-bold">
                    {value.created_at}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-center">
              
              <div className="card-toolbar">
                
                <a
                  style={{cursor:"pointer"}}
                  className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                  onClick={(e)=>updateKey(value.accesskey, value.permission)}
                   > 
                  <span className="svg-icon svg-icon-2">
                    
                  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    opacity="0.3"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
    fill="black"
  />
  <path
    d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
    fill="black"
  />
  <path
    d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
    fill="black"
  />
</svg>

                  </span>
                </a> 
               
              </div>
            </td>
          </tr> 
          ))):( <>
           <tr>
                     <td colspan="12" className="dataTables_empty" id="key_loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="key_no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
            </>)}
     

</tbody>
        {/*end::Table body*/}
      </table>
      {/*end::Table*/}
    </div>
    {/*end::Table container*/}


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
  id="key-modal"
  className="bg-body shadow drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-name="chat"
  data-kt-drawer-activate="true" 
  data-kt-drawer-direction="end" 
  data-kt-drawer-close="#close_ssh"
  data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}" 
  style={{maxWidth: '70%',important:true}}
  
>
  {/*begin::Messenger*/}
  <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
    {/*begin::Card header*/}
    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div className="d-flex justify-content-center flex-column me-3">
          <a
            
            className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
          >
            Update Permission
          </a>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Close*/}
        <div
        onClick={openDrawer}
          className="btn btn-sm btn-icon btn-active-light-primary"
          id="close_ssh"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {/*end::Card header*/}
    {/*begin::Card body*/}
    <div className="" id="">
      {/*begin::Form*/}
      <form id="permission_add"  className="form">
        {/*begin::Modal body*/}
        <div className="modal-body ">
          {/*begin::Scroll*/}
          <div
            className="scroll-y me-n7 pe-7"
            id="kt_modal_create_api_key_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
            data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
            data-kt-scroll-offset="300px"
          >
            {/*begin::Input group*/}
            <div className="mb-5 fv-row"> 
              {/*begin::Input*/} 
              {keyData!=null?(<>
              {keyData.length==0?(<>
              <p>You don't have Access Key in your account, Please add access key in your account
              </p></>):(<>
              <div className="mb-10">
              <label className="form-label">Select access key</label>
                <select
                  className="form-select" 
                  name="selected_key" 
                  id="selected_key" 
                > <option value={0}>Select Access key</option>
                  {keyData!=null?(keyData && keyData.map((value,index)=>(
                  <option value={value.accesskey  }>{value.name}</option>
                  ))):('')} 
                </select> 
                </div>
                <div className="mb-10">
                <label className="form-label">Select permissions</label>
                <select
                  className="form-select" 
                  name="selected_permission" 
                  id="selected_permission" 
                > <option value="">Select permissions</option>
                  <option value="read">Read</option>
                  <option value="write">Write</option>
                  <option value="full">Read/Write</option>
                  <option value="none">None</option>
                </select> 
                </div>
              </>)}
              </>):('')}
                
              {/*end::Input*/}
            </div>
            {/*end::Input group*/} 
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Modal body*/}
        {/*begin::Modal footer*/}
        <div className="modal-footer ">
          {/*begin::Button*/}
          {keyData!=null?(<>
              {keyData.length==0?(<>
              <Link to="/accessKeys">
                <a  
            className="btn btn-primary w-100"
            id="key-btn"
          >
            <span className="indicator-label">Update Access key</span>
          </a>
          </Link>
              </>):(<>
                <a 
          onClick={updateUserPermissions}
            className="btn btn-primary w-100"
            id="key-btn"
          >
            <span className="indicator-label">Add Permissions</span>
          </a>
              </>)}
              </>):('')}
          {/*end::Button*/}
        </div>
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
    </div>
    {/*end::Card body*/}
  </div>
  {/*end::Messenger*/}
</div>   
        </>
    );
          }
}

export default Permissions;
