import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IsCloudUpdate } from "../../../../../Redux/Services/Action/actions";   
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import swal from 'sweetalert';
import UseApi from "../../../../../CustomHooks/useApi";
import UsePost from "../../../../../CustomHooks/usePost";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
const Backup=(props)=>{
  const navigate=useNavigate();
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4];  
  const [backupData,setBackupData]=useState(null);
  const [cloudInfo,setCloudInfo]=useState(null);
  const [pageStatus,setPageStatus] = useState(false)
  const dispatch=useDispatch();
  useEffect(() => { 
    if(props.cloudData.backups){
    setBackupData(props.cloudData.backups); 
    setCloudInfo(props.cloudData);
  }
  }, [props.cloudData]); 
  const navRedirect=()=>{
    setTimeout(() => {
      navigate("/cloud/"+id);
     }, 3000);  
  }
  const restoreBackup=async(backupId)=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once restored the backup, your server move to the state of this Backup",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
        Event.prototype.preventDefault();
        Event.prototype.stopPropagation();
      if(willDelete) {
        setPageStatus(true)
        const dt=toast.loading("Snapshot restoring..."); 
    const dnsp=await UseApi('POST','cloud/'+id+'/backup/'+backupId+'/restore','');
    if(dnsp.status=='error'){
      setPageStatus(false)
      toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else{
      setPageStatus(false)
      toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });  
    const data=await UseApi('get','cloud/'+id,'');
         dispatch(props.updateCloudStatus(data.cloud[0]));
         navRedirect();
    }
      }else {
        swal({
            title: "Backup",
            text: "Your server still on the current state!",
            icon: "success",
            button: "OK",
          });
      }
      });
    
  }

  const deleteBackup=async(backupId)=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this backup!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        setPageStatus(true)
        const dt=toast
        const dnsp=await UseApi('DELETE','cloud/'+id+'/backup/'+backupId+'/delete','');
        ////console.log(dnsp);
        if(dnsp.status=='error'){
          setPageStatus(false) 
          toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        }else{
          setPageStatus(false)
          toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });  
        const data=await UseApi('get','cloud/'+id,'');
         dispatch(props.updateCloudStatus(data.cloud[0]));
         navRedirect();
        }
      }else {
        swal({
            title: "Backup",
            text: "Your Backup is safe!",
            icon: "success",
            button: "OK",
          });
      }
      });
    
  }
  const BackupStatus=async (type)=>{
    toast.dismiss();
    swal({
    title:"Are you sure?",
    text: "Do you really want to "+type+" backup in your cloud!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
    })
    .then(async(willDelete) => {
    if(willDelete) {
      setPageStatus(true)
      const dt=toast.loading("Backup enabling, Please wait...");
      const responseData=await UsePost('post','cloud/'+id+'/backups/'+type,'backupsenable'); 
       // ////console.log(responseData);
        if(responseData.status==='error'){
          setPageStatus(false)
          toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });  
        }else if(responseData.status==='success'){
          setPageStatus(false)
          toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        }); 
        const data=await UseApi('get','cloud/'+id,'');
         dispatch(props.updateCloudStatus(data.cloud[0]));
         navRedirect();
        }
    }
    });
    
   }
   
    return(
        <>
        <div className="card mb-5 mb-xxl-10">
      {/*begin::Header*/}
      <div className="card-header">
        {/*begin::Title*/}
        <div className="card-title">
          <h3>Backups</h3>
        </div>
        {/*end::Title*/}
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className="card-body py-10">
        {/*begin::Row*/}
        <div className="row mb-10">
          {/*begin::Notice*/}
          <div
            id="backup-toggle"
            className="notice d-flex bg-light-primary rounded border-primary border p-6"
          >
            {/*begin::Icon*/}{" "}
            {/*begin::Svg Icon | path: icons/duotune/finance/fin001.svg*/}{" "}
            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                {" "}
                <path
                  d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z"
                  fill="black"
                />{" "}
                <path
                  opacity="0.3"
                  d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z"
                  fill="black"
                />{" "}
              </svg>{" "}
            </span>{" "}
            {/*end::Svg Icon*/} {/*end::Icon*/} {/*begin::Wrapper*/}{" "}
            <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
              {" "}
              {/*begin::Content*/}{" "}
              <div className="mb-3 mb-md-0 fw-bold">
                <h4 className="text-gray-900 fw-bolder">Cloud Server Backup</h4>{" "}
                <div className="fs-6 text-gray-700 pe-7">
                  Backups are currently disabled for this cloud server <br /> We
                  charge only 20% cost of your cloud server for this backup
                  service
                </div>{" "}
              </div>{" "}
              {/*end::Content*/} {/*begin::Action*/}{" "}
              {cloudInfo!=null && (cloudInfo.features && (cloudInfo.features.backups && (cloudInfo.features.backups=="1"?(<>
              
              <a
                onClick={()=>BackupStatus('disable')} 
                className="btn btn-danger px-6 align-self-center text-nowrap"
              >
                Disable Backups
              </a>
              </>):(<>
                <a
                onClick={()=>BackupStatus('enable')} 
                className="btn btn-primary px-6 align-self-center text-nowrap"
              >
                Enable Backups
              </a>
              </>))))}
            </div>
          </div>
          {/*end::Notice*/}
        </div>
        {/*end::Row*/}
      </div>
      {/*end::Body*/}
    </div>
    {/*end::Access*/}
    <div className="separator my-10" />
    {/*begin::License usage*/}
    <div className="card">
      {/*begin::Card header*/}
      <div className="card-header">
        {/*begin::Heading*/}
        <div className="card-title">
          <h3>Current Backups </h3>
        </div>
        {/*end::Heading*/}
      </div>
      {/*end::Card header*/}
      {/*begin::Card body*/}
      <div className="card-body p-0">
        {/*begin::Table wrapper*/}
        <div className="table-responsive">
          {/*begin::Table*/}
          <table
            className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
            id="kt_security_license_usage_table"
          >
            {/*begin::Thead*/}
            <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
              <tr>
                <th className="w-150px ps-9">#ID</th>
                <th className="min-w-150px">Backup Size</th>
                <th className="min-w-150px">Created At</th>
                <th className="min-w-150px">Status</th>
                <th className="min-w-150px">Restore</th>
                <th className="min-w-150px">Delete</th>
              </tr>
            </thead>
            {/*end::Thead*/}
            {/*begin::Tbody*/}
            <tbody id="backup-list" className="fw-6 fw-bold text-gray-600">
             {backupData!=null && Object.keys(backupData).length !=0?(<>{ backupData.map((value,index)=>(<>
             <tr> 
              <td className="ps-9">{value.id}</td> 
              <td>{value.size}</td> 
              <td>{value.created_at}</td> 
              <td>
                {value.status=='Success'?(<span className="badge badge-light-success fs-7 fw-bolder">{value.status}</span>):('')} 
              </td>
              <td className="text-left"> 
                <div className="card-toolbar">  
                  <a onClick={(e)=>{e.preventDefault();restoreBackup(value.id)}} className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" > 
                   <span className="svg-icon svg-icon-2"> 
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <path d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z" fill="black"></path> <path opacity="0.3" d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z" fill="black"></path> 
                      </svg> 
                   </span>  
                  </a> 
                </div>
              </td> 
              <td className="text-left"> 
                <div className="card-toolbar"> 
                 <a onClick={(e)=>{e.preventDefault();deleteBackup(value.id)}} className="btn btn-sm btn-icon btn-color-danger btn-active-light-danger">
                  <span className="svg-icon svg-icon-2"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black"></path> <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black"></path> <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black"></path> 
                    </svg> 
                  </span>  
                 </a> 
                </div>
              </td>  
            </tr>
            </>))}</>):(<>
              <tr>  
                    <td colspan="12" >
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
                    </>)}
            </tbody>
            {/*end::Tbody*/}
          </table>
          {/*end::Table*/}
        </div>
        {/*end::Table wrapper*/}
      </div>
      {/*end::Card body*/}
    </div>
    {pageStatus &&
        <TopBarHeaderLine />
      }
        </>
    )
}

const mapStateToProps=state=>({
  cloudData:state.cloud.cloudDataInfo
})

const mapDispatchToProps=dispatch=>({
  updateCloudStatus:data=>dispatch(IsCloudUpdate(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(Backup) 