import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import UseApi from "../../../../../../CustomHooks/useApi"; 
import AddFundRazorpay from "./section/AddFundRazorpay";
import localStorage from "local-storage";
export default function AddingFund() {
  const dataFetchedRef = useRef(); 
  const [userData, setUserData] = useState(null);
  const [razorpayCardList, setRazorpayCardList] = useState(null);
  const [stripeCardList, setStripeCardList] = useState(null); 
  const getcards = async () => {
    const rdata = await UseApi("get", "razorpay/listcard", "");
    const sdata = await UseApi("get", "stripe/listcard", "");
    //const data = await UseApi("get", "account/info", "");
    setUserData(localStorage.get("user_info"));
    setStripeCardList(sdata.cards);
    setRazorpayCardList(rdata.cards); 
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    toast.dismiss();
    getcards();
    
  }, []);


  return (
    <>
      {/*begin::Row*/}
      <div className="row g-0">
        {/*begin::Col*/}
        <div className="col  px-6 py-8 rounded-2 me-7 mb-7 btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start">
          {/*begin::Svg Icon | path: icons/duotune/finance/fin006.svg*/}
          <i className="bi bi-wallet2 fs-2x" />
          {/*end::Svg Icon*/}
          <div className="card-body">
            <a className="card-title fw-bold text-muted text-hover-primary fs-4">
              Add Credits via Debit/Credit Card, Netbanking, Wallet, UPI
            </a>
            <div className="fw-bold text-primary my-6">
              <label htmlFor="size" className="form-label">
                {" "}
                Worldwide Payments - You can add fund via Debit/Credit Card,
                Netbanking, Wallet, UPI
              </label>
            </div>
            <div className="d-flex align-items-center" id="payment-via-cn">
              <form role="form">
                {" "}
                <input
                  type="hidden"
                  id="firstname"
                  name="firstname"
                  defaultValue=""
                />{" "}
                <input
                  type="hidden"
                  id="lastname"
                  name="lastname"
                  defaultValue=""
                />{" "}
                <input
                  type="hidden"
                  name="mobile"
                  id="mobile"
                  defaultValue=""
                />{" "}
                <input
                  type="hidden"
                  id="email"
                  name="email"
                  defaultValue=""
                />{" "}
                <input
                  type="hidden"
                  id="currency"
                  name="currency"
                  defaultValue="INR"
                />{" "}
                <input
                  type="hidden"
                  name="invoiceid"
                  id="invoiceid"
                  defaultValue="Addfund"
                />{" "}
                <input
                  type="hidden"
                  name="disp"
                  id="disp"
                  defaultValue="Add Credits to Cloud Account"
                />{" "}
                  {userData!=null && userData.currency=="INR"?(<>
                  <AddFundRazorpay />
                  </>):(<>
                    <AddFundRazorpay />
                  </>)}
                   
                {/*end:Action*/}{" "}
              </form>
            </div>
          </div>
        </div>
        {/*end::Col*/}
      </div>
      {/*end::Row*/}
    </>
  );
}
