import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IsCloudUpdate } from "../../../../../Redux/Services/Action/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import UseApi from "../../../../../CustomHooks/useApi";
import UsePost from "../../../../../CustomHooks/usePost";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
const Storage = (props) => {
  const navigate = useNavigate();
  var query = window.location.href;
  var vars = query.split("/");
  const [pageStatus, setPageStatus] = useState(false);
  var id = vars[4];
  const dataUseRef = useRef();
  const [storageData, setStorageData] = useState(null);
  const dataFetchedRef = useRef(false);
  const dispatch = useDispatch();

  const navRedirect = () => {
    setTimeout(() => {
      navigate("/cloud/" + id);
    }, 3000);
  };
  useEffect(() => {
    setStorageData(props.cloudData.storages);
  }, [props.cloudData]);

  const deleteStorage = async (storageId) => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this additional storage!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        var submitBtn = document.querySelector("#delete-storage-" + storageId);
        if (submitBtn) {
          setPageStatus(true);
          var preContent = submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML =
            '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
          const dt = toast.loading(
            "Additional Storage " + storageId + " deleting, Please wait..."
          );
          const dnsp = await UseApi(
            "delete",
            "cloud/" + id + "/storage/" + storageId + "/delete",
            ""
          );
          if (dnsp.status == "error") {
            setPageStatus(false);
            toast.update(dt, {
              render: dnsp.message,
              type: "warning",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setPageStatus(false);
            toast.update(dt, {
              render: dnsp.message,
              type: "success",
              isLoading: false,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            const data = await UseApi("get", "cloud/" + id, "");
            dispatch(props.updateCloudStatus(data.cloud[0]));
            navRedirect();
          }
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML = preContent;
        }
      } else {
        setPageStatus(false);
        swal({
          title: "Additional Storage",
          text: "Your additional storage " + storageId + " is safe!",
          icon: "success",
          button: "OK",
        });
      }
    });
  };

  function checkStorageSize(storage_size) {
    toast.dismiss();
    if (storage_size < 1) {
      var sizeField = document.querySelector("#size");
      if (sizeField) {
        sizeField.value = 1;
      }
      // const dt=toast.loading("Additional storage assigning, Please wait...");
      // toast.update(dt,{render: "Storage size can not be less then 1GB", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      // });
      return;
    }
  }
  const addAdditionalStorage = async () => {
    toast.dismiss();
    swal({
      title: "Are you sure?",
      text: "Do you really want to assign additional storage in your cloud!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        var submitBtn = document.querySelector("#add-additional-ip");
        if (submitBtn) {
          setPageStatus(true);
          var preContent = submitBtn.innerHTML;
          submitBtn.classList.add("disabled");
          submitBtn.innerHTML =
            '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';

          const dt = toast.loading(
            "Additional storage assigning, Please wait..."
          );
          const responseData = await UsePost(
            "post",
            "cloud/" + id + "/storage/add/",
            "addstorageform"
          );
          //  console.log(preContent);
          if (responseData) {
            submitBtn.classList.remove("disabled");
            submitBtn.innerHTML = preContent;
            if (responseData.status === "error") {
              setPageStatus(false);
              toast.update(dt, {
                render: responseData.message,
                type: "error",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (responseData.status === "success") {
              setPageStatus(false);
              toast.update(dt, {
                render: responseData.message,
                type: "success",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              const data = await UseApi("get", "cloud/" + id, "");
              dispatch(props.updateCloudStatus(data.cloud[0]));
            }
          }
        }
      } else {
        setPageStatus(false);
      }
    });
  };
  const updateRdns = async (storageId) => {
    toast.dismiss();
    const dt = toast.loading(
      "Updating storage " + storageId + " , Please wait..."
    );
    const bus_data = document.querySelector("#bus_data" + storageId);
    const type_data = document.querySelector("#type_data" + storageId);
    const bus_field = document.querySelector("#bus");
    const type_field = document.querySelector("#type");
    bus_field.value = bus_data.value;
    type_field.value = type_data.value;

    var submitBtn = document.querySelector("#update-storage-" + storageId);
    if (submitBtn) {
      setPageStatus(true);
      var preContent = submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML =
        '<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const responseData = await UsePost(
        "post",
        "cloud/" + id + "/storage/" + storageId + "/update",
        "updatestorage_"
      );

      //////console.log(responseData);
      if (responseData.status === "error") {
        setPageStatus(false);
        toast.update(dt, {
          render: responseData.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (responseData.status === "success") {
        setPageStatus(false);
        toast.update(dt, {
          render: responseData.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const data = await UseApi("get", "cloud/" + id, "");
        dispatch(props.updateCloudStatus(data.cloud[0]));
        navRedirect();
      }
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML = preContent;
    }
  };
  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}

      <div className="card">
        {/*begin::Card header*/}
        <div className="card-header">
          {/*begin::Heading*/}
          <div className="card-title">
            <h3>Current Storage on this server </h3>
          </div>
          {/*end::Heading*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body p-0">
          {/*begin::Table wrapper*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <form className="form" id="updatestorage_" name="updatestorage_">
              <input type="hidden" name="bus" id="bus" defaultValue="" />
              <input type="hidden" name="type" id="type" defaultValue="" />
            </form>
            <table
              className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
              id="kt_security_license_usage_table"
            >
              {/*begin::Thead*/}
              <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                <tr>
                  <th className="w-150px ps-9">ID</th>
                  <th className="min-w-200px">Storage Size</th>
                  <th className="min-w-250px text-center">BUS</th>
                  <th className="pe-5 min-w-250px text-center">Storage Type</th>
                  <th className="w-250px ps-9" />
                </tr>
              </thead>
              <tbody className="fw-6 fw-bold text-gray-600" id="storage-list">
                {storageData != null &&
                  storageData &&
                  storageData.map((value, index) => (
                    <tr>
                      <td className="w-150px ps-9">{value.id}</td>
                      <td className="min-w-150px">
                        {value.size} GB{" "}
                        <span className="badge badge-light-success fs-7 fw-bolder">
                          Used {value.disk_usedp}%
                        </span>
                      </td>
                      <td className="min-w-150px text-center">
                        <select
                          className="form-select"
                          id={"bus_data" + value.id}
                        >
                          {value.bus == "virtio" ? (
                            <>
                              <option value="virtio" selected="Selected">
                                virtio
                              </option>
                              <option value="ide">ide</option>
                            </>
                          ) : (
                            <>
                              <option value="virtio">virtio</option>
                              <option value="ide" selected="Selected">
                                ide
                              </option>
                            </>
                          )}
                        </select>
                      </td>
                      <td className="p-5 pe-5 min-w-150px text-center">
                        <select
                          className="form-select"
                          id={"type_data" + value.id}
                        >
                          {value.type == "Primary" ? (
                            <>
                              <option value="Primary" selected="Selected">
                                Primary
                              </option>
                              <option value="Additional">Additional</option>
                            </>
                          ) : (
                            <>
                              <option value="Primary">Primary</option>
                              <option value="Additional" selected="Selected">
                                Additional
                              </option>
                            </>
                          )}
                        </select>
                      </td>
                      <td className="w-250px ps-9">
                        <a
                          id={"update-storage-" + value.id}
                          onClick={() => updateRdns(value.id)}
                          className="btn btn-primary me-2"
                        >
                          Update
                        </a>
                        {value.type == "Additional" ? (
                          <>
                            <a
                              id={"delete-storage-" + value.id}
                              onClick={() => deleteStorage(value.id)}
                              className="btn btn-danger"
                            >
                              Delete
                            </a>
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {/*end::Table wrapper*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::License usage*/}
      <div className="separator my-10" />
      {/*begin::Deactivate Account*/}
      <div className="card">
        {/*begin::Card header*/}
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_deactivate"
          aria-expanded="true"
          aria-controls="kt_account_deactivate"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Add Storage</h3>
          </div>
        </div>
        {/*end::Card header*/}
        {/*begin::Content*/}
        <div id="kt_account_settings_deactivate" className="collapse show">
          {/*begin::Form*/}
          <form id="addstorageform" className="form">
            {/*begin::Card body*/}
            <div className="card-body border-top p-9">
              {/*begin::Notice*/}
              <div className="notice d-flex bg-light-primary rounded border-primary border mb-9 p-6">
                {/*begin::Icon*/}
                {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.3"
                      x={2}
                      y={2}
                      width={20}
                      height={20}
                      rx={10}
                      fill="black"
                    />
                    <rect
                      x={11}
                      y={14}
                      width={7}
                      height={2}
                      rx={1}
                      transform="rotate(-90 11 14)"
                      fill="black"
                    />
                    <rect
                      x={11}
                      y={17}
                      width={2}
                      height={2}
                      rx={1}
                      transform="rotate(-90 11 17)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                {/*end::Icon*/}
                {/*begin::Wrapper*/}
                <div className="d-flex flex-stack flex-grow-1">
                  {/*begin::Content*/}
                  <div className="fw-bold">
                    <h4 className="text-gray-900 fw-bolder">
                      You can add/remove additional storage on your server..
                    </h4>
                    <div className="fs-6 text-gray-700">
                      You will charge INR 9/Per GB monthly for additonal
                      storage.
                    </div>
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*end::Notice*/}
              {/*begin::Form input row*/}
              <div className="mb-1">
                <label htmlFor="size" className="required form-label">
                  Please fill size in GB
                </label>
                <input
                  type="number"
                  className="form-control"
                  min={1}
                  name="size"
                  id="size"
                  defaultValue=""
                  placeholder={50}
                  onChange={(e) => checkStorageSize(e.target.value)}
                />
              </div>
              {/*end::Form input row*/}
            </div>
            {/*end::Card body*/}
            {/*begin::Card footer*/}
            <div className=" d-flex justify-content-start py-6 px-9">
              <a
                id="add-additional-ip"
                onClick={addAdditionalStorage}
                className="btn btn-primary fw-bold"
              >
                + Add Storage
              </a>
            </div>
            {/*end::Card footer*/}
          </form>
          {/*end::Form*/}
        </div>
        {/*end::Content*/}
      </div>
      {pageStatus && <TopBarHeaderLine />}
    </>
  );
};

const mapStateToProps = (state) => ({
  cloudData: state.cloud.cloudDataInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updateCloudStatus: (data) => dispatch(IsCloudUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Storage);
