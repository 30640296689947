import { CLOUD_DEPLOY_DATA, DEPLOY_NODE_POOL_ROW, HOST_COUNT, IS_CLOUD_UPDATE, UPDATE_NODE_POOL_ROW, DATABASE_NODE_COUNT, CURRENT_OBJECT_PARENTID, OBJECT_BREADCRUMB, DATABASE_ACCESS_IP_DATA, DATABASE_CLUSTER_INFO, IS_PLAN_UPDATE, ACTIVITY_PAGINATION, CLOUD_PAGINATION } from "../constants"


export const IsCloudUpdate=(data)=>{
    //console.log("action",data);
    return { 
        type: IS_CLOUD_UPDATE,
        data:data
    }
}
export const UpdateNodePoolRow=(data)=>{ 
    return { 
        type: UPDATE_NODE_POOL_ROW,
        data:data
    }
}
export const DeployNodePoolRow=(data)=>{ 
    return { 
        type: DEPLOY_NODE_POOL_ROW,
        data:data
    }
}
export const AddHostCount=(data)=>{ 
    return { 
        type: HOST_COUNT,
        data:data
    }
}

export const cloudDeployUpdate=(data)=>{ 
    return { 
        type: CLOUD_DEPLOY_DATA,
        data:data
    }
}

export const AddDatabaseNodeCount=(data)=>{ 
    return { 
        type: DATABASE_NODE_COUNT,
        data:data
    }
}

export const AddCurrentObjectParentid=(data)=>{ 
    return { 
        type: CURRENT_OBJECT_PARENTID,
        data:data
    }
}


export const AddObjectBreadcrumb=(data)=>{ 
    return { 
        type: OBJECT_BREADCRUMB,
        data:data
    }
}

export const AddDatabaseAccessIp=(data)=>{ 
    return { 
        type: DATABASE_ACCESS_IP_DATA,
        data:data
    }
}

export const AddDatabaseDeploy=(data)=>{ 
    return { 
        type: DATABASE_CLUSTER_INFO,
        data:data
    }
}

export const updatePricingInfo=(data)=>{ 
    return { 
        type: IS_PLAN_UPDATE,
        data:data
    }
}

export const UpdateActivityPagination=(data)=>{ 
    return { 
        type: ACTIVITY_PAGINATION,
        data:data
    }
}

export const UpdateCloudPagination=(data)=>{ 
    return { 
        type: CLOUD_PAGINATION,
        data:data
    }
}