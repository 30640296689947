import React, { useEffect, useState } from "react";
import AddCardTab from "./sections/AddCard/AddCardTab"; 
import AddFundTab from "./sections//AddFund/AddFundTab";
import ViewBankTab from "./sections/ViewBankTab";
import ViewInvoicesTab from "./sections/ViewInvoicesTab";
import AccessDenied from "../../AccessDenied";
import UseApi from "../../../../CustomHooks/useApi";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
export default function Billing() {  
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess]=useState(true);

  const getInvoices=async()=>{  
    const data=await UseApi('get','billing','');  
     if(data){
      setDataFetched(true);
     }

     if(data.status=="error"){
      setAccess(false);
     }
   } 
  useEffect(()=>{   
    getInvoices();  
  },[]);

if(dataFetched){
  if(access==false){
    return (
      <>
       <AccessDenied />
      </>
    );
  }
}else{
  return(
    <TopBarHeader/>
  )
}
    return(
        <> 
           
             <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    <div className="post d-flex flex-column-fluid">
      <div className="container-xxl" id="appresult">
        <div className=" mb-6 mb-xl-9">
          <div className="card-body pt-0 pb-0">
            {/*begin::Nav*/}
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
              {/*begin::Nav item*/}
              <li className="nav-item" id="btn-funds">
                <a
                  className="nav-link text-active-primary py-5 me-6 active"
                  data-bs-toggle="tab"
                  href="#addfund"
                >
                  Add Funds
                </a>
              </li>
              <li className="nav-item" id="btn-credits">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#addcredit"
                  onclick="cardList();cardListStripe();"
                >
                  Payment Methods
                </a>
              </li>
              <li className="nav-item" id="btn-credits">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#bankdetails"
                  onclick="getbankdetails();"
                >
                  Bank Details
                </a>
              </li>
              <li className="nav-item" id="btn-invoice">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#invoice" 
                >
                  Invoices
                </a>
              </li>
              <li className="nav-item d-none" id="btn-tds">
                <a className="nav-link" data-bs-toggle="tab" href="#tds">
                  TDS
                </a>
              </li>
              {/*end::Nav item*/}
            </ul>
            {/*end::Nav*/}
          </div>
        </div>

         
        <div className="tab-content" id="myTabContent">
        <AddFundTab />
        <AddCardTab />
        <ViewBankTab />
        <ViewInvoicesTab />
          <div className="tab-pane fade" id="tds" role="tabpanel">
            {/*begin::Deactivate Account*/}
            <div className="">
              {/*begin::Card header*/}
              <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_deactivate"
                aria-expanded="true"
                aria-controls="kt_account_deactivate"
              >
                <div className="card-title m-0">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_deactivate"
                    aria-expanded="true"
                    aria-controls="kt_account_deactivate"
                  >
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">TDS </span>
                      <span className="text-muted mt-1 fw-bold fs-7">
                        Here you can upload and view TDS added and certificates
                        provided by you.
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              {/*end::Card header*/}
              {/*begin::Content*/}
              <div
                id="kt_account_settings_deactivate"
                className="collapse show"
              >
                {/*begin::Card body*/}
                <div className="card-body border-top">
                  {/*begin::Card toolbar*/}
                  <div className="card-toolbar p-5">
                    {/*begin::Toolbar*/}
                    {/*begin::Add customer*/}
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#-modal"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/files/fil018.svg*/}
                      <span className="svg-icon svg-icon-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                            fill="currentColor"
                          />
                          <path
                            d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z"
                            fill="currentColor"
                          />
                          <path
                            opacity="0.3"
                            d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                      Upload Files
                    </button>
                    {/*end::Toolbar*/}
                  </div>
                </div>
                {/*end::Card body*/}
              </div>
              {/*end::Content*/}
              {/*begin::Notice*/}
              {/*begin::Table container*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table className="table table-striped table-row-dashed table-row-gray-300 align-middle gs-4 gy-4">
                  {/*begin::Table head*/}
                  <thead className="bg-secondary">
                    <tr className=" fw-bold">
                      <th className="fw-bold min-w-100px">#</th>
                      <th className="fw-bold min-w-100px">Type</th>
                      <th className="fw-bold min-w-10px">Amount</th>
                      <th className="fw-bold min-w-100px">Date</th>
                      <th className="min-w-50px" />
                      <th className="min-w-50px" />
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody id="tds-invoice-list"></tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table container*/}
              {/*end::Notice*/}
            </div>
            {/*end::Deactivate Account*/}
            <div
              className="modal fade"
              id="-modal"
              tabIndex={-1}
              style={{ display: "none" }}
              aria-hidden="true"
            >
              {/*begin::Modal dialog*/}
              <div className="modal-dialog modal-dialog-centered mw-650px">
                {/*begin::Modal content*/}
                <div className="modal-content">
                  {/*begin::Form*/}
                  <form
                    className="form"
                    action="https://api.microhost.com/upload.php"
                    method="post"
                    encType="multipart/form-data"
                  >
                    <input
                      type="hidden"
                      name="redirect"
                      defaultValue="https://cloud.microhost.com/billing/#tds"
                    />
                    <input
                      type="hidden"
                      name="apikey"
                      id="apikey"
                      defaultValue="UMXPzyqjbWHOukpTxVwFYsDaiIvnrcgANReZhBJoSQEdfLGtmlKC"
                    />
                    {/*begin::Modal header*/}
                    <div className="modal-header">
                      {/*begin::Modal title*/}
                      <h2 className="fw-bold">Upload files</h2>
                      {/*end::Modal title*/}
                      {/*begin::Close*/}
                      <div
                        className="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal"
                      >
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                        <span className="svg-icon svg-icon-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x={6}
                              y="17.3137"
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(-45 6 17.3137)"
                              fill="currentColor"
                            />
                            <rect
                              x="7.41422"
                              y={6}
                              width={16}
                              height={2}
                              rx={1}
                              transform="rotate(45 7.41422 6)"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </div>
                      {/*end::Close*/}
                    </div>
                    {/*end::Modal header*/}
                    {/*begin::Modal body*/}
                    <div className="modal-body pt-5 pb-5 px-lg-15">
                      {/*begin::Input group*/}
                      <div className="form-group">
                        {/*begin::Dropzone*/}
                        <div
                          className="dropzone dropzone-queue mb-2"
                          id="kt_modal_upload_dropzone"
                        >
                          {/*begin::Controls*/}
                          <div className="dropzone-panel mb-8">
                            <input
                              type="file"
                              name="uploadedFile"
                              id="uploadedFile"
                              className="inputfile "
                              aria-label="File browser"
                            />
                            <button type="submit" className="btn btn-primary">
                              Upload
                            </button>
                          </div>
                        </div>
                        {/*end::Input group*/}
                      </div>
                      {/*end::Modal body*/}
                      {/*end::Form*/}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*end::Tables Widget 9*/}
  </div>
  {/*end::Col*/} 
  <input
    type="hidden"
    name="merchant_order_id"
    id="merchant_order_id"
    defaultValue=""
  />
</div> 
        </>
    )
 }