import React, { useEffect, useState } from "react"; 
import localStorage from "local-storage";
import AddingRazorpayCard from "./AddCardsBtns/AddingRazorpayCard";
import AddingStripeCard from "./AddCardsBtns/AddingStripeCard";
export default function AddingCardBtns() {
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    setUserData(localStorage.get("user_info"));
  }, []);
  return (
    <> 
            {userData != null && userData.currency == "INR" ? (
              <>
                <AddingRazorpayCard />
              </>
            ) : (
              <>
                <AddingStripeCard />
              </>
            )} 
    </>
  );
}
