import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import UseApi from "../../../../CustomHooks/useApi"; 
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";

function useForceUpdate(){
  const [value, setValue] = useState(0);  
  return () => setValue(value => value + 1); 
}

export default function LoadbalancerDeploy(){ 
  
  const forceUpdate = useForceUpdate();
  const [adcdata,setAdcdata]=useState([]);
  const [ddcdata,setDdcdata]=useState([]);
  const dataFetchedRef=useRef(); 
 // const dczonesData=useRef();
  let navigate=useNavigate();
  const deployLoadbalancer=async()=>{
    const dcSlug = document.querySelectorAll('input[name="dcslug"]');
    const lbName = document.querySelector('#name');
    var submitBtn=document.querySelector("#loadbalancer-btn");
  if(submitBtn){
    var preContent=submitBtn.innerHTML;
    submitBtn.classList.add("disabled");
    submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';  
    toast.dismiss();
    const dt=toast.loading("Please wait...");
    if(dcSlug){  
      let checkedValue = null;
      for (const radioButton of dcSlug) {
        if (radioButton.checked) {
          checkedValue = radioButton.value;
          break;
        }
      }
      if(!checkedValue){ 
        toast.update(dt,{render: "Please select data center!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      submitBtn.innerHTML=preContent;
      submitBtn.classList.remove("disabled");
      return;
      }
    }
    if(lbName){
      if(lbName.value==""){ 
        toast.update(dt,{render: "Loabalancer name must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      submitBtn.innerHTML=preContent;
      submitBtn.classList.remove("disabled");
      return;
      }
    }

   
    const responseData=await UsePost('post','loadbalancer/add','addloadbalancer');
    if(responseData){
      submitBtn.innerHTML=preContent;
      submitBtn.classList.remove("disabled");
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      
      navigate("/loadbalancer/"+responseData.loadbalancerid);
    }
  } 
      }
    }
    useEffect(() => {
      window.addEventListener("keydown", function(event) {
        event.stopPropagation();
        var query = window.location.href; 
        var vars = query.split("/"); 
        var action=vars[4]; 
        //console.log(vars);
        if(event.key=="Enter" && action=="deploy" && vars[3]=="loadbalancer"){
          deployLoadbalancer();
      }
    });
    }, []);
    const getDczone=async()=>{
      // setAdcdata([]);
      // setDdcdata([]);
         const data=await UseApi('get','dczones',''); 
         if(Object.keys(data).length === 0){ 
          setAdcdata([]);
          setDdcdata([]);
         }else{
          
          data.dczones.map(async(value,index)=>{ 
             if(value.status!='active'){
              fetch("https://api.ipify.org?format=json")
              .then(response => response.json())
              .then(data =>{
                var server_ip=data.ip;
                if(server_ip){
              if(server_ip !== '103.209.144.51' || server_ip !== '103.209.144.66' || server_ip !==
                '103.209.145.208' || server_ip !== '103.209.144.81' || server_ip !== '103.209.144.82' ||
                server_ip !== '103.209.144.83' || server_ip !== '103.127.30.91' || server_ip !==
                '103.209.144.60' || server_ip !== '103.127.30.239' || server_ip !== '103.127.28.52'){
                  ddcdata.push(value); 
                } 
              }
              })
              .catch(error => console.error(error));
              // var client=await fetch(`https://geolocation-db.com/json/`)
              // .then(res => res.json());
              //  if(client){
              // var server_ip=client.IPv4;
              // if(server_ip !== '103.209.144.51' || server_ip !== '103.209.144.66' || server_ip !==
              //   '103.209.145.208' || server_ip !== '103.209.144.81' || server_ip !== '103.209.144.82' ||
              //   server_ip !== '103.209.144.83' || server_ip !== '103.127.30.91' || server_ip !==
              //   '103.209.144.60' || server_ip !== '103.127.30.239' || server_ip !== '103.127.28.52'){
              //     ddcdata.push(value); 
              //   } 
              //   forceUpdate();
              // }
              forceUpdate();
             }else{
                 adcdata.push(value);
                 forceUpdate();
             }
          })
            
         }
    }
    useEffect(()=>{
      if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        toast.dismiss();
        getDczone(); 
    },[]);  
    return(
        <>
        {adcdata.length>0?(<>
        {/*begin::Tables Widget 9*/}
        <form id="addloadbalancer" className="form">
        <div className="col-xl-12 card mb-5">
          {/*begin::Header*/}
          <div className="card-header border-3 mb-5 pt-2">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label  fw-bold fs-3 mb-1"> 
                Select DC Location
              </span>
              <span className="text-muted mt-1 fw-bold fs-7">
                Data Centeres
              </span>
            </h3>
          </div>
          {/*end::Header*/} 
        
            {/* Begin body */}
            <div className="container pb-5">
              {/*begin::Row*/}
              <div
                id="dc-location"
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >



           {adcdata.length>0?(adcdata && adcdata.map((value,index)=>(
                <>
                <div className="col-md-3 col-lg-3 col-xxl-3 dc_sibling">
                  <input
                    className="form-check-input deploy_checkbox "
                    type="radio"
                    name="dcslug"
                    defaultValue={value.slug}
                    id={"dcslug"+value.slug+""} 
                  />
                   <label
                  style={{ WebkitJustifyContent: 'unset' }}
                  id={"dcslugl_"+value.slug+""}
                  htmlFor={"dcslug"+value.slug+""}
                  className=" dcsluglable border-1 border-gray-300 border px-1 py-1">
  {/*begin::Info*/}
  <div className="d-flex flex-stack mb-3">
    {/*begin::Wrapper*/}
    <div className="me-3 p-3">
      {/*begin::Icon*/}
      <img
        src={"/assets/media/flags/svg/"+value.cc+".svg"}
        className="w-35px ms-n1 me-6"
        alt=""
      />
      {/*end::Icon*/}
      {/*begin::Title*/}
      <a 
        className="text-gray-800 text-hover-primary fw-bolder"
      >
        {value.city}
      </a>
      {/*end::Title*/}
    </div>
    {/*end::Wrapper*/}
    {/*begin::Action*/}
    <div className="me-3">
      {/*begin::Menu*/}
      <span className=" text-muted fw-bold justify-content-end">{value.country}</span>
      {/*end::Menu*/}
    </div>
    {/*end::Action*/}
  </div>
  {/*end::Info*/}
  {/*begin::Customer*/}
  {/*end::Customer*/}
</label>
                </div>  
                </>
              ))):(<><div className="small-spinner"></div></>)}
{ddcdata.length>0?(ddcdata && ddcdata.map((value,index)=>(
                <>
                <div className="col-md-3 col-lg-3 col-xxl-3 dc_sibling d-none">
                  <input
                    className="form-check-input deploy_checkbox deploylocationsoldout"
                    type="radio"
                    name="dcslug"
                    onClick={(e)=>e.target.checked=false}
                    defaultValue={value.slug}
                    id={"dcslug"+value.slug+""} 
                  />
                  <label
                    id={"dcslugl_"+value.slug+""}
                    htmlFor={"dcslug"+value.slug+""}
                    className="dcsluglable btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start"
                    data-kt-button="true"
                  >
                    
                    {/*begin::Radio button*/}
                    <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start">
                      
                    </span>
                    {/*end::Radio button*/}
                    <span className="ms-5">
                      
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center">
                        
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-50px me-5">
                          
                          <img
                            src={"/assets/media/flags/"+value.country.toLowerCase().replaceAll(' ','-')+".svg"}
                            className=""
                            alt=""
                          />
                        </div>
                        {/*end::Avatar*/} {/*begin::Text*/}
                        <div className="flex-grow-1">
                          
                          <a  className="text-dark fw-bolder text-hover-primary fs-6">
                            {value.city}
                          </a>
                          <span className="text-muted d-block fw-bold">
                            {value.country}
                          </span>
                          <span className="text-muted d-block fw-bold deploylocationsoldoutmsg" style={{fontSize:"10px"}}>
                            Temporarily unavailable
                          </span> 
                        </div>
                        {/*end::Text*/}
                      </div>
                      {/*end::Item*/}
                    </span>
                  </label>
                </div>  
                </>
              ))):('')}



                
              </div>
              {/*end::Row*/}
            </div>
            </div>

            <div className="col-xl-12 card mb-5">
          {/*begin::Header*/}
          <div className="card-header border-3 mb-5 pt-2">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label  fw-bold fs-3 mb-1"> 
                Load Balanacer Setting
              </span>
              <span className="text-muted mt-1 fw-bold fs-7">
              Load Balanacer Algorithm
              </span>
            </h3>
          </div>
          {/*end::Header*/} 
            <div className="container pb-5">
              {/*begin::Row*/}
              <div
                id="define-vpc"
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  {/*begin::Radio button*/}
                  <label
                    className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5"
                  >
                    {/*end::Description*/}
                    <div className="d-flex align-items-center me-2">
                      {/*begin::Radio*/}
                      <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="algorithm"
                          defaultValue="roundrobin"
                          id="roundrobin"
                          defaultChecked="checked"
                        />
                      </div>
                      {/*end::Radio*/}
                      {/*begin::Info*/}
                      <div className="flex-grow-1">
                        <div className="fw-bold opacity-50">Round Robin</div>
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::Description*/}
                  </label>
                  {/*end::Radio button*/}
                </div>
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  {/*begin::Radio button*/}
                  <label
                    className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5"
                  >
                    {/*end::Description*/}
                    <div className="d-flex align-items-center me-2">
                      {/*begin::Radio*/}
                      <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="algorithm"
                          defaultValue="leastconn"
                          id="leastconn"
                        />
                      </div>
                      {/*end::Radio*/}
                      {/*begin::Info*/}
                      <div className="flex-grow-1">
                        <div className="fw-bold opacity-50">
                          Least Connections
                        </div>
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::Description*/}
                  </label>
                  {/*end::Radio button*/}
                </div>
              </div>
              {/*end::Row*/}
            </div>
            <div className="container pb-5">
              {/*begin::Row*/}
              <div
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  {/*begin::Radio button*/}
                  <label
                    className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-5 mb-5"
                  >
                    {/*end::Description*/}
                    <div className="form-check form-switch form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="redirecthttps"
                        tabIndex={0}
                      />
                      <label className="form-check-label">
                        Redirect all traffic to HTTPS
                      </label>
                    </div>
                    {/*end::Description*/}
                  </label>
                  {/*end::Radio button*/}
                </div>
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  {/*begin::Radio button*/}
                  <label
                    className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-5 mb-5"
                  >
                    {/*end::Description*/}
                    <div className="form-check form-switch form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="cookie"
                        id="cookie"
                        tabIndex={0}
                      />
                      <label className="form-check-label">
                        Sticky Sessions
                      </label>
                      <input
                        type="text"
                        id="cookiename"
                        style={{ display: "none" }}
                        name="cookiename"
                        minvalue={1}
                        placeholder="MH-LB"
                        defaultValue="MH-LB"
                      />
                    </div>
                    {/*end::Description*/}
                  </label>
                  {/*end::Radio button*/}
                </div>
              </div>
              {/*end::Row*/}
            </div>
            </div>
            <div className="col-xl-12 card mb-5">
          {/*begin::Header*/}
          <div className="card-header border-3 mb-5 pt-2"> 
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label  fw-bold fs-3 mb-1"> 
                  Load Balancer Name
                </span>
              </h3>
            </div>
            <div className="container pb-5">
              {/*begin::Row*/}
              <div
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-md-6 col-lg-12 col-xxl-6">
                  <div className="h-lg-100">
                    <input
                      type="text"
                      className="form-control  "
                      name="name"
                      id="name"
                      placeholder="loadbalancer_app"
                    />
                    {/*end: Card Body*/}
                  </div>
                </div>
              </div>
              {/*end::Row*/}
            </div>
        
          <button
            type="button"
            style={{ width: "100%" }}
            className="btn btn-primary mt-3"
            onClick={deployLoadbalancer}
            id="loadbalancer-btn"
          >
            Create Load Balanacer +
          </button>
        </div> 
        </form>
        </>):(<><TopBarHeader /></>)}
        </>
        
    )
}