import React, { useCallback, useEffect, useRef, useState } from "react";  
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import UseApi from "../../../../../CustomHooks/useApi";
 export default function ViewBankTab() { 
  const dataFetchedRef=useRef();  
  const [bankDetails,setBankDetails]=useState(null);
  const getBankInfo=async()=>{    
    const bank_info=await UseApi('get','billing/bankdetails','');
 
    //console.log(bank_info);
    if(bank_info){
      if(bank_info.status=="success"){
        setBankDetails(bank_info.info);
      }else{
        setBankDetails(bank_info.status); 
     }
    }  
   } 
   useEffect(()=>{ 
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true; 
    toast.dismiss();
    getBankInfo();
     
  },[]);
    return (
        <>
       <div className="tab-pane fade" id="bankdetails" role="tabpanel">
            {bankDetails!=null?(<>
            {bankDetails=="success"?(<>
            {/*begin::Deactivate Account*/}
            <div className="" >
              {/*begin::Content*/}
              <div
                id="kt_account_settings_deactivate"
                className="collapse show"
              >
                {/*begin::Payment methods*/}
                <div className="card mb-5 mb-xl-10">
                  {/*begin::Card header*/}
                  <div className="card-header card-header-stretch pb-0">
                    {/*begin::Title*/}
                    <div className="card-title">
                      <h3 className="m-0">Bank Details</h3>
                    </div>
                    {/*end::Title*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar m-0">
                      {/*begin::Tab nav*/}
                      <ul
                        className="nav nav-stretch nav-line-tabs border-transparent"
                        role="tablist"
                      >
                        {/*begin::Tab item*/}
                        <li className="nav-item" role="presentation">
                          <a
                            id="kt_billing_creditcard_tab"
                            className="nav-link fs-5 fw-bolder me-5 active"
                            data-bs-toggle="tab"
                            role="tab"
                            href="#kt_bank"
                          >
                            Bank Info
                          </a>
                        </li>
                        {/*end::Tab item*/}
                        {/*begin::Tab item*/}
                        <li className="nav-item" role="presentation">
                          <a
                            id="kt_billing_paypal_tab"
                            className="nav-link fs-5 fw-bolder"
                            data-bs-toggle="tab"
                            role="tab"
                            href="#kt_upi"
                          >
                            UPI Address
                          </a>
                        </li>
                        {/*end::Tab item*/}
                      </ul>
                      {/*end::Tab nav*/}
                    </div>
                    {/*end::Toolbar*/}
                  </div>
                  {/*end::Card header*/}
                  {/*begin::Tab content*/}
                  <div
                    id="kt_billing_payment_tab_content"
                    className=" tab-content"
                  >
                    {/*begin::Tab panel*/}
                    <div
                      id="kt_bank"
                      className="tab-pane fade show active"
                      role="tabpanel"
                    >
                      {/*begin::Row*/}
                      <div className="row ">
                        <div id="bank-list" />
                        <div className="col-xl-12">
                          {/*begin::Card*/}
                          <div className=" h-xl-100 flex-row flex-stack flex-wrap p-6">
                            {/*begin::Info*/}
                            <div className="d-flex flex-column py-2">
                              {/*begin::Owner*/}
                              <div className="d-flex align-items-center fs-4 fw-bolder mb-5">
                              {bankDetails!=null?(<>{bankDetails.ba_bank_name=="RBL Bank"?(<>
                                <img
                                  src="assets/media/svg/bank/rbl.svg"
                                  alt=""
                                  className="me-4"
                                  width="10%"
                                />
                              </>):('')}</>):(<div class="bt-spinner"></div>)}
                               
                              </div>
                              {/*end::Owner*/}
                              {/*begin::Wrapper*/}
                              <div className="d-flex align-items-center">
                                <div className="card-body p-9">
                                  {/*begin::Row*/}
                                  <div className="row mb-7">
                                    {/*begin::Label*/}
                                    <label className="col-lg-4 fw-bold text-muted">
                                      Name
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Col*/}
                                    <div className="col-lg-8">
                                      <span
                                        className="fw-bolder fs-6 text-gray-800"
                                        id="ba_name"
                                      >
                                        {bankDetails!=null?(bankDetails.ba_name):(<div class="bt-spinner"></div>)}
                                        </span>
                                      {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                      {/* <span
                                        style={{ marginLeft: 10 }}
                                        onclick="copyToClipboard('ba_name','b_name')"
                                        id="b_name"
                                        className="fs-6 svg-icon svg-icon-muted svg-icon-2"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.5"
                                            d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                            fill="black"
                                          />
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span> */}
                                      {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Col*/}
                                  </div>
                                  {/*end::Row*/}
                                  {/*begin::Row*/}
                                  <div className="row mb-7">
                                    {/*begin::Label*/}
                                    <label className="col-lg-4 fw-bold text-muted">
                                      Bank Name
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Col*/}
                                    <div className="col-lg-8">
                                      <span
                                        className="fw-bolder fs-6 text-gray-800"
                                        id="bank_name"
                                      >
                                        {bankDetails!=null?(bankDetails.ba_bank_name):(<div class="bt-spinner"></div>)}
                                        </span>
                                      {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                      {/* <span
                                        style={{ marginLeft: 10 }}
                                        onclick="copyToClipboard('bank_name','bk_name')"
                                        id="bk_name"
                                        className="fs-6 svg-icon svg-icon-muted svg-icon-2"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.5"
                                            d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                            fill="black"
                                          />
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span> */}
                                      {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Col*/}
                                  </div>
                                  {/*end::Row*/}
                                  {/*begin::Input group*/}
                                  <div className="row mb-7">
                                    {/*begin::Label*/}
                                    <label className="col-lg-4 fw-bold text-muted">
                                      Bank Account Number
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Col*/}
                                    <div className="col-lg-8 fv-row">
                                      <span
                                        className="fw-bold text-gray-800 fs-6"
                                        id="ba_number"
                                      >
                                        {bankDetails!=null?(bankDetails.ba_account_number):(<div class="bt-spinner"></div>)}
                                        </span>
                                      {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                      {/* <span
                                        style={{ marginLeft: 10 }}
                                        onclick="copyToClipboard('ba_number','b_number')"
                                        id="b_number"
                                        className="fs-6 svg-icon svg-icon-muted svg-icon-2"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.5"
                                            d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                            fill="black"
                                          />
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span> */}
                                      {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Col*/}
                                  </div>
                                  {/*end::Input group*/}
                                  {/*begin::Input group*/}
                                  <div className="row mb-7">
                                    {/*begin::Label*/}
                                    <label className="col-lg-4 fw-bold text-muted">
                                      Bank IFSC Code
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Col*/}
                                    <div className="col-lg-8">
                                      <span
                                        className="fw-bold text-gray-800 fs-6"
                                        id="ba_ifsc"
                                      >
                                        {bankDetails!=null?(bankDetails.ba_ifsc):(<div class="bt-spinner"></div>)}
                                        </span>
                                      {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                                      {/* <span
                                        style={{ marginLeft: 10 }}
                                        onclick="copyToClipboard('ba_ifsc','b_ifsc')"
                                        id="b_ifsc"
                                        className="fs-6 svg-icon svg-icon-muted svg-icon-2"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            opacity="0.5"
                                            d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                            fill="black"
                                          />
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </span> */}
                                      {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Col*/}
                                  </div>
                                  {/*end::Input group*/}
                                  {/*begin::Notice*/}
                                  <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                                    {/*begin::Icon*/}
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={2}
                                          width={20}
                                          height={20}
                                          rx={10}
                                          fill="black"
                                        />
                                        <rect
                                          x={11}
                                          y={14}
                                          width={7}
                                          height={2}
                                          rx={1}
                                          transform="rotate(-90 11 14)"
                                          fill="black"
                                        />
                                        <rect
                                          x={11}
                                          y={17}
                                          width={2}
                                          height={2}
                                          rx={1}
                                          transform="rotate(-90 11 17)"
                                          fill="black"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                    {/*end::Icon*/}
                                    {/*begin::Wrapper*/}
                                    <div className="d-flex flex-stack flex-grow-1">
                                      {/*begin::Content*/}
                                      <div className="fw-bold">
                                        <h4 className="text-gray-900 fw-bolder">
                                          We need your attention!
                                        </h4>
                                        <div className="fs-6 text-gray-700">
                                          If you have any further query related
                                          to bank details then feel free to
                                          contact 
                                          <a
                                            className="fw-bolder"
                                            href="tel:01204840000"
                                          >
                                            , support
                                          </a>
                                          .
                                        </div>
                                      </div>
                                      {/*end::Content*/}
                                    </div>
                                    {/*end::Wrapper*/}
                                  </div>
                                  {/*end::Notice*/}
                                </div>
                              </div>
                              {/*end::Wrapper*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Card*/}
                        </div>
                      </div>
                      {/*end::Row*/}
                    </div>
                    {/*end::Tab panel*/}
                    {/*begin::Tab panel*/}
                    <div
                      id="kt_upi"
                      className="tab-pane fade p-6"
                      role="tabpanel"
                      aria-labelledby="kt_billing_paypal_tab"
                    >
                      {/*begin::Title*/}
                      <h3 className="mb-10">My UPI</h3>
                      {/*end::Title*/}
                      {/*begin::Row*/}
                      <div className="row mb-7">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-bold text-muted">
                          Name
                        </label>
                        {/*end::Label*/}
                        {/*begin::Col*/}
                        <div className="col-lg-8">
                          <span
                            className="fw-bolder fs-6 text-gray-800"
                            id="upi_name"
                          >
                            {bankDetails!=null?(bankDetails.vpa_id):(<div class="bt-spinner"></div>)}
                            </span>
                          {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                          {/* <span
                            style={{ marginLeft: 10 }}
                            onclick="copyToClipboard('upi_name','b2_name')"
                            id="b2_name"
                            className="fs-6 svg-icon svg-icon-muted svg-icon-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.5"
                                d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                fill="black"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                fill="black"
                              />
                            </svg>
                          </span> */}
                          {/*end::Svg Icon*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <div className="row mb-7">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-bold text-muted">
                          UPI Address
                        </label>
                        {/*end::Label*/}
                        {/*begin::Col*/}
                        <div className="col-lg-8">
                          <span
                            className="fw-bolder fs-6 text-gray-800"
                            id="my_upi"
                          >
                            {bankDetails!=null?(bankDetails.vpa_address):(<div class="bt-spinner"></div>)}
                            </span>
                          {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
                          {/* <span
                            style={{ marginLeft: 10 }}
                            onclick="copyToClipboard('my_upi','b_upi')"
                            id="b_upi"
                            className="fs-6 svg-icon svg-icon-muted svg-icon-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.5"
                                d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                fill="black"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                fill="black"
                              />
                            </svg>
                          </span> */}
                          {/*end::Svg Icon*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                    </div>
                    {/*end::Tab panel*/}
                  </div>
                  {/*end::Tab content*/}
                </div>
                {/*end::Payment methods*/}
              </div>
            </div>
            </>):(<> 
            <div className="d-flex flex-column flex-center"> 
            <div className="fs-5 fw-bolder text-dark mb-4">No bank details found.</div>
            </div>
            </>)}
            </>):('')}
          </div>
        </>
    );
}
