import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IsCloudUpdate } from "../../../../../Redux/Services/Action/actions";   
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import swal from 'sweetalert';
import UseApi from "../../../../../CustomHooks/useApi";
import UsePost from "../../../../../CustomHooks/usePost";
import TopBarHeader from "../../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../../Loaders/TableLoader";
const Resize=(props)=>{
  const navigate=useNavigate();
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4];   
  const [planData,setPlanData]=useState(null);
  const [pageStatus,setPageStatus] = useState(false)
  const [diskPlanData,setDiskPlanData]=useState(null);
  const [resizeCloudName, setResizeCloudName] = useState('')
  const dataFetchedRef=useRef (false);
  const dispatch=useDispatch();
  const user_data=useRef(null);
  const navRedirect=()=>{
    setTimeout(() => {
      navigate("/cloud/"+id);
     }, 3000);  
  }

  const getPlans=async()=>{ 
    setResizeCloudName('RAM')
    setDiskPlanData(null);
       const data=await UseApi('get','cloud/'+id+'/resizeplans?withdisk=0','');  
       user_data.current=await UseApi('get','account/info','');
       ////console.log(user_data.current);
       if(Object.keys(data).length === 0){
        setPlanData(null); 
       }else{
        console.log(data.plans);
        setPlanData(data.plans); 
        ////console.log(data);
       }
  }
  const getResizePlans=async()=>{ 
    setResizeCloudName("DISK")
    setPlanData(null);
    const data=await UseApi('get','cloud/'+id+'/resizeplans?withdisk=1','');  
    user_data.current=await UseApi('get','account/info','');
    if(Object.keys(data).length === 0){
      setDiskPlanData(null); 
    }else{
      setDiskPlanData(data.plans);  
     ////console.log(data);
    }
}
  useEffect(()=>{ 
      if(dataFetchedRef.current) return;
         dataFetchedRef.current=true;
      getPlans(); 
  },[]);  
 
  const show_plan_price=(planid,planType)=>{
      const plan_type=document.querySelector("#plan_type");
      const plan_value=document.querySelector("#plan_value");
      plan_type.value=planType;
      plan_value.value=planid;
      //const change_plan=document.querySelector('#change_plan_'+planid);
       const tickParent=document.querySelector('#tick_parent_'+planid);
       const tick2=document.getElementsByClassName('tick_');  
       const tickmain=document.getElementsByClassName('tick-parent');
       for(var i=0;i<tickmain.length;i++){
          tickmain[i].classList.remove("activeplan");
       }
       for(var i=0;i<tick2.length;i++){
        tick2[i].innerHTML="";
     }
     // tickmain.classList.remove("activeplan");
      tickParent.classList.remove("planseffect");
      tickParent.classList.add("activeplan"); 
      const tick= document.querySelector('#tick_'+planid);
       tick.innerHTML='<svg xmlns="http://www.w3.org/2000/svg"  width={24} height={24} viewBox="0 0 24 24" fill="none" > <path  opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="black" /><path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="black"  /> </svg>';

      //tick.innerHtml='<span classname="svg-icon svg-icon-success svg-icon-2hx"> <svg xmlns="http://www.w3.org/2000/svg"  width={24} height={24} viewBox="0 0 24 24" fill="none" > <path  opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="black" /><path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="black"  /> </svg> </span> ';
       ////console.log('#tick_'+planid);
}
  const resizeCloud=async ()=>{  
    toast.dismiss();
    swal({
    title:"Are you sure?",
    text: "Do you really want to resize your cloud server!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
    })
    .then(async(willDelete) => {
    if(willDelete) {
    setPageStatus(true)
      const dt=toast.loading("cloud server resizing, Please wait...");
      const responseData=await UsePost('post','cloud/'+id+'/resize','cloudresize'); 
       // ////console.log(responseData);
        if(responseData.status==='error'){
          setPageStatus(false)
          toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });  
        }else if(responseData.status==='success'){
          setPageStatus(false)
          toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        }); 
        const data=await UseApi('get','cloud/'+id,'');
         dispatch(props.updateCloudStatus(data.cloud[0]));
        navRedirect();
        }
    }else {
      setPageStatus(false)
      swal({
          title: "Cloud",
          text: "Your cloud server not resize!",
          icon: "warning",
          button: "OK",
        });
    }
    });
    
   } 
   console.log(diskPlanData);
    return(
        <>
        {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
      
         <div className="card">
      {/*begin::Header*/}
      <div className="card-header card-header-stretch">
        {/*begin::Title*/}
        <div className="card-title">
          <h3>Resize Cloud</h3>
        </div>
        {/*end::Title*/}
        {/*begin::Toolbar*/}
        <div className="card-toolbar">
          {/*begin::Tab nav*/}
          <ul
            className="nav nav-stretch fs-5 fw-bold nav-line-tabs border-transparent"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a 
                onClick={getPlans}
                className="nav-link text-active-gray-800 me-4 active"
                data-bs-toggle="tab"
                role="tab"
              >
                RAM,CPU PLANS
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a 
                onClick={getResizePlans}
                className="nav-link text-active-gray-800 me-4"
                data-bs-toggle="tab"
                role="tab"
              >
                DISK,RAM.CPU PLANS
              </a>
            </li>
          </ul>
          {/*end::Tab nav*/}
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Header*/}
      <div className="">
        {/*begin::Body*/}
        {/*begin::Nav*/}
        <ul className="nav nav-pills nav-pills-custom row position-relative mx-0 mb-9">
          {/*begin::Item*/}
          <li className="nav-item col-2 mx-0 p-0">
            {/*begin::Link*/}
            <a
              className="nav-link active d-flex justify-content-center w-100 border-0 h-100"
              data-bs-toggle="pill"
              href="#basic-tab"
            >
              {/*begin::Subtitle*/}
              <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                Basic
              </span>
              {/*end::Subtitle*/}
              {/*begin::Bullet*/}
              <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
              {/*end::Bullet*/}
            </a>
            {/*end::Link*/}
          </li>
          {/*end::Item*/}
          {/*begin::Item*/}
          <li className="d-none nav-item col-2 mx-0 px-0">
            {/*begin::Link*/}
            <a
              className="nav-link d-flex justify-content-center w-100 border-0 h-100"
              data-bs-toggle="pill"
              href="#dedicated-standard-tab"
            >
              {/*begin::Subtitle*/}
              <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                Dedicated Standard
              </span>
              {/*end::Subtitle*/}
              {/*begin::Bullet*/}
              <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
              {/*end::Bullet*/}
            </a>
            {/*end::Link*/}
          </li>
          {/*end::Item*/}
          {/*begin::Item*/}
          <li className="nav-item col-2 mx-0 px-0">
            {/*begin::Link*/}
            <a
              className="nav-link d-flex justify-content-center w-100 border-0 h-100"
              data-bs-toggle="pill"
              href="#dedicated-cpu-tab"
            >
              {/*begin::Subtitle*/}
              <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                Dedicated Memory
              </span>
              {/*end::Subtitle*/}
              {/*begin::Bullet*/}
              <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
              {/*end::Bullet*/}
            </a>
            {/*end::Link*/}
          </li>
          {/*end::Item*/}
          {/*begin::Item*/}
          <li className="nav-item col-2 mx-0 px-0">
            {/*begin::Link*/}
            <a
              className="nav-link d-flex justify-content-center w-100 border-0 h-100"
              data-bs-toggle="pill"
              href="#dedicated-memory-tab"
            >
              {/*begin::Subtitle*/}
              <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                Dedicated CPU
              </span>
              {/*end::Subtitle*/}
              {/*begin::Bullet*/}
              <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
              {/*end::Bullet*/}
            </a>
            {/*end::Link*/}
          </li>
          {/*end::Item*/}
          {/*begin::Bullet*/}
          <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded" />
          {/*end::Bullet*/}
        </ul>
        {/*end::Nav*/}
        {/*begin::Tab Content*/}
        <div className="tab-content">
          {/*begin::Tap pane*/}
          <div className="tab-pane fade show active" id="basic-tab">
            {/*begin::Radio group*/}
            <form id="cloudresize">
              <input
                type="hidden"
                name="type"
                id="plan_type"
                defaultValue=""
              />
              <input
                type="hidden"
                name="plan"
                id="plan_value"
                defaultValue=""
              />
               </form>
              <div className="row" data-kt-buttons="true">
                <div className="table-responsive" id="ramcpuplans">
                  {/*begin::Table*/}
                  <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                    {/*begin::Thead*/}
                    <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr>
                        <th className="min-w-55px ps-0 text-center" />
                        <th className="min-w-125px px-0 text-center">RAM</th>
                        <th className="min-w-125px">vCPU</th>
                        <th className="min-w-125px">SSD Disk</th>
                        <th className="min-w-125px">Bandwidth</th>
                        <th className="min-w-125px ps-0 text-center">Price</th>
                        <th className="min-w-125px ps-0 text-center" />
                      </tr>
                    </thead>
                    {/*end::Thead*/}
                    {/*begin::Tbody*/}
                    <tbody
                      id="basic-plan"
                      className="fs-6 fw-bold text-gray-600"
                    >
                      {resizeCloudName == "RAM" &&
                      <>
                      {planData!=null ? (planData && planData.map((value,index)=>(
                      <>
                      {value.slug=='basic'?(<>
                    <tr class="planseffect tick-parent" id={"tick_parent_"+value.id}>
                      <td class="ps-0 text-center">
                      <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_"+value.id}></span>
                      </td>
                      <td class="ps-0 text-center">{(value.ram/1024).toFixed(0)} GB</td> 
                      <td>{value.cpu} vCPU</td> 
                      <td>{value.disk} GB</td> 
                      <td>{value.bandwidth} GB</td> 
                      <td> 
                        {user_data.current!=null?
                        (props.cloudData.billingcycle=='hourly'?(<>Hourly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)/720).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='monthly'?(<>Monthly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*1)-((parseInt(value.price)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='3month'?(<>3 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*3)-((parseInt(value.price*3)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='6month'?(<>6 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*6)-((parseInt(value.price*6)*10)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='12month'?(<>12 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*12)-((parseInt(value.price*12)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='24month'?(<>24 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*24)-((parseInt(value.price*24)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='36month'?(<>36 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*36)-((parseInt(value.price*36)*25)/100)).toFixed(2)}</>):
                        (''))))))
                        )):('')} 
                      </td>
                      <td>
                         <a onClick={()=>show_plan_price(value.id,"ramcpu")} id={"change_plan_"+value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                      </td>
                    </tr>
                    </>):('')}
                      </>  
                      ))):<>
                      <tr>
                       <td colSpan={12}>
                       <TableLoader/>
                       </td>
                      </tr>
                      </>}
                      </>}
                      {resizeCloudName == "DISK" &&
                      <>
                      {diskPlanData!=null ? (diskPlanData && diskPlanData.map((value,index)=>(
                      <>
                      {value.slug=='basic'?(<>
                        <tr class="planseffect tick-parent" id={"tick_parent_"+value.id}>
                      <td class="ps-0 text-center">
                      <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_"+value.id}></span>
                      </td>
                      <td class="ps-0 text-center">{(value.ram/1024).toFixed(0)} GB</td> 
                      <td>{value.cpu} vCPU</td> 
                      <td>{value.disk} GB</td> 
                      <td>{value.bandwidth} GB</td> 
                      <td> 
                      {user_data.current!=null?
                        (props.cloudData.billingcycle=='hourly'?(<>Hourly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)/720).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='monthly'?(<>Monthly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*1)-((parseInt(value.price)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='3month'?(<>3 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*3)-((parseInt(value.price*3)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='6month'?(<>6 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*6)-((parseInt(value.price*6)*10)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='12month'?(<>12 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*12)-((parseInt(value.price*12)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='24month'?(<>24 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*24)-((parseInt(value.price*24)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='36month'?(<>36 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*36)-((parseInt(value.price*36)*25)/100)).toFixed(2)}</>):
                        (''))))))
                        )):('')} 
                      </td>
                      <td>
                         <a onClick={()=>show_plan_price(value.id,"full")} id={"change_plan_"+value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                      </td>
                    </tr>
                    </>):('')}
                      </>  
                      ))):<>
                      <tr>
                       <td colSpan={12}>
                       <TableLoader/>
                       </td>
                      </tr>
                      </>}
                      </>
}
                    </tbody>
                    {/*end::Tbody*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Radio group*/}
              {/*begin::Card footer*/}
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <a
                 onClick={resizeCloud}
                 className="btn btn-primary fw-bold"
                >
                  Resize Cloud Server
                </a>
              </div>
              {/*end::Card footer*/}
           
          </div>
          {/*end::Tap pane*/}
          {/*begin::Tap pane*/}
          <div className="d-none tab-pane fade" id="dedicated-standard-tab">
            {/*begin::Radio group*/}
          
              <div className="row" data-kt-buttons="true">
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                    {/*begin::Thead*/}
                    <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr>
                        <th className="min-w-55px ps-0 text-center" />
                        <th className="min-w-125px px-0 text-center">RAM</th>
                        <th className="min-w-125px">vCPU</th>
                        <th className="min-w-125px">SSD Disk</th>
                        <th className="min-w-125px">Bandwidth</th>
                        <th className="min-w-125px ps-0 text-center">Price</th>
                        <th className="min-w-125px ps-0 text-center" />
                      </tr>
                    </thead>
                    {/*end::Thead*/}
                    {/*begin::Tbody*/}
                    <tbody
                      id="standard-plan"
                      className="fs-6 fw-bold text-gray-600"
                    >
                    {planData!=null && (planData && planData.map((value,index)=>(
                      <>
                      {value.slug=='dedicated-standard'?(<>
                        <tr class="planseffect tick-parent" id={"tick_parent_"+value.id}>
                      <td class="ps-0 text-center">
                      <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_"+value.id}></span>
                      </td>
                      <td class="ps-0 text-center">{(value.ram/1024).toFixed(0)} GB</td> 
                      <td>{value.cpu} vCPU</td> 
                      <td>{value.disk} GB</td> 
                      <td>{value.bandwidth} GB</td> 
                      <td> 
                      {user_data.current!=null?
                        (props.cloudData.billingcycle=='hourly'?(<>Hourly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)/720).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='monthly'?(<>Monthly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*1)-((parseInt(value.price)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='3month'?(<>3 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*3)-((parseInt(value.price*3)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='6month'?(<>6 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*6)-((parseInt(value.price*6)*10)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='12month'?(<>12 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*12)-((parseInt(value.price*12)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='24month'?(<>24 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*24)-((parseInt(value.price*24)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='36month'?(<>36 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*36)-((parseInt(value.price*36)*25)/100)).toFixed(2)}</>):
                        (''))))))
                        )):('')} 
                      </td>
                      <td>
                         <a onClick={()=>show_plan_price(value.id,"ramcpu")} id={"change_plan_"+value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                      </td>
                    </tr>
                    </>):('')}
                      </>  
                      )))}


                  {diskPlanData!=null && (diskPlanData && diskPlanData.map((value,index)=>(
                      <>
                      {value.slug=='dedicated-standard'?(<>
                        <tr class="planseffect tick-parent" id={"tick_parent_"+value.id}>
                      <td class="ps-0 text-center">
                      <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_"+value.id}></span>
                      </td>
                      <td class="ps-0 text-center">{(value.ram/1024).toFixed(0)} GB</td> 
                      <td>{value.cpu} vCPU</td> 
                      <td>{value.disk} GB</td> 
                      <td>{value.bandwidth} GB</td> 
                      <td> 
                      {user_data.current!=null?
                        (props.cloudData.billingcycle=='hourly'?(<>Hourly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)/720).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='monthly'?(<>Monthly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*1)-((parseInt(value.price)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='3month'?(<>3 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*3)-((parseInt(value.price*3)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='6month'?(<>6 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*6)-((parseInt(value.price*6)*10)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='12month'?(<>12 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*12)-((parseInt(value.price*12)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='24month'?(<>24 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*24)-((parseInt(value.price*24)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='36month'?(<>36 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*36)-((parseInt(value.price*36)*25)/100)).toFixed(2)}</>):
                        (''))))))
                        )):('')} 
                      </td>
                      <td>
                         <a onClick={()=>show_plan_price(value.id,"full")} id={"change_plan_"+value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                      </td>
                    </tr>
                    </>):('')}
                      </>  
                      )))}

                    </tbody>
                    {/*end::Tbody*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Radio group*/}
              {/*begin::Card footer*/}
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <a
                 onClick={resizeCloud}
                 className="btn btn-primary fw-bold"
                >
                  Resize Cloud Server
                </a>
              </div>
              {/*end::Card footer*/} 
          </div>
          {/*end::Tap pane*/}
          {/*begin::Tap pane*/}
          <div className="tab-pane fade" id="dedicated-memory-tab">
            {/*begin::Radio group*/} 
              <div className="row" data-kt-buttons="true">
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                    {/*begin::Thead*/}
                    <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr>
                        <th className="min-w-55px ps-0 text-center" />
                        <th className="min-w-125px px-0 text-center">RAM</th>
                        <th className="min-w-125px">vCPU</th>
                        <th className="min-w-125px">SSD Disk</th>
                        <th className="min-w-125px">Bandwidth</th>
                        <th className="min-w-125px ps-0 text-center">Price</th>
                        <th className="min-w-125px ps-0 text-center" />
                      </tr>
                    </thead>
                    {/*end::Thead*/}
                    {/*begin::Tbody*/}
                    <tbody
                      id="memory-plan"
                      className="fs-6 fw-bold text-gray-600"
                    >
                      {planData!=null && (planData && planData.map((value,index)=>(
                      <>
                      {value.slug=='dedicated-cpu'?(<>
                        <tr class="planseffect tick-parent" id={"tick_parent_"+value.id}>
                      <td class="ps-0 text-center">
                      <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_"+value.id}></span>
                      </td>
                      <td class="ps-0 text-center">{(value.ram/1024).toFixed(0)} GB</td> 
                      <td>{value.cpu} vCPU</td> 
                      <td>{value.disk} GB</td> 
                      <td>{value.bandwidth} GB</td> 
                      <td> 
                      {user_data.current!=null?
                        (props.cloudData.billingcycle=='hourly'?(<>Hourly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)/720).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='monthly'?(<>Monthly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*1)-((parseInt(value.price)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='3month'?(<>3 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*3)-((parseInt(value.price*3)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='6month'?(<>6 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*6)-((parseInt(value.price*6)*10)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='12month'?(<>12 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*12)-((parseInt(value.price*12)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='24month'?(<>24 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*24)-((parseInt(value.price*24)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='36month'?(<>36 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*36)-((parseInt(value.price*36)*25)/100)).toFixed(2)}</>):
                        (''))))))
                        )):('')} 
                      </td>
                      <td>
                         <a onClick={()=>show_plan_price(value.id,"ramcpu")} id={"change_plan_"+value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                      </td>
                    </tr>
                    </>):('')}
                      </>  
                      )))}


                    {diskPlanData!=null && (diskPlanData && diskPlanData.map((value,index)=>(
                      <>
                      {value.slug=='dedicated-cpu'?(<>
                        <tr class="planseffect tick-parent" id={"tick_parent_"+value.id}>
                      <td class="ps-0 text-center">
                      <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_"+value.id}></span>
                      </td>
                      <td class="ps-0 text-center">{(value.ram/1024).toFixed(0)} GB</td> 
                      <td>{value.cpu} vCPU</td> 
                      <td>{value.disk} GB</td> 
                      <td>{value.bandwidth} GB</td> 
                      <td> 
                      {user_data.current!=null?
                        (props.cloudData.billingcycle=='hourly'?(<>Hourly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)/720).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='monthly'?(<>Monthly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*1)-((parseInt(value.price)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='3month'?(<>3 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*3)-((parseInt(value.price*3)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='6month'?(<>6 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*6)-((parseInt(value.price*6)*10)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='12month'?(<>12 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*12)-((parseInt(value.price*12)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='24month'?(<>24 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*24)-((parseInt(value.price*24)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='36month'?(<>36 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*36)-((parseInt(value.price*36)*25)/100)).toFixed(2)}</>):
                        (''))))))
                        )):('')} 
                      </td>
                      <td>
                         <a onClick={()=>show_plan_price(value.id,"full")} id={"change_plan_"+value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                      </td>
                    </tr>
                    </>):('')}
                      </>  
                      )))}

                    </tbody>
                    {/*end::Tbody*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Radio group*/}
              {/*begin::Card footer*/}
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <a
                 onClick={resizeCloud}
                 className="btn btn-primary fw-bold"
                >
                  Resize Cloud Server
                </a>
              </div>
              {/*end::Card footer*/} 
          </div>
          {/*end::Tap pane*/}
          {/*begin::Tap pane*/}
          <div className="tab-pane fade" id="dedicated-cpu-tab">
            {/*begin::Radio group*/}
            
              <div className="row" data-kt-buttons="true">
                <div className="table-responsive" id="ramcpuplans">
                  {/*begin::Table*/}
                  <table className="table table-row-bordered table-flush align-middle gy-6 tableramcpu">
                    {/*begin::Thead*/}
                    <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr>
                        <th className="min-w-55px ps-0 text-center" />
                        <th className="min-w-125px px-0 text-center">RAM</th>
                        <th className="min-w-125px">vCPU</th>
                        <th className="min-w-125px">SSD Disk</th>
                        <th className="min-w-125px">Bandwidth</th>
                        <th className="min-w-125px ps-0 text-center">Price</th>
                        <th className="min-w-125px ps-0 text-center" />
                      </tr>
                    </thead>
                    {/*end::Thead*/}
                    {/*begin::Tbody*/}
                    <tbody
                      id="cpu-plan"
                      className="fs-6 fw-bold text-gray-600"
                    >
                      {planData!=null && (planData && planData.map((value,index)=>(
                      <>
                      {value.slug=='dedicated-memory'?(<>
                        <tr class="planseffect tick-parent" id={"tick_parent_"+value.id}>
                      <td class="ps-0 text-center">
                      <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_"+value.id}></span>
                      </td>
                      <td class="ps-0 text-center">{(value.ram/1024).toFixed(0)} GB</td> 
                      <td>{value.cpu} vCPU</td> 
                      <td>{value.disk} GB</td> 
                      <td>{value.bandwidth} GB</td> 
                      <td> 
                      {user_data.current!=null?
                        (props.cloudData.billingcycle=='hourly'?(<>Hourly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)/720).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='monthly'?(<>Monthly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*1)-((parseInt(value.price)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='3month'?(<>3 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*3)-((parseInt(value.price*3)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='6month'?(<>6 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*6)-((parseInt(value.price*6)*10)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='12month'?(<>12 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*12)-((parseInt(value.price*12)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='24month'?(<>24 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*24)-((parseInt(value.price*24)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='36month'?(<>36 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*36)-((parseInt(value.price*36)*25)/100)).toFixed(2)}</>):
                        (''))))))
                        )):('')} 
                      </td>
                      <td>
                         <a onClick={()=>show_plan_price(value.id,"ramcpu")} id={"change_plan_"+value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                      </td>
                    </tr>
                    </>):('')}
                      </>  
                      )))}


                   {diskPlanData!=null && (diskPlanData && diskPlanData.map((value,index)=>(
                      <>
                      {value.slug=='dedicated-memory'?(<>
                        <tr class="planseffect tick-parent" id={"tick_parent_"+value.id}>
                      <td class="ps-0 text-center">
                      <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_"+value.id}></span>
                      </td>
                      <td class="ps-0 text-center">{(value.ram/1024).toFixed(0)} GB</td> 
                      <td>{value.cpu} vCPU</td> 
                      <td>{value.disk} GB</td> 
                      <td>{value.bandwidth} GB</td> 
                      <td> 
                      {user_data.current!=null?
                        (props.cloudData.billingcycle=='hourly'?(<>Hourly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)/720).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='monthly'?(<>Monthly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*1)-((parseInt(value.price)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='3month'?(<>3 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*3)-((parseInt(value.price*3)*5)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='6month'?(<>6 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*6)-((parseInt(value.price*6)*10)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='12month'?(<>12 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*12)-((parseInt(value.price*12)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='24month'?(<>24 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*24)-((parseInt(value.price*24)*25)/100)).toFixed(2)}</>):
                        (props.cloudData.billingcycle=='36month'?(<>36 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price*36)-((parseInt(value.price*36)*25)/100)).toFixed(2)}</>):
                        (''))))))
                        )):('')} 
                      </td>
                      <td>
                         <a onClick={()=>show_plan_price(value.id,"full")} id={"change_plan_"+value.id} class="btn btn-sm btn-primary fw-bold">Select</a>
                      </td>
                    </tr>
                    </>):('')}
                      </>  
                      )))}

                    </tbody>
                    {/*end::Tbody*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Radio group*/}
              {/*begin::Card footer*/}
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <a
                 onClick={resizeCloud}
                 className="btn btn-primary fw-bold"
                >
                  Resize Cloud Server
                </a>
              </div>
              {/*end::Card footer*/} 
          </div>
          {/*end::Tap pane*/}
        </div>
        {/*end::Tab Content*/}
      </div>
      {/*end: Card Body*/}
    </div>
    {pageStatus &&
        <TopBarHeaderLine />
      }
        </>
    )
}

const mapStateToProps=state=>({
  cloudData:state.cloud.cloudDataInfo
})

const mapDispatchToProps=dispatch=>({
  updateCloudStatus:data=>dispatch(IsCloudUpdate(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(Resize) 
 
