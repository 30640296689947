import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";

export default function LoadBalancer(){
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess]=useState(true); 
  const [perPage, setPerPage]=useState(5);
  const [loadBalancerData,setLoadBalancerData]=useState(null);
  const dataFetchedRef=useRef (false);
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }
  const getLoadBalancer=async()=>{ 
    const dt=toast 
       const data=await UseApi('get','loadbalancer','');
       if(data){
        setDataFetched(true);
       }
       if('status' in data){
        if(data.status=="error"){ 
           toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });  
          setAccess(false);
          setLoadBalancerData(null); 
          loaderFunc(); 
          return;
        }
     }
       if(Object.keys(data).length === 0){
        setLoadBalancerData(null);
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            }); 
            loaderFunc();
       }else{
        setLoadBalancerData(data.loadbalancers);  
        toast.dismiss();
          //   toast.update(dt,{render: "Your Loadbalancers request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          // });  
          
       }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getLoadBalancer(); 
  },[]);
  
  function ProductInfo({ productdata }) { 
    console.log(productdata,";kji");
    return(
        <>
        
        {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((value, index) => (
          <tr>
            <td />{" "}
            <td className="p-3">
              {" "}
              <div className="d-flex justify-content-start flex-column">
                <a
                  
                  className="text-dark  fw-bolder text-hover-primary fs-6"
                >
                  {value.name}
                </a>
                <span className="text-dark  fw-bold text-hover-primary fs-7">
                  Location: {value.city} / Backends: {value.backendcount}
                </span>
              </div>{" "}
            </td>{" "}
            <td className="text-end">
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className="d-flex flex-stack mb-2">
                  {" "}
                  <span className=" me-2 fs-7 fw-bold">
                    {value.ip}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>
            <td>
              {" "}
              <div className="d-flex align-items-center">
                {" "}
                <div className="symbol symbol-30px me-5">
                  {" "}
                  <img src={"/img/flag_"+value.cc+".png"} />{" "}
                </div>{" "}
                <div className="d-flex justify-content-start flex-column">
                  {" "}
                  <a
                    
                    className="text-dark  fw-bold text-hover-primary fs-6"
                  >
                    {value.city}
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </td>
            <td className="text-center">
              {" "}
              <span className="badge badge-secondary badge-lg">{value.backendcount}</span>{" "}
            </td>
            <td className="text-center">
              {" "}
              <div className="d-flex justify-content-start flex-column">
                {" "}
                <span className=" me-2 fs-7 fw-bold">
                  {value.created_at}
                </span>{" "}
              </div>
            </td>{" "}
            <td className="text-center">
              {" "}
              <div className="d-flex justify-content-end flex-shrink-0">
                {" "}
                <Link to={"/loadbalancer/"+value.id+""}>
                <a  
                  className="btn  btn-primary btn-active-light-primary btn-sm me-1 me-5 "
                >
                  {" "}
                  Manage{" "}
                  <span className="svg-icon ms-2 svg-icon-3">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      {" "}
                      <rect
                        opacity="0.5"
                        x={18}
                        y={13}
                        width={13}
                        height={2}
                        rx={1}
                        transform="rotate(-180 18 13)"
                        fill="black"
                      />{" "}
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="black"
                      />{" "}
                    </svg>{" "}
                  </span>{" "}
                </a>{" "}
                </Link>
              </div>{" "}
            </td>
          </tr>
          ))):( <>
           <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
            </>)}
            
        </>
    )
}


const [searchTerm,setSearchTerm]=useState('');
function PaginatedItems({ itemsPerPage, ProductData, search }) {
  // We start with an empty list of items. 
  const [pageCount, setPageCount] = useState(0);
  const [vpcData,setVpcData]=useState(null);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    
    const endOffset = itemOffset + itemsPerPage;


  //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
     if(ProductData!=null){
    //   var str="Noida";
   // ////console.log(ProductData);
    const data= ProductData.filter(row => {
     return  Object.values(row).some(value => { 
        if(value!=null){
          if(value.toString().toLowerCase().includes(search.toLowerCase())){
            return value;
          } 
        }
      });
    });
    //////console.log(data);
      // const data=ProductData.filter(item => 
      //        item.hostname.toLowerCase().includes(search.toLowerCase())
      //              ); 

                   if(data.length<=0){
                    loaderFunc();
                   }
    setVpcData(data.slice(itemOffset, endOffset));
    //setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
    // alert(pageCount);
      }
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  
  const handlePageClick = (event) => { 
    const newOffset = event.selected * itemsPerPage % ProductData.length;
    ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
   
  };
  var searchEle=document.querySelector("#searchClient");
  if(searchEle){
    searchEle.focus();
  }
  return (
    <>
      <ProductInfo productdata={vpcData} />
      <td colspan="12">
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      </td>
    </>
  );
}
if(dataFetched){
  if(access==false){
    return (
      <>
       <AccessDenied />
      </>
    );
  }
if(loadBalancerData!=null){
  if(Object.keys(loadBalancerData).length!=0){
return(
  <> 
  <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label  fw-bold fs-3 mb-1">
          {" "}
          Load Balancer Management{" "}
          {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
          <span className="svg-icon svg-icon-2">
            <i className="bi bi-diagram-3 " style={{ fontSize: 20 }} />
          </span>
          {/*end::Svg Icon*/}
        </span>
      </h3>
      <div
        className="card-toolbar" 
      >
        <div className="position-relative my-1" style={{ marginRight: 20 }}>
          <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
        <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select  
        onChange={e => setPerPage(e.target.value)}
        //data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={5}
        id="perPage"
        
      >
        <option value={5} selected="">
          5 Records
        </option>
        <option value={10}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
  </div>
</div>
       <Link to="/loadbalancer/deploy">
        <a 
          
          className="btn btn-sm btn-active-light-primary btn-primary"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
          <span className="svg-icon svg-icon-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-90 11.364 20.364)"
                fill="black"
              />
              <rect
                x="4.36396"
                y="11.364"
                width={16}
                height={2}
                rx={1}
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}Add Load Balancer
        </a>
        </Link>
      </div>
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    {/*begin::Table container*/}
    <div className="table-responsive">
      {/*begin::Table*/}
      <table
        id="loadbalancer"
        className="table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className=" fw-bold ">
            <th className="  min-w-20px p-3" />
            <th className="  min-w-250px p-3">#LB</th>
            <th className=" min-w-200px">IP Address</th>
            <th className=" min-w-150px">Location</th>
            <th className=" text-center min-w-150px">Backends</th>
            <th className=" text-center min-w-150px">Created</th>
            <th className="min-w-200px">Action</th>
          </tr>
        </thead>
        {/*end::Table head*/}
        {/*begin::Table body*/}
        <tbody> 
  {loadBalancerData!=null?(<>
<PaginatedItems itemsPerPage={perPage} ProductData={loadBalancerData} search={searchTerm}  />
  </>):(<>
    <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
  </>)}
  </tbody>
        {/*end::Table body*/}
      </table>
      {/*end::Table*/}
    </div>
    {/*end::Table container*/}
    {/*begin::Body*/}
  </div>
  {/*end::Tables Widget 9*/}
</div>
  </>
)
} else{
  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          {/*begin::Card body*/}
          <div className="card-body p-0 mt-10">
            {/*begin::Illustration*/}
            <div className="text-center px-4 mt-10">
              <span className="mw-100 mh-300px">
              <svg  
              width="234px"
              height="234px" 
              fill="#21325B"
              viewBox="0 0 32 32"
              id="icon"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#21325B"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
              <g id="SVGRepo_iconCarrier">
                {" "}
                <defs>
                  {" "}
                  <style
                    dangerouslySetInnerHTML={{ __html: " .cls-1 { fill: none; } " }}
                  />{" "}
                </defs>{" "}
                <path d="M8,30H2V24H8ZM4,28H6V26H4Z" transform="translate(0 0)" />{" "}
                <path d="M19,30H13V24h6Zm-4-2h2V26H15Z" transform="translate(0 0)" />{" "}
                <path d="M30,30H24V24h6Zm-4-2h2V26H26Z" transform="translate(0 0)" />{" "}
                <path
                  d="M16,10a4,4,0,1,1,4-4A4.0045,4.0045,0,0,1,16,10Zm0-6a2,2,0,1,0,2,2A2.002,2.002,0,0,0,16,4Z"
                  transform="translate(0 0)"
                />{" "}
                <path
                  d="M26,16H21a4.0045,4.0045,0,0,1-4-4H15a4.0045,4.0045,0,0,1-4,4H6a2.0023,2.0023,0,0,0-2,2v4H6V18h5A5.9694,5.9694,0,0,0,15,16.46V22h2V16.46A5.9694,5.9694,0,0,0,21,18h5v4h2V18A2.0023,2.0023,0,0,0,26,16Z"
                  transform="translate(0 0)"
                />{" "} 
              </g>
            </svg> 
              </span>
            </div>
            {/*end::Illustration*/}
            {/*begin::Wrapper*/}
            <div className="card-px text-center py-5 mb-20">
              {/*begin::Title*/}
              <h1 className="fs-2x fw-bolder mb-5">LoadBalancer</h1>
              <h2 className="fs-1x fw-bolder mb-5 text-muted">
              Distribute your web traffic evenly
              </h2>
              {/*end::Title*/}
              {/*begin::Description*/}
              <p className="text-gray-400 fs-4 fw-bold mb-10">
              Ensure a seamless experience for your users even during high traffic peaks.              </p>
              {/*end::Description*/}
              {/*begin::Action*/}
              <Link to="/loadbalancer/deploy">
              <a
                href="#"
                className="btn btn-primary mb-10"
              >
                Create LoadBalancer
              </a>
              </Link>
              {/*end::Action*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Card body*/}
        </div>
      </div>
    </>
  );
}
}
}else{
return (
<>
<TopBarHeader/>
{/* <div class="loading-spinner2"></div> */}
</>
);
}
}