import axios from 'axios';
import Cookies from 'js-cookie';
import localStorage from 'local-storage'; 

export default async function CallAppinfo(){ 
       await axios({
        method: "get",
        url:'https://api.utho.com/v2/appinfo'
    }).then(async function(response){  
      localStorage.set("appInfo",response.data); 
      return true; 
    }) 
}

