import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../CustomHooks/usePost";
import swal from "sweetalert"; 
function useForceUpdate(){
  const [value, setValue] = useState(0);  
  return () => setValue(value => value + 1); 
}
export default function ManageFirewall(){ 
  const forceUpdate = useForceUpdate();
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4];
  const navigate=useNavigate();
  const [firewallData,setFirewallData]=useState(null);
  const [serverForFw,setServerForFw]=useState(null);
  const [incomingOption, setIncomingOption]=useState([<option value='tcp'>TCP</option>]);
  const [outgoingOption, setOutgoingOption]=useState([<option value='tcp'>TCP</option>]);
  const dataFetchedRef=useRef (false);
  const navRedirect=()=>{
    setTimeout(() => {
      navigate("/firewall/"+id);
     }, 3000);
      
  }
  const getFirewall=async()=>{
   
       const data=await UseApi('get','firewall/'+id,'');
       if(data.firewalls.length <= 0){ 
        setFirewallData(null);
       }else{
        setFirewallData(data.firewalls[0]);  
          //   toast.update(dt,{render: "Your Firewall info listed below", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          // });  
          // //console.log(data.firewalls[0]);
       }
  }
  const getServerList=async()=>{ 
       const data=await UseApi('get','forfirewall','');
       if(Object.keys(data).length === 0){
        setServerForFw(null); 
       }else{
        setServerForFw(data.serversforfw);  
        //console.log(data.serversforfw);  
       }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
       toast.dismiss();
      getFirewall(); 
      getServerList();
      
  },[]); 
 ////console.log(firewallData);


  const addIncomingFirewall=async()=>{
    toast.dismiss();
    const dtincoming=toast.loading("Please wait...");
    const responseData=await UsePost('post','firewall/'+id+'/rule/add','addincomingrule');
      if(responseData.status==='error'){
        toast.update(dtincoming,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else{
        toast.update(dtincoming,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      forceUpdate();
      getFirewall();
      navRedirect();
        
      }
     
  }
  const addOutgoingFirewall=async()=>{
    toast.dismiss();
    const dtoutgoing=toast.loading("Please wait...");
    const responseData=await UsePost('post','firewall/'+id+'/rule/add','addoutgoingrule');
      if(responseData.status==='error'){
        toast.update(dtoutgoing,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else{
        toast.update(dtoutgoing,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      forceUpdate();
      getFirewall();
      navRedirect();
        
      }
     
  }
  const addFirewallServer=async()=>{
    toast.dismiss();
    const dtServer=toast.loading("Please wait...");
    const serverField=document.querySelector('#select_server'); 
if(serverField.value!='0'){ 
    const responseData=await UsePost('post','firewall/'+id+'/server/add','addserver');
    //console.log(responseData); 
    if(responseData){
      if(responseData.status==='error'){ 
        toast.update(dtServer,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else{ 
        toast.update(dtServer,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });   
      forceUpdate();
      getFirewall();
      navRedirect();
        
      }
    }else{
      toast.update(dtServer,{render: "Sorry we unable to find this server or you don't have access!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      }); 
    }
    }else{
      toast.update(dtServer,{render: "Sorry we unable to find this server or you don't have access!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });   
    }
  
  }

  const destroyFirewall=async()=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this Firewall!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("Firewall deleting..."); 
    const dnsp=await UseApi('DELETE','firewall/'+id+'/destroy','');
    //console.log(dnsp);
    if(dnsp.status=='error'){
      toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else if(dnsp.status=='success'){
      toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    }); 
    forceUpdate();
    getFirewall();
    navigate('/firewall');
    }
      }else {
        swal({
            title: "Firewall",
            text: "Your Firewall is safe!",
            icon: "success",
            button: "OK",
          });
      }
      });
  }

  const deleteFirewallRule=async(fid)=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this Incoming firewall rule!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("firewall rule deleting..."); 
    const dnsp=await UseApi('DELETE','firewall/'+id+'/rule/'+fid+'/delete','');
    //console.log(dnsp);
    if(dnsp.status=='error'){
      toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else if(dnsp.status=='success'){
      toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    }); 
    forceUpdate();
    getFirewall();
    navRedirect();
    }
      }else {
        swal({
            title: "Firewall",
            text: "Your Firewall rule is safe!",
            icon: "success",
            button: "OK",
          });
      }
      });
  }
 
  const deleteFirewallServers=async(fid)=>{
    toast.dismiss();
    swal({
      title:"Are you sure?",
      text: "Once deleted, you will not be able to recover this firewall server!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("firewall server deleting..."); 
    const dnsp=await UseApi('DELETE','firewall/'+id+'/server/'+fid+'/delete','');
    //console.log(dnsp);
    if(dnsp.status=='error'){
      toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
    }else if(dnsp.status=='success'){
      toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    }); 
    forceUpdate();
    getFirewall();
    navRedirect();
    }
      }else {
        swal({
            title: "Firewall",
            text: "Your Firewall server is safe!",
            icon: "success",
            button: "OK",
          });
      }
      });
  }
  
  const setFirewallRule=(type)=>{
    toast.dismiss();
    if(type=='incoming'){ 
      const serviceType=document.querySelector('#service_incoming');
      const protocol_incoming=document.querySelector('#protocol_incoming');
      const port_tincoming=document.querySelector('#port_tincoming'); 
        
      if (serviceType.value === "SSH") {
        setIncomingOption([<option value='tcp'>TCP</option>]);
        protocol_incoming.innerHtml="<option value='tcp'>TCP</option>";
                 port_tincoming.value="22";

     }
         else if (serviceType.value == "RDP") { 
          setIncomingOption([<option value='tcp'>TCP</option>]);
             protocol_incoming.innerHtml="<option value='tcp'>TCP</option>";
                 port_tincoming.value="3389"; 

     }
         else if (serviceType.value === "MYSQL") {
          setIncomingOption([<option value='tcp'>TCP</option>]);
                protocol_incoming.innerHtml="<option value='tcp'>TCP</option>";
                 port_tincoming.value="3306";

     }
         else if (serviceType.value === "MSSQL") {
          setIncomingOption([<option value='tcp'>TCP</option>]);
             protocol_incoming.innerHtml="<option value='tcp'>TCP</option>";
             port_tincoming.value="1433";

     }
           else if (serviceType.value === "HTTP") {
            setIncomingOption([<option value='tcp'>TCP</option>]);
             protocol_incoming.innerHtml="<option value='tcp'>TCP</option>";
             port_tincoming.value="80";
     }
         else if (serviceType.value === "HTTPS") {
          setIncomingOption([<option value='tcp'>TCP</option>]);
        protocol_incoming.innerHtml="<option value='tcp'>TCP</option>";
             port_tincoming.value="443";

     }
         else if (serviceType.value === "DNS TCP") {
          setIncomingOption([<option value='tcp'>TCP</option>]);
           protocol_incoming.innerHtml="<option value='tcp'>TCP</option>";
             port_tincoming.value="53";

     }
         else if (serviceType.value == "DNS UDP") { 
          setIncomingOption([<option value='udp'>UDP</option>]);
             protocol_incoming.innerHtml="<option value='udp'>UDP</option>";
             port_tincoming.value="53";

     }
         else if (serviceType.value === "ALL TCP") {
          setIncomingOption([<option value='tcp'>TCP</option>]);
           protocol_incoming.innerHtml="<option value='tcp'>TCP</option>";
             port_tincoming.value="ALL";
     }
         else if (serviceType.value === "ALL UDP") {
          setIncomingOption([<option value='udp'>UDP</option>]);
         protocol_incoming.innerHtml="<option value='udp'>UDP</option>";
             port_tincoming.value="ALL";

     }
         else if (serviceType.value === "PING") {
          setIncomingOption([<option value='ICMP'>ICMP</option>]);
         protocol_incoming.innerHtml="<option value='ICMP'>ICMP</option>";
               //	$("#incomingportru").val("ICMP");
                   port_tincoming.value="PING";
     }
         else if (serviceType.value === "CUSTOM") {
          setIncomingOption([<option value='tcp'>TCP</option>,<option value='udp'>UDP</option>]);
            protocol_incoming.innerHtml="<option value='tcp'>TCP</option><option value='udp'>UDP</option>";
             port_tincoming.value="ALL";

     }
    }
    if(type=='outgoing'){
      const serviceType=document.querySelector('#service_outgoing');
      const protocol_outgoing=document.querySelector('#protocol_outgoing');
      const port_toutgoing=document.querySelector('#port_toutgoing');

      if (serviceType.value === "SSH") {
        setOutgoingOption([<option value='tcp'>TCP</option>]);
        protocol_outgoing.innerHtml="<option value='tcp'>TCP</option>";
        port_toutgoing.value="22";

     }
         else if (serviceType.value === "RDP") {
          setOutgoingOption([<option value='tcp'>TCP</option>]);
          protocol_outgoing.innerHtml="<option value='tcp'>TCP</option>";
          port_toutgoing.value="3389";

     }
         else if (serviceType.value === "MYSQL") {
          setOutgoingOption([<option value='tcp'>TCP</option>]);
          protocol_outgoing.innerHtml="<option value='tcp'>TCP</option>";
          port_toutgoing.value="3306";

     }
         else if (serviceType.value === "MSSQL") {
          setOutgoingOption([<option value='tcp'>TCP</option>]);
          protocol_outgoing.innerHtml="<option value='tcp'>TCP</option>";
          port_toutgoing.value="1433";

     }
           else if (serviceType.value === "HTTP") {
            setOutgoingOption([<option value='tcp'>TCP</option>]);
            protocol_outgoing.innerHtml="<option value='tcp'>TCP</option>";
            port_toutgoing.value="80";
     }
         else if (serviceType.value === "HTTPS") {
          setOutgoingOption([<option value='tcp'>TCP</option>]);
          protocol_outgoing.innerHtml="<option value='tcp'>TCP</option>";
          port_toutgoing.value="443";

     }
         else if (serviceType.value === "DNS TCP") {
          setOutgoingOption([<option value='tcp'>TCP</option>]);
          protocol_outgoing.innerHtml="<option value='tcp'>TCP</option>";
          port_toutgoing.value="53";

     }
         else if (serviceType.value === "DNS UDP") {
          setOutgoingOption([<option value='udp'>UDP</option>]);
          protocol_outgoing.innerHtml='<option value="udp">UDP</option>';
          port_toutgoing.value="53";

     }
         else if (serviceType.value === "ALL TCP") {
          setOutgoingOption([<option value='tcp'>TCP</option>]);
          protocol_outgoing.innerHtml="<option value='tcp'>TCP</option>";
          port_toutgoing.value="ALL";
     }
         else if (serviceType.value === "ALL UDP") {
          setOutgoingOption([<option value='udp'>UDP</option>]);
          protocol_outgoing.innerHtml="<option value='udp'>UDP</option>";
          port_toutgoing.value="ALL";

     }
         else if (serviceType.value === "PING") {
          setOutgoingOption([<option value='ICMP'>ICMP</option>]);
          protocol_outgoing.innerHtml="<option value='ICMP'>ICMP</option>"; 
               port_toutgoing.value="PING";
     }
         else if (serviceType.value === "CUSTOM") {
          setOutgoingOption([<option value='tcp'>TCP</option>,<option value='udp'>UDP</option>]);
          protocol_outgoing.innerHtml="<option value='tcp'>TCP</option><option value='udp'>UDP</option>";
          port_toutgoing.value="ALL";

     }
    }
  }
    return(
        <>
        {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
        {firewallData!=null?(<>
        <div className="col-xl-12">
  {/*begin::Tables Widget 5*/}
  <div className="card card-xxl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5">
      
      <h3 className="card-title align-items-start flex-column">
        <span className=" card-label fw-bold fs-3 mb-1">
          Firewall Management:{" "}
          <span
            className="badge badge-success mt-1 fw-bold fs-7"
            id="firewall_name"
          >
            {firewallData!=null && firewallData.name?(<>{firewallData.name}</>):('')}
          </span>
        </span>
      </h3>
      <div className="card-toolbar">
        <ul className="nav">
          <li className="nav-item">
            <a
              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold px-4 me-1 active"
              data-bs-toggle="tab"
              href="#rule_tab_1"
            >
              Rules
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold px-4 me-1 "
              data-bs-toggle="tab"
              href="#server_tab_2"
            >
              Servers
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold px-4"
              data-bs-toggle="tab"
              href="#destroy_tab_3"
            >
              Destroy
            </a>
          </li>
        </ul>
      </div>
    </div>
    {/*end::Header*/}
    {/*begin::incoming rules*/}
    <hr />
    <div className="card-body py-3">
      <div className="tab-content">
        {/*begin::Tap pane*/}
        <div className="tab-pane fade show active" id="rule_tab_1">
          <i className="bi bi-arrow-down-left-square-fill fs-2x text-success" />
          <span className="card-label fw-bold fs-3 mb-1">Incoming Rules</span>
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
              {/*begin::Table head*/}
              <thead>
                <tr className="fw-bold">
                  <th className="min-w-100px">Service</th>
                  <th className="min-w-100px">Protocol</th>
                  <th className="min-w-100px">Port Range</th>
                  <th className="min-w-100px">Sources</th>
                  <th className="min-w-100px d-none" />
                  <th className="min-w-100px " />
                </tr>
              </thead>
              {/*end::Table head*/}
              <tbody id="incoming_rules_list">
                {firewallData!=null && (firewallData.rules && firewallData.rules.map((value,index)=>(
                  <>
                  {value.type=='incoming'?(
                <tr>
                  {" "}
                  <td className="fw-bold">
                    {" "}
                    <div className="symbol symbol-45px me-2"> {value.service} </div>{" "}
                  </td>{" "}
                  <td className="fw-bold">
                    {" "}
                    <div className="symbol symbol-45px me-2"> {value.protocol} </div>{" "}
                  </td>{" "}
                  <td className="fw-bold">
                    {" "}
                    <div className="position-relative me-md-2"> {value.port} </div>{" "}
                  </td>{" "}
                  <td className="fw-bold">
                    {" "}
                    <div className="position-relative me-md-2"> {value.addresses=='0'?('ALL'):(value.addresses)} </div>{" "}
                  </td>{" "}
                  <td className="d-none">
                    <a 
                      className="btn btn-success btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#update-incoming"
                      onclick="updater_incoming_rules('SSH','TCP','22','ALL')"
                    >
                      Update
                    </a>{" "}
                  </td>
                  <td className="text-center">
                    {" "}
                    <a 
                      className="btn btn-danger btn-sm"
                       onClick={()=>deleteFirewallRule(value.id)}
                       >
                      Delete
                    </a>
                  </td>
                </tr>
                ):('')}
                </>
                )))}
              </tbody>
            </table>
            {/*begin::Table body*/}
            <form id="addincomingrule" name="addincomingrule">
              <input
                type="hidden"
                id="type"
                name="type"
                defaultValue="incoming"
              />
              <table className="table table-striped table-rounded table-row-dashed border gy-7 gs-7">
                <tbody>
                  <tr>
                    <td>
                      <div className="symbol symbol-45px me-2">
                        <select
                          className="form-select"
                          name="service"
                          id="service_incoming" 
                          onChange={()=>setFirewallRule("incoming")}
                        >
                          <option value="SSH">SSH</option>
                          <option value="RDP">RDP</option>
                          <option value="HTTP">HTTP</option>
                          <option value="HTTPS">HTTPS</option>
                          <option value="MYSQL">MYSQL</option>
                          <option value="MSSQL">MSSQL</option>
                          <option value="DNS TCP">DNS TCP</option>
                          <option value="DNS UDP">DNS UDP</option>
                          <option value="ALL TCP">ALL TCP</option>
                          <option value="ALL UDP">ALL UDP</option>
                          <option value="PING">PING</option>
                          <option value="CUSTOM">CUSTOM</option>
                        </select>
                         
                      </div>
                    </td>
                    {/* <td>
                      <div className="symbol symbol-45px me-2">
                        <select
                          className="form-select form-solid-select"
                          name="protocol"
                          id="protocol_incoming"
                        >
                          <option value="tcp">TCP</option>
                          
                        </select>
                      </div>
                    </td> */}
                     <td>
                      <div className="symbol symbol-45px me-2">
                        <select
                          className="form-select"
                          name="protocol" 
                          id="protocol_incoming"  
                        > 
                        {incomingOption.length>0?(<>
                        {incomingOption.map((value,index)=>(
                          <>{value}</>
                        ))}
                        </>):(<></>)}
                          {/* <option value='tcp'>TCP</option>
                          <option value='udp'>UDP</option>
                          <option value='ICMP'>ICMP</option> */}
                        </select>
                         
                      </div>
                    </td>
                    <td className="text-end text-muted fw-bold">
                      <div
                        className="position-relative w-md-200px me-md-2"
                        id="port_incoming"
                      >
                        <input
                          type="text"
                          className="form-control "
                          id="port_tincoming"
                          name="port"
                          minvalue={1}
                          maxvalue={65444}
                          placeholder="22 OR 5001:5003"
                          defaultValue={22}
                        />
                      </div>
                    </td>
                    <td className="text-end">
                      <div className="position-relative w-md-200px me-md-2">
                        <input
                          type="text"
                          className="form-control "
                          id="addresses"
                          name="addresses"
                          placeholder="0.0.0.0"
                          defaultValue="ALL"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="symbol symbol-45px me-2"></div>
                    </td>
                    <td className="text-center">
                      <a 
                        className="btn btn-primary button"
                         onClick={addIncomingFirewall}
                         >
                        Add New
                      </a>
                    </td>
                  </tr>
                </tbody>
                {/*end::Table body*/}
              </table>
            </form>
          </div>
          <hr />
          <i className="bi bi-arrow-up-right-square-fill fs-2x text-danger" />
          <span className="card-label fw-bold fs-3 mb-1">Outgoing Rules</span>
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table className="table table-striped table-rounded table-striped border gy-7 gs-7">
              {/*begin::Table head*/}
              <thead>
                <tr className="fw-bold">
                  <th className="min-w-100px">Service</th>
                  <th className="min-w-100px">Protocol</th>
                  <th className="min-w-100px">Port Range</th>
                  <th className="min-w-100px">Sources</th>
                  <th className="min-w-100px d-none" />
                  <th className="min-w-100px" />
                </tr>
              </thead>
              {/*end::Table head*/}
              <tbody id="outgoing_rules_list">
              {firewallData!=null && (firewallData.rules && firewallData.rules.map((value,index)=>(
                  <>
                  {value.type=='outgoing'?(
                <tr>
                  {" "}
                  <td className="fw-bold">
                    {" "}
                    <div className="symbol symbol-45px me-2"> {value.service} </div>{" "}
                  </td>{" "}
                  <td className="fw-bold">
                    {" "}
                    <div className="symbol symbol-45px me-2"> {value.protocol} </div>{" "}
                  </td>{" "}
                  <td className="fw-bold">
                    {" "}
                    <div className="position-relative me-md-2"> {value.port} </div>{" "}
                  </td>{" "}
                  <td className="fw-bold">
                    {" "}
                    <div className="position-relative me-md-2"> {value.addresses=='0'?('ALL'):(value.addresses)} </div>{" "}
                  </td>{" "}
                  <td className="d-none">
                    <a 
                      className="btn btn-success btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#update-incoming"
                      onclick="updater_incoming_rules('SSH','TCP','22','ALL')"
                    >
                      Update
                    </a>{" "}
                  </td>
                  <td className="text-center">
                    {" "}
                    <button
                      className="btn btn-danger btn-sm"
                       onClick={()=>deleteFirewallRule(value.id)}
                       >
                      Delete
                    </button>
                  </td>
                </tr>
                ):('')}
                </>
                )))}
              </tbody>
            </table>
            {/*begin::Table body*/}
            <form id="addoutgoingrule" name="addoutgoingrule">
              <input
                type="hidden"
                id="type"
                name="type"
                defaultValue="outgoing"
              />
              <table className="table table-striped table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                <tbody>
                  <tr>
                    <td>
                      <div className="symbol symbol-45px me-2">
                        <select
                          className="form-select "
                          name="service"
                          id="service_outgoing"
                          onChange={()=>setFirewallRule("outgoing")}
                        >
                          <option value="SSH">SSH</option>
                          <option value="RDP">RDP</option>
                          <option value="HTTP">HTTP</option>
                          <option value="HTTPS">HTTPS</option>
                          <option value="MYSQL">MYSQL</option>
                          <option value="MSSQL">MSSQL</option>
                          <option value="DNS TCP">DNS TCP</option>
                          <option value="DNS UDP">DNS UDP</option>
                          <option value="ALL TCP">ALL TCP</option>
                          <option value="ALL UDP">ALL UDP</option>
                          <option value="PING">PING</option>
                          <option value="CUSTOM">CUSTOM</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className="symbol symbol-45px me-2">
                        <select
                          className="form-select"
                          name="protocol"
                          id="protocol_outgoing"
                        >
                           {outgoingOption.length>0?(<>
                        {outgoingOption.map((value,index)=>(
                          <>{value}</>
                        ))}
                        </>):(<></>)}
                          {/* <option value='tcp'>TCP</option>
                          <option value='udp'>UDP</option>
                          <option value='ICMP'>ICMP</option> */}
                        </select>
                      </div>
                    </td>
                    <td className="text-end text-muted fw-bold">
                      <div
                        className="position-relative w-md-200px me-md-2"
                        id="port_outgoing"
                      >
                        <input
                          type="text"
                          className="form-control"
                          id="port_toutgoing"
                          name="port"
                          minvalue={1}
                          maxvalue={65444}
                          placeholder="22 OR 5001:5003"
                          defaultValue={22}
                        />
                      </div>
                    </td>
                    <td className="text-end">
                      <div className="position-relative w-md-200px me-md-2">
                        <input
                          type="text"
                          className="form-control"
                          id="addresses"
                          name="addresses"
                          placeholder="0.0.0.0"
                          defaultValue="ALL"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="symbol symbol-45px me-2"></div>
                    </td>
                    <td className="text-center">
                      <a 
                        className="btn btn-primary "
                        onClick={addOutgoingFirewall}
                        >
                        + Add New
                      </a>
                    </td>
                  </tr>
                </tbody>
                {/*end::Table body*/}
              </table>
            </form>
          </div>
        </div>
        {/*end::Tap pane*/}
        {/*begin::Tap pane*/}
        <div className="tab-pane fade " id="server_tab_2">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="text-primary card-label fw-bold fs-5 mb-1">
                Add Servers
              </span>
              <span className="text-muted mt-1 fw-bold fs-7">
                You can add multiple servers in your single firewall.
              </span>
              <div id="server_option" />
            </h3>
          </div>
          <form id="addserver">
            {/*begin::Table container*/}
            <div className="d-flex align-items-center">
              {/*begin::Input group*/}
              <div className="symbol symbol-45px me-2">
                <select
                  className="form-select"
                  id="select_server"
                  name="cloudid"
                  aria-label="Select example"
                > <option value={0}>Select Servers or Apps</option>
                  {serverForFw!=null?(serverForFw && serverForFw.map((value,index)=>(
                  <option value={value.cloudid}>{value.hostname+' '+value.ip}</option>
                  ))):('')}
                  
                   
                </select>
              </div>
              {/*end::Input group*/}
              {/*begin:Action*/}
              <div className="d-flex align-items-center">
                <a 
                  className="btn btn-primary me-5"
                  onClick={addFirewallServer}
                  >
                  Add Server
                </a>
              </div>
              {/*end:Action*/}
            </div>
          </form>
          <hr />
          <h3 className="card-title  align-items-start flex-column">
            <span className="text-center card-label fw-bold fs-5 mb-1">
              Firewall Servers
            </span>
          </h3>
          <div className="table-responsive">
            {/*begin::Table*/}
            <table className="table table-striped table-rounded table-striped border gy-7 gs-7">
              {/*begin::Table head*/}
              <thead>
                <tr className="fw-bold">
                  <th className="min-w-300px">Name</th>
                  <th className="min-w-300px">IP</th>
                  <th className="min-w-100px" />
                  <th className="min-w-100px" />
                </tr>
              </thead>
              {/*end::Table head*/}
              <tbody id="firewall-server-add">
              {firewallData!=null && (firewallData.servers && firewallData.servers.map((value,index)=>(<>
                {" "}
                <tr className="">
                  <td className="fw-bold">
                    {" "}
                    <div className="symbol symbol-45px me-2">
                      {" "}
                      {value.name}
                    </div>
                    <br />{" "}
                    <span className="text-muted mt-1 fw-bold fs-9">
                      Location: {value.city}
                    </span>{" "}
                  </td>{" "}
                  <td className="fw-bold">
                    {" "}
                    <div className="symbol symbol-45px me-2">
                      {" "}
                      {value.ip}
                    </div>{" "}
                  </td>{" "}
                  <td className="text-end">
                    {" "}
                    <Link to={"/cloud/"+value.cloudid}>
                    <a 
                      className="btn btn-success btn-sm"
                    >
                      View Cloud{" "}
                    </a>
                    </Link>
                  </td>{" "}
                  <td className="text-end">
                    {" "}
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={()=>deleteFirewallServers(value.cloudid)}
                      >
                      Delete
                    </button>{" "}
                  </td>{" "}
                </tr>{" "}
                </>)))} 
              </tbody>
            </table>
          </div>
        </div>
        {/*end::Tap pane*/}
        {/*begin::Tap pane*/}
        <div className="tab-pane fade" id="destroy_tab_3">
          {/*begin::Deactivate Account*/}
          <div className="">
            {/*begin::Card header*/}
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_deactivate"
              aria-expanded="true"
              aria-controls="kt_account_deactivate"
            >
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Destroy Firewall</h3>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Content*/}
            <div id="kt_account_settings_deactivate" className="collapse show">
              {/*begin::Form*/}
              <form id="destroyform" className="form">
                {/*begin::Card body*/}
                <div className="card-body border-top p-9">
                  {/*begin::Notice*/}
                  <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                    {/*begin::Icon*/}
                    {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                    <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={10}
                          fill="black"
                        />
                        <rect
                          x={11}
                          y={14}
                          width={7}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11 14)"
                          fill="black"
                        />
                        <rect
                          x={11}
                          y={17}
                          width={2}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11 17)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                    {/*end::Icon*/}
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-stack flex-grow-1">
                      {/*begin::Content*/}
                      <div className="fw-bold">
                        <h4 className="text-gray-900 fw-bold">
                          Your Firewall will be destroyed, and any cloud server
                          will be dissociated from them.
                        </h4>
                        <div className="fs-6 text-gray-700">
                          Once this happens, the cloud servers will allow any
                          type of inbound and outbound traffic, unless you’ve
                          configured a software firewall in them, or they still
                          belong to other Firewalls.
                        </div>
                      </div>
                      {/*end::Content*/}
                    </div>
                    {/*end::Wrapper*/}
                  </div>
                  {/*end::Notice*/}
                  {/*begin::Form input row*/}
                  {/*end::Form input row*/}
                </div>
                {/*end::Card body*/}
                {/*begin::Card footer*/}
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <button
                    onClick={destroyFirewall}
                    type="button"
                    className="btn btn-danger fw-bold"
                  >
                    Destroy Firewall
                  </button>
                </div>
                {/*end::Card footer*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Deactivate Account*/}
        </div>
        {/*end::Tap pane*/}
      </div>
    </div>
    {/*end::incoming rules*/}
  </div>
  {/*end::Tables Widget 5*/}
</div>
</>):(<><div class="loading-spinner2"></div></>)}
        </>
    )
}