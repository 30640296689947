import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import UseApi from "../../../../../CustomHooks/useApi";
import UsePost from "../../../../../CustomHooks/usePost";
import { IsCloudUpdate } from "../../../../../Redux/Services/Action/actions";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";
const Firewall=(props)=>{
  const navigate=useNavigate();
  var query = window.location.href;
  var vars = query.split("/");
  var id=vars[4];
  const [firewallData,setFirewallData]=useState(null);
  const [availabelFirewallData,setAvailabelFirewallData]=useState(null);
  const [pageStatus,setPageStatus] = useState(false)
  const dispatch=useDispatch();
  const navRedirect=()=>{
    setTimeout(() => {
      navigate("/cloud/"+id);
     }, 3000);
  }

  useEffect(() => {
    setFirewallData(props.cloudData.firewalls);
  }, [props.cloudData]);

const getFirewall=async()=>{
  const data=await UseApi('get','firewall','');

  if(Object.keys(data).length === 0){
    setAvailabelFirewallData(null);
  }else{
    setAvailabelFirewallData(data.firewalls);

  }
}

useEffect(()=>{
    getFirewall();
},[])

  const deployFirewall=async()=>{
    const dt=toast.loading("Firewall creating, Please wait...");
    toast.dismiss();
    const firewall_name=document.querySelector("#firewall_name");
    if(firewall_name){
    if(firewall_name.value==""){
      toast.update(dt,{render: "Please assign a name to firewall", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
  }else{

    setPageStatus(true)
    const responseData=await UsePost('post','firewall/create','createfirewall');
      if(responseData.status==='error'){
        setPageStatus(false)
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        setPageStatus(false)
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      firewall_type('assign');
      getFirewall();
      navRedirect();
      }
    }
  }
    }

    const addFirewallServer=async()=>{
      toast.dismiss();
      const dtServer=toast.loading("Firewall adding, Please wait...");
      const firewall_id=document.querySelector('#select_server');
  if(firewall_id){
      if(firewall_id.value==""){
      toast.update(dtServer,{render: "Please select a firewall", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
    });
  }else{
    setPageStatus(true)
      const responseData=await UsePost('post','firewall/'+firewall_id.value+'/server/add','addserver');
      // console.log(responseData);
      if(responseData){
        if(responseData.status==='error'){
          setPageStatus(false)
          toast.update(dtServer,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        }else{
          setPageStatus(false)
          toast.update(dtServer,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        const data=await UseApi('get','cloud/'+id,'');
        dispatch(props.updateCloudStatus(data.cloud[0]));
        firewall_type('close');
        navRedirect();
        }
      }
    }
  }
} 
    const firewall_type=async(type)=>{
      var firewall_close=document.querySelector("#firewall_close");
      //console.log(type);
      if(type!="close"){
        if(firewall_close){
          firewall_close.classList.remove("d-none");
        }
       if(type=="assign"){
        document.querySelector("#firewall_op").classList.add("d-none");
        document.querySelector("#firewall_create").classList.add("d-none");
        document.querySelector("#firewall_assign").classList.remove("d-none");
       }
       if(type=="create"){
        //console.log("create hua");
        document.querySelector("#firewall_op").classList.add("d-none");
        document.querySelector("#firewall_create").classList.remove("d-none");
        document.querySelector("#firewall_assign").classList.add("d-none");
       }
       if(type=="both"){
        document.querySelector("#firewall_op").classList.add("d-none");
        document.querySelector("#firewall_create").classList.add("d-none");
        document.querySelector("#firewall_assign").classList.remove("d-none");
       }
      }else{
        if(firewall_close){
          firewall_close.classList.add("d-none");
          document.querySelector("#firewall_op").classList.remove("d-none");
          document.querySelector("#firewall_create").classList.add("d-none");
          document.querySelector("#firewall_assign").classList.add("d-none");
        }
      }

    }
    return(
        <>
        {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
        <div className="card mb-5 mb-xxl-10">
      {/*begin::Header*/}
      <div className="card-header">
        {/*begin::Title*/}
        <div className="card-title">
          <h3>Firewalls</h3>

        </div>
        <div className="card-toolbar">
  <div
    id="firewall_close"
    onClick={(e)=>firewall_type('close')}
    className="btn btn-sm btn-icon btn-active-light-primary d-none"
  >
    <span className="svg-icon svg-icon-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect
          opacity="0.5"
          x={6}
          y="17.3137"
          width={16}
          height={2}
          rx={1}
          transform="rotate(-45 6 17.3137)"
          fill="black"
        />
        <rect
          x="7.41422"
          y={6}
          width={16}
          height={2}
          rx={1}
          transform="rotate(45 7.41422 6)"
          fill="black"
        />
      </svg>
    </span>
  </div>
</div>

        {/*end::Title*/}
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className="card-body py-10">
        {/*begin::Row*/}
        <div className="row mb-10">
          {/*begin::Notice*/}
          <div className="notice  bg-light-primary rounded border-primary border p-6">
          <div id="firewall_assign" className="d-none">
               {/*begin::Wrapper*/}
            <div className=" flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
              <div className="row">
              <div className="col-6">
              {/*begin::Content*/}
              <div className="mb-3 mb-md-0 fw-bold">
                <div className="fs-6 text-gray-700 pe-7">
                 Please assign new firewall to this cloud server.
                </div>
              </div>
              {/*end::Content*/}
              </div>
              <div className="col-3">
              {/*begin::Content*/}
              <div className="mb-3 mb-md-0 fw-bold">
                <div className="fs-6 text-gray-700 pe-7">
                  or
                </div>
              </div>
              {/*end::Content*/}
              </div>
              <div className="col-3">
              {/*begin::Action*/}
              <a
                onClick={()=>firewall_type('create')}
                className="btn btn-primary px-6 align-self-center text-nowrap"
              >
                Create new firewall
              </a>
              {/*end::Action*/}
              </div>
              </div>
            </div>
            {/*end::Wrapper*/}
  {/*begin::Form*/}
  <form id="addserver" className="form">
        {/*begin::Modal body*/}
        {/*begin::Scroll*/}
        {/*begin::Input group*/}
        <div className="modal-body mb-5 fv-row">
          {/*begin::Label*/}
          <label className="required fs-5 fw-bold mb-2">
            {" "}
            Existing Firewalls
          </label>
          {/*end::Label*/}
          {/*begin::Input*/}
          <select
                className="form-select"
                id="select_server"
                defaultValue=""
                 >
           <option value="">Choose firewall</option>
           {availabelFirewallData!=null && (availabelFirewallData && availabelFirewallData.map((value,index)=>(
            <option value={value.id}>{value.name}</option>
          )))}
          </select>
          <input type="hidden" name="cloudid" defaultValue={id} />
          {/*end::Input*/}
          {/*end::Input group*/}
        </div>
        {/*end::Scroll*/}
        {/*begin::Modal footer*/}
        {/*begin::Button*/}
        < a
          style={{ width: "100%", cursor: "pointer" }}
          onClick={addFirewallServer}
          className="btn btn-primary"
        >
          <span className="indicator-label">Assign Firewall</span>
        </a>
        {/*end::Button*/}
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
            </div>
            <div id="firewall_create" className="d-none">
               {/*begin::Wrapper*/}
            <div className=" flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
              <div className="row">
              <div className="col-6">
              {/*begin::Content*/}
              <div className="mb-3 mb-md-0 fw-bold">
                <div className="fs-6 text-gray-700 pe-7">
                 Please create new firewall to this cloud server.
                </div>
              </div>
              {/*end::Content*/}
              </div>
              <div className="col-3">
              {/*begin::Content*/}
              <div className="mb-3 mb-md-0 fw-bold">
                <div className="fs-6 text-gray-700 pe-7">
                  or
                </div>
              </div>
              {/*end::Content*/}
              </div>
              <div className="col-3">
              {/*begin::Action*/}
              <a
                onClick={(e)=>firewall_type('assign')}
                className="btn btn-primary px-6 align-self-center text-nowrap"
              >
                Assign existing firewall
              </a>
              {/*end::Action*/}
              </div>
              </div>
            </div>
            {/*end::Wrapper*/}
  {/*begin::Form*/}
  <form id="createfirewall" className="form">
        {/*begin::Modal body*/}
        {/*begin::Scroll*/}
        {/*begin::Input group*/}
        <div className="modal-body mb-5 fv-row">
          {/*begin::Label*/}
          <label className="required fs-5 fw-bold mb-2">
            {" "}
            New Firewall Name
          </label>
          {/*end::Label*/}
          {/*begin::Input*/}
          <input
            type="text"
            className="form-control"
            placeholder="my_web_firewall"
            name="name"
            defaultValue=""
            id="firewall_name"
          />
          {/*end::Input*/}
          {/*end::Input group*/}
        </div>
        {/*end::Scroll*/}
        {/*begin::Modal footer*/}
        {/*begin::Button*/}
        <button
          type="button"
          style={{ width: "100%" }}
          onClick={deployFirewall}
          className="btn btn-primary"
        >
          <span className="indicator-label">Create Firewall</span>
        </button>
        {/*end::Button*/}
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
            </div>

             <div id="firewall_op">

            {/*begin::Wrapper*/}
            <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
              {/*begin::Content*/}
              <div className="mb-3 mb-md-0 fw-bold">
                <div className="fs-6 text-gray-700 pe-7">
                   {/*begin::Icon*/}
            {/*begin::Svg Icon | path: icons/duotune/finance/fin001.svg*/}
            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z"
                  fill="black"
                />
                <path
                  opacity="0.3"
                  d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z"
                  fill="black"
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            {/*end::Icon*/}

                   Please create new or assign a existing firewall to this cloud server.
                </div>
              </div>
              {/*end::Content*/}
              {/*begin::Action*/}
              <a
                onClick={(e)=>firewall_type('both')}
                className="btn btn-primary px-6 align-self-center text-nowrap"
              >
                Create or assign Firewall
              </a>
              {/*end::Action*/}
            </div>
            {/*end::Wrapper*/}
            </div>
          </div>
          {/*end::Notice*/}
        </div>
        {/*end::Row*/}
      </div>
      {/*end::Body*/}
    </div>
    {/*end::Access*/}
    <div className="separator my-10" />
    {/*begin::License usage*/}
    <div className="card">
      {/*begin::Card header*/}
      <div className="card-header">
        {/*begin::Heading*/}
        <div className="card-title">
          <h3>Current Firewalls </h3>
        </div>
        {/*end::Heading*/}
      </div>
      {/*end::Card header*/}
      {/*begin::Card body*/}
      <div className="card-body p-0">
        {/*begin::Table wrapper*/}
        <div className="table-responsive">
          {/*begin::Table*/}
          <table
            className="table table-striped table-flush align-middle table-row-bordered table-row-solid gy-4"
            id="kt_security_license_usage_table"
          >
            {/*begin::Thead*/}
            <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
              <tr>
                <th className="w-150px ps-9">Firewall Name</th>
                <th className="w-150px">Created At</th>
                <th className="w-150px ps-9" />
              </tr>
            </thead>
            {/*end::Thead*/}
            {/*begin::Tbody*/}
            <tbody id="firewall-list" className="fw-6 fw-bold text-gray-600">
              {firewallData!=null && (firewallData && Object.keys(firewallData).length !=0?(<>{firewallData.map((value,index)=>(
            <tr>
              <td className="ps-9">{value.name}</td>
              <td>{value.created_at}</td>
              <td className="ps-9">
              <Link to={"/firewall/"+value.id}>
                 <a  className="btn btn-sm btn-primary">Manage Firewall</a>
              </Link>
              </td>
            </tr>
              ))}</>):(<>
              <tr>
                    <td colspan="12" >
                      <div className="d-flex flex-column flex-center">
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
                    </>)) }
            </tbody>
            {/*end::Tbody*/}
          </table>
          {/*end::Table*/}
        </div>
        {/*end::Table wrapper*/}
      </div>
      {/*end::Card body*/}
    </div>
    {pageStatus &&
        <TopBarHeaderLine />
      }
        </>
    )
}

const mapStateToProps=state=>({
  cloudData:state.cloud.cloudDataInfo
})

const mapDispatchToProps=dispatch=>({
  updateCloudStatus:data=>dispatch(IsCloudUpdate(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(Firewall)
