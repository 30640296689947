import React, { useState, useEffect} from "react"; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import { connect, useDispatch } from "react-redux"; 
import swal from "sweetalert";   
import { AddCurrentObjectParentid, AddObjectBreadcrumb } from "../../../../../../Redux/Services/Action/actions";
import UsePostData from "../../../../../../CustomHooks/usePostData";
import UseApi from "../../../../../../CustomHooks/useApi";
import UseTicketPost from "../../../../../../CustomHooks/useTicketPost";
import copy from "copy-to-clipboard";
const FileFolder = (props) => {
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[5];  
  const [objectData, setObjectData]= useState(null); 
   
  const copyToClipboard=()=> {   
       
    var eleText = document.querySelector("#file_url");
    var ele = document.querySelector("#file_url_copy_btn");
    if(ele && eleText){
      var copyText=ele.innerHTML;
      var copyEle=eleText.value;
    copy(copyEle);   
    ele.innerHTML="Copied";
    eleText.classList.add("bg-primary");
    eleText.classList.add("text-light");
     setTimeout(() => {
      eleText.classList.remove("bg-primary");
      eleText.classList.remove("text-light");
      ele.innerHTML=copyText;
    }, 2000);
  }
   
  }
  const openDrawerFile=()=>{
    var drawerElement = document.querySelector("#files-modal");
        drawerElement.classList.toggle("drawer-on");
        drawerElement.style.width='500px';
  }
  function toggle_upload_files(){
    var filesSec=document.querySelector("#upload-files");
    if(filesSec){
      filesSec.classList.toggle("d-none");
    }
  }
  function toggle_upload_folder(){
    var filesSec=document.querySelector("#upload-folder");
    if(filesSec){
      filesSec.classList.toggle("d-none");
    }
  } 
 
  
  function getParentDirectoriesWithPath(path) {
    const parts = path.split('/');
    const result = [];
  
    // Remove the last part if it's empty (e.g., trailing slash)
    if (!parts[parts.length - 1]) {
      parts.pop();
    }
  
    function traverse(index) {
      if (index <= 0) return;
  
      const parentDir = parts.slice(0, index).join('/');
      result.push({ name: parts[index - 1], path: parentDir });
  
      traverse(index - 1);
    }
  
    traverse(parts.length);
  
    return result.reverse();
  }

    const [currentPath, setCurrentPath]=useState("");
    const [navPath, setNavPath]=useState("");
    // const callNav=async()=>{
    //   var pathArr=getParentDirectoriesWithPath(currentPath);
    //   setNavPath(pathArr);
    // }
    const openFolder=async(path, type)=>{ 
      // alert(path);
       setObjectData(null);
       var actualPath=""; 
        if(type=="nav"){
          actualPath=path;
        }else{
         if(currentPath!="" && currentPath!="/" && currentPath!=path){ 
        actualPath=currentPath+"/"+path; 
       }else{
        actualPath=path;
       }
        }
     
       const data=await UseApi('get','objectstorage/innoida/bucket/'+id+'/objects?path='+actualPath,'');
       if(data){
        if(data.status=="success"){
          if(data.objects){
            if(data.objects.length>0){
            if(data.objects.length==1){ 
              if(data.objects[0].timestamp==false){ 
                setObjectData([]);
              }else{ 
                setObjectData(data.objects);
              }
            }else{
              setObjectData(data.objects);
            }
          }else{
            setObjectData([]);
          }
        }
          if(data.path=="/"){
            setCurrentPath("");
          }else{
            setCurrentPath(data.path);
          } 
          var pathArr=getParentDirectoriesWithPath(data.path);
          // console.log(data.path);
          setNavPath(pathArr);
        }else{
          setObjectData(null);
        }
      }
      // callNav();
    }  

    useEffect(()=>{ 
      toast.dismiss();   
          openFolder(currentPath, "list");   
          // callNav();
   },[]); 


   const addFolder=async()=>{
    var submitBtn=document.querySelector("#add-folder-btn");   
    if(submitBtn){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
    
      var folder_name=document.querySelector("#folder_name");
    var actualPath="";
    if(folder_name && currentPath!=null){
      if(folder_name.value!=""){
        var path=folder_name.value;
    if(currentPath!="/"){
     actualPath=currentPath+"/"+path;
    }else{
      actualPath=path;
    }
  }else{
    const dt=toast.loading("Please wait...");  
    toast.update(dt,{render: "Please enter folder name", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      return;
  }
   if(folder_name){
    folder_name.value="";
   }
  }
    const data= await UsePostData('post','objectstorage/innoida/bucket/'+id+'/createdirectory', {"path":actualPath});
    
    if(data){
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      if(data.status=="success"){
        toggle_upload_folder();
        const dt=toast.loading("Please wait...");  
        toast.update(dt,{render: "Folder created succeddfully", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          }); 
          openFolder(currentPath, "nav");
      }else{
        const dt=toast.loading("Please wait...");  
        toast.update(dt,{render: "Folder not created!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      openFolder(currentPath, "nav");
      }
    }
   }
  }
   const addFile=async()=>{ 
    var submitBtn=document.querySelector("#add-file-btn");   
    if(submitBtn){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
    
    const data= await UseTicketPost('post','objectstorage/innoida/bucket/'+id+'/upload', 'file-form');
    
    if(data){
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      if(data.status=="success"){ 
        const dt=toast.loading("Please wait...");  
        toast.update(dt,{render: "File uploaded succeddfully", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          }); 
          openFolder(currentPath, "nav");
      }else{
        const dt=toast.loading("Please wait...");  
        toast.update(dt,{render: data.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      openFolder(currentPath, "nav");
      }
    }
    var browseSec=document.querySelector("#browse-sec");
      if(browseSec){
        browseSec.classList.remove("d-none");
      }
    setSelectedFiles([]);
    toggle_upload_files();
   }
  }

   function handleFileUpload() {
    var fileInput = document.getElementById('fileInput');
    if(fileInput){
    fileInput.click();
    }
  }
  const [selectedFiles, setSelectedFiles]=useState([]);
  function handleFileChange(e) { 
    const files = Array.from(e.target.files); 
    // console.log(files); 
    setSelectedFiles(files); 
    var browseSec=document.querySelector("#browse-sec");
    if(browseSec){
      browseSec.classList.add("d-none");
    }
  }

  function extractFileNameFromPath(filePath) {
    var startIndex = filePath.lastIndexOf('\\') + 1;
    var fileName = filePath.substr(startIndex);
    return fileName;
  }
  var fileDownload = require('js-file-download');
  const downloadFile = async (url, name) => {
    try {
      const response = await fetch(url);
      const data = await response.blob(); // Get the file content as a Blob
  
      fileDownload(data, name); // Initiate the download using js-file-download
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }; 
  
  async function getFileDownloadUrl(name){ 
    var file_path=name;
    if(currentPath!="" && currentPath!="/" && currentPath!=file_path){ 
      file_path=currentPath+"/"+name; 
     }else{
      file_path=name;
     }
      const data=await UseApi('get', 'objectstorage/innoida/bucket/'+id+'/download?path='+file_path,'')
        
        if(data){ 
          // console.log(data.url);
            downloadFile(data.url, name);
           
        }
    }
  
    const shareUrl=async(name)=>{
      var file_path=name;
    if(currentPath!="" && currentPath!="/" && currentPath!=file_path){ 
      file_path=currentPath+"/"+name; 
     }else{
      file_path=name;
     }
      const data=await UseApi('get', 'objectstorage/innoida/bucket/'+id+'/download?path='+file_path,'');
        if(data){ 
          // console.log(data);
      var file_url = document.querySelector("#file_url");
      if(file_url){  
        file_url.value=data.url; 
      openDrawerFile();
    }
  }
}
    const deleteDataFile=async(name)=>{ 
      toast.dismiss();
      swal({
        title:"Are you sure?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        })
        .then(async(willDelete) => {
        if(willDelete) {
          var file_path=name;
    if(currentPath!="" && currentPath!="/" && currentPath!=file_path){ 
      file_path=currentPath+"/"+name; 
     }else{
      file_path=name;
     }
          const dt=toast.loading("Data object deleting..."); 
      const dnsp=await UseApi('DELETE','objectstorage/innoida/bucket/'+id+'/delete/object?path='+file_path,'');
      //console.log(dnsp);
      if(dnsp.status=='error'){
        toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(dnsp.status=='success'){
        toast.update(dt,{render: "Data object deleted successfully", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      openFolder(currentPath, "nav");
      }
        } 
        });
    }

    function handleRemoveFile(){
      setSelectedFiles([]);
      var browseSec=document.querySelector("#browse-sec");
      if(browseSec){
        browseSec.classList.remove("d-none");
      }
    }
    return (
        <>
          <div className="tab-pane fade active show" id="object-tab" role="tabpanel">
        <div className="card card-xl-stretch mb-5 mb-xl-12 container p-10 d-none" id="upload-files">
  <div className="notice d-flex  rounded border-primary border border-dashed p-6 text-center container">
    <div className=" flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
      <div className="mb-3 mb-md-0 fw-bold p-20">
       
        <form id="file-form">
        <input
              type="file"
              name="file"
              id="fileInput" 
              style={{display: "none"}}
              onChange={handleFileChange} 
              className="form-control mw-250px me-3"
            />  
            <input
              type="hidden"
              name="path"  
              defaultValue={currentPath}
              className="form-control mw-250px me-3"
            />  
        <div className="fs-6 text-gray-700 pe-7 row">
          <div className="form-group">
            <div
              className="dropzone dropzone-queue mb-2"
              id="browse-sec"
            >
               <h4 className="text-gray-900 fw-bold text-center">
          You can browse your device to upload files here.
        </h4>
        <br />
              <div className="dropzone-panel mb-4 text-center">
                <a onClick={handleFileUpload} id="file-btn" className="dropzone-select btn  btn-primary me-2">
                  Attach files
                </a>
              </div>
              <div className="  text-center d-none">
                <div className=" p-5">
                  <div className="dropzone-file">
                    <div
                      title="some_image_file_name.jpg"
                      className="dropzone-filename text-dark"
                    >
                      <span data-dz-name="">some_image_file_name.jpg</span>
                      <strong>
                        (<span data-dz-size="">340kb</span>)
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
            {selectedFiles.length!=0?(<> 
      {selectedFiles.map((file, index) => (

    <div
    className="p-2 rounded   text-dark fw-bold  text-center"
    data-kt-element="message-text"
      > 
          {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil003.svg*/}
          <span className="svg-icon svg-icon-muted svg-icon-2hx me-5">
          <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          >
          <path
          opacity="0.3"
          d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
          fill="black"
          />
          <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
          </svg>
          </span>
          {/*end::Svg Icon*/}
          <span className="me-5">
           
          {extractFileNameFromPath(file.name)}
          </span>  
          <span className="svg-icon svg-icon-muted svg-icon-2" style={{cursor: "pointer"}} onClick={handleRemoveFile}>
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        >
        <path
        opacity="0.3"
        d="M6 19.7C5.7 19.7 5.5 19.6 5.3 19.4C4.9 19 4.9 18.4 5.3 18L18 5.3C18.4 4.9 19 4.9 19.4 5.3C19.8 5.7 19.8 6.29999 19.4 6.69999L6.7 19.4C6.5 19.6 6.3 19.7 6 19.7Z"
        fill="black"
        />
        <path
        d="M18.8 19.7C18.5 19.7 18.3 19.6 18.1 19.4L5.40001 6.69999C5.00001 6.29999 5.00001 5.7 5.40001 5.3C5.80001 4.9 6.40001 4.9 6.80001 5.3L19.5 18C19.9 18.4 19.9 19 19.5 19.4C19.3 19.6 19 19.7 18.8 19.7Z"
        fill="black"
        />
        </svg>
        </span>
    </div> 
          ))}   
           <a className="btn btn-sm btn-primary" id="add-file-btn" onClick={addFile}><span className="me-3">Upload File</span>
            {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr078.svg*/}
  <span className="svg-icon svg-icon-muted svg-icon-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.3"
        x="12.75"
        y="4.25"
        width={12}
        height={2}
        rx={1}
        transform="rotate(90 12.75 4.25)"
        fill="black"
      />
      <path
        d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z"
        fill="black"
      />
      <path
        d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z"
        fill="#C4C4C4"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
           </a>
    </>):('')}
          </div>
        </div>

        </form>
      </div>
    </div>
  </div>
</div>


<div className="card card-flush">
  {/*begin::Card header*/}
  <div className="card-header pt-8">
    <div className="card-title">
      {/*begin::Search*/}
      <div className="d-flex align-items-center position-relative my-1">
        {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
        <h3>
        {/* <span className="svg-icon svg-icon-1 position-absolute ms-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <rect
              opacity="0.5"
              x="17.0365"
              y="15.1223"
              width="8.15546"
              height={2}
              rx={1}
              transform="rotate(45 17.0365 15.1223)"
              fill="black"
            />
            <path
              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
              fill="black"
            />
          </svg>
        </span> */}
           Files and Folders
        </h3>
      </div>
      {/*end::Search*/}
    </div>
    {/*begin::Card toolbar*/}
    <div className="card-toolbar">
      {/*begin::Toolbar*/}
      <div
        className="d-flex justify-content-end"
        data-kt-filemanager-table-toolbar="base"
      >
       
        {/*begin::Export*/}
        <button
          type="button"
          className="btn btn-light-primary me-3"
          onClick={toggle_upload_folder}
        >
          {/*begin::Svg Icon | path: icons/duotune/files/fil013.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.3"
                d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                fill="black"
              />
              <path
                d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                fill="black"
              />
              <path
                opacity="0.3"
                d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}New Folder
        </button>
        {/*end::Export*/}
        {/*begin::Add customer*/}
        <button
          type="button"
          className="btn btn-primary" 
          onClick={toggle_upload_files}
        >
          {/*begin::Svg Icon | path: icons/duotune/files/fil018.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.3"
                d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                fill="black"
              />
              <path
                d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z"
                fill="black"
              />
              <path
                opacity="0.3"
                d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}Upload Files
        </button>
        {/*end::Add customer*/}
      </div>
      {/*end::Toolbar*/}
      {/*begin::Group actions*/}
      <div
        className="d-flex justify-content-end align-items-center d-none"
        data-kt-filemanager-table-toolbar="selected"
      >
        <div className="fw-bolder me-5">
          <span
            className="me-2"
            data-kt-filemanager-table-select="selected_count"
          />
          Selected
        </div>
        <button
          type="button"
          className="btn btn-danger"
          data-kt-filemanager-table-select="delete_selected"
        >
          Delete Selected
        </button>
      </div>
      {/*end::Group actions*/}
    </div>
    {/*end::Card toolbar*/}
  </div>
  {/*end::Card header*/}
  {/*begin::Card body*/}
  <div className="card-body">
    {/*begin::Table header*/}
    <div className="d-flex flex-stack">
      {/*begin::Folder path*/}
      <div className="badge badge-lg badge-light-primary">
        <div className="d-flex align-items-center flex-wrap">
          
          
          {/*begin::Svg Icon | path: icons/duotune/abstract/abs039.svg*/}
          <span className="svg-icon svg-icon-2x svg-icon-primary me-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.3"
                d="M14.1 15.013C14.6 16.313 14.5 17.813 13.7 19.113C12.3 21.513 9.29999 22.313 6.89999 20.913C5.29999 20.013 4.39999 18.313 4.39999 16.613C5.09999 17.013 5.99999 17.313 6.89999 17.313C8.39999 17.313 9.69998 16.613 10.7 15.613C11.1 15.713 11.5 15.813 11.9 15.813C12.7 15.813 13.5 15.513 14.1 15.013ZM8.5 12.913C8.5 12.713 8.39999 12.513 8.39999 12.313C8.39999 11.213 8.89998 10.213 9.69998 9.613C9.19998 8.313 9.30001 6.813 10.1 5.513C10.6 4.713 11.2 4.11299 11.9 3.71299C10.4 2.81299 8.49999 2.71299 6.89999 3.71299C4.49999 5.11299 3.70001 8.113 5.10001 10.513C5.80001 11.813 7.1 12.613 8.5 12.913ZM16.9 7.313C15.4 7.313 14.1 8.013 13.1 9.013C14.3 9.413 15.1 10.513 15.3 11.713C16.7 12.013 17.9 12.813 18.7 14.113C19.2 14.913 19.3 15.713 19.3 16.613C20.8 15.713 21.8 14.113 21.8 12.313C21.9 9.513 19.7 7.313 16.9 7.313Z"
                fill="black"
              />
              <path
                d="M9.69998 9.61307C9.19998 8.31307 9.30001 6.81306 10.1 5.51306C11.5 3.11306 14.5 2.31306 16.9 3.71306C18.5 4.61306 19.4 6.31306 19.4 8.01306C18.7 7.61306 17.8 7.31306 16.9 7.31306C15.4 7.31306 14.1 8.01306 13.1 9.01306C12.7 8.91306 12.3 8.81306 11.9 8.81306C11.1 8.81306 10.3 9.11307 9.69998 9.61307ZM8.5 12.9131C7.1 12.6131 5.90001 11.8131 5.10001 10.5131C4.60001 9.71306 4.5 8.91306 4.5 8.01306C3 8.91306 2 10.5131 2 12.3131C2 15.1131 4.2 17.3131 7 17.3131C8.5 17.3131 9.79999 16.6131 10.8 15.6131C9.49999 15.1131 8.7 14.1131 8.5 12.9131ZM18.7 14.1131C17.9 12.8131 16.7 12.0131 15.3 11.7131C15.3 11.9131 15.4 12.1131 15.4 12.3131C15.4 13.4131 14.9 14.4131 14.1 15.0131C14.6 16.3131 14.5 17.8131 13.7 19.1131C13.2 19.9131 12.6 20.5131 11.9 20.9131C13.4 21.8131 15.3 21.9131 16.9 20.9131C19.3 19.6131 20.1 16.5131 18.7 14.1131Z"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/} 

          <a style={{cursor: "pointer"}} onClick={(e)=>openFolder("", "nav")}>{id}</a>

          {navPath!="" && navPath.map((value,index)=>(<> 
                 
           <span className="svg-icon svg-icon-2x svg-icon-primary mx-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                fill="black"
              />
            </svg>
          </span>
           <a style={{cursor: "pointer"}} onClick={(e)=>openFolder(value.path, "nav")}>{value.name}</a>
           
           </>))}
           
          
          
        </div>
      </div>
      {/*end::Folder path*/}
      {/*begin::Folder Stats*/} 
      {objectData!=null?(<>
        <div className="badge badge-lg badge-primary">
        <span id="kt_file_manager_items_counter">{objectData.length} items</span>
        </div>
        </>):('')}
         
      {/*end::Folder Stats*/}
    </div>
    {/*end::Table header*/}
    {/*begin::Table*/}
    <div
      id="kt_file_manager_list_wrapper"
      className="dataTables_wrapper dt-bootstrap4 no-footer"
    >
      <div className="table-responsive">
        <table
          id="kt_file_manager_list"
          data-kt-filemanager-table="files"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
        >
          {/*begin::Table head*/}
          <thead>
            {/*begin::Table row*/}
            <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
              <th
                className="w-10px pe-2 sorting_disabled"
                rowSpan={1}
                colSpan={1}
                style={{ width: "29.25px" }}
              > 
              </th>
              <th
                className="min-w-250px sorting_disabled"
                rowSpan={1}
                colSpan={1}
                style={{ width: "458.337px" }}
              >
                Name
              </th>
              <th
                className="min-w-10px sorting_disabled"
                rowSpan={1}
                colSpan={1}
                style={{ width: "100.438px" }}
              >
                Size
              </th>
              <th
                className="min-w-125px sorting_disabled"
                rowSpan={1}
                colSpan={1}
                style={{ width: "293.525px" }}
              >
                Last Modified
              </th>
              <th
                className="w-125px sorting_disabled"
                rowSpan={1}
                colSpan={1}
                style={{ width: 125 }}
              />
            </tr>
            {/*end::Table row*/}
          </thead>
          {/*end::Table head*/}
          <tbody className="fw-bold text-gray-600 d-none" id="upload-folder">

            <tr
        id="kt_file_manager_new_folder_row"
        data-kt-filemanager-template="upload"
      >
        <td />
        <td
          id="kt_file_manager_add_folder_form"
          className="fv-row fv-plugins-bootstrap5 fv-plugins-framework fv-plugins-icon-container"
        >
          <form id="objectdeploy">
          <div className="d-flex align-items-center">
            {/*begin::Folder icon*/}
            {/*begin::Svg Icon | path: icons/duotune/files/fil012.svg*/}
            <span className="svg-icon svg-icon-2x svg-icon-primary me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                  fill="black"
                />
                <path
                  d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z"
                  fill="black"
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            {/*end::Folder icon*/}
            {/*begin:Input*/}
            <input
              type="text"
              name="name"
              id="folder_name"
              defaultValue=""
              placeholder="Enter the folder name"
              className="form-control mw-250px me-3"
            />  
            {/*end:Input*/}
            {/*begin:Submit button*/}
            <a 
              className="btn btn-icon btn-light-primary me-3"
              id="add-folder-btn"
              onClick={addFolder}
            >
              <span className="indicator-label">
                 <span className="svg-icon svg-icon-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
                      fill="black"
                    />
                  </svg>
                </span>
               </span>
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle" />
              </span>
            </a>
            {/*end:Submit button*/}
            {/*begin:Cancel button*/}
            <a
            onClick={toggle_upload_folder}
              className="btn btn-icon btn-light-danger" 
            >
              <span className="indicator-label">
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr088.svg*/}
                <span className="svg-icon svg-icon-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="7.05025"
                      y="15.5356"
                      width={12}
                      height={2}
                      rx={1}
                      transform="rotate(-45 7.05025 15.5356)"
                      fill="black"
                    />
                    <rect
                      x="8.46447"
                      y="7.05029"
                      width={12}
                      height={2}
                      rx={1}
                      transform="rotate(45 8.46447 7.05029)"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </span>
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle" />
              </span>
            </a>
            {/*end:Cancel button*/}
          </div>
          </form>
          <form/>
          <div />
          <div className="fv-plugins-message-container invalid-feedback" />
        </td>
        <td />
        <td />
        <td />
      </tr>
          </tbody>
          {/*begin::Table body*/}
          <tbody className="fw-bold text-gray-600"> 


          {objectData!=null?(<>
          {objectData.length>0?(<>
            {objectData.map((value,index)=>(<>
             {value.type!="directory"?(<>
             
             
            <tr className="odd">
              {/*begin::Checkbox*/}
              <td> 
              </td>
              {/*end::Checkbox*/}
              {/*begin::Name=*/}
              <td>
                <div className="d-flex align-items-center">
                  {/*begin::Svg Icon | path: icons/duotune/files/fil003.svg*/}
                  <span className="svg-icon svg-icon-2x svg-icon-primary me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
                        fill="black"
                      />
                      <path
                        d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                  <a href="#" className="text-gray-800 text-hover-primary">
                    {value.name}
                  </a>
                </div>
              </td>
              {/*end::Name=*/}
              {/*begin::Size*/}
              <td>{value.size} </td>
              {/*end::Size*/}
              {/*begin::Last modified*/}
              <td data-order="2022-06-24T17:20:00+05:30">
              {value.timestamp}
              </td>
              {/*end::Last modified*/}
              {/*begin::Actions*/}
              <td
                className="text-end"
                data-kt-filemanager-table="action_dropdown"
              >
                <div className="d-flex justify-content-end">
                  <div className="me-2">
                  <a 
                    style={{cursor:"pointer"}}
                     onClick={(e)=>shareUrl(value.name)}
                      className="btn btn-sm btn-icon btn-light btn-active-light-success" 
                      data-bs-toggle="tooltip" data-bs-placement="top" title="Share"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/coding/cod007.svg*/}
                      <span className="svg-icon svg-icon-primary svg-icon-2 m-0">
                      <svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
    fill="black"
  />
  <path
    opacity="0.3"
    d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
    fill="black"
  />
</svg>

                        </span>
                        </a>

                  </div>
                   {/*begin::download*/}
                   <div classNameedit="ms-2">
                    
                    <a 
                    style={{cursor:"pointer"}}
                     onClick={(e)=>getFileDownloadUrl(value.name)}
                      className="btn btn-sm btn-icon btn-light btn-active-light-success" 
                      data-bs-toggle="tooltip" data-bs-placement="top" title="Download"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/coding/cod007.svg*/}
                      <span className="svg-icon svg-icon-primary svg-icon-2 m-0">
                      <svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M21 22H3C2.4 22 2 21.6 2 21C2 20.4 2.4 20 3 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22ZM13 13.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V13.4H13Z"
    fill="black"
  />
  <path
    opacity="0.3"
    d="M7 13.4H17L12.7 17.7C12.3 18.1 11.7 18.1 11.3 17.7L7 13.4Z"
    fill="black"
  />
</svg>

                      </span>
                      {/*end::Svg Icon*/}
                    </a> 
                  </div>
                  {/*end::download*/} 
                  {/*begin::delete*/}
                  <div className="ms-2">
                    <a 
                      onClick={(e)=>deleteDataFile(value.name)}
                      className="btn btn-sm btn-icon btn-light btn-active-light-danger"
                      data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" 
                    >
                      {/*begin::Svg Icon | path: icons/duotune/general/gen052.svg*/}
                      <span className="svg-icon svg-icon-2 m-0">
                      <svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
    fill="black"
  />
  <path
    opacity="0.5"
    d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
    fill="black"
  />
  <path
    opacity="0.5"
    d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
    fill="black"
  />
</svg>


                      </span>
                      {/*end::Svg Icon*/}
                    </a>
                     
                  </div>
                  {/*end::delete*/}
                </div>
              </td>
              {/*end::Actions*/}
            </tr>
            </>):(<>
            
              <tr className="even">
              {/*begin::Checkbox*/}
              <td> 
              </td>
              {/*end::Checkbox*/}
              {/*begin::Name=*/}
              <td>
                <div  className="d-flex align-items-center" onClick={(e)=>openFolder(value.name, "list")}>
                {/* <div  className="d-flex align-items-center"> */}

                  {/*begin::Svg Icon | path: icons/duotune/files/fil003.svg*/}
                  <span className="svg-icon svg-icon-2x svg-icon-primary me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                        fill="black"
                      />
                      <path
                        d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                  <a  className="text-gray-800 text-hover-primary">
                  {value.name}
                  </a>
                </div>
              </td>
              {/*end::Name=*/}
              {/*begin::Size*/}
              <td>   </td>
              {/*end::Size*/}
              {/*begin::Last modified*/}
              <td data-order="2022-11-10T11:05:00+05:30">
                {value.timestamp}
              </td>
              {/*end::Last modified*/}
              {/*begin::Actions*/}
              <td
                className="text-end"
                data-kt-filemanager-table="action_dropdown"
              >
                <div className="d-flex justify-content-end">
                <div className="me-2 d-none">
                  <a 
                    style={{cursor:"pointer"}}
                     onClick={(e)=>shareUrl(value.name)}
                      className="btn btn-sm btn-icon btn-light btn-active-light-success" 
                      data-bs-toggle="tooltip" data-bs-placement="top" title="Share"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/coding/cod007.svg*/}
                      <span className="svg-icon svg-icon-primary svg-icon-2 m-0">
                      <svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
    fill="black"
  />
  <path
    opacity="0.3"
    d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
    fill="black"
  />
</svg>

                        </span>
                        </a>

                  </div>
                  {/*begin::delete*/}
                  <div className="ms-2">
                    <a 
                      onClick={(e)=>deleteDataFile(value.name)}
                      className="btn btn-sm btn-icon btn-light btn-active-light-danger"
                      data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" 
                    >
                      {/*begin::Svg Icon | path: icons/duotune/general/gen052.svg*/}
                      <span className="svg-icon svg-icon-2 m-0">
                      <svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
    fill="black"
  />
  <path
    opacity="0.5"
    d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
    fill="black"
  />
  <path
    opacity="0.5"
    d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
    fill="black"
  />
</svg>


                      </span>
                      {/*end::Svg Icon*/}
                    </a>
                     
                  </div>
                  {/*end::delete*/}
                </div>
              </td>
              {/*end::Actions*/}
            </tr>
            </>)} 
            </>))} 
            </>):(<><tr> 
                    <td colspan="12"  valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr></>)}
            
            </>):(<>
              <td colspan="12" className="dataTables_empty">
                      {/* <div className="loading-spinner"></div> */}
                      <div className="small-spinner2"></div>
                    </td>
            </>)}
          
          </tbody>
          {/*end::Table body*/}
        </table>
      </div> 
    </div>
    {/*end::Table*/}
  </div>
  {/*end::Card body*/}
</div>

<div
            id="files-modal"
            className="bg-body shadow drawer drawer-end"
            data-kt-drawer="true"
            data-kt-drawer-name="chat"
            data-kt-drawer-activate="true"
            data-kt-drawer-direction="end"
            data-kt-drawer-close="#close_ssh"
            data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}"
            style={{ maxWidth: "70%", important: true }}
          >
            {/*begin::Messenger*/}
            <div
              className="card rounded-0 w-100 border-0"
              id="kt_drawer_chat_messenger"
            >
              {/*begin::Card header*/}
              <div
                className="card-header pe-5"
                id="kt_drawer_chat_messenger_header"
              >
                {/*begin::Title*/}
                <div className="card-title">
                  {/*begin::User*/}
                  <div className="d-flex justify-content-center flex-column me-3">
                    <a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                      Share the file
                    </a>
                  </div>
                  {/*end::User*/}
                </div>
                {/*end::Title*/}
                {/*begin::Card toolbar*/}
                <div className="card-toolbar">
                  {/*begin::Close*/}
                  <div
                    onClick={openDrawerFile}
                    className="btn btn-sm btn-icon btn-active-light-primary"
                    id="close_ssh"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x={6}
                          y="17.3137"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-45 6 17.3137)"
                          fill="black"
                        />
                        <rect
                          x="7.41422"
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(45 7.41422 6)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Close*/}
                </div>
                {/*end::Card toolbar*/}
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="" id="">
                {/*begin::Form*/}
                <form name="routing" id="importkey" className="form">
                  {/*begin::Modal body*/}
                  <div className="modal-body ">
                    {/*begin::Scroll*/}
                    <div
                      className="scroll-y me-n7 pe-7"
                      id="kt_modal_create_api_key_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                      data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      {/*begin::Input group*/}
                      <div>
  <label className="form-label mb-5">Copy the share link <span className="text-danger">( Valid for 1 hour only )</span></label>
  <div className="d-flex">
                  <input
                    id="file_url"
                    type="text"
                    className="form-control form-control-solid me-3 flex-grow-1"
                    name="search"
                    defaultValue=""
                  />
                  <a style={{cursor:"pointer"}}
                     onClick={copyToClipboard}
                    id="file_url_copy_btn"
                    className="btn btn-light fw-bolder flex-shrink-0" 
                  >
                    Copy Link
                  </a>
                </div>
  <div className="text-muted fs-6 mb-7 mt-5">
  Conveniently replicate the link to facilitate easy sharing with others. Simplify the process of distributing content by swiftly copying the shareable link.
  </div>
                      </div> 
                      {/*end::Input group*/} 
                    </div>
                    {/*end::Scroll*/}
                  </div>
                  {/*end::Modal body*/} 
                </form>
                {/*end::Form*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Messenger*/}
          </div>
        </div>  
        </>
    );
}
 

const mapStateToProps = (state) => ({
  ObjectParentid: state.objectCluster.currentObjectParentid 
});

const mapDispatchToProps = (dispatch) => ({
  updateCurrentParentid: (data) => dispatch(AddCurrentObjectParentid(data)),
  updateBreadcrumb: (data) => dispatch(AddObjectBreadcrumb(data)),
});

export default connect(mapStateToProps,mapDispatchToProps)(FileFolder)
