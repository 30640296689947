import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom/dist"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import swal from 'sweetalert'; 
import useRazorpay from "react-razorpay";
import axios from "axios"; 
import spinner from "./spinner.svg";
import UsePostData from "../CustomHooks/usePostData";
import UseApi from "../CustomHooks/useApi";
import UsePost from "../CustomHooks/usePost";
import { AddHostCount } from "../Redux/Services/Action/actions";
function useForceUpdate(){
  const [value, setValue] = useState(0);  
  return () => setValue(value => value + 1); 
}

const DeployCloud=(props)=>{ 
  const makeid=(length)=> {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
  const forceUpdate = useForceUpdate();
  const [adcdata,setAdcdata]=useState([]);
  const [ddcdata,setDdcdata]=useState([]);
  const dataFetchedRef=useRef();
  const dataFetchedRef2=useRef(); 
  const dataFetchedRef3=useRef();
  const dataFetchedRef4=useRef();  
  const countType=useRef();
  const navigate=useNavigate();
  const [distroData,setDistroData]=useState(null); 
  const [planData,setPlanData]=useState(null); 
  const [firewallData,setFirewallData]=useState(null); 
  const [sshData,setSshData]=useState(null);  
  const [hostCount, setHostCount]=useState(null);
  const [invoiceData, setInvoiceData]=useState (null);
  const [userData, setUserData]=useState (null);
  const [countChange, setCountChange]=useState(null);
  const dispatch=useDispatch();
  const user_data=useRef(null);
  const dczoneStatus=useRef(true);
  
    const getDczone=async()=>{ 
      // setAdcdata([]);
      // setDdcdata([]);
         const data=await UseApi('get','dczones',''); 
         
         if(Object.keys(data).length === 0){ 
          setAdcdata([]);
          setDdcdata([]);
         }else{  
           if(dczoneStatus.current){
          data.dczones.map(async(value,index)=>{  
             if(value.status!=='active'){
              // var client=await fetch(`https://geolocation-db.com/json/`).then(res =>{
              //    res.json();
              //   }).catch(err => {
              //     //console.log("error");
              //   }); 
              var client= await axios.get('https://geolocation-db.com/json/');
              //console.log(client);
              var server_ip=client.IPv4;
              if(server_ip !== '103.209.144.51' || server_ip !== '103.209.144.66' || server_ip !==
                '103.209.145.208' || server_ip !== '103.209.144.81' || server_ip !== '103.209.144.82' ||
                server_ip !== '103.209.144.83' || server_ip !== '103.127.30.91' || server_ip !==
                '103.209.144.60' || server_ip !== '103.127.30.239' || server_ip !== '103.127.28.52'){
                  ddcdata.push(value); 
                }
                forceUpdate(); 
             }else{
                 adcdata.push(value);
                 forceUpdate();
             }
          })
          dczoneStatus.current=false;
        }
            
         }  
    }
    useEffect(()=>{
      if (dataFetchedRef2.current) return;
        dataFetchedRef2.current = true; 
        getDczone(); 
    },[]);  
       
    const getDistro=async()=>{ 
      setDistroData(null);
         const data=await UseApi('get','cloud/getdeploy','');  
         user_data.current=await UseApi('get','account/info',''); 
         setUserData(user_data.current.user); 
        // //////console.log(user_data.current);
         if(Object.keys(data).length === 0){
          setDistroData(null); 
          setPlanData(null);
          setFirewallData(null);
          setSshData(null);
          setHostCount(null);
         }else{
          setPlanData(data.plans); 
          setDistroData(data.distro); 
          setFirewallData(data.firewalls);
          setSshData(data.keys); 
         // //////console.log(data.plans);
         if(props){
         setHostCount(props.hostCountData);
         }
         } 
         forceUpdate();
    }
 // select dc

 const [vpcData,setVpcData]=useState(null);
 const [backupData,setBackupData]=useState(null);
 const [snapData,setSnapData]=useState(null);
 const [isoData,setIsoData]=useState(null);

    const selectDc=async(slug)=>{ 
        const vpc_data=await UseApi('get','vpc/dc/'+slug,''); 

        const backup_data=await UseApi('get','backups/'+slug,''); 
        const snap_data=await UseApi('get','snapshot/'+slug,''); 
        const iso_data=await UseApi('get','iso/'+slug,''); 

        if(vpc_data.status==="error"){
          setVpcData(null);
        }else{
          delete(vpc_data.html);
          setVpcData(vpc_data);
        }

        if(Object.keys(backup_data).length === 0){
             setBackupData(null);
        }else{
              setBackupData(backup_data);
        }
        if(Object.keys(snap_data).length === 0){
             setSnapData(null);
        }else{
             setSnapData(snap_data);
        }
        if(Object.keys(iso_data).length === 0){
             setIsoData(null);
        }else{
             setIsoData(iso_data);
        }  
    }

    useEffect(()=>{ 
        if(dataFetchedRef.current) return;
           dataFetchedRef.current=true;
           getDistro();  
    },[]);  
    
    const selectOs=(image, distribution, cost)=>{ 
      if(props){
      const os_cost=document.querySelector("#os_cost");
      os_cost.value=cost;
      if(props.deployInfo){
      props.deployInfo[0].os_cost=cost;
      }
      const os_version_list_=document.querySelector("#os_version_list_"+distribution);
      os_version_list_.classList.remove('d-block'); 
      
       const all_radio=document.getElementsByClassName("radio_image");
       for(var i=0;i<all_radio.length;i++){
        all_radio[i].checked=false;
        }
       const all_label=document.getElementsByClassName("versionOs");
       for(var i=0;i<all_label.length;i++){
        all_label[i].classList.remove('active');
        }
        var pselect=document.querySelectorAll([".deploy_osversioncontainer"]);
        pselect.forEach((clist) => {
          clist.classList.remove('d-block');
        });
       const all_versionText=document.getElementsByClassName("osversionselectmultiple");
       for(var i=0;i<all_versionText.length;i++){
        all_versionText[i].innerHTML="Select Version";
        } 
  
        const select_image=document.querySelector("#select_image");
        if(props.deployInfo){
        props.deployInfo[0].select_image=image;
        }
        select_image.value=image;
      const current_radio=document.querySelector("#select_"+distribution);
         current_radio.checked=true;
      const current_label=document.querySelector("#versionOs_"+distribution);
            current_label.classList.add('active');
      const current_select_version=document.querySelector("#osversion_"+distribution);
            current_select_version.innerHTML=image;
      
            cloudCost(); 
    }
  }
  
   
  const osVersion=(distribution)=>{ 
    var pselect=document.querySelectorAll([".deploy_osversioncontainer"]);
    var pheight=document.querySelectorAll([".versionOs"]); 
    pselect.forEach((clist) => {
     clist.classList.remove('d-block');
   });
   //var cl=event.currentTarget.querySelector('.versionOs');
     
   pheight.forEach((clist) => {
     clist.style.height="auto";
      clist.classList.remove('active');
   }); 
  //  event.currentTarget.querySelector('.deploy_osversioncontainer').classList.add('d-block');
  const os_version_list_=document.querySelector("#os_version_list_"+distribution);
  os_version_list_.classList.add('d-block');  
   }


   // start- select plans
 
const show_plan_price=(pid,price)=>{
  const selectPlan=document.querySelector("#change_plan_"+pid);
  const billing_info=document.querySelector("#billing_info");
  const offPlan=document.querySelector("#off_"+pid);
  const updated_plan_price=document.querySelector("#updated_plan_price_"+pid);
  const actual_price=document.querySelector("#actual_price_"+pid);
  if(user_data.current!=null){
    if(selectPlan){
      if(props.deployInfo){
      props.deployInfo[0].billing_info=selectPlan.value;
      }
        billing_info.value=selectPlan.value;
      if(selectPlan.value==='36month'){
        offPlan.innerHTML="40% OFF";
        actual_price.classList.remove('d-none');
        updated_plan_price.innerHTML=user_data.current.user.currencyprefix+" "+parseFloat(parseInt(price)-((parseInt(price)*40)/100)).toFixed(2)+"/mon";
      }else if(selectPlan.value==='12month'){
        offPlan.innerHTML="25% OFF";
        actual_price.classList.remove('d-none');
        updated_plan_price.innerHTML=user_data.current.user.currencyprefix+" "+parseFloat(parseInt(price)-((parseInt(price)*25)/100)).toFixed(2)+"/mon";
      }else if(selectPlan.value==='3months'){
        offPlan.innerHTML="10% OFF";
        actual_price.classList.remove('d-none');
        updated_plan_price.innerHTML=user_data.current.user.currencyprefix+" "+parseFloat(parseInt(price)-((parseInt(price)*10)/100)).toFixed(2)+"/mon";
      }else if(selectPlan.value==='monthly'){
        offPlan.innerHTML="5% OFF";
        actual_price.classList.remove('d-none');
        updated_plan_price.innerHTML=user_data.current.user.currencyprefix+" "+parseFloat(parseInt(price)-((parseInt(price)*5)/100)).toFixed(2)+"/mon";
      }else if(selectPlan.value==='no'){
        offPlan.innerHTML="";
        actual_price.classList.add('d-none');
        updated_plan_price.innerHTML=user_data.current.user.currencyprefix+" "+parseFloat(parseInt(price)/720).toFixed(2)+"/hourly";
      }
    }
  } 
  const plan_value=document.querySelector("#plan_value"); 
  const plan_cost=document.querySelector("#plan_cost"); 
   plan_value.value=pid; 
   plan_cost.value=price;
   if(props.deployInfo){
   props.deployInfo[0].plan_cost=price;
   props.deployInfo[0].plan_value=pid;
   }
   const tickParent=document.querySelector('#tick_parent_'+pid);
   const tick2=document.getElementsByClassName('tick_');  
   const tickmain=document.getElementsByClassName('tick-parent');
   for(var i=0;i<tickmain.length;i++){
      tickmain[i].classList.remove("activeplan");
   }
   for(var i=0;i<tick2.length;i++){
    tick2[i].innerHTML="";
 } 
  tickParent.classList.remove("planseffect");
  tickParent.classList.add("activeplan"); 
  const tick= document.querySelector('#tick_'+pid);
   tick.innerHTML='<svg xmlns="http://www.w3.org/2000/svg"  width={24} height={24} viewBox="0 0 24 24" fill="none" > <path  opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="black" /><path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="black"  /> </svg>'; 
   cloudCost();
  }
  
   // end- select plans


   // start ssh keys

   const openDrawer=()=>{
    var drawerElement = document.querySelector("#importkey-modal");
        drawerElement.classList.toggle("drawer-on");
        drawerElement.style.width='500px';
  }
  const importSshKey=async ()=>{  
   const dt=toast.loading("Please wait...");
    const responseData=await UsePost('post','key/import','importkey');
    
      //////console.log(responseData);
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      var SshForm = document.querySelector("#importkey");
      SshForm.reset();
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      }); 
      getDistro();
      openDrawer();
      var SshForm = document.querySelector("#importkey");
      SshForm.reset();
      } 
  }
   // end ssh keys 
   const deployCloudServer=async ()=>{  
    const dt=toast.loading("Please wait...");
     const responseData=await UsePost('post','cloud/deploy','clouddeploy');
      //console.log(responseData);
       if(responseData.status==='error'){
         toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
       });  
       }else{ 
         toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined 
        }); 
        const billing_info=document.querySelector("#billing_info").value;
        if(billing_info!="hourly"){
        openInvoice(responseData.invoiceid); 
        }else{
          navigate("/cloud/"+responseData.cloudid);
        }
       } 
   } 

const getCount=()=>{
  getDczone();
  cloudCost();
  forceUpdate();
 } 
   const updateHostCount=(status)=>{ 
    forceUpdate();
    if(props){
    const node_count=document.querySelector("#total_chq");
   const downCountBtn=document.querySelector("#hostDown");
  const upCountBtn=document.querySelector("#hostUp");
    if(status==='up'){
      downCountBtn.classList.remove('disabled');
      if(parseInt(node_count.value)>=5){
        upCountBtn.classList.add('disabled'); 
      }else{
        node_count.value=parseInt(node_count.value)+1;
        if(props.hostCountData){
         props.hostCountData.push('host');
         setHostCount(props.hostCountData);
        } 
       //  props.updateHostCountData(props.hostCountData); 
         getCount(); 
      }  
     
    }else if(status==='down'){
      upCountBtn.classList.remove('disabled');
      if(parseInt(node_count.value)<=1){
        downCountBtn.classList.add('disabled'); 
      }else{
        node_count.value=parseInt(node_count.value)-1; 
        if(props.hostCountData){
           props.hostCountData.pop();
           setHostCount(props.hostCountData); 
        }
          // props.updateHostCountData(props.hostCountData); 
           getCount(); 
      }  
    }  
  }
   } 
   

const cloudCost=()=>{ 
  const billing_info=document.querySelector("#billing_info");
  const plan_cost=document.querySelector("#plan_cost");
  const os_cost=document.querySelector("#os_cost");
  const node_count=document.querySelector("#total_chq");
  const order_total=document.querySelector("#order_total");
  const order_total_hour=document.querySelector("#order_toatl_hr");
  const isBackup=document.querySelector("#selectBackupEnabled");
  const supportSelect=document.querySelector("#select_support_option");
  const saved_amount=document.querySelector("#saved_cloud_cost");
  const weekly_backup=document.querySelector("#weekly_backup");
  const backup_weekly=document.querySelector("#backup_weekly");
  const server_management=document.querySelector("#server_management");
  const management_server=document.querySelector("#management_server");
  const discount_server_cost=document.querySelector("#discount_server_cost");
  const discount_cost_server=document.querySelector("#discount_cost_server");
  const original_server_cost=document.querySelector("#original_server_cost");
  const original_cost_server=document.querySelector("#original_cost_server");
  const discount_offer=document.querySelector("#discount_offer");
  const currency_rate=1;
  var serverCount=1;
 
  

   if(node_count){
    if(node_count.value!=0){
        serverCount=node_count.value;
    }
   }
//////console.log(serverCount);
   var os_price=parseFloat(os_cost.value*serverCount);
   var backupCost=0; 
   
   var supportCost=0; 
    if(supportSelect.value==='full'){ 
       supportCost=(6995*currency_rate)*parseFloat(serverCount); 
       server_management.classList.remove("d-none");
    }else if(supportSelect.value==='basic'){ 
      supportCost=(3995*currency_rate)*parseFloat(serverCount); 
      server_management.classList.remove("d-none");
    }else if(supportSelect.value==='unmanaged'){ 
      server_management.classList.add("d-none"); 
      supportCost=0; 
    }
  var combineCost=parseFloat(plan_cost.value);
  var planOfferCost=0;
  var hourlyFinalCost=0; 
  var orgAmount=0;
  var org_backupCost=0;
   if(billing_info){
    if(billing_info.value==="hourly"){   
      planOfferCost=parseFloat(combineCost).toFixed(2);  
      hourlyFinalCost=parseFloat(planOfferCost/720).toFixed(2); 
      if(discount_offer && original_cost_server && original_server_cost && discount_cost_server && discount_server_cost){
        original_server_cost.classList.add("d-none");
        discount_server_cost.classList.add("d-none");
      }
    }else if(billing_info.value==="monthly"){ 
      planOfferCost=parseFloat((combineCost-((combineCost*5)/100))*1).toFixed(2);
      hourlyFinalCost=parseFloat(planOfferCost/720).toFixed(2); 
      if(discount_offer && original_cost_server && original_server_cost && discount_cost_server && discount_server_cost){
        original_server_cost.classList.remove("d-none");
        discount_server_cost.classList.remove("d-none");
        original_cost_server.innerHTML=combineCost*serverCount;
        discount_cost_server.innerHTML=planOfferCost*serverCount;
        discount_offer.innerHTML="5%";
      }
    }else if(billing_info.value==="3months"){ 
      os_price*=3;
      supportCost*=3;
      backupCost*=3
      orgAmount=combineCost*3;
      var monPrice=parseFloat((combineCost-((combineCost*10)/100))).toFixed(2); 
      planOfferCost=parseFloat((combineCost-((combineCost*10)/100))*3).toFixed(2); 
      hourlyFinalCost=parseFloat(monPrice/720).toFixed(2); 
      if(discount_offer && original_cost_server && original_server_cost && discount_cost_server && discount_server_cost){
        original_server_cost.classList.remove("d-none");
        discount_server_cost.classList.remove("d-none");
        original_cost_server.innerHTML=orgAmount*serverCount;
        discount_cost_server.innerHTML=planOfferCost*serverCount;
        discount_offer.innerHTML="10%";
      }
    }else if(billing_info.value==="12month"){ 
      os_price*=12;
      supportCost*=12;
      backupCost*=12;
      orgAmount=combineCost*12;
      planOfferCost=parseFloat((combineCost-((combineCost*25)/100))*12).toFixed(2);
      var monPrice=parseFloat((combineCost-((combineCost*25)/100))).toFixed(2);  
      hourlyFinalCost=parseFloat(monPrice/720).toFixed(2); 
      if(discount_offer && original_cost_server && original_server_cost && discount_cost_server && discount_server_cost){
        original_server_cost.classList.remove("d-none");
        discount_server_cost.classList.remove("d-none");
        original_cost_server.innerHTML=orgAmount*serverCount;
        discount_cost_server.innerHTML=planOfferCost*serverCount;
        discount_offer.innerHTML="25%";
      }
    }else if(billing_info.value==="36month"){ 
      os_price*=36;
      supportCost*=36;
      backupCost*=36;
      orgAmount=combineCost*36;
      planOfferCost=parseFloat((combineCost-((combineCost*40)/100))*36).toFixed(2); 
      var monPrice=parseFloat((combineCost-((combineCost*40)/100))).toFixed(2); 
      hourlyFinalCost=parseFloat(monPrice/720).toFixed(2); 
      if(discount_offer && original_cost_server && original_server_cost && discount_cost_server && discount_server_cost){
        original_server_cost.classList.remove("d-none");
        discount_server_cost.classList.remove("d-none");
        original_cost_server.innerHTML=orgAmount*serverCount;
        discount_cost_server.innerHTML=planOfferCost*serverCount;
        discount_offer.innerHTML="40%";
      }
    } 
   }  
   
   var finalCost=0;
  //  //////console.log(billing_info.value);
  //  //////console.log(parseFloat(supportCost)+parseFloat(planOfferCost));
  
      management_server.innerHTML=supportCost;
 if(billing_info.value!=""){
  if(isBackup.checked){
    backupCost=parseFloat((planOfferCost*0.2)*serverCount);
    org_backupCost=parseFloat((orgAmount*0.2)*serverCount);
    if(backup_weekly && weekly_backup){
      //console.log("backup enabled");
      weekly_backup.classList.remove("d-none");
      backup_weekly.innerHTML=backupCost;
    }
  }else{
    if(backup_weekly && weekly_backup){
      //console.log("backup disabled");
      weekly_backup.classList.add("d-none");
    backupCost=0;
    }
  }
  
  finalCost=parseFloat(parseFloat(planOfferCost*serverCount)+parseFloat(os_price)+parseFloat(backupCost)+parseFloat(supportCost)).toFixed(2);
  
}
  if(isNaN(finalCost)){
    order_total.innerHTML=0.00;
   order_total_hour.innerHTML=0.00;
   saved_amount.innerHTML=0.00;
  }else{
   order_total.innerHTML=finalCost;
   order_total_hour.innerHTML=hourlyFinalCost;
   saved_amount.innerHTML=(parseFloat(orgAmount*serverCount)+org_backupCost)-(parseFloat(planOfferCost*serverCount)+backupCost);
  }
}

const supportOp=(type)=>{
  const select_support_option=document.querySelector("#select_support_option"); 
  if(select_support_option){
    select_support_option.value=type;
    if(props.deployInfo){
    props.deployInfo[0].select_support_option=type;
  }
}
  
  cloudCost(); 
}


const openInvoice=async(id)=>{
  const invoice=await UseApi ('get','billing/'+id,'');    
  setInvoiceData(invoice.invoices[0]); 
   ////console.log(invoice);
   const transTabel=document.querySelector("#trans-tabel");
   const invoiceCredit=document.querySelector("#invoice-credit");
   var userAmount=0;
   if(userData!=null){
    userAmount=userData.availablecredit;
   }
   if(invoice.invoices[0]){ 
   if(transTabel){ 
    
   if(invoice.invoices[0].transs.length<=0){
     transTabel.classList.add("d-none");
   }else{
    transTabel.classList.remove("d-none");
   }
  } 
 // //console.log(parseFloat(invoice.invoices[0].rowtotal));
   if(invoiceCredit){
   if(parseFloat(userAmount)>parseFloat(invoice.invoices[0].rowtotal)){
    invoiceCredit.classList.remove("d-none");
   }else{
    invoiceCredit.classList.add("d-none");
   }
  }
  } 
  var drawerElement = document.querySelector("#invoice_drawer");
      drawerElement.classList.toggle("drawer-on");
      drawerElement.style.width='1100px';
  
}
const applyCreditAmount=async(id)=>{ 
  swal({
    title:"Are you sure?",
    text: "Once Applied, your available credit will reduce according to this amount!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
    })
    .then(async(willDelete) => {
    if(willDelete) {
      const dt=toast.loading("Invoice processing..."); 
      const dnsp=await UsePost('post','billing/applycredit','applycredit'); 
      if(dnsp.status=='error'){
       toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
     });
     }else{
       toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
     });  
     }
    }else {
      swal({
          title: "Apply Credit",
          text: "Your not applied for the invoice!",
          icon: "success",
          button: "OK",
        });
    }
    });
  
} 
const Razorpay = useRazorpay();
const invoicePayment = useCallback(async() => {  
  const payInvoiceBtn=document.querySelector("#invoice-payment-button");
  if(payInvoiceBtn){
    payInvoiceBtn.value="Loading...";
  }
  const u_data=await UseApi('get','account/info',''); 
  const uData=u_data.user;
  const u_razorpay_info=await UseApi('get','mhcsecinfo','');
   
 ////console.log(razorpay_info); 
  const amountFund=document.querySelector("#invoiceAmountFund");
  const descp_invoice_id=document.querySelector("#invoice_id");
  var invoice_id="";
  //console.log(descp_invoice_id.value);
  if(descp_invoice_id){
     invoice_id= descp_invoice_id.value;  
  } 
  var rorder_id="";
  var rfullname="";
  var rmobile="";
  var remail="";
  var ramount=0;
  var rcurrency="";
  if(amountFund){
    ramount=amountFund.value;
  }

  if(uData){
  rorder_id=uData.razorpay_orderid;
  rfullname=uData.fullname;
  rmobile=uData.mobile;
  remail=uData.email;
  rcurrency=uData.currency;
  }
  // //console.log(rorder_id);
  // //console.log(rfullname);
  // //console.log(rmobile);
  // //console.log(remail);
  // //console.log(ramount);
  // //console.log(invoice_id);
  const options = { 
    //key: "rzp_live_aeMFERiIAsYFFF",
    key: u_razorpay_info.razorpay_key,
    amount: ramount*100,
    currency: "INR",
    name: "Utho Cloud",
    description: invoice_id,
    image: "https://www.utho.com/assets/favicon/apple-touch-icon.png", 
    prefill: {
      name: rfullname,
      email: remail,
      contact: rmobile,
      },
    options: {
      checkout: {
        method: {
          netbanking: 1,
          card: 0,
          upi: 1,
        }
      }
    },
    handler: async(res) => {
      //res.razorpay_payment_id
      //res.status_code
      //pay_L0C8DacFM1Voqi
      //console.log(res);
      if(res.status_code==200){ 
        swal({
          title: "Processing please wait...",
          icon: spinner,
          html:true,
          showSpinner: true
     });
          const resData=await UsePostData('post','billing/addpayment',{'platform':'2023','invoiceid':invoice_id,'transid':res.razorpay_payment_id,'amount':ramount,'currency':rcurrency,'gateway':'Razorpay'});
          if(resData.status=="success"){ 
            swal({
                title: "Invoice",
                text: "Invoice paid successfuly",
                icon: "success",
                button: "OK",
              });
              setTimeout(() => {
                navigate("/cloud");
              }, 4000);
          }else{ 
            swal({
                title: "Invoice",
                text: "Invoice not paid!",
                icon: "warning",
                button: "OK",
              });
          }
          
          
          //console.log(resData);
          // const paymentInfo=await axios.get('https://'+u_razorpay_info.razorpay_key+':'+u_razorpay_info.razorpay_secret+'@api.razorpay.com/v1/payments/'+res.razorpay_payment_id+'');
        // //console.log(paymentInfo);
        }
    },
    notes: {
      address: "Utho Cloud- Fund add",
    },
    theme: {
      color: "#377dff",
    },
  };

  const rzpay = new Razorpay(options);
  rzpay.open();
  if(payInvoiceBtn){
    payInvoiceBtn.value="Pay";
  } 
}, [Razorpay]);
    return(
        <> 
        {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
        <div
        className="p-5 d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
         {/*begin::Content*/}
  <div className="d-flex flex-column flex-column-fluid" id="kt_content">
  
<div className="container-xxl"> 
        <div className="row">
        <div className="col-xl-9">
  <form id="clouddeploy">
    {/*begin::Tables Widget 9*/}
    <div className="card card-xl-stretch mb-5 mb-xl-12">
      {/*begin::Header*/}
      <div className="card-header border-0 mb-5 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">
            Select DC Location
          </span>
          <span className="text-muted mt-1 fw-bold fs-7">Data Centeres</span>
        </h3>
         
      </div>
      {/*end::Header*/}
      {/* Begin body */}
      <div className="container">
        {/*begin::Row*/}
        <div
          id="dc-location"
          className="row g-9"
          data-kt-buttons="true"
          data-kt-buttons-target="[data-kt-button]"
        >
           

           {adcdata.length>0?(adcdata && adcdata.map((value,index)=>(
                <>
                <div className="col-md-4 col-lg-4 col-xxl-4 dc_sibling">
                  <input
                    className="form-check-input deploy_checkbox "
                    type="radio"
                    name="dcslug"
                    defaultValue={value.slug}
                    id={"dcslug"+value.slug+""} 
                  />
                  <label
                  onClick={()=>selectDc(value.slug)}
                    id={"dcslugl_"+value.slug+""}
                    htmlFor={"dcslug"+value.slug+""}
                    className="dcsluglable btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start"
                    data-kt-button="true"
                  >
                    
                    {/*begin::Radio button*/}
                    <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start">
                      
                    </span>
                    {/*end::Radio button*/}
                    <span className="ms-5">
                      
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center">
                        
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-50px me-5">
                          
                          <img
                            src={"/assets/media/flags/"+value.country.toLowerCase().replaceAll(' ','-')+".svg"}
                            className=""
                            alt=""
                          />
                        </div>
                        {/*end::Avatar*/} {/*begin::Text*/}
                        <div className="flex-grow-1">
                          
                          <a  className="text-dark fw-bolder text-hover-primary fs-6">
                            {value.city}
                          </a>
                          <span className="text-muted d-block fw-bold">
                            {value.country}
                          </span>
                          <span className="text-muted d-block fw-bold deploylocationsoldoutmsg"></span>
                        </div>
                        {/*end::Text*/}
                      </div>
                      {/*end::Item*/}
                    </span>
                  </label>
                </div>  
                </>
              ))):('')}
{ddcdata.length>0?(ddcdata && ddcdata.map((value,index)=>(
                <>
                <div className="col-md-4 col-lg-4 col-xxl-4 dc_sibling">
                  <input
                    className="form-check-input deploy_checkbox deploylocationsoldout"
                    type="radio"
                    name="dcslug"
                    onClick={(e)=>e.target.checked=false}
                    defaultValue={value.slug}
                    id={"dcslug"+value.slug+""} 
                  />
                  <label
                    id={"dcslugl_"+value.slug+""}
                    htmlFor={"dcslug"+value.slug+""}
                    className="dcsluglable btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start"
                    data-kt-button="true"
                  >
                    
                    {/*begin::Radio button*/}
                    <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start">
                      
                    </span>
                    {/*end::Radio button*/}
                    <span className="ms-5">
                      
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center">
                        
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-50px me-5">
                          
                          <img
                            src={"/assets/media/flags/"+value.country.toLowerCase().replaceAll(' ','-')+".svg"}
                            className=""
                            alt=""
                          />
                        </div>
                        {/*end::Avatar*/} {/*begin::Text*/}
                        <div className="flex-grow-1">
                          
                          <a  className="text-dark fw-bolder text-hover-primary fs-6">
                            {value.city}
                          </a>
                          <span className="text-muted d-block fw-bold">
                            {value.country}
                          </span>
                          <span className="text-muted d-block fw-bold deploylocationsoldoutmsg" style={{fontSize:"10px"}}>
                            Temporarily unavailable
                          </span> 
                        </div>
                        {/*end::Text*/}
                      </div>
                      {/*end::Item*/}
                    </span>
                  </label>
                </div>  
                </>
              ))):('')}

        </div>
        {/*end::Row*/}
      </div>
      {/* End body */}
      {/*begin::Col*/}
      <div className="col-xl-12 mb-xl-12">
        {/*begin::List widget 10*/}
        {/*begin::Header*/}
        <div className="card-header border-0 mb-5 pt-10">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
              Select OS/APPS
            </span>
            <span className="text-gray-400 mt-1 fw-bold fs-6">
              Operating System, ISO, Snapshot, Backups
            </span>
          </h3>
          {/*end::Title*/}
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body">
          {/*begin::Nav*/}
          <ul className="nav nav-pills nav-pills-custom row position-relative mx-0 mb-9">
            {/*begin::Item*/}
            <li className="nav-item col-3 mx-0 p-0">
              {/*begin::Link*/}
              <a
                onclick="market_action(0)"
                className="nav-link active d-flex justify-content-center w-100 border-0 h-100"
                data-bs-toggle="pill"
                href="#distros-tab"
              >
                {/*begin::Subtitle*/}
                <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
                  Operating System
                </span>
                {/*end::Subtitle*/}
                {/*begin::Bullet*/}
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                {/*end::Bullet*/}
              </a>
              {/*end::Link*/}
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className="nav-item col-2 mx-0 px-0">
              {/*begin::Link*/}
              <a
                onclick="market_action(0)"
                className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                data-bs-toggle="pill"
                href="#iso-tab"
              >
                {/*begin::Subtitle*/}
                <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                  ISO
                </span>
                {/*end::Subtitle*/}
                {/*begin::Bullet*/}
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                {/*end::Bullet*/}
              </a>
              {/*end::Link*/}
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className="nav-item col-2 mx-0 px-0">
              {/*begin::Link*/}
              <a
                onclick="market_action(0)"
                className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                data-bs-toggle="pill"
                href="#snapshot-tab"
              >
                {/*begin::Subtitle*/}
                <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                  Snapshot
                </span>
                {/*end::Subtitle*/}
                {/*begin::Bullet*/}
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                {/*end::Bullet*/}
              </a>
              {/*end::Link*/}
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className="nav-item col-2 mx-0 px-0">
              {/*begin::Link*/}
              <a
                onclick="market_action(0)"
                className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                data-bs-toggle="pill"
                href="#backups-tab"
              >
                {/*begin::Subtitle*/}
                <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                  Backups
                </span>
                {/*end::Subtitle*/}
                {/*begin::Bullet*/}
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                {/*end::Bullet*/}
              </a>
              {/*end::Link*/}
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className="nav-item col-2 mx-0 px-0 d-none">
              {/*begin::Link*/}
              <a
                onclick="market_action(1)"
                className="nav-link d-flex justify-content-center w-100 border-0 h-100 "
                data-bs-toggle="pill"
                href="#marketplace-tab"
              >
                {/*begin::Subtitle*/}
                <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                  Marketplace
                </span>
                {/*end::Subtitle*/}
                {/*begin::Bullet*/}
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                {/*end::Bullet*/}
              </a>
              {/*end::Link*/}
            </li>
            {/*end::Item*/}
            {/*begin::Bullet*/}
            <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded" />
            {/*end::Bullet*/}
          </ul>
          {/*end::Nav*/}
          {/*begin::Tab Content*/}
          <div className="tab-content">
            {/*begin::Tap pane*/}
            <div className="tab-pane fade show active" id="distros-tab">
              {/*begin::Row*/}
              <input
                type="hidden"
                class="form-control"
                id="os_cost"
                defaultValue={props.deployInfo && props.deployInfo?(props.deployInfo[0].os_cost):("")}
               />
              <div
                id="distros"
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
           {distroData!=null && (distroData && distroData.map((value,index)=>(
                  <>
                {/*begin::Col*/}
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  
                  <input
                    type="radio"
                    name="imageOS"
                    id={"select_"+value.distribution}
                    defaultValue=""
                    className="deploy_checkbox radio_image" 
                  />
                  <label
                    onClick={()=>osVersion(value.distribution)}
                    className="versionOs btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start"
                    id={"versionOs_"+value.distribution}
                    data-kt-button="true"
                  >
                    
                    <span className="ms-5">
                      
                      {/*begin::Item*/}
                      <div className="d-flex  ">
                        
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-50px me-5">
                          
                          <span className={"fonticon_"+value.distro} />
                        </div>
                        {/*end::Avatar*/} {/*begin::Text*/}
                        <div className="flex-grow-1">
                          
                          <a className="text-dark fw-bolder text-hover-primary fs-6">
                            {value.distribution}
                          </a>
                          <span className="text-muted d-block fw-bold deploy_checkbox_line2 osversionselectmultiple" id={"osversion_"+value.distribution}>
                            
                            Select Version
                          </span>
                          <span className="deploy_osversioncontainer" id={"os_version_list_"+value.distribution}>
                         
                          {value.images && value.images.map((value2,index2)=>(
                            <>
                             <span
                              className="deploy_osversionselector"
                              data-image={value2.image}
                              data-oscost={value2.cost}
                              data-title={value2.distribution}
                              onClick={(e)=>{e.stopPropagation();selectOs(value2.image, value2.distribution,value2.cost)}}
                              >
                              {value2.version}
                              {value2.cost>0?(<>
                                <span class="badge badge-warning">{value2.cost}/Mo</span>
                              </>):('')}
                            </span>
                            </>
                          ))}  
                          </span>
                        </div>
                        {/*end::Text*/}
                      </div>
                      {/*end::Item*/}
                    </span>
                  </label>
                </div>
                {/*end::Col*/}
                </>
                )))}
              </div>
              {/*end::Row*/}
              <input
            type="hidden"
            name="image"
            id="select_image"
            defaultValue={props.deployInfo && props.deployInfo?(props.deployInfo[0].select_image):('')}
            className="deploy_checkbox" 
          />
            </div>
            {/*end::Tap pane*/}
            {/*begin::Tap pane*/}
            <div className="tab-pane fade" id="iso-tab">
              <div
                id="getiso"
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                {isoData!=null && Object.keys(isoData).length!=0?(Object.entries(isoData)[0][1].map((value,index)=>(<>
                
                  <div className="col-md-4 col-lg-12 col-xxl-4">
  
  {/*begin::Option*/}
  <input
    className="btn-check deploy_checkbox"
    type="radio"
    name="iso"
    defaultValue={value.file}
    id={"iso"+value.file} 
  />
  <label
    htmlFor={"iso"+value.file}
    className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
  >
    {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
    <span className="svg-icon svg-icon-primary svg-icon-4x me-4">
      
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M19 10C18.9 10 18.9 10 18.8 10C18.9 9.7 19 9.3 19 9C19 7.3 17.7 6 16 6C15.4 6 14.8 6.2 14.3 6.5C13.4 5 11.8 4 10 4C7.2 4 5 6.2 5 9C5 9.3 5.00001 9.7 5.10001 10H5C3.3 10 2 11.3 2 13C2 14.7 3.3 16 5 16H9L13.4 20.4C14 21 15 20.6 15 19.8V16H19C20.7 16 22 14.7 22 13C22 11.3 20.7 10 19 10Z"
          fill="black"
        />
      </svg>
    </span>
    {/*end::Svg Icon*/}
    <span className="d-block fw-bold text-start">
      
      <span className="text-dark fw-bolder d-block fs-3">{value.name}</span>
      <span className="text-muted fw-bold fs-6">{value.added_at}</span>
    </span>
  </label>
  {/*end::Option*/}
</div>

                </>))):(<>

                {/*begin::Content*/}
                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                  {/*begin::Wrapper*/}
                  <div className="pt-lg-10 mb-10">
                    {/*begin::Message*/}
                    <div className="fw-bold fs-3  mb-15 badge badge-light-primary">
                      Please select Datacenter Location to view ISO list.
                    </div>
                    {/*end::Message*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Illustration*/}
                  <div
                    className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"
                    style={{
                      backgroundImage:
                        "url(/assets/media/illustrations/sketchy-1/17.png"
                    }}
                  />
                  {/*end::Illustration*/}
                </div>
                {/*end::Content*/}
                </>)}
              </div>
            </div>
            {/*end::Tap pane*/}
            {/*begin::Tap pane*/}
            <div className="tab-pane fade" id="snapshot-tab">
              <div id="getsnapshot" className="row g-9" data-kt-buttons="true">
              {snapData!=null && Object.keys(snapData).length!=0?(Object.entries(snapData)[0][1].map((value,index)=>(<>
              
                <div className="col-md-4 col-lg-12 col-xxl-4">
  
  <input
    className="btn-check deploy_checkbox"
    type="radio"
    name="snapshotid"
    defaultValue={value.id}
    id={"snapshotid"+value.id}
     />
  <label
    htmlFor={"snapshotid"+value.id}
    className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
  >
    
    <span className="svg-icon svg-icon-primary svg-icon-4x me-4">
      
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        
        <path
          opacity="0.3"
          d="M22 5V19C22 19.6 21.6 20 21 20H19.5L11.9 12.4C11.5 12 10.9 12 10.5 12.4L3 20C2.5 20 2 19.5 2 19V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5ZM7.5 7C6.7 7 6 7.7 6 8.5C6 9.3 6.7 10 7.5 10C8.3 10 9 9.3 9 8.5C9 7.7 8.3 7 7.5 7Z"
          fill="black"
        />
        <path
          d="M19.1 10C18.7 9.60001 18.1 9.60001 17.7 10L10.7 17H2V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V12.9L19.1 10Z"
          fill="black"
        />
      </svg>
    </span>
    <span className="d-block fw-bold text-start">
      
      <span className="text-dark fw-bolder d-block fs-3">{value.id}</span>
      <span className="text-muted fw-bold fs-6"> {value.createdate} </span>
    </span>
  </label>
</div>

              </>))):(<>
                {/*begin::Content*/}
                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                  {/*begin::Wrapper*/}
                  <div className="pt-lg-10 mb-10">
                    {/*begin::Message*/}
                    <div className="fw-bold fs-3  mb-15 badge badge-light-primary">
                      Please select Datacenter Location to view snapshots list.
                    </div>
                    {/*end::Message*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Illustration*/}
                  <div
                    className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"
                    style={{
                      backgroundImage:
                        "url(/assets/media/illustrations/sketchy-1/17.png"
                    }}
                  />
                  {/*end::Illustration*/}
                </div>
                {/*end::Content*/}
                </>)}
              </div>
            </div>
            {/*end::Tap pane*/}
            {/*begin::Tap pane*/}
            <div className="tab-pane fade" id="backups-tab">
              <div id="getbackup" className="row g-9" data-kt-buttons="true">
              {backupData!=null && Object.keys(backupData).length!=0?(Object.entries(backupData)[0][1].map((value,index)=>(<>
                <div className="col-md-4 col-lg-12 col-xxl-4">
  
  {/*begin::Option*/}
  <input
    className="btn-check deploy_checkbox"
    type="radio"
    name="backupid"
    defaultValue={value.id}
    id={"backupid"+value.id}
     />
  <label
    htmlFor={"backupid"+value.id}
    className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
  >
    {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
    <span className="svg-icon svg-icon-primary svg-icon-4x me-4">
      
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          opacity="0.3"
          d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM6.39999 9.89999C6.99999 8.19999 8.40001 6.9 10.1 6.4C10.6 6.2 10.9 5.7 10.7 5.1C10.5 4.6 9.99999 4.3 9.39999 4.5C7.09999 5.3 5.29999 7 4.39999 9.2C4.19999 9.7 4.5 10.3 5 10.5C5.1 10.5 5.19999 10.6 5.39999 10.6C5.89999 10.5 6.19999 10.2 6.39999 9.89999ZM14.8 19.5C17 18.7 18.8 16.9 19.6 14.7C19.8 14.2 19.5 13.6 19 13.4C18.5 13.2 17.9 13.5 17.7 14C17.1 15.7 15.8 17 14.1 17.6C13.6 17.8 13.3 18.4 13.5 18.9C13.6 19.3 14 19.6 14.4 19.6C14.5 19.6 14.6 19.6 14.8 19.5Z"
          fill="black"
        />
        <path
          d="M16 12C16 14.2 14.2 16 12 16C9.8 16 8 14.2 8 12C8 9.8 9.8 8 12 8C14.2 8 16 9.8 16 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
          fill="black"
        />
      </svg>
    </span>
    {/*end::Svg Icon*/}
    <span className="d-block fw-bold text-start">
      
      <span className="text-dark fw-bolder d-block fs-3">{value.id}</span>
      <span className="text-muted fw-bold fs-6"> {value.created_at}</span>
    </span>
  </label>
  {/*end::Option*/}
</div>

              
              </>))):(<>
                {/*begin::Content*/}
                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                  {/*begin::Wrapper*/}
                  <div className="pt-lg-10 mb-10">
                    {/*begin::Message*/}
                    <div className="fw-bold fs-3  mb-15 badge badge-light-primary">
                      Please select Datacenter Location to view backup list.
                    </div>
                    {/*end::Message*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Illustration*/}
                  <div
                    className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"
                    style={{
                      backgroundImage:
                        "url(/assets/media/illustrations/sketchy-1/17.png"
                    }}
                  />
                  {/*end::Illustration*/}
                </div>
                {/*end::Content*/}
                </>)}
              </div>
            </div>
            {/*end::Tap pane*/}
            {/*begin::Tap pane*/}
            <div className="tab-pane fade" id="marketplace-tab">
              {/*begin::Row*/}
              <div
                id="marketplace-data"
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                {/*begin::Col*/}
                <div
                  className="col-md-4 col-lg-4 col-xxl-4 market_place"
                  onclick="selectOs(9)"
                >
                  
                  <label
                    id="os_select9"
                    onclick="event.preventDefault(); marketplace_fields('cPanel');"
                    className="btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start"
                    data-kt-button="true"
                  >
                    
                    <span className="ms-5">
                      
                      {/*begin::Item*/}
                      <div className="d-flex  ">
                        
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-50px me-5">
                          <img
                            src="/assets/marketplace/cpanel.svg"
                            className=""
                            alt=""
                          />
                        </div>
                        {/*end::Avatar*/} {/*begin::Text*/}
                        <div className="flex-grow-1">
                          
                          <a  className="text-dark fw-bolder text-hover-primary fs-6">
                            cPanel
                          </a>
                        </div>
                        {/*end::Text*/}
                      </div>
                      {/*end::Item*/}
                    </span>
                  </label>
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div
                  className="col-md-4 col-lg-4 col-xxl-4 market_place"
                  onclick="selectOs(5)"
                >
                  
                  <label
                    id="os_select5"
                    onclick="event.preventDefault(); marketplace_fields('WordPress');"
                    className="btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start"
                    data-kt-button="true"
                  >
                    
                    <span className="ms-5">
                      
                      {/*begin::Item*/}
                      <div className="d-flex  ">
                        
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-50px me-5">
                          <img
                            src="/assets/marketplace/WordPress.svg"
                            className=""
                            alt=""
                          />
                        </div>
                        {/*end::Avatar*/} {/*begin::Text*/}
                        <div className="flex-grow-1">
                          
                          <a  className="text-dark fw-bolder text-hover-primary fs-6">
                            WordPress
                          </a>
                        </div>
                        {/*end::Text*/}
                      </div>
                      {/*end::Item*/}
                    </span>
                  </label>
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
            </div>
            {/*end::Tap pane*/}
          </div>
          {/*end::Tab Content*/}
        </div>
        {/*end: Card Body*/}
        {/*end::List widget 10*/}
      </div>
      {/*end::Col*/}
      {/*begin::Col*/}
      <div className="col-xl-12 mb-xl-12" id="market_fields"></div>
      {/*begin::Col*/}
      {/*begin::Col*/}
      <div className="col-xl-12">
        {/*begin::List widget 10*/}
        {/*begin::Header*/}
        <div className="card-header border-0 mb-5 pt-5">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
              Select Cloud Size
            </span>
            <span className="text-gray-400 mt-1 fw-bold fs-6">
              Standard, High Memory, Dedicated CPU
            </span>
          </h3>
        </div>
        {/*end::Header*/}
        <div className="">
          {/*begin::Body*/}
          {/*begin::Nav*/}
          <ul className="nav nav-pills nav-pills-custom row position-relative mx-0 mb-9">
            {/*begin::Item*/}
            <li className="nav-item col-2 mx-0 p-0">
              {/*begin::Link*/}
              <a
                className="nav-link active d-flex justify-content-center w-100 border-0 h-100"
                data-bs-toggle="pill"
                href="#basic-tab"
              >
                {/*begin::Subtitle*/}
                <span className="nav-text text-gray-800 fw-bolder fs-6 mb-3">
                  Basic
                </span>
                {/*end::Subtitle*/}
                {/*begin::Bullet*/}
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                {/*end::Bullet*/}
              </a>
              {/*end::Link*/}
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className="nav-item col-4 mx-0 px-0">
              {/*begin::Link*/}
              <a
                className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                data-bs-toggle="pill"
                href="#dedicated-standard-tab"
              >
                {/*begin::Subtitle*/}
                <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
                  Dedicated Standard
                </span>
                {/*end::Subtitle*/}
                {/*begin::Bullet*/}
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                {/*end::Bullet*/}
              </a>
              {/*end::Link*/}
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className="nav-item col-2 mx-0 px-0">
              {/*begin::Link*/}
              <a
                className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                data-bs-toggle="pill"
                href="#dedicated-cpu-tab"
              >
                {/*begin::Subtitle*/}
                <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
                  Dedicated CPU
                </span>
                {/*end::Subtitle*/}
                {/*begin::Bullet*/}
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                {/*end::Bullet*/}
              </a>
              {/*end::Link*/}
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className="nav-item col-4 mx-0 px-0">
              {/*begin::Link*/}
              <a
                className="nav-link d-flex justify-content-center w-100 border-0 h-100"
                data-bs-toggle="pill"
                href="#dedicated-memory-tab"
              >
                {/*begin::Subtitle*/}
                <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
                  Dedicated Memory
                </span>
                {/*end::Subtitle*/}
                {/*begin::Bullet*/}
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
                {/*end::Bullet*/}
              </a>
              {/*end::Link*/}
            </li>
            {/*end::Item*/}
            {/*begin::Bullet*/}
            <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded" />
            {/*end::Bullet*/}
          </ul>
          {/*end::Nav*/}
          {/*begin::Tab Content*/}
          <div className="tab-content">
          <input
                type="hidden"
                name="planid"
                id="plan_value"
                defaultValue={props.deployInfo && props.deployInfo?(props.deployInfo[0].plan_value):('')}
              />
              <input
                type="hidden" 
                id="plan_cost"
                defaultValue={props.deployInfo && props.deployInfo?(props.deployInfo[0].plan_cost):('')}
              />
            {/*begin::Tap pane*/}
            <div className="tab-pane fade show active" id="basic-tab">
              {/*begin::Radio group*/}
              <div className="row" data-kt-buttons="true">
                <div className="table-responsive" id="ramcpuplans">
                  {/*begin::Table*/}
                  <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                    {/*begin::Thead*/}
                    <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr>
                        <th className="min-w-55px ps-0 text-center" />
                        <th className="min-w-125px px-0 text-center">RAM</th>
                        <th className="min-w-125px">vCPU</th>
                        <th className="min-w-125px">SSD Disk</th>
                        <th className="min-w-125px">Bandwidth</th>
                        <th className="min-w-125px ps-0 text-center">Plan </th>
                        <th className="min-w-125px ps-0 text-center">Price</th>
                      </tr>
                    </thead>
                    {/*end::Thead*/}
                    {/*begin::Tbody*/}
                    <tbody
                      id="basic-plan"
                      className="fs-6 fw-bold text-gray-600"
                    >
                      {planData!=null && (planData && planData.map((value,index)=>(
                        <>
                        {value.slug=='basic'?(<>
                        <tr 
                        onClick={()=>show_plan_price(value.id,value.price)}
                        className="planseffect tick-parent" id={"tick_parent_"+value.id}
                        >
                        <td class="ps-0 text-center">
                         <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_"+value.id}></span>
                        </td>
                        <td className="ps-0 text-center">{value.ram} MB</td>
                        <td>{value.cpu} vCPU</td> 
                        <td>{value.disk} GB</td> 
                        <td>{value.bandwidth} GB</td>
                        <td>
                          
                          <select
                            onChange={()=>show_plan_price(value.id,value.price)}
                            id={"change_plan_"+value.id} 
                            className="form-select form-select-sm"
                          >
                            
                        {user_data.current!=null?(<>
                            <option value="36month" selected="selected">
                              3 Years {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*40)/100)).toFixed(2)} 40% OFF
                            </option>
                            <option value="12month">
                              1 Years {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*25)/100)).toFixed(2)} 25% OFF
                            </option>
                            <option value="3months">
                            3 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*10)/100)).toFixed(2)} 10% OFF
                            </option>
                            <option value="monthly">
                            Monthly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*5)/100)).toFixed(2)} 5% OFF
                            </option>
                            <option value="hourly">Hourly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)/720).toFixed(2)}</option>
                            </>):('')}
                          </select>
                        </td>
                        <td>
                          <div className="row">
                            <div className="col-lg-2 col-xxl-2"> 
                            </div>
                            <div className="col-lg-10 col-xxl-10">
                              <span
                                className="badge badge-light-primary fs-7 fw-bolder"
                                style={{ marginRight: 5 }}
                                id={"updated_plan_price_"+value.id}
                              >
                                {user_data.current!=null?(<>{user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*40)/100)).toFixed(2)}/mon</>):('')}
                              </span>
                              <span
                                className="badge badge-light-danger fs-7 fw-bolder"
                                id={"actual_price_"+value.id} 
                              >
                                <del>{user_data.current!=null?(<>{user_data.current.user.currencyprefix} {parseFloat(value.price).toFixed(2)}/mon</>):('')}</del>
                              </span>
                              <br />
                              <span className="text-warning" id={"off_"+value.id}>
                                40% OFF
                              </span>
                              <span
                                className="badge badge-success fs-base d-none"
                                id="free_trial_10008"
                              />
                            </div>
                            <div />
                          </div>
                        </td>
                      </tr>
                      </>
                        ):('')}
                      </>
                      )))}
                    </tbody>
                    {/*end::Tbody*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Radio group*/}
            </div>
            {/*end::Tap pane*/}
            {/*begin::Tap pane*/}
            <div className="tab-pane fade" id="dedicated-standard-tab">
              {/*begin::Radio group*/}
              <div className="row" data-kt-buttons="true">
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                    {/*begin::Thead*/}
                    <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr>
                        <th className="min-w-55px ps-0 text-center" />
                        <th className="min-w-125px px-0 text-center">RAM</th>
                        <th className="min-w-125px">vCPU</th>
                        <th className="min-w-125px">SSD Disk</th>
                        <th className="min-w-125px">Bandwidth</th>
                        <th className="min-w-125px ps-0 text-center">Plan </th>
                        <th className="min-w-125px ps-0 text-center">Price</th>
                      </tr>
                    </thead>
                    {/*end::Thead*/}
                    {/*begin::Tbody*/}
                    <tbody
                      id="standard-plan"
                      className="fs-6 fw-bold text-gray-600"
                    >
                       {planData!=null && (planData && planData.map((value,index)=>(
                        <>
                        {value.slug=='dedicated-standard'?(<>
                        <tr 
                        onClick={()=>show_plan_price(value.id,value.price)}
                        className="planseffect tick-parent" id={"tick_parent_"+value.id}
                        >
                        <td class="ps-0 text-center">
                         <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_"+value.id}></span>
                        </td>
                        <td className="ps-0 text-center">{value.ram} MB</td>
                        <td>{value.cpu} vCPU</td> 
                        <td>{value.disk} GB</td> 
                        <td>{value.bandwidth} GB</td>
                        <td>
                          
                          <select
                            onChange={()=>show_plan_price(value.id,value.price)}
                            id={"change_plan_"+value.id} 
                            className="form-select form-select-sm"
                          >
                            
                        {user_data.current!=null?(<>
                            <option value="36month" selected="selected">
                              3 Years {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*40)/100)).toFixed(2)} 40% OFF
                            </option>
                            <option value="12month">
                              1 Years {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*25)/100)).toFixed(2)} 25% OFF
                            </option>
                            <option value="3months">
                            3 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*10)/100)).toFixed(2)} 10% OFF
                            </option>
                            <option value="monthly">
                            Monthly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*5)/100)).toFixed(2)} 5% OFF
                            </option>
                            <option value="hourly">Hourly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)/720).toFixed(2)}</option>
                            </>):('')}
                          </select>
                        </td>
                        <td>
                          <div className="row">
                            <div className="col-lg-2 col-xxl-2"> 
                            </div>
                            <div className="col-lg-10 col-xxl-10">
                              <span
                                className="badge badge-light-primary fs-7 fw-bolder"
                                style={{ marginRight: 5 }}
                                id={"updated_plan_price_"+value.id}
                              >
                                {user_data.current!=null?(<>{user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*40)/100)).toFixed(2)}/mon</>):('')}
                              </span>
                              <span
                                className="badge badge-light-danger fs-7 fw-bolder"
                                id={"actual_price_"+value.id} 
                              >
                                <del>{user_data.current!=null?(<>{user_data.current.user.currencyprefix} {parseFloat(value.price).toFixed(2)}/mon</>):('')}</del>
                              </span>
                              <br />
                              <span className="text-warning" id={"off_"+value.id}>
                                40% OFF
                              </span>
                              <span
                                className="badge badge-success fs-base d-none"
                                id="free_trial_10008"
                              />
                            </div>
                            <div />
                          </div>
                        </td>
                      </tr>
                      </>
                        ):('')}
                      </>
                      )))}
                       
                    </tbody>
                    {/*end::Tbody*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Radio group*/}
            </div>
            {/*end::Tap pane*/}
            {/*begin::Tap pane*/}
            <div className="tab-pane fade" id="dedicated-memory-tab">
              {/*begin::Radio group*/}
              <div className="row" data-kt-buttons="true">
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table  table-row-bordered table-flush align-middle gy-6 tableramcpu">
                    {/*begin::Thead*/}
                    <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr>
                        <th className="min-w-55px ps-0 text-center" />
                        <th className="min-w-125px px-0 text-center">RAM</th>
                        <th className="min-w-125px">vCPU</th>
                        <th className="min-w-125px">SSD Disk</th>
                        <th className="min-w-125px">Bandwidth</th>
                        <th className="min-w-125px ps-0 text-center">Plan </th>
                        <th className="min-w-125px ps-0 text-center">Price</th>
                      </tr>
                    </thead>
                    {/*end::Thead*/}
                    {/*begin::Tbody*/}
                    <tbody
                      id="memory-plan"
                      className="fs-6 fw-bold text-gray-600"
                    >
                        {planData!=null && (planData && planData.map((value,index)=>(
                        <>
                        {value.slug=='dedicated-memory'?(<>
                        <tr 
                        onClick={()=>show_plan_price(value.id,value.price)}
                        className="planseffect tick-parent" id={"tick_parent_"+value.id}
                        >
                        <td class="ps-0 text-center">
                         <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_"+value.id}></span>
                        </td>
                        <td className="ps-0 text-center">{value.ram} MB</td>
                        <td>{value.cpu} vCPU</td> 
                        <td>{value.disk} GB</td> 
                        <td>{value.bandwidth} GB</td>
                        <td>
                          
                          <select
                            onChange={()=>show_plan_price(value.id,value.price)}
                            id={"change_plan_"+value.id} 
                            className="form-select form-select-sm"
                          >
                            
                        {user_data.current!=null?(<>
                            <option value="36month" selected="selected">
                              3 Years {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*40)/100)).toFixed(2)} 40% OFF
                            </option>
                            <option value="12month">
                              1 Years {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*25)/100)).toFixed(2)} 25% OFF
                            </option>
                            <option value="3months">
                            3 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*10)/100)).toFixed(2)} 10% OFF
                            </option>
                            <option value="monthly">
                            Monthly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*5)/100)).toFixed(2)} 5% OFF
                            </option>
                            <option value="hourly">Hourly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)/720).toFixed(2)}</option>
                            </>):('')}
                          </select>
                        </td>
                        <td>
                          <div className="row">
                            <div className="col-lg-2 col-xxl-2"> 
                            </div>
                            <div className="col-lg-10 col-xxl-10">
                              <span
                                className="badge badge-light-primary fs-7 fw-bolder"
                                style={{ marginRight: 5 }}
                                id={"updated_plan_price_"+value.id}
                              >
                                {user_data.current!=null?(<>{user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*40)/100)).toFixed(2)}/mon</>):('')}
                              </span>
                              <span
                                className="badge badge-light-danger fs-7 fw-bolder"
                                id={"actual_price_"+value.id} 
                              >
                                <del>{user_data.current!=null?(<>{user_data.current.user.currencyprefix} {parseFloat(value.price).toFixed(2)}/mon</>):('')}</del>
                              </span>
                              <br />
                              <span className="text-warning" id={"off_"+value.id}>
                                40% OFF
                              </span>
                              <span
                                className="badge badge-success fs-base d-none"
                                id="free_trial_10008"
                              />
                            </div>
                            <div />
                          </div>
                        </td>
                      </tr>
                      </>
                        ):('')}
                      </>
                      )))}
                       
                    </tbody>
                    {/*end::Tbody*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Radio group*/}
            </div>
            {/*end::Tap pane*/}
            {/*begin::Tap pane*/}
            <div className="tab-pane fade" id="dedicated-cpu-tab">
              {/*begin::Radio group*/}
              <div className="row" data-kt-buttons="true">
                <div className="table-responsive" id="ramcpuplans">
                  {/*begin::Table*/}
                  <table className="table table-row-bordered table-flush align-middle gy-6 tableramcpu">
                    {/*begin::Thead*/}
                    <thead className="border-bottom border-gray-200 fs-6 fw-bolder bg-secondary">
                      <tr>
                        <th className="min-w-55px ps-0 text-center" />
                        <th className="min-w-125px px-0 text-center">RAM</th>
                        <th className="min-w-125px">vCPU</th>
                        <th className="min-w-125px">SSD Disk</th>
                        <th className="min-w-125px">Bandwidth</th>
                        <th className="min-w-125px ps-0 text-center">Plan </th>
                        <th className="min-w-125px ps-0 text-center">Price</th>
                      </tr>
                    </thead>
                    {/*end::Thead*/}
                    {/*begin::Tbody*/}
                    <tbody id="cpu-plan" className="fs-6 fw-bold text-gray-600">
                    {planData!=null && (planData && planData.map((value,index)=>(
                        <>
                        {value.slug=='dedicated-cpu'?(<>
                        <tr 
                        onClick={()=>show_plan_price(value.id,value.price)}
                        className="planseffect tick-parent" id={"tick_parent_"+value.id}
                        >
                        <td class="ps-0 text-center">
                         <span class="svg-icon svg-icon-success svg-icon-2hx tick_" id={"tick_"+value.id}></span>
                        </td>
                        <td className="ps-0 text-center">{value.ram} MB</td>
                        <td>{value.cpu} vCPU</td> 
                        <td>{value.disk} GB</td> 
                        <td>{value.bandwidth} GB</td>
                        <td>
                          
                          <select
                            onChange={()=>show_plan_price(value.id,value.price)}
                            id={"change_plan_"+value.id} 
                            className="form-select form-select-sm"
                          >
                            
                        {user_data.current!=null?(<>
                            <option value="36month" selected="selected">
                              3 Years {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*40)/100)).toFixed(2)} 40% OFF
                            </option>
                            <option value="12month">
                              1 Years {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*25)/100)).toFixed(2)} 25% OFF
                            </option>
                            <option value="3months">
                            3 Months {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*10)/100)).toFixed(2)} 10% OFF
                            </option>
                            <option value="monthly">
                            Monthly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*5)/100)).toFixed(2)} 5% OFF
                            </option>
                            <option value="hourly">Hourly {user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)/720).toFixed(2)}</option>
                            </>):('')}
                          </select>
                        </td>
                        <td>
                          <div className="row">
                            <div className="col-lg-2 col-xxl-2"> 
                            </div>
                            <div className="col-lg-10 col-xxl-10">
                              <span
                                className="badge badge-light-primary fs-7 fw-bolder"
                                style={{ marginRight: 5 }}
                                id={"updated_plan_price_"+value.id}
                              >
                                {user_data.current!=null?(<>{user_data.current.user.currencyprefix} {parseFloat(parseInt(value.price)-((parseInt(value.price)*40)/100)).toFixed(2)}/mon</>):('')}
                              </span>
                              <span
                                className="badge badge-light-danger fs-7 fw-bolder"
                                id={"actual_price_"+value.id} 
                              >
                                <del>{user_data.current!=null?(<>{user_data.current.user.currencyprefix} {parseFloat(value.price).toFixed(2)}/mon</>):('')}</del>
                              </span>
                              <br />
                              <span className="text-warning" id={"off_"+value.id}>
                                40% OFF
                              </span>
                              <span
                                className="badge badge-success fs-base d-none"
                                id="free_trial_10008"
                              />
                            </div>
                            <div />
                          </div>
                        </td>
                      </tr>
                      </>
                        ):('')}
                      </>
                      )))}
                       
                    </tbody>
                    {/*end::Tbody*/}
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Radio group*/}
            </div>
            {/*end::Tap pane*/}
          </div>
          {/*end::Tab Content*/}
        </div>
        {/*end: Card Body*/}
        {/*end::List widget 10*/}
      </div>
      {/*end::Col*/}
      {/*begin::Col*/}
      <div className="col-xl-12 mb-xl-12">
        {/*begin::List widget 10*/}
        <div className=" h-lg-100">
          {/*begin::Header*/}
          <div className="card-header">
            {/*begin::Title*/}
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder text-gray-800">
                SSH Key
              </span>
            </h3>
            {/*end::Title*/}
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body">
            {/*begin::Radio group*/}
            <div id="ssh-data" className="row" data-kt-buttons="true">
              {sshData!=null && (sshData && sshData.map((value,index)=>(
                <>
                <div className="col-md-4 col-lg-12 col-xxl-4 sshsibling pt-5">
  
  {/*begin::Radio button*/}
  <input
    className="form-check-input deploy_checkbox"
    id={"sshkeys"+value.id}
    type="radio"
    name="sshkeys"
    defaultValue={value.id} 
  />
  <label
    htmlFor={"sshkeys"+value.id}
    className="btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-stack text-start"
  >
    
    {/*end::Description*/}
    <div className="d-flex align-items-center me-2">
      
      {/*begin::Radio*/}
      <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
        
      </div>
      {/*end::Radio*/} {/*begin::Info*/}
      <div className="flex-grow-1">
        
        <h2 className="d-flex align-items-center fs-3 fw-bolder flex-wrap">
          
          {value.name}
        </h2>
      </div>
      {/*end::Info*/}
    </div>
    {/*end::Description*/} {/*begin::Price*/}
    <div className="ms-5">
      
      {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
      <span className="svg-icon svg-icon-primary svg-icon-4x me-4">
        
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 299.32 299.32"
          style={{ enableBackground: "new 0 0 299.32 299.32" }}
          xmlSpace="preserve"
        >
          
          <g>
            
            <path d="M59.52,128.087c-11.895,0-21.572,9.678-21.572,21.573s9.678,21.573,21.572,21.573s21.572-9.678,21.572-21.573 S71.414,128.087,59.52,128.087z M59.52,156.233c-3.624,0-6.572-2.949-6.572-6.573s2.948-6.573,6.572-6.573s6.572,2.949,6.572,6.573 S63.144,156.233,59.52,156.233z" />
            <path d="M297.349,150.704l-19.257-24.072c-1.708-2.135-4.294-3.378-7.028-3.378H151.472c-10.746-30.638-40.05-51.476-73.591-51.476 C34.938,71.778,0,106.716,0,149.66s34.938,77.882,77.881,77.882c28.516,0,53.866-14.475,67.487-38.145h21.145l18.085,18.731 c1.79,1.855,4.297,2.841,6.865,2.74c2.575-0.112,4.979-1.323,6.602-3.326l11.169-13.782l14.784,14.534 c3.48,3.422,9.056,3.445,12.564,0.053l19.604-18.95h14.878c2.734,0,5.32-1.243,7.028-3.378l19.257-24.071 C299.978,158.662,299.978,153.991,297.349,150.704z M266.738,171.397h-14.191c-2.334,0-4.577,0.907-6.255,2.529l-15.912,15.381 l-15.593-15.329c-1.811-1.778-4.28-2.706-6.817-2.568c-2.533,0.144-4.888,1.349-6.484,3.32l-11.003,13.578l-13.675-14.163 c-1.696-1.756-4.033-2.749-6.475-2.749H139.94c-3.465,0-6.622,1.989-8.118,5.115c-9.751,20.374-30.42,33.03-53.941,33.03 C44.862,209.542,18,182.679,18,149.66s26.862-59.882,59.881-59.882c27.695,0,51.613,18.362,58.167,44.653 c0.999,4.009,4.601,6.823,8.732,6.823h121.957l12.058,15.072L266.738,171.397z" />
          </g>
        </svg>
      </span>
      {/*end::Svg Icon*/}
    </div>
    {/*end::Price*/}
  </label>
  {/*end::Radio button*/}
</div>

                </>
              )))} 
              <a onClick={openDrawer} className="col-md-4 col-lg-4 col-xxl-4 pt-5">
                {/*begin::Radio button*/}
                <label className="btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-stack text-start">
                  {/*end::Description*/}
                  <div className="d-flex align-items-center me-2">
                    
                    {/*begin::Info*/}
                    <div className="flex-grow-1">
                      
                      <div className="fw-bold opacity-50"> Add New</div>
                    </div>
                    {/*end::Info*/}
                  </div>
                  {/*end::Description*/} {/*begin::Price*/}
                  <div className="ms-5">
                    
                    {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                    <span className="svg-icon svg-icon-4x me-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={10}
                          fill="black"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="black"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Price*/}
                </label>
                {/*end::Radio button*/}
              </a>
            </div>
            {/*end::Radio group*/}
          </div>
          {/*end: Card Body*/}
        </div>
        {/*end::List widget 10*/}
        {vpcData!=null?(<>
        {/*begin::List widget 10*/}
        <div className=" h-lg-100" id="vpc_section">
          {/*begin::Header*/}
          <div className="card-header">
            {/*begin::Title*/}
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder text-gray-800">
                VPC Network
              </span>
            </h3>
            {/*end::Title*/}
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body">
            {/*begin::Radio group*/}
            <div id="getvpc" className="row" data-kt-buttons="true">

             {Object.keys(vpcData).length!=0?(Object.entries(vpcData).map((value,index)=>(<>
              <div className="col-md-4 col-lg-12 col-xxl-4 mt-5">
  
  {/*begin::Option*/}
  <input
    className="btn-check deploy_checkbox"
    type="radio"
    name="vpc"
    defaultValue={value[1].id}
    id={"vpc"+value[1].id}
  />
  <label
    htmlFor={"vpc"+value[1].id}
    className="btn btn-outline btn-outline-dashed btn-outline-default  d-flex align-items-center"
  >
    {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
    <span className="svg-icon svg-icon-primary svg-icon-4x me-4">
      
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          opacity="0.3"
          d="M19 18C20.7 18 22 16.7 22 15C22 13.3 20.7 12 19 12C18.9 12 18.9 12 18.8 12C18.9 11.7 19 11.3 19 11C19 9.3 17.7 8 16 8C15.4 8 14.8 8.2 14.3 8.5C13.4 7 11.8 6 10 6C7.2 6 5 8.2 5 11C5 11.3 5.00001 11.7 5.10001 12H5C3.3 12 2 13.3 2 15C2 16.7 3.3 18 5 18H19Z"
          fill="black"
        />
      </svg>
    </span>
    {/*end::Svg Icon*/}
    <span className="d-block fw-bold text-start">
      
      <span className="text-dark fw-bolder d-block fs-3">
        {value[1].network}
      </span>
      <span className="text-muted fw-bold fs-6"> {value[1].name}</span>
    </span>
  </label>
  {/*end::Option*/}
</div>

             </>))):('')}

            </div>
            {/*end::Radio group*/}
          </div>
          {/*end: Card Body*/}
        </div>
        {/*end::List widget 10*/}
        </>):('')}
        {/*begin::List widget 10*/}
        {/*begin::Header*/}
        <div className="card-header pt-7">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
              Cloud Firewall
              <a id="applink" href="firewall">
                <span className="badge badge-primary">Manage</span>
              </a>
            </span>
          </h3>
          {/*end::Title*/}
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className=" row card-body">
          <div className="col-md-6 col-lg-6 col-xxl-6">
            <select
              className="form-select"
              id="get-firewall-list"
              name="firewall" 
            >
              <option>No Firewall Group</option>
              {firewallData!=null && (firewallData && firewallData.map((value,index)=>(
                <option value={value.id}>{value.name}</option>
              )))}
            </select>
          </div>
        </div>
        {/*end: Card Body*/}
        {/*end::List widget 10*/}
        {/*begin::List widget 10*/}
        {/*begin::Header*/}
        <div className="card-header pt-7">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
              Additional Features
            </span>
          </h3>
          {/*end::Title*/}
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className=" row card-body">
          <div className="col-md-6 col-lg-6 col-xxl-6">
            <div className="form-check form-check-custom ">
              <input
                className="form-check-input"
                type="checkbox"
                onClick={cloudCost} 
                name="enablebackup"
                id="selectBackupEnabled"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Enable Weekly backup
                <span className="badge badge-success">
                  Auto Weekly backup at 20% of basic cost of server.
                </span>
              </label>
            </div>
          </div>
        </div>
        {/*end: Card Body*/}
        {/*end::List widget 10*/}
        {/*begin::List widget 10*/}
        {/*begin::Header*/}
        <div className="card-header pt-7">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
              Server Management Support
            </span>
          </h3>
          {/*end::Title*/}
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="mt-5 p-1">
                  <input
                   type="hidden"
                   className="form-control"
                   defaultValue={props.deployInfo && props.deployInfo?(props.deployInfo[0].select_support_option):('')}
                   id="select_support_option" 
                  />
          {/*begin::Radio group*/}
          <div className="row" data-kt-buttons="true">
            <div className="col-md-4 col-lg-4 col-xxl-4 supportsibling">
              {/*begin::Radio button*/}
              <input
                className="form-check-input deploy_checkbox"
                type="radio" 
                defaultValue="basic"
                name="support"  
                id="management_basic" 
              />
               
              <label 
                onClick={()=>supportOp('basic')}
                htmlFor="management_basic"
                className="btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="In this support package we will manage basic OS areas like Users,Auth,Updates"
              >
                {/*end::Description*/}
                <div className="d-flex align-items-center me-2">
                  {/*begin::Radio*/}
                  <div className="form-check form-check-custom form-check-solid form-check-primary me-2"></div>
                  {/*end::Radio*/}
                  
                  {/*begin::Info*/}
                  <div className="flex-grow-1">
                    <div className="fw-bold ">Basic Managed</div>
                  </div>
                  {/*end::Info*/}
                </div>
                {/*end::Description*/}
                {/*begin::Price*/}
                <div className="ms-5" id="managed_os_cost">
                  <span className="mb-2">Rs.</span>
                  <span className="fs-2 fw-bolder">3995</span>
                  <span className="fs-7 opacity-50">
                    /<span data-kt-element="period">Mon</span>
                  </span>
                </div>
                {/*end::Price*/}
              </label>
              {/*end::Radio button*/}
            </div>
            <div className="col-md-4 col-lg-4 col-xxl-4 supportsibling">
              {/*begin::Radio button*/}
              <input
                className="form-check-input deploy_checkbox"
                type="radio" 
                defaultValue="full"
                name="support"
                id="management_full" 
              />
              <label
                onClick={()=>supportOp('full')}
                htmlFor="management_full"
                className="btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Complete management of server including OS, Control Panel, Databases Install or upgrades (MySQL,MSSQL)"
              >
                {/*end::Description*/}
                <div className="d-flex align-items-center me-2">
                  {/*begin::Radio*/}
                  <div className="form-check form-check-custom form-check-solid form-check-primary me-2"></div>
                  {/*end::Radio*/}
                  {/*begin::Info*/}
                  <div className="flex-grow-1">
                    <div className="fw-bold ">Fully Managed</div>
                  </div>
                  {/*end::Info*/}
                </div>
                {/*end::Description*/}
                {/*begin::Price*/}
                <div className="ms-5" id="managed_full_cost">
                  <span className="mb-2">Rs.</span>
                  <span className="fs-2 fw-bolder">6995</span>
                  <span className="fs-7 opacity-50">
                    /<span data-kt-element="period">Mon</span>
                  </span>
                </div>
                {/*end::Price*/}
              </label>
              {/*end::Radio button*/}
            </div>
            <div className="col-md-4 col-lg-4 col-xxl-4 supportsibling">
              {/*begin::Radio button*/}
              <input
                className="form-check-input deploy_checkbox supportselect"
                type="radio"
                onchange="refreshPlanDisplay();"
                defaultChecked=""
                defaultValue="unmanaged"
                name="support"
                id="management_self"
              />
              <label
                onClick={()=>supportOp('unmanaged')}
                htmlFor="management_self"
                className="btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Utho will provide only Infra level support (Compute,Storage,Network), OS needs to be managed by customer."
              >
                {/*end::Description*/}
                <div className="d-flex align-items-center me-2">
                  {/*begin::Radio*/}
                  <div className="form-check form-check-custom form-check-solid form-check-primary me-6"></div>
                  {/*end::Radio*/}
                  {/*begin::Info*/}
                  <div className="flex-grow-1">
                    <div className="fw-bold ">Un-Managed</div>
                    <span className="fs-9 opacity-50 badge badge-light-primary">
                    <span data-kt-element="period">
                      Only Infra Level Support
                    </span>
                  </span>
                  </div>
                  {/*end::Info*/}
                </div>
                {/*end::Description*/}
                 
              </label>
              {/*end::Radio button*/}
            </div>
          </div>
          {/*end::Radio group*/}
        </div>
        {/*end: Card Body*/}
        {/*end::List widget 10*/}
        {/*begin::List widget 10*/}
        {/*begin::Header*/}
        <div className="card-header pt-7 d-none">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
              Billing Cycle
            </span>
          </h3>
          {/*end::Title*/}
        </div>
        {/*end::Header*/}
        {/*begin::Body
          <div class=" row card-body">
              <div class="col-md-6 col-lg-6 col-xxl-6">*/}  
        <input
          type="hidden"
          name="billingcycle"
          defaultValue={props.deployInfo && props.deployInfo?(props.deployInfo[0].billing_info):('')}
          id="billing_info"
        />
        {/* </div>

          </div>
          end: Card Body*/}
        {/*end::List widget 10*/}
        {/*begin::List widget 10*/}
        {/*begin::Header*/}
        <div className="card-header pt-7">
          {/*begin::Title*/}
          <h3 className="card-title align-items-start text-blue flex-column">
            <span className="card-label fw-bolder text-gray-800">
              Server Hostname &amp; Label 
            </span>
          </h3>
          {/*end::Title*/}
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}

        {hostCount!=null && hostCount.map((value,index)=>(
          <>
          <div className=" row card-body">
          <div className="col-md-6 col-lg-6 col-xxl-6">
            <input
              type="text"
              className="form-control"
              name={"cloud["+index+"][hostname]"}
              defaultValue={"cloudserver-"+makeid(8)+".mhc"}
              placeholder="host!.cloud"
            />
          </div>
        </div>
          </>
        ))}
         
        {/*end: Card Body*/}
        {/*end::List widget 10*/}
        {/*begin::List widget 10*/}
        {/*begin::Body*/}
        {/*end::List widget 10*/}
      </div>
      {/*end::Col*/}
    </div>
    {/*end::Tables Widget 9*/}
  </form>
</div>
<div
  className="col-xl-3"
  // style={{
  //   position: "sticky",
  //   bottom: 0,
  //   zIndex: 99,
  //   borderTop: "outset",
  //   borderColor: "#009EF7"
  // }}
>
  {/*begin::List widget 10*/}
  <div className="card card-flush me-5" style={{position:"fixed"}}>
    {/*begin::Body*/}
    <div className="" >
      <div className=" row card-body">
        {/*begin::Title*/}
        <h3 className="card-title align-items-start text-blue flex-column">
          <span className="card-label fw-bolder text-gray-800">
            Server Quantity
          </span>
        </h3>
        {/*end::Title*/}
        <div className="col-md-3 col-lg-12 col-xxl-3">
          {/*begin::Dialer*/}
          <div className="input-group w-md-150px">
            {/*begin::Decrease control*/}
            <a
              className="btn btn-icon btn-outline btn-outline-secondary disabled"
              onClick={()=>{updateHostCount('down');cloudCost()}}
              id="hostDown"
            >
              <i className="bi bi-dash fs-1" />
            </a>
            {/*end::Decrease control*/}
            
            {/*begin::Input control*/}
            <input
              type="text"
              className="form-control text-center"
              readOnly={true} 
              defaultValue={1}
              id="total_chq" 
            />
            {/*end::Input control*/}
            {/*begin::Increase control*/}
            <a
              className="btn btn-icon btn-outline btn-outline-secondary" 
              onClick={()=>{updateHostCount('up');cloudCost()}}
              id="hostUp"
            >
              <i className="bi bi-plus fs-1" />
            </a>
            {/*end::Increase control*/}
          </div>
          {/*end::Dialer*/}
        </div>
      </div>
      {/*end: Card Body*/}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-5">Cost Summary</span>
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">

          <thead>
            <tr className="border-0"> 
              <th className="p-0 min-w-150px"></th> 
              <th className="p-0 min-w-100px text-end"></th>
            </tr>
          </thead>
          <tbody>
          <tr className="d-none" id="original_server_cost">
          <td>
            <div className="d-flex align-items-center"> 
              <div className="d-flex justify-content-start flex-column"> 
                <a className="text-muted text-hover-primary fw-bold text-muted d-block fs-7">
                  <span className="text-dark">Original server cost</span></a>
              </div> 
            </div>
          </td>
          <td className="text-end">
            <a  className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{userData!=null && userData.currencyprefix?(userData.currencyprefix):('')}<span id="original_cost_server"></span></a> 
          </td>
          </tr>
          <tr className="d-none" id="discount_server_cost">
          <td>
            <div className="d-flex align-items-center"> 
              <div className="d-flex justify-content-start flex-column"> 
                <a className="text-muted text-hover-primary fw-bold text-muted d-block fs-7">
                  <span className="text-dark" >Discount server cost (<span id="discount_offer"></span>)</span></a>
              </div> 
            </div>
          </td>
          <td className="text-end">
            <a  className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{userData!=null && userData.currencyprefix?(userData.currencyprefix):('')}<span id="discount_cost_server"></span></a> 
          </td>
          </tr>
            <tr className="d-none" id="weekly_backup">
          <td>
            <div className="d-flex align-items-center"> 
              <div className="d-flex justify-content-start flex-column"> 
                <a className="text-muted text-hover-primary fw-bold text-muted d-block fs-7">
                  <span className="text-dark">Weekly backup cost</span></a>
              </div> 
            </div>
          </td>
          <td className="text-end">
            <a  className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{userData!=null && userData.currencyprefix?(userData.currencyprefix):('')}<span id="backup_weekly"></span></a> 
          </td>
          </tr>
          <tr className="d-none" id="server_management">
          <td>
            <div className="d-flex align-items-center"> 
              <div className="d-flex justify-content-start flex-column"> 
                <a className="text-muted text-hover-primary fw-bold text-muted d-block fs-7">
                  <span className="text-dark">Server management cost</span></a>
              </div> 
            </div>
          </td>
          <td className="text-end">
            <a  className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{userData!=null && userData.currencyprefix?(userData.currencyprefix):('')}<span id="management_server"></span></a> 
          </td>
          </tr>
          </tbody>
          </table>
          <span className="mt-1 fw-bold fs-7">
            Total <span
              className="card-label fw-bolder fs-3 mb-1"
              style={{ color: "#328cf1" }}
            >
              <span>{user_data.current!=null?(user_data.current.user.currencyprefix):('')}</span> <span id="order_total">0.00</span>
              <span className="badge badge-primary" />
            </span>
            <span >
              (<span>{user_data.current!=null?(user_data.current.user.currencyprefix):('')}</span>
              <span id="order_toatl_hr">0.000</span>&gt;/hr)
            </span>
          </span>
          <br />
          <span
            
            className="badge badge-light-success mt-1 fw-bold fs-7"
          >
            You saved {user_data.current!=null?(user_data.current.user.currencyprefix):('')}<span id="saved_cloud_cost">0.00</span>
          </span>
        </h3>
        <div className="card-toolbar w-100">
          <a 
            onClick={deployCloudServer}
            className="btn btn-sm btn-primary w-100"
          >
            {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
            <span className="svg-icon svg-icon-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                  fill="black"
                />
                <path
                  opacity="0.3"
                  d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                  fill="black"
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}Deploy Now
          </a>
        </div>
      </div>
    </div>
    {/*end: Card Body*/}
  </div>
  {/*end::List widget 10*/}
</div>
</div>


<div
  id="importkey-modal"
  className="bg-body shadow drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-name="chat"
  data-kt-drawer-activate="true" 
  data-kt-drawer-width="{default:'500px', 'md': '500px'}"
  data-kt-drawer-direction="end" 
  data-kt-drawer-close="#close_ssh"
  
>
  {/*begin::Messenger*/}
  <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
    {/*begin::Card header*/}
    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div className="d-flex justify-content-center flex-column me-3">
          <a
            
            className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
          >
            Add New SSH Key
          </a>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Close*/}
        <div
        onClick={openDrawer}
          className="btn btn-sm btn-icon btn-active-light-primary"
          id="close_ssh"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {/*end::Card header*/}
    {/*begin::Card body*/}
    <div className="" id="">
      {/*begin::Form*/}
      <form name="importkey" id="importkey" className="form">
        {/*begin::Modal body*/}
        <div className="modal-body ">
          {/*begin::Scroll*/}
          <div
            className="scroll-y me-n7 pe-7"
            id="kt_modal_create_api_key_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
            data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
            data-kt-scroll-offset="300px"
          >
            {/*begin::Input group*/}
            <div className="mb-5 fv-row">
              {/*begin::Label*/}
              <label className="required fs-5 fw-bold mb-2"> Name</label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <input
                type="text"
                className="form-control  "
                placeholder="Name"
                name="name"
              />
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <div className="d-flex flex-column mb-5 fv-row">
              {/*begin::Label*/}
              <label className="required fs-5 fw-bold mb-2">
                SSH Key Content
              </label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <textarea
                className="form-control  "
                type="text"
                rows={3}
                name="sshkey"
                placeholder="SSH"
                defaultValue={""}
              />
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Modal body*/}
        {/*begin::Modal footer*/}
        <div className="modal-footer ">
          {/*begin::Button*/}
          <button
            type="button"
            onClick={importSshKey}
            className="btn btn-primary"
          >
            <span className="indicator-label">Import SSH Key</span>
          </button>
          {/*end::Button*/}
        </div>
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
    </div>
    {/*end::Card body*/}
  </div>
  {/*end::Messenger*/}
</div>

{/* Invoice start */}

<div
  id="invoice_drawer"
  className="bg-white drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-overlay="true"
  data-kt-drawer-activate="true" 
  data-kt-drawer-width="1200px"
  style={{ width: "1200px !important" }}
>
  <div className=" container-fluid">
    {/*begin::Body*/}
    <div className=" p-5">
      {/*begin::Layout*/}
      <div className="d-flex flex-column flex-xl-row">
        {/*begin::Content*/}
        <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
          {/*begin::Invoice 2 content*/}
          <div className="mt-n1">
            {/*begin::Top*/}
            <div className="d-flex flex-stack pb-10">
              {/*begin::Logo*/}
              <a >
                <img
                  alt="Logo"
                  src="https://www.utho.com/images/logo-dark-registered-cloud-blue.png"
                  width="200px"
                />
              </a>
              {/*end::Logo*/}
              {/*begin::Action*/}
              <div id="payinvoice" />
              <div id="paidinvoice" className="">
              {invoiceData!=null && invoiceData.status=="Paid"?(<>
                <button className="btn btn-success me-5">Paid</button>
              </>):(<>
                <button className="btn btn-success me-5" id="invoice-payment-button" onClick={invoicePayment}>Pay</button>
              </>)}
              


                {/* <button className="btn btn-light-danger me-5" onClick={openInvoice}>close</button> */}
                
              </div>
              
              {/*end::Action*/}
            </div>
            {/*end::Top*/}
            {/*begin::Wrapper*/}
            <div className="m-0">
              {/*begin::Label*/}
              <div className="fw-bolder fs-3 text-gray-800 mb-8">
                Invoice <span>{invoiceData!=null && invoiceData.id?(invoiceData.id):('')}</span>
              </div>
              {/*end::Label*/}
              {/*begin::Row*/}
              <div className="row g-5 mb-11">
                {/*end::Col*/}
                <div className="col-sm-6">
                  {/*end::Label*/}
                  <div className="fw-bold fs-7 text-gray-600 mb-1">
                    Issue Date:
                  </div>
                  {/*end::Label*/}
                  {/*end::Col*/}
                  <div
                    className="fw-bolder fs-6 text-gray-800"
                    id="invoice-date"
                  >
                    {invoiceData!=null && invoiceData.date?(invoiceData.date):('')}
                  </div>
                  {/*end::Col*/}
                </div>
                {/*end::Col*/}
                {/*end::Col*/}
                <div className="col-sm-6"></div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
              {/*begin::Row*/}
              <div className="row g-5 mb-12">
                {/*end::Col*/}
                <div className="col-sm-6">
                  {/*end::Label*/}
                  <div className="fw-bold fs-7 text-gray-600 mb-1">
                    Issue For:
                  </div>
                  {/*end::Label*/}
                  {/*end::Text*/}
                  <div
                    className="fw-bolder fs-6 text-gray-800"
                    id="company_name"
                  >
                    {userData!=null && userData.company?(userData.company):('')}
                    </div>
                  {/*end::Text*/}
                  {/*end::Description*/}
                  <div className="fw-bold fs-7 text-gray-600">
                    <span id="user-address">{userData!=null && userData.address?(userData.address+", "):('')} {userData!=null && userData.city?(userData.city+", "):('')} {userData!=null && userData.country?(userData.country+", "):('')}</span>
                    <br />
                    <span id="user-mobile" >{userData!=null && userData.mobile?(userData.mobile):('')}</span>
                    <br />
                    <span id="user-gst" >{userData!=null && userData.gstnumber?(userData.gstnumber):('')}</span>
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Col*/}
                {/*end::Col*/}
                <div className="col-sm-6 text-end">
                  {/*end::Label*/}
                  <div className="fw-bold fs-7 text-gray-600 mb-1">
                    Issued By:
                  </div>
                  {/*end::Label*/}
                  {/*end::Text*/}
                  <div className="fw-bolder fs-6 text-gray-800">
                    Micro Hosting Private Limited
                  </div>
                  {/*end::Text*/}
                  {/*end::Description*/}
                  <div className="fw-bold fs-7 text-gray-600">
                    Utho Cloud
                    <br />
                    A-43, Sector 63, Noida 201301 UP - India
                    <br />
                    <strong>GSTIN:</strong> 09AAICM9463A1ZB
                    <br />
                    <strong>PAN Number:</strong> AAICM9463A
                    <br />
                    <strong>SAC:</strong> 998315
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
              {/*begin::Content*/}
              <div className="flex-grow-1">
                {/*begin::Table*/}
                <div className="table-responsive border mb-9">
                  <table className="table mb-3 table-row-bordered">
                    <thead className="bg-secondary">
                      <tr className="border-bottom fs-6 fw-bolder">
                        <th className="min-w-10px" />
                        <th className="min-w-175px pb-2 ">Item Description</th>
                        <th className="min-w-70px text-end pb-2" />
                        <th className="min-w-80px text-end pb-2" />
                        <th className="min-w-100px text-start pb-2">Amount</th>
                      </tr>
                    </thead>
                    <tbody id="item-list">
                      {invoiceData!=null?(invoiceData.items!=null?(invoiceData.items.map((value,index)=>(<>
                    <tr className="fw-bold text-gray-700">
                      <td></td>
                      <td className="d-flex align-items-center pt-6">{value.description}</td>
                      <td></td>
                      <td></td>
                      <td className="pt-6 fw-bold">{userData!=null && userData.currencyprefix?(userData.currencyprefix):('')} { value.amount}</td> 
                    </tr>
                    </>))):('')):('')} 
                    </tbody>
                  </table>
                </div>
                {/*end::Table*/}
                {/*begin::Container*/}
                <div className="d-flex row">

                
                  <div className="col-lg-8 col-md-8 col-xxl-8">
                  {invoiceData!=null && invoiceData.status!="Paid"?(<>
                    <div
                      className="d-flex flex-wrap flex-stack mb-6"
                      id="invoice-credit"
                    >
                      
                      {" "}
                      <div className="d-flex my-2">
                        {" "}
                        <div className="d-flex align-items-center position-relative me-4">
                          {" "}
                          <form id="applycredit" name="applycredit">
                            {" "}
                            <div className="input-group mb-5" id="wallet">
                            <input
                             type="hidden"
                             name="invoiceid" 
                             value={invoiceData!=null && invoiceData?(invoiceData.id):('')} />
                              <span class="input-group-text" >{userData!=null && userData.currencyprefix?(userData.currencyprefix):('')}</span>  <input type="text" class="form-control" placeholder="amount" id="amount" name="amount" min="10" value={invoiceData!=null && invoiceData.rowtotal?(invoiceData.rowtotal):('')} /><a  class="btn btn-primary btn-sm" id="applycredit-btn" onClick={applyCreditAmount}>Apply Credit</a>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    </>):(<></>)}
                  </div>
                  

                  <div className="col-lg-4 col-md-4 col-xxl-4">
                    {/*begin::Section*/}
                    <div className="mw-300px" id="invoice-final">
  {/*begin::Item*/}{" "}
  <div className="d-flex flex-stack mb-3">
    {/*begin::Accountname*/}
    <div className="fw-bold pe-10 text-gray-600 fs-7">Subtotal:</div>
    {/*end::Accountname*/}
    {/*begin::Label*/}
    <div className="text-end fw-bolder fs-6 text-gray-800" id="subtotal-amount">
      {invoiceData!=null && invoiceData.subtotal?(invoiceData.subtotal):('')}
    </div>
    {/*end::Label*/}
  </div>
  {/*end::Item*/}

  {invoiceData!=null && invoiceData.taxrate!='0.00' && invoiceData.taxrate2=='0.00'?(<>
  {/*begin::Item*/}{" "}
  <div className="d-flex flex-stack mb-3">
    {/*begin::Accountname*/}
    <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% IGST</div>
    {/*end::Accountname*/}
    {/*begin::Label*/}
    <div className="text-end fw-bolder fs-6 text-gray-800">{userData!=null && userData.currencyprefix?(userData.currencyprefix):('')} {invoiceData.tax}</div>
    {/*end::Label*/}
  </div>
  {/*end::Item*/}

  </>):('')}


  {invoiceData!=null && invoiceData.taxrate!='0.00' && invoiceData.taxrate2!='0.00'?(<>
  {/*begin::Item*/}{" "}
  <div className="d-flex flex-stack mb-3">
    {/*begin::Accountname*/}
    <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% CGST</div>
    {/*end::Accountname*/}
    {/*begin::Label*/}
    <div className="text-end fw-bolder fs-6 text-gray-800">{userData!=null && userData.currencyprefix?(userData.currencyprefix):('')} {invoiceData.tax2}</div>
    {/*end::Label*/}
  </div>
  {/*end::Item*/}

  </>):('')}

  {invoiceData!=null && invoiceData.taxrate2!='0.00'?(<>
  {/*begin::Item*/}{" "}
  <div className="d-flex flex-stack mb-3">
    {/*begin::Accountname*/}
    <div className="fw-bold pe-10 text-gray-600 fs-7">{invoiceData.taxrate}% SGST</div>
    {/*end::Accountname*/}
    {/*begin::Label*/}
    <div className="text-end fw-bolder fs-6 text-gray-800">{userData!=null && userData.currencyprefix?(userData.currencyprefix):('')} {invoiceData.tax2}</div>
    {/*end::Label*/}
  </div>
  {/*end::Item*/}

  </>):('')}

  {invoiceData!=null && invoiceData.credit!='0'?(<>
  {/*begin::Item*/}{" "}
  <div className="d-flex flex-stack mb-3">
    {/*begin::Accountname*/}
    <div className="fw-bold pe-10 text-gray-600 fs-7">Credits</div>
    {/*end::Accountname*/}
    {/*begin::Label*/}
    <div className="text-end fw-bolder fs-6 text-gray-800">{userData!=null && userData.currencyprefix?(userData.currencyprefix):('')} {invoiceData.credit}</div>
    {/*end::Label*/}
  </div>
  {/*end::Item*/}

  </>):('')}

 
  {/*begin::Item*/}{" "}
  <div className="d-flex flex-stack mb-3">
    {/*begin::Accountname*/}
    <div className="fw-bold pe-10 text-gray-600 fs-7">Total</div>
    {/*end::Accountname*/}
    {/*begin::Label*/}
    <div className="text-end fw-bolder fs-6 text-gray-800" id="total-amount">
      {userData!=null && userData.currencyprefix?(userData.currencyprefix):('')} {invoiceData!=null && invoiceData.total}
    <input type="hidden" id="invoiceAmountFund" value={invoiceData!=null && invoiceData.total} />
    <input type="hidden" id="invoice_id" value={invoiceData!=null && invoiceData.id} />
    </div>
    {/*end::Label*/}
  </div>
  {/*end::Item*/}
</div>

                  </div>
                  {/*end::Section*/}
                </div>
                {/*end::Container*/}
                <div id="trans-tabel" className="d-none">
                  <div className="fw-bolder fs-2">Transactions</div>
                  <table className="table mb-3 table-row-bordered">
                    <thead className="bg-secondary">
                      <tr className="border-bottom fs-6 fw-bolder">
                        <th className="min-w-175px pb-2 text-center">
                          Transaction Date
                        </th>
                        <th className="min-w-70px text-end pb-2">
                          Transaction Gateways
                        </th>
                        <th className="min-w-80px text-end pb-2">
                          Transaction id
                        </th>
                        <th className="min-w-100px text-center pb-2">
                          Transaction Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      className="fw-bold text-gray-600"
                      id="trans-list"
                    >
                      {invoiceData!=null && invoiceData.transs.map((value,index)=>(<>
                      <tr> 
                        <td class="text-center">{value.date}</td> 
                        <td class="text-end">{value.gateway}</td> 
                        <td class="text-end">{value.transid}</td> 
                        <td class="text-center">{userData!=null && userData.currencyprefix?(userData.currencyprefix):('')} {value.amountin?(value.amountin):(value.amountout)}</td>
                      </tr>
                      </>))}
                    </tbody>
                  </table>
                  {/*end::Table*/}
                  <div id="due-price" style={{ marginTop: 25 }} >
                  <div class="d-flex justify-content-end">
                     <div class="mw-300px" id="invoice-final">
                      <div class="d-flex flex-stack mb-3">
                        <div class="fw-bold pe-10 text-danger-600 fs-7">Due Amount</div>
                        <div class="text-end fw-bolder fs-6 text-danger-800">{userData!=null && userData.currencyprefix?(userData.currencyprefix):('')} {invoiceData!=null?(invoiceData.balance):('')}</div>
                      </div>
                     </div>
                  </div>
                    </div>
                </div>
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Invoice 2 content*/}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Layout*/}
    </div>
    {/*end::Body*/}
  </div>
</div>

{/* Invoice end */}
</div>
</div>  
</div>
      </>
        
    )
}

const mapStateToProps=state=>({
  hostCountData:state.cloud.hostCountDataInfo,
  deployInfo:state.cloud.deployDataInfo
})

const mapDispatchToProps=dispatch=>({
  updateHostCountData:data=>dispatch(AddHostCount(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(DeployCloud)
