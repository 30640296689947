import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import useRazorpay from "react-razorpay";
import UseApi from "../../../../../../../CustomHooks/useApi";
import UsePostData from "../../../../../../../CustomHooks/usePostData";
import spinner from "../../../spinner.svg";
import localStorage from "local-storage";
const AddFundRazorpay = () => {
  const [currencyprefix, setCurrencyprefix]=useState(null);
  const filesUrl=localStorage.get("filesUrl");
  const [siteUrl, setSiteUrl]=useState(null);
  const app_info=localStorage.get("appInfo");
  const [user_data,setUser_data]=useState(null);
  async function getUser(){
    const u_data = await UseApi("get", "account/info", "");
    if(u_data){
      if(u_data.user){
      setUser_data(u_data.user);
      if(u_data.user.currency=="INR"){
        setCurrencyprefix("Rs.");
      }else{
        setCurrencyprefix("$");
      }
    }
  }
   }
   useEffect(()=>{
    getUser();
   },[])
   function generateUniqueOrderID() {
    // Implement logic to generate a unique order ID, for example, a combination of timestamp and a random number
    return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
  }
  const Razorpay = useRazorpay();
  const handlePayment = useCallback(async () => {
    toast.dismiss();
    const payBtn = document.querySelector("#payment-button");
    var btnHtml = payBtn.innerHTML;
    if (payBtn) {
      payBtn.innerHTML =
        'Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span>';
      payBtn.classList.add("disabled");
    }
    const u_data = await UseApi("get", "account/info", "");
    const uData = u_data.user;
    const u_razorpay_info = await UseApi("get", "mhcsecinfo", "");
  //  console.log("razopray key");
  //  console.log(u_razorpay_info);
    const amountFund = document.querySelector("#amountFund");
    if(filesUrl){
    setSiteUrl(filesUrl);
    }
    // var rorder_id = "";
    var rfullname = "";
    var rmobile = "";
    var remail = "";
    var rcurrency = "";
    var ramount=0;

    if (uData) {
      // rorder_id = uData.razorpay_orderid;
      rfullname = uData.fullname;
      rmobile = uData.mobile;
      remail = uData.email;
      rcurrency = uData.currency;
      setCurrencyprefix(uData.currencyprefix);
    }
    if(amountFund){
      ramount=amountFund.value;
    }
    if(uData.currency!="INR"){
       if(ramount<10){
        swal({
            title: "Add Fund",
            text: "Sorry you need to add minimum $10",
            icon: "warning",
            button: "OK",
          });
          if (payBtn) {
            payBtn.innerHTML = btnHtml;
            payBtn.classList.remove("disabled");
          }
          return;
       }
    }
    if(uData.currency=="INR"){
      if(ramount<1){
       swal({
           title: "Add Fund",
           text: "Sorry you need to add minimum Rs.1",
           icon: "warning",
           button: "OK",
         });
         if (payBtn) {
          payBtn.innerHTML = btnHtml;
          payBtn.classList.remove("disabled");
        }
         return;
      }
   }
  //  const rorder_id = generateUniqueOrderID();
    const options = {
      // key: "rzp_live_aeMFERiIAsYFFF",
      key: u_razorpay_info.razorpay_key,
      // key:"rzp_live_k7fSHvV65rLqOJ",
      customer_id:uData.razorpay_customerid,
      amount: parseInt(ramount * 100),
      // order_id: rorder_id,
      currency: rcurrency,
      name: app_info?(app_info.brand):(''),
      description: "Add Fund",
      image: (filesUrl!=null?(filesUrl):(''))+"/publicfiles/"+(app_info?(u_razorpay_info.logo):('')),
      prefill: { 
        contact: rmobile,
      },
      options: {
        checkout: {
          method: {
            netbanking: 1,
            card: 1,
            upi: 1,
          },
        },
      },
      handler: async (res) => {
        //res.razorpay_payment_id
        //res.status_code
        //pay_L0C8DacFM1Voqi
        // console.log(res);
        if (res.status_code == 200) {
          swal({
            title: "Processing please wait...",
            icon: spinner,
            html: true,
            showSpinner: true,
          });
          const resData = await UsePostData("post", "billing/addpayment", {
            platform: "2023",
            invoiceid: "Addfund",
            transid: res.razorpay_payment_id,
            amount: ramount,
            currency: rcurrency,
            gateway: "Razorpay",
          });
          //console.log(resData);
          if (resData.status == "success") {
            //console.log("added");
            swal({
              title: "Add Fund",
              // text: "Fund added successfuly",
              text: resData.message,
              icon: "success",
              button: "OK",
            });
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          } else {
            swal({
              title: "Add Fund",
              // text: "Fund not added in your account!",
              text: resData.message,
              icon: "warning",
              button: "OK",
            });
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          } 
        }
      },
      notes: {
        u_userid: uData.id,
        u_type: "Add Fund",
      },
      theme: {
        color: "#377dff",
      },
    };
     
    console.log(options, "options");
    const rzpay = new Razorpay(options);
    rzpay.open();
    if (payBtn) {
      payBtn.innerHTML = btnHtml;
      payBtn.classList.remove("disabled");
    }
  }, [Razorpay]);
  return (
    <>
      {/*begin::Input group*/}{" "}
       <div className="symbol symbol-45px me-2">
        {" "}
        <div className="input-group mb-5">
          {" "}
          <span className="input-group-text">
            {currencyprefix!=null?(currencyprefix):('')}
          </span>{" "}
          <input
            type="text"
            className="form-control"
            placeholder="Please enter the amount"
            id="amountFund"
            defaultValue={1}
            name="amount"
          />{" "}
          <span className="input-group-text">.00</span>
        </div>
      </div>{" "}
      {/*end::Input group*/} {/*begin:Action*/}{" "}
      <div className="d-flex align-items-center">
        {" "}
        {/* {userData!=null && userData.currency=="INR"?(<> */}
        <a
          onClick={handlePayment}
          id="payment-button"
          className="btn btn-primary me-5"
        >
          Add Fund
        </a>
      </div>{" "}
    </>
  );
};

export default AddFundRazorpay;
