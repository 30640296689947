import React from 'react'
import TopBarProgress from 'react-topbar-progress-indicator'
 
export default function TopBarHeaderLine(props) {
    TopBarProgress.config({
        barColors: {
          "0": "#1D2F3E",
          "0.1": "#152737",
          "0.2": "#1D2F3E",
          "0.3": "#152737",
          "0.4": "#1D2F3E",
          "0.5": "#152737",
          "0.6": "#1D2F3E",
          "0.7": "#152737",
          "0.8": "#1D2F3E",
          "0.9": "#152737",
          "1.0": "#1D2F3E",
        },
        shadowBlur: 5
      });
    //   console.log("topheader");
  return (
    <>
    <TopBarProgress/>
     </>
  )
}
