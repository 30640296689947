import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import UseApi from "../../../../CustomHooks/useApi";
import UsePost from "../../../../CustomHooks/usePost";
import "./custom.css";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import UseTicketPost from "../../../../CustomHooks/useTicketPost";
import UseDevPost from "../../../../CustomHooks/useDevPost";


export default function ManageTicket(){
  useEffect(() => {
    window.KTScroll.init();
    window.KTDrawer.init();
    window.KTMenu.createInstances();
    window.KTToggle.createInstances();
  }, []);
  const [editorContent, setEditorContent] = useState('');

  const handleChange = (content) => {
     setEditorContent(content);
    };
  var fileDownload = require('js-file-download');
  const navigate=useNavigate();
  const nl2br = require('react-nl2br');
  const [messages,setMessages]=useState(null);
  const [ticket,setTicket]=useState(null);
  const [currentDocument, setCurrentDocument]=useState(null);
  const dataFetchedRef=useRef();
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4];

  const navRedirect=()=>{
    setTimeout(() => {
      navigate("/ticket/"+id);
     }, 3000);
      
  }
  const getTicket=async(id)=>{ 
       const data=await UseApi('get','ticket/'+id+'',''); 
       //console.log(data);
       if(Object.keys(data).length <= 0){
        swal({
            title: "Ticket",
            text: "No Ticket record found with id: "+id+"!",
            icon: "warning",
            button: "OK",
          }).then(()=>{
            navigate("/ticket");
          })
            return;
        // const dt=toast.loading("Tickets loading...");
        // setTicket(null);
        //      toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        //     }); 
       }else{
        setTicket(data);  
          //   toast.update(dt,{render: "Your Ticket info request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          // });  
          //console.log(data);
       }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getTicket(id); 
  },[]); 

  useEffect(()=>{
    if(ticket && ticket.messages){
    // var reverseTickets=ticket.messages.reverse();
    var reverseTickets=ticket.messages;
    setMessages(reverseTickets);
    }
  },[ticket])
  
  const closeTicket=async()=>{
    toast.dismiss();
    swal({
      title:"Press OK,",
      text: "If you wish to temporarily end this conversation. You can reopen this ticket later if you have any further queries by replying to it!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then(async(willDelete) => {
      if(willDelete) {
        const dt=toast.loading("Please wait...");
        const responseData=await UsePost('post','ticket/'+id+'/status/Closed','closeTicket');
        //console.log(responseData);
          if(responseData.status==='error'){
            toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          }else if(responseData.status==='success'){
            toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });  
          getTicket(id); 
          navRedirect();
          }
      } 
      });
   
    }

    const addReply=async()=>{
      toast.dismiss();
      const dt=toast.loading("Please wait...");
      const replyMessage=document.querySelector("#replyMessage");
      if(replyMessage.value==""){
        toast.update(dt,{render: "Please add reply", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          return;
      }
      const submitBtn=document.querySelector("#createTicket");
      if(submitBtn){
        var preContent=submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML='Please wait... <span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
        const responseData=await UseTicketPost('post','ticket/'+id+'/reply','replytoticket');
          if(responseData.status==='error'){
            toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          }else if(responseData.status==='success'){
            toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });  
        
          const replyForm=document.querySelector('#replytoticket');
          replyForm.reset();
          setEditorContent("");
          setSelectedFiles([]);
          getTicket(id); 
          // navRedirect();
          }
          // deleteUploadFile();
          submitBtn.classList.remove("disabled");
          submitBtn.innerHTML=preContent;
        }
    }
 

    const downloadFile=(file)=>{
      fileDownload("https://api.utho.com/image.php?file="+file, file);
    }

   

    function formatMessageTime(dateString) {
      const date = new Date(dateString);
      const currentDate = new Date();
      const timeDiff = Math.abs(currentDate.getTime() - date.getTime());
      const minutes = Math.floor(timeDiff / (1000 * 60));
      const hours = Math.floor(minutes / 60);
    
      if (minutes <= 1) {
        return 'Just now';
      } else if (minutes < 30) {
        return `${minutes} mins ago`;
      } else if (minutes < 90) {
        return 'An hour ago';
      } else if (hours < 24) {
        const formattedTime = formatTime(date);
        return `Today at ${formattedTime}`;
      } else if (hours < 48) {
        const formattedTime = formatTime(date);
        return `Yesterday at ${formattedTime}`;
      } else {
        const formattedDate = formatDate(date);
        const formattedTime = formatTime(date);
        return `${formattedDate} at ${formattedTime}`;
      }
    }
    
    function formatTime(date) {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const period = hours >= 12 ? 'pm' : 'am';
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes.toString().padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}${period}`;
    }
    
    function formatDate(date) {
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();
      return `${month} ${day}, ${year}`;
    }

    function getFileExtension(filename) {
      // Get the last index of the dot (.) character
      const dotIndex = filename.lastIndexOf('.');
    
      if (dotIndex === -1) {
        // If no dot found, return an empty string
        return '';
      }
    
      // Extract the file extension using substring
      const extension = filename.substring(dotIndex + 1);
    
      return extension;
    }

    function handleFileUpload() {
      var fileInput = document.getElementById('fileInput');
      if(fileInput){
      fileInput.click();
      }
    }
    function extractFileNameFromPath(filePath) {
      var startIndex = filePath.lastIndexOf('\\') + 1;
      var fileName = filePath.substr(startIndex);
      return fileName;
    }

    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = useRef(null);

    
    const handleRemoveFile = (index) => {
      const updatedFiles = [...selectedFiles];
      updatedFiles.splice(index, 1);
      setSelectedFiles(updatedFiles);
  
      // Create a new file input element to clear the previous selection
      const newFileInput = document.createElement('input');
      newFileInput.type = 'file';
      newFileInput.name = 'files[]';
      newFileInput.multiple = true;
      newFileInput.style.display = 'none';
      newFileInput.addEventListener('change', handleFileChange);
      fileInputRef.current.parentNode.replaceChild(newFileInput, fileInputRef.current);
      fileInputRef.current = newFileInput; 
    }; 
 

    function shortenFileName(fileName, startingLetters = 10, endingLetters = 4) {
      if (fileName.length <= 20) {
        // If the file name is 20 characters or less, return as is
        return fileName;
      }
    
      const fileNameWithoutExt = fileName.slice(0, fileName.lastIndexOf('.'));
      const fileExt = fileName.slice(fileName.lastIndexOf('.'));
    
      const shortenedName = fileNameWithoutExt.slice(0, startingLetters) + '...' + fileNameWithoutExt.slice(-endingLetters) + fileExt;
      return shortenedName;
    }



    function handleFileChange(e) {
      // const selectedFilesList = document.getElementById('selectedFilesList');
      // selectedFilesList.innerHTML = ''; // Clear the previous list
    
      const files = Array.from(e.target.files);  
      setSelectedFiles(files);
      // setSelectedFiles([...selectedFiles, ...files]);
      // files.forEach(file => {
      //   const listItem = document.createElement('li');
      //   listItem.textContent = file.name;
      //   const removeButton = document.createElement('button');
      //   removeButton.textContent = 'Remove';
      //   removeButton.addEventListener('click', () => removeFile(file));
      //   listItem.appendChild(removeButton);
      //   selectedFilesList.appendChild(listItem);
      // });
    }

    function removeFile(fileToRemove) {
      // Get the list of selected files from the file input field and convert it to an array
      const files = Array.from(document.getElementById('fileInput').files);
    
      // Create a new array with the selected files, excluding the file to remove
      const filteredFiles = files.filter(file => file !== fileToRemove);
    
      // Clear the file input selection by setting its value to an empty string
      document.getElementById('fileInput').value = '';
    
      // Clear the input's FileList by setting it to an empty FileList (for some browsers)
      document.getElementById('fileInput').files = new DataTransfer().files;
    
      // Create a new DataTransfer object to hold the filtered files
      const newFiles = new DataTransfer();
    
      // Add each file from the index array to the new DataTransfer object
      // This effectively removes the file to remove from the list of selected files
      filteredFiles.forEach(file => newFiles.items.add(file));
    
      // Update the file input field's selected files to the new filtered files
      document.getElementById('fileInput').files = newFiles.files;
    
      // Call the handleFileChange function to update the displayed list of selected files
      // after removing the file from the list
      handleFileChange({ target: { files: filteredFiles } });
    }
    
    return(
      <>
      {ticket!=null?(<>
      {/*begin::Layout*/}
      <div className="d-flex flex-column flex-lg-row">
        {/*begin::Content*/}
        <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
          {/*begin::Messenger*/}
          <div className="card" id="kt_chat_messenger">
            {/*begin::Card header*/} 
            <div className="card-header card-header-custom" id="kt_chat_messenger_header">
              {/*begin::Title*/} 
              <div className="card-title"> 
                {/*begin::User*/}
                <div className="d-flex justify-content-center flex-column me-3">
                  <a
                    href="#"
                    className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                  >
                   Ticket ID: #<span id="tid">{ticket!=null?(ticket.tid):('')}</span>
                  </a>
                  {/*begin::Info*/}
                  <div className="mb-0 lh-1">
                  {ticket!=null?(<>
                  {ticket.status=='Closed'?(<>
                    <span className="badge badge-danger badge-circle w-10px h-10px me-1" />
                    <span className="fs-7 fw-bold text-muted">Ticket is closed, To reopen ticket please reply on ticket if you have any other query.</span>
                    </>):(<>
                    <span className="badge badge-success badge-circle w-10px h-10px me-1" />
                    <span className="fs-7 fw-bold text-muted">{ticket.status}</span>
                    </>)
                     }
                     </>):('')}
                  </div>
                  {/*end::Info*/}
                </div>
                {/*end::User*/}
              </div>
              {/*end::Title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Menu*/}
                <div className="me-n3">
                {ticket!=null?(<>
                {ticket.status!='Closed'?(<>
                  <button
                  onClick={closeTicket}
                    className="btn btn-sm  btn-danger" 
                  >
                    set to close
                  </button> 
                  </>):(<>
                  <Link to="/ticket">
                    <button
                    className="btn btn-sm  btn-primary" 
                  >
                    Back to tickets
                  </button> 
                  </Link>
                  </>)}
                  </>):('')}
                </div>
                {/*end::Menu*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body" id="kt_chat_messenger_body">
              {/*begin::Messages*/}
              <div
                className="scroll-y me-n5 pe-5 h-lg-auto" 
              >
                <div id="reply-body mb-5">
  {/*begin::Form*/}
  <form id="replytoticket" className="rounded mb-10" >
    {/*begin::Body*/}
    <div className="d-block mb-5" style={{border:"1px solid #ccd1d5"}}>
      <div className="row">
        <div className="col-12">
        <div 
  className="container-fluid d-flex flex-column flex-md-row flex-md-stack p-5"
>
  {/*begin::Page title*/}
  <div
    data-kt-swapper="true"
    data-kt-swapper-mode="prepend"
    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
    className="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0"
  >
    {/*begin::Title*/}
    <h1 className="d-flex text-muted fw-bolder fs-3 flex-column mb-0">
    <span>Subject : <span className="text-dark fs-4">{ticket!=null?(ticket.subject.charAt(0).toUpperCase() + ticket.subject.slice(1)):('')}</span></span>
    </h1>
    {/*end::Title*/}
    {/*begin::Breadcrumb*/}
    <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 pt-1 d-none">
       
      {/*begin::Item*/}
      <li className="breadcrumb-item text-muted">Customer Name</li>
      {/*end::Item*/}
      {/*begin::Item*/}
      <li className="breadcrumb-item">
        <span className="bullet bg-gray-300 w-5px h-2px" />
      </li>
      {/*end::Item*/}
      {/*begin::Item*/}
      <li className="breadcrumb-item text-dark">{ticket!=null?(ticket.name):('')}</li>
      {/*end::Item*/}
    </ul>
    {/*end::Breadcrumb*/}
  </div>
  {/*end::Page title*/}
  {/*begin::Action group*/}
  <div className="d-flex align-items-center overflow-auto pt-3 pt-md-0">
    {/*begin::Action wrapper*/}
    <div className="d-flex align-items-center">
     {/*begin::Label*/}
     <span className="fs-7 text-gray-700 fw-bolder">Department:</span>
      {/*end::Label*/}
      {/*begin::NoUiSlider*/}
      <div className="d-flex align-items-center ps-4">
        
        <span 
          className="d-flex flex-center badge badge-light-primary fs-7 fw-bolder"
          >
          {ticket!=null?(ticket.departmentname):('')}
        </span>
      </div>
      {/*end::NoUiSlider*/}
    </div>
    {/*end::Action wrapper*/}
    {/*begin::Action wrapper*/}
    <div className="d-flex align-items-center">
      {/*begin::Separartor*/}
      <div className="bullet bg-secondary h-35px w-1px mx-5" />
      {/*end::Separartor*/}
      {/*begin::Label*/}
      <span className="fs-7 text-gray-700 fw-bolder">Status:</span>
      {/*end::Label*/}
      {/*begin::NoUiSlider*/}
      <div className="d-flex align-items-center ps-4">
      {ticket!=null?(<>
                {ticket.status!='Closed'?(<>
                  <span 
          className="d-flex flex-center badge badge-light-success fs-7 fw-bolder"
          >
          Active
        </span>
                  </>):(<>
                    <span 
          className="d-flex flex-center badge badge-light-danger fs-7 fw-bolder"
          >
          Closed
        </span>
                  </>)}
                  </>):('')}
        
      </div>
      {/*end::NoUiSlider*/}
      {/*begin::Separartor*/}
      <div className="bullet bg-secondary h-35px w-1px mx-5" />
      {/*end::Separartor*/}
    </div>
    {/*end::Action wrapper*/}
    {/*begin::Action wrapper*/}
    <div className="d-flex align-items-center"> 
      {/*begin::Label*/}
      <span className="fs-7 text-gray-700 fw-bolder">Priority:</span>
      {/*end::Label*/}
      {/*begin::NoUiSlider*/}
      <div className="d-flex align-items-center ps-4">
        
        <span 
          className="d-flex flex-center badge badge-light-info fs-7 fw-bolder"
          >
          {ticket!=null?(ticket.priority):('')}
        </span>
      </div>
      {/*end::NoUiSlider*/}
      {/*begin::Separartor*/}
      <div className="bullet bg-secondary h-35px w-1px mx-5 d-none" />
      {/*end::Separartor*/}
    </div>
    {/*end::Action wrapper*/}
    {/*begin::Action wrapper*/}
    <div className="d-flex align-items-center d-none">
      {/*begin::Label*/}
      {/* <span className="fs-7 text-gray-700 fw-bolder pe-3"> Upload Documents:</span> */}
      {/*end::Label*/}
      {/*begin::Actions*/}
      <div className="d-flex">
        {/*begin::Action*/}
        <a
          href="#"
          className="btn btn-sm btn-icon btn-icon-muted btn-active-icon-success" 
        >
          Upload
          {/*begin::Svg Icon | path: icons/duotune/files/fil003.svg*/}
          <span className="svg-icon svg-icon-2qx">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.3"
                d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
                fill="black"
              />
              <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </a>
        {/*end::Action*/}
      
      </div>
      {/*end::Actions*/}
    </div>
    {/*end::Action wrapper*/}
  </div>
  {/*end::Action group*/}
</div>

        </div>
        <div className="col-12">
        <div className="mb-10" >
          {/* <label htmlFor="exampleFormControlInput1" className=" form-label">
            Reply message
          </label> */}
          {/*begin::Input group*/}
          <ReactQuill
      value={editorContent} // Set the initial value here if needed
      onChange={handleChange}
      style={{height:"300px",borderRadius: 0}}
    />
          <div className="form-floating d-none">
            <textarea
            style={{border:"none", borderRadius:0}}
              className="form-control"
              name="message"
              id="replyMessage"
              rows={10}
              cols={50}
              defaultValue={editorContent}
              value={editorContent}
            /> 
            {/* <label htmlFor="floatingTextarea2">Send message</label> */}
          </div>
          {/*end::Input group*/}
        </div>
        <div className="mb-10 d-none">
          <label htmlFor="exampleFormControlInput1" className=" form-label">
            CC Address
          </label>
          <input
            type="text"
            id="cc"
            name="cc"
            defaultValue=""
            className="form-control d-none"
          />
          <input type="file" id="fileInput" ref={fileInputRef} multiple className="form-control form-control-flush"  name="files[]" onChange={handleFileChange}  style={{display: "none"}}  />
        </div>
        </div>
      </div>
    </div>
    {/*begin::Send*/}
    <div className="card mb-5">
    {selectedFiles.length!=0?(<>
        {selectedFiles.length>1?(<> 
      {selectedFiles.map((file, index) => (

    <div
    className="p-2 rounded bg-light-info text-dark fw-bold  text-start"
    data-kt-element="message-text"
      > 
          {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil003.svg*/}
          <span className="svg-icon svg-icon-muted svg-icon-2hx me-5">
          <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          >
          <path
          opacity="0.3"
          d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
          fill="black"
          />
          <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
          </svg>
          </span>
          {/*end::Svg Icon*/}
          <span className="me-5">
           
          {shortenFileName(extractFileNameFromPath(file.name))}
          </span> 
          <span className="svg-icon svg-icon-muted svg-icon-2" style={{cursor: "pointer"}} onClick={(e) => removeFile(file)}>
          <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          >
          <path
          opacity="0.3"
          d="M6 19.7C5.7 19.7 5.5 19.6 5.3 19.4C4.9 19 4.9 18.4 5.3 18L18 5.3C18.4 4.9 19 4.9 19.4 5.3C19.8 5.7 19.8 6.29999 19.4 6.69999L6.7 19.4C6.5 19.6 6.3 19.7 6 19.7Z"
          fill="black"
          />
          <path
          d="M18.8 19.7C18.5 19.7 18.3 19.6 18.1 19.4L5.40001 6.69999C5.00001 6.29999 5.00001 5.7 5.40001 5.3C5.80001 4.9 6.40001 4.9 6.80001 5.3L19.5 18C19.9 18.4 19.9 19 19.5 19.4C19.3 19.6 19 19.7 18.8 19.7Z"
          fill="black"
          />
          </svg>
          </span>
    </div> 
          ))}  
        </>):(<>
          </>)}
    </>):('')}
    </div>
    <div className="d-flex flex-stack"> 
  <div className="d-flex align-items-center me-2">
    {/* <button
      className="btn btn-sm btn-icon btn-active-light-primary me-1"
      type="button"
      data-bs-toggle="tooltip"
      title="Coming soon"
    >
      <i className="bi bi-paperclip fs-3" />
    </button> */}
    <a
      onClick={addReply} 
      className="btn btn-primary me-1"
      type="button" 
      id="createTicket"
      data-kt-element="send"
    > 
     Add Reply
    </a>
  </div> 
  {selectedFiles.length!=0?(<>
      {selectedFiles.length>1?(<>
        <div
        style={{cursor:"pointer"}}
className="p-2 rounded bg-light-info text-dark fw-bold  text-start"
data-kt-element="message-text"
  > 
   {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil003.svg*/}
   <span className="svg-icon svg-icon-muted svg-icon-2hx me-5">
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      >
      <path
      opacity="0.3"
      d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
      fill="black"
      />
      <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
      </svg>
      </span>
      {/*end::Svg Icon*/}
        {selectedFiles.map((file, index) => (
          <> 
          {index==0?(<> 
      <span className="me-5">
        {shortenFileName(extractFileNameFromPath(file.name))}, 
      </span>    
          </>):(<> 
          {index==1?(<>
            <span className="me-5">
        {shortenFileName(extractFileNameFromPath(file.name))}...
      </span>
      <span className="badge badge-primary">{selectedFiles.length}</span>
          </>):(<></>)}
          </>)}

           
    </>   ))}
   </div> </>):(<> 
    {selectedFiles.map((file, index) => (

  <div
  className="p-2 rounded bg-light-info text-dark fw-bold  text-start"
  data-kt-element="message-text"
    > 
        {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil003.svg*/}
        <span className="svg-icon svg-icon-muted svg-icon-2hx me-5">
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        >
        <path
        opacity="0.3"
        d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
        fill="black"
        />
        <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
        </svg>
        </span>
        {/*end::Svg Icon*/}
        <span className="me-5">

        {extractFileNameFromPath(file.name)}
        </span> 
        <span className="svg-icon svg-icon-muted svg-icon-2" style={{cursor: "pointer"}} onClick={(e) => handleRemoveFile(index)}>
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        >
        <path
        opacity="0.3"
        d="M6 19.7C5.7 19.7 5.5 19.6 5.3 19.4C4.9 19 4.9 18.4 5.3 18L18 5.3C18.4 4.9 19 4.9 19.4 5.3C19.8 5.7 19.8 6.29999 19.4 6.69999L6.7 19.4C6.5 19.6 6.3 19.7 6 19.7Z"
        fill="black"
        />
        <path
        d="M18.8 19.7C18.5 19.7 18.3 19.6 18.1 19.4L5.40001 6.69999C5.00001 6.29999 5.00001 5.7 5.40001 5.3C5.80001 4.9 6.40001 4.9 6.80001 5.3L19.5 18C19.9 18.4 19.9 19 19.5 19.4C19.3 19.6 19 19.7 18.8 19.7Z"
        fill="black"
        />
        </svg>
        </span>
  </div> 
        ))}  
      </>)}
  </>):('')}
   
  <a
    onClick={handleFileUpload}   
    className="btn btn-light-primary"
    data-bs-toggle="tooltip"
    title="Upload document"
    type="button"
  >
    <span className="me-3">Upload document</span>
      <i className="bi bi-upload fs-3" />
   
  </a>
</div>

    {/* <div className="btn-group me-4">
      <a
        className="btn btn-primary me-3"
        style={{borderRadius:0}}
        onClick={addReply}
        id="createTicket"
        >
          <span>Add Reply</span>
        
      </a>
      <a
        onClick={handleFileUpload}
          className="btn btn-sm btn-icon btn-active-light-primary me-1"
          type="button"
          data-bs-toggle="tooltip"
          title="Upload document"
        >
          <i className="bi bi-upload fs-3" />
        </a> 
    </div> */}
    {/*end::Send*/}
  </form>
</div>
{messages!=null && messages.map((value,index)=>(
          <>
           {value.staffreply==1?(<>
                {/*begin::Message(in)*/} 
                <div className="mb-9">
  {/*begin::Card*/}
  <div className="card card-bordered w-100 bg-light-warning" style={{borderRadius:0, border: "1px solid #e0e1e2"}}>
    <div className="card-header-custom">
         {/*begin::Wrapper*/}
      <div className="w-100 d-flex flex-stack">
        {/*begin::Container*/}
        <div className="d-flex align-items-center f">
          {/*begin::Author*/}
          <div className="symbol symbol-50px me-5">
            <img src="/assets/images/utho-logo.svg" className="w-100" />
          </div>
          {/*end::Author*/}
          {/*begin::Info*/}
          <div className="d-flex flex-column fw-bold fs-5 text-gray-600 text-dark">
            {/*begin::Text*/}
            <div className="d-flex align-items-center">
              {/*begin::Username*/}
              <a 
                className="text-gray-800 fw-bolder text-hover-primary fs-5 me-3"
              >
                Support
              </a>
              {/*end::Username*/}
             </div>
            {/*end::Text*/}
            {/*begin::Date*/}
            <span className="text-muted fw-bold fs-6">{formatMessageTime(value.date)}</span>
            {/*end::Date*/}
          </div>
          {/*end::Info*/}
        </div>
        {/*end::Container*/}
         
      </div>
      {/*end::Wrapper*/}
    </div>
    {/*begin::Body*/}
    <div className="card-body">
     
      {/*begin::Desc*/}
      <p className="fw-normal fs-5 text-gray-700 m-0">
      {<div dangerouslySetInnerHTML={{ __html: value.message }} />}
      </p>
      {/*end::Desc*/}
      {value.attachment!=""?(<>
          {value.attachment.split(',').map((filename,index2)=>(<>  
                      {getFileExtension(filename)=="png" || getFileExtension(filename)=="jpg" ||getFileExtension(filename)=="jpeg"?(<>
                        <div className="mt-2 image-container me-3" style={{maxWidth:"300px",maxHeight:"250px"}}>
                        <img src={"https://api.utho.com/image.php?file="+filename}  style={{maxWidth:"300px",maxHeight:"250px"}} />
                        <div className="overlay">
                        <span className="svg-icon svg-icon-muted svg-icon-2hx me-5" style={{cursor: "pointer"}} onClick={(e)=>downloadFile(filename)}>
 <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="SVGRepo_bgCarrier" strokeWidth={0} />
<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
<g id="SVGRepo_iconCarrier">
  {" "}
  <path
    d="M31.67 3.50098C24.0687 3.50892 16.781 6.53205 11.406 11.907C6.03107 17.2819 3.00794 24.5697 3 32.171C3.00953 39.7705 6.03361 47.0559 11.4086 52.4282C16.7836 57.8006 24.0705 60.8212 31.67 60.827C39.2687 60.819 46.5538 57.797 51.9269 52.4239C57.3 47.0508 60.322 39.7656 60.33 32.167C60.321 24.5681 57.2988 17.2829 51.9261 11.9091C46.5534 6.53531 39.2689 3.51156 31.67 3.50098ZM41.78 41.327L32.79 47.317L32.78 47.327C32.4528 47.5499 32.0659 47.6684 31.67 47.667C31.2739 47.6699 30.8865 47.5512 30.56 47.327L30.39 47.207L21.56 41.327C21.1188 41.0336 20.812 40.5772 20.707 40.0578C20.602 39.5385 20.7074 38.9987 21 38.557C21.2956 38.1155 21.7537 37.8089 22.2746 37.7039C22.7954 37.599 23.3366 37.7044 23.78 37.997L29.67 41.927V18.667C29.6691 18.4041 29.7202 18.1436 29.8204 17.9005C29.9205 17.6574 30.0678 17.4366 30.2537 17.2507C30.4396 17.0648 30.6605 16.9175 30.9035 16.8173C31.1466 16.7172 31.4071 16.6661 31.67 16.667C32.2004 16.667 32.7091 16.8777 33.0842 17.2528C33.4593 17.6278 33.67 18.1365 33.67 18.667V41.927L39.56 37.997C40.0017 37.7044 40.5415 37.599 41.0608 37.704C41.5802 37.809 42.0366 38.1158 42.33 38.557C42.6236 38.9975 42.7305 39.5364 42.6274 40.0556C42.5243 40.5749 42.2196 41.032 41.78 41.327Z"
    fill="#c2c3c7"
  />{" "}
</g>
</svg>
</span>
                        </div>
                        </div>
                      </>):(<>
                        <div
                    className="p-5 rounded text-dark fw-bold  text-start mt-5"
                    data-kt-element="message-text"
                  > 
                   {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil003.svg*/}
<span className="svg-icon svg-icon-muted svg-icon-2hx me-5">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      opacity="0.3"
      d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
      fill="black"
    />
    <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
  </svg>
</span>
{/*end::Svg Icon*/}
<span className="me-5">
 {filename}
 </span>
 <span className="svg-icon svg-icon-muted svg-icon-2hx me-5" style={{cursor: "pointer"}} onClick={(e)=>downloadFile(filename)}>
 <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="SVGRepo_bgCarrier" strokeWidth={0} />
<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
<g id="SVGRepo_iconCarrier">
  {" "}
  <path
    d="M31.67 3.50098C24.0687 3.50892 16.781 6.53205 11.406 11.907C6.03107 17.2819 3.00794 24.5697 3 32.171C3.00953 39.7705 6.03361 47.0559 11.4086 52.4282C16.7836 57.8006 24.0705 60.8212 31.67 60.827C39.2687 60.819 46.5538 57.797 51.9269 52.4239C57.3 47.0508 60.322 39.7656 60.33 32.167C60.321 24.5681 57.2988 17.2829 51.9261 11.9091C46.5534 6.53531 39.2689 3.51156 31.67 3.50098ZM41.78 41.327L32.79 47.317L32.78 47.327C32.4528 47.5499 32.0659 47.6684 31.67 47.667C31.2739 47.6699 30.8865 47.5512 30.56 47.327L30.39 47.207L21.56 41.327C21.1188 41.0336 20.812 40.5772 20.707 40.0578C20.602 39.5385 20.7074 38.9987 21 38.557C21.2956 38.1155 21.7537 37.8089 22.2746 37.7039C22.7954 37.599 23.3366 37.7044 23.78 37.997L29.67 41.927V18.667C29.6691 18.4041 29.7202 18.1436 29.8204 17.9005C29.9205 17.6574 30.0678 17.4366 30.2537 17.2507C30.4396 17.0648 30.6605 16.9175 30.9035 16.8173C31.1466 16.7172 31.4071 16.6661 31.67 16.667C32.2004 16.667 32.7091 16.8777 33.0842 17.2528C33.4593 17.6278 33.67 18.1365 33.67 18.667V41.927L39.56 37.997C40.0017 37.7044 40.5415 37.599 41.0608 37.704C41.5802 37.809 42.0366 38.1158 42.33 38.557C42.6236 38.9975 42.7305 39.5364 42.6274 40.0556C42.5243 40.5749 42.2196 41.032 41.78 41.327Z"
    fill="#c2c3c7"
  />{" "}
</g>
</svg>
</span>
                      </div>
                      </>)}
                      </>))}
                  </>):(<></>)} 
    </div>
    {/*end::Body*/}
  </div>
  {/*end::Card*/}
</div>

                {/*end::Message(in)*/}
                </>):(<>
                {/*begin::Message(out)*/}
                <div className="mb-9">
  {/*begin::Card*/}
  <div className="card card-bordered w-100 bg-light-primary" style={{borderRadius:0, border: "1px solid #e0e1e2"}}>
    <div className="card-header-custom">
  {/*begin::Wrapper*/}
  <div className="w-100 d-flex flex-stack">
        {/*begin::Container*/}
        <div className="d-flex align-items-center f">
          {/*begin::Author*/}
          <div className="symbol symbol-50px me-5">
            <div className="symbol-label fs-1 fw-bolder bg-light-info text-info">
            {value.name.charAt(0)}
            </div>
          </div>
          {/*end::Author*/}
          {/*begin::Info*/}
          <div className="d-flex flex-column fw-bold fs-5 text-gray-600 text-dark">
            {/*begin::Text*/}
            <div className="d-flex align-items-center">
              {/*begin::Username*/}
              <a
                href="../../demo1/dist/pages/user-profile/overview.html"
                className="text-gray-800 fw-bolder text-hover-primary fs-5 me-3"
              >
                {value.name}
              </a>
              {/*end::Username*/}
             </div>
            {/*end::Text*/}
            {/*begin::Date*/}
            <span className="text-muted fw-bold fs-6">{formatMessageTime(value.date)}</span>
            {/*end::Date*/}
          </div>
          {/*end::Info*/}
        </div>
        {/*end::Container*/}
         
      </div>
      {/*end::Wrapper*/}
    </div>
    {/*begin::Body*/}
    <div className="card-body">
    
      {/*begin::Desc*/}
      <p className="fw-normal fs-5 text-gray-700 m-0">
      {<div dangerouslySetInnerHTML={{ __html: value.message }} />}
      </p>
      {/*end::Desc*/}
      {value.attachment!=""?(<>
          {value.attachment.split(',').map((filename,index2)=>(<>  
                      {getFileExtension(filename)=="png" || getFileExtension(filename)=="jpg" ||getFileExtension(filename)=="jpeg"?(<>
                        <div className="mt-2 image-container me-3" style={{maxWidth:"300px",maxHeight:"250px"}}>
                        <img src={"https://api.utho.com/image.php?file="+filename}  style={{maxWidth:"300px",maxHeight:"250px"}} />
                        <div className="overlay">
                        <span className="svg-icon svg-icon-muted svg-icon-2hx me-5" style={{cursor: "pointer"}} onClick={(e)=>downloadFile(filename)}>
 <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="SVGRepo_bgCarrier" strokeWidth={0} />
<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
<g id="SVGRepo_iconCarrier">
  {" "}
  <path
    d="M31.67 3.50098C24.0687 3.50892 16.781 6.53205 11.406 11.907C6.03107 17.2819 3.00794 24.5697 3 32.171C3.00953 39.7705 6.03361 47.0559 11.4086 52.4282C16.7836 57.8006 24.0705 60.8212 31.67 60.827C39.2687 60.819 46.5538 57.797 51.9269 52.4239C57.3 47.0508 60.322 39.7656 60.33 32.167C60.321 24.5681 57.2988 17.2829 51.9261 11.9091C46.5534 6.53531 39.2689 3.51156 31.67 3.50098ZM41.78 41.327L32.79 47.317L32.78 47.327C32.4528 47.5499 32.0659 47.6684 31.67 47.667C31.2739 47.6699 30.8865 47.5512 30.56 47.327L30.39 47.207L21.56 41.327C21.1188 41.0336 20.812 40.5772 20.707 40.0578C20.602 39.5385 20.7074 38.9987 21 38.557C21.2956 38.1155 21.7537 37.8089 22.2746 37.7039C22.7954 37.599 23.3366 37.7044 23.78 37.997L29.67 41.927V18.667C29.6691 18.4041 29.7202 18.1436 29.8204 17.9005C29.9205 17.6574 30.0678 17.4366 30.2537 17.2507C30.4396 17.0648 30.6605 16.9175 30.9035 16.8173C31.1466 16.7172 31.4071 16.6661 31.67 16.667C32.2004 16.667 32.7091 16.8777 33.0842 17.2528C33.4593 17.6278 33.67 18.1365 33.67 18.667V41.927L39.56 37.997C40.0017 37.7044 40.5415 37.599 41.0608 37.704C41.5802 37.809 42.0366 38.1158 42.33 38.557C42.6236 38.9975 42.7305 39.5364 42.6274 40.0556C42.5243 40.5749 42.2196 41.032 41.78 41.327Z"
    fill="#c2c3c7"
  />{" "}
</g>
</svg>
</span>
                        </div>
                        </div>
                      </>):(<>
                        <div
                    className="p-5 rounded text-dark fw-bold  text-start mt-5"
                    data-kt-element="message-text"
                  > 
                   {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil003.svg*/}
<span className="svg-icon svg-icon-muted svg-icon-2hx me-5">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      opacity="0.3"
      d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
      fill="black"
    />
    <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
  </svg>
</span>
{/*end::Svg Icon*/}
<span className="me-5">
 {filename}
 </span>
 <span className="svg-icon svg-icon-muted svg-icon-2hx me-5" style={{cursor: "pointer"}} onClick={(e)=>downloadFile(filename)}>
 <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="SVGRepo_bgCarrier" strokeWidth={0} />
<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
<g id="SVGRepo_iconCarrier">
  {" "}
  <path
    d="M31.67 3.50098C24.0687 3.50892 16.781 6.53205 11.406 11.907C6.03107 17.2819 3.00794 24.5697 3 32.171C3.00953 39.7705 6.03361 47.0559 11.4086 52.4282C16.7836 57.8006 24.0705 60.8212 31.67 60.827C39.2687 60.819 46.5538 57.797 51.9269 52.4239C57.3 47.0508 60.322 39.7656 60.33 32.167C60.321 24.5681 57.2988 17.2829 51.9261 11.9091C46.5534 6.53531 39.2689 3.51156 31.67 3.50098ZM41.78 41.327L32.79 47.317L32.78 47.327C32.4528 47.5499 32.0659 47.6684 31.67 47.667C31.2739 47.6699 30.8865 47.5512 30.56 47.327L30.39 47.207L21.56 41.327C21.1188 41.0336 20.812 40.5772 20.707 40.0578C20.602 39.5385 20.7074 38.9987 21 38.557C21.2956 38.1155 21.7537 37.8089 22.2746 37.7039C22.7954 37.599 23.3366 37.7044 23.78 37.997L29.67 41.927V18.667C29.6691 18.4041 29.7202 18.1436 29.8204 17.9005C29.9205 17.6574 30.0678 17.4366 30.2537 17.2507C30.4396 17.0648 30.6605 16.9175 30.9035 16.8173C31.1466 16.7172 31.4071 16.6661 31.67 16.667C32.2004 16.667 32.7091 16.8777 33.0842 17.2528C33.4593 17.6278 33.67 18.1365 33.67 18.667V41.927L39.56 37.997C40.0017 37.7044 40.5415 37.599 41.0608 37.704C41.5802 37.809 42.0366 38.1158 42.33 38.557C42.6236 38.9975 42.7305 39.5364 42.6274 40.0556C42.5243 40.5749 42.2196 41.032 41.78 41.327Z"
    fill="#c2c3c7"
  />{" "}
</g>
</svg>
</span>
                      </div>
                      </>)}
                      </>))}
                  </>):(<></>)} 
    </div>
    {/*end::Body*/}
  </div>
  {/*end::Card*/}
</div>
                {/*end::Message(out)*/}
                </>)} </>))}  
                {/*begin::Message(out)*/}
                <div className="mb-9">
  {/*begin::Card*/}
  <div className="card card-bordered w-100 bg-light-primary" style={{borderRadius:0, border: "1px solid #e0e1e2"}}>
    <div className="card-header-custom">

      {/*begin::Wrapper*/}
      <div className="w-100 d-flex flex-stack">
        {/*begin::Container*/}
        <div className="d-flex align-items-center f">
          {/*begin::Author*/}
          <div className="symbol symbol-50px me-5">
            <div className="symbol-label fs-1 fw-bolder bg-light-info text-info">
             {ticket!=null?(ticket.name.charAt(0)):('')}
            </div>
          </div>
          {/*end::Author*/}
          {/*begin::Info*/}
          <div className="d-flex flex-column fw-bold fs-5 text-gray-600 text-dark">
            {/*begin::Text*/}
            <div className="d-flex align-items-center">
              {/*begin::Username*/}
              <a 
                className="text-gray-800 fw-bolder text-hover-primary fs-5 me-3"
              >
                {ticket!=null?(ticket.name):('')}
              </a>
              {/*end::Username*/}
             </div>
            {/*end::Text*/}
            {/*begin::Date*/}
            <span className="text-muted fw-bold fs-6">{ticket!=null?(formatMessageTime(ticket.date)):('')}</span>
            {/*end::Date*/}
          </div>
          {/*end::Info*/}
        </div>
        {/*end::Container*/}
         
      </div>
      {/*end::Wrapper*/}
    </div>
    {/*begin::Body*/}
    <div className="card-body">
      {/*begin::Desc*/}
      <p className="fw-normal fs-5 text-gray-700 m-0">
      {ticket!=null?(<><div dangerouslySetInnerHTML={{ __html: ticket.message }} /></>):('')}
      </p>
      {/*end::Desc*/}
      
    </div>
    {/*end::Body*/}
  </div>
  {/*end::Card*/}
</div>
                {/*end::Message(out)*/}

                 
              </div>
              {/*end::Messages*/}
            </div>
            {/*end::Card body*/} 
          </div>
          {/*end::Messenger*/}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Layout*/}
      </>):(<><div class="loading-spinner2"></div></>)}
    </> 
    ) 
}