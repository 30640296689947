import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';  
import UseApi from "../../../../../CustomHooks/useApi";
import copy from "copy-to-clipboard";
import UsePost from "../../../../../CustomHooks/usePost";
const AccessKey = () => {
    const [dataFetched, setDataFetched] = useState(false);
    const [access, setAccess]=useState(true);
    const [perPage, setPerPage]=useState(5);
    const [objectData,setObjectData]=useState(null);
    const dataFetchedRef=useRef (false);
    const loaderFunc=()=>{
      var loader = document.querySelector("#loader");
      var no_record = document.querySelector("#no_record");
      if(loader && no_record){
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");
  
      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
    }
    const openDrawer=()=>{
      var drawerElement = document.querySelector("#key-modal");
          drawerElement.classList.toggle("drawer-on");
          drawerElement.style.width='500px';
    }
    const getObjectStorage=async()=>{ 
     
         const data=await UseApi('get','objectstorage/innoida/accesskeys','');
        //  console.log(data);
         if(data){
          setDataFetched(true);
         }
         if('status' in data){
          if(data.status=="error"){ 
            const dt=toast.loading("Object Storage loading..."); 
             toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });  
            setAccess(false);
            setObjectData(null); 
            loaderFunc(); 
            return;
          }
       }
         if(Object.keys(data).length === 0){
          const dt=toast.loading("Object Storage loading..."); 
          setObjectData(null);
               toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              }); 
              loaderFunc();
         }else{
          setObjectData(data.accesskeys);  
          toast.dismiss();  
            
         }
    }
    useEffect(()=>{
      if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        toast.dismiss(); 
        getObjectStorage(); 
    },[]); 

    const copyToClipboard=(id)=> {   
       
      var eleText = document.querySelector("#access_copy_key_"+id);
      var ele = document.querySelector("#access_copy_btn_"+id);
      if(ele && eleText){
        var copyText=ele.innerHTML;
        var copyEle=eleText.innerHTML;
      copy(copyEle);   
      ele.innerHTML="Copied";
      // ele.classList.remove("badge-primary");
      // ele.classList.add("badge-success");
      setTimeout(() => {
      //   ele.classList.add("badge-primary");
      // ele.classList.remove("badge-success");
        ele.innerHTML=copyText;
      }, 2000);
    }
     
    }

    const updateKey=async(accesskey,name, status)=>{
        var selected_key_name=document.querySelector("#selected_key_name");
        var selected_key_status=document.querySelector("#selected_key_status");
        var selected_accesskey=document.querySelector("#selected_accesskey");
        if(selected_key_name && selected_key_status && selected_accesskey){
          selected_accesskey.value=accesskey;
          selected_key_name.value=name;
          if(status=="Active"){
             selected_key_status.value="enable";
          }else if(status=="Suspended"){
            selected_key_status.value="disable";
          }
        }
      openDrawer();
    }
 
    const updateKeyStatus=async()=>{ 
      var submitBtn=document.querySelector("#key-btn");  
      var selected_key_name=document.querySelector("#selected_key_name");
      if(submitBtn && selected_key_name){
        var preContent=submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
      const dt=toast.loading("Please wait...");   
      var accesskeyname=selected_key_name.value;
      const responseData=await UsePost('post','objectstorage/innoida/accesskey/'+accesskeyname+'/status','key_status_add');
      if(responseData){   
        submitBtn.classList.remove("disabled");
        submitBtn.innerHTML=preContent;
        if(responseData.status==='error'){
          toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        }else if(responseData.status==='success'){
          toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });

        getObjectStorage();
        openDrawer();
      }
    } 
        }
      }
    function ProductInfo({ productdata }) { 
        return(
            <> 
     
            {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((value, index) => (
           <tr>
           <td className="text-center p-3">
             <div className="align-items-center w-100 me-2">
               <div className=" flex-stack mb-2">
                 <span className=" me-2 fs-7 fw-bold">{value.name}</span>
               </div>
             </div>
           </td>
           <td className="text-center">
             <div className=" align-items-center w-100 me-2">
               <div className=" flex-stack mb-2"> 
                 <a className=" me-2 fs-7 fw-bold">
                 <span id={"access_copy_key_"+value.accesskey}>{value.accesskey}</span>
                 <span id={"access_copy_btn_"+value.accesskey} onClick={(e)=>copyToClipboard(value.accesskey)} className="svg-icon svg-icon-primary svg-icon-2 ms-5">
   <svg
     xmlns="http://www.w3.org/2000/svg"
     width={24}
     height={24}
     viewBox="0 0 24 24"
     fill="none"
   >
     <path
       opacity="0.5"
       d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
       fill="black"
     />
     <path
       fillRule="evenodd"
       clipRule="evenodd"
       d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
       fill="black"
     />
   </svg>
 </span>
 
                 </a>
               </div>
             </div>
           </td> 
           <td className="text-center">
              <div className="align-items-center"> 
                  <div className="d-flex justify-content-start flex-column ">
                   {value.status=="Active"?(<>
                    <span className="fw-bold fs-6 text-gray-700"><span className="status-circle"></span>
                    {value.status}
                    </span>  </>):(<>
                       {value.status=="Suspended"?(<>
                        <span className="fw-bold fs-6 text-gray-700"><span className="status-circle-warning"></span>
                    {value.status}
                    </span> 
                       </>):(<>  
                       </>)}
                      
                    </>)}
                       
                  </div>{" "}
                </div> 
              </td> 
           <td className="text-center">
              <div className="align-items-center">
                  {" "}
                  <div className="d-flex justify-content-start flex-column">
                    {" "}
                    <a className="text-gray-700 fw-bold text-hover-primary fs-6">
                    {value.created_at}
                    </a> 
                  </div>{" "}
                </div> 
              </td>  
              <td className="text-center">
              
              <div className="card-toolbar">
                
                <a
                  style={{cursor:"pointer"}}
                  className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                   onClick={(e)=>updateKey(value.accesskey, value.name, value.status)}
                   > 
                  <span className="svg-icon svg-icon-2">
                    
                  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    opacity="0.3"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
    fill="black"
  />
  <path
    d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
    fill="black"
  />
  <path
    d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
    fill="black"
  />
</svg>

                  </span>
                </a> 
               
              </div>
            </td>
         </tr>
               ))):( <>
                <tr>
                         <td colspan="12" className="dataTables_empty" id="loader">
                          <div className="loading-spinner"></div>
                        </td>
                        <td colspan="12" className="d-none" id="no_record" valign="top">
                          <div className="d-flex flex-column flex-center"> 
                            <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                          </div>
                        </td>
                        </tr>
                </>)}
                
            </>
        )
    }
    
    
    const [searchTerm,setSearchTerm]=useState('');
    function PaginatedItems({ itemsPerPage, ProductData, search }) {
      // We start with an empty list of items. 
      const [pageCount, setPageCount] = useState(0);
      const [vpcData,setVpcData]=useState(null);
      // Here we use item offsets; we could also use page offsets
      // following the API or data you're working with.
      const [itemOffset, setItemOffset] = useState(0);
    
      useEffect(() => {
        // Fetch items from another resources.
        
        const endOffset = itemOffset + itemsPerPage;
    
    
      //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
         if(ProductData!=null){
        //   var str="Noida";
       // ////console.log(ProductData);
        const data= ProductData.filter(row => {
         return  Object.values(row).some(value => { 
            if(value!=null){
              if(value.toString().toLowerCase().includes(search.toLowerCase())){
                return value;
              } 
            }
          });
        });
        //////console.log(data);
          // const data=ProductData.filter(item => 
          //        item.hostname.toLowerCase().includes(search.toLowerCase())
          //              ); 
    
                       if(data.length<=0){
                        loaderFunc();
                       }
        setVpcData(data.slice(itemOffset, endOffset));
        //setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
        // alert(pageCount);
          }
      }, [itemOffset, itemsPerPage]);
    
      // Invoke when user click to request another page.
      
      const handlePageClick = (event) => { 
        const newOffset = event.selected * itemsPerPage % ProductData.length;
        ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
       
      };
      var searchEle=document.querySelector("#searchClient");
      if(searchEle){
        searchEle.focus();
      }
      return (
        <>
          <ProductInfo productdata={vpcData} />
          <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
          </td>
        </>
      );
    }
    return (
        <>
          <div
          className="tab-pane fade"
          id="keys-tab"
          role="tabpanel"
        >
              <div className="mb-5 mb-xl-12"> 
                <div className="table-responsive">
                <table 
                    className="table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                  >
                    <thead className="bg-secondary">
                      <tr className="fw-bold "> 
                      <th className="w-100px text-center"> Name </th>
                      <th className="w-100px text-center"> Access Keys </th> 
                      <th className="w-100px text-center"> Status </th> 
                      <th className="w-150px text-center"> CreatedAt </th> 
                      <th className="w-150px text-center"> 
                      <div
                      className="position-relative my-1"
                      style={{ marginRight: 20 }}
                    >
                       <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
                      </th>
                      </tr>
                    </thead>
                    <tbody>
                    {objectData!=null?(<>
<PaginatedItems itemsPerPage={perPage} ProductData={objectData} search={searchTerm}  />
  </>):(<>
    <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
  </>)}
                    </tbody>
                  </table>
                </div>
              </div>
            
              </div>

              <div
  id="key-modal"
  className="bg-body shadow drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-name="chat"
  data-kt-drawer-activate="true" 
  data-kt-drawer-direction="end" 
  data-kt-drawer-close="#close_ssh"
  data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}" 
  style={{maxWidth: '70%',important:true}}
  
>
  {/*begin::Messenger*/}
  <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
    {/*begin::Card header*/}
    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div className="d-flex justify-content-center flex-column me-3">
          <a
            
            className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
          >
            Update Key
          </a>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Close*/}
        <div
        onClick={openDrawer}
          className="btn btn-sm btn-icon btn-active-light-primary"
          id="close_ssh"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {/*end::Card header*/}
    {/*begin::Card body*/}
    <div className="" id="">
      {/*begin::Form*/}
      <form id="key_status_add"  className="form">
        {/*begin::Modal body*/}
        <div className="modal-body ">
          {/*begin::Scroll*/}
          <div
            className="scroll-y me-n7 pe-7"
            id="kt_modal_create_api_key_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
            data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
            data-kt-scroll-offset="300px"
          >
            {/*begin::Input group*/}
            <div className="mb-5 fv-row"> 
              {/*begin::Input*/} 
               
              <div className="mb-10">
              <label className="form-label">Select access key</label>
                <input type="text" className="form-control" readOnly defaultValue="" id="selected_key_name" />
                <input type="hidden" className="form-control" readOnly defaultValue="" name="accesskey" id="selected_accesskey" />
                </div>
                <div className="mb-10">
                <label className="form-label">Select Status</label>
                <select
                  className="form-select" 
                  name="status" 
                  id="selected_key_status" >
                  <option value="enable">Active</option>
                  <option value="disable">Suspend</option>
                  <option value="remove">Delete</option> 
                </select> 
                </div> 
                
              {/*end::Input*/}
            </div>
            {/*end::Input group*/} 
          </div>
          {/*end::Scroll*/} 
        {/*end::Modal body*/}
        {/*begin::Modal footer*/} 
          {/*begin::Button*/} 
                <a  
            className="btn btn-primary w-100"
            id="key-btn"
            onClick={updateKeyStatus}
          >
            <span className="indicator-label">Update Access key</span>
          </a>
          
          {/*end::Button*/}
        </div>
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
    </div>
    {/*end::Card body*/}
  </div>
  {/*end::Messenger*/}
</div>  
        </>
    );
}

export default AccessKey;
