import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import localStorage from "local-storage";
import UseLogin from "../CustomHooks/useLogin";
import { useEffect } from "react";
import { useState } from "react";
import UseApi from "../CustomHooks/useApi";
import CallAppinfo from "../CustomHooks/CallAppinfo";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from 'crypto-js';
import './custom.css';
import UseLoginApi from "../CustomHooks/useLoginApi";
import ApplyCoupon from "./ApplyCoupon";

export default function Login(){ 
     const navigate=useNavigate();
      var current_url_string = window.location.href;
      const current_url = new URL(current_url_string);
      const current_domain = current_url.hostname;
      const [app_info, setApp_info] = useState(null);
      const [logo_white, setLogo_white] = useState(null);
      async function currApp(){
        const AppData=await axios.get('https://api.utho.com/v2/appinfo');
        if(AppData && AppData.data){
          //console.log("data come");
        setApp_info(AppData.data);
        //console.log(AppData);
       var siteUrlString=AppData.data.site;

       var siteUrlArr=siteUrlString.split(".");
        var siteLength=siteUrlArr.length;

       var siteOrg="https://api."+siteUrlArr[siteLength-2]+"."+siteUrlArr[siteLength-1];
       localStorage.set("filesUrl",siteOrg);
       var whiteLogo=siteOrg+"/publicfiles/"+AppData.data.logo_light;
       setLogo_white(whiteLogo);
      }
    }
      function sleep(delay) {
        var start = new Date().getTime();
        while (new Date().getTime() < start + delay);
    }
     const loginUser=async()=>{
      var submitBtn=document.querySelector("#login-btn");
      if(submitBtn){
        var preContent=submitBtn.innerHTML;
        submitBtn.classList.add("disabled");
        submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
      const dt=toast.loading("Please wait...");
      const responseData=await UseLogin('post','auth','login-form');
       if(responseData){
        submitBtn.innerHTML=preContent;
        submitBtn.classList.remove("disabled");
      if(responseData.status=='loginsuccess'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });

        localStorage.set("Auth",responseData.token);
        Cookies.set('microhost_api_auth', responseData.token, { expires: 30, path: '/', domain: "."+current_domain });
        const apiUrl = 'https://api.utho.com/v2/appinfo';
        const headers = { 
          'Content-Type': 'application/json', // Adjust as needed
        };

        // Define the Fetch options
        const fetchOptions = {
          method: 'GET',
          headers: headers // Change to 'POST', 'PUT', 'DELETE', etc. if needed 
        };

   const AppData= fetch(apiUrl, fetchOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json(); // Parse the response body as JSON
    })
    .then(data => { 
      if (data) {  
        localStorage.set("appInfo",data);
      } 
       
    })
    .catch(error => {
      console.error('API request failed:', error); 
    });
        // const now = new Date();
        // const expires = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days
        // document.cookie = "microhost_api_auth="+responseData.token+"; expires=" + expires.toUTCString() + "; path=/";
        // const data=await CallAppinfo();
        
        
        if(AppData){
          
          setTimeout(()=>{
            navigate("/");
          },2000) 
        // const location_url = window.location.origin;
        //  window.location.href = location_url;
        }

        }
      if(responseData.status=='loginfailed'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false,  closeOnClick: true,  pauseOnHover: true,  draggable: true, progress: undefined
      });
      }
    }
    }
     }

useEffect(() => {
  currApp();
  window.addEventListener("keydown", function(event) {
    event.stopPropagation();
    toast.dismiss();
    var query = window.location.href;
      var vars = query.split("/");
      var action=vars[3];
      //console.log(vars);
      if(event.key=="Enter" && action=="login" || event.key=="Enter" && action==""){
    //console.log(event);
    loginUser();
  }
});
}, []);

const showPassword=(action)=>{
  var password_field=document.querySelector("#password_field");
  var eye_close=document.querySelector("#eye-close");
  var eye_open=document.querySelector("#eye-open");
  if(eye_close && eye_open){
  if(action=="close"){
    eye_close.classList.remove("d-none");
    eye_open.classList.add("d-none");
    password_field.type="password";
  }else{
    eye_close.classList.add("d-none");
    eye_open.classList.remove("d-none");
    password_field.type="text";  }
}
}
    return(
        <>
<ApplyCoupon />
{/* Same as */}
<div className="page d-flex flex-row flex-column-fluid loginbg">
<div
  className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative d-none d-lg-block d-md-block d-xxl-block"
  >
    {/*begin::Wrapper*/}
    <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">

  </div>
  {/*end::Wrapper*/}
</div>
<div className="container d-flex flex-column flex-lg-row-fluid py-10 top-front">
  {/*begin::Logo*/}
  <a href="/" className="text-center ">
  {logo_white!=null?(<>
    <img
          alt="Logo"
          src={logo_white}
          className="h-60px logo-fade-in"
        />
  </>):(<>
    <img
          alt="Logo"
          src="./assets/media/logos/loader-black.png"
          className="h-60px logo-fade-in"
        />
  </>)}
        
      </a>
      {/*end::Logo*/}
  {/*begin::Content*/}
  <div className="container">
    {/*begin::Wrapper*/}
    <div className="w-lg-600px p-10 p-lg-15 mx-auto">
      {/*begin::Form*/}
      <form
        onSubmit={(event)=>event.preventDefault()}
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate="novalidate"
        id="login-form"
      >
        {/*begin::Heading*/}
        <div className="text-center mb-10">
          {/*begin::Title*/}
          <h1 className="text-light mb-3">Sign In To {app_info!=null?(app_info.brand):('')}</h1>
          {/*end::Title*/}
          {/*begin::Link*/}
          <div className="text-gray-400 fw-bold fs-4">
            New Here?&nbsp;&nbsp;
            <Link to="/signup">
            <a
              className="link-primary fw-bolder"
            >
              Create an Account
            </a>
            </Link>
          </div>
          {/*end::Link*/}
        </div>
        {/*begin::Heading*/}
        {/*begin::Input group*/}
        <div className="fv-row mb-10 fv-plugins-icon-container">
          {/*begin::Label*/}
          <label className="form-label fs-6 fw-bolder text-gray-400">Email</label>
          {/*end::Label*/}
          {/*begin::Input*/}
          <input
            className="form-control form-control-lg form-control-solid"
            type="text"
            name="email"
            placeholder="Enter registered email address"
            autoComplete="off"
          />
          {/*end::Input*/}
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="fv-row mb-10 fv-plugins-icon-container">
          {/*begin::Wrapper*/}

          <div className="d-flex flex-stack mb-2">
            {/*begin::Label*/}
            <label className="form-label fw-bolder text-gray-400   fs-6 mb-0">
              Password
            </label>
            {/*end::Label*/}
            {/*begin::Link*/}
            <Link to="/forgot-password">
            <a

              className="link-primary fs-6 fw-bolder"
            >
              Forgot Password ?
            </a>
            </Link>
            {/*end::Link*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Input*/}
          <div className="position-relative mb-3">
  <input
    className="form-control form-control-lg form-control-solid"
    type="password"
    placeholder="Enter Password"
    name="password"
    id="password_field"
    autoComplete="off"
  />
  <span
    className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
    data-kt-password-meter-control="visibility"
  >
    <i className="bi bi-eye-slash fs-2 " id="eye-close" onClick={(e)=>showPassword("open")} />
    <i className="bi bi-eye fs-2 d-none" id="eye-open" onClick={(e)=>showPassword("close")}/>
  </span>
</div>
          {/*end::Input*/}
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        {/*end::Input group*/}
        {/*begin::Actions*/}
        <div className="text-center">
          {/*begin::Submit button*/}
          <button
            type="button"
            onClick={loginUser}
            className="btn btn-lg btn-primary w-100 mb-5"
            id="login-btn"
          >
            <span className="indicator-label">Login</span>
          </button>
          {/*end::Submit button*/}

        </div>
        {/*end::Actions*/}
        <div />
      </form>
      {/*end::Form*/}
      <div className="text-center text-muted text-uppercase fw-bolder mb-5">or</div>
      <Link to="/login-with-otp">
      <a  ><button type="button" class="btn btn-light-primary fw-bolder w-100 mb-10"><img class="h-20px me-3 " src="assets/media/svg/misc/smartphone.svg" />Login with OTP</button></a>
      </Link>
    </div>
    {/*end::Wrapper*/}

  </div>
  {/*end::Content*/}
</div>
</div>
        </>
    )
}
