import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import UseApi from "../../../../CustomHooks/useApi"; 
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";

export default function VpcDeploy(){ 
  const [ddata,setDdata]=useState(null);
  const dataFetchedRef=useRef(); 
 // const dczonesData=useRef();
  let navigate=useNavigate();
  const deployVpc=async()=>{
    var submitBtn=document.querySelector("#vpc-btn");
    if(submitBtn){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';  
    toast.dismiss();
    const dt=toast.loading("Please wait...");
    const responseData=await UsePost('post','vpc/create','createvpc');
    if(responseData){
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      
      navigate("/vpc/"+responseData.id);
    }
  }
      }
    }
    useEffect(() => {
      window.addEventListener("keydown", function(event) {
        event.stopPropagation();
        var query = window.location.href; 
        var vars = query.split("/"); 
        var action=vars[4]; 
        //console.log(vars);
        if(event.key=="Enter" && action=="deploy" && vars[3]=="vpc"){
          deployVpc();
      }
    });
    }, []);
    const getDczone=async()=>{
         const data=await UseApi('get','dczones',''); 
         if(Object.keys(data).length === 0){
          setDdata(null);  
         }else{
          data.dczones.map((value,index)=>{ 
            if(value.status==='active' && value.product_vpc==='1'){ 
              //console.log("value");
              setDdata(value); 

            }
          })
            
         }
    }
    useEffect(()=>{
      if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        toast.dismiss();
        getDczone(); 
    },[]); 
   //console.log(ddata);
    return(
        <> 
        {ddata!=null?(<>
   <form id="createvpc" className="form"> 
      <div className="col-xl-12 card mb-5">
      <div className="card-header border-3 mb-5 pt-2">
           <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
            Select DC Location
            </span>
            <span className="text-gray-400 mt-1 fw-bold fs-6">
            Data Centeres
            </span>
          </h3>
        </div>  
         
            {/* Begin body */}
            <div className="container pb-5">
              {/*begin::Row*/}
              <div
                id="dc-location"
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              > 
              {ddata!=null?(
                <>
                <div className="col-md-3 col-lg-3 col-xxl-3 dc_sibling">
                  <input
                    className="form-check-input deploy_checkbox "
                    type="radio"
                    name="dcslug"
                    defaultValue={ddata.slug}
                    id={"dcslug"+ddata.slug+""} 
                  />
                    <label
                  style={{ WebkitJustifyContent: 'unset' }}
                  id={"dcslugl_"+ddata.slug+""}
                  htmlFor={"dcslug"+ddata.slug+""}
                  className=" dcsluglable border-1 border-gray-300 border px-1 py-1">
  {/*begin::Info*/}
  <div className="d-flex flex-stack mb-3">
    {/*begin::Wrapper*/}
    <div className="me-3 p-3">
      {/*begin::Icon*/}
      <img
        src={"/assets/media/flags/svg/"+ddata.cc+".svg"}
        className="w-35px ms-n1 me-6"
        alt=""
      />
      {/*end::Icon*/}
      {/*begin::Title*/}
      <a 
        className="text-gray-800 text-hover-primary fw-bolder"
      >
        {ddata.city}
      </a>
      {/*end::Title*/}
    </div>
    {/*end::Wrapper*/}
    {/*begin::Action*/}
    <div className="me-3">
      {/*begin::Menu*/}
      <span className=" text-muted fw-bold justify-content-end">{ddata.country}</span>
      {/*end::Menu*/}
    </div>
    {/*end::Action*/}
  </div>
  {/*end::Info*/}
  {/*begin::Customer*/}
  {/*end::Customer*/}
</label>
                </div>  
                </>
              ):(<><div className="small-spinner"></div></>)}
              </div>
              {/*end::Row*/}
              
            </div>
            </div>
            <div className="col-xl-12 card mb-5">
      <div className="card-header border-3 mb-5 pt-2">
           <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
            Define VPC Network
            </span>
            <span className="text-gray-400 mt-1 fw-bold fs-6">
            Configure Your VPC
            </span>
          </h3>
        </div>  
 
            <div className="container pb-5">
              {/*begin::Row*/}
              <div
                id="define-vpc"
                className="row g-9"
                data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]"
              >
                <div className="col-lg-4 col-md-4 col-xxl-4 fw-bold">
                  <label htmlFor="vpcName">VPC Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter vpc name"
                    name="name"
                    id="name"
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-xxl-4 fw-bold">
                  <label htmlFor="vpcName">Network</label>
                  <input
                    type="hidden"
                    name="planid"
                    id="planid"
                    defaultValue={1008}
                  />
                  <input
                    type="text"
                    className="form-control"
                    name="network"
                    id="network"
                    onchange="validatevpc();"
                    defaultValue="10.210.100.0"
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-xxl-4 fw-bold">
                  <label htmlFor="vpcName">Network Size</label>
                  <input
                    type="text"
                    className="form-control"
                    name="size"
                    id="size"
                    onchange="validatevpc();"
                    defaultValue={24}
                    min={1}
                  />
                </div>
              </div>
              {/*end::Row*/}
            </div>
          </div> 
          <div className="col-xl-12 card mb-5">
      <div className="card-header border-3 mb-5 pt-2">
           <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-gray-800">
            Deploy VPC
            </span>
            <span className="text-gray-400 mt-1 fw-bold fs-6">
            Click the below btn to deploy your VPC
            </span>
          </h3>
        </div>  
 
            <div className="container pb-5">
              {/*begin::Row*/}
              <button
            type="button"
            className="btn btn-primary"
            style={{ width: "100%" }}
            onClick={deployVpc}
            id="vpc-btn"
          >
            Deploy VPC +
          </button> 
              {/*end::Row*/}
            </div>
          </div>
          
  {/*end::Tables Widget 9*/}
 
</form>
</>):(<><TopBarHeader /></>)}
        </>
        
    )
}