import React from 'react';
import "./custom.css";
const TableLoader = () => {
    return (
        <>
         <div class='preloader'>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
         </div>

        </>
    );
}

export default TableLoader;
