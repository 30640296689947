import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import MicroApp from "./App/MicroApp/MicroApp";
import {BrowserRouter as Router} from "react-router-dom"; 
import Login from './App/Auth/Login';
import Signup from './App/Auth/Signup';
import ForgotPassword from './App/Auth/ForgotPassword';
import NewPassword from './App/Auth/NewPassword';
import TwoFactor from './App/Auth/TwoFactor'; 
import Error404 from './App/Auth/Error404';
import {Elements} from "@stripe/react-stripe-js"; 
import localStorage from 'local-storage'; 
import LoginOtp from './App/Auth/LoginOtp';
import LoginWithOTP from './App/Auth/LoginWithOTP'; 
import DeployCloud from './App/MicroApp/DeployCloud';
import IsTwoFactor from './App/Auth/IsTwoFactor';
import Feedback from './App/MicroApp/Body/Feedback';
import ActivateUser from './App/MicroApp/Body/Products/subuser/ActivateUser';
import { ToastContainer, toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
import 'react-toastify/dist/ReactToastify.css';   
import LoaderGif from './App/MicroApp/Body/Products/LoaderGif';
import Cookies from 'js-cookie'; 
import CallAppinfo from './App/CustomHooks/CallAppinfo';
import AccountVerifyMobile from './App/MicroApp/Body/Products/verification/mobile/AccountVerifyMobile';
import SubuserVerification from './App/Auth/SubuserVerification';
import UpdateUserProfile from './App/Auth/UpdateUserProfile';
import BuisnessProfile from './App/Auth/BuisnessProfile';
import TopBarHeader from './App/CustomHooks/TopBarHeader';
import TopBarHeaderLine from './App/CustomHooks/TopBarHeaderLine';
function AppRoute() {    
  const [userData, setUserData] = useState(null);
  const navigate=useNavigate(); 
  // useEffect(()=>{
  //   navigate("/");
  // },[])
  const call_info =async()=>{
    const AppData=await CallAppinfo();
  }

  useEffect(()=>{
    call_info();
  },[])
  var current_url_string = window.location.href;
      const current_url = new URL(current_url_string);
      const current_domain = current_url.hostname;  
       const callOnce=useRef(true);
      const isProfile=useRef(false);
  // const lsAuth=localStorage.get("Auth"); 
   var lsAuth=Cookies.get('microhost_api_auth');   
   useEffect(() => {
    if (lsAuth && callOnce.current) {
      callOnce.current = false;
      const apiUrl = 'https://api.utho.com/v2/account/info';
      const bearerToken = lsAuth;
  
      const headers = {
        'Authorization': 'Bearer ' + bearerToken,
        'Content-Type': 'application/json',
      };
  
      const fetchOptions = {
        method: 'GET',
        headers: headers,
      };
  
      fetch(apiUrl, fetchOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          if (data.user) {
            localStorage.set("user_info", data.user);
            setUserData(data.user); // Update the state with user data here
             
          }
        })
        .catch(error => {
          console.error('API request failed:', error);
        });
    }
  }, [lsAuth]);

   if(lsAuth){    
  
    localStorage.set("Auth",lsAuth);
    // CallAppinfo(); 
     if(userData){ 
      
      const UserRes=userData; 
     if(UserRes){ 
      // const userHash=CryptoJS.MD5(UserRes.email).toString();
      // const two_fa_Auth=userHash+lsAuth+userHash; 
     if(UserRes.twofa != "Required"){ 
      if(UserRes.sms_verified==0){
    
        return(<> 
         <Routes>
     <Route path="/activateuser/:id" element={<ActivateUser />}   /> 
     <Route path="*" element={<AccountVerifyMobile />}   /> 
     </Routes>    
        </>)
      }else{   
           if(UserRes.type=="Individual"){
    
        if(UserRes.address && UserRes.city && UserRes.state && UserRes.postcode && UserRes.country){
          isProfile.current=true;
        }else{
        isProfile.current=false;
        }
    
      }else{ 
        if(UserRes.address && UserRes.city && UserRes.state && UserRes.postcode && UserRes.country && UserRes.company){
            isProfile.current=true;
          }else{
         isProfile.current=false;
          }
    
      }  
        if(isProfile.current){ 
        if(UserRes.supportneed_usecase && UserRes.supportneed_businesstype && UserRes.supportneed_monthlyspend && UserRes.supportneed_employeesize){
           return (
              <> 
              <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              /> 
             <SubuserVerification />
              </>
          );
        }else{
           if(UserRes.support_fields_required=="No"){
             
             return (
              <>  
              <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />
                 <SubuserVerification /> 
              </>
          ); 
          }else{
            return (
              <> 
              <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />   
              <BuisnessProfile />  
              </>
          );
          }
        
        }
      }else{ 
        return (
          <> 
           <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
             <UpdateUserProfile />  
          </>
      );
      }
      } 
    }else{
      return (
        <> 
         <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        
      <Routes> 
      <Route path="/activateuser/:id" element={<ActivateUser />}   />
      <Route path="*" element={<TwoFactor />}   />
      </Routes>  
      </>
      );
    }  
    }
      }else{
        return(<>
        <TopBarHeaderLine />
        <div className="mainLoad"><img src="assets/images/utho.png" alt="" width="100%" /></div>
        {/* <div class="loading-spinner2"></div> */}
        </>)
      }

  }else{
    return (
      <>
       <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> 
        <Routes> 
        
        <Route path="/" element={<Login />}   /> 
        <Route path="/login" element={<Login />}   />
        {/* <Route path="/login/auth" element={<SignIn />}   /> */}
        <Route path="/activateuser/:id" element={<ActivateUser />}   />
        <Route path="/signup" element={<Signup />}   />
        <Route path="/forgot-password" element={<ForgotPassword />}   />
        <Route path="/new-password" element={<NewPassword />}   /> 
        <Route path="/login-with-otp" element={<LoginWithOTP />}   />
        <Route path="/verify-otp" element={<LoginOtp />}   />
        {/* <Route path="/deploy-server" element={<DeployCloud />} /> */}
        <Route path="*" element={<Login />} />

        </Routes>  
      </>
  );
  }
 
}

export default AppRoute;
