import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import AccessKey from "./accessKey/AccessKey";
import StorageList from "./storage/StorageList";
import UsePost from "../../../../CustomHooks/usePost";
import CreateAccesskey from "./accessKey/CreateAccesskey";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";

export default function ObjectStorage() {
  
  const [objectData, setObjectData] = useState(null);
  const dataFetchedRef=useRef (false); 
  
  const getObjectStorage=async()=>{ 
       const data=await UseApi('get','objectstorage/innoida/bucket','');
      //  console.log(data);
       if(data){ 
       }
       if('status' in data){
        if(data.status=="error"){ 
          const dt=toast; 
          //  toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          // });   
          setObjectData([]);  
          return;
        }
     }
       if(data.buckets.length === 0){
        const dt=toast.loading("Object Storage loading..."); 
        setObjectData(null);
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });  
       }else{
        setObjectData(data.buckets);  
        toast.dismiss();  
          
       }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss(); 
      getObjectStorage(); 
  },[]); 
        return (
          <>
          {objectData!=null?(<>
           {objectData.length > 0?(<>
            <div className="col-xl-12 card">
              <div className="">
             <CreateAccesskey />
<div className="">
            <ul className="nav nav-pills nav-pills-custom row position-relative mx-0">
   
  <li className="nav-item col-6 mx-0 px-0">
    <a
      className="nav-link active d-flex justify-content-center w-100 border-0 h-100"
      data-bs-toggle="pill"
      href="#storage-tab"
    >
      <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
        Buckets
      </span>
      <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
    </a>
  </li>
  <li className="nav-item col-6 mx-0 px-0">
    <a
      className="nav-link d-flex justify-content-center w-100 border-0 h-100"
      data-bs-toggle="pill"
      href="#keys-tab"
    >
      <span className="sphide nav-text text-gray-800 fw-bolder fs-6 mb-3">
        Access Keys
      </span>
      <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded" />
    </a>
  </li>
  <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded" />
</ul>
</div>
              </div>
              <div className="tab-content" id="myTabContent">
      <AccessKey />
      <StorageList />
            
        </div>
            </div> 
           </>):(<>
            <div className="col-xl-12">
          <div className="card">
          {/*begin::Card body*/}
          <div className="card-body p-0 mt-10">
             {/*begin::Illustration*/}
             <div className="text-center px-4 mt-10">
              <span className="mw-100 mh-300px">
              <svg
          fill="#21325B"
          viewBox="0 0 34 34"
          id="icon"
          xmlns="http://www.w3.org/2000/svg"
          width="234px"
          height="234px"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
          <g id="SVGRepo_iconCarrier">
            <defs>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n                                .cls-1 {\n                                    fill: none;\n                                }\n                            "
                }}
              />
            </defs>
            <path d="M28,20H26v2h2v6H4V22H14V20H4a2.0023,2.0023,0,0,0-2,2v6a2.0023,2.0023,0,0,0,2,2H28a2.0023,2.0023,0,0,0,2-2V22A2.0023,2.0023,0,0,0,28,20Z" />
            <circle cx={7} cy={25} r={1} />
            <path d="M30,8H22v6H16v8h8V16h6ZM22,20H18V16h4Zm6-6H24V10h4Z" />
            <path d="M18,10H10V2h8ZM12,8h4V4H12Z" />
          </g>
        </svg>
        
              </span> 
        
            </div>
            {/*end::Illustration*/}
            {/*begin::Wrapper*/}
            <div className="card-px text-center py-5 mb-20">
              {/*begin::Title*/}
              <h1 className="fs-2x fw-bolder mb-5">Buckets</h1>
              <h2 className="fs-1x fw-bolder mb-5 text-muted">Seamlessly Scale and Store Data</h2>
              {/*end::Title*/}
              {/*begin::Description*/}
              <p className="text-gray-400 fs-4 fw-bold mb-10">
              Attach fault-tolerant, high-performance object storage volumes<br/> to your Cloud Servers or Kubernetes Clusters
              </p>
              {/*end::Description*/}
              {/*begin::Action*/}
              <Link to="/objectStorage/deploy">
              <a
                href="#"
                className="btn btn-primary mb-10"
              >
                Create bucket
              </a>
              </Link>
              {/*end::Action*/}
            </div>
            {/*end::Wrapper*/} 
          </div>
          {/*end::Card body*/}
        </div>
        
        </div> 
           </>)}
          
          </>):(<>
          <TopBarHeader />
          {/* <div class="loading-spinner2"></div> */}
          </>)}
            
          
          </>
        ); 
}
