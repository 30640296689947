import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import copy from "copy-to-clipboard";
import AccessDenied from "../../AccessDenied";
import "./cloud.css";
import LoaderGif from "../LoaderGif";
import ActionList from "./helper/ActionList";
import { connect } from "react-redux";
import { UpdateCloudPagination } from "../../../../Redux/Services/Action/actions";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
import TopBarHeaderLine from "../../../../CustomHooks/TopBarHeaderLine";
import TableLoader from "../../Loaders/TableLoader";
const Cloud=(props)=>{
 let nevigate = useNavigate()
  // CSS Start
  const sphide={
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
      }

  // CSS End
  const [dataFetched, setDataFetched] = useState(false);
  const [cloudData,setCloudData]=useState(null);
  const [access, setAccess]=useState(true);
  const [pageStatus, setPageStatus] = useState(false)
  const [metaData, setMetaData]=useState(null);
  const dataFetchedRef=useRef(false);
  const currentPage=useRef(0);
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }

  const getCloud=async()=>{
    toast.dismiss();
    const dt=toast
    setPageStatus(false)
    // console.log("come on");
    if(props && props.paginationInfo){
      // console.log("come in");
    var pageCount=props.paginationInfo[0].perpage;
    var CurrPage=props.paginationInfo[0].currentPage;
    if(pageCount && CurrPage){
       const data=await UseApi('get','cloud?perpage='+pageCount+'&page='+CurrPage,'');
       if(data){
        setDataFetched(true);
       }
       if('status' in data){
        if(data.status=="error"){
           toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          });
          setAccess(false);
          setCloudData(null);
          loaderFunc();
          return;
        }
     }
       if(Object.keys(data).length === 0){
             setCloudData(null);
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            loaderFunc();
       }else{
        setPageStatus(true)
          setCloudData(data.cloud);
          setMetaData(data.meta);
          toast.dismiss();
          //   toast.update(dt,{render: "Your cloud server request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          // });
       }
      }
  }

  }
  useEffect(()=>{
    getCloud();
    // console.log("cloud coming");
  },[props]);

  const copyToClipboard=(id)=> {

    var ele = document.querySelector("#ip_address_sec"+id);
    var eleText = document.querySelector("#ip_address"+id);
    if(ele){
      var copyText=ele.innerHTML;
      var copyEle=eleText.innerHTML;
    copy(copyEle);
    ele.innerHTML="Copied";
    ele.classList.remove("badge-primary");
    ele.classList.add("badge-success");
    setTimeout(() => {
      ele.classList.add("badge-primary");
    ele.classList.remove("badge-success");
      ele.innerHTML=copyText;
    }, 2000);
  }

  }


  function ProductInfo({ productdata }) {
    return(
        <>
        {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((cloud, index) => (
          <tr>
            {" "}
            <td className="p-3 d-none">
              {" "}
              <div className="d-flex flex-column w-100 me-2" >
                {" "}
                <div className="d-flex flex-stack mb-2">
                  {" "}
                  <span className=" me-2 fs-7 fw-bold">{cloud.cloudid}</span>{" "}
                </div>{" "}
              </div>{" "}
             </td>{" "}
            <td className="d-none">
              {" "}
              <div className="d-flex align-items-center sphide">
                {" "}
                <div className="symbol symbol-30px me-5">
                  {" "}
                  <img src={"/img/flag_"+cloud.dclocation.dccc+".png"} />{" "}
                </div>{" "}
                <div className="d-flex justify-content-start flex-column">
                  {" "}
                  <a

                    className="text-dark fw-bold text-hover-primary fs-6"
                  >
                    {cloud.dclocation.location}
                  </a>{" "}
                  <span className="text-muted fw-bold text-muted d-block fs-7">
                    {cloud.dclocation.country}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>{" "}
            <td>
            <div className="d-flex align-items-center ps-10">
            {cloud.stack?(<>
  <div className="symbol symbol-45px me-5 text-center">
    <img src={'/'+cloud.stack.logo_url} alt="" style={{ width: "45px",height: "45px",padding: "2px",background: "#ebebeb75"}} />
  </div>
  </>):(<>
    <div className="symbol symbol-45px me-5  text-center pt-2" style={{ width: "45px",height: "45px",padding: "2px",background: "#ebebeb75"}}>
    <span className={"fonticon_"+(cloud.image.distribution).replaceAll(" ","")} />
    </div>
  </>)}
  <div className="d-flex justify-content-start flex-column">
    <Link to={"/cloud/"+cloud.cloudid}>
    <div style={{cursor:"pointer"}} className="text-dark fw-bolder text-hover-primary fs-6">
    {cloud.hostname}
    </div>
    </Link>
    <span className="text-muted fw-bold text-muted d-block fs-7">
    {cloud.cpu} vCPU /{(cloud.ram/1024).toFixed(0)} GB / {cloud.disksize} GB Disk / {cloud.dclocation.location}
    </span>
  </div>
</div>


            </td>
            <td className="d-none">
              {cloud.stack?(<>

                <div className="d-flex align-items-center">
                {" "}
                <div className="symbol symbol-45px me-2">
              <img src={'/'+cloud.stack.logo_url} className="" alt="" style={{ width: "45px",height: "36px",padding: "2px",background: "#ebebeb75"}}  />
                </div>{" "}
                <div className="d-flex justify-content-start flex-column">
                  {" "}
                  <a

                    className=" fw-bold text-hover-primary d-block fs-6"
                  >
                    {cloud.stack.title?.charAt(0).toUpperCase() + cloud.stack.title?.slice(1)}
                  </a>{" "}
                </div>{" "}
              </div>
              </>):(<>
              <div className="d-flex align-items-center">
                {" "}
                <div className="symbol symbol-45px me-2 text-center" style={{ width: "45px",height: "36px",padding: "2px",background: "#ebebeb75"}}>
                  {" "}
                  <span className={"fonticon_"+cloud.image.distribution+""} />{" "}
                </div>{" "}
                <div className="d-flex justify-content-start flex-column">
                  {" "}
                  <a

                    className=" fw-bold text-hover-primary d-block fs-6"
                  >
                    {cloud.image.distribution.charAt(0).toUpperCase() + cloud.image.distribution.slice(1)}
                  </a>{" "}
                </div>{" "}
              </div>
              </>)}
            </td>{" "}
            <td className="text-center">
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2">
                  {" "}
                  <span className="w-100 btn btn-active-primary btn-light-primary btn-sm sphide me-2 fs-7 fw-bold"
                  htmlFor="IP Address"
                  id={"ip_address_sec"+index}
                  onClick={()=>copyToClipboard(index)}
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="Click to copy IP Address"
                  >
                   <span id={"ip_address"+index}> {cloud.ip}</span>&nbsp;&nbsp;
                    {/*begin::Svg Icon | path: assets/media/icons/duotune/general/gen054.svg*/}
  <span className="svg-icon svg-icon-light svg-icon-2 d-none">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        opacity="0.5"
        d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
        fill="black"
      />
    </svg>
  </span>
  {/*end::Svg Icon*/}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </td>{" "}
            <td className="text-start d-none">
              {" "}
              <div className="d-flex flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2 sphide">
                  {" "}
                  <p>Created:&nbsp;&nbsp; <span className="text-muted me-2 fs-7 fw-bold">
                    {cloud.created_at}
                  </span>
                  </p>
                  <p>Due date: &nbsp;&nbsp;
                  <span className="text-primary me-2 fs-7 fw-bold">
                    {cloud.nextduedate}
                  </span>
                  </p>
                </div>{" "}
              </div>{" "}
            </td>{" "}
            <td className="text-center">
              {" "}
              <div className=" flex-column w-100 me-2">
                {" "}
                <div className=" flex-stack mb-2">
                  {" "}
                  {cloud.stack?(<>
                    {cloud.stack.status!="Pending"?(<>
                    <span className="w-100 btn btn-active-success btn-light-success btn-sm sphide me-2 fs-7 fw-bold" style={{cursor:"default"}}> Active  </span>
                  </>):(<>
                    <span className="w-100 btn btn-active-warning btn-light-warning btn-sm sphide me-2 fs-7 fw-bold"> Installing Stack  </span>
                  </>)}
                  </>):(<>
                    {cloud.status=="Active"?(<>
                    <span className="w-100 btn btn-active-success btn-light-success btn-sm sphide me-2 fs-7 fw-bold"> {cloud.status}  </span>
                  </>):(<>
                    <span className="w-100 btn btn-active-warning btn-light-warning btn-sm sphide me-2 fs-7 fw-bold"> {cloud.status}  </span>
                  </>)}
                  </>)}

                  {" "}
                </div>{" "}
              </div>{" "}
            </td>{" "}
            <td className="text-center">
              {" "}
              <div className="d-flex justify-content-end flex-shrink-0">
                {" "}
                <a
                  target="_blank"
                  href={"/cloud/console/"+cloud.cloudid}
                  className="btn btn-bg-light-primary btn-active-color-primary me-1 "
                >
                  {" "}
                  <span className="svg-icon ms-2 svg-icon-2 svg-icon-primary">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      {" "}
                      <path
                        d="M2 16C2 16.6 2.4 17 3 17H21C21.6 17 22 16.6 22 16V15H2V16Z"
                        fill="black"
                      />{" "}
                      <path
                        opacity="0.3"
                        d="M21 3H3C2.4 3 2 3.4 2 4V15H22V4C22 3.4 21.6 3 21 3Z"
                        fill="black"
                      />{" "}
                      <path opacity="0.3" d="M15 17H9V20H15V17Z" fill="black" />{" "}
                    </svg>
                  </span>{" "}
                </a>{" "}
              </div>{" "}
            </td>{" "}
            <td>

              {" "}
              <div className="d-flex justify-content-start flex-shrink-0">
                {" "}
                <ActionList cloudid={cloud.cloudid} />
                {/* <Link to={"/cloud/"+cloud.cloudid+""}>
                <a

                  className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 " style={sphide}
                >

                  Manage ➔
                </a>{" "}
                </Link> */}
              </div>{" "}
            </td>{" "}
          </tr>
           ))):( <>
            <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <TopBarHeaderLine />
                      <TableLoader />
                      {/* <div className="loading-spinner"></div> */}
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center">
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
           </>)}


        </>
    )
           }

  function updatePerPage(count){
    if(props && props.updatePaginationData){
      props.paginationInfo[0].perpage=count;
      getCloud();
    }
          }
  const handlePageClick = (event) => {
          var matched=true;
          if(props.paginationInfo[0].currentPage!=(parseInt(event.selected+1))){
             matched=false;
            }
          if(props && props.updatePaginationData){
           props.paginationInfo[0].currentPage=event.selected+1;
           if(!matched){
            getCloud();
           }
          }
        };
  function PaginatedItems({ProductData }) {
           const [listData,setListData]=useState(ProductData);
           console.log(metaData,pageStatus);
        if(metaData!=null && metaData.totalpages){
          return (
            <>
              <ProductInfo productdata={listData} />
              <td colspan="12">
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={metaData.totalpages}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
                initialPage={props.paginationInfo[0].currentPage-1}
              />
              </td>
            </>
          );
        }else{
        }
        }
  if(dataFetched){
    if(access==false){
      return (
        <>
         <AccessDenied />
        </>
      );
    } if(cloudData!=null){
      if(Object.keys(cloudData).length!=0){
        return(
          <>
          {/* <div style={{zIndex: "109"}} id="overlay_id" className="drawer-overlay"></div> */}
           <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold fs-3 mb-1">
          Cloud Instances
          {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
          <span className="svg-icon svg-icon-2 d-none">
            <i className="las la-cloud" style={{ fontSize: 20 }} />
          </span>
          {/*end::Svg Icon*/}
        </span>
        <span className="text-muted mt-1 fw-bold fs-7" />
      </h3>
      <div
        className="card-toolbar"
      >
        {/* <div className="position-relative my-1" style={{ marginRight: 20 }}>
          <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div> */}
        <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select
        onChange={e => updatePerPage(e.target.value)}
        data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={10}
        id="perPage"

      >
        <option value={10} selected={true}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
  </div>
</div>

        <Link to="/cloud/deploy">
        <a

          className="btn btn-sm btn-primary btn-active-light-primary"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
          <span className="svg-icon svg-icon-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-90 11.364 20.364)"
                fill="black"
              />
              <rect
                x="4.36396"
                y="11.364"
                width={16}
                height={2}
                rx={1}
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}Deploy New
        </a>
        </Link>
      </div>
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    {/*begin::Table container*/}

    <div className="table-responsive">
      {/*begin::Table*/}
      <table
        id="clouddata"
        className="table  table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className="fw-bold ">
            <th className="w-25px text-center d-none">#</th>
            <th className="w-100px sphide text-center d-none">Data Centers</th>
            <th className="w-40 text-center"></th>
            <th className="w-100px text-center d-none">OS/App</th>
            <th className="w-15 text-center">IPv4</th>
            <th className="w-100px text-start sphide d-none">Created/Due date</th>
            <th className="w-15 text-center">Status</th>
            <th className="w-10 text-center">Console</th>
            <th className="w-10 text-center" />
          </tr>
        </thead>
        {/*end::Table head*/}
    <PaginatedItems  ProductData={cloudData} />


    </table>
      {/*end::Table*/}
    </div>
    {/*end::Table container*/}
    {/*begin::Body*/}
  </div>
  {/*end::Tables Widget 9*/}
</div>

          </>
      )
        } else{
          return (
            <>
              <div className="col-xl-12">
                <div className="card">
                  {/*begin::Card body*/}
                  <div className="card-body p-0 mt-10">
                    {/*begin::Illustration*/}
                    <div className="text-center px-4 mt-10">
                      <span className="mw-100 mh-300px">
                      <svg
                      viewBox="0 0 64 64"
                      width="234px"
                      height="234px"
  version={1.0}
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  enableBackground="new 0 0 64 64"
  xmlSpace="preserve"
  fill="#21325B"
  stroke="#21325B"
>
  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    {" "}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#21325B"
      d="M53.802,25.104C52.003,24.4,50.05,24,48,24c-0.553,0-1-0.447-1-1 s0.447-1,1-1c1.841,0,3.607,0.297,5.278,0.812C50.992,14.287,43.246,8,34,8c-7.532,0-14.079,4.172-17.49,10.324 c1.515,0.393,2.89,1.133,4.037,2.128c0,0,1.182,1.028,0.433,1.777s-1.847-0.363-1.847-0.363c-1.142-0.957-2.556-1.593-4.106-1.792 C14.689,20.03,14.349,20,14,20c-4.418,0-8,3.582-8,8c0,1.1,0.228,2.145,0.632,3.098C8.286,30.404,10.092,30,12,30 c0.553,0,1,0.447,1,1s-0.447,1-1,1c-1.585,0-3.091,0.318-4.474,0.879c-0.123,0.05-0.243,0.105-0.364,0.158 c-0.389,0.174-0.772,0.356-1.138,0.568C2.426,35.675,0,39.551,0,44c0,6.627,5.373,12,12,12h36c8.837,0,16-7.164,16-16 C64,33.214,59.767,27.43,53.802,25.104z M23,45c-0.553,0-1-0.447-1-1c0-2.762-1.119-5.262-2.929-7.071c0,0-0.993-0.913-0.243-1.647 s1.657,0.233,1.657,0.233C22.656,37.687,24,40.687,24,44C24,44.553,23.553,45,23,45z M57,41c-0.553,0-1-0.447-1-1 c0-4.418-3.582-8-8-8c-0.553,0-1-0.447-1-1s0.447-1,1-1c5.522,0,10,4.477,10,10C58,40.553,57.553,41,57,41z"
    />{" "}
  </g>
</svg>

                      </span>
                    </div>
                    {/*end::Illustration*/}
                    {/*begin::Wrapper*/}
                    <div className="card-px text-center py-5 mb-20">
                      {/*begin::Title*/}
                      <h1 className="fs-2x fw-bolder mb-5">Cloud Server</h1>
                      <h2 className="fs-1x fw-bolder mb-5 text-muted">
                      Deploy your first server
                      </h2>
                      {/*end::Title*/}
                      {/*begin::Description*/}
                      <p className="text-gray-400 fs-4 fw-bold mb-10">
                      Deploy cloud servers with ease and take your cloud journey to the next level.<br/> Start enjoying the benefits from Utho's robust and scalable server solutions.
                      </p>
                      {/*end::Description*/}
                      {/*begin::Action*/}
                      <Link to="/cloud/deploy">
                      <a
                        href="#"
                        className="btn btn-primary mb-10"
                      >
                        Create Cloud Server
                      </a>
                      </Link>
                      {/*end::Action*/}
                    </div>
                    {/*end::Wrapper*/}
                  </div>
                  {/*end::Card body*/}
                </div>
              </div>
            </>
          );
        }
      }else{
      }
    }else{
     return (
      <>
         <TopBarHeader/>
        {/* <div class="loading-spinner2"></div> */}
        {/* <LoaderGif /> */}
      </>
    );
    }

}

const mapStateToProps = (state) => ({
  paginationInfo: state.cloudList.cloudPaginationInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updatePaginationData: (data) => dispatch(UpdateCloudPagination(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cloud);
