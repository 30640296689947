import React, { useEffect, useRef, useState } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import swal from "sweetalert";  
import UseDevPost from "../../../../../../CustomHooks/useDevPost";
import UsePost from "../../../../../../CustomHooks/usePost";
const Access = (props) => {

  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[5]; 
 
 
  function selectAccessControl() {
    var accessControl = document.querySelector("#access-control");
    var accessControlDesc = document.querySelector("#access-control-desc");
    if (accessControl && accessControlDesc) {
      if (accessControl.value == "private") {
        accessControlDesc.innerHTML =
          "Private: <b>Only you</b> can list, create, overwrite, and delete Objects in this Bucket.";
      } else if (accessControl.value == "public") {
        accessControlDesc.innerHTML =
          "Public <b>Read</b>: <b>Everyone</b> can list Objects in this Bucket, but only you can create, overwrite, and delete them.";
      } else if (accessControl.value == "download") {
        accessControlDesc.innerHTML =
          "Public <b>Read/Write</b>: <b>Everyone</b> can list, create, overwrite, and delete Objects in this Bucket. <b>This is not recommended</b>.";
      }
      else if (accessControl.value == "upload") {
        accessControlDesc.innerHTML =
          "Public <b>Read/Write</b>: <b>Everyone</b> can list, create, overwrite, and delete Objects in this Bucket. <b>This is not recommended</b>.";
      }
    }
  }

  const updateObjectStorage=async()=>{ 
    var submitBtn =document.querySelector("#update-btn");     
    var accessControl = document.querySelector("#access-control");
    if(submitBtn && accessControl){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
    const dt=toast.loading("Please wait..."); 
    var accessValue=accessControl.value;
    const responseData=await UsePost('post','objectstorage/innoida/bucket/'+id+'/policy/'+accessValue,'update-storage');
    if(responseData){   
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      
      if(props){
        props.updateObject();
      }
    }
  }
      }
    }

    const [currentObject, setCurrentObject]= useState(null);
    useEffect(()=>{
      if(props){
      setCurrentObject(props.data); 
      }
    },[props])

    useEffect(()=>{
      selectAccessControl();
    },[currentObject])
    const handleSubmit = (e) => {
      e.preventDefault();
      // Perform your form submission logic here
      updateObjectStorage();
    };

    const accessArr={
                     private: "Private",  
                     download: "Public",
                     upload: "Upload"
                        } 
    const [accessSelect, setAccessSelect]= useState(null);
    useEffect(()=>{
      setAccessSelect(accessArr);
      // console.log(accessSelect);
    },[2])
    
    return (
        
        <>
            <div
          className="tab-pane fade "
          id="connection-details"
          role="tabpanel"
        >
          <form id="update-storage" onSubmit={handleSubmit}>
          <div className="card mb-5 mb-xl-8">
            {/*begin::Card header*/}
            <div className="card-header border-0">
              <div className="card-title">
                <h3 className="fw-bolder m-0">Manage Access Control</h3>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body pt-0">
              {/*begin::Notice*/}
              <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                {/*begin::Icon*/}
                {/*begin::Svg Icon | path: icons/duotune/art/art006.svg*/}
                <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z"
                      fill="black"
                    />
                    <path
                      d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                {/*end::Icon*/}
                {/*begin::Wrapper*/}
                <div className="d-flex flex-stack flex-grow-1">
                  {/*begin::Content*/}
                  <div className="fw-bold">
                    <div className="fs-6 text-gray-700">
                      Utilize access control policies and mechanisms to manage
                      and enforce permissions for user access.
                    </div>
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*end::Notice*/}
              {/*begin::Input group*/}
              <div className="mb-10">
                {/*begin::Label*/}
                <label className="form-label"> {typeof accessSelect} Access Control</label>
                {/*end::Label*/}
                {/*begin::Input*/}
                <select
                  class="form-select"
                  onChange={selectAccessControl}
                  id="access-control"
                  name="policy" 
                  aria-label="Select Permission"
                >
                   
                  {accessSelect!=null && Object.entries(accessSelect).map(([key,value],index)=>(<>
                     {currentObject!=null?(<>
                     {currentObject.access==key?(<>
                      <option value={key} selected>{value}</option>
                     </>):(<>
                      <option value={key}>{value}</option>
                     </>)}
                      
                     </>):('')}
                  </>))}
                  
                </select>
                {/*end::Input*/}
                {/*begin::Description*/}
                <div className="text-muted fs-6 mt-5" id="access-control-desc">
                  Private: Only you can list, create, overwrite, and delete
                  Objects in this Bucket.
                </div>
                {/*end::Description*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="d-none">
                {/*begin::Label*/}
                <label className="form-label mb-5">
                  Cross-Origin Resource Sharing
                </label>
                {/*end::Label*/}
                <div className="form-check form-switch form-check-custom form-check-solid mb-5">
                  
                   <input
                    className="form-control"
                    type="hidden" 
                    id="cors-field" 
                    name="cors"
                    
                  />
                  {currentObject!=null && currentObject.cors=="1"?(<>
                    <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue=""
                    id="cors-input"
                    defaultChecked="true"
                  />
                  </>):(<>
                    <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue=""
                    id="cors-input"  
                  />
                  </>)}
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchChecked"
                  >
                    CORS
                  </label>
                </div>

                {/*begin::Description*/}
                <div className="text-muted fs-6 ">
                  Configure CORS settings with detailed rules to define which
                  origins, methods, and headers are permitted for cross-origin
                  requests, enabling precise control over resource sharing.
                </div>
                {/*end::Description*/}
              </div>
              {/*end::Input group*/}
            </div>

            {/*end::Card body*/}
            {/*begin::Card footer*/}
            <div className="card-footer border-0 d-flex justify-content-center pt-0">
              <a className="btn btn-primary" id="update-btn"  onClick={updateObjectStorage}>Save Changes</a>
            </div>
            {/*end::Card footer*/}
          </div>
          </form>
        </div>
        </>
    );
}

export default Access;
