import localStorage from 'local-storage';
import React, { useRef } from 'react'; 
import { useEffect } from 'react';
import UseDirectPostData from '../../../../CustomHooks/useDirectPostData';
import CallAppinfo from '../../../../CustomHooks/CallAppinfo';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';  
import Cookies from 'js-cookie';

export default function ActivateUser(){
    const navigate = useNavigate();
    const callOnce=useRef(true);
   //console.log("active user call");
    var query = window.location.href; 
    var vars = query.split("/"); 
    // //console.log(vars);
    var id=vars[4];
    const activateUser=async()=>{
        const res=await UseDirectPostData('POST','user/activate/'+id,{});
        //console.log(res);
        if(res){
        if(res.status=="success"){
            localStorage.clear();
            localStorage.set("Auth",res.token); 
            Cookies.set("microhost_api_auth", res.token, { expires:30});
            // const now = new Date();
            // const expires = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days
            // document.cookie = "microhost_api_auth="+res.token+"; expires=" + expires.toUTCString() + "; path=/"; 
           
           if(callOnce.current){
            callOnce.current=false;
            const apiUrl = 'https://api.utho.com/v2/appinfo';
            const headers = { 
              'Content-Type': 'application/json', // Adjust as needed
            };
    
            // Define the Fetch options
            const fetchOptions = {
              method: 'GET',
              headers: headers, // Change to 'POST', 'PUT', 'DELETE', etc. if needed 
            };
    
       const AppData= fetch(apiUrl, fetchOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json(); // Parse the response body as JSON
        })
        .then(data => { 
          if (data) {  
            localStorage.set("appInfo",data);
          } 
           
        })
        .catch(error => {
          console.error('API request failed:', error); 
        });
           
            // const data=await CallAppinfo();
            // const location_url = window.location.origin;
            // window.location.href = location_url; 
            if(AppData){
                setTimeout(()=>{
                  // navigate('/Welcome');
                  const location_url = window.location.origin;
        window.location.href = location_url;
                },1000)
            }

        }
            
        }else{
              
            swal({
                  title: "Authorization Failed",
                  text: "Your are not an authorized user, Contact to support!",
                  icon: "warning",
                  button: "OK",
                });
                localStorage.clear();
                navigate('/login');
        }
    }
}
    
     useEffect(()=>{
       activateUser();
     },[])
    //console.log(id);
    return (
         <></>
    );
};

 
