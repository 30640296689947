import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom/dist"; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";    
import UseDevPost from "../../../../../../CustomHooks/useDevPost";
import UseDevApi from "../../../../../../CustomHooks/useDevApi";
const Ssl = (props) => {

  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[5]; 

  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }
  function formatMessageTime(dateString) {
    const date = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - date.getTime());
    const minutes = Math.floor(timeDiff / (1000 * 60));
    const hours = Math.floor(minutes / 60);
  
    if (minutes <= 1) {
      return 'Just now';
    } else if (minutes < 30) {
      return `${minutes} mins ago`;
    } else if (minutes < 90) {
      return 'An hour ago';
    } else if (hours < 24) {
      const formattedTime = formatTime(date);
      return `Today at ${formattedTime}`;
    } else if (hours < 48) {
      const formattedTime = formatTime(date);
      return `Yesterday at ${formattedTime}`;
    } else {
      const formattedDate = formatDate(date);
      const formattedTime = formatTime(date);
      return `${formattedDate} at ${formattedTime}`;
    }
  }
  function formatTime(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}${period}`;
  }

  function formatDate(date) {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }
  
  const openDrawer=()=>{
    var drawerElement = document.querySelector("#ssl-modal");
        drawerElement.classList.toggle("drawer-on");
        drawerElement.style.width='500px';
  }

  const updateSslCertificate=async()=>{ 
    var submitBtn=document.querySelector("#ssl-btn"); 
   
    if(submitBtn){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
    const dt=toast.loading("Please wait...");  
    const responseData=await UseDevPost('put','object/'+id+'/update','ssl_add');
    if(responseData){   
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      openDrawer();
      if(props){
      props.updateObject();
      }
      // navigate("/ssl");
    }
  } 
      }
    }
   
    const [sslInfo, setSslInfo]= useState(null);
    const [sslData, setSslData]= useState(null);
    const getAllSsl=async()=>{
     
         const datas=await UseDevApi('get','ssl','');
        //  console.log(datas);
         if(datas.status=="error"){   
              setSslData([]);
         }else{ 
          setSslData(datas.data);  
          toast.dismiss(); 
         } 
    }

    const getSsl=async()=>{    
      if(props){
        if(props.data){
          var sslid=props.data.ssl_certificate;
          if(sslid==0){ 
            setSslInfo(null); 
            loaderFunc(); 
          }else{
            const data=await UseDevApi('get','ssl/'+sslid,'');
          
            if(data.status=="error"){    
              setSslInfo(null); 
              loaderFunc(); 
              return;
            }else{
              setSslInfo(data.data);  
              toast.dismiss(); 
            }
          }
         
        }
      }
         
    }

    useEffect(()=>{ 
      getSsl();
      getAllSsl();
    },[props])

if(props && props.data){
    return (
        <>
          <div className="tab-pane fade" id="sslCertificate-tab" role="tabpanel">
          <div className="card">
            <div className="card-header mt-5">
              <h2>SSL/TLS Certificate</h2>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <div className="w-100" id="own-certificate">
                      {/*begin::Heading*/}
                      <div className="pb-10 pb-lg-12">
                      <div className="notice d-flex bg-light-primary rounded border-primary border p-6">
            {" "}
            {/*begin::Icon*/}{" "}
            {/*begin::Svg Icon | path: icons/duotune/finance/fin001.svg*/}{" "}
            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                {" "}
                <path
                  d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z"
                  fill="black"
                />{" "}
                <path
                  opacity="0.3"
                  d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z"
                  fill="black"
                />{" "}
              </svg>{" "}
            </span>{" "}
            {/*end::Svg Icon*/} {/*end::Icon*/}
            
             
             {/*begin::Wrapper*/}{" "}
            <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
              {" "}
              {/*begin::Content*/}{" "}
              <div className="mb-3 mb-md-0 fw-bold">
                {" "}
                <h4 className="text-gray-900 fw-bolder">SSL Certificate</h4>{" "}
                <div className="fs-6 text-gray-700 pe-7">
                Object Storage buckets can be seamlessly served with a default TLS certificate for secure communication. Additionally, an alternative feature allows users to upload a custom certificate to be utilized during the TLS segment of the HTTPS request. By providing your own certificate, you have control over the encryption process and can ensure a personalized level of security for your data transmissions.
                </div>{" "}
              </div>{" "}
              {/*end::Content*/} {/*begin::Action*/}{" "}
              <a   
              onClick={openDrawer} 
                 className="btn btn-primary px-6 align-self-center text-nowrap"
              >
                Attach SSL Certificate
              </a>{" "}
              {/*end::Action*/}{" "}
            </div>{" "}
            {/*end::Wrapper*/}{" "}

            
          </div>
                      </div>
                      {/*end::Heading*/} 

                          {/*begin::Table container*/}
    <div className="table-responsive">
      {/*begin::Table*/}
      <table
        id="ssl_data"
        className="table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className="fw-bold ">
            <th className="w-50px text-center">#</th>
            <th className="w-150px">Name</th>
            <th className="w-150px">Expired At</th> 
            <th className="w-150px">Status</th>
            <th className="w-150px">Last update</th>  
          </tr>
        </thead>
        {/*end::Table head*/}
        {/*begin::Table body*/}
        <tbody id="list-ssl"> 
        {sslInfo!=null && Object.keys(sslInfo).length!=0?(sslInfo && sslInfo.map((value, index) => (
          <tr>
            {/*begisetSslDatan::ID*/}
            <td className="text-center">
              <span className="fw-bold text-center">{++index}</span>
            </td>
            {/*end::ID*/}
            {/*begin::User=*/}
            <td className="">
              {/*begin::User details*/}
              <div className="d-flex flex-column"> 
                <a   className="text-gray-800 fw-bolder text-hover-primary mb-1"   >
                  {value.name}
                </a> 
              </div>
              {/*begin::User details*/}
            </td>
            {/*end::User=*/}
            <td className="text-left">
              {/*begin::User details*/}
              <div className="d-flex flex-column">
                <span className="text-muted fw-bold">{formatMessageTime(value.expired)}</span> 
              </div>
              {/*begin::User details*/}
            </td> 
            <td> 
              {value.status=="active"?(<>
                <div className="badge badge-success">Active</div>
              </>):(<>
                <div className="badge badge-warning">Inactive</div>
              </>)} 
            </td>   
            <td className="text-end">
              
              <div className="d-flex flex-column w-100 me-2">
                
                <div className="d-flex flex-stack mb-2">
                  
                  <span className="text-muted me-2 fs-7 fw-bold">
                    {formatMessageTime(value.updatedAt)}
                  </span>
                </div>
              </div>
            </td>
           
          </tr> 
          ))):( <>
           <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
            </>)}
     

</tbody>
        {/*end::Table body*/}
      </table>
      {/*end::Table*/}
    </div>
    {/*end::Table container*/}


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
  id="ssl-modal"
  className="bg-body shadow drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-name="chat"
  data-kt-drawer-activate="true" 
  data-kt-drawer-direction="end" 
  data-kt-drawer-close="#close_ssh"
  data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}" 
  style={{maxWidth: '70%',important:true}}
  
>
  {/*begin::Messenger*/}
  <div className="card rounded-0 w-100 border-0" id="kt_drawer_chat_messenger">
    {/*begin::Card header*/}
    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div className="d-flex justify-content-center flex-column me-3">
          <a
            
            className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
          >
            Attach SSL Certificate
          </a>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Close*/}
        <div
        onClick={openDrawer}
          className="btn btn-sm btn-icon btn-active-light-primary"
          id="close_ssh"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {/*end::Card header*/}
    {/*begin::Card body*/}
    <div className="" id="">
      {/*begin::Form*/}
      <form id="ssl_add"  className="form">
        {/*begin::Modal body*/}
        <div className="modal-body ">
          {/*begin::Scroll*/}
          <div
            className="scroll-y me-n7 pe-7"
            id="kt_modal_create_api_key_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
            data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
            data-kt-scroll-offset="300px"
          >
            {/*begin::Input group*/}
            <div className="mb-5 fv-row"> 
              {/*begin::Input*/} 
              {sslData!=null?(<>
              {sslData.length==0?(<>
              <p>You don't have SSL Certificate in your account, Please add SSL in your account
              </p></>):(<>
                <select
                  className="form-select" 
                  name="ssl_certificate" 
                > <option value={0}>Select SSL Certificate</option>
                  {sslData!=null?(sslData && sslData.map((value,index)=>(
                  <option value={value.id}>{value.name}</option>
                  ))):('')} 
                </select> 
              </>)}
              </>):('')}
                
              {/*end::Input*/}
            </div>
            {/*end::Input group*/} 
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Modal body*/}
        {/*begin::Modal footer*/}
        <div className="modal-footer ">
          {/*begin::Button*/}
          {sslData!=null?(<>
              {sslData.length==0?(<>
              <Link to="/ssl">
                <a  
            className="btn btn-primary w-100"
            id="ssl-btn"
          >
            <span className="indicator-label">Add New SSL Certificate</span>
          </a>
          </Link>
              </>):(<>
                <a 
          onClick={updateSslCertificate}
            className="btn btn-primary w-100"
            id="ssl-btn"
          >
            <span className="indicator-label">Add SSL Certificate</span>
          </a>
              </>)}
              </>):('')}
          {/*end::Button*/}
        </div>
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
    </div>
    {/*end::Card body*/}
  </div>
  {/*end::Messenger*/}
</div> 
        </div>  
        </>
    );
          }
}

export default Ssl;
