import { CLOUD_PAGINATION } from "../../../../Redux/Services/constants"

const initialState={ 
     cloudPaginationInfo:[{currentPage:1,perpage:10}]
}

export default function CloudListReducer(state=initialState,action){


   switch (action.type) { 
       case CLOUD_PAGINATION:
           ////console.log("Reducer",action.data);
           return(
            {cloudPaginationInfo:action.data}
           )             
       default:
           return state 
   }
}

