import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom/dist";
import UsePost from "../../../../CustomHooks/usePost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseApi from "../../../../CustomHooks/useApi";
import { connect } from "react-redux";
import "./custom.css";
import { AddDatabaseDeploy, AddDatabaseNodeCount } from "../../../../Redux/Services/Action/actions";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import PricingContainer from "../pricing/PricingContainer";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const DeployDatabaseCluster = (props) => {
  const [nodeCount, setNodeCount]=useState([]); 
  const forceUpdate = useForceUpdate();
  const [adcdata, setAdcdata] = useState([]);
  const [ddcdata, setDdcdata] = useState([]); 
  const dataFetchedRef2 = useRef(); 
  const [planData, setPlanData] = useState(null);
  const [userData, setUserData] = useState(null); 
  const user_data = useRef(null);
  const dczoneStatus = useRef(true);

  const navigate=useNavigate();
  const navRedirect=()=>{
    setTimeout(() => {
      navigate('/database-cluster');
     }, 2000);
      
  }

  const getDczone = async () => {
    const data = await UseApi("get", "dczones", "");
    user_data.current = await UseApi("get", "account/info", "");
    const PlanData = await UseApi("get", "cloud/getdeploy", "");

    if(PlanData){
        setPlanData(PlanData.plans);
    }
    setUserData(user_data.current.user);
    if (Object.keys(data).length === 0) {
      setAdcdata([]);
      setDdcdata([]);
    } else {
      if (dczoneStatus.current) {
        data.dczones.map(async (value, index) => {
          if (value.status !== "active") {
            fetch("https://api.ipify.org?format=json")
              .then((response) => response.json())
              .then((data) => {
                var server_ip = data.ip;
                if (server_ip) {
                  if (
                    server_ip !== "103.209.144.51" ||
                    server_ip !== "103.209.144.66" ||
                    server_ip !== "103.209.145.208" ||
                    server_ip !== "103.209.144.81" ||
                    server_ip !== "103.209.144.82" ||
                    server_ip !== "103.209.144.83" ||
                    server_ip !== "103.127.30.91" ||
                    server_ip !== "103.209.144.60" ||
                    server_ip !== "103.127.30.239" ||
                    server_ip !== "103.127.28.52"
                  ) {
                    ddcdata.push(value);
                  }
                  forceUpdate();
                }
              })
              .catch((error) => console.error(error));
          } else {
            adcdata.push(value);
            forceUpdate();
          }
        });
        dczoneStatus.current = false;
      }
      setTimeout(()=>{ 
        var clickTo=document.querySelector("#dcslugl_innoida");
        if(clickTo){
        clickTo.click();
        }   
      },1000)
    }
  };
 
  useEffect(() => {
    if (dataFetchedRef2.current) return;
    dataFetchedRef2.current = true;
    getDczone(); 
  }, []);

  const selectDc = async (slug) => { 
    var dczone_slug=document.querySelector("#dczone_slug");
    if(dczone_slug){
      dczone_slug.value=slug;
    }
  if(props){ 
    if(props.deployInfo[0]){ 
      if(props.deployInfo[0].dcslug!=slug){
      const updatedObject = { ...props.deployInfo[0], dcslug: slug };
       const newArray = [updatedObject]; 
       props.updateDeployInfo(newArray);
    //   console.log(newArray);
      }
   } 
  }
    var dcSelect = document.querySelectorAll([".tickMark"]);
    var currentDc = document.querySelector("#tickMark-" + slug);
    if (dcSelect) {
      dcSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    }
    if (currentDc) {
      currentDc.classList.remove("d-none");
    }
  };
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }; 
  // end- select plans
  function ChangeScreen() {
    useEffect(() => {
      function handleResize() {
        const sideCheckout = document.querySelector("#sideCheckout");
        if (sideCheckout) {
          if (window.innerWidth > 990) {
            sideCheckout.classList.add("mhc_checkout");
          } else {
            sideCheckout.classList.remove("mhc_checkout");
          }
        }
      }
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return null;
  }
 
  const [allowIp, setAllowIp]=useState(null);
  function getAllowIp(){
          if(props){
            if(props.nodeCountData){
              setAllowIp(props.nodeCountData);
          } 
          }
  }

  useEffect(()=>{
    getAllowIp();
    updateDeploy();
  },[props])  
  const addIpField=async()=>{  
    if(props){ 
     if(props.nodeCountData){ 
        const newArray = [...props.nodeCountData, makeid(8)]; 
        props.updateNodeCountData(newArray);
     //   console.log(newArray);
    } 
   } 
  }
  const deleteIpField=async(ip_id)=>{ 
    if(props){ 
     if(props.nodeCountData){
      const newArray = props.nodeCountData; 
     // console.log(newArray);
      for(var i=0;i<newArray.length;i++){   
              if(newArray[i]==ip_id){
               const index = newArray.indexOf(newArray[i]);
               if (index > -1) { // only splice array when item is found
                 newArray.splice(index, 1); // 2nd parameter means remove one item only
               } 
              }  
            }
  //    console.log(newArray);
      props.updateNodeCountData([]);
      props.updateNodeCountData(newArray);

  } 
 } 
  } 

  const [databaseData, setDatabaseData]= useState(null);
  const getDatabases=async()=>{  
    
      const data={"databases": [
                              {
                                  "id": "1",
                                  "appid": "1",
                                  "type": "SQL",
                                  "name": "MySQL",
                                  "slug": "mariadb",
                                  "versions": "mariadb-version-15.1",
                                  "status": "active", 
                              }
                            ]
                          }
        if(data){
          setDatabaseData(data.databases);
        }
  }

  useEffect(()=>{
    getDatabases();
  },[]);


  const databaseVersion = (dbImage) => { 
    var pselect = document.querySelectorAll([".deploy_dbVersioncontainer"]);
    var pheight = document.querySelectorAll([".versionDb"]);
    pselect.forEach((clist) => {
      clist.classList.remove("d-block");
    });
    //var cl=event.currentTarget.querySelector('.versionOs');

    pheight.forEach((clist) => {
      clist.style.height = "auto";
      clist.classList.remove("active");
    }); 

     const database_version_list = document.querySelector(
      "#database_version_list_" + dbImage
    );
    database_version_list.classList.add("d-block");
  };

  function database_Name(str) {
    // Split the string into an array using the delimiter "-"
    var arr = str.split("-");
    
    // Return the element at the 0th index
    return arr[0].toLowerCase();
  }
  function database_Version(str) {
    // Split the string into an array using the delimiter "-"
    var arr = str.split("-");
    
    // Return the element at the 0th index
    return arr[2].toLowerCase();
  }

  const selectDb = (slug, version) => {
    if(props){ 
      if(props.deployInfo[0]){ 
        if(props.deployInfo[0].db_version!=version){
        const updatedObject = { ...props.deployInfo[0], db_version: version };
         const newArray = [updatedObject]; 
         props.updateDeployInfo(newArray); 
     } 
    }
    }
      
    var database_version=document.querySelector("#database_Version");
    if(database_version){
      database_version.value=database_Version(version); 
    }
    var database_engine=document.querySelector("#database_engine");
    if(database_engine){
      database_engine.value=database_Name(version); 
    }

    var osSelect = document.querySelectorAll([".tickMarkDb"]);
    var currentOs = document.querySelector("#tickMarkDb-" + slug);
    if (osSelect) {
      osSelect.forEach((clist) => {
        clist.classList.add("d-none");
      });
    } 
    if (currentOs) { 
      currentOs.classList.remove("d-none");
    } 

      const os_cost = document.querySelector("#selectedDatabaseVersion");
      if(os_cost){
      os_cost.value = version; 
      }
      const current_db_version = document.querySelector("#database_version_list_" + slug);
      if(current_db_version){ 
        current_db_version.classList.remove("d-block");
      }
      const all_radio = document.getElementsByClassName("database_radio");
      if(all_radio){
      for (var i = 0; i < all_radio.length; i++) {
        all_radio[i].checked = false;
      }
    }
      const all_label = document.getElementsByClassName("versionDb");
      if(all_label){
      for (var i = 0; i < all_label.length; i++) {
        //all_label[i].classList.remove('active');
        all_label[i].classList.remove("shadowBox");
      }
    }
      var pselect = document.querySelectorAll([".deploy_dbversioncontainer"]);
      if(pselect){
      pselect.forEach((clist) => {
        clist.classList.remove("d-block");
      });
    }
      const all_versionText = document.getElementsByClassName(
        "dbversionselectmultiple"
      );
      if(all_versionText){
      for (var i = 0; i < all_versionText.length; i++) {
        all_versionText[i].innerHTML = "Select Version";
      }
    }

       
      const current_radio = document.querySelector("#select_" + slug);
      if(current_radio){
      current_radio.checked = true;
      }
      const current_label = document.querySelector(
        "#versionDb_" + slug
      );
      if(current_label){
      current_label.classList.add("shadowBox");
      }
      //current_label.classList.add('active');
      const current_select_version = document.querySelector(
        "#dbversion_" + slug
      );
      if(current_select_version){
      current_select_version.innerHTML = version;
      }
  };
 
  

  const addDatabase=async()=>{ 
    var submitBtn=document.querySelector("#database-btn"); 
   
    if(submitBtn){
      var preContent=submitBtn.innerHTML;
      submitBtn.classList.add("disabled");
      submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
    const dt=toast.loading("Please wait..."); 
    const responseData=await UsePost('post','databases','databaseDeploy');
    if(responseData){   
      submitBtn.classList.remove("disabled");
      submitBtn.innerHTML=preContent;
      if(responseData.status==='error'){
        toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });
      }else if(responseData.status==='success'){
        toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      });  
      navRedirect();
    }
  }

      }
    }
  function databaseName(str) {
    // Split the string into an array using the delimiter "-"
    if(str!="" && str!=undefined && str!=null){
    var arr = str.split("-");
    
    // Return the element at the 0th index
    return arr[0].toLowerCase();
    }
  }


function updateDeploy(){
  if(props && props.deployInfo[0]){
    if(props.deployInfo[0].db_version!=""){
  var database_version=document.querySelector("#database_version");
  var database_engine=document.querySelector("#database_engine");
  if(database_version && database_engine){
    database_version.value=database_Version(props.deployInfo[0].db_version); 
    database_engine.value=database_Name(props.deployInfo[0].db_version); 
      var dbslug=databaseName(props.deployInfo[0].db_version);
      selectDb(dbslug, props.deployInfo[0].db_version);
    }    
  }
//   if(props.deployInfo[0].price!=""){
//   var plan_value=document.querySelector("#plan_value");
//   if(plan_value){
//     plan_value.value=props.deployInfo[0].plan_id;
//     show_plan_price(props.deployInfo[0].plan_id, props.deployInfo[0].price)
//   }
// }
// if(props.deployInfo[0].billingcycle!=""){
//   var billing_info=document.querySelector("#billing_info");
//   if(billing_info){
//     billing_info.value=props.deployInfo[0].billingcycle;
//   }
// }
if(props.deployInfo[0].nodes_count!=""){
  var db_node_count=document.querySelector("#db_node_count");
  if(db_node_count){
    db_node_count.value=props.deployInfo[0].nodes_count;
  }
}
if(props.deployInfo[0].cluster_label!=""){
  var db_label=document.querySelector("#db_label");
  if(db_label){
    db_label.value=props.deployInfo[0].cluster_label;
  } 
}
if(props.deployInfo[0].dcslug!=""){
  var dczone_slug=document.querySelector("#dczone_slug");
  if(dczone_slug){
    dczone_slug.value=props.deployInfo[0].dcslug;
    selectDc(props.deployInfo[0].dcslug);
  } 
}
}
calcFinalCost();
}

useEffect(()=>{ 
  if(props){
    updateDeploy();
  // console.log(props.deployInfo[0]);
  }
},[props])

function checkInput(val){
  var db_node_count=document.querySelector("#db_node_count");
  if(db_node_count){
  if(val<1){
    db_node_count.value=1;  
  }
  }
  calcFinalCost();
}

function calcFinalCost(){
  var current_plan_price=document.querySelector("#plan_cost");
  var db_node_count=document.querySelector("#db_node_count");
  var finalCost=0;
  if(current_plan_price && db_node_count){ 
    // console.log(parseInt(current_plan_price.value)+" => "+parseInt(db_node_count.value));
       finalCost=parseInt(current_plan_price.value)*parseInt(db_node_count.value);

  }
 
  var currency_final=document.querySelector("#currency_final");
  var total_cost=document.querySelector("#total_cost");
  var billing_final=document.querySelector("#billing_final");

  if(currency_final && total_cost && billing_final){
    if(user_data && user_data.current && user_data.current.user){
      currency_final.innerHTML=user_data.current.user.currencyprefix;
    }
    total_cost.innerHTML=finalCost;
    const billing_info = document.querySelector("#billing_info");
    if(billing_info){
      if(billing_info.value=="12month"){
         billing_final.innerHTML=" /year";
      }else  if(billing_info.value=="monthly"){
         billing_final.innerHTML=" /mon";
      }else  if(billing_info.value=="hourly"){
              billing_final.innerHTML=" /mon";
      }
    }
  }
}


function updatedPlanInfo(plan=[]){  
  // console.log("info ") ;
  //  console.log(props.deployInfo[0]);
  const billing_info = document.querySelector("#billing_info");
  const plan_value = document.querySelector("#size_value"); 
  const plan_cost = document.querySelector("#plan_cost"); 

  if (billing_info && plan_value && plan_cost) { 
    billing_info.value = plan.billing;
    plan_value.value = plan.planid; 
    plan_cost.value=plan.payable_price;

  }
  if (props.deployInfo) { 

    props.deployInfo[0].plan_id = plan.planid;
    props.deployInfo[0].billingcycle = plan.billing; 
    props.deployInfo[0].price=plan.payable_price;
  }
  calcFinalCost();
 }
  return (
    <>
    {props && adcdata.length>0?(<>
      <ChangeScreen /> 
          <form id="databaseDeploy">
            {/*begin::Tables Widget 9*/}
            <div className=" col-xl-12 card mb-5">
              {/*begin::Header*/}
              <div className="card-header border-3 mb-5 pt-2">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800 mb-1">
                    Select DC Location
                  </span>
                  <span className="text-muted mt-1 fw-bold fs-7">
                    Data Centeres
                  </span>
                </h3>
              </div>
              {/*end::Header*/}
              {/* Begin body */}
              <div className="container pb-5">
           {/*begin::Row*/}
           <div
                  id="dc-location"
                  className="row g-7"
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >
                  <input
                      type="hidden"
                      name="dcslug"
                      id="dczone_slug"
                      className="form-control text-center" 
                      defaultValue=""
                    />
                  {adcdata.length > 0
                    ? adcdata &&
                      adcdata.map((value, index) => (
                        <>
                          <div className="col-md-4 col-lg-4 col-sm-6 col-xxl-4 dc_sibling">
                            <input
                              className="form-check-input deploy_checkbox "
                              type="radio"
                              name="dcslug_zone"
                              defaultValue={value.slug}
                              id={"dcslug" + value.slug + ""}
                            />

                            <label
                              onClick={() => selectDc(value.slug)}
                              style={{ WebkitJustifyContent: "unset" }}
                              id={"dcslugl_" + value.slug + ""}
                              htmlFor={"dcslug" + value.slug + ""}
                              className="position-relative uptrans dcsluglable border-1 border-gray-300 border px-1 py-1"
                            >
                              <div className=" child">
                                <div className="p-3 ">
                                  <div className="row">
                                    <div className="col-4">
                                      <img
                                        src={
                                          "/assets/media/flags/svg/" +
                                          value.cc +
                                          ".svg"
                                        }
                                        className="w-50px "
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-8">
                                      <p className="no-margin text-dark fw-bolder text-hover-primary ">
                                        {value.city}
                                        <span className="text-muted d-block fw-bold ">
                                          {value.country}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <span className="d-none child position-absolute top-0 start-100 translate-middle  badge badge-circle badge-primary">
                                5
                              </span>
                              {/*begin::Ribbon*/}
                              <div
                                className="d-none tickMark ribbon ribbon-triangle ribbon-top-start border-primary"
                                id={"tickMark-" + value.slug}
                              >
                                {/*begin::Ribbon icon*/}
                                <div className="ribbon-icon mt-n5">
                                  <i className="bi bi-check2 fs-2 text-white" />
                                </div>
                                {/*end::Ribbon icon*/}
                              </div>
                              {/*end::Ribbon*/}
                            </label>
                          </div>
                        </>
                      ))
                    : (<><div className="small-spinner"></div></>)}
                  {ddcdata.length > 0
                    ? !ddcdata &&
                      ddcdata.map((value, index) => (
                        <>
                          <div className="col-md-4 col-lg-4 col-sm-6 col-xxl-4 dc_sibling">
                            <input
                              className="form-check-input deploy_checkbox deploylocationsoldout"
                              type="radio"
                              name="dcslug_zone"
                              onClick={(e) => (e.target.checked = false)}
                              defaultValue={value.slug}
                              id={"dcslug" + value.slug + ""}
                            />
                            <label
                              onClick={() => selectDc(value.slug)}
                              style={{ WebkitJustifyContent: "unset" }}
                              id={"dcslugl_" + value.slug + ""}
                              htmlFor={"dcslug" + value.slug + ""}
                              className="uptrans dcsluglable border-1 border-gray-300 border px-1 py-1"
                            >
                              <div className="child">
                                <div className="p-3 ">
                                  <div className="row">
                                    <div className="col-4">
                                      <img
                                        src={
                                          "/assets/media/flags/svg/" +
                                          value.cc +
                                          ".svg"
                                        }
                                        className="w-50px "
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-8">
                                      <p className="no-margin text-dark fw-bolder text-hover-primary ">
                                        {value.city}
                                        <span className="text-muted d-block fw-bold ">
                                          {value.country}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
 
                            </label>
                          </div>
                        </>
                      ))
                    : (<></>)}
                </div>
                {/*end::Row*/}
              </div>
              {/* End body */}
              </div>
              {/*begin::Col*/}
              <div className="col-xl-12 card mb-5">
                {/*begin::List widget 10*/}

                <div className="card-header border-3 mb-5 pt-2">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Select Database Cluster
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                      MySQL, MongoDB, Postgre
                    </span>
                  </h3>
                </div>
                <div className="container pb-5">
                <div
                  className="row g-7 "
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >
             <input
                    type="hidden"
                    name="cluster_version"
                    id="database_version"
                    defaultValue=""
                  />
                   <input
                    type="hidden"
                    name="cluster_engine"
                    id="database_engine"
                    defaultValue=""
                  />
              {/* <DatabaseDropdown /> */}
              {databaseData!=null?(<>
                {databaseData.length!=0?(<>
                
                {databaseData.map((value,index)=>(<> 
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  <input
                    type="radio" 
                    id={"select_"+value.slug}
                    className="deploy_checkbox database_radio"
                    defaultValue=""
                  />
                  <label
                  onClick={(e)=>(databaseVersion(value.slug))}
                    className=" label versionDb text-start border-1 border-gray-300 border px-6 py-2"
                    id={"version"+value.name}
                    style={{ position: "relative" }}
                  >
                    <span className="ms-5">
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <div className="row">
                            <div className="col-4">
                              <div className="symbol symbol-50px me-5">
                                <img src={"/assets/media/databases/"+value.slug+".svg"} className="w-100" />
                              </div>
                            </div>
                            <div className="col-8">
                              <p className="text-dark fw-bolder text-hover-primary fs-6">
                                {value.name}
                                <span
                                  className="text-muted d-block fw-bold deploy_checkbox_line2 dbversionselectmultiple w-100px"
                                  id={"dbversion_"+value.slug}
                                >
                                  Select Version
                                </span>
                              </p>
                            </div>
                          </div>
                          <span
                            className="deploy_dbVersioncontainer bg-white p-5 border-left-right-bottom"
                            id={"database_version_list_"+value.slug}
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              width: "100%",
                              zIndex: 99,
                              display: "none"
                            }}
                          >
                            {value.versions && value.versions.split(",").map((value2,index2)=>(<>
                              <span 
                              onClick={(event)=>{event.stopPropagation();selectDb(value.slug, value2)}}
                              className="deploy_osversionselector alert alert-primary p-3"
                            >
                              {value2}
                            </span>
                            </>))} 
                            
                          </span>
                        </div>
                      </div>
                    </span>
                    <div
                      className="d-none tickMarkDb ribbon ribbon-triangle ribbon-top-start border-primary"
                      id={"tickMarkDb-"+value.slug}
                    >
                      <div className="ribbon-icon mt-n5">
                        <i className="bi bi-check2 fs-2 text-white" />
                      </div>
                    </div>
                  </label>
                </div>
                </>))}
                
                </>):('')}
              </>):(<>
                <div className="small-spinner"></div>
              </>)}
                </div>
              </div>
              </div>
              {/*end::Col*/}
                
              {/*begin::Col*/} 
                <input
                  type="hidden"
                  name="size"
                  id="size_value"
                  defaultValue={
                    props.deployInfo && props.deployInfo
                      ? props.deployInfo[0].plan_id
                      : ""
                  }
                /> 
                <input
                  type="hidden" 
                  id="plan_cost"
                  defaultValue={
                    props.deployInfo && props.deployInfo
                      ? props.deployInfo[0].price
                      : ""
                  }
                /> 
                <input
                  type="hidden"
                  name="billing"
                  id="billing_info"
                  defaultValue={
                    props.deployInfo && props.deployInfo
                      ? props.deployInfo[0].billingcycle
                      : ""
                  }
                /> 
              {userData!=null && planData!=null?(<>
                {props.deployInfo[0]?(<>
                
                <PricingContainer updatePlan={updatedPlanInfo} currBilling={props.deployInfo[0].billingcycle} currPlanid={props.deployInfo[0].plan_id} user={userData} plans={planData}/>
                </>):('')}
             </>):(<>
              <div className="col-xl-12 card mb-5">
                <div className="card-header border-3 mb-5 pt-2">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">
                      Select Plan Type
                    </span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6">
                      Basic, Dedicated CPU, Dedicated Memory
                    </span>
                  </h3>
                </div>
                <div className="container pb-5">
                <div className="small-spinner"></div>
                </div>
                </div> 
             </>)}
              {/*end::Col*/} 

                <div className="col-xl-12 card mb-5">
  <div className="card-header border-3 mb-5 pt-2">
    <h3 className="card-title align-items-start flex-column">
      <span className="card-label fw-bolder text-gray-800">
      Set Number of Nodes
      </span>
      <span className="text-gray-400 mt-1 fw-bold fs-6">
        Input the number of nodes for your cluster
      </span>
    </h3>
  </div>
  <div className="container pb-5">
  <div
    className="row g-9"
    data-kt-buttons="true"
    data-kt-buttons-target="[data-kt-button]"
  >
    <div className="col-md-3 col-lg-3 col-xxl-3">
      <div className="">
        <div className="input-group mb-5">
          <input
            type="number"
            name="node_count"
            id="db_node_count"
            min={1}
            className="form-control text-center"
            onChange={(e)=>checkInput(e.target.value)}
            defaultValue={3} 
          />
          <span className="input-group-text" id="basic-addon2">Node</span>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>

<div className="col-xl-12 mb-5 d-none">
  <div className="card-header border-0 mb-5">
    <h3 className="card-title align-items-start flex-column">
      <span className="card-label fw-bolder text-gray-800">
      Allowed IP Address(es) or Range(s)
      </span>
      <span className="text-gray-400 mt-1 fw-bold fs-6">
      Add any IPv4 address or range that should be authorized to access this cluster.
 <br/>By default, all public and private connections are denied.
      </span>
    </h3>
  </div>
  <div
    className="row g-9 container"
    data-kt-buttons="true"
    data-kt-buttons-target="[data-kt-button]"
  >
    <div className="col-md-4 col-lg-4 col-sm-2 col-xxl-4">
      <div className="">
        <div className="input-group mb-5">
          <input
            type="text"
            name={"databaseIp[0][ip_address]"}
            className="form-control form-control-sm"
            placeholder="103.212.109.456" 
          />
        </div>
      </div>
    </div>
   
  </div> 
  {allowIp!=null?(<>
                {allowIp.length>0?(<>
                {allowIp.map((value,index)=>(<>
     <div
    className="row g-9 container"
    data-kt-buttons="true"
    data-kt-buttons-target="[data-kt-button]"
  >
    <div className="col-md-4 col-lg-4 col-sm-2 col-xxl-4">
      <div className="">
        <div className="input-group mb-5">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="103.212.109.456" 
            id={"clusterNode-"+index} 
            name={"databaseIp[" + (index+1) + "][ip_address]"}
          />
        </div>
      </div>
    </div>
    <div className="col-md-2 col-lg-2 col-sm-2 col-xxl-2">
 {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr015.svg*/}
 <span style={{cursor:"pointer"}} onClick={(e)=>deleteIpField(value)} className="svg-icon svg-icon-danger svg-icon-2hx">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
  {/*end::Svg Icon*/}
    </div>
  </div>
  </>))}
                </>):('')}
                </>):('')}
  
  <div className="card-header border-0 pt-5"> 
  <div className="card-toolbar align-items-start flex-column">
    <a id="addnodepool" onClick={addIpField} className="btn btn-sm btn-light-primary">
      <span className="svg-icon svg-icon-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
        >
          <rect
            opacity="0.5"
            x="11.364"
            y="20.364"
            width={16}
            height={2}
            rx={1}
            transform="rotate(-90 11.364 20.364)"
            fill="black"
          />
          <rect
            x="4.36396"
            y="11.364"
            width={16}
            height={2}
            rx={1}
            fill="black"
          />
        </svg>
      </span>
      Add An IP
    </a>
  </div>
</div>

</div>


  <div className="col-xl-12 card mb-5">
  <div className="card-header border-3 mb-5 pt-2">
    <h3 className="card-title align-items-start flex-column">
      <span className="card-label fw-bolder text-gray-800">
        Name Your Cluster
      </span>
      <span className="text-gray-400 mt-1 fw-bold fs-6">
        Input the name for your database cluster
      </span>
    </h3>
  </div>
  <div className="container pb-5">
  <div
    className="row g-9"
    data-kt-buttons="true"
    data-kt-buttons-target="[data-kt-button]"
  >
    <div className="col-md-4 col-lg-4 col-xxl-4">
      <div className="">
        <div className="input-group mb-5">
          <input
            type="text"
            name="cluster_label"
            id="db_label"
            className="form-control"
            placeholder="cluster label"
            defaultValue={"utho-"+makeid(8)+"-cluster"}
          />
        </div>
      </div>
    </div>
  </div>
  </div>
</div>

<div className="col-xl-12 card mb-5">
      <div className="card-header  mb-5 pt-2" style={{border:"none"}}>
  <h3 className="card-title align-items-start flex-column">
    <span className="card-label fw-bold fs-3 mb-1">
      Total Cost
    </span>
    <span className="fs-xl-2x fs-2 fw-boldest">
    <span id="currency_final"></span>
    <span id="total_cost"></span>
    <span id="billing_final"></span> </span>
    <span className="text-muted mt-1 fw-bold fs-7" />
  </h3>
  <div className="card-toolbar">  
    <a   style={{ width: "100%" }}
                 className="btn  btn-primary btn-active-light-primary"  id="database-btn" onClick={addDatabase}>
                  Create Cluster &nbsp; &nbsp;
      <span className="svg-icon svg-icon-3">
      <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
      fill="black"
    />
    <path
      opacity="0.3"
      d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
      fill="black"
    />
  </svg>
      </span>
    </a>
  </div>
</div>
</div>
    
            {/*end::Tables Widget 9*/}
          </form> 
          </>):(<><TopBarHeader /></>)}
    </>
  );
};


const mapStateToProps = (state) => ({
  nodeCountData: state.databaseClusterCount.nodeCountDataInfo,
  deployInfo: state.databaseClusterCount.deployClusterInfo, 
});

const mapDispatchToProps = (dispatch) => ({
  updateNodeCountData: (data) => dispatch(AddDatabaseNodeCount(data)),
  updateDeployInfo: (data) => dispatch(AddDatabaseDeploy(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeployDatabaseCluster); 
