import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import UsePostData from "../../../../../../../CustomHooks/usePostData"; 
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";  
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import swal from "sweetalert";
// const stripePromise = loadStripe("pk_test_jBtU87tvR5Vg9kL6ZedQw7Tj");
const stripePromise = loadStripe("pk_live_51L12BxIfq3qLqxWvLgbRRH7D4xhVQQ8vAxOuIsRbmCbOVWgFslIB2eyCoBZv1KIPdQkbipkXSO71mgL31ny75DLv00hwsFXgMe");
function MyCheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    var cardBtn=document.querySelector("#card_btn"); 
    var btnHtml=cardBtn.innerHTML;
    if (cardBtn) {
      cardBtn.innerHTML =
        'Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span>';
        cardBtn.classList.add("disabled");
    }
    const { error, token } = await stripe.createToken(
      elements.getElement(CardElement)
    );

    if (error) {
      swal({
        title: "Card Declined",
        text: error.message,
        icon: "warning",
        button: "OK",
      });
      if (cardBtn) {
        cardBtn.innerHTML =btnHtml;
          cardBtn.classList.remove("disabled");
      }
      // console.error(error);
    } else {
      const dt=toast.loading("Card adding..."); 
      const saveInfo=await UsePostData('POST','stripe/addtoken/'+token.id,{});
      if(saveInfo){
        if(saveInfo.status=="success"){
          toast.update(dt,{render: saveInfo.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        const modal_close=document.querySelector("#modal_close");
    if(modal_close){
      modal_close.click();
    }
        setTimeout(() => {
          window.location.reload();
          
        }, 2000);
        }else{
          toast.update(dt,{render: saveInfo.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
        });
        }
      }
      if (cardBtn) {
        cardBtn.innerHTML =btnHtml;
          cardBtn.classList.remove("disabled");
      }
    }
  };

  return (
    <div className="card p-5">
      <form  >
        <CardElement className="my-card-element mb-5" />
        <a onClick={handleSubmit} id="card_btn" className="btn btn-primary w-100">
          Add Card
        </a>
      </form>
    </div>
  );
}
export default function AddingStripeCard() {
  const [userData, setUserData] = useState(null);
 
  return (
    <> 
     <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_1">
     Continue
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                  <span className="svg-icon svg-icon-4 ms-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={18}
                        y={13}
                        width={13}
                        height={2}
                        rx={1}
                        transform="rotate(-180 18 13)"
                        fill="black"
                      />
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
     </a>
       <div
 className="modal fade" tabIndex={-1} id="kt_modal_1"
>
  {/*begin::Modal dialog*/}
  <div className="modal-dialog modal-dialog-centered mw-650px">
    {/*begin::Modal content*/}
    <div className="modal-content">
      {/*begin::Modal header*/}
      <div className="modal-header">
        {/*begin::Modal title*/}
        <h2>Add New Card</h2>
        {/*end::Modal title*/}
        {/*begin::Close*/}
        <div 
        id="modal_close"
          className="btn btn-sm btn-icon btn-active-color-primary"
          data-bs-dismiss="modal"
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Modal header*/}
      {/*begin::Modal body*/}
      <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
        {/*begin::Form*/}
        <Elements stripe={stripePromise}>
            <MyCheckoutForm />
          </Elements>
        {/*end::Form*/}
      </div>
      {/*end::Modal body*/}
    </div>
    {/*end::Modal content*/}
  </div>
  {/*end::Modal dialog*/}
</div>

    </>
  );
}
