import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate'; 
import UseDevApi from '../../../../../CustomHooks/useDevApi';
import UseApi from "../../../../../CustomHooks/useApi";
import TopBarHeader from "../../../../../CustomHooks/TopBarHeader";
import TableLoader from "../../../Loaders/TableLoader";
import TopBarHeaderLine from "../../../../../CustomHooks/TopBarHeaderLine";

const StorageList = () => {
    const [dataFetched, setDataFetched] = useState(false);
    const [access, setAccess]=useState(true);
    const [perPage, setPerPage]=useState(5);
    const [objectData,setObjectData]=useState(null);
    const dataFetchedRef=useRef (false);
    const loaderFunc=()=>{
      var loader = document.querySelector("#loader");
      var no_record = document.querySelector("#no_record");
      if(loader && no_record){
      no_record.classList.add("d-none");
      loader.classList.remove("d-none");
  
      setTimeout(() => {
        loader.classList.add("d-none");
        no_record.classList.remove("d-none");
      }, 100);
    }
    }
    const getObjectStorage=async()=>{ 
     
         const data=await UseApi('get','objectstorage/innoida/bucket','');
        //  console.log(data);
         if(data){
          setDataFetched(true);
         }
         if('status' in data){
          if(data.status=="error"){ 
            toast.dismiss(); 
            const dt=toast.loading("Object Storage loading..."); 
             toast.update(dt,{render: data.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });  
            setAccess(false);
            setObjectData(null); 
            loaderFunc(); 
            return;
          }
       }
         if(data.buckets.length === 0){
          toast.dismiss(); 
          const dt=toast.loading("Object Storage loading..."); 
          setObjectData(null);
               toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
              }); 
              loaderFunc();
         }else{
          setObjectData(data.buckets);  
          toast.dismiss();  
            
         }
    }
    useEffect(()=>{
      if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        toast.dismiss(); 
        getObjectStorage(); 
    },[]); 


    function ProductInfo({ productdata }) { 
        return(
            <> 
     
            {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((value, index) => (
              <tr>
                <td></td>
              <td className="text-start">
                <div className=" align-items-end">
                  {" "}
                  <div className="d-flex justify-content-end flex-column">
                    {" "}
                    <a className="text-gray-700 fw-bold text-hover-primary fs-6">
                      {value.name}
                    </a>{" "}
                  </div>{" "}
                </div>
              </td>
              <td className="text-start">
           <div className="d-flex align-items-center sphide ">
             {" "}
             <div className="symbol symbol-30px me-5">
               {" "}
               {value.dclocation && value.dclocation?(<>
                <img src={"/img/flag_"+value.dclocation.dccc+".png"} />
                  </>):('')}
               
             </div>{" "}
             <div className="d-flex justify-content-start flex-column">
               {" "}
               <a className="text-gray-700 fw-bold text-hover-primary fs-6 text-start">
                 {value.dclocation && value.dclocation?(<>
                 {value.dclocation.location}
                 </>):('')}
               </a>{" "}
               <span className="text-muted fw-bold text-muted d-block fs-7 text-start">
               {value.dclocation && value.dclocation?(<>
                 {value.dclocation.country}
                 </>):('')}
               </span>{" "}
             </div>{" "}
           </div>
         </td>
              <td className="text-center d-none">
              <div className="align-items-center">
                  {" "}
                  <div className="d-flex justify-content-center flex-column">
                    {" "}
                    <a className="text-gray-700 fw-bold text-hover-primary fs-6">
                      {/* {value.size} bytes */}
                    </a> 
                  </div>{" "}
                </div> 
              </td> 
              <td className="text-center">
              <div className="align-items-center">
                  {" "}
                  <div className="d-flex justify-content-center flex-column">
                    {" "}
                    <a className="text-gray-700 fw-bold text-hover-primary fs-6">
                      {value.object_count?(value.object_count):(0)}
                    </a> 
                  </div>{" "}
                </div> 
              </td> 
              <td className="text-center">
              <div className="align-items-center"> 
                  <div className="d-flex justify-content-start flex-column ">
                   {value.status=="active"?(<>
                    <span className="fw-bold fs-6 text-gray-700"><span className="status-circle"></span>
                    Active
                    </span>  </>):(<> <span className="fw-bold fs-6 text-gray-700"><span className="status-circle"></span>
                    Active
                    </span>  
                    </>)}
                       
                  </div>{" "}
                </div> 
              </td> 
              <td className="text-center">
              <div className="align-items-center">
                  {" "}
                  <div className="d-flex justify-content-start flex-column">
                    {" "}
                    <a className="text-gray-700 fw-bold text-hover-primary fs-6">
                    {value.created_at}
                    </a> 
                  </div>{" "}
                </div> 
              </td>  
              <td className="text-center">
                <div className="d-flex justify-content-center flex-shrink-0">
                  {" "}
                  {value.status!="active"?(<>
                  <Link to={"/ObjectStorage/"+value.dcslug+"/"+value.name}>
                    <a className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 ">
                      {" "}
                      Manage{" "}
                      <span className="svg-icon ms-2 svg-icon-3">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          {" "}
                          <rect
                            opacity="0.5"
                            x={18}
                            y={13}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-180 18 13)"
                            fill="black"
                          />{" "}
                          <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="black"
                          />{" "}
                        </svg>{" "}
                      </span>{" "}
                    </a>{" "}
                  </Link>
                  </>):(<>
                    <a className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 disabled">
                      {" "}
                      Manage{" "}
                      <span className="svg-icon ms-2 svg-icon-3">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          {" "}
                          <rect
                            opacity="0.5"
                            x={18}
                            y={13}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-180 18 13)"
                            fill="black"
                          />{" "}
                          <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="black"
                          />{" "}
                        </svg>{" "}
                      </span>{" "}
                    </a>
                  </>)}
                </div>{" "}
              </td>
            </tr>  
               ))):( <>
                <tr>
                         <td colspan="12" className="dataTables_empty" id="loader">
                          <div className="loading-spinner"></div>
                        </td>
                        <td colspan="12" className="d-none" id="no_record" valign="top">
                          <div className="d-flex flex-column flex-center"> 
                            <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                          </div>
                        </td>
                        </tr>
                </>)}
                
            </>
        )
    }
    
    
    const [searchTerm,setSearchTerm]=useState('');
    function PaginatedItems({ itemsPerPage, ProductData, search }) {
      // We start with an empty list of items. 
      const [pageCount, setPageCount] = useState(0);
      const [vpcData,setVpcData]=useState(null);
      // Here we use item offsets; we could also use page offsets
      // following the API or data you're working with.
      const [itemOffset, setItemOffset] = useState(0);
    
      useEffect(() => {
        // Fetch items from another resources.
        
        const endOffset = itemOffset + itemsPerPage;
    
    
      //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
         if(ProductData!=null){
        //   var str="Noida";
       // ////console.log(ProductData);
        const data= ProductData.filter(row => {
         return  Object.values(row).some(value => { 
            if(value!=null){
              if(value.toString().toLowerCase().includes(search.toLowerCase())){
                return value;
              } 
            }
          });
        });
        //////console.log(data);
          // const data=ProductData.filter(item => 
          //        item.hostname.toLowerCase().includes(search.toLowerCase())
          //              ); 
    
                       if(data.length<=0){
                        loaderFunc();
                       }
        setVpcData(data.slice(itemOffset, endOffset));
        //setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
        // alert(pageCount);
          }
      }, [itemOffset, itemsPerPage]);
    
      // Invoke when user click to request another page.
      
      const handlePageClick = (event) => { 
        const newOffset = event.selected * itemsPerPage % ProductData.length;
        ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
       
      };
      var searchEle=document.querySelector("#searchClient");
      if(searchEle){
        searchEle.focus();
      }
      return (
        <>
          <ProductInfo productdata={vpcData} />
          <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
          </td> 
        </>
      );
    }

     
    return (
        <>
          <div
          className="tab-pane fade active show"
          id="storage-tab"
          role="tabpanel"
        >
              <div className="mb-5 mb-xl-12">
                
                <div className="table-responsive">
                <table
                    id="firewallTable"
                    className="table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                  >
                    <thead className="bg-secondary">
                      <tr className="fw-bold ">
                        <th className="w-50px text-center"></th> 
                        <th className="w-200px text-start">Storage</th> 
                        <th className="w-225px text-start">Location</th>   
                        <th className="w-150px text-center d-none">Size</th>
                        <th className="w-150px text-center">Objects</th>
                        <th className="w-150px text-center">Status</th>
                        <th className="w-250px text-center">Created at</th> 
                        <th className="w-250px text-center"> 
                        <div
                      className="position-relative"
                      style={{ marginRight: 20 }}
                    >
                       <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {objectData!=null?(<>
<PaginatedItems itemsPerPage={perPage} ProductData={objectData} search={searchTerm}  />
  </>):(<>
    <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <TopBarHeaderLine />
                       <TableLoader />
                      {/* <div className="loading-spinner"></div> */}
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
  </>)}
                    </tbody>
                  </table>
                </div>
              </div>
            
              </div>
        </>
    );
}

export default StorageList;
