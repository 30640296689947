import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import swal from "sweetalert";    
import UsePostData from '../../../../../CustomHooks/usePostData';
import UseApi from '../../../../../CustomHooks/useApi';
const AlertTab = (props) => {
  var query = window.location.href; 
  var vars = query.split("/"); 
  var id=vars[4];
  const [comparisonArr, setComparsionArr]=useState(["==", ">=", "<=", ">", "<", "!="]);
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }  
 
    const dataFetchedRef =useRef(false);
const [alertData, setAlertData]=useState(null);
const [monitorData, setMonitorData]=useState(null); 
const [contactData, setContactData]=useState([]);
const getAlert = async () => {
 
  toast.dismiss(); 
  var response=await UseApi("get", "cloud/"+id+"/alert/list", "");
  console.log(response);
  //  if(props && props.alertInfo){
  //   setMonitorData(props.alertInfo);
  //   if(props.alertInfo.alerts){
  //     setAlertData(props.alertInfo.alerts);
  //   }
  //   if(props.alertInfo.contacts){
  //     var cData=[];

  //     props.alertInfo.contacts.map((value,index)=>{
  //       cData.push(value.name);
  //     })
  //     setContactData(cData);
  //   }
  //  }
};


useEffect(() => {
  if (dataFetchedRef.current) return;
  dataFetchedRef.current = true;
  toast.dismiss();
  getAlert();
}, []);
function removeFirstAndLastComma(str) {
  if (str.startsWith(",")) {
    str = str.substring(1);
  }
  if (str.endsWith(",")) {
    str = str.substring(0, str.length - 1);
  }
  return str;
}
const updateAlert=async(alertid)=>{}
//     const updateAlert=async(alertid)=>{ 
//       var submitBtn=document.querySelector("#updatealert"+alertid+"-btn"); 
//       var add_comparison=document.querySelector("#comparison_"+alertid); 
//       var add_occurrences=document.querySelector("#occurrences_"+alertid); 
//       var add_contacts=document.querySelector("#contacts_"+alertid); 
//       var add_comparison_limit=document.querySelector("#comparison_limit_"+alertid); 
//       var add_status=document.querySelector("#status_"+alertid);  
//       var acomparison="";
//       var aoccurrences="";
//       var acontacts="";
//       var acomparison_limit="";
//       var astatus="";  
//       if(add_comparison && add_occurrences && add_contacts && add_comparison_limit && add_status){
//          if(add_comparison.value==""){
//           const dt=toast.loading("Please wait..."); 
//           toast.update(dt,{render: "Comparison Operator must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//         });
//         return;
//         }else{
//           acomparison=add_comparison.value;
//         }
//         if(add_comparison_limit.value==""){
//           const dt=toast.loading("Please wait..."); 
//           toast.update(dt,{render: "Limit must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//         });
//         return;
//         }else{
//           acomparison_limit=add_comparison_limit.value;
//         }
//         if(add_occurrences.value==""){
//           const dt=toast.loading("Please wait..."); 
//           toast.update(dt,{render: "Frequency must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//         });
//         return;
//         }else{
//           aoccurrences=add_occurrences.value;
//         }
         
//         if(add_status.value==""){
//           const dt=toast.loading("Please wait..."); 
//           toast.update(dt,{render: "Please provide status", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//         });
//         return;
//         }else{
//           astatus=add_status.value;
//         }
      
//       if(submitBtn){
//         var preContent=submitBtn.innerHTML;
//         submitBtn.classList.add("disabled");
//         submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
//       const dt=toast.loading("Please wait..."); 
//       if(monitorData!=null){
//       const responseData=await UsePostData('post','monitoring/server/'+monitorData.id+'/alert/'+alertid+'/update',{"comparison":acomparison, "comparison_limit":acomparison_limit, "occurrences":aoccurrences, "status":astatus, "contactsnew":acontacts});
//       if(responseData){
//         submitBtn.classList.remove("disabled");
//         submitBtn.innerHTML=preContent;
//         if(responseData.status==='error'){
//           toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//         });
//         }else if(responseData.status==='success'){
//           toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//         });     
//         if(props && props.updateMonitoring){
//           setAlertData(null);
//           props.updateMonitoring();
//         }
        
//       }
//     }
//     }
//         }
//       }
//       }

const deleteAlert=async(alertid)=>{}
//       const deleteAlert=async(alertid)=>{
//         toast.dismiss();
//         var submitBtn=document.querySelector("#deletealert"+alertid+"-btn");
//         if(submitBtn){
         
//         swal({
//           title:"Are you sure?",
//           text: "Once deleted, you will not be able to recover this alert rule!",
//           icon: "warning",
//           buttons: true,
//           dangerMode: true,
//           })
//           .then(async(willDelete) => {
//           if(willDelete) {
//             var preContent=submitBtn.innerHTML;
//             submitBtn.classList.add("disabled");
//             submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
       
//             const dt=toast.loading("Alert deleting..."); 
//             if(monitorData!=null){
//         const dnsp=await UseApi('DELETE','monitoring/server/'+monitorData.id+'/alert/'+alertid+'/delete','');
//         //console.log(dnsp);
//         if(dnsp){
//         submitBtn.classList.remove("disabled");
//         submitBtn.innerHTML=preContent;
//         if(dnsp.status=='error'){
//           toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//         });
//         }else if(dnsp.status=='success'){
//           toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//         }); 
//         if(props && props.updateMonitoring){ 
//           setAlertData(null);
//           props.updateMonitoring(); 
//         }
//         }
//       } 
//           }
//         } 
//           });
//         }
//       }
// useEffect(()=>{
//   getAlert();
// },[props])
const addNewAlert=async(serverid)=>{}
// const addNewAlert=async(serverid)=>{ 
//   var submitBtn=document.querySelector("#addnewcontact-btn");
//   var add_type=document.querySelector("#add_type");
//   var add_comparison=document.querySelector("#add_comparison"); 
//   var add_occurrences=document.querySelector("#add_occurrences"); 
//   var add_contacts=document.querySelector("#contactsnew"); 
//   var add_comparison_limit=document.querySelector("#add_comparison_limit"); 
//   var add_status=document.querySelector("#add_status"); 
//   var atype="";
//   var acomparison="";
//   var aoccurrences="";
//   var acontacts="";
//   var acomparison_limit="";
//   var astatus=""; 
//   if(add_type && add_comparison && add_occurrences && add_contacts && add_comparison_limit && add_status){
   
//     if(add_type.value==""){
//       const dt=toast.loading("Please wait..."); 
//       toast.update(dt,{render: "Type must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//     });
//     return;
//     }else{
//       atype=add_type.value;
//     }
//     if(add_comparison.value==""){
//       const dt=toast.loading("Please wait..."); 
//       toast.update(dt,{render: "Comparison Operator must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//     });
//     return;
//     }else{
//       acomparison=add_comparison.value;
//     }
//     if(add_comparison_limit.value==""){
//       const dt=toast.loading("Please wait..."); 
//       toast.update(dt,{render: "Limit must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//     });
//     return;
//     }else{
//       acomparison_limit=add_comparison_limit.value;
//     }
//     if(add_occurrences.value==""){
//       const dt=toast.loading("Please wait..."); 
//       toast.update(dt,{render: "Frequency must be required!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//     });
//     return;
//     }else{
//       aoccurrences=add_occurrences.value;
//     }
    
//     if(add_contacts.value==""){
//       const dt=toast.loading("Please wait..."); 
//       toast.update(dt,{render: "Please provide contacts!", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//     });
//     return;
//     }else{ 
//         acontacts=add_contacts.value;
//     }
//     if(add_status.value==""){
//       const dt=toast.loading("Please wait..."); 
//       toast.update(dt,{render: "Please provide status", type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//     });
//     return;
//     }else{
//       astatus=add_status.value;
//     }
  
//   if(submitBtn){
//     var preContent=submitBtn.innerHTML;
//     submitBtn.classList.add("disabled");
//     submitBtn.innerHTML='<span className="indicator-label"><span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>'; 
//   const dt=toast.loading("Please wait..."); 
//   const responseData=await UsePostData('post','monitoring/server/'+serverid+'/alert/add',{"type":atype, "comparison":acomparison, "comparison_limit":acomparison_limit, "occurrences":aoccurrences, "status":astatus, "contactsnew":acontacts});
//   if(responseData){
//     submitBtn.classList.remove("disabled");
//     submitBtn.innerHTML=preContent;
//     if(responseData.status==='error'){
//       toast.update(dt,{render: responseData.message, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//     });
//     }else if(responseData.status==='success'){
//       toast.update(dt,{render: responseData.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
//     });  
//       add_type.value="";
//       add_comparison="";
//       add_comparison_limit="";
//       add_contacts="";
//       add_occurrences="";
//       add_status=""; 
//     ToogleAddAlert();
//     if(props && props.updateMonitoring){
//       setAlertData(null);
//       props.updateMonitoring();
//     }
    
//   }
// }
//     }
//   }
//   }
const ToogleAddAlert=()=>{}
//   const ToogleAddAlert=()=>{
   
//     var addAlertSec=document.querySelector("#add_alert_sec");
//     var addAlertBtn=document.querySelector("#addalert-btn");
//     if(addAlertSec && addAlertBtn){
//         addAlertSec.classList.toggle("d-none");
//         addAlertBtn.classList.toggle("disabled");
//     }
// } 
// var add_contactTagify = document.querySelector("#add_contacts");

// const contactTagify=new window.Tagify(add_contactTagify, {
//     whitelist: contactData,
//     maxTags: 10,
//     dropdown: {
//         maxItems: 20,          
//         classname: "",  
//         enabled: 0,              
//         closeOnSelect: false    
//     }
// });   
 
function addNewContact(e){}
// function addNewContact(e){
//   var contactsnew=document.querySelector("#contactsnew");
//   var contactArr="";
//   if(contactsnew){
//     if(e.target.value){
//     var contactData=JSON.parse(e.target.value);
//     if(props && props.alertInfo && props.alertInfo.contacts){
//       props.alertInfo.contacts.map((cvalue,index)=>{ 
//         contactData.map((data,index2)=>{ 
//           if(data.value==cvalue.name){
//             contactArr+=cvalue.id+","; 
//           } 
//         })
//       })
//     }
    
//     var currValue=contactArr.replace(/^,|,$/g, '');
//     contactsnew.value=currValue;
//   }else{
//     contactsnew.value="";
//   }
//   } 
// }
    return (
        <> 
      <div id="" className="card">
           {/*begin::Card header*/}
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_deactivate"
        aria-expanded="true"
        aria-controls="kt_account_deactivate"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Alert Rules</h3>
        </div>
      </div>
      {/*end::Card header*/}
       {/*begin::Table*/} 
       <div
                id="kt_table_users_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div className="table-responsive">
                  <table
                    className="table table-row-dashed border table-row-bordered gy-5 gs-7"
                    id="page_list"
                  >
                    {/*begin::Table head*/}
                    <thead className="bg-secondary">
                      {/*begin::Table row*/}
                      <tr className="text-start fw-bolder fs-7 text-uppercase gs-0">
                        <th className="min-w-150px text-center" style={{paddingLeft:"20px"}}>Type </th>
                        <th className="min-w-50px">Comparison </th>
                        <th className="min-w-50px text-center">Limits</th>
                        <th className="min-w-50px text-center">Frequency</th>
                        <th className="min-w-250px text-center">Contacts</th>
                        <th className="min-w-150px text-center">Status</th>
                        <th className="min-w-300px sorting" />
                      </tr>
                      {/*end::Table row*/}
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    <tbody className="text-gray-600" id="con-data">
                      {alertData!=null?(<>
                       {alertData.map((value,index)=>(<>
                        <tr className="openCustomer" data-id="">
                        {" "}
                        <td className="text-center"> 
                        <div className="align-items-start"> 
                         <div className="d-flex justify-content-center flex-column"> 
                           <a className="text-gray-700 fw-bold text-hover-primary fs-6 mt-4">{value.type}</a>
                         </div>
                        </div>
                        </td> 
                        <td className="text-center">
                        <select className='form-select' id={"comparison_"+value.id}  tabIndex={-1} aria-hidden="true">
                        {comparisonArr && comparisonArr.map((value2,index)=>(<>
                          {value.comparison==value2?(<>
                            <option value={value2} selected={true}>{value2}</option>
                          </>):(<>
                            <option value={value2}>{value2}</option>
                          </>)}
                          
                          </>
                        ))}
                      </select>
                        </td> 
                        <td className="text-center">
                          <input
                            type="text" 
                            id={"comparison_limit_"+value.id}
                            defaultValue={value.comparison_limit}
                            className="form-control" 
                          />
                        </td> 
                        <td className="text-center">
                          <input
                            type="text" 
                            id={"occurrences_"+value.id}
                            defaultValue={value.occurrences}
                            className="form-control" 
                          />
                        </td> 
                        <td className="text-center"> 
                          {value.contacts=="0" || value.contacts==""?(<>
                          {/* <input type='hidden' defaultValue="0"  id={"contacts_"+value.id} /> */}
                            <div className="align-items-start"> 
                         <div className="d-flex justify-content-center"> 
                           <a className="badge badge-warning">no contacts</a>
                         </div>
                        </div>
                          </>):(<>
                          {/* <span className='alert alert-success'> */}
                            {props && props.alertInfo && props.alertInfo.contacts.map((valueContact, indexCon)=>(<>
                              {value.contacts.split(',').map((contact, index) => (<>
                               {valueContact.id==contact?(<>
                                <span key={index} className="badge badge-success me-2 mt-1">
                                {valueContact.name}
                              </span>
                               </>):('')}
                              
                              </>
                            ))}
                            </>))}
                            
                            {/* </span> */}
                          </>)}
                          
                        </td> 
                        <td className="text-center">
                          <select
                            className="form-select"
                            id={"status_"+value.id} 
                          >
                            {value.status=="1"?(<>
                            <option value={1} selected={true}>Active</option>
                            <option value={0}>Inactive </option>
                            </>):(<>
                            <option value={1}>Active</option>
                            <option value={0} selected={true}>Inactive </option>
                            </>)}
                          </select>
                        </td> 
                        <td className="text-center">
                          <a
                             
                            id={"updatealert"+value.id+"-btn"}
                            onClick={(e)=>updateAlert(value.id)}
                             className="btn btn-sm btn-success"
                            style={{ marginRight: 20 }}
                          >
                             <span className="svg-icon svg-icon-muted svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                {" "}
                                <path
                                  opacity="0.3"
                                  d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                  fill="black"
                                />{" "}
                                <path
                                  opacity="0.3"
                                  d="M13 14.4V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V14.4H13Z"
                                  fill="black"
                                />{" "}
                                <path
                                  d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM13 14.4V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V14.4H8L11.3 17.7C11.7 18.1 12.3 18.1 12.7 17.7L16 14.4H13Z"
                                  fill="black"
                                />{" "}
                              </svg>
                            </span>{" "}
                            Update
                          </a>{" "}
                          <a
                             
                            id={"deletealert"+value.id+"-btn"}
                            onClick={(e)=>deleteAlert(value.id)}
                             className="btn btn-icon btn-light-danger"
                          >
                            <span className="svg-icon svg-icon-muted svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                {" "}
                                <path
                                  d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                  fill="black"
                                />{" "}
                                <path
                                  opacity="0.5"
                                  d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                  fill="black"
                                />{" "}
                                <path
                                  opacity="0.5"
                                  d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                  fill="black"
                                />{" "}
                              </svg>
                            </span>{" "}
                           </a>
                        </td>{" "}
                      </tr>
                        </>))}
                      </>):(<> <tr><td colSpan={12}><div className="small-spinner2"></div></td></tr></>)}
                      
                      <tr>
                        <td colSpan={12}>
                        <a 
                            id="addalert-btn"
                            onClick={ToogleAddAlert} 
                            className="btn btn-primary"
                            style={{ marginRight: 20 }}
                          >
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil017.svg*/}
                            {/*begin::Svg Icon | path: assets/media/icons/duotune/files/fil013.svg*/}
                            <span className="svg-icon svg-icon-muted svg-icon-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                  fill="black"
                                />
                                <path
                                  d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.3"
                                  d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                            {/*end::Svg Icon*/}
                            Add New
                          </a>
                        </td>
                      </tr>
                     </tbody>
                     </table>
                     <table
                    className="table table-row-dashed border table-row-bordered gy-5 gs-7 d-none" id="add_alert_sec">
                    {/*begin::Table head*/}
                    <thead className="bg-secondary">
                      {/*begin::Table row*/}
                      <tr className="text-start fw-bolder fs-7 text-uppercase gs-0">
                        <th className="min-w-250px text-center" style={{paddingLeft:"20px"}}>Type</th>
                        <th className="min-w-50px">Comparison</th>
                        <th className="min-w-50px text-center">Limits</th>
                        <th className="min-w-50px text-center">Frequency</th>
                        <th className="min-w-250px text-center">Contacts</th> 
                        <th className="min-w-300px sorting" />
                      </tr>
                      {/*end::Table row*/}
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    <tbody className="text-gray-600" id="con-data">
                      <tr className="openCustomer" >
                        <td className="text-center">
                        <select className="form-select" id="add_type" name="type" >
                        <optgroup  label="System">
                        <option value='nodata'>No Data</option>
                        <option value='cpu'>CPU Usage</option>
                        {monitorData!=null && monitorData.type=="linux"?(<>
                          <option value='cpuio'>CPU IO Wait %</option>
                          <option value='load1min'>System Load 1 Min</option>
                          <option value='load5min'>System Load 5 Min</option>
                          <option value='load15min'>System Load 15 Min</option>
                        </>):('')} 
                        <option value='service'>Service/Process Not Running</option>
                    </optgroup>

                    <optgroup label="RAM & Disk">
                        <option value='ram'>RAM Usage</option>
                        <option value='ramMB'> RAM Usage MB</option>
                        <option value='swap'> Swap Usage %</option>
                        <option value='swapMB'> Swap Usage MB</option>
                        <option value='disk'> Disk Usage % (Aggregated)</option>
                        <option value='diskGB'> Disk Usage GB (Aggregated)</option>
                     </optgroup>

                    <optgroup label="Network">
                    {monitorData!=null && monitorData.type=="linux"?(<>
                      <option value='connections'>Connections</option>
                      <option value='ssh'> SSH Sessions</option>
                      </>):('')}
                      <option value='ping'> Ping Latency</option>
                      <option value='netdl'> Network Download Speed MB/s</option>
                      <option value='netup'> Network Upload Speed MB/s</option>
                    </optgroup>
                          </select> 
                         
                        </td>
                        <td className="text-center">
                        <select className='form-select' id="add_comparison">
                        <option value="==">==</option>
                        <option value=">=" selected="">&gt;=
                        </option>
                        <option value="<=">&lt;=</option>
                        <option value=">">&gt;</option>
                        <option value="<">&lt;</option>
                        <option value="!=">!=</option>
                      </select>
                        </td>
                        <td className="text-center">
                          <input
                            type="text"
                            id="add_comparison_limit"
                            name="comparison_limit"
                            defaultValue=""
                            className="form-control" 
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="text"
                            id="add_occurrences"
                            name="occurrences"
                            defaultValue=""
                            className="form-control" 
                          />
                        </td>
                        <td className="text-center">
                          <input type='hidden' id="add_status" defaultValue="Active" name="status" />
                          <input class="form-control" value="" onChange={(e)=>addNewContact(e)} id="add_contacts"/>
                          <input type="hidden" name="contactsnew" id="contactsnew" />
                        </td>
                        <td className="text-center">
                        {monitorData!=null?(<>
                          <a
                            id="addnewcontact-btn"
                            onClick={(e)=>addNewAlert(monitorData.id)}
                             className="btn btn-primary sphide"
                            style={{ marginRight: 20 }}
                          >  
                           Add Contact  </a>
                           </>):('')}
                          <a
                             
                            id="addalert-btn"
                            onClick={ToogleAddAlert}
                             className="btn btn-danger"
                            style={{ marginRight: 20 }}
                          > 
                            Cancel
                          </a>
                        </td> 
                      </tr>
                      
                    </tbody>
                    {/*end::Table body*/}
                  </table>
                  
                </div>
              </div>
              {/*end::Table*/}
        </div> 
        </>
    );
}

export default AlertTab;
