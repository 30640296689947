import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import UseApi from "../../../../CustomHooks/useApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsePost from "../../../../CustomHooks/usePost";
import swal from "sweetalert";
import ReactPaginate from 'react-paginate';
import AccessDenied from "../../AccessDenied";
import TopBarHeader from "../../../../CustomHooks/TopBarHeader";
export default function AccountManage(){
  const [dataFetched, setDataFetched] = useState(false);
  const [access, setAccess]=useState(true);
  const [perPage, setPerPage]=useState(5);
  const [subData,setSubData]=useState(null);
  const dataFetchedRef=useRef (false);
  const loaderFunc=()=>{
    var loader = document.querySelector("#loader");
    var no_record = document.querySelector("#no_record");
    if(loader && no_record){
    no_record.classList.add("d-none");
    loader.classList.remove("d-none");

    setTimeout(() => {
      loader.classList.add("d-none");
      no_record.classList.remove("d-none");
    }, 100);
  }
  }
  const getSubAccount=async()=>{
    
    const dt=toast
       const datas=await UseApi('get','user','');
       if(datas){
        setDataFetched(true);
       }
       if(datas.status=='error' && datas.message!='No Records Found.'){ 
        toast.update(dt,{render: datas.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            });
            setAccess(false);
            setSubData(null);
            loaderFunc();
       }else{
       delete(datas.html);
       const data=Object.values(datas);
       ////console.log(data);
       if(datas.status=="error" && datas.message=="No Records Found."){  
             toast.update(dt,{render: "no record found", type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
            }); 
            setSubData([]);
       }else{
        setSubData(data[1]);  
        toast.dismiss();
          //   toast.update(dt,{render: "Your Sub Accounts request has been successful", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
          // }); 
       }
      }
  }
  useEffect(()=>{
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      toast.dismiss();
      getSubAccount(); 
  },[]); 
  const openDrawer=()=>{
    var drawerElement = document.querySelector("#add_subuser-modal");
    if(drawerElement){
        drawerElement.classList.toggle("drawer-on");
        drawerElement.style.width='600px';
  }
}

  var permission_arr={'compute':'Compute',
  'loadbalancer':'Load balancer',
  'vpc':'VPC',
  'vpn':'VPN',
  'ssl':'SSL',
  'sshkey':'SSH Key',
  'kubernetes':'Kubernetes',
  'firewall':'Firewall',
  'dns':'DNS',
  'snapshot':'Snapshot',
  'backup':'Backup',
  'iso':'ISO',
  'api':'API',
  'billing':'Billing',
  'activity':'Activity',
  'ticket':'Ticket',
  'billingaddfund':'Billing Add Fund',
  'user':'User',
  'image':'Image',
  'monitoring':'Monitoring',
  'kyc':'KYC'
}

permission_arr=Object.entries(permission_arr);

const permissionInput=()=>{
  permission_arr.map((value,index)=>{
    const readCheck=document.querySelector('#'+value[0]+'_read');
      const writeCheck=document.querySelector('#'+value[0]+'_write');
      const deleteCheck=document.querySelector('#'+value[0]+'_delete');
      const eachPermission=document.querySelector('#'+value[0]+'_set');
    if(deleteCheck.checked == true){
       eachPermission.value=''+value[0]+'_delete'+','+value[0]+'_read'+','+value[0]+'_write';
      readCheck.checked=true;
      writeCheck.checked=true;
    }else if(readCheck.checked === true && writeCheck.checked === true){
      eachPermission.value=''+value[0]+'_read'+','+value[0]+'_write';
      deleteCheck.checked=false;
    }
    else if(readCheck.checked === false && writeCheck.checked === true){
      eachPermission.value=''+value[0]+'_read'+','+value[0]+'_write';
      deleteCheck.checked=false;
      readCheck.checked=true;
    }else if(readCheck.checked === true && writeCheck.checked === true){
      eachPermission.value=''+value[0]+'_read'+','+value[0]+'_write';
      deleteCheck.checked=false;
    }else if(readCheck.checked === true && writeCheck.checked === false && deleteCheck.checked === false){
      eachPermission.value=''+value[0]+'_read';
    }else if(readCheck.checked === false && writeCheck.checked === false && deleteCheck.checked === false){
      eachPermission.value="blank";
    }     
})
}

const addUser=()=>{
  toast.dismiss();
  var permissions="";
  permission_arr.map((value,index)=>{
    const setPerms=document.querySelector('#'+value[0]+'_set');
     var permission_value=setPerms.value; 
     if(permission_value!="blank"){
     permissions+=permission_value+",";
     }
  });
  const permField=document.querySelector('#p_info');
  permField.value=permissions;
  swal({
    title:"Are you sure?",
    text: "Once subuser created, your subuser can access your account with the given permissions!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
    })
    .then(async(willDelete) => {
    if(willDelete) {
      const dt=toast.loading("Subuser creating..."); 
  const dnsp=await UsePost('POST','user','subuser-add');
  if(dnsp.status=='error'){
    toast.update(dt,{render: dnsp.message, type: "warning", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  });
  }else{
    toast.update(dt,{render: dnsp.message, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
  }); 
  openDrawer();
  const subForm=document.querySelector('#subuser-add');
      subForm.reset();
  getSubAccount();
  }
    }else {
      openDrawer();
      const subForm=document.querySelector('#subuser-add');
      subForm.reset();
      swal({
          title: "Subuser",
          text: "Subuser creation process stop successfully!",
          icon: "success",
          button: "OK",
        });
    }
    });
}

////console.log(subData);
function ProductInfo({ productdata }) { 
    return(
        <>
        
       
        {productdata!=null && Object.keys(productdata).length!=0?(productdata && productdata.map((value, index) => (
          <tr>
            {/*begin::ID*/}
            <td className="text-center">
              <span className="fw-bold text-center">{++index}</span>
            </td>
            {/*end::ID*/}
            {/*begin::User=*/}
            <td className="">
              {/*begin::User details*/}
              <div className="d-flex flex-column">
                
                <Link to={"/accountManagement/2323"}>
                <a 
                  
                  className="text-gray-800 fw-bolder text-hover-primary mb-1"
                >
                  {value.fullname}
                </a>
                </Link>
              </div>
              {/*begin::User details*/}
            </td>
            {/*end::User=*/}
            <td className="text-left">
              {/*begin::User details*/}
              <div className="d-flex flex-column">
                <span className="text-muted fw-bold">{value.email}</span>
                <span className="text-muted fw-bold" />
              </div>
              {/*begin::User details*/}
            </td>
            {/*begin::Role=*/}
            <td> 
              {value.status=='Pending'?(<div className="badge badge-warning">Pending</div>):(<div className="badge badge-success">Active</div>)}
            </td>
            {/*end::Role=*/}
            {/*begin::Last login=*/}
            {/* <td className="text-center">
              <div className="badge badge-light fw-bolder">{value.last_login_time}</div>
            </td> */}
            {/*end::Last login=*/}
            {/*begin::Joined*/}
            {/* <td className="text-center">
              <div className="badge badge-light fw-bolder">{value.created_at}</div>
            </td> */}
            {/*begin::Joined*/}
            {/*begin::Action=*/}
            <td className="text-center">
              <div className="d-flex justify-content-end flex-shrink-0">
                <Link  to={"/accountManagement/"+value.id+""}>
                <a
                  
                  className="btn btn-primary btn-active-light-primary btn-sm me-1 me-5 "
                >
                  
                  Manage
                  <span className="svg-icon ms-2 svg-icon-3">
                    
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      
                      <rect
                        opacity="0.5"
                        x={18}
                        y={13}
                        width={13}
                        height={2}
                        rx={1}
                        transform="rotate(-180 18 13)"
                        fill="black"
                      />
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </a>
                </Link>
              </div>
            </td>
            {/*end::Action=*/}
          </tr> 
          ))):( <>
           <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
            </>)}
            
        </>
    )

                  }
    const [searchTerm,setSearchTerm]=useState('');
    function PaginatedItems({ itemsPerPage, ProductData, search }) {
      // We start with an empty list of items. 
      const [pageCount, setPageCount] = useState(0);
      const [vpcData,setVpcData]=useState(null);
      // Here we use item offsets; we could also use page offsets
      // following the API or data you're working with.
      const [itemOffset, setItemOffset] = useState(0);
    
      useEffect(() => {
        // Fetch items from another resources.
        
        const endOffset = itemOffset + itemsPerPage;
  
  
      //  ////console.log(`Loading items from ${itemOffset} to ${endOffset}`);
         if(ProductData!=null){
        //   var str="Noida";
       // ////console.log(ProductData);
        const data= ProductData.filter(row => {
         return  Object.values(row).some(value => { 
            if(value!=null){
              if(value.toString().toLowerCase().includes(search.toLowerCase())){
                return value;
              } 
            }
          });
        });
        ////console.log(data);
          // const data=ProductData.filter(item => 
          //        item.hostname.toLowerCase().includes(search.toLowerCase())
          //              ); 

                       if(data.length<=0){
                        loaderFunc();
                       }
        setVpcData(data.slice(itemOffset, endOffset));
        //setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
        // alert(pageCount);
          }
      }, [itemOffset, itemsPerPage]);
    
      // Invoke when user click to request another page.
      
      const handlePageClick = (event) => { 
        const newOffset = event.selected * itemsPerPage % ProductData.length;
        ////console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
       
      };
      var searchEle=document.querySelector("#searchClient");
      if(searchEle){
        searchEle.focus();
      }
      return (
        <>
          <ProductInfo productdata={vpcData} />
          <td colspan="12">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
          </td>
        </>
      );
  
     
    }
 
    if(dataFetched){
      if(access==false){
        return (
          <>
           <AccessDenied />
          </>
        );
      } 
  if(subData!=null){
    if(Object.keys(subData).length!=0){ 
    return(
      <> 
       <div className="col-xl-12">
  {/*begin::Tables Widget 9*/}
  <div className="card card-xl-stretch mb-5 mb-xl-12">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold fs-3 mb-1">
          Account management
          {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
          {/* <span class="svg-icon svg-icon-2">
												<i class="las la-cloud" style="font-size:20px"></i>
											</span> */}
          {/*end::Svg Icon*/}
        </span>
        <span className="text-muted mt-1 fw-bold fs-7" />
      </h3>
      <div
        className="card-toolbar" 
      >
        <div className="position-relative my-1" style={{ marginRight: 20 }}>
          <input
            type="text"
            className="form-control form-control-sm"
            data-kt-table-widget-4="search"
            id="searchClient"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
        <div style={{ marginRight: 20 }}>
  <div className="dataTables_length">
    <label className="w-125px">
      <select  
        onChange={e => setPerPage(e.target.value)}
        //data-control="select2"
        className="form-select form-select-sm form-select-solid"
        defaultValue={5}
        id="perPage"
        
      >
        <option value={5} selected="">
          5 Records
        </option>
        <option value={10}>10 Records</option>
        <option value={25}>25 Records</option>
        <option value={50}>50 Records</option>
        <option value={100}>100 Records</option>
      </select>
    </label>
  </div>
</div>
        <a 
          onClick={openDrawer}
          className="btn btn-sm btn-primary btn-active-light-primary" 
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
          <span className="svg-icon svg-icon-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-90 11.364 20.364)"
                fill="black"
              />
              <rect
                x="4.36396"
                y="11.364"
                width={16}
                height={2}
                rx={1}
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}Add user 
        </a>
      </div>
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    {/*begin::Table container*/}
    <div className="table-responsive">
      {/*begin::Table*/}
      <table
        id="subuser_data"
        className="table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
      >
        {/*begin::Table head*/}
        <thead className="bg-secondary">
          <tr className="fw-bold ">
            <th className="w-125px text-center">#</th>
            <th className="w-150px">User</th>
            <th className="w-200px">Contact</th>
            <th className="w-150px">Status</th>
            {/* <th className="w-150px text-center">Last login</th>
            <th className="w-150px text-center">Created at</th> */}
            <th className="w-150px text-center" />
          </tr>
        </thead>
        {/*end::Table head*/}
        {/*begin::Table body*/}
        <tbody id="list-subuser"> 
      {subData!=null?(<>
       <PaginatedItems itemsPerPage={perPage} ProductData={subData} search={searchTerm}  />
       
</>):(<>
  <tr>
                     <td colspan="12" className="dataTables_empty" id="loader">
                      <div className="loading-spinner"></div>
                    </td>
                    <td colspan="12" className="d-none" id="no_record" valign="top">
                      <div className="d-flex flex-column flex-center"> 
                        <div className="fs-5 fw-bolder text-dark mb-4">No items found.</div>
                      </div>
                    </td>
                    </tr>
  </>)}

</tbody>
        {/*end::Table body*/}
      </table>
      {/*end::Table*/}
    </div>
    {/*end::Table container*/}
    {/*begin::Body*/}
  </div>
  {/*end::Tables Widget 9*/}
</div> 

<div
  id="add_subuser-modal"
  className="drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-activate="true" 
  data-kt-drawer-overlay="false" 
  data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}" 
  style={{maxWidth: '70%',important:true}}
>
  {/*begin::Messenger*/}
  <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
    {/*begin::Card header*/}
    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div className="d-flex justify-content-center flex-column me-3">
          <a
            
            className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
          >
            Add Subuser
          </a>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Close*/}
        <div
          className="btn btn-sm btn-icon btn-active-light-primary"
          onClick={openDrawer}
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {/*end::Card header*/}
    {/*begin::Card body*/}
    <div className="" id="">
      {/*begin::Form*/}
      <form id="subuser-add">
        {/*begin::Modal body*/}
        <div className="modal-body ">
          {/*begin::Scroll*/}
          <div className="scroll-y me-n7 pe-7">
            {/*begin::Input group*/}
            <div className="mb-5 fv-row">
              {/*begin::Label*/}
              <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                {" "}
                Full Name
              </label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <input
                type="text"
                id="fullname"
                name="fullname"
                className="form-control"
                placeholder="Enter First Name"
              />
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <div className="mb-5 fv-row">
              {/*begin::Label*/}
              <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                {" "}
                Mobile
              </label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <div className="d-flex flex-wrap flex-stack ">
                <input
                  type="text"
                  id="mobile"
                  defaultValue=""
                  maxLength={10}
                  name="mobile"
                  className="form-control border-input"
                  placeholder="Enter your Mobile"
                />
              </div>
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <div className="d-flex flex-column mb-5 fv-row">
              {/*begin::Label*/}
              <label htmlFor="feedback" className="required fs-5 fw-bold mb-2">
                Email
              </label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <input
                type="text"
                name="email"
                id="email"
                className="form-control"
                placeholder="name@example.com"
              />
              <input
                type="hidden"
                id="p_info"
                className="form-control"
                name="permissions"
                defaultValue={0}
              />
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
            <div className="d-flex flex-column mb-5 fv-row">
              {/*begin::Label*/}
              <label htmlFor="feedback" className="required fs-5 fw-bold mb-2">
                Permissons
              </label>
              {/*end::Label*/}
              {/*begin::Table*/}
              <table className="table align-middle table-row-dashed fs-6 gy-5">
                {/*begin::Table body*/}
                <tbody className="text-gray-600 fw-bold" id="permission-list">

                  {permission_arr.map((value,index)=>(
                    <>
                  {/*begin::Table row*/}
                  <tr>
                    {/*begin::Label*/}
                    <td className="text-gray-800">{value[1]}</td> 
                    <input type="hidden" class="form-control" id={""+value[0]+"_set"}/>
                    {/*end::Label*/}
                    {/*begin::Options*/}
                    <td>
                      {/*begin::Wrapper*/}
                      <div className="d-flex">
                        {/*begin::Checkbox*/}
                        <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={permissionInput}
                            defaultValue={""+value[0]+"_read"}
                            id={""+value[0]+"_read"}
                          />
                          <span className="form-check-label">Read</span>
                        </label>
                        {/*end::Checkbox*/}
                        {/*begin::Checkbox*/}
                        <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={permissionInput}
                            defaultValue={""+value[0]+"_write"}
                            id={""+value[0]+"_write"}
                          />
                          <span className="form-check-label">Write</span>
                        </label>
                        {/*end::Checkbox*/}
                        {/*begin::Checkbox*/}
                        <label className="form-check form-check-sm form-check-custom form-check-solid ">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={permissionInput}
                            defaultValue={""+value[0]+"_delete"}
                            id={""+value[0]+"_delete"}
                          />
                          <span className="form-check-label">Delete</span>
                        </label>
                        {/*end::Checkbox*/}
                      </div>
                      {/*end::Wrapper*/}
                    </td>
                    {/*end::Options*/}
                  </tr>
                  {/*end::Table row*/}
                  </>
                  ))}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Modal body*/}
        {/*begin::Modal footer*/}
        <div className="modal-footer ">
          {/*begin::Button*/}
          <button
            type="button"
            onClick={addUser}
            className="btn btn-primary w-100"
          >
            <span className="indicator-label">+ Add User</span>
          </button>
          {/*end::Button*/}
        </div>
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
    </div>
    {/*end::Card body*/}
  </div>
  {/*end::Messenger*/}
</div>   
      </>
  )
} else{
  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          {/*begin::Card body*/}
          <div className="card-body p-0 mt-10">
            {/*begin::Illustration*/}
            <div className="text-center px-4 mt-10">
              <span className="mw-100 mh-300px">
              <svg 
              // viewBox="0 0 64 64"  
              width="234px"
              height="234px" 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
                fill="#21325B"
              />
              <rect opacity="0.3" x={14} y={4} width={4} height={4} rx={2} fill="black" />
              <path
                d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
                fill="#21325B"
              />
              <rect opacity="0.3" x={6} y={5} width={6} height={6} rx={3} fill="black" />
            </svg>
            
              </span>
            </div>
            {/*end::Illustration*/}
            {/*begin::Wrapper*/}
            <div className="card-px text-center py-5 mb-20">
              {/*begin::Title*/}
              <h1 className="fs-2x fw-bolder mb-5">Subuser</h1>
              <h2 className="fs-1x fw-bolder mb-5 text-muted">
              Unlock the Power of Cloud
              </h2>
              {/*end::Title*/}
              {/*begin::Description*/}
              <p className="text-gray-400 fs-4 fw-bold mb-10">
              Streamline your deployment process by harnessing the power of the cloud.<br/> Seamlessly migrate your systems and explore a world of endless possibilities for your organization.
              </p>
              {/*end::Description*/}
              {/*begin::Action*/} 
              <a
                onClick={openDrawer}
                href="#"
                className="btn btn-primary mb-10"
              >
                Create Subuser
              </a> 
              {/*end::Action*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Card body*/}
        </div>
      </div>

<div
  id="add_subuser-modal"
  className="drawer drawer-end"
  data-kt-drawer="true"
  data-kt-drawer-activate="true" 
  data-kt-drawer-overlay="false" 
  data-kt-drawer-width="{default:'300px', 'md': '500px', 'sm': '300px'}" 
  style={{maxWidth: '70%',important:true}}
>
  {/*begin::Messenger*/}
  <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
    {/*begin::Card header*/}
    <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
      {/*begin::Title*/}
      <div className="card-title">
        {/*begin::User*/}
        <div className="d-flex justify-content-center flex-column me-3">
          <a
            
            className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
          >
            Add Subuser
          </a>
        </div>
        {/*end::User*/}
      </div>
      {/*end::Title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Close*/}
        <div
          className="btn btn-sm btn-icon btn-active-light-primary"
          onClick={openDrawer}
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {/*end::Card header*/}
    {/*begin::Card body*/}
    <div className="" id="">
      {/*begin::Form*/}
      <form id="subuser-add">
        {/*begin::Modal body*/}
        <div className="modal-body ">
          {/*begin::Scroll*/}
          <div className="scroll-y me-n7 pe-7">
            {/*begin::Input group*/}
            <div className="mb-5 fv-row">
              {/*begin::Label*/}
              <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                {" "}
                Full Name
              </label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <input
                type="text"
                id="fullname"
                name="fullname"
                className="form-control"
                placeholder="Enter First Name"
              />
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <div className="mb-5 fv-row">
              {/*begin::Label*/}
              <label htmlFor="type" className="required fs-5 fw-bold mb-2">
                {" "}
                Mobile
              </label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <div className="d-flex flex-wrap flex-stack ">
                <input
                  type="text"
                  id="mobile"
                  defaultValue=""
                  maxLength={10}
                  name="mobile"
                  className="form-control border-input"
                  placeholder="Enter your Mobile"
                />
              </div>
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <div className="d-flex flex-column mb-5 fv-row">
              {/*begin::Label*/}
              <label htmlFor="feedback" className="required fs-5 fw-bold mb-2">
                Email
              </label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <input
                type="text"
                name="email"
                id="email"
                className="form-control"
                placeholder="name@example.com"
              />
              <input
                type="hidden"
                id="p_info"
                className="form-control"
                name="permissions"
                defaultValue={0}
              />
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
            <div className="d-flex flex-column mb-5 fv-row">
              {/*begin::Label*/}
              <label htmlFor="feedback" className="required fs-5 fw-bold mb-2">
                Permissons
              </label>
              {/*end::Label*/}
              {/*begin::Table*/}
              <table className="table align-middle table-row-dashed fs-6 gy-5">
                {/*begin::Table body*/}
                <tbody className="text-gray-600 fw-bold" id="permission-list">

                  {permission_arr.map((value,index)=>(
                    <>
                  {/*begin::Table row*/}
                  <tr>
                    {/*begin::Label*/}
                    <td className="text-gray-800">{value[1]}</td> 
                    <input type="hidden" class="form-control" id={""+value[0]+"_set"}/>
                    {/*end::Label*/}
                    {/*begin::Options*/}
                    <td>
                      {/*begin::Wrapper*/}
                      <div className="d-flex">
                        {/*begin::Checkbox*/}
                        <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={permissionInput}
                            defaultValue={""+value[0]+"_read"}
                            id={""+value[0]+"_read"}
                          />
                          <span className="form-check-label">Read</span>
                        </label>
                        {/*end::Checkbox*/}
                        {/*begin::Checkbox*/}
                        <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={permissionInput}
                            defaultValue={""+value[0]+"_write"}
                            id={""+value[0]+"_write"}
                          />
                          <span className="form-check-label">Write</span>
                        </label>
                        {/*end::Checkbox*/}
                        {/*begin::Checkbox*/}
                        <label className="form-check form-check-sm form-check-custom form-check-solid ">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={permissionInput}
                            defaultValue={""+value[0]+"_delete"}
                            id={""+value[0]+"_delete"}
                          />
                          <span className="form-check-label">Delete</span>
                        </label>
                        {/*end::Checkbox*/}
                      </div>
                      {/*end::Wrapper*/}
                    </td>
                    {/*end::Options*/}
                  </tr>
                  {/*end::Table row*/}
                  </>
                  ))}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Modal body*/}
        {/*begin::Modal footer*/}
        <div className="modal-footer ">
          {/*begin::Button*/}
          <button
            type="button"
            onClick={addUser}
            className="btn btn-primary w-100"
          >
            <span className="indicator-label">+ Add User</span>
          </button>
          {/*end::Button*/}
        </div>
        {/*end::Modal footer*/}
      </form>
      {/*end::Form*/}
    </div>
    {/*end::Card body*/}
  </div>
  {/*end::Messenger*/}
</div>   
    </>
  );
}
}
}else{
return (
<>
{/* <div class="loading-spinner2"></div> */}
<TopBarHeader/>
</>
);
}
}