import React from 'react'; 
import { useEffect, useState } from 'react';
import UseApi from '../CustomHooks/useApi';
import BillingVerification from '../MicroApp/Body/Products/verification/Billing/BillingVerification';
import MicroApp from '../MicroApp/MicroApp';
import Feedback from '../MicroApp/Body/Feedback';
import localStorage from 'local-storage';
import BuisnessProfile from './BuisnessProfile';
import { Route, Routes, useNavigate } from 'react-router-dom';
import UpdateUserProfile from './UpdateUserProfile';
import TopBarHeader from '../CustomHooks/TopBarHeader';
import TopBarHeaderLine from '../CustomHooks/TopBarHeaderLine';
const SubuserVerification = () => { 
    const navigate=useNavigate();
    
    const [subusers,setSubusers]=useState(null); 
    const getSubuser=async()=>{
       
        const responseData=await UseApi('get','amaccess',''); 
        if(responseData){
        if(responseData.status=="success"){ 
            setSubusers(responseData.accountaccess);  
        }else{
            setSubusers([]);
        }
    }
        //console.log(responseData);
    }
    useEffect(()=>{
       getSubuser();
    },[])
 
    const user_info_data = localStorage.get("user_info");
if(user_info_data){ 
    return (
        <>  
         {subusers!=null?(<>  
         {subusers.length>0?(<>
            <Routes>
            <Route path="/updateProfile" element={<UpdateUserProfile />}   /> 
            <Route path="/supportProfile" element={<BuisnessProfile />}   /> 
            <Route path="*" element={<MicroApp />}   /> 
            </Routes> 
         </>):(<> 
            {user_info_data.c_added!='no' || user_info_data.rvn!=null?(<>
                <Routes>
                <Route path="/updateProfile" element={<UpdateUserProfile />}   /> 
                <Route path="/supportProfile" element={<BuisnessProfile />}   /> 
                <Route path="*" element={<MicroApp />}   /> 
                </Routes> 
            </>):(<>
                <BillingVerification />
               
            </>)}
         
         </>)}
        </>):(<>
             <TopBarHeaderLine />
            <div className="mainLoad"><img src="assets/images/utho.png" alt="" width="100%" /></div>
            {/* <div class="loading-spinner2"></div> */}
        </>)}
        </>
        
    );
        }else{
            return(<>
            <TopBarHeaderLine />
            <div className="mainLoad"><img src="assets/images/utho.png" alt="" width="100%" /></div>
            {/* <div class="loading-spinner2"></div> */}
            </>)
        }
};
 
export default SubuserVerification;
