//import React, { useEffect, useState } from "react"; 
import axios from "axios"; 
 export default async function UseCharts(type,url){  
   const darr= await axios({
        method: type,
        url:'https://console.utho.com/zabbix.php?'+url, 
    }).then(function(response){ 
        return response.data;
    })
    ////console.log(darr);
    return darr;
}